import { useMediaQuery } from '@chakra-ui/react'
import LandingLayout from '../../../layouts/landing'
import RegisterCard from '../../../components/register/card'
import RegisterInstitutionCompoProfile from '../../../components/profile/register'


const RegisterInstitutionProfile = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  return (
    <LandingLayout>
      {isMobile ? (
        <RegisterInstitutionCompoProfile />
      ) : (
        <RegisterCard>
          <RegisterInstitutionCompoProfile />
        </RegisterCard>
      )}
    </LandingLayout>
  )
}

export default RegisterInstitutionProfile