import { Box, Stack } from '@chakra-ui/layout';
import { Card, CardBody, Flex, Text, useMediaQuery, Image, useBreakpoint } from '@chakra-ui/react';
import { colors } from '../../../../utils/colors';
import { LandingMarginProps } from '../../../../pages/landing/regular-landing';
import { useEffect, useState } from 'react';
import { steps } from '.';
import PropTypes from 'prop-types'

export const FewStepsJob: React.FC<LandingMarginProps> = ({ contPadding }) => {
    const [width, setWidth] = useState(0);
    const [isMobile] = useMediaQuery('(max-width: 600px)');
    const deviceWidth = useBreakpoint();

    const isTablet = width >= 600 && width <= 768;
    const cardGap = 40;
    const isLargeViewport = width > 1440;
    const responsiveMargin = deviceWidth === 'base' ? 42.5 : 170;

    const calcCardWidth = (isLargeViewport) ? '305px' : `${((width - responsiveMargin) - (cardGap * 3)) / (isMobile ? 2 : 4)}px`;

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setWidth]);

    return (
        <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            minHeight='390px'
            width={['100%']}
            px={contPadding}
        >
            <Box>
                <Text
                    fontSize={['36px', '46px']}
                    textAlign={'center'}
                    color='#004876'
                    mb='90px'
                >
                    ¡A pocos pasos de tu nuevo trabajo!
                </Text>
            </Box>
            <Flex
                flexDirection="row"
                flexWrap='wrap'
                gap={(isLargeViewport) ? '40px' : (isMobile) ? '10px' : '0'}
                justifyContent={{ base: 'space-between', md: 'space-between', lg: (isLargeViewport) ? 'center' : 'space-between' }}
                alignContent={['center', 'center']}
                width={['100%', '100%']}
            >
                {steps.map(step => {
                    return <Card
                        width={['46%', calcCardWidth]}
                        height={['180px', '220px']}
                        display='flex'
                        justifyContent="center"
                        alignItems="center"
                        boxShadow='0px 0px 52.5px 6px rgba(0, 0, 0, 0.09)'
                        key={step.title}
                        bgColor='#fff'
                        padding='32px'
                        borderRadius='12px'
                    >
                        <Stack>
                            <CardBody display='flex'
                                justifyContent="center"
                                alignItems="center"
                                flexDirection='column'
                                p='0'
                            >
                                <Image src={step.icon} />
                                <Text
                                    mt={['10px', '25px']}
                                    color={colors.institutionalRed}
                                    lineHeight='23.31px'
                                    textAlign='center'
                                    fontSize={{ base: '16px', md: '16px', lg: '18px' }}
                                >{step.title}</Text>
                            </CardBody>
                        </Stack>
                    </Card>;
                })}

            </Flex>
        </Flex>
    );
};
FewStepsJob.propTypes = {
    contPadding: PropTypes.string.isRequired,
  }