import * as types from './types'

import {
  ModuleTypeAddFolder,
  ModuleTypeDeleteFolder,
  ModuleTypeFavoriteNote,
  ModuleTypeFavoriteUpdate,
  ModuleTypeFavoritesByFolder,
  ModuleTypeFoldersList,
  ModuleTypeRenameFolder,
} from './_definitions'

import { PayloadAction } from '../../types'
import { favorites } from './_ducks'

const initialModuleStateAddFolder: ModuleTypeAddFolder = {
  hasErrMessage: false,
  addFolderStatus: null,
  addFolderSuccess: null,
}
const initialModuleStateRenameFolder: ModuleTypeRenameFolder = {
  hasErrMessage: false,
  renameFolderStatus: null,
  renameFolderSuccess: null,
}

const initialModuleStateDeleteFolder: ModuleTypeDeleteFolder = {
  hasErrMessage: false,
  deleteFolderStatus: null,
  deleteFolderSuccess: null,
}

const initialModuleStateFoldersList: ModuleTypeFoldersList = {
  hasErrMessage: false,
  foldersListStatus: null,
}

const initialModuleStateFavoritesByFolders: ModuleTypeFavoritesByFolder = {
  hasErrMessage: false,
  favoritesByFolderStatus: null,
}

const initialModuleStateFavoriteNote: ModuleTypeFavoriteNote = {
  hasErrMessage: false,
  favoriteNoteStatus: null,
  favoriteNoteSuccess: null,
}

const initialModuleStateFavoriteUpdate: ModuleTypeFavoriteUpdate = {
  hasErrMessage: false,
  favoriteUpdateStatus: null,
  favoriteUpdateSuccess: null,
}

const reducer = favorites.createReducer(
  {
    [types.ADD_FOLDER_POST_REQUEST]: (state: ModuleTypeAddFolder): ModuleTypeAddFolder => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.ADD_FOLDER_POST_SUCCESS]: (
      state: ModuleTypeAddFolder,
      { payload }: PayloadAction,
    ): ModuleTypeAddFolder => ({
      ...state,
      addFolderSuccess: true,
      addFolderStatus: payload,
    }),
    [types.ADD_FOLDER_POST_FAILURE]: (
      state: ModuleTypeAddFolder,
      { payload }: PayloadAction,
    ): ModuleTypeAddFolder => ({
      ...state,
      hasErrMessage: true,
      addFolderSuccess: false,
      addFolderStatus: payload,
    }),
    [types.ADD_FOLDER_POST_CLEAN]: (state: ModuleTypeAddFolder): ModuleTypeAddFolder => ({
      ...state,
      hasErrMessage: false,
      addFolderSuccess: null,
      addFolderStatus: null,
    }),
    [types.FOLDER_DELETE_REQUEST]: (state: ModuleTypeDeleteFolder): ModuleTypeDeleteFolder => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.FOLDER_DELETE_SUCCESS]: (
      state: ModuleTypeDeleteFolder,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteFolder => ({
      ...state,
      deleteFolderSuccess: true,
      deleteFolderStatus: payload,
    }),
    [types.FOLDER_DELETE_FAILURE]: (
      state: ModuleTypeDeleteFolder,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteFolder => ({
      ...state,
      hasErrMessage: true,
      deleteFolderSuccess: false,
      deleteFolderStatus: payload,
    }),
    [types.FOLDER_DELETE_CLEAN]: (state: ModuleTypeDeleteFolder): ModuleTypeDeleteFolder => ({
      ...state,
      hasErrMessage: false,
      deleteFolderSuccess: null,
      deleteFolderStatus: null,
    }),
    [types.RENAME_FOLDER_PUT_REQUEST]: (state: ModuleTypeRenameFolder): ModuleTypeRenameFolder => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.RENAME_FOLDER_PUT_SUCCESS]: (
      state: ModuleTypeRenameFolder,
      { payload }: PayloadAction,
    ): ModuleTypeRenameFolder => ({
      ...state,
      renameFolderSuccess: true,
      renameFolderStatus: payload,
    }),
    [types.RENAME_FOLDER_PUT_FAILURE]: (
      state: ModuleTypeRenameFolder,
      { payload }: PayloadAction,
    ): ModuleTypeRenameFolder => ({
      ...state,
      hasErrMessage: true,
      renameFolderSuccess: false,
      renameFolderStatus: payload,
    }),
    [types.RENAME_FOLDER_PUT_CLEAN]: (state: ModuleTypeRenameFolder): ModuleTypeRenameFolder => ({
      ...state,
      hasErrMessage: false,
      renameFolderSuccess: null,
      renameFolderStatus: null,
    }),
    [types.FOLDERS_LIST_GET_REQUEST]: (state: ModuleTypeFoldersList): ModuleTypeFoldersList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.FOLDERS_LIST_GET_SUCCESS]: (
      state: ModuleTypeFoldersList,
      { payload }: PayloadAction,
    ): ModuleTypeFoldersList => ({
      ...state,
      foldersListStatus: payload,
    }),
    [types.FOLDERS_LIST_GET_FAILURE]: (
      state: ModuleTypeFoldersList,
      { payload }: PayloadAction,
    ): ModuleTypeFoldersList => ({
      ...state,
      hasErrMessage: true,
      foldersListStatus: payload,
    }),
    [types.FOLDERS_LIST_GET_CLEAN]: (state: ModuleTypeFoldersList): ModuleTypeFoldersList => ({
      ...state,
      hasErrMessage: false,
      foldersListStatus: null,
    }),

    [types.FAVORITES_BY_FOLDER_GET_REQUEST]: (
      state: ModuleTypeFavoritesByFolder,
    ): ModuleTypeFavoritesByFolder => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.FAVORITES_BY_FOLDER_GET_SUCCESS]: (
      state: ModuleTypeFavoritesByFolder,
      { payload }: PayloadAction,
    ): ModuleTypeFavoritesByFolder => ({
      ...state,
      favoritesByFolderStatus: payload,
    }),
    [types.FAVORITES_BY_FOLDER_GET_FAILURE]: (
      state: ModuleTypeFavoritesByFolder,
      { payload }: PayloadAction,
    ): ModuleTypeFavoritesByFolder => ({
      ...state,
      hasErrMessage: true,
      favoritesByFolderStatus: payload,
    }),
    [types.FAVORITES_BY_FOLDER_GET_CLEAN]: (
      state: ModuleTypeFavoritesByFolder,
    ): ModuleTypeFavoritesByFolder => ({
      ...state,
      hasErrMessage: false,
      favoritesByFolderStatus: null,
    }),
    [types.FAVORITE_NOTE_PUT_REQUEST]: (state: ModuleTypeFavoriteNote): ModuleTypeFavoriteNote => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.FAVORITE_NOTE_PUT_SUCCESS]: (
      state: ModuleTypeFavoriteNote,
      { payload }: PayloadAction,
    ): ModuleTypeFavoriteNote => ({
      ...state,
      favoriteNoteSuccess: true,
      favoriteNoteStatus: payload,
    }),
    [types.FAVORITE_NOTE_PUT_FAILURE]: (
      state: ModuleTypeFavoriteNote,
      { payload }: PayloadAction,
    ): ModuleTypeFavoriteNote => ({
      ...state,
      hasErrMessage: true,
      favoriteNoteSuccess: false,
      favoriteNoteStatus: payload,
    }),
    [types.FAVORITE_NOTE_PUT_CLEAN]: (state: ModuleTypeFavoriteNote): ModuleTypeFavoriteNote => ({
      ...state,
      hasErrMessage: false,
      favoriteNoteSuccess: null,
      favoriteNoteStatus: null,
    }),
    [types.FAVORITE_UPDATE_POST_REQUEST]: (
      state: ModuleTypeFavoriteUpdate,
    ): ModuleTypeFavoriteUpdate => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.FAVORITE_UPDATE_POST_SUCCESS]: (
      state: ModuleTypeFavoriteUpdate,
      { payload }: PayloadAction,
    ): ModuleTypeFavoriteUpdate => ({
      ...state,
      favoriteUpdateSuccess: true,
      favoriteUpdateStatus: payload,
    }),
    [types.FAVORITE_UPDATE_POST_FAILURE]: (
      state: ModuleTypeFavoriteUpdate,
      { payload }: PayloadAction,
    ): ModuleTypeFavoriteUpdate => ({
      ...state,
      hasErrMessage: true,
      favoriteUpdateSuccess: false,
      favoriteUpdateStatus: payload,
    }),
    [types.FAVORITE_UPDATE_POST_CLEAN]: (
      state: ModuleTypeFavoriteUpdate,
    ): ModuleTypeFavoriteUpdate => ({
      ...state,
      hasErrMessage: false,
      favoriteUpdateSuccess: null,
      favoriteUpdateStatus: null,
    }),
  },
  {
    initialModuleStateAddFolder,
    initialModuleStateDeleteFolder,
    initialModuleStateRenameFolder,
    initialModuleStateFoldersList,
    initialModuleStateFavoritesByFolders,
    initialModuleStateFavoriteNote,
    initialModuleStateFavoriteUpdate,
  },
)

export default reducer
