import { colors } from '../../../utils/colors'

export const jobApplicationCardStyles = {
  container: {
    border: `1px solid ${colors.principal}`,
    padding: '20px 30px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  title: {
    color: colors.principal,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '28px',
    margin: '0 0 10px 0',
  },
  mainDataContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
    color: colors.principal,
    fontSize: '12px',
  },
  infoContainer: { width: '85%' },
  dataItem: { display: 'flex', alignItems: 'center', flexWrap: 'wrap' },
  adDataTitle: { fontWeight: 'bold' },
  adData: {
    columnGap: '5px',
    textTransform: 'capitalize',
    marginLeft: '5px',
  },
  locationContainer: { display: 'flex', flexDirection: 'column', rowGap: '10px' },
  actionContainer: {
    width: '15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '10px',
    color: colors.principal,
  },
  actionButton: {
    borderRadius: '5px',
    backgroundColor: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    color: '#fff',
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '15px',
    justifyContent: 'center',
  },
  stateTitle: { fontSize: '15px' },
  badge: {
    textTransform: 'capitalize',
    padding: '4px 8px',
    fontSize: '11px',
    borderRadius: '5px',
  },
  containerMobile: {
    border: `1px solid ${colors.principal}`,
    padding: '20px 30px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  infoContainerMobile: {
    width: '100%',
  },
  mainDataContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    color: colors.principal,
    fontSize: '12px',
  },
  dataItemMobile1: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    fontSize: '14px',
  },
  dataItemMobile2: {
    display: 'flex',
    fontSize: '14px',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '5px',
  },
  actionContainerMobile: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
  },
  imageContainer: { width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  imageContainerMobile: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  avatarMobile: { width: '100px', height: '100px', borderRadius: '100px', objectFit: 'contain' },
  avatarImage: { width: '100px', height: '100px', borderRadius: '100px', objectFit: 'contain' },
}
