import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Card,
  Box,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { transformDateFromSQL } from '../../../../../../utils/profile'

const PersonalDataResume = () => {
  const userInformation = useSelector((state: any) => state?.user?.userInformation ?? '')
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [datos, setDatos] = useState({
    dni: userInformation?.Profile?.dni ?? '-',
    cuil: userInformation?.Profile?.cuil ?? '-',
    age: userInformation?.Profile?.age ?? '-',
    email: userInformation.email ?? '-',
    gender: userInformation?.Profile?.gender ?? '-',
    phone: userInformation?.Profile?.phoneNumber ?? '-',
    cellPhone: userInformation?.Profile?.cellPhone ?? '-',
    civilStatus: userInformation?.Profile?.civilStatus ?? '-',
    birthdate: '-',
    nationality: userInformation.Profile?.Nationality?.description ?? '-',
    Address: '-',
  })

  useEffect(() => {
    userInformation === undefined || userInformation === null || userInformation === ''
      ? null
      : setearDatos()
  }, [userInformation])

  const setearDatos = () => {
    const calle =
      userInformation.Profile?.Address?.street && userInformation.Profile?.Address?.street !== ''
        ? userInformation.Profile?.Address?.street
        : '-'
    const numero =
      userInformation.Profile?.Address?.number && userInformation.Profile?.Address?.number !== ''
        ? userInformation.Profile?.Address?.number
        : '-'
    const floor =
      userInformation.Profile?.Address?.floor && userInformation.Profile?.Address?.floor !== ''
        ? userInformation.Profile?.Address?.floor
        : '-'
    const department =
      userInformation.Profile?.Address?.department &&
      userInformation.Profile?.Address?.department !== ''
        ? userInformation.Profile?.Address?.department
        : '-'

    const provinciaName = userInformation.Profile?.Address?.Province?.description ?? ''
    const localidadName = userInformation.Profile?.Address?.Locality?.description ?? ''
    const departamentoName = userInformation.Profile?.Address?.Department?.description ?? ''
    const fullAddress =
      'Calle: ' +
      calle +
      ' | Altura: ' +
      numero +
      ' | Piso: ' +
      floor +
      ' | Dpto: ' +
      department +
      ' | Localidad: ' +
      (localidadName === '' ? '-' : localidadName.toLowerCase()) +
      ' | Departamento/Partido: ' +
      (localidadName === '' ? '-' : departamentoName.toLowerCase()) +
      ' | Provincia: ' +
      (provinciaName === '' ? '-' : provinciaName.toLowerCase())

    const fecha = userInformation.Profile.birthdate ?? ''
    const { profileDate } = transformDateFromSQL(fecha)

    setDatos({
      ...datos,
      dni: userInformation.Profile.dni ?? '-',
      cuil: userInformation.Profile.cuil ?? '-',
      age: userInformation.Profile.age ?? '-',
      email: userInformation.email ?? '-',
      gender: userInformation.Profile.gender ?? '-',
      phone: userInformation.Profile.phoneNumber ?? '-',
      cellPhone: userInformation.Profile.cellPhone ?? '-',

      civilStatus: userInformation.Profile.civilStatus ?? '-',
      birthdate: profileDate === '' ? '-' : profileDate,
      nationality: userInformation.Profile?.Nationality?.description ?? '-',
      Address: fullAddress,
    })
  }

  return (
    <Card>
      <CardBody>
        <Stack divider={<StackDivider />} spacing='4'>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              DNI
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.dni}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Cuil
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.cuil}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Edad
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.age}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Email
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.email}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Género
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.gender}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Teléfono
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.phone === '' ? '-' : datos.phone}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Teléfono Celular
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.cellPhone}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Estado civil
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.civilStatus}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Fecha de nacimiento
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.birthdate}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Nacionalidad
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.nationality}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Domicilio
            </Heading>
            <Text
              textTransform={'capitalize'}
              fontSize='12px'
              ml={isMobile ? 0 : 2}
              mt={isMobile ? 2 : 0}
            >
              {datos.Address}
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  )
}

export default PersonalDataResume
