import { colors } from './../../../../utils/colors'
export const preferencesStyles = {
  heading: {
    fontSize: '16px',
    marginBottom: '16px',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
  },
  preferenceForm: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '24px',
  },
  loading: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    width: '80%',
    height: '30vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  preferenceFormMobile: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '24px',
  },
}
