import * as Yup from 'yup'

import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import { Box, Button, FormControl, FormLabel, Text, useMediaQuery } from '@chakra-ui/react'
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { SkillsComponentProps, socialSkills } from '../_types'
import {
  jobsExperienceRequest,
  socialSkillsClean,
  socialSkillsRequest,
  userInformationRequest,
} from '../../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import { PostRegisterJobsExpriences } from '../../../../../state/modules/user/services/types'
import Select from 'react-select'
import { colors } from '../../../../../utils/colors'
import { formsStyles } from '../../styles'
import { jobComponentStyles } from '../jobs/styles'

const SkillComponent: FC<SkillsComponentProps> = ({ setShowSkills, setIsConfirmModalOpen }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [skillOptions, setSkillOptions] = useState<any[]>([])
  const [socialSkills, setSocialSkills] = useState<any>([])
  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state?.user)
  const token = localStorage.getItem('access_token')

  useEffect(() => {
    dispatch(socialSkillsRequest({ token }))

    return () => {
      dispatch(socialSkillsClean())
    }
  }, [])

  useEffect(() => {
    const userSkills = userInformation?.SocialSkills

    user?.socialSkillsList?.forEach((skill: any) => {
      const repeat = userSkills.find((item: any) => item.id === skill.id)
      if (!repeat) setSkillOptions((prev: any) => [...prev, skill])
    })
  }, [user])

  const initialValues: socialSkills = {
    socialSkills: [],
  }

  const validateSchema = Yup.object({
    socialSkills: Yup.array().min(1, 'Campo requerido'),
  })

  const handleSubmit = (values: any) => {
    const token = localStorage.getItem('access_token')

    const updatedInitialValuesForm: PostRegisterJobsExpriences = {
      userId: userInformation.id,
      token: token,
      jobsExperiences: [],
      socialSkills: [...values.socialSkills],
    }

    dispatch(jobsExperienceRequest(updatedInitialValuesForm))
    dispatch(userInformationRequest({ userId: userInformation.id }))
    setShowSkills(false)
    setIsConfirmModalOpen(true)
  }
  return (
    <Box
      width={isMobile ? '100%' : '70%'}
      sx={formsStyles.formContainer}
      paddingRight={isMobile ? '0rem' : '2rem'}
    >
      <Button sx={formsStyles.closeButton} alignSelf='end' onClick={() => setShowSkills(false)}>
        <CloseIcon />
      </Button>
      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, errors }) => {
          return (
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Text margin='12px 0' fontSize={'16px'} fontWeight={'bold'}>
                Habilidades sociales
              </Text>
              <FormControl>
                <FormLabel color={colors.principal} htmlFor={'socialSkills'}>
                  Nombre
                </FormLabel>
                <Select
                  placeholder='Seleccione sus habilidades'
                  name={'socialSkills'}
                  isMulti
                  options={skillOptions.map((item: any) => {
                    return { value: item.id, label: item.description }
                  })}
                  onChange={(value: any) => {
                    setSocialSkills(value)
                    setFieldValue(
                      'socialSkills',
                      value.map((item: any) => {
                        return { socialSkillId: item.value }
                      }),
                    )
                  }}
                  value={socialSkills}
                />
                <ErrorMessage
                  name='socialSkills'
                  render={(msg) => <Text sx={jobComponentStyles.error}>{msg}</Text>}
                />
              </FormControl>
              <Button
                sx={formsStyles.saveButton}
                mt={4}
                alignSelf={'center'}
                width={isMobile ? '100%' : 'auto'}
                type='submit'
              >
                Guardar cambios
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default SkillComponent
