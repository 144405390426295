import { ads } from './_ducks'

export const INSTITUTIONS_GET_REQUEST: string = ads.defineType('INSTITUTIONS_GET_REQUEST')
export const INSTITUTIONS_GET_SUCCESS: string = ads.defineType('INSTITUTIONS_GET_SUCCESS')
export const INSTITUTIONS_GET_FAILURE: string = ads.defineType('INSTITUTIONS_GET_FAILURE')
export const INSTITUTIONS_GET_CLEAN: string = ads.defineType('INSTITUTIONS_GET_CLEAN')

export const INSTITUTIONS_COMPLETE_GET_REQUEST: string = ads.defineType(
  'INSTITUTIONS_COMPLETE_GET_REQUEST',
)
export const INSTITUTIONS_COMPLETE_GET_SUCCESS: string = ads.defineType(
  'INSTITUTIONS_COMPLETE_GET_SUCCESS',
)
export const INSTITUTIONS_COMPLETE_GET_FAILURE: string = ads.defineType(
  'INSTITUTIONS_COMPLETE_GET_FAILURE',
)
export const INSTITUTIONS_COMPLETE_GET_CLEAN: string = ads.defineType(
  'INSTITUTIONS_COMPLETE_GET_CLEAN',
)

export const TIMESLOTS_GET_REQUEST: string = ads.defineType('TIMESLOTS_GET_REQUEST')
export const TIMESLOTS_GET_SUCCESS: string = ads.defineType('TIMESLOTS_GET_SUCCESS')
export const TIMESLOTS_GET_FAILURE: string = ads.defineType('TIMESLOTS_GET_FAILURE')
export const TIMESLOTS_GET_CLEAN: string = ads.defineType('TIMESLOTS_GET_CLEAN')

export const JOBTYPES_GET_REQUEST: string = ads.defineType('JOBTYPES_GET_REQUEST')
export const JOBTYPES_GET_SUCCESS: string = ads.defineType('JOBTYPES_GET_SUCCESS')
export const JOBTYPES_GET_FAILURE: string = ads.defineType('JOBTYPES_GET_FAILURE')
export const JOBTYPES_GET_CLEAN: string = ads.defineType('JOBTYPES_GET_CLEAN')

export const NEWJOB_POST_REQUEST: string = ads.defineType('NEWJOB_POST_REQUEST')
export const NEWJOB_POST_SUCCESS: string = ads.defineType('NEWJOB_POST_SUCCESS')
export const NEWJOB_POST_FAILURE: string = ads.defineType('NEWJOB_POST_FAILURE')
export const NEWJOB_POST_CLEAN: string = ads.defineType('NEWJOB_POST_CLEAN')

export const FIND_JOBS_POST_REQUEST: string = ads.defineType('FIND_JOBS_POST_REQUEST')
export const FIND_JOBS_POST_SUCCESS: string = ads.defineType('FIND_JOBS_POST_SUCCESS')
export const FIND_JOBS_POST_FAILURE: string = ads.defineType('FIND_JOBS_POST_FAILURE')
export const FIND_JOBS_POST_CLEAN: string = ads.defineType('FIND_JOBS_POST_CLEAN')

export const EDIT_JOB_PUT_REQUEST: string = ads.defineType('EDIT_JOB_PUT_REQUEST')
export const EDIT_JOB_PUT_SUCCESS: string = ads.defineType('EDIT_JOB_PUT_SUCCESS')
export const EDIT_JOB_PUT_FAILURE: string = ads.defineType('EDIT_JOB_PUT_FAILURE')
export const EDIT_JOB_PUT_CLEAN: string = ads.defineType('EDIT_JOB_PUT_CLEAN')

export const EDIT_JOB_STATUS_PUT_REQUEST: string = ads.defineType('EDIT_JOB_STATUS_PUT_REQUEST')
export const EDIT_JOB_STATUS_PUT_SUCCESS: string = ads.defineType('EDIT_JOB_STATUS_PUT_SUCCESS')
export const EDIT_JOB_STATUS_PUT_FAILURE: string = ads.defineType('EDIT_JOB_STATUS_PUT_FAILURE')
export const EDIT_JOB_STATUS_PUT_CLEAN: string = ads.defineType('EDIT_JOB_STATUS_PUT_CLEAN')

export const JOB_DELETE_REQUEST: string = ads.defineType('JOB_DELETE_REQUEST')
export const JOB_DELETE_SUCCESS: string = ads.defineType('JOB_DELETE_SUCCESS')
export const JOB_DELETE_FAILURE: string = ads.defineType('JOB_DELETE_FAILURE')
export const JOB_DELETE_CLEAN: string = ads.defineType('JOB_DELETE_CLEAN')

export const SEARCH_JOBS_POST_REQUEST: string = ads.defineType('SEARCH_JOBS_POST_REQUEST')
export const SEARCH_JOBS_POST_SUCCESS: string = ads.defineType('SEARCH_JOBS_POST_SUCCESS')
export const SEARCH_JOBS_POST_FAILURE: string = ads.defineType('SEARCH_JOBS_POST_FAILURE')
export const SEARCH_JOBS_POST_CLEAN: string = ads.defineType('SEARCH_JOBS_POST_CLEAN')

export const JOB_APPLICATION_GET_REQUEST: string = ads.defineType('JOB_APPLICATION_GET_REQUEST')
export const JOB_APPLICATION_GET_SUCCESS: string = ads.defineType('JOB_APPLICATION_GET_SUCCESS')
export const JOB_APPLICATION_GET_FAILURE: string = ads.defineType('JOB_APPLICATION_GET_FAILURE')
export const JOB_APPLICATION_GET_CLEAN: string = ads.defineType('JOB_APPLICATION_GET_CLEAN')

export const AD_DETAIL_GET_REQUEST: string = ads.defineType('AD_DETAIL_GET_REQUEST')
export const AD_DETAIL_GET_SUCCESS: string = ads.defineType('AD_DETAIL_GET_SUCCESS')
export const AD_DETAIL_GET_FAILURE: string = ads.defineType('AD_DETAIL_GET_FAILURE')
export const AD_DETAIL_GET_CLEAN: string = ads.defineType('AD_DETAIL_GET_CLEAN')

export const DOWNLOAD_ADS_SPREADSHEET_POST_REQUEST: string = ads.defineType(
  'DOWNLOAD_ADS_SPREADSHEET_POST_REQUEST',
)
export const DOWNLOAD_ADS_SPREADSHEET_POST_SUCCESS: string = ads.defineType(
  'DOWNLOAD_ADS_SPREADSHEET_POST_SUCCESS',
)
export const DOWNLOAD_ADS_SPREADSHEET_POST_FAILURE: string = ads.defineType(
  'DOWNLOAD_ADS_SPREADSHEET_POST_FAILURE',
)
export const DOWNLOAD_ADS_SPREADSHEET_POST_CLEAN: string = ads.defineType(
  'DOWNLOAD_ADS_SPREADSHEET_POST_CLEAN',
)

export const POSITIONS_LIST_GET_REQUEST: string = ads.defineType('POSITIONS_LIST_GET_REQUEST')
export const POSITIONS_LIST_GET_SUCCESS: string = ads.defineType('POSITIONS_LIST_GET_SUCCESS')
export const POSITIONS_LIST_GET_FAILURE: string = ads.defineType('POSITIONS_LIST_GET_FAILURE')
export const POSITIONS_LIST_GET_CLEAN: string = ads.defineType('POSITIONS_LIST_GET_CLEAN')

export const AD_STATUS_LIST_GET_REQUEST: string = ads.defineType('AD_STATUS_LIST_GET_REQUEST')
export const AD_STATUS_LIST_GET_SUCCESS: string = ads.defineType('AD_STATUS_LIST_GET_SUCCESS')
export const AD_STATUS_LIST_GET_FAILURE: string = ads.defineType('AD_STATUS_LIST_GET_FAILURE')
export const AD_STATUS_LIST_GET_CLEAN: string = ads.defineType('AD_STATUS_LIST_GET_CLEAN')

export const AD_FEATURED_LIST_GET_REQUEST: string = ads.defineType('AD_FEATURED_LIST_GET_REQUEST')
export const AD_FEATURED_LIST_GET_SUCCESS: string = ads.defineType('AD_FEATURED_LIST_GET_SUCCESS')
export const AD_FEATURED_LIST_GET_FAILURE: string = ads.defineType('AD_FEATURED_LIST_GET_FAILURE')
export const AD_FEATURED_LIST_GET_CLEAN: string = ads.defineType('AD_FEATURED_LIST_GET_CLEAN')

export const SUBJECTS_LIST_GET_REQUEST: string = ads.defineType('SUBJECTS_LIST_GET_REQUEST')
export const SUBJECTS_LIST_GET_SUCCESS: string = ads.defineType('SUBJECTS_LIST_GET_SUCCESS')
export const SUBJECTS_LIST_GET_FAILURE: string = ads.defineType('SUBJECTS_LIST_GET_FAILURE')
export const SUBJECTS_LIST_GET_CLEAN: string = ads.defineType('SUBJECTS_LIST_GET_CLEAN')

export const DUPLICATE_JOB_POST_REQUEST: string = ads.defineType('DUPLICATE_JOB_POST_REQUEST')
export const DUPLICATE_JOB_POST_SUCCESS: string = ads.defineType('DUPLICATE_JOB_POST_SUCCESS')
export const DUPLICATE_JOB_POST_FAILURE: string = ads.defineType('DUPLICATE_JOB_POST_FAILURE')
export const DUPLICATE_JOB_POST_CLEAN: string = ads.defineType('DUPLICATE_JOB_POST_CLEAN')

export const REFERENCE_NUMBERS_GET_REQUEST: string = ads.defineType('REFERENCE_NUMBERS_GET_REQUEST')
export const REFERENCE_NUMBERS_GET_SUCCESS: string = ads.defineType('REFERENCE_NUMBERS_GET_SUCCESS')
export const REFERENCE_NUMBERS_GET_FAILURE: string = ads.defineType('REFERENCE_NUMBERS_GET_FAILURE')
export const REFERENCE_NUMBERS_GET_CLEAN: string = ads.defineType('REFERENCE_NUMBERS_GET_CLEAN')

export const AD_LAST_TEN_GET_REQUEST: string = ads.defineType('AD_LAST_TEN_GET_REQUEST')
export const AD_LAST_TEN_GET_SUCCESS: string = ads.defineType('AD_LAST_TEN_GET_SUCCESS')
export const AD_LAST_TEN_GET_FAILURE: string = ads.defineType('AD_LAST_TEN_GET_FAILURE')
export const AD_LAST_TEN_GET_CLEAN: string = ads.defineType('AD_LAST_TEN_GET_CLEAN')
