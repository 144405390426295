import { Box, Heading, Text, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  downloadAdsSpreadsheetClean,
  jobApplicationClean,
  searchJobsClean,
  searchJobsRequest,
} from '../../state/modules/ads/actions'
import { useDispatch, useSelector } from 'react-redux'

import AdCard from './AdCard'
import AdsSearchFilter from './AdsSearchFilter'
import Loader from '../loader'
import Paginator from '../Paginator'
import { SearchFilter } from './AdsSearchFilter/_types'
import { adsSearchStyles } from './styles'

const AdsSearch = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const ads = useSelector((state: any) => state?.ads)
  const searchedJobs = useSelector((state: any) => state?.ads?.searchedJobs)
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const initialFilters: SearchFilter = {
    referenceNumber: null,
    title: '',
    institutionName: '',
    zone: { provinceId: [], departmentId: [], localityId: [] },
    level: [],
    jobPosition: [],
    suplencies: [],
    timeSlot: [],
    jobType: null,
    subjects: [],
    characteristics: [],
    modalities: [],
    positionType: '',
  }
  const [filters, setFilters] = useState(initialFilters)
  const toast = useToast()
  const toastDuration = 2000

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    dispatch(searchJobsRequest({ token, page: currentPage, filters }))
    return () => {
      dispatch(searchJobsClean())
    }
  }, [currentPage, filters, ads?.jobApplicationSuccess])

  useEffect(() => {
    if (ads?.jobApplicationSuccess && ads?.jobApplicationSuccess !== null) {
      toast({
        description: ads?.jobApplicationStatus.message,
        status: 'success',
        duration: toastDuration,
      })

      dispatch(jobApplicationClean())
    }

    if (ads?.jobApplicationSuccess === false) {
      toast({
        description: ads?.jobApplicationStatus.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })

      dispatch(jobApplicationClean())
    }
  }, [ads?.jobApplicationSuccess])

  useEffect(() => {
    if (ads?.downloadAdsSpreadsheetSuccess && ads?.downloadAdsSpreadsheetSuccess !== null) {
      const link = document.createElement('a')
      const myBlob = ads?.downloadAdsSpreadsheetStatus
      link.href = URL.createObjectURL(myBlob)
      link.download = `Export_avisos_${new Date().toLocaleDateString('en-US')}.xlsx`
      link.click()
      toast({
        description: 'Planilla descargada correctamente',
        status: 'success',
        duration: toastDuration,
      })

      dispatch(downloadAdsSpreadsheetClean())
    }

    if (ads?.downloadAdsSpreadsheetSuccess === false) {
      toast({
        description: ads?.downloadAdsSpreadsheetStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(downloadAdsSpreadsheetClean())
    }
  }, [ads?.downloadAdsSpreadsheetSuccess])

  return (
    <Box sx={isMobile ? adsSearchStyles.containerMobile : adsSearchStyles.container}>
      <Heading sx={adsSearchStyles.heading}>Búsqueda de avisos</Heading>
      <Box sx={isMobile ? adsSearchStyles.adsSearchMobile : adsSearchStyles.adsSearch}>
        <AdsSearchFilter
          setCurrentPage={setCurrentPage}
          initialFilters={initialFilters}
          setFilters={setFilters}
          filters={filters}
        />
      </Box>

      {searchedJobs?.count > 0 && (
        <Box sx={adsSearchStyles.totalAds}>
          {searchedJobs.count} {searchedJobs.count > 1 ? 'Avisos' : 'Aviso'}
        </Box>
      )}

      {searchedJobs ? (
        <Box sx={isMobile ? adsSearchStyles.adsListMobile : adsSearchStyles.adsList}>
          {searchedJobs?.count > 0 ? (
            <>
              {searchedJobs?.rows?.map((job: any) => (
                <AdCard key={job.id} job={job} />
              ))}
              <Paginator
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                elements={searchedJobs?.rows}
                elementsLength={searchedJobs?.count}
                elementsPerpage={10}
                resultsDescription='Avisos'
              />
            </>
          ) : (
            <Box sx={adsSearchStyles.noResultsContainer}>
              <Text sx={adsSearchStyles.noResults}>
                *No existen avisos que coincidan con la búsqueda
              </Text>
            </Box>
          )}
        </Box>
      ) : (
        <Loader props={adsSearchStyles.loading} />
      )}
    </Box>
  )
}

export default AdsSearch
