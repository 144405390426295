import { useMediaQuery } from '@chakra-ui/react'
import LandingLayout from '../../layouts/landing'
import RegisterCard from '../../components/register/card'
import RegisterInstitutionCompo from '../../components/register/institution'

const RegisterIntitution = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  return (
    <LandingLayout>
      {isMobile ? (
        <RegisterInstitutionCompo />
      ) : (
        <RegisterCard>
          <RegisterInstitutionCompo />
        </RegisterCard>
      )}
    </LandingLayout>
  )
}

export default RegisterIntitution
