import { Box, Heading, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  duplicateJobClean,
  editJobClean,
  editJobStatusClean,
  findJobsClean,
  findJobsRequest,
  institutionsClean,
  institutionsCompleteClean,
  institutionsCompleteRequest,
  institutionsRequest,
  jobDeleteClean,
  jobTypesClean,
  jobTypesRequest,
  positionsRequest,
  subjectsRequest,
  subjectsSuccess,
  timeSlotsClean,
  timeSlotsRequest,
} from '../../state/modules/ads/actions'
import { suplenciesClean, suplenciesRequest } from '../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux/'

import { Filters } from './JobsFilter/_types'
import JobsFilter from './JobsFilter'
import JobsModal from './JobsModal'
import JobsTable from './JobsTable'
import { Order } from './_types'
import { jobsStyles } from './styles'

const Jobs: React.FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [action, setAction] = useState<string>('')
  const [currentAd, setCurrentAd] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filters, setFilters] = useState<Filters>({
    cue: null,
    referenceNumber: null,
    title: '',
    positionType: null,
    position: [],
  })
  const [order, setOrder] = useState<Order>({ field: 'referenceNumber', criteria: 'DESC' })

  const userId = useSelector((state: any) => state?.user?.userInformation?.id)
  const ads = useSelector((state: any) => state?.ads)
  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    const filterValues = {
      cue: filters.cue,
      referenceNumber: filters.referenceNumber,
      title: filters.title,
      positionType: filters.positionType,
      position: filters.position,
    }

    if (userId !== null && userId !== undefined) {
      dispatch(
        findJobsRequest({
          token,
          filters: filterValues,
          order,
          userId,
          page: currentPage,
          size: 10,
        }),
      )
      dispatch(institutionsRequest({ token, userId }))
      dispatch(institutionsCompleteRequest({ token, userId, all: true }))

      dispatch(timeSlotsRequest({ token }))
      dispatch(jobTypesRequest({ token }))
      dispatch(positionsRequest({ token }))
      dispatch(subjectsRequest({ token }))
      dispatch(suplenciesRequest({ token }))

      return () => {
        dispatch(findJobsClean())
        dispatch(institutionsClean())
        dispatch(institutionsCompleteClean())
        dispatch(timeSlotsClean())
        dispatch(jobTypesClean())
        dispatch(suplenciesClean())
      }
    }
  }, [
    filters,
    userId,
    ads?.editJobStateSuccess,
    ads?.deleteJobSuccess,
    ads?.duplicateJobSuccess,
    ads?.editJobSuccess,
    order,
    currentPage,
  ])

  useEffect(() => {
    if (ads?.editJobStateSuccess) {
      toast({
        description: ads?.editJobStateStatus?.msg,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(editJobStatusClean())
    }
    if (ads?.editJobStateSuccess === false) {
      toast({
        description: ads?.editJobStateStatus.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(editJobStatusClean())
    }
  }, [ads?.editJobStateSuccess])

  useEffect(() => {
    if (ads?.deleteJobSuccess) {
      toast({
        description: ads?.deleteJobStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(jobDeleteClean())
      setShowModal(false)
    }
    if (ads?.deleteJobSuccess === false) {
      toast({
        description: ads?.deleteJobStatus.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(jobDeleteClean())
    }
  }, [ads?.deleteJobSuccess])

  useEffect(() => {
    if (ads?.duplicateJobSuccess) {
      toast({
        description: ads?.duplicateJobStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(duplicateJobClean())
      setShowModal(false)
    }
    if (ads?.duplicateJobSuccess === false) {
      toast({
        description: ads?.duplicateJobStatus.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(duplicateJobClean())
    }
  }, [ads?.duplicateJobSuccess])

  useEffect(() => {
    if (ads?.editJobSuccess) {
      toast({
        description: ads?.editJobStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(editJobClean())
      setTimeout(() => {
        setShowModal(false)
      }, 1000)
    }
    if (ads?.editJobSuccess === false) {
      toast({
        description: ads?.editJobStatus.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(editJobClean())
    }
  }, [ads?.editJobSuccess])

  const handleShow = (option: string) => {
    setAction(option)
    setShowModal(true)
  }
  return (
    <Box sx={isMobile ? jobsStyles.containerMobile : jobsStyles.container}>
      <Heading sx={jobsStyles.heading}>Gestionar avisos</Heading>
      <Box sx={isMobile ? jobsStyles.jobsMobile : jobsStyles.jobs}>
        <JobsModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          currentAd={currentAd}
          action={action}
        />
        <JobsFilter setFilters={setFilters} filters={filters} setCurrentPage={setCurrentPage} />
        <JobsTable
          order={order}
          setOrder={setOrder}
          handleShow={handleShow}
          setCurrentAd={setCurrentAd}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>
    </Box>
  )
}

export default Jobs
