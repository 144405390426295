import { colors } from '../../../../utils/colors'

export const experienceTableStyles = {
  tablesContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  tableHeading: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '14px',
    marginBottom: '16px',
  },
  tableHead: {
    color: colors.principal,
    fontSize: '11px',
    padding: '8px',
    textWrap: 'wrap',
  },
  tableData: {
    color: colors.black,
    fontSize: '11px',
    padding: '20px 8px',
    textWrap: 'wrap',
  },
  loader: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    width: '80%',
    height: '30vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '9px',
    rowGap: '4px',
  },
  actionButton: {
    backgroundColor: 'transparent',
    padding: 0,
    minWidth: '30px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
