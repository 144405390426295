import { textDecoration } from '@chakra-ui/react'
import { colors } from '../../utils/colors'

export const headerStyles = {
  menuButton: { color: colors.institutionalRed, fontSize: '14px' },

  menu: {
    color: colors.institutionalRed,
    fontSize: '14px',
  },
  menuItem: {},
  myInstitutionsSelect: {
    border: 'none !important',
    fontSize: '14px',
    color: colors.institutionalRed,
    background: 'transparent',
  },
  myInstitutionsSelectOption: { color: colors.institutionalRed },
  navbarButton: {
    fontSize: '14px',
    fontWeight: '200',
    createAccount: {
      backgroundColor: colors.institutionalRed,
      color: '#fff',
      '&:hover': {
        backgroundColor: colors.institutionalRed
      }
    },
    login: {
      border: `solid 2px ${colors.institutionalRed}`,
      color: colors.institutionalRed,
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff'
      }
    }
  },
  customLink: {
    // color: colors.principal,
    fontFamily: 'Montserrat',
    fontWeight: 600,
    textTransform: 'uppercase',
    textDecoration: 'none'
  },
  customSubLink: {
    // color: colors.principal,
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontWeight: 500,
    textTransform: 'capitalize',
    textDecoration: 'none'
  },
  actionButton: {
    fontFamily: 'Montserrat',
    border: `solid 1px ${colors.institutionalRed}`,
    borderRadius: '6px',
    backgroundColor: '#fff',
    color: colors.institutionalRed,
    fontSize: '14px',
  },
  gearButton: {
    borderRadius: '6px',
    color: '#fff',
    padding: '14px',
    backgroundColor: colors.institutionalRed,
    fontSize: '18px',
  }
}
