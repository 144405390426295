import { all, fork } from 'redux-saga/effects'

import { SagaIterator } from 'redux-saga'
import { sagas as abmSagas } from './modules/abm/sagas'
import { sagas as adsSagas } from './modules/ads/sagas'
import { sagas as contactSagas } from './modules/contact/sagas'
import { sagas as favoritesSagas } from './modules/favorites/sagas'
import { sagas as jobApplicationsSagas } from './modules/jobApplications/sagas'
import { sagas as listSagas } from './modules/list/sagas'
import { sagas as loginSagas } from './modules/login/sagas'
import { sagas as notificationsSagas } from './modules/notifications/sagas'
import { sagas as profilesSagas } from './modules/profiles/sagas'
import { sagas as userSagas } from './modules/user/sagas'

// Sagas

const allSagas = [
  ...listSagas,
  ...loginSagas,
  ...userSagas,
  ...abmSagas,
  ...adsSagas,
  ...profilesSagas,
  ...contactSagas,
  ...jobApplicationsSagas,
  ...notificationsSagas,
  ...favoritesSagas,
]

export default function* rootSaga(): SagaIterator {
  yield all(allSagas.map((saga) => fork(saga)))
}
