import { colors } from '../../../utils/colors'

export const jobsTableStyles = {
  container: { marginTop: '16px', width: '100%' },
  table: {},
  tableHeadTd: { display: 'flex' },

  tableHead: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: colors.principal,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'initial',
    textWrap: 'wrap',
  },
  tableHeadAction: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: colors.principal,
    textTransform: 'initial',
  },
  tableHeadText: { cursor: 'pointer' },
  tableData: { fontSize: '12px' },
  actionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '15%',
  },
  badge: {
    textTransform: 'capitalize',
    padding: '4px 8px',
    fontSize: '10px',
    borderRadius: '5px',
  },
  chevronIcon: { color: colors.principal, marginLeft: '5px', cursor: 'pointer' },
  loading: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableDataTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  noResultsContainer: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    textAlign: 'center',
    padding: '20px 0px',
    fontWeight: 'bold',
  },
}
