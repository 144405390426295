import { TableContainer } from '@chakra-ui/react'
import { colors } from '../../../../../../utils/colors'

export const preferencesDataShowStyles = {
  tableContainer: {
    width: '90%',
    margin: '0 auto',
  },
  tHead: { fontSize: '11px', padding: '8px', color: colors.principal, textWrap: 'wrap' },
  tData: {
    fontSize: '11px',
    padding: '8px',
  },

  deleteButton: { cursor: 'pointer', boxSize: 3, color: colors.principal },
  noResults: {
    width: '80%',
    height: '15vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
