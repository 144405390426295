import { notifications } from './_ducks'

export const USER_EMAIL_NOTIFICATION_GET_REQUEST: string = notifications.defineType(
  'USER_EMAIL_NOTIFICATION_GET_REQUEST',
)
export const USER_EMAIL_NOTIFICATION_GET_SUCCESS: string = notifications.defineType(
  'USER_EMAIL_NOTIFICATION_GET_SUCCESS',
)
export const USER_EMAIL_NOTIFICATION_GET_FAILURE: string = notifications.defineType(
  'USER_EMAIL_NOTIFICATION_GET_FAILURE',
)
export const USER_EMAIL_NOTIFICATION_GET_CLEAN: string = notifications.defineType(
  'USER_EMAIL_NOTIFICATION_GET_CLEAN',
)

export const EDIT_USER_EMAIL_NOTIFICATION_POST_REQUEST: string = notifications.defineType(
  'EDIT_USER_EMAIL_NOTIFICATION_POST_REQUEST',
)
export const EDIT_USER_EMAIL_NOTIFICATION_POST_SUCCESS: string = notifications.defineType(
  'EDIT_USER_EMAIL_NOTIFICATION_POST_SUCCESS',
)
export const EDIT_USER_EMAIL_NOTIFICATION_POST_FAILURE: string = notifications.defineType(
  'EDIT_USER_EMAIL_NOTIFICATION_POST_FAILURE',
)
export const EDIT_USER_EMAIL_NOTIFICATION_POST_CLEAN: string = notifications.defineType(
  'EDIT_USER_EMAIL_NOTIFICATION_POST_CLEAN',
)

export const EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_REQUEST: string = notifications.defineType(
  'EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_REQUEST',
)
export const EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_SUCCESS: string = notifications.defineType(
  'EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_SUCCESS',
)
export const EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_FAILURE: string = notifications.defineType(
  'EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_FAILURE',
)
export const EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_CLEAN: string = notifications.defineType(
  'EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_CLEAN',
)
