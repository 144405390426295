import * as types from '../types'

import {
  addFolderFailure,
  addFolderSuccess,
  deleteFolderFailure,
  deleteFolderSuccess,
  favoriteNoteFailure,
  favoriteNoteSuccess,
  favoriteUpdateFailure,
  favoriteUpdateSuccess,
  getFavoritesByFolderFailure,
  getFavoritesByFolderSuccess,
  getFoldersFailure,
  getFoldersSuccess,
  renameFolderFailure,
  renameFolderSuccess,
} from '../actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteFolderService,
  getFavoritesByFolderService,
  getFoldersListService,
  postAddFolderService,
  postFavoriteUpdateService,
  putFavoriteNoteService,
  renameFolderService,
} from '../service'

import { SagaIterator } from 'redux-saga'

function* addFolderWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postAddFolderService, payload)

    yield put(addFolderSuccess(data))
  } catch (error) {
    yield put(addFolderFailure(error))
  }
}
export function* addFolderWatcher(): SagaIterator {
  yield takeLatest(types.ADD_FOLDER_POST_REQUEST, addFolderWorker)
}

function* deleteFolderWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(deleteFolderService, payload)

    yield put(deleteFolderSuccess(data))
  } catch (error) {
    yield put(deleteFolderFailure(error))
  }
}
export function* deleteFolderWatcher(): SagaIterator {
  yield takeLatest(types.FOLDER_DELETE_REQUEST, deleteFolderWorker)
}

function* renameFolderWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(renameFolderService, payload)

    yield put(renameFolderSuccess(data))
  } catch (error) {
    yield put(renameFolderFailure(error))
  }
}
export function* renameFolderWatcher(): SagaIterator {
  yield takeLatest(types.RENAME_FOLDER_PUT_REQUEST, renameFolderWorker)
}

function* getFoldersWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getFoldersListService, payload)
    yield put(getFoldersSuccess(data))
  } catch (error) {
    yield put(getFoldersFailure(error))
  }
}
export function* getFoldersWatcher(): SagaIterator {
  yield takeLatest(types.FOLDERS_LIST_GET_REQUEST, getFoldersWorker)
}

function* getFavoritesByFolderWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getFavoritesByFolderService, payload)
    yield put(getFavoritesByFolderSuccess(data))
  } catch (error) {
    yield put(getFavoritesByFolderFailure(error))
  }
}
export function* getFavoritesByFolderWatcher(): SagaIterator {
  yield takeLatest(types.FAVORITES_BY_FOLDER_GET_REQUEST, getFavoritesByFolderWorker)
}

function* favoriteNoteWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(putFavoriteNoteService, payload)

    yield put(favoriteNoteSuccess(data))
  } catch (error) {
    yield put(favoriteNoteFailure(error))
  }
}
export function* favoriteNoteWatcher(): SagaIterator {
  yield takeLatest(types.FAVORITE_NOTE_PUT_REQUEST, favoriteNoteWorker)
}

function* favoriteUpdateWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postFavoriteUpdateService, payload)

    yield put(favoriteUpdateSuccess(data))
  } catch (error) {
    yield put(favoriteUpdateFailure(error))
  }
}
export function* favoriteUpdateWatcher(): SagaIterator {
  yield takeLatest(types.FAVORITE_UPDATE_POST_REQUEST, favoriteUpdateWorker)
}

export const sagas = [
  addFolderWatcher,
  deleteFolderWatcher,
  renameFolderWatcher,
  getFoldersWatcher,
  getFavoritesByFolderWatcher,
  favoriteNoteWatcher,
  favoriteUpdateWatcher,
]
