import { Box, Center, Heading, Stack, Text } from '@chakra-ui/layout'
import { Image, Flex, Button, Img } from '@chakra-ui/react'
import anirtex from '../../../../assets/images/landing/brands/anirtex.jpg'
import avalian from '../../../../assets/images/landing/brands/avalian_cobertur_medica.png'
import bancoRoela from '../../../../assets/images/landing/brands/banco_roela.jpg'
import bbva from '../../../../assets/images/landing/brands/bbva_seguros.png'
import galenoSalud from '../../../../assets/images/landing/brands/galeno_salud.png'
import galenoSeguros from '../../../../assets/images/landing/brands/galeno_seguros.png'
import matific from '../../../../assets/images/landing/brands/matific.png'
import matic from '../../../../assets/images/landing/brands/matic.png'
import tintaFresca from '../../../../assets/images/landing/brands/tinta_fresca.png'
import chicosUniforme from '../../../../assets/images/landing/brands/chicos_uniformes.png'
import colegium from '../../../../assets/images/landing/brands/colegium.png'
import fase2 from '../../../../assets/images/landing/brands/fase_2.png'
import educaria from '../../../../assets/images/landing/brands/educaria.png'
import ymcaTurismo from '../../../../assets/images/landing/brands/ymca_turismo.png'
import chevron from '../../../../assets/images/landing/brands/chevron.png'
import { useEffect, useRef, useState } from 'react'
import { LandingMarginProps } from '../../../../pages/landing/regular-landing'
import PropTypes from 'prop-types'
import './styles.css'

const Brands: React.FC<LandingMarginProps> = ({ contPadding }) => {
  const scrollElement = useRef<HTMLInputElement | null>(null)
  const [viewportWidth, setViewportWidth] = useState(0)
  const cardWidth = 200
  const offset = 600

  useEffect(() => {
    function handleResize() {
      setViewportWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setViewportWidth])

  const brands = [
    { id: 1, name: 'avalian', image: avalian },
    { id: 2, name: 'bancoRoela', image: bancoRoela },
    { id: 3, name: 'tintaFresca', image: tintaFresca },
    { id: 4, name: 'bbva', image: bbva },
    { id: 5, name: 'galenoSalud', image: galenoSalud },
    { id: 6, name: 'galenoSeguros', image: galenoSeguros },
    { id: 7, name: 'anirtex', image: anirtex },
    { id: 8, name: 'matific', image: matific },
    { id: 9, name: 'matic', image: matic },
    { id: 10, name: 'chicosUniforme', image: chicosUniforme },
    { id: 11, name: 'colegium', image: colegium },
    { id: 12, name: 'fase2', image: fase2 },
    { id: 13, name: 'educaria', image: educaria },
    { id: 14, name: 'ymcaTurismo', image: ymcaTurismo }
  ]
  const scrollX = (direction: string) => {
    if (scrollElement.current) {
      if (direction === 'previous') {
        scrollElement.current.scrollLeft -= offset
      } else if (direction === 'next') {
        scrollElement.current.scrollLeft += offset
      }
    }
  }

  return (
    <Flex
      flexDirection='column'
      justifyItems='center'
      alignItems='center'
      width={['100vw']}
      flexWrap='wrap'
      overflow='hidden'
      pl={'1.2vw'}
    >
      <Box>
        <Text
          fontSize={['36px', '46px']}
          textAlign={'center'}
          color='#004876'
          mb={['45px', '90px']}
        >
          Nos acompañan
        </Text>
      </Box>

      <Box
        height='280px'
        width='100%'
        position={'relative'}
      >
        <Box
          position='absolute'
          top='43%'
          left='-0.6vw'
        >
          <Box>
            <Button
              bgColor='transparent'
              _hover={{ bg: 'transparent' }}
              onClick={() => scrollX('previous')}
            >
              <Img src={chevron} transform={'rotate(180deg)'} h='74%' w='118%' />
            </Button>
          </Box>
        </Box>

        <Box
          position='absolute'
          top='43%'
          right='-0.1vw'
        >
          <Box>
            <Button
              bgColor='transparent'
              _hover={{ bg: 'transparent' }}
              onClick={() => scrollX('next')}
            >
              <Img src={chevron} height='74%' width='118%' />
            </Button>
          </Box>
        </Box>

        <Flex
          id='brandsCont'
          flexDirection="row"
          gap='20px'
          justifyContent={['center', 'flex-start']}
          alignItems={['center', 'center']}
          width={['100%', '100%']}
          height={['100%', '100%']}
          py='30px'
          ref={scrollElement}
          overflowX='scroll'
          px='21px'
        >
          {brands.map((brand) => {
            return (
              <Flex
                key={brand.id}
                height='200px'
                width={`${cardWidth}px`}
                justifyItems='center'
                alignItems='center'
                bgColor='#fff'
                boxShadow='0px 0px 52.5px 6px rgba(0, 0, 0, 0.09)'
                borderRadius='12px'
                p='32px'
              >
                <Box
                  height='50px'
                  width='150px'
                  bgImage={brand.image}
                  bgPosition={'center center'}
                  bgSize={['contain', 'contain']}
                  bgRepeat='no-repeat'
                ></Box>
              </Flex>
            )
          })}
        </Flex>
      </Box>
    </Flex>
  )
}
Brands.propTypes = {
  contPadding: PropTypes.string.isRequired,
}
export default Brands

