import * as Yup from 'yup'

import {
  ApplicationsDataField,
  ApplicationsHandlerFilterProps,
  InstitutionData,
  Position,
} from './_types'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import {
  adStatusClean,
  adStatusRequest,
  positionsClean,
  positionsRequest,
  referenceNumbersClean,
  referenceNumbersRequest,
  adFeaturedRequest,
  adFeaturedClean
} from '../../../state/modules/ads/actions'
import {
  applicationsHandlerFields,
  filterSvg,
  mobilityOptions,
  positionTypeOptions,
  retiredOptions,
  suplenciesOptions,
  teacherGraduatedOptions,
} from '../../../utils/jobApplications'
import {
  institutionTypesClean,
  institutionTypesRequest,
  languagesClean,
  languagesRequest,
  levelsClean,
  levelsRequest,
  suplenciesClean,
  suplenciesRequest,
} from '../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import { CloseIcon } from '@chakra-ui/icons'
import Select from 'react-select'
import { applicationsHandlerFilterStyles } from './styles'
import { colors } from '../../../utils/colors'
import { downloadApplicationsSheetRequest } from '../../../state/modules/jobApplications/actions'

const ApplicationsHandlerFilter: FC<ApplicationsHandlerFilterProps> = ({
  filters,
  setFilters,
  setCurrentPage,
  isDownloading,
  setIsDownloading,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const token = localStorage.getItem('access_token') ?? ''

  const institutions = useSelector((state: any) => state?.ads?.institutionsCompleteList)
  const handleApplicationList = useSelector(
    (state: any) => state?.jobApplications?.handleApplicationsList,
  )
  const user = useSelector((state: any) => state?.user)
  const ads = useSelector((state: any) => state?.ads)
  const dispatch = useDispatch()
  const [showFilters, setShowFilters] = useState<boolean>(true)
  const [isClean, setIsClean] = useState<boolean>(true)

  const [referenceNumber, setReferenceNumber] = useState<any>(null)
  const [cueOptions, setCueOptions] = useState<any[]>([])
  const [cues, setCues] = React.useState<any>(null)
  const [levelOptions, setLevelOptions] = useState<any[]>([])
  const [levels, setLevels] = useState<any[]>([])
  const [institutionTypeOptions, setInstitutionTypeOptions] = useState<any[]>([])
  const [institutionTypes, setInstitutionTypes] = useState<any[]>([])
  const [adStatusOptions, setAdStatusOptions] = useState<any[]>([])
  const [adStatus, setAdStatus] = useState<any>({ value: '1', label: 'Activo' })
  const [positionType, setPositionType] = useState<any>(null)
  const [positionOptions, setPositionOptions] = useState<any[]>([])
  const [position, setPosition] = useState<any[]>([])
  const [teacherGraduated, setTeacherGraduated] = useState<any>(null)
  const [suplencies, setSuplencies] = useState<any>(null)
  const [mobility, setMobility] = useState<any>(null)
  const [languages, setLanguages] = useState<any[]>([])
  const [retired, setRetired] = useState<any>(null)

  useEffect(() => {
    dispatch(institutionTypesRequest({ token }))
    dispatch(levelsRequest({ token }))
    dispatch(positionsRequest({ token }))
    dispatch(adStatusRequest({ token }))
    dispatch(languagesRequest({ token }))
    dispatch(adFeaturedRequest({ token }))

    return () => {
      dispatch(institutionTypesClean())
      dispatch(levelsClean())
      dispatch(positionsClean())
      dispatch(adStatusClean())
      dispatch(languagesClean())
      dispatch(referenceNumbersClean())
      dispatch(adFeaturedClean())
    }
  }, [])

  useEffect(() => {
    if (institutions) {
      setCueOptions([])
      institutions.map((institution: InstitutionData) => {
        const cue = institution?.cue.toString()
        const lastDigits = cue?.slice(-2)
        const firstLength = cue?.length - lastDigits?.length
        const transformedCue = `${cue?.slice(0, firstLength)}-${lastDigits}`
        setCueOptions((prev: any) => [
          ...prev,
          {
            value: institution?.cue,
            label: `${transformedCue} / ${institution?.name}`,
            id: institution?.id,
          },
        ])
      })
    }
  }, [institutions])

  useEffect(() => {
    if (user) {
      setLevelOptions(
        user?.levelsList?.map((level: any) => ({ value: level.id, label: level.description })),
      )
      setInstitutionTypeOptions(
        user?.institutionTypesList?.map((institutionType: any) => ({
          value: institutionType.id,
          label: institutionType.description,
        })),
      )
    }
  }, [user])

  useEffect(() => {
    if (ads) {
      setAdStatusOptions(
        ads?.adStatusList
          ?.filter((adStatus: any) => adStatus.id !== '5')
          .map((adStatus: any) => ({
            value: adStatus.id,
            label: adStatus.description,
          })),
      )
    }
  }, [ads])

  useEffect(() => {
    if (ads) {
      if (positionType?.value === 'DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: Position) => pos.type === 'DOCENTE')
            .map((pos: Position) => {
              return { value: pos.id, label: pos.description }
            }),
        )
      } else if (positionType?.value === 'NO DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: any) => pos.type === 'NO DOCENTE')
            .map((pos: any) => {
              return { value: pos.id, label: pos.description }
            }),
        )
      } else {
        setPositionOptions([])
      }
    }
  }, [ads, positionType])

  useEffect(() => {
    if (cues !== null) dispatch(referenceNumbersRequest({ token, institutionId: cues.id }))
  }, [cues])

  const renderInput = (
    field: ApplicationsDataField,
    setFieldValue?: any,
    handleChange?: any,
    setFieldTouched?: any,
  ) => {
    switch (true) {
      case 'cue' === field.id:
        return (
          <Select
            placeholder='Selecciona tu CUE'
            name={field.id}
            styles={{
              menu: (baseStyles: any) => ({
                ...baseStyles,
              }),
            }}
            options={cueOptions}
            isClearable
            onBlur={() => setFieldTouched(field.id)}
            onChange={(value) => {
              handleSearchCue(value, setFieldValue)
            }}
            value={cues}
          />
        )

      case 'level' === field.id:
        return (
          <Select
            placeholder='Selecciona tu nivel'
            name={field.id}
            styles={{
              menu: (baseStyles) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            isMulti
            options={levelOptions}
            onChange={(value) => {
              handleLevel(value, setFieldValue)
            }}
            value={levels}
          />
        )

      case 'institutionTypes' === field.id:
        return (
          <Select
            placeholder='Selecciona tu modalidad'
            name={field.id}
            styles={{
              menu: (baseStyles) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            isMulti
            options={institutionTypeOptions}
            onChange={(value) => {
              handleInstitutionTypes(value, setFieldValue)
            }}
            value={institutionTypes}
          />
        )

      case 'positionType' === field.id:
        return (
          <Select
            placeholder='Selecciona tu tipo de cargo'
            name={field.id}
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            onBlur={() => setFieldTouched(field.id)}
            options={positionTypeOptions}
            value={positionType}
            onChange={(value: any) => {
              handleChangePositionType(value, setFieldValue)
            }}
          />
        )
      case 'position' === field.id:
        return (
          <Select
            placeholder='Selecciona tu puesto'
            name={field.id}
            options={positionOptions}
            onBlur={() => setFieldTouched(field.id)}
            value={position}
            onChange={(value) => {
              handleChangePosition(value, setFieldValue)
            }}
            isMulti
          />
        )
      case 'adStatus' === field.id:
        return (
          <Select
            placeholder='Selecciona tu estado'
            name={field.id}
            options={adStatusOptions}
            onBlur={() => setFieldTouched(field.id)}
            value={adStatus}
            isClearable
            onChange={(value) => {
              handleChangeStatus(value, setFieldValue)
            }}
          />
        )
      case 'teacherGraduated' === field.id:
        return (
          <Select
            placeholder='Selecciona tu opción'
            name={field.id}
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            options={teacherGraduatedOptions}
            value={teacherGraduated}
            onChange={(value: any) => {
              handleChangeTeacherGraduated(value, setFieldValue)
            }}
          />
        )

      case 'suplencies' === field.id:
        return (
          <Select
            placeholder='Selecciona tu opción'
            name={field.id}
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            value={suplencies}
            options={suplenciesOptions}
            onChange={(value: any) => {
              if (value?.value) {
                setFieldValue(field.id, value.value)
                setSuplencies(value)
              } else {
                setFieldValue(field.id, '')
                setSuplencies(null)
              }
            }}
          />
        )
      case 'mobility' === field.id:
        return (
          <Select
            placeholder='Selecciona tu opción'
            name={field.id}
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            value={mobility}
            options={mobilityOptions}
            onChange={(value: any) => {
              if (value?.value) {
                setFieldValue(field.id, value.value)
                setMobility(value)
              } else {
                setFieldValue(field.id, '')
                setMobility(null)
              }
            }}
          />
        )
      case 'retired' === field.id:
        return (
          <Select
            placeholder='Selecciona tu opción'
            name={field.id}
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            value={retired}
            options={retiredOptions}
            onChange={(value: any) => {
              if (value?.value) {
                setFieldValue(field.id, value.value)
                setRetired(value)
              } else {
                setFieldValue(field.id, '')
                setRetired(null)
              }
            }}
          />
        )
      case 'languages' === field.id:
        return (
          <Select
            placeholder='Selecciona tu opción'
            name={field.id}
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            isMulti
            options={user?.languagesList?.map((e: any) => ({ value: e.id, label: e.description }))}
            value={languages}
            onChange={(value: any) => {
              setFieldValue(
                field.id,
                value.map((e: any) => e.value),
              )
              setLanguages(value.map((e: any) => ({ value: e.value, label: e.label })))
            }}
          />
        )
      case 'referenceNumber' === field.id:
        return (
          <Select
            isClearable
            placeholder='Selecciona tu opción'
            value={referenceNumber}
            options={
              cues === null
                ? []
                : ads?.referenceNumbersListStatus?.referenceNumbers?.map((elem: any) => ({
                    value: elem.referenceNumber,
                    label: elem.referenceNumber,
                  }))
            }
            onBlur={() => setFieldTouched(field.id)}
            onChange={(value) => {
              handleReferenceNumbers(value, setFieldValue)
            }}
          />
        )
      default:
        break
    }
  }

  const handleSearchCue = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.id : ''
    setCues(value)
    setReferenceNumber(null)
    setFieldValue('cue', fieldValue)
    setTimeout(() => {
      setFieldValue('referenceNumber', '')
    }, 100)
  }
  const handleReferenceNumbers = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value?.value : ''
    setReferenceNumber(value)
    setFieldValue('referenceNumber', fieldValue)
  }
  const handleChangePositionType = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.value : ''
    setPositionType(value)
    setFieldValue('positionType', fieldValue)
    setPosition([])
    setTimeout(() => {
      setFieldValue('position', [])
    }, 100)
    setLevels([])
    setTimeout(() => {
      setFieldValue('level', [])
    }, 120)
    setInstitutionTypes([])
    setTimeout(() => {
      setFieldValue('institutionTypes', [])
    }, 140)
  }

  const handleLevel = (value: any, setFieldValue: any) => {
    setLevels(value)
    setFieldValue(
      'level',
      value?.map((item: any) => Number(item.value)),
    )
  }

  const handleInstitutionTypes = (value: any, setFieldValue: any) => {
    setInstitutionTypes(value)
    setFieldValue(
      'institutionTypes',
      value.map((item: any) => item.value),
    )
  }

  const handleChangePosition = (value: any, setFieldValue: any) => {
    setPosition(value)
    setFieldValue(
      'position',
      value.map((item: any) => item.value),
    )
  }
  const handleChangeTeacherGraduated = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.value : ''

    setTeacherGraduated(value)
    setFieldValue('teacherGraduated', fieldValue)
  }

  const handleChangeStatus = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.value : ''
    setAdStatus(value)
    setFieldValue('adStatus', fieldValue)
  }

  const initialValues = {
    referenceNumber: '',
    cue: '',
    positionType: '',
    position: [],
    level: [],
    institutionTypes: [],
    adStatus: ads?.adStatusList ? '1' : '',
    teacherGraduated: '',
    languages: [],
    suplencies: '',
    retired: '',
    mobility: '',
  }
  const validateSchema = Yup.object({
    referenceNumber: Yup.string(),
    cue: Yup.string().required('Este campo es obligatorio'),
    positionType: Yup.string(),
    position: Yup.array(),
    level: Yup.array(),
    institutionTypes: Yup.array(),
    adStatus: Yup.string(),
    teacherGraduated: Yup.string(),
    languages: Yup.array(),
    suplencies: Yup.string(),
    mobility: Yup.string(),
    retired: Yup.string(),
  }).defined()
  const handleSubmit = (values: any) => {
    console.log(values)

    const {
      cue,
      adStatus,
      referenceNumber,
      position,
      level,
      teacherGraduated,
      institutionTypes,
      suplencies,
      mobility,
      retired,
      languages,
      ...rest
    } = values

    const updatedValues = {
      institutionId: cue === '' ? null : cue,
      statusId: adStatus === '' ? null : adStatus,
      adReferenceNumber: referenceNumber,
      positions: position,
      levels: level,
      teacherTitle: teacherGraduated,
      modalities: institutionTypes,
      suplency: suplencies === '' ? null : suplencies === 'si' ? true : false,
      retired: retired === '' ? null : retired === 'si' ? true : false,
      mobility: mobility === '' ? null : mobility === 'si' ? true : false,
      languages: languages?.map((e: string) => ({ languageId: e })),
      ...rest,
    }

    setFilters(updatedValues)
    setIsClean(false)
    setCurrentPage(1)
  }

  const handleDownloadSpreadsheet = () => {
    setIsDownloading(true)
    dispatch(
      downloadApplicationsSheetRequest({ token: localStorage.getItem('access_token'), filters }),
    )
  }

  const handleCleanFilters = (resetForm: any) => {
    setReferenceNumber(null)
    setCues(null)
    setAdStatus([])
    setLevels([])
    setInstitutionTypes([])
    setPositionType(null)
    setPosition([])
    setTeacherGraduated(null)
    setSuplencies(null)
    setMobility(null)
    setRetired(null)
    setLanguages([])
    setIsClean(true)
    setFilters(null)
    setCurrentPage(1)
    resetForm()
  }

  return (
    <Box
      sx={
        isMobile
          ? !showFilters
            ? { display: 'flex', justifyContent: 'space-between' }
            : applicationsHandlerFilterStyles.containerMobile
          : applicationsHandlerFilterStyles.container
      }
    >
      {!showFilters ? (
        isMobile ? (
          <>
            <Text color={colors.principal}>Filtros</Text>
            <Icon
              sx={applicationsHandlerFilterStyles.filterIcon}
              viewBox='0 0 28 21'
              onClick={() => setShowFilters(true)}
            >
              <path d={filterSvg.d} fill={filterSvg.fill} />
            </Icon>
          </>
        ) : (
          <Icon
            sx={applicationsHandlerFilterStyles.filterIcon}
            viewBox='0 0 28 21'
            onClick={() => setShowFilters(true)}
          >
            <path d={filterSvg.d} fill={filterSvg.fill} />
          </Icon>
        )
      ) : (
        <CloseIcon
          color={'#004876'}
          sx={applicationsHandlerFilterStyles.filterIcon}
          onClick={() => setShowFilters(false)}
        />
      )}
      {showFilters && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validateSchema}
        >
          {({ setFieldValue, resetForm, handleChange, errors, touched, setFieldTouched }) => {
            const touchedFields = touched as FormikTouched<any>
            const errorFields = errors as FormikErrors<any>

            return (
              <Form>
                <Box
                  sx={
                    isMobile
                      ? applicationsHandlerFilterStyles.formMobile
                      : applicationsHandlerFilterStyles.form
                  }
                >
                  {applicationsHandlerFields
                    .filter((e) => e.isFiltered)
                    .sort((e1, e2) => e1.order - e2.order)
                    .map((field: ApplicationsDataField) => {
                      const isShown =
                        (field.id === 'level' && positionType?.value !== 'DOCENTE') ||
                        (field.id === 'institutionTypes' && positionType?.value !== 'DOCENTE')
                          ? 'none'
                          : 'initial'
                      return (
                        <FormControl
                          display={isShown}
                          sx={
                            isMobile
                              ? applicationsHandlerFilterStyles.inputMobile
                              : applicationsHandlerFilterStyles.input
                          }
                          key={field.order}
                        >
                          <FormLabel>
                            {field.name}
                            {field.required && '*'}
                          </FormLabel>
                          {renderInput(field, setFieldValue, handleChange, setFieldTouched)}
                          {errorFields[field.id] && touchedFields[field.id] && (
                            <Text sx={applicationsHandlerFilterStyles.error}>{`${
                              errorFields[field.id]
                            }`}</Text>
                          )}
                        </FormControl>
                      )
                    })}
                  <Box
                    sx={
                      isMobile
                        ? applicationsHandlerFilterStyles.buttonBoxMobile
                        : applicationsHandlerFilterStyles.buttonBox
                    }
                  >
                    <Button sx={applicationsHandlerFilterStyles.searchButton} type='submit'>
                      Buscar
                    </Button>
                    <Button
                      sx={applicationsHandlerFilterStyles.cleanFilters}
                      isLoading={isDownloading}
                      isDisabled={
                        isClean ||
                        handleApplicationList?.rows?.length === 0 ||
                        !handleApplicationList
                      }
                      onClick={() => handleDownloadSpreadsheet()}
                    >
                      Exportar
                    </Button>
                    <Button
                      sx={applicationsHandlerFilterStyles.cleanFilters}
                      isDisabled={isClean}
                      onClick={() => handleCleanFilters(resetForm)}
                    >
                      Limpiar filtros
                    </Button>
                  </Box>
                </Box>
              </Form>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}

export default ApplicationsHandlerFilter
