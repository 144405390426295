export const editEducationFormStyles = {
  tablesContainer: { width: '80%' },
  tHead: { textTransform: 'initial', fontSize: '11px', padding: '8px', textWrap: 'wrap' },
  tData: {
    fontSize: '11px',
    padding: '8px',
  },
  noResults: {
    width: '80%',
    height: '30vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '9px',
    rowGap: '4px',
  },
}
