import { colors } from '../../../../utils/colors'

export const requestModalStyles = {
  box: { height: 'auto', margin: 10 },
  heading: {
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '35.68px',
    color: colors.principal,
    marginBottom: '18px',
  },
  form: {
    height: '100%',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
  },
  institutionDataHeading: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '35.68px',
  },
  userDataHeading: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '35.68px',
  },
  institutionData: {
    border: `1px solid ${colors.principal}`,
    padding: '20px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: '20px',
  },
  userData: {
    border: `1px solid ${colors.principal}`,
    padding: '20px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: '20px',
  },

  input: { width: '320px' },
  buttonBox: { display: 'flex', justifyContent: 'flex-end', columnGap: '50px' },
  linkBox: { display: 'flex', flexDirection: 'column', marginBottom: '30px', rowGap: '10px' },
  linkTitle: { fontSize: '20px', marginBottom: '5px', fontWeight: 'bold' },
  linkText: { fontSize: '20px', fontWeight: 'bold' },
  link: { fontWeight: 'normal', color: `${colors.principal}` },
  noLinkResults: { textAlign: 'center', padding: '20px 0px' },
}
