import { Text } from '@chakra-ui/react'
import BlueButton from '../../buttons/button-blue'
const baseURL = window._env_.REACT_APP_API_OAUTH_URL

const LoginComenius = () => {
  const loginOauth = () => {
    const authorizeURL = `${baseURL}auth/authorize`;
    window.location.href = authorizeURL;
  }
  return (
    <BlueButton variant='outline' width={'full'} onClick={() => loginOauth()}>
      <Text size={'sm'} fontWeight={'normal'}>
        Ingresar con Comenius
      </Text>
    </BlueButton>
  )
}

export default LoginComenius
