import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Box, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Text } from '@chakra-ui/react'

import { preferenceFields } from '../../../../../../utils/profile'
import { preferencesDataShowStyles } from './styles'

const PreferenceResume = () => {
  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const dataShowFields = preferenceFields.filter((item: any) => !item.id.includes('other'))

  return (
    <>
      {userInformation?.Preferences?.length === 0 ? (
        <Box sx={preferencesDataShowStyles.noResults}>
          <Text>*No existen registros asociados</Text>
        </Box>
      ) : (
        <TableContainer sx={preferencesDataShowStyles.tableContainer}>
          <Table colorScheme='facebook'>
            <Thead>
              <Tr>
                {dataShowFields.map((field) => (
                  <Th
                    key={field.order}
                    textTransform='initial'
                    sx={preferencesDataShowStyles.tHead}
                  >
                    {field.name}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {userInformation?.Preferences?.map((item: any) => {
                const curricular = item?.Subjects?.filter((item: any) => item.type === 'CURRICULAR')
                const extraCurricular = item?.Subjects?.filter(
                  (item: any) => item.type === 'EXTRA-CURRICULAR',
                )
                return (
                  <Tr key={item.id}>
                    <Td sx={preferencesDataShowStyles.tData}>{item?.Positions[0]?.type}</Td>

                    <Td sx={preferencesDataShowStyles.tData}>
                      {item?.Levels?.length === 0
                        ? '-'
                        : item?.Levels?.map((item: any, i: number) => (
                            <Box key={i}>*{item.description}</Box>
                          ))}
                    </Td>

                    <Td sx={preferencesDataShowStyles.tData}>
                      {item?.Characteristics?.length === 0
                        ? '-'
                        : item?.Characteristics?.map((item: any, i: number) => (
                            <Box key={i}>*{item.description}</Box>
                          ))}
                    </Td>

                    <Td sx={preferencesDataShowStyles.tData}>
                      {item?.Modalities?.length === 0
                        ? '-'
                        : item?.Modalities?.map((item: any, i: number) => (
                            <Box key={i}>*{item.description}</Box>
                          ))}
                    </Td>
                    <Td sx={preferencesDataShowStyles.tData}>
                      {item?.Positions?.map((item: any, i: number) => (
                        <Box key={i}>*{item.description}</Box>
                      ))}
                    </Td>

                    <Td sx={preferencesDataShowStyles.tData}>
                      {curricular?.length === 0
                        ? '-'
                        : curricular.map((item: any, i: number) => (
                            <Box key={i}>*{item.description}</Box>
                          ))}
                    </Td>

                    <Td sx={preferencesDataShowStyles.tData}>
                      {extraCurricular?.length === 0
                        ? '-'
                        : extraCurricular.map((item: any, i: number) => (
                            <Box key={i}>*{item.description}</Box>
                          ))}
                    </Td>
                    <Td sx={preferencesDataShowStyles.tData}>
                      {!item?.intendedSalary || item?.intendedSalary === null
                        ? '-'
                        : item?.intendedSalary}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default PreferenceResume
