import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import createRootReducer from './rootReducer'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSagas'

let store: any = null
const middlewares: any[] = []

const sagaMiddleware = createSagaMiddleware()

const loggerMiddleware: any = createLogger({
  collapsed: true,
})

const preloadedState = {
  login: {},
  list: {},
  user: {},
  abm: {},
  ads: {},
  profiles: {},
  contact: {},
  jobApplications: {},
  notifications: {},
  favorites: {},
}

export default function makeStore() {
  if (window._env_.NODE_ENV === 'development') {
    middlewares.push(loggerMiddleware)
  }

  store = configureStore({
    reducer: createRootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(loggerMiddleware, sagaMiddleware),
    preloadedState,
  })

  sagaMiddleware.run(rootSaga)

  return store
}

export { store, makeStore }
