import {
  Box,
  Button,
  Checkbox,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon, QuestionIcon } from '@chakra-ui/icons'
import { FC, useEffect, useState } from 'react'
import {
  addFolderRequest,
  getFoldersClean,
  getFoldersRequest,
} from '../../../state/modules/favorites/actions'
import { useDispatch, useSelector } from 'react-redux'

import EditInput from '../EditInput'
import { FavoritesFoldersModalProps } from './_types'
import Loader from '../../loader'
import { MdOutlineFolder } from 'react-icons/md'
import { colors } from '../../../utils/colors'
import { favoritesFoldersModalStyles } from './styles'

const FavoritesFoldersModal: FC<FavoritesFoldersModalProps> = ({
  isOpen,
  onClose,
  foldersList,
  favoritesList,
  onConfirm,
}) => {
  const [addFolder, setAddFolder] = useState<boolean>(false)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [favoritesArray, setFavoritesArray] = useState<any[]>([])

  const dispatch = useDispatch()

  const loaderStyles = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
  }

  const handleCreateFolder = (name: string) => {
    const token = localStorage.getItem('token') ?? ''
    if (name === '') {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
      dispatch(addFolderRequest({ name, token }))
      setAddFolder(false)
    }
  }

  const handleAddFolder = () => {
    setAddFolder(true)
  }

  const handleFavoriteCheck = (e: any) => {
    const id = e.target.id === 'general' ? null : e.target.id

    const isRepeat = favoritesArray.find((elem: any) => elem.folderId === id)

    if (!isRepeat) {
      setFavoritesArray((prev: any) => [...prev, { folderId: id, isChecked: e.target.checked }])
    } else {
      if (id !== null)
        setFavoritesArray((prev: any) => [
          ...prev.filter((elem: any) => elem.folderId !== null),
          { folderId: null, isChecked: false },
        ])

      setFavoritesArray((prev: any) => [
        ...prev.filter((elem: any) => elem.folderId !== id),
        { folderId: id, isChecked: e.target.checked },
      ])
    }
  }

  useEffect(() => {
    if (isOpen) {
      const generalCheck = favoritesList.find((elem) => elem.folderId === null)

      if (generalCheck)
        setFavoritesArray((prev: any) => {
          return [
            ...prev.filter((elem: any) => elem.folderId !== null),
            { folderId: null, isChecked: true },
          ]
        })
      else
        setFavoritesArray((prev: any) => {
          return [
            ...prev.filter((elem: any) => elem.folderId !== null),
            { folderId: null, isChecked: false },
          ]
        })
    }

    isOpen &&
      foldersList?.forEach((folder: any) => {
        const favoriteCheck = favoritesList.find((elem) => elem.folderId === folder.id)

        if (favoriteCheck)
          setFavoritesArray((prev: any) => [
            ...prev.filter((elem: any) => elem.folderId !== favoriteCheck.folderId),
            { folderId: favoriteCheck.folderId, isChecked: true },
          ])
        else
          setFavoritesArray((prev: any) => [
            ...prev.filter((elem: any) => elem.folderId !== folder.id),
            { folderId: folder.id, isChecked: false },
          ])
      })
  }, [isOpen])

  return (
    <Modal
      scrollBehavior='inside'
      size={['3xl']}
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setAddFolder(false)
      }}
    >
      <ModalOverlay />
      <ModalContent
        p={['20px 20px', '20px 20px', '20px 90px', '20px 40px', '30px 90px', '30px 90px']}
      >
        <Box py={['5px', '5px', '5px', '5px', '5px']}>
          <ModalHeader sx={favoritesFoldersModalStyles.heading} p='0'>
            Guardar perfil en favoritos
          </ModalHeader>
          <Text sx={favoritesFoldersModalStyles.subHeading}>
            Seleccione una o varias carpetas{' '}
          </Text>
        </Box>
        <ModalCloseButton />
        <ModalBody
          p={['10px 0', '10px 0', '10px 0', '10px 0', '10px 0']}
        >
          {foldersList ? (
            <>
              <Box sx={favoritesFoldersModalStyles.folderContainer} px='0'>
                <Box sx={favoritesFoldersModalStyles.folder} px='0'>
                  <Checkbox
                    id={'general'}
                    sx={favoritesFoldersModalStyles.folderCheckbox}
                    _checked={{
                      '& .chakra-checkbox__control': { 
                        backgroundColor: colors.institutionalRed,
                        border:'none'
                      }
                    }}
                    onChange={handleFavoriteCheck}
                    isChecked={
                      favoritesArray.find(
                        (elem: any) => elem.folderId === null && elem.isChecked === true,
                      )
                        ? true
                        : false
                    }
                    visibility={
                      favoritesArray.find(
                        (elem: any) => elem.folderId !== null && elem.isChecked === true,
                      )
                        ? 'hidden'
                        : 'initial'
                    }
                  />
                  <Text sx={favoritesFoldersModalStyles.folderText}>General</Text>
                </Box>
                {foldersList?.map((folder: any) => (
                  <Box key={folder.id} sx={favoritesFoldersModalStyles.folder}>
                    <Checkbox
                      sx={favoritesFoldersModalStyles.folderCheckbox}
                      _checked={{
                        '& .chakra-checkbox__control': { 
                          backgroundColor: colors.institutionalRed,
                          border:'none'
                        },
                      }}
                      id={folder.id}
                      onChange={handleFavoriteCheck}
                      isChecked={
                        favoritesArray.find(
                          (elem: any) => elem.folderId === folder.id && elem.isChecked === true,
                        )
                          ? true
                          : false
                      }
                    />
                    <Text sx={favoritesFoldersModalStyles.folderText}>{folder.name}</Text>
                  </Box>
                ))}
              </Box>
              {addFolder ? (
                <EditInput
                  onConfirm={handleCreateFolder}
                  onClose={() => {
                    setAddFolder(false)
                    setIsEmpty(false)
                  }}
                  setIsEmpty={setIsEmpty}
                  isEmpty={isEmpty}
                />
              ) : (
                <Link sx={favoritesFoldersModalStyles.addLink} onClick={handleAddFolder}>
                  + Agregar carpeta
                </Link>
              )}
            </>
          ) : (
            <Loader props={loaderStyles} size={'lg'} />
          )}
        </ModalBody>
        <ModalFooter
          py={['5px', '5px', '5px', '5px', '5px']}
        >
          <Button
            onClick={() => {
              onConfirm(favoritesArray)
              onClose()
            }}
            sx={favoritesFoldersModalStyles.actionButton}
            _hover={{ bg: colors.institutionalRed }}
            size={'sm'}
          >
            Guardar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FavoritesFoldersModal
