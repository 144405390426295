import * as types from './types'

import {
  ModuleType,
  ModuleTypeDeleteEducation,
  ModuleTypeDeleteJobsExperience,
  ModuleTypeDeleteLanguage,
  ModuleTypeDeletePreference,
  ModuleTypeDeleteSocialSkills,
  ModuleTypeDepartments,
  ModuleTypeDownloadResume,
  ModuleTypeEditEducation,
  ModuleTypeEditJobsExperience,
  ModuleTypeEditLanguage,
  ModuleTypeEditPreference,
  ModuleTypeEmailValidation,
  ModuleTypeEmployeesQuantityList,
  ModuleTypeFileDelete,
  ModuleTypeInstitutionPhoto,
  ModuleTypeInstitutionTypesList,
  ModuleTypeLanguagesList,
  ModuleTypeLevelModalitiesList,
  ModuleTypeLevelsList,
  ModuleTypeLocalities,
  ModuleTypeNationalities,
  ModuleTypePrivateMode,
  ModuleTypeProvinces,
  ModuleTypeRegions,
  ModuleTypeRegisterContactData,
  ModuleTypeRegisterEducation,
  ModuleTypeRegisterGeneralInformation,
  ModuleTypeRegisterInstitutionProfile,
  ModuleTypeRegisterInstitutionalData,
  ModuleTypeRegisterJobsExperience,
  ModuleTypeRegisterOwnData,
  ModuleTypeRegisterPersonalData,
  ModuleTypeRegisterPreferences,
  ModuleTypeSocialSkillsList,
  ModuleTypeStudentsQuantityList,
  ModuleTypeSuplenciesList,
  ModuleTypeTermsAndConditions,
} from './_definitions'

import { PayloadAction } from '../../types'
import { user } from './_duck'

const initialModuleState: ModuleType = {
  hasErrMessage: false,
  userInformation: null,
}

const initialModuleStateGeneralInformation: ModuleTypeRegisterGeneralInformation = {
  hasErrMessage: false,
  generalInformationSuccess: null,
  statusGeneralInformation: null,
}

const initialModuleStatePersonalData: ModuleTypeRegisterPersonalData = {
  hasErrMessage: false,
  personalDataSuccess: null,
  statusPersonalData: null,
}
const initialModuleStateEducation: ModuleTypeRegisterEducation = {
  hasErrMessage: false,
  educationSuccess: null,
  statusEducation: null,
}
const initialModuleStateJobsExperience: ModuleTypeRegisterJobsExperience = {
  hasErrMessage: false,
  jobsExperienceSuccess: null,
  statusJobsExperience: null,
}
const initialModuleStatePreferences: ModuleTypeRegisterPreferences = {
  hasErrMessage: false,
  preferencesSuccess: null,
  statusPreferences: null,
}

const initialModuleStateRegisterInstitutionProfile: ModuleTypeRegisterInstitutionProfile = {
  hasErrMessage: false,
  registerInstitutionSuccess: null,
  statusRegisterInstitution: null,
}

const initialModuleStateEditEducation: ModuleTypeEditEducation = {
  hasErrMessage: false,
  editEducationSuccess: null,
  statusEditEducation: null,
}

const initialModuleStateDeleteEducation: ModuleTypeDeleteEducation = {
  hasErrMessage: false,
  deleteEducationSuccess: null,
  statusDeleteEducation: null,
}

const initialModuleStateLanguagesList: ModuleTypeLanguagesList = {
  hasErrMessage: false,
  languagesList: null,
}

const initialModuleStateEditLanguage: ModuleTypeEditLanguage = {
  hasErrMessage: false,
  editLanguageSuccess: null,
  statusEditLanguage: null,
}

const initialModuleStateDeleteLanguage: ModuleTypeDeleteLanguage = {
  hasErrMessage: false,
  deleteLanguageSuccess: null,
  statusDeleteLanguage: null,
}

//jobs and prefe
const initialModuleStateEditJobExperience: ModuleTypeEditJobsExperience = {
  hasErrMessage: false,
  editJobsExperienceSuccess: null,
  statusEditJobsExperience: null,
}

const initialModuleStateDeleteJobExperience: ModuleTypeDeleteJobsExperience = {
  hasErrMessage: false,
  deleteJobsExperienceSuccess: null,
  statusDeleteJobsExperience: null,
}

const initialModuleStateDeleteSocialSkill: ModuleTypeDeleteSocialSkills = {
  hasErrMessage: false,
  deleteSocialSkillsSuccess: null,
  statusDeleteSocialSkills: null,
}
const initialModuleStateEditPreference: ModuleTypeEditPreference = {
  hasErrMessage: false,
  editPreferenceSuccess: null,
  statusEditPreference: null,
}

const initialModuleStateDeletePreference: ModuleTypeDeletePreference = {
  hasErrMessage: false,
  deletePreferenceSuccess: null,
  statusDeletePreference: null,
}
const initialModuleStateDownloadResume: ModuleTypeDownloadResume = {
  hasErrMessage: false,
  downloadResumeSuccess: null,
  userResume: null,
}

const initialModuleStateProvincesList: ModuleTypeProvinces = {
  hasErrMessage: false,
  provincesList: null,
}
const initialModuleStateDepartmentsList: ModuleTypeDepartments = {
  hasErrMessage: false,
  departmentsList: null,
}
const initialModuleStateLocalitiesList: ModuleTypeLocalities = {
  hasErrMessage: false,
  localitiesList: null,
}
const initialModuleStateRegionsList: ModuleTypeRegions = {
  hasErrMessage: false,
  regionsList: null,
}

const initialModuleStateNationalitiesList: ModuleTypeNationalities = {
  hasErrMessage: false,
  nationalitiesList: null,
}

const initialModuleStatePrivateMode: ModuleTypePrivateMode = {
  hasErrMessage: false,
  privateModeSuccess: null,
  privateMode: null,
}

const initialModuleStateTermsAndConditions: ModuleTypeTermsAndConditions = {
  hasErrMessage: false,
  termsAndConditionsSuccess: null,
  termsAndConditionsStatus: null,
}

const initialModuleStateFileDelete: ModuleTypeFileDelete = {
  hasErrMessage: false,
  fileDeleteSuccess: null,
  fileDeleteStatus: null,
}
const initialModuleStateEmployeesQuantityList: ModuleTypeEmployeesQuantityList = {
  hasErrMessage: false,
  employeesQuantityList: null,
}

const initialModuleStateStudentsQuantityList: ModuleTypeStudentsQuantityList = {
  hasErrMessage: false,
  studentsQuantityList: null,
}

const initialModuleStateLevelsList: ModuleTypeLevelsList = {
  hasErrMessage: false,
  levelsList: null,
}

const initialModuleStateLevelModalitiesList: ModuleTypeLevelModalitiesList = {
  hasErrMessage: false,
  levelModalitiesList: null,
}

const initialModuleStateInstitutionTypesList: ModuleTypeInstitutionTypesList = {
  hasErrMessage: false,
  institutionTypesList: null,
}

const initialModuleStateSocialSkillsList: ModuleTypeSocialSkillsList = {
  hasErrMessage: false,
  socialSkillsList: null,
}

const initialModuleStateSuplenciesList: ModuleTypeSuplenciesList = {
  hasErrMessage: false,
  suplenciesList: null,
}

// post insti

const initialModuleStateInstitutionPhoto: ModuleTypeInstitutionPhoto = {
  hasErrMessage: false,
  institutionPhotoSuccess: null,
  institutionPhoto: null,
}
const initialModuleStateInsitutionalData: ModuleTypeRegisterInstitutionalData = {
  hasErrMessage: false,
  institutionalDataSuccess: null,
  statusInstitutionalData: null,
}
const initialModuleStateOwnData: ModuleTypeRegisterOwnData = {
  hasErrMessage: false,
  ownDataSuccess: null,
  statusOwnData: null,
}
const initialModuleStateContactData: ModuleTypeRegisterContactData = {
  hasErrMessage: false,
  contactDataSuccess: null,
  statusContactData: null,
}

const reducer = user.createReducer(
  {
    [types.USER_INFORMATION_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.USER_INFORMATION_GET_SUCCESS]: (
      state: ModuleType,
      { payload }: PayloadAction,
    ): ModuleType => ({
      ...state,
      userInformation: payload.user[0],
    }),
    [types.USER_INFORMATION_GET_FAILURE]: (state: ModuleType): ModuleType => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.USER_INFORMATION_CLEAN_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      hasErrMessage: false,
      userInformation: null,
    }),
    //profile 1
    [types.GENERAL_INFORMATION_REQUEST]: (
      state: ModuleTypeRegisterGeneralInformation,
    ): ModuleTypeRegisterGeneralInformation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.GENERAL_INFORMATION_SUCCESS]: (
      state: ModuleTypeRegisterGeneralInformation,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterGeneralInformation => ({
      ...state,
      generalInformationSuccess: true,
      statusGeneralInformation: payload,
    }),
    [types.GENERAL_INFORMATION_FAILURE]: (
      state: ModuleTypeRegisterGeneralInformation,
    ): ModuleTypeRegisterGeneralInformation => ({
      ...state,
      hasErrMessage: true,
      generalInformationSuccess: false,
    }),
    [types.GENERAL_INFORMATION_CLEAN]: (
      state: ModuleTypeRegisterGeneralInformation,
    ): ModuleTypeRegisterGeneralInformation => ({
      ...state,
      generalInformationSuccess: null,
    }),
    //profile 2
    [types.PERSONAL_DATA_REQUEST]: (
      state: ModuleTypeRegisterPersonalData,
    ): ModuleTypeRegisterPersonalData => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.PERSONAL_DATA_SUCCESS]: (
      state: ModuleTypeRegisterPersonalData,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterPersonalData => ({
      ...state,
      personalDataSuccess: true,
      statusPersonalData: payload,
    }),
    [types.PERSONAL_DATA_FAILURE]: (
      state: ModuleTypeRegisterPersonalData,
    ): ModuleTypeRegisterPersonalData => ({
      ...state,
      hasErrMessage: true,
      personalDataSuccess: false,
    }),
    [types.PERSONAL_DATA_CLEAN]: (
      state: ModuleTypeRegisterPersonalData,
    ): ModuleTypeRegisterPersonalData => ({
      ...state,
      hasErrMessage: false,
      personalDataSuccess: null,
    }),
    //profile 3
    [types.EDUCATION_REQUEST]: (
      state: ModuleTypeRegisterEducation,
    ): ModuleTypeRegisterEducation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.EDUCATION_SUCCESS]: (
      state: ModuleTypeRegisterEducation,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterEducation => ({
      ...state,
      educationSuccess: true,
      statusEducation: payload,
    }),
    [types.EDUCATION_FAILURE]: (
      state: ModuleTypeRegisterEducation,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterEducation => ({
      ...state,
      hasErrMessage: true,
      educationSuccess: false,
      statusEducation: payload,
    }),
    [types.EDUCATION_CLEAN]: (state: ModuleTypeRegisterEducation): ModuleTypeRegisterEducation => ({
      ...state,
      hasErrMessage: false,
      educationSuccess: null,
      statusEducation: null,
    }),
    //profile 4
    [types.JOBS_EXPERIENCE_REQUEST]: (
      state: ModuleTypeRegisterJobsExperience,
    ): ModuleTypeRegisterJobsExperience => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.JOBS_EXPERIENCE_SUCCESS]: (
      state: ModuleTypeRegisterJobsExperience,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterJobsExperience => ({
      ...state,
      jobsExperienceSuccess: true,
      statusJobsExperience: payload,
    }),
    [types.JOBS_EXPERIENCE_FAILURE]: (
      state: ModuleTypeRegisterJobsExperience,
    ): ModuleTypeRegisterJobsExperience => ({
      ...state,
      hasErrMessage: true,
      jobsExperienceSuccess: false,
    }),
    [types.JOBS_EXPERIENCE_CLEAN]: (
      state: ModuleTypeRegisterJobsExperience,
    ): ModuleTypeRegisterJobsExperience => ({
      ...state,
      jobsExperienceSuccess: null,
    }),
    //profile 5
    [types.PREFERENCE_REQUEST]: (
      state: ModuleTypeRegisterPreferences,
    ): ModuleTypeRegisterPreferences => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.PREFERENCE_SUCCESS]: (
      state: ModuleTypeRegisterPreferences,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterPreferences => ({
      ...state,
      preferencesSuccess: true,
      statusPreferences: payload,
    }),
    [types.PREFERENCE_FAILURE]: (
      state: ModuleTypeRegisterPreferences,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterPreferences => ({
      ...state,
      hasErrMessage: true,
      preferencesSuccess: false,
      statusPreferences: payload,
    }),
    [types.PREFERENCE_CLEAN]: (
      state: ModuleTypeRegisterPreferences,
    ): ModuleTypeRegisterPreferences => ({
      ...state,
      preferencesSuccess: null,
      statusPreferences: null,
    }),
    [types.REGISTER_INSTITUTION_PROFILE_POST_REQUEST]: (
      state: ModuleTypeRegisterInstitutionProfile,
    ): ModuleTypeRegisterInstitutionProfile => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.REGISTER_INSTITUTION_PROFILE_POST_SUCCESS]: (
      state: ModuleTypeRegisterInstitutionProfile,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterInstitutionProfile => ({
      ...state,
      registerInstitutionSuccess: true,
      statusRegisterInstitution: payload,
    }),
    [types.REGISTER_INSTITUTION_PROFILE_POST_FAILURE]: (
      state: ModuleTypeRegisterInstitutionProfile,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterInstitutionProfile => ({
      ...state,
      hasErrMessage: true,
      registerInstitutionSuccess: false,
      statusRegisterInstitution: payload,
    }),
    [types.REGISTER_INSTITUTION_PROFILE_CLEAN]: (
      state: ModuleTypeRegisterInstitutionProfile,
    ): ModuleTypeRegisterInstitutionProfile => ({
      ...state,
      registerInstitutionSuccess: null,
      statusRegisterInstitution: null,
    }),
    [types.VALIDATION_MAIL_REQUEST]: (
      state: ModuleTypeEmailValidation,
    ): ModuleTypeEmailValidation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.VALIDATION_MAIL_SUCCESS]: (
      state: ModuleTypeEmailValidation,
      { payload }: PayloadAction,
    ): ModuleTypeEmailValidation => ({
      ...state,
      emailValidationSuccess: true,
      statusEmailValidation: payload,
    }),
    [types.VALIDATION_MAIL_FAILURE]: (
      state: ModuleTypeEmailValidation,
    ): ModuleTypeEmailValidation => ({
      ...state,
      hasErrMessage: true,
      emailValidationSuccess: false,
    }),
    //edit edu
    [types.EDUCATION_EDIT_REQUEST]: (state: ModuleTypeEditEducation): ModuleTypeEditEducation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.EDUCATION_EDIT_SUCCESS]: (
      state: ModuleTypeEditEducation,
      { payload }: PayloadAction,
    ): ModuleTypeEditEducation => ({
      ...state,
      editEducationSuccess: true,
      statusEditEducation: payload,
    }),
    [types.EDUCATION_EDIT_FAILURE]: (state: ModuleTypeEditEducation): ModuleTypeEditEducation => ({
      ...state,
      hasErrMessage: true,
      editEducationSuccess: false,
    }),
    [types.EDUCATION_EDIT_CLEAN]: (state: ModuleTypeEditEducation): ModuleTypeEditEducation => ({
      ...state,
      editEducationSuccess: null,
    }),
    //dele edu
    [types.EDUCATION_DELETE_REQUEST]: (
      state: ModuleTypeDeleteEducation,
    ): ModuleTypeDeleteEducation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.EDUCATION_DELETE_SUCCESS]: (
      state: ModuleTypeDeleteEducation,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteEducation => ({
      ...state,
      deleteEducationSuccess: true,
      statusDeleteEducation: payload,
    }),
    [types.EDUCATION_DELETE_FAILURE]: (
      state: ModuleTypeDeleteEducation,
    ): ModuleTypeDeleteEducation => ({
      ...state,
      hasErrMessage: true,
      deleteEducationSuccess: false,
    }),
    [types.EDUCATION_DELETE_CLEAN]: (
      state: ModuleTypeDeleteEducation,
    ): ModuleTypeDeleteEducation => ({
      ...state,
      deleteEducationSuccess: null,
    }),
    [types.LANGUAGES_LIST_GET_REQUEST]: (
      state: ModuleTypeLanguagesList,
    ): ModuleTypeLanguagesList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.LANGUAGES_LIST_GET_SUCCESS]: (
      state: ModuleTypeLanguagesList,
      { payload }: PayloadAction,
    ): ModuleTypeLanguagesList => ({
      ...state,
      languagesList: payload.languages,
    }),
    [types.LANGUAGES_LIST_GET_FAILURE]: (
      state: ModuleTypeLanguagesList,
    ): ModuleTypeLanguagesList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.LANGUAGES_LIST_GET_CLEAN]: (
      state: ModuleTypeLanguagesList,
    ): ModuleTypeLanguagesList => ({
      ...state,
      hasErrMessage: false,
      languagesList: null,
    }),
    ////edit lang///
    [types.LANGUAGE_EDIT_REQUEST]: (state: ModuleTypeEditLanguage): ModuleTypeEditLanguage => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.LANGUAGE_EDIT_SUCCESS]: (
      state: ModuleTypeEditLanguage,
      { payload }: PayloadAction,
    ): ModuleTypeEditLanguage => ({
      ...state,
      editLanguageSuccess: true,
      statusEditLanguage: payload,
    }),
    [types.LANGUAGE_EDIT_FAILURE]: (state: ModuleTypeEditLanguage): ModuleTypeEditLanguage => ({
      ...state,
      hasErrMessage: true,
      editLanguageSuccess: false,
    }),
    [types.LANGUAGE_EDIT_CLEAN]: (state: ModuleTypeEditLanguage): ModuleTypeEditLanguage => ({
      ...state,
      editLanguageSuccess: null,
    }),
    //dele lang
    [types.LANGUAGE_DELETE_REQUEST]: (
      state: ModuleTypeDeleteLanguage,
    ): ModuleTypeDeleteLanguage => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.LANGUAGE_DELETE_SUCCESS]: (
      state: ModuleTypeDeleteLanguage,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteLanguage => ({
      ...state,
      deleteLanguageSuccess: true,
      statusDeleteLanguage: payload,
    }),
    [types.LANGUAGE_DELETE_FAILURE]: (
      state: ModuleTypeDeleteLanguage,
    ): ModuleTypeDeleteLanguage => ({
      ...state,
      hasErrMessage: true,
      deleteLanguageSuccess: false,
    }),
    [types.LANGUAGE_DELETE_CLEAN]: (state: ModuleTypeDeleteLanguage): ModuleTypeDeleteLanguage => ({
      ...state,
      deleteLanguageSuccess: null,
    }),

    // josb y preference

    [types.JOBS_EXPERIENCE_EDIT_REQUEST]: (
      state: ModuleTypeEditJobsExperience,
    ): ModuleTypeEditJobsExperience => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.JOBS_EXPERIENCE_EDIT_SUCCESS]: (
      state: ModuleTypeEditJobsExperience,
      { payload }: PayloadAction,
    ): ModuleTypeEditJobsExperience => ({
      ...state,
      editJobsExperienceSuccess: true,
      statusEditJobsExperience: payload,
    }),
    [types.JOBS_EXPERIENCE_EDIT_FAILURE]: (
      state: ModuleTypeEditJobsExperience,
    ): ModuleTypeEditJobsExperience => ({
      ...state,
      hasErrMessage: true,
      editJobsExperienceSuccess: false,
    }),
    [types.JOBS_EXPERIENCE_EDIT_CLEAN]: (
      state: ModuleTypeEditJobsExperience,
    ): ModuleTypeEditJobsExperience => ({
      ...state,
      editJobsExperienceSuccess: null,
    }),
    [types.JOBS_EXPERIENCE_DELETE_REQUEST]: (
      state: ModuleTypeDeleteJobsExperience,
    ): ModuleTypeDeleteJobsExperience => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.JOBS_EXPERIENCE_DELETE_SUCCESS]: (
      state: ModuleTypeDeleteJobsExperience,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteJobsExperience => ({
      ...state,
      deleteJobsExperienceSuccess: true,
      statusDeleteJobsExperience: payload,
    }),
    [types.JOBS_EXPERIENCE_DELETE_FAILURE]: (
      state: ModuleTypeDeleteJobsExperience,
    ): ModuleTypeDeleteJobsExperience => ({
      ...state,
      hasErrMessage: true,
      deleteJobsExperienceSuccess: false,
    }),
    [types.JOBS_EXPERIENCE_DELETE_CLEAN]: (
      state: ModuleTypeDeleteJobsExperience,
    ): ModuleTypeDeleteJobsExperience => ({
      ...state,
      deleteJobsExperienceSuccess: null,
    }),
    // delete social skills
    [types.SOCIAL_SKILL_DELETE_REQUEST]: (
      state: ModuleTypeDeleteSocialSkills,
    ): ModuleTypeDeleteSocialSkills => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.SOCIAL_SKILL_DELETE_SUCCESS]: (
      state: ModuleTypeDeleteSocialSkills,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteSocialSkills => ({
      ...state,
      deleteSocialSkillsSuccess: true,
      statusDeleteSocialSkills: payload,
    }),
    [types.SOCIAL_SKILL_DELETE_FAILURE]: (
      state: ModuleTypeDeleteSocialSkills,
    ): ModuleTypeDeleteSocialSkills => ({
      ...state,
      hasErrMessage: true,
      deleteSocialSkillsSuccess: false,
    }),
    [types.SOCIAL_SKILL_DELETE_CLEAN]: (
      state: ModuleTypeDeleteSocialSkills,
    ): ModuleTypeDeleteSocialSkills => ({
      ...state,
      deleteSocialSkillsSuccess: null,
    }),
    ////edit PReferencias///
    [types.PREFERENCE_EDIT_REQUEST]: (
      state: ModuleTypeEditPreference,
    ): ModuleTypeEditPreference => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.PREFERENCE_EDIT_SUCCESS]: (
      state: ModuleTypeEditPreference,
      { payload }: PayloadAction,
    ): ModuleTypeEditPreference => ({
      ...state,
      editPreferenceSuccess: true,
      statusEditPreference: payload,
    }),
    [types.PREFERENCE_EDIT_FAILURE]: (
      state: ModuleTypeEditPreference,
    ): ModuleTypeEditPreference => ({
      ...state,
      hasErrMessage: true,
      editPreferenceSuccess: false,
    }),
    [types.PREFERENCE_EDIT_CLEAN]: (state: ModuleTypeEditPreference): ModuleTypeEditPreference => ({
      ...state,
      editPreferenceSuccess: null,
    }),
    //dele preference
    [types.PREFERENCE_DELETE_REQUEST]: (
      state: ModuleTypeDeletePreference,
    ): ModuleTypeDeletePreference => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.PREFERENCE_DELETE_SUCCESS]: (
      state: ModuleTypeDeletePreference,
      { payload }: PayloadAction,
    ): ModuleTypeDeletePreference => ({
      ...state,
      deletePreferenceSuccess: true,
      statusDeletePreference: payload,
    }),
    [types.PREFERENCE_DELETE_FAILURE]: (
      state: ModuleTypeDeletePreference,
      { payload }: PayloadAction,
    ): ModuleTypeDeletePreference => ({
      ...state,
      hasErrMessage: true,
      deletePreferenceSuccess: false,
      statusDeletePreference: payload,
    }),
    [types.PREFERENCE_DELETE_CLEAN]: (
      state: ModuleTypeDeletePreference,
    ): ModuleTypeDeletePreference => ({
      ...state,
      hasErrMessage: false,
      deletePreferenceSuccess: null,
      statusDeletePreference: null,
    }),
    // post insti
    [types.INSTITUTIONAL_DATA_REQUEST]: (
      state: ModuleTypeRegisterInstitutionalData,
    ): ModuleTypeRegisterInstitutionalData => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.INSTITUTIONAL_DATA_SUCCESS]: (
      state: ModuleTypeRegisterInstitutionalData,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterInstitutionalData => ({
      ...state,
      institutionalDataSuccess: true,
      statusInstitutionalData: payload,
    }),
    [types.INSTITUTIONAL_DATA_FAILURE]: (
      state: ModuleTypeRegisterInstitutionalData,
    ): ModuleTypeRegisterInstitutionalData => ({
      ...state,
      hasErrMessage: true,
      institutionalDataSuccess: false,
    }),
    [types.INSTITUTIONAL_DATA_CLEAN]: (
      state: ModuleTypeRegisterInstitutionalData,
    ): ModuleTypeRegisterInstitutionalData => ({
      ...state,
      institutionalDataSuccess: null,
    }),
    [types.OWN_DATA_REQUEST]: (state: ModuleTypeRegisterOwnData): ModuleTypeRegisterOwnData => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.OWN_DATA_SUCCESS]: (
      state: ModuleTypeRegisterOwnData,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterOwnData => ({
      ...state,
      ownDataSuccess: true,
      statusOwnData: payload,
    }),
    [types.OWN_DATA_FAILURE]: (state: ModuleTypeRegisterOwnData): ModuleTypeRegisterOwnData => ({
      ...state,
      hasErrMessage: true,
      ownDataSuccess: false,
    }),
    [types.OWN_DATA_CLEAN]: (state: ModuleTypeRegisterOwnData): ModuleTypeRegisterOwnData => ({
      ...state,
      ownDataSuccess: null,
    }),
    [types.CONTACT_DATA_REQUEST]: (
      state: ModuleTypeRegisterContactData,
    ): ModuleTypeRegisterContactData => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.CONTACT_DATA_SUCCESS]: (
      state: ModuleTypeRegisterContactData,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterContactData => ({
      ...state,
      contactDataSuccess: true,
      statusContactData: payload,
    }),
    [types.CONTACT_DATA_FAILURE]: (
      state: ModuleTypeRegisterContactData,
    ): ModuleTypeRegisterContactData => ({
      ...state,
      hasErrMessage: true,
      contactDataSuccess: false,
    }),
    [types.CONTACT_DATA_CLEAN]: (
      state: ModuleTypeRegisterContactData,
    ): ModuleTypeRegisterContactData => ({
      ...state,
      contactDataSuccess: null,
    }),

    [types.RESUME_DOWNLOAD_REQUEST]: (
      state: ModuleTypeDownloadResume,
    ): ModuleTypeDownloadResume => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.RESUME_DOWNLOAD_SUCCESS]: (
      state: ModuleTypeDownloadResume,
      { payload }: PayloadAction,
    ): ModuleTypeDownloadResume => ({
      ...state,
      downloadResumeSuccess: true,
      userResume: payload,
    }),
    [types.RESUME_DOWNLOAD_FAILURE]: (
      state: ModuleTypeDownloadResume,
    ): ModuleTypeDownloadResume => ({
      ...state,
      hasErrMessage: true,
      downloadResumeSuccess: false,
    }),
    [types.RESUME_DOWNLOAD_CLEAN]: (state: ModuleTypeDownloadResume): ModuleTypeDownloadResume => ({
      ...state,
      downloadResumeSuccess: null,
      userResume: null,
    }),
    [types.PROVINCES_GET_REQUEST]: (state: ModuleTypeProvinces): ModuleTypeProvinces => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.PROVINCES_GET_SUCCESS]: (
      state: ModuleTypeProvinces,
      { payload }: PayloadAction,
    ): ModuleTypeProvinces => ({
      ...state,
      provincesList: payload.provinces,
    }),
    [types.PROVINCES_GET_FAILURE]: (state: ModuleTypeProvinces): ModuleTypeProvinces => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.PROVINCES_GET_CLEAN]: (state: ModuleTypeProvinces): ModuleTypeProvinces => ({
      ...state,
      hasErrMessage: false,
      provincesList: null,
    }),
    [types.DEPARTMENTS_GET_REQUEST]: (state: ModuleTypeDepartments): ModuleTypeDepartments => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.DEPARTMENTS_GET_SUCCESS]: (
      state: ModuleTypeDepartments,
      { payload }: PayloadAction,
    ): ModuleTypeDepartments => ({
      ...state,
      departmentsList: payload.departments,
    }),
    [types.DEPARTMENTS_GET_FAILURE]: (state: ModuleTypeDepartments): ModuleTypeDepartments => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.DEPARTMENTS_GET_CLEAN]: (state: ModuleTypeDepartments): ModuleTypeDepartments => ({
      ...state,
      hasErrMessage: false,
      departmentsList: null,
    }),
    [types.LOCALITIES_GET_REQUEST]: (state: ModuleTypeLocalities): ModuleTypeLocalities => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.LOCALITIES_GET_SUCCESS]: (
      state: ModuleTypeLocalities,
      { payload }: PayloadAction,
    ): ModuleTypeLocalities => ({
      ...state,
      localitiesList: payload.localities,
    }),
    [types.LOCALITIES_GET_FAILURE]: (state: ModuleTypeLocalities): ModuleTypeLocalities => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.LOCALITIES_GET_CLEAN]: (state: ModuleTypeLocalities): ModuleTypeLocalities => ({
      ...state,
      hasErrMessage: false,
      localitiesList: null,
    }),
    [types.REGIONS_GET_REQUEST]: (state: ModuleTypeRegions): ModuleTypeRegions => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.REGIONS_GET_SUCCESS]: (
      state: ModuleTypeRegions,
      { payload }: PayloadAction,
    ): ModuleTypeRegions => ({
      ...state,
      regionsList: payload.regions,
    }),
    [types.REGIONS_GET_FAILURE]: (state: ModuleTypeRegions): ModuleTypeRegions => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.REGIONS_GET_CLEAN]: (state: ModuleTypeRegions): ModuleTypeRegions => ({
      ...state,
      hasErrMessage: false,
      regionsList: null,
    }),
    [types.NATIONALITIES_GET_REQUEST]: (
      state: ModuleTypeNationalities,
    ): ModuleTypeNationalities => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.NATIONALITIES_GET_SUCCESS]: (
      state: ModuleTypeNationalities,
      { payload }: PayloadAction,
    ): ModuleTypeNationalities => ({
      ...state,
      nationalitiesList: payload.nationalities,
    }),
    [types.NATIONALITIES_GET_FAILURE]: (
      state: ModuleTypeNationalities,
    ): ModuleTypeNationalities => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.NATIONALITIES_GET_CLEAN]: (state: ModuleTypeNationalities): ModuleTypeNationalities => ({
      ...state,
      hasErrMessage: false,
      nationalitiesList: null,
    }),
    [types.PRIVATE_MODE_GET_REQUEST]: (state: ModuleTypePrivateMode): ModuleTypePrivateMode => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.PRIVATE_MODE_GET_SUCCESS]: (
      state: ModuleTypePrivateMode,
      { payload }: PayloadAction,
    ): ModuleTypePrivateMode => ({
      ...state,
      privateModeSuccess: true,
      privateMode: payload,
    }),
    [types.PRIVATE_MODE_GET_FAILURE]: (
      state: ModuleTypePrivateMode,
      { payload }: PayloadAction,
    ): ModuleTypePrivateMode => ({
      ...state,
      hasErrMessage: true,
      privateModeSuccess: false,
      privateMode: payload,
    }),
    [types.PRIVATE_MODE_GET_CLEAN]: (state: ModuleTypePrivateMode): ModuleTypePrivateMode => ({
      ...state,
      privateModeSuccess: null,
      privateMode: null,
    }),
    [types.INSTITUTION_PHOTO_POST_REQUEST]: (
      state: ModuleTypeInstitutionPhoto,
    ): ModuleTypeInstitutionPhoto => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.INSTITUTION_PHOTO_POST_SUCCESS]: (
      state: ModuleTypeInstitutionPhoto,
      { payload }: PayloadAction,
    ): ModuleTypeInstitutionPhoto => ({
      ...state,
      hasErrMessage: false,
      institutionPhotoSuccess: true,
      institutionPhoto: payload,
    }),
    [types.INSTITUTION_PHOTO_POST_FAILURE]: (
      state: ModuleTypeInstitutionPhoto,
      { payload }: PayloadAction,
    ): ModuleTypeInstitutionPhoto => ({
      ...state,
      hasErrMessage: true,
      institutionPhotoSuccess: false,
      institutionPhoto: payload,
    }),
    [types.INSTITUTION_PHOTO_POST_CLEAN]: (
      state: ModuleTypeInstitutionPhoto,
    ): ModuleTypeInstitutionPhoto => ({
      ...state,
      hasErrMessage: false,
      institutionPhotoSuccess: null,
      institutionPhoto: null,
    }),
    [types.TERMS_AND_CONDITIONS_GET_REQUEST]: (
      state: ModuleTypeTermsAndConditions,
    ): ModuleTypeTermsAndConditions => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.TERMS_AND_CONDITIONS_GET_SUCCESS]: (
      state: ModuleTypeTermsAndConditions,
      { payload }: PayloadAction,
    ): ModuleTypeTermsAndConditions => ({
      ...state,
      termsAndConditionsSuccess: true,
      termsAndConditionsStatus: payload,
    }),
    [types.TERMS_AND_CONDITIONS_GET_FAILURE]: (
      state: ModuleTypeTermsAndConditions,
      { payload }: PayloadAction,
    ): ModuleTypeTermsAndConditions => ({
      ...state,
      hasErrMessage: true,
      termsAndConditionsSuccess: false,
      termsAndConditionsStatus: payload,
    }),
    [types.TERMS_AND_CONDITIONS_GET_CLEAN]: (
      state: ModuleTypeTermsAndConditions,
    ): ModuleTypeTermsAndConditions => ({
      ...state,
      termsAndConditionsSuccess: null,
      termsAndConditionsStatus: null,
    }),
    [types.FILE_DELETE_REQUEST]: (state: ModuleTypeFileDelete): ModuleTypeFileDelete => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.FILE_DELETE_SUCCESS]: (
      state: ModuleTypeFileDelete,
      { payload }: PayloadAction,
    ): ModuleTypeFileDelete => ({
      ...state,
      hasErrMessage: false,
      fileDeleteSuccess: true,
      fileDeleteStatus: payload,
    }),
    [types.FILE_DELETE_FAILURE]: (
      state: ModuleTypeFileDelete,
      { payload }: PayloadAction,
    ): ModuleTypeFileDelete => ({
      ...state,
      hasErrMessage: true,
      fileDeleteSuccess: false,
      fileDeleteStatus: payload,
    }),
    [types.FILE_DELETE_CLEAN]: (state: ModuleTypeFileDelete): ModuleTypeFileDelete => ({
      ...state,
      hasErrMessage: false,
      fileDeleteSuccess: null,
      fileDeleteStatus: null,
    }),
    [types.EMPLOYEES_QUANTITY_LIST_GET_REQUEST]: (
      state: ModuleTypeEmployeesQuantityList,
    ): ModuleTypeEmployeesQuantityList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.EMPLOYEES_QUANTITY_LIST_GET_SUCCESS]: (
      state: ModuleTypeEmployeesQuantityList,
      { payload }: PayloadAction,
    ): ModuleTypeEmployeesQuantityList => ({
      ...state,
      employeesQuantityList: payload.employeesQuantity,
    }),
    [types.EMPLOYEES_QUANTITY_LIST_GET_FAILURE]: (
      state: ModuleTypeEmployeesQuantityList,
    ): ModuleTypeEmployeesQuantityList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.EMPLOYEES_QUANTITY_LIST_GET_CLEAN]: (
      state: ModuleTypeEmployeesQuantityList,
    ): ModuleTypeEmployeesQuantityList => ({
      ...state,
      hasErrMessage: false,
      employeesQuantityList: null,
    }),
    [types.STUDENTS_QUANTITY_LIST_GET_REQUEST]: (
      state: ModuleTypeStudentsQuantityList,
    ): ModuleTypeStudentsQuantityList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.STUDENTS_QUANTITY_LIST_GET_SUCCESS]: (
      state: ModuleTypeStudentsQuantityList,
      { payload }: PayloadAction,
    ): ModuleTypeStudentsQuantityList => ({
      ...state,
      studentsQuantityList: payload.studentsQuantity,
    }),
    [types.STUDENTS_QUANTITY_LIST_GET_FAILURE]: (
      state: ModuleTypeStudentsQuantityList,
    ): ModuleTypeStudentsQuantityList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.STUDENTS_QUANTITY_LIST_GET_CLEAN]: (
      state: ModuleTypeStudentsQuantityList,
    ): ModuleTypeStudentsQuantityList => ({
      ...state,
      hasErrMessage: false,
      studentsQuantityList: null,
    }),
    [types.LEVELS_LIST_GET_REQUEST]: (state: ModuleTypeLevelsList): ModuleTypeLevelsList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.LEVELS_LIST_GET_SUCCESS]: (
      state: ModuleTypeLevelsList,
      { payload }: PayloadAction,
    ): ModuleTypeLevelsList => ({
      ...state,
      levelsList: payload.levels,
    }),
    [types.LEVELS_LIST_GET_FAILURE]: (state: ModuleTypeLevelsList): ModuleTypeLevelsList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.LEVELS_LIST_GET_CLEAN]: (state: ModuleTypeLevelsList): ModuleTypeLevelsList => ({
      ...state,
      hasErrMessage: false,
      levelsList: null,
    }),
    [types.LEVEL_MODALITIES_LIST_GET_REQUEST]: (
      state: ModuleTypeLevelModalitiesList,
    ): ModuleTypeLevelModalitiesList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.LEVEL_MODALITIES_LIST_GET_SUCCESS]: (
      state: ModuleTypeLevelModalitiesList,
      { payload }: PayloadAction,
    ): ModuleTypeLevelModalitiesList => ({
      ...state,
      levelModalitiesList: payload.levelModalities,
    }),
    [types.LEVEL_MODALITIES_LIST_GET_FAILURE]: (
      state: ModuleTypeLevelModalitiesList,
    ): ModuleTypeLevelModalitiesList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.LEVEL_MODALITIES_LIST_GET_CLEAN]: (
      state: ModuleTypeLevelModalitiesList,
    ): ModuleTypeLevelModalitiesList => ({
      ...state,
      hasErrMessage: false,
      levelModalitiesList: null,
    }),
    [types.INSTITUTION_TYPES_LIST_GET_REQUEST]: (
      state: ModuleTypeInstitutionTypesList,
    ): ModuleTypeInstitutionTypesList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.INSTITUTION_TYPES_LIST_GET_SUCCESS]: (
      state: ModuleTypeInstitutionTypesList,
      { payload }: PayloadAction,
    ): ModuleTypeInstitutionTypesList => ({
      ...state,
      institutionTypesList: payload.institutionTypes,
    }),
    [types.INSTITUTION_TYPES_LIST_GET_FAILURE]: (
      state: ModuleTypeInstitutionTypesList,
    ): ModuleTypeInstitutionTypesList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.INSTITUTION_TYPES_LIST_GET_CLEAN]: (
      state: ModuleTypeInstitutionTypesList,
    ): ModuleTypeInstitutionTypesList => ({
      ...state,
      hasErrMessage: false,
      institutionTypesList: null,
    }),
    [types.SOCIAL_SKILLS_LIST_GET_REQUEST]: (
      state: ModuleTypeSocialSkillsList,
    ): ModuleTypeSocialSkillsList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.SOCIAL_SKILLS_LIST_GET_SUCCESS]: (
      state: ModuleTypeSocialSkillsList,
      { payload }: PayloadAction,
    ): ModuleTypeSocialSkillsList => ({
      ...state,
      socialSkillsList: payload.socialSkills,
    }),
    [types.SOCIAL_SKILLS_LIST_GET_FAILURE]: (
      state: ModuleTypeSocialSkillsList,
    ): ModuleTypeSocialSkillsList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.SOCIAL_SKILLS_LIST_GET_CLEAN]: (
      state: ModuleTypeSocialSkillsList,
    ): ModuleTypeSocialSkillsList => ({
      ...state,
      hasErrMessage: false,
      socialSkillsList: null,
    }),
    [types.SUPLENCIES_LIST_GET_REQUEST]: (
      state: ModuleTypeSuplenciesList,
    ): ModuleTypeSuplenciesList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.SUPLENCIES_LIST_GET_SUCCESS]: (
      state: ModuleTypeSuplenciesList,
      { payload }: PayloadAction,
    ): ModuleTypeSuplenciesList => ({
      ...state,
      suplenciesList: payload.suplencies,
    }),
    [types.SUPLENCIES_LIST_GET_FAILURE]: (
      state: ModuleTypeSuplenciesList,
    ): ModuleTypeSuplenciesList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.SUPLENCIES_LIST_GET_CLEAN]: (
      state: ModuleTypeSuplenciesList,
    ): ModuleTypeSuplenciesList => ({
      ...state,
      hasErrMessage: false,
      suplenciesList: null,
    }),
  },
  {
    initialModuleState,
    initialModuleStateGeneralInformation,
    initialModuleStatePersonalData,
    initialModuleStateEducation,
    initialModuleStateJobsExperience,
    initialModuleStateRegisterInstitutionProfile,
    initialModuleStatePreferences,
    initialModuleStateEditEducation,
    initialModuleStateDeleteEducation,
    initialModuleStateLanguagesList,
    initialModuleStateEditLanguage,
    initialModuleStateDeleteLanguage,
    initialModuleStateEditJobExperience,
    initialModuleStateDeleteJobExperience,
    initialModuleStateDeleteSocialSkill,
    initialModuleStateEditPreference,
    initialModuleStateDeletePreference,
    initialModuleStateDownloadResume,
    initialModuleStateProvincesList,
    initialModuleStateDepartmentsList,
    initialModuleStateLocalitiesList,
    initialModuleStateRegionsList,
    initialModuleStateNationalitiesList,
    initialModuleStatePrivateMode,
    initialModuleStateInstitutionPhoto,
    initialModuleStateTermsAndConditions,
    initialModuleStateFileDelete,
    initialModuleStateEmployeesQuantityList,
    initialModuleStateStudentsQuantityList,
    initialModuleStateLevelsList,
    initialModuleStateLevelModalitiesList,
    initialModuleStateInstitutionTypesList,
    initialModuleStateSocialSkillsList,
    initialModuleStateSuplenciesList,
  },
)

export default reducer
