import * as types from './types'

import {
  ModuleTypeDownloadSpreadsheet,
  ModuleTypeHandleInvite,
  ModuleTypeHandleRequest,
  ModuleTypeInstitutionInformation,
  ModuleTypeInstitutionInvitesHistory,
  ModuleTypeNewInvite,
  ModuleTypeProfileInformation,
  ModuleTypeProfilesList,
  ModuleTypeResendInvite,
  ModuleTypeSendRequest,
  ModuleTypeUserInvites,
  ModuleTypeViewRequestsList,
} from './_definitions'

import { PayloadAction } from '../../types'
import { profiles } from './_ducks'

const initialModuleStateProfilesList: ModuleTypeProfilesList = {
  hasErrMessage: false,
  profilesList: null,
}

const initialModuleStateViewRequestsList: ModuleTypeViewRequestsList = {
  hasErrMessage: false,
  viewRequestsList: null,
}

const initialModuleStateHandleRequest: ModuleTypeHandleRequest = {
  hasErrMessage: false,
  handleRequestStatus: null,
  handleRequestSuccess: null,
}

const initialModuleStateSendRequest: ModuleTypeSendRequest = {
  hasErrMessage: false,
  sendRequestStatus: null,
  sendRequestSuccess: null,
}

const initialModuleStateProfileInformation: ModuleTypeProfileInformation = {
  hasErrMessage: false,
  profileInformation: null,
}

const initialModuleStateDownloadSpreadsheet: ModuleTypeDownloadSpreadsheet = {
  hasErrMessage: false,
  downloadSpreadsheetStatus: null,
  downloadSpreadsheetSuccess: null,
}

const initialModuleStateInstitutionInformation: ModuleTypeInstitutionInformation = {
  hasErrMessage: false,
  institutionInformation: null,
}
const intitialModuleStateUserInvites: ModuleTypeUserInvites = {
  hasErrMessage: false,
  userInvitesList: null,
}
const intitialModuleStateInstitutionInvitesHistory: ModuleTypeInstitutionInvitesHistory = {
  hasErrMessage: false,
  institutionInvitesHistory: null,
}

const intitialModuleStateNewInvite: ModuleTypeNewInvite = {
  hasErrMessage: false,
  newInviteStatus: null,
  newInviteSuccess: null,
}

const intitialModuleStateHandleInvite: ModuleTypeHandleInvite = {
  hasErrMessage: false,
  handleInviteStatus: null,
  handleInviteSuccess: null,
}
const intitialModuleStateResendInvite: ModuleTypeResendInvite = {
  hasErrMessage: false,
  resendInviteStatus: null,
  resendInviteSuccess: null,
}

const reducer = profiles.createReducer(
  {
    [types.PROFILES_POST_REQUEST]: (state: ModuleTypeProfilesList): ModuleTypeProfilesList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.PROFILES_POST_SUCCESS]: (
      state: ModuleTypeProfilesList,
      { payload }: PayloadAction,
    ): ModuleTypeProfilesList => ({
      ...state,
      profilesList: payload.result,
    }),
    [types.PROFILES_POST_FAILURE]: (state: ModuleTypeProfilesList): ModuleTypeProfilesList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.PROFILES_POST_CLEAN]: (state: ModuleTypeProfilesList): ModuleTypeProfilesList => ({
      ...state,
      hasErrMessage: false,
      profilesList: null,
    }),
    [types.VIEW_REQUISITIONS_GET_REQUEST]: (
      state: ModuleTypeViewRequestsList,
    ): ModuleTypeViewRequestsList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.VIEW_REQUISITIONS_GET_SUCCESS]: (
      state: ModuleTypeViewRequestsList,
      { payload }: PayloadAction,
    ): ModuleTypeViewRequestsList => ({
      ...state,
      viewRequestsList: payload.visualizationRequests.rows,
    }),
    [types.VIEW_REQUISITIONS_GET_FAILURE]: (
      state: ModuleTypeViewRequestsList,
    ): ModuleTypeViewRequestsList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.VIEW_REQUISITIONS_GET_CLEAN]: (
      state: ModuleTypeViewRequestsList,
    ): ModuleTypeViewRequestsList => ({
      ...state,
      hasErrMessage: false,
      viewRequestsList: null,
    }),
    [types.HANDLE_REQUISITION_PUT_REQUEST]: (
      state: ModuleTypeHandleRequest,
    ): ModuleTypeHandleRequest => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.HANDLE_REQUISITION_PUT_SUCCESS]: (
      state: ModuleTypeHandleRequest,
      { payload }: PayloadAction,
    ): ModuleTypeHandleRequest => ({
      ...state,
      handleRequestStatus: payload,
      handleRequestSuccess: true,
    }),
    [types.HANDLE_REQUISITION_PUT_FAILURE]: (
      state: ModuleTypeHandleRequest,
      { payload }: PayloadAction,
    ): ModuleTypeHandleRequest => ({
      ...state,
      hasErrMessage: true,
      handleRequestStatus: payload,
      handleRequestSuccess: false,
    }),
    [types.HANDLE_REQUISITION_PUT_CLEAN]: (
      state: ModuleTypeHandleRequest,
    ): ModuleTypeHandleRequest => ({
      ...state,
      hasErrMessage: false,
      handleRequestStatus: null,
      handleRequestSuccess: null,
    }),
    [types.SEND_REQUISITION_POST_REQUEST]: (
      state: ModuleTypeSendRequest,
    ): ModuleTypeSendRequest => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.SEND_REQUISITION_POST_SUCCESS]: (
      state: ModuleTypeSendRequest,
      { payload }: PayloadAction,
    ): ModuleTypeSendRequest => ({
      ...state,
      sendRequestStatus: payload,
      sendRequestSuccess: true,
    }),
    [types.SEND_REQUISITION_POST_FAILURE]: (
      state: ModuleTypeSendRequest,
      { payload }: PayloadAction,
    ): ModuleTypeSendRequest => ({
      ...state,
      hasErrMessage: true,
      sendRequestStatus: payload,
      sendRequestSuccess: false,
    }),
    [types.SEND_REQUISITION_POST_CLEAN]: (state: ModuleTypeSendRequest): ModuleTypeSendRequest => ({
      ...state,
      hasErrMessage: false,
      sendRequestStatus: null,
      sendRequestSuccess: null,
    }),
    [types.PROFILE_GET_REQUEST]: (
      state: ModuleTypeProfileInformation,
    ): ModuleTypeProfileInformation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.PROFILE_GET_SUCCESS]: (
      state: ModuleTypeProfileInformation,
      { payload }: PayloadAction,
    ): ModuleTypeProfileInformation => ({
      ...state,
      profileInformation: payload.result,
    }),
    [types.PROFILE_GET_FAILURE]: (
      state: ModuleTypeProfileInformation,
    ): ModuleTypeProfileInformation => ({
      ...state,
      hasErrMessage: true,
      profileInformation: false,
    }),
    [types.PROFILE_GET_CLEAN]: (
      state: ModuleTypeProfileInformation,
    ): ModuleTypeProfileInformation => ({
      ...state,
      hasErrMessage: false,
      profileInformation: null,
    }),
    [types.DOWNLOAD_SPREADSHEET_POST_REQUEST]: (
      state: ModuleTypeDownloadSpreadsheet,
    ): ModuleTypeDownloadSpreadsheet => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.DOWNLOAD_SPREADSHEET_POST_SUCCESS]: (
      state: ModuleTypeDownloadSpreadsheet,
      { payload }: PayloadAction,
    ): ModuleTypeDownloadSpreadsheet => ({
      ...state,
      downloadSpreadsheetStatus: payload,
      downloadSpreadsheetSuccess: true,
    }),
    [types.DOWNLOAD_SPREADSHEET_POST_FAILURE]: (
      state: ModuleTypeDownloadSpreadsheet,
      { payload }: PayloadAction,
    ): ModuleTypeDownloadSpreadsheet => ({
      ...state,
      hasErrMessage: true,
      downloadSpreadsheetStatus: payload,
      downloadSpreadsheetSuccess: false,
    }),
    [types.DOWNLOAD_SPREADSHEET_POST_CLEAN]: (
      state: ModuleTypeDownloadSpreadsheet,
    ): ModuleTypeDownloadSpreadsheet => ({
      ...state,
      hasErrMessage: false,
      downloadSpreadsheetStatus: null,
      downloadSpreadsheetSuccess: null,
    }),
    [types.INSTITUTION_GET_REQUEST]: (
      state: ModuleTypeInstitutionInformation,
    ): ModuleTypeInstitutionInformation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.INSTITUTION_GET_SUCCESS]: (
      state: ModuleTypeInstitutionInformation,
      { payload }: PayloadAction,
    ): ModuleTypeInstitutionInformation => ({
      ...state,
      institutionInformation: payload.institution,
    }),
    [types.INSTITUTION_GET_FAILURE]: (
      state: ModuleTypeInstitutionInformation,
    ): ModuleTypeInstitutionInformation => ({
      ...state,
      hasErrMessage: true,
      institutionInformation: false,
    }),
    [types.INSTITUTION_GET_CLEAN]: (
      state: ModuleTypeInstitutionInformation,
    ): ModuleTypeInstitutionInformation => ({
      ...state,
      hasErrMessage: false,
      institutionInformation: null,
    }),
    [types.USER_INVITES_GET_REQUEST]: (state: ModuleTypeUserInvites): ModuleTypeUserInvites => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.USER_INVITES_GET_SUCCESS]: (
      state: ModuleTypeUserInvites,
      { payload }: PayloadAction,
    ): ModuleTypeUserInvites => ({
      ...state,
      userInvitesList: payload,
    }),
    [types.USER_INVITES_GET_FAILURE]: (state: ModuleTypeUserInvites): ModuleTypeUserInvites => ({
      ...state,
      hasErrMessage: true,
      userInvitesList: false,
    }),
    [types.USER_INVITES_GET_CLEAN]: (state: ModuleTypeUserInvites): ModuleTypeUserInvites => ({
      ...state,
      hasErrMessage: false,
      userInvitesList: null,
    }),
    [types.INSTITUTION_INVITES_HISTORY_GET_REQUEST]: (
      state: ModuleTypeInstitutionInvitesHistory,
    ): ModuleTypeInstitutionInvitesHistory => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.INSTITUTION_INVITES_HISTORY_GET_SUCCESS]: (
      state: ModuleTypeInstitutionInvitesHistory,
      { payload }: PayloadAction,
    ): ModuleTypeInstitutionInvitesHistory => ({
      ...state,
      institutionInvitesHistory: payload,
    }),
    [types.INSTITUTION_INVITES_HISTORY_GET_FAILURE]: (
      state: ModuleTypeInstitutionInvitesHistory,
    ): ModuleTypeInstitutionInvitesHistory => ({
      ...state,
      hasErrMessage: true,
      institutionInvitesHistory: false,
    }),
    [types.INSTITUTION_INVITES_HISTORY_GET_CLEAN]: (
      state: ModuleTypeInstitutionInvitesHistory,
    ): ModuleTypeInstitutionInvitesHistory => ({
      ...state,
      hasErrMessage: false,
      institutionInvitesHistory: null,
    }),
    [types.NEW_INVITE_POST_REQUEST]: (state: ModuleTypeNewInvite): ModuleTypeNewInvite => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.NEW_INVITE_POST_SUCCESS]: (
      state: ModuleTypeNewInvite,
      { payload }: PayloadAction,
    ): ModuleTypeNewInvite => ({
      ...state,
      hasErrMessage: false,
      newInviteStatus: payload,
      newInviteSuccess: true,
    }),
    [types.NEW_INVITE_POST_FAILURE]: (
      state: ModuleTypeNewInvite,
      { payload }: PayloadAction,
    ): ModuleTypeNewInvite => ({
      ...state,
      hasErrMessage: true,
      newInviteStatus: payload,
      newInviteSuccess: false,
    }),
    [types.NEW_INVITE_POST_CLEAN]: (state: ModuleTypeNewInvite): ModuleTypeNewInvite => ({
      ...state,
      hasErrMessage: false,
      newInviteStatus: null,
      newInviteSuccess: null,
    }),
    [types.HANDLE_INVITE_PUT_REQUEST]: (state: ModuleTypeHandleInvite): ModuleTypeHandleInvite => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.HANDLE_INVITE_PUT_SUCCESS]: (
      state: ModuleTypeHandleInvite,
      { payload }: PayloadAction,
    ): ModuleTypeHandleInvite => ({
      ...state,
      hasErrMessage: false,
      handleInviteStatus: payload,
      handleInviteSuccess: true,
    }),
    [types.HANDLE_INVITE_PUT_FAILURE]: (
      state: ModuleTypeHandleInvite,
      { payload }: PayloadAction,
    ): ModuleTypeHandleInvite => ({
      ...state,
      hasErrMessage: true,
      handleInviteStatus: payload,
      handleInviteSuccess: false,
    }),
    [types.HANDLE_INVITE_PUT_CLEAN]: (state: ModuleTypeHandleInvite): ModuleTypeHandleInvite => ({
      ...state,
      hasErrMessage: false,
      handleInviteStatus: null,
      handleInviteSuccess: null,
    }),
    [types.RESEND_INVITE_POST_REQUEST]: (
      state: ModuleTypeResendInvite,
    ): ModuleTypeResendInvite => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.RESEND_INVITE_POST_SUCCESS]: (
      state: ModuleTypeResendInvite,
      { payload }: PayloadAction,
    ): ModuleTypeResendInvite => ({
      ...state,
      hasErrMessage: false,
      resendInviteStatus: payload,
      resendInviteSuccess: true,
    }),
    [types.RESEND_INVITE_POST_FAILURE]: (
      state: ModuleTypeResendInvite,
      { payload }: PayloadAction,
    ): ModuleTypeResendInvite => ({
      ...state,
      hasErrMessage: true,
      resendInviteStatus: payload,
      resendInviteSuccess: false,
    }),
    [types.RESEND_INVITE_POST_CLEAN]: (state: ModuleTypeResendInvite): ModuleTypeResendInvite => ({
      ...state,
      hasErrMessage: false,
      resendInviteStatus: null,
      resendInviteSuccess: null,
    }),
  },
  {
    initialModuleStateProfilesList,
    initialModuleStateViewRequestsList,
    initialModuleStateHandleRequest,
    initialModuleStateSendRequest,
    initialModuleStateProfileInformation,
    initialModuleStateDownloadSpreadsheet,
    initialModuleStateInstitutionInformation,
    intitialModuleStateUserInvites,
    intitialModuleStateInstitutionInvitesHistory,
    intitialModuleStateNewInvite,
    intitialModuleStateHandleInvite,
    intitialModuleStateResendInvite,
  },
)

export default reducer
