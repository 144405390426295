import { user } from './_duck'

export const USER_INFORMATION_GET_REQUEST: string = user.defineType('USER_INFORMATION_GET_REQUEST')
export const USER_INFORMATION_GET_SUCCESS: string = user.defineType('USER_INFORMATION_GET_SUCCESS')
export const USER_INFORMATION_GET_FAILURE: string = user.defineType('USER_INFORMATION_GET_FAILURE')

export const USER_INFORMATION_CLEAN_REQUEST: string = user.defineType(
  'USER_INFORMATION_CLEAN_REQUEST',
)

export const GENERAL_INFORMATION_REQUEST: string = user.defineType('GENERAL_INFORMATION_REQUEST')
export const GENERAL_INFORMATION_SUCCESS: string = user.defineType('GENERAL_INFORMATION_SUCCESS')
export const GENERAL_INFORMATION_FAILURE: string = user.defineType('GENERAL_INFORMATION_FAILURE')
export const GENERAL_INFORMATION_CLEAN: string = user.defineType('GENERAL_INFORMATION_CLEAN')

export const PERSONAL_DATA_REQUEST: string = user.defineType('PERSONAL_DATA_REQUEST')
export const PERSONAL_DATA_SUCCESS: string = user.defineType('PERSONAL_DATA_SUCCESS')
export const PERSONAL_DATA_FAILURE: string = user.defineType('PERSONAL_DATA_FAILURE')
export const PERSONAL_DATA_CLEAN: string = user.defineType('PERSONAL_DATA_CLEAN')

export const EDUCATION_REQUEST: string = user.defineType('EDUCATION_REQUEST')
export const EDUCATION_SUCCESS: string = user.defineType('EDUCATION_SUCCESS')
export const EDUCATION_FAILURE: string = user.defineType('EDUCATION_FAILURE')
export const EDUCATION_CLEAN: string = user.defineType('EDUCATION_CLEAN')

export const JOBS_EXPERIENCE_REQUEST: string = user.defineType('JOBS_EXPERIENCE_REQUEST')
export const JOBS_EXPERIENCE_SUCCESS: string = user.defineType('JOBS_EXPERIENCE_SUCCESS')
export const JOBS_EXPERIENCE_FAILURE: string = user.defineType('JOBS_EXPERIENCE_FAILURE')
export const JOBS_EXPERIENCE_CLEAN: string = user.defineType('JOBS_EXPERIENCE_CLEAN')

export const PREFERENCE_REQUEST: string = user.defineType('PREFERENCE_REQUEST')
export const PREFERENCE_SUCCESS: string = user.defineType('PREFERENCE_SUCCESS')
export const PREFERENCE_FAILURE: string = user.defineType('PREFERENCE_FAILURE')
export const PREFERENCE_CLEAN: string = user.defineType('PREFERENCE_CLEAN')

export const REGISTER_INSTITUTION_PROFILE_POST_REQUEST: string = user.defineType(
  'REGISTER_INSTITUTION_PROFILE_POST_REQUEST',
)
export const REGISTER_INSTITUTION_PROFILE_POST_SUCCESS: string = user.defineType(
  'REGISTER_INSTITUTION_PROFILE_POST_SUCCESS',
)
export const REGISTER_INSTITUTION_PROFILE_POST_FAILURE: string = user.defineType(
  'REGISTER_INSTITUTION_PROFILE_POST_FAILURE',
)
export const REGISTER_INSTITUTION_PROFILE_CLEAN: string = user.defineType(
  'REGISTER_INSTITUTION_PROFILE_CLEAN',
)

export const VALIDATION_MAIL_REQUEST: string = user.defineType('VALIDATION_MAIL_REQUEST')
export const VALIDATION_MAIL_SUCCESS: string = user.defineType('VALIDATION_MAIL_SUCCESS')
export const VALIDATION_MAIL_FAILURE: string = user.defineType('VALIDATION_MAIL_FAILURE')

export const EDUCATION_EDIT_REQUEST: string = user.defineType('EDUCATION_EDIT_REQUEST')
export const EDUCATION_EDIT_SUCCESS: string = user.defineType('EDUCATION_EDIT_SUCCESS')
export const EDUCATION_EDIT_FAILURE: string = user.defineType('EDUCATION_EDIT_FAILURE')
export const EDUCATION_EDIT_CLEAN: string = user.defineType('EDUCATION_EDIT_CLEAN')

export const EDUCATION_DELETE_REQUEST: string = user.defineType('EDUCATION_DELETE_REQUEST')
export const EDUCATION_DELETE_SUCCESS: string = user.defineType('EDUCATION_DELETE_SUCCESS')
export const EDUCATION_DELETE_FAILURE: string = user.defineType('EDUCATION_DELETE_FAILURE')
export const EDUCATION_DELETE_CLEAN: string = user.defineType('EDUCATION_DELETE_CLEAN')

export const LANGUAGES_LIST_GET_REQUEST: string = user.defineType('LANGUAGES_LIST_GET_REQUEST')
export const LANGUAGES_LIST_GET_SUCCESS: string = user.defineType('LANGUAGES_LIST_GET_SUCCESS')
export const LANGUAGES_LIST_GET_FAILURE: string = user.defineType('LANGUAGES_LIST_GET_FAILURE')
export const LANGUAGES_LIST_GET_CLEAN: string = user.defineType('LANGUAGES_LIST_GET_CLEAN')

export const LANGUAGE_DELETE_REQUEST: string = user.defineType('LANGUAGE_DELETE_REQUEST')
export const LANGUAGE_DELETE_SUCCESS: string = user.defineType('LANGUAGE_DELETE_SUCCESS')
export const LANGUAGE_DELETE_FAILURE: string = user.defineType('LANGUAGE_DELETE_FAILURE')
export const LANGUAGE_DELETE_CLEAN: string = user.defineType('LANGUAGE_DELETE_CLEAN')

export const LANGUAGE_EDIT_REQUEST: string = user.defineType('LANGUAGE_EDIT_REQUEST')
export const LANGUAGE_EDIT_SUCCESS: string = user.defineType('LANGUAGE_EDIT_SUCCESS')
export const LANGUAGE_EDIT_FAILURE: string = user.defineType('LANGUAGE_EDIT_FAILURE')
export const LANGUAGE_EDIT_CLEAN: string = user.defineType('LANGUAGE_EDIT_CLEAN')

export const JOBS_EXPERIENCE_EDIT_REQUEST: string = user.defineType('JOBS_EXPERIENCE_EDIT_REQUEST')
export const JOBS_EXPERIENCE_EDIT_SUCCESS: string = user.defineType('JOBS_EXPERIENCE_EDIT_SUCCESS')
export const JOBS_EXPERIENCE_EDIT_FAILURE: string = user.defineType('JOBS_EXPERIENCE_EDIT_FAILURE')
export const JOBS_EXPERIENCE_EDIT_CLEAN: string = user.defineType('JOBS_EXPERIENCE_EDIT_CLEAN')

export const JOBS_EXPERIENCE_DELETE_REQUEST: string = user.defineType(
  'JOBS_EXPERIENCE_DELETE_REQUEST',
)
export const JOBS_EXPERIENCE_DELETE_SUCCESS: string = user.defineType(
  'JOBS_EXPERIENCE_DELETE_SUCCESS',
)
export const JOBS_EXPERIENCE_DELETE_FAILURE: string = user.defineType(
  'JOBS_EXPERIENCE_DELETE_FAILURE',
)
export const JOBS_EXPERIENCE_DELETE_CLEAN: string = user.defineType('JOBS_EXPERIENCE_DELETE_CLEAN')

export const SOCIAL_SKILL_DELETE_REQUEST: string = user.defineType('SOCIAL_SKILL_DELETE_REQUEST')
export const SOCIAL_SKILL_DELETE_SUCCESS: string = user.defineType('SOCIAL_SKILL_DELETE_SUCCESS')
export const SOCIAL_SKILL_DELETE_FAILURE: string = user.defineType('SOCIAL_SKILL_DELETE_FAILURE')
export const SOCIAL_SKILL_DELETE_CLEAN: string = user.defineType('SOCIAL_SKILL_DELETE_CLEAN')

export const PREFERENCE_DELETE_REQUEST: string = user.defineType('PREFERENCE_DELETE_REQUEST')
export const PREFERENCE_DELETE_SUCCESS: string = user.defineType('PREFERENCE_DELETE_SUCCESS')
export const PREFERENCE_DELETE_FAILURE: string = user.defineType('PREFERENCE_DELETE_FAILURE')
export const PREFERENCE_DELETE_CLEAN: string = user.defineType('PREFERENCE_DELETE_CLEAN')

export const PREFERENCE_EDIT_REQUEST: string = user.defineType('PREFERENCE_EDIT_REQUEST')
export const PREFERENCE_EDIT_SUCCESS: string = user.defineType('PREFERENCE_EDIT_SUCCESS')
export const PREFERENCE_EDIT_FAILURE: string = user.defineType('PREFERENCE_EDIT_FAILURE')
export const PREFERENCE_EDIT_CLEAN: string = user.defineType('PREFERENCE_EDIT_CLEAN')

export const INSTITUTIONAL_DATA_REQUEST: string = user.defineType('INSTITUTIONAL_DATA_REQUEST')
export const INSTITUTIONAL_DATA_SUCCESS: string = user.defineType('INSTITUTIONAL_DATA_SUCCESS')
export const INSTITUTIONAL_DATA_FAILURE: string = user.defineType('INSTITUTIONAL_DATA_FAILURE')
export const INSTITUTIONAL_DATA_CLEAN: string = user.defineType('INSTITUTIONAL_DATA_CLEAN')

export const OWN_DATA_REQUEST: string = user.defineType('OWN_DATA_REQUEST')
export const OWN_DATA_SUCCESS: string = user.defineType('OWN_DATA_SUCCESS')
export const OWN_DATA_FAILURE: string = user.defineType('OWN_DATA_FAILURE')
export const OWN_DATA_CLEAN: string = user.defineType('OWN_DATA_CLEAN')

export const CONTACT_DATA_REQUEST: string = user.defineType('CONTACT_DATA_REQUEST')
export const CONTACT_DATA_SUCCESS: string = user.defineType('CONTACT_DATA_SUCCESS')
export const CONTACT_DATA_FAILURE: string = user.defineType('CONTACT_DATA_FAILURE')
export const CONTACT_DATA_CLEAN: string = user.defineType('CONTACT_DATA_CLEAN')

export const RESUME_DOWNLOAD_REQUEST: string = user.defineType('RESUME_DOWNLOAD_REQUEST')
export const RESUME_DOWNLOAD_SUCCESS: string = user.defineType('RESUME_DOWNLOAD_SUCCESS')
export const RESUME_DOWNLOAD_FAILURE: string = user.defineType('RESUME_DOWNLOAD_FAILURE')
export const RESUME_DOWNLOAD_CLEAN: string = user.defineType('RESUME_DOWNLOAD_CLEAN')

export const PROVINCES_GET_REQUEST: string = user.defineType('PROVINCES_GET_REQUEST')
export const PROVINCES_GET_SUCCESS: string = user.defineType('PROVINCES_GET_SUCCESS')
export const PROVINCES_GET_FAILURE: string = user.defineType('PROVINCES_GET_FAILURE')
export const PROVINCES_GET_CLEAN: string = user.defineType('PROVINCES_GET_CLEAN')

export const DEPARTMENTS_GET_REQUEST: string = user.defineType('DEPARTMENTS_GET_REQUEST')
export const DEPARTMENTS_GET_SUCCESS: string = user.defineType('DEPARTMENTS_GET_SUCCESS')
export const DEPARTMENTS_GET_FAILURE: string = user.defineType('DEPARTMENTS_GET_FAILURE')
export const DEPARTMENTS_GET_CLEAN: string = user.defineType('DEPARTMENTS_GET_CLEAN')

export const LOCALITIES_GET_REQUEST: string = user.defineType('LOCALITIES_GET_REQUEST')
export const LOCALITIES_GET_SUCCESS: string = user.defineType('LOCALITIES_GET_SUCCESS')
export const LOCALITIES_GET_FAILURE: string = user.defineType('LOCALITIES_GET_FAILURE')
export const LOCALITIES_GET_CLEAN: string = user.defineType('LOCALITIES_GET_CLEAN')

export const REGIONS_GET_REQUEST: string = user.defineType('REGIONS_GET_REQUEST')
export const REGIONS_GET_SUCCESS: string = user.defineType('REGIONS_GET_SUCCESS')
export const REGIONS_GET_FAILURE: string = user.defineType('REGIONS_GET_FAILURE')
export const REGIONS_GET_CLEAN: string = user.defineType('REGIONS_GET_CLEAN')

export const NATIONALITIES_GET_REQUEST: string = user.defineType('NATIONALITIES_GET_REQUEST')
export const NATIONALITIES_GET_SUCCESS: string = user.defineType('NATIONALITIES_GET_SUCCESS')
export const NATIONALITIES_GET_FAILURE: string = user.defineType('NATIONALITIES_GET_FAILURE')
export const NATIONALITIES_GET_CLEAN: string = user.defineType('NATIONALITIES_GET_CLEAN')

export const PRIVATE_MODE_GET_REQUEST: string = user.defineType('PRIVATE_MODE_GET_REQUEST')
export const PRIVATE_MODE_GET_SUCCESS: string = user.defineType('PRIVATE_MODE_GET_SUCCESS')
export const PRIVATE_MODE_GET_FAILURE: string = user.defineType('PRIVATE_MODE_GET_FAILURE')
export const PRIVATE_MODE_GET_CLEAN: string = user.defineType('PRIVATE_MODE_GET_CLEAN')

export const INSTITUTION_PHOTO_POST_REQUEST: string = user.defineType(
  'INSTITUTION_PHOTO_POST_REQUEST',
)
export const INSTITUTION_PHOTO_POST_SUCCESS: string = user.defineType(
  'INSTITUTION_PHOTO_POST_SUCCESS',
)
export const INSTITUTION_PHOTO_POST_FAILURE: string = user.defineType(
  'INSTITUTION_PHOTO_POST_FAILURE',
)
export const INSTITUTION_PHOTO_POST_CLEAN: string = user.defineType('INSTITUTION_PHOTO_POST_CLEAN')

export const TERMS_AND_CONDITIONS_GET_REQUEST: string = user.defineType(
  'TERMS_AND_CONDITIONS_GET_REQUEST',
)
export const TERMS_AND_CONDITIONS_GET_SUCCESS: string = user.defineType(
  'TERMS_AND_CONDITIONS_GET_SUCCESS',
)
export const TERMS_AND_CONDITIONS_GET_FAILURE: string = user.defineType(
  'TERMS_AND_CONDITIONS_GET_FAILURE',
)
export const TERMS_AND_CONDITIONS_GET_CLEAN: string = user.defineType(
  'TERMS_AND_CONDITIONS_GET_CLEAN',
)

export const FILE_DELETE_REQUEST: string = user.defineType('FILE_DELETE_REQUEST')
export const FILE_DELETE_SUCCESS: string = user.defineType('FILE_DELETE_SUCCESS')
export const FILE_DELETE_FAILURE: string = user.defineType('FILE_DELETE_FAILURE')
export const FILE_DELETE_CLEAN: string = user.defineType('FILE_DELETE_CLEAN')

export const EMPLOYEES_QUANTITY_LIST_GET_REQUEST: string = user.defineType(
  'EMPLOYEES_QUANTITY_LIST_GET_REQUEST',
)
export const EMPLOYEES_QUANTITY_LIST_GET_SUCCESS: string = user.defineType(
  'EMPLOYEES_QUANTITY_LIST_GET_SUCCESS',
)
export const EMPLOYEES_QUANTITY_LIST_GET_FAILURE: string = user.defineType(
  'EMPLOYEES_QUANTITY_LIST_GET_FAILURE',
)
export const EMPLOYEES_QUANTITY_LIST_GET_CLEAN: string = user.defineType(
  'EMPLOYEES_QUANTITY_LIST_GET_CLEAN',
)

export const STUDENTS_QUANTITY_LIST_GET_REQUEST: string = user.defineType(
  'STUDENTS_QUANTITY_LIST_GET_REQUEST',
)
export const STUDENTS_QUANTITY_LIST_GET_SUCCESS: string = user.defineType(
  'STUDENTS_QUANTITY_LIST_GET_SUCCESS',
)
export const STUDENTS_QUANTITY_LIST_GET_FAILURE: string = user.defineType(
  'STUDENTS_QUANTITY_LIST_GET_FAILURE',
)
export const STUDENTS_QUANTITY_LIST_GET_CLEAN: string = user.defineType(
  'STUDENTS_QUANTITY_LIST_GET_CLEAN',
)

export const LEVELS_LIST_GET_REQUEST: string = user.defineType('LEVELS_LIST_GET_REQUEST')
export const LEVELS_LIST_GET_SUCCESS: string = user.defineType('LEVELS_LIST_GET_SUCCESS')
export const LEVELS_LIST_GET_FAILURE: string = user.defineType('LEVELS_LIST_GET_FAILURE')
export const LEVELS_LIST_GET_CLEAN: string = user.defineType('LEVELS_LIST_GET_CLEAN')

export const INSTITUTION_TYPES_LIST_GET_REQUEST: string = user.defineType(
  'INSTITUTION_TYPES_LIST_GET_REQUEST',
)
export const INSTITUTION_TYPES_LIST_GET_SUCCESS: string = user.defineType(
  'INSTITUTION_TYPES_LIST_GET_SUCCESS',
)
export const INSTITUTION_TYPES_LIST_GET_FAILURE: string = user.defineType(
  'INSTITUTION_TYPES_LIST_GET_FAILURE',
)
export const INSTITUTION_TYPES_LIST_GET_CLEAN: string = user.defineType(
  'INSTITUTION_TYPES_LIST_GET_CLEAN',
)

export const LEVEL_MODALITIES_LIST_GET_REQUEST: string = user.defineType(
  'LEVEL_MODALITIES_LIST_GET_REQUEST',
)
export const LEVEL_MODALITIES_LIST_GET_SUCCESS: string = user.defineType(
  'LEVEL_MODALITIES_LIST_GET_SUCCESS',
)
export const LEVEL_MODALITIES_LIST_GET_FAILURE: string = user.defineType(
  'LEVEL_MODALITIES_LIST_GET_FAILURE',
)
export const LEVEL_MODALITIES_LIST_GET_CLEAN: string = user.defineType(
  'LEVEL_MODALITIES_LIST_GET_CLEAN',
)

export const SOCIAL_SKILLS_LIST_GET_REQUEST: string = user.defineType(
  'SOCIAL_SKILLS_LIST_GET_REQUEST',
)
export const SOCIAL_SKILLS_LIST_GET_SUCCESS: string = user.defineType(
  'SOCIAL_SKILLS_LIST_GET_SUCCESS',
)
export const SOCIAL_SKILLS_LIST_GET_FAILURE: string = user.defineType(
  'SOCIAL_SKILLS_LIST_GET_FAILURE',
)
export const SOCIAL_SKILLS_LIST_GET_CLEAN: string = user.defineType('SOCIAL_SKILLS_LIST_GET_CLEAN')

export const SUPLENCIES_LIST_GET_REQUEST: string = user.defineType('SUPLENCIES_LIST_GET_REQUEST')
export const SUPLENCIES_LIST_GET_SUCCESS: string = user.defineType('SUPLENCIES_LIST_GET_SUCCESS')
export const SUPLENCIES_LIST_GET_FAILURE: string = user.defineType('SUPLENCIES_LIST_GET_FAILURE')
export const SUPLENCIES_LIST_GET_CLEAN: string = user.defineType('SUPLENCIES_LIST_GET_CLEAN')
