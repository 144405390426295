import * as types from '../types'

import {
  activateUserFailure,
  activateUserSuccess,
  deleteUserAssociationFailure,
  deleteUserAssociationSuccess,
  deleteUserFailure,
  deleteUserFromDbFailure,
  deleteUserFromDbSuccess,
  deleteUserSuccess,
  downloadUserSheetFailure,
  downloadUserSheetSuccess,
  editUserAssociationFailure,
  editUserAssociationSuccess,
  editUserFailure,
  editUserSuccess,
  findUsersFailure,
  findUsersSuccess,
  handleRequisitionFailure,
  handleRequisitionSuccess,
  institutionAssociationsFailure,
  institutionAssociationsSuccess,
  institutionsFailure,
  institutionsSuccess,
  newInstitutionFailure,
  newInstitutionSuccess,
  newUserAssociationFailure,
  newUserAssociationSuccess,
  newUserFailure,
  newUserSuccess,
  requisitionsFailure,
  requisitionsSuccess,
  rolesFailure,
  rolesSuccess,
  updateInstitutionDataFailure,
  updateInstitutionDataSuccess,
  usersFailure,
  usersSuccess,
  verificationMailFailure,
  verificationMailSuccess,
} from '../actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteUserAssociationService,
  deleteUserFromDbService,
  deleteUserService,
  getInstitutionAssociationsService,
  getRequisitionsInformationService,
  getRolesInformationService,
  getUsersService,
  postDownloadUserSheetService,
  postEditUserAssociationService,
  postInstitutionsListService,
  postNewInstitutionListService,
  postNewUserAssociationService,
  postNewUserService,
  postUsersListService,
  postVerificationEmailService,
  putActivateUserService,
  putEditUserService,
  putHandleRequisitionService,
  putUpdateInstitutionDataService,
} from '../services'

import { SagaIterator } from 'redux-saga'

function* getRolesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getRolesInformationService, payload)
    yield put(rolesSuccess(data))
  } catch (error) {
    yield put(rolesFailure(error))
  }
}
export function* getRolesWatcher(): SagaIterator {
  yield takeLatest(types.ROLES_GET_REQUEST, getRolesWorker)
}

function* postUsersWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postUsersListService, payload)
    yield put(usersSuccess(data))
  } catch (error) {
    yield put(usersFailure(error))
  }
}
export function* postUsersWatcher(): SagaIterator {
  yield takeLatest(types.USERS_POST_REQUEST, postUsersWorker)
}

function* postNewUserWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postNewUserService, payload)
    yield put(newUserSuccess(data))
  } catch (error) {
    yield put(newUserFailure(error))
  }
}
export function* postNewUserWatcher(): SagaIterator {
  yield takeLatest(types.NEW_USER_POST_REQUEST, postNewUserWorker)
}

function* deleteUserWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(deleteUserService, payload)
    yield put(deleteUserSuccess(data))
  } catch (error) {
    yield put(deleteUserFailure(error))
  }
}
export function* deleteUserWatcher(): SagaIterator {
  yield takeLatest(types.USER_DELETE_REQUEST, deleteUserWorker)
}

function* editUserWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(putEditUserService, payload)
    yield put(editUserSuccess(data))
  } catch (error) {
    yield put(editUserFailure(error))
  }
}
export function* editUserWatcher(): SagaIterator {
  yield takeLatest(types.USER_EDIT_REQUEST, editUserWorker)
}

function* activateUserWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(putActivateUserService, payload)
    yield put(activateUserSuccess(data))
  } catch (error) {
    yield put(activateUserFailure(error))
  }
}
export function* activateUserWatcher(): SagaIterator {
  yield takeLatest(types.USER_ACTIVATE_REQUEST, activateUserWorker)
}

function* getRequisitionsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getRequisitionsInformationService, payload)
    yield put(requisitionsSuccess(data['user-institutions']))
  } catch (error) {
    yield put(requisitionsFailure(error))
  }
}
export function* getRequisitionsWatcher(): SagaIterator {
  yield takeLatest(types.REQUISITIONS_GET_REQUEST, getRequisitionsWorker)
}

function* putHandleRequisitionWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(putHandleRequisitionService, payload)

    yield put(handleRequisitionSuccess({ data }))
  } catch (error) {
    yield put(handleRequisitionFailure(error))
  }
}
export function* putHandleRequisitionWatcher(): SagaIterator {
  yield takeLatest(types.REQUISITION_HANDLE_PUT_REQUEST, putHandleRequisitionWorker)
}

function* postInstitutionsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postInstitutionsListService, payload)
    yield put(institutionsSuccess(data))
  } catch (error) {
    yield put(institutionsFailure(error))
  }
}
export function* postInstitutionsWatcher(): SagaIterator {
  yield takeLatest(types.INSTITUTIONS_POST_REQUEST, postInstitutionsWorker)
}

function* postNewInstitutionWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postNewInstitutionListService, payload)

    yield put(newInstitutionSuccess(data))
  } catch (error) {
    yield put(newInstitutionFailure(error))
  }
}
export function* postNewInstitutionWatcher(): SagaIterator {
  yield takeLatest(types.NEW_INSTITUTION_POST_REQUEST, postNewInstitutionWorker)
}

function* getUsersWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getUsersService, payload)

    yield put(findUsersSuccess(data))
  } catch (error) {
    yield put(findUsersFailure(error))
  }
}
export function* getUsersWatcher(): SagaIterator {
  yield takeLatest(types.FIND_USERS_GET_REQUEST, getUsersWorker)
}

function* getInstitutionAssociationsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getInstitutionAssociationsService, payload)

    yield put(institutionAssociationsSuccess(data))
  } catch (error) {
    yield put(institutionAssociationsFailure(error))
  }
}
export function* getInstitutionAssociationsWatcher(): SagaIterator {
  yield takeLatest(types.INSTITUTION_ASSOCIATIONS_GET_REQUEST, getInstitutionAssociationsWorker)
}

function* postNewUserAssociationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postNewUserAssociationService, payload)

    yield put(newUserAssociationSuccess(data))
  } catch (error) {
    yield put(newUserAssociationFailure(error))
  }
}
export function* postNewUserAssociationWatcher(): SagaIterator {
  yield takeLatest(types.NEW_USER_ASSOCIATION_POST_REQUEST, postNewUserAssociationWorker)
}

function* postEditUserAssociationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postEditUserAssociationService, payload)

    yield put(editUserAssociationSuccess(data))
  } catch (error) {
    yield put(editUserAssociationFailure(error))
  }
}
export function* postEditUserAssociationWatcher(): SagaIterator {
  yield takeLatest(types.EDIT_USER_ASSOCIATION_POST_REQUEST, postEditUserAssociationWorker)
}

function* deleteUserAssociationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(deleteUserAssociationService, payload)

    yield put(deleteUserAssociationSuccess(data))
  } catch (error) {
    yield put(deleteUserAssociationFailure(error))
  }
}
export function* deleteUserAssociationWatcher(): SagaIterator {
  yield takeLatest(types.USER_ASSOCIATION_DELETE_REQUEST, deleteUserAssociationWorker)
}

function* deleteUserFromDbWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(deleteUserFromDbService, payload)
    yield put(deleteUserFromDbSuccess(data))
  } catch (error) {
    yield put(deleteUserFromDbFailure(error))
  }
}
export function* deleteUserFromDbWatcher(): SagaIterator {
  yield takeLatest(types.USER_DELETE_FROM_DB_REQUEST, deleteUserFromDbWorker)
}

function* postVerificationEmailWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postVerificationEmailService, payload)
    yield put(verificationMailSuccess(data))
  } catch (error) {
    yield put(verificationMailFailure(error))
  }
}
export function* postVerificationEmailWatcher(): SagaIterator {
  yield takeLatest(types.VERIFICATION_EMAIL_REQUEST, postVerificationEmailWorker)
}

function* putUpdateInstitutionDataWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(putUpdateInstitutionDataService, payload)
    yield put(updateInstitutionDataSuccess(data))
  } catch (error) {
    yield put(updateInstitutionDataFailure(error))
  }
}
export function* putUpdateInstitutionDataWatcher(): SagaIterator {
  yield takeLatest(types.UPDATE_INSTITUTION_DATA_REQUEST, putUpdateInstitutionDataWorker)
}

function* postDownloadUserSheetWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postDownloadUserSheetService, payload)
    yield put(downloadUserSheetSuccess(data))
  } catch (error) {
    yield put(downloadUserSheetFailure(error))
  }
}
export function* postDownloadUserSheetWatcher(): SagaIterator {
  yield takeLatest(types.DOWNLOAD_USER_SHEET_POST_REQUEST, postDownloadUserSheetWorker)
}

export const sagas = [
  getRolesWatcher,
  postUsersWatcher,
  postNewUserWatcher,
  deleteUserWatcher,
  editUserWatcher,
  activateUserWatcher,
  getRequisitionsWatcher,
  putHandleRequisitionWatcher,
  postInstitutionsWatcher,
  postNewInstitutionWatcher,
  getUsersWatcher,
  getInstitutionAssociationsWatcher,
  postNewUserAssociationWatcher,
  postEditUserAssociationWatcher,
  deleteUserAssociationWatcher,
  deleteUserFromDbWatcher,
  postVerificationEmailWatcher,
  putUpdateInstitutionDataWatcher,
  postDownloadUserSheetWatcher,
]
