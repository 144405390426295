import { colors } from '../../../utils/colors'

export const jobsFilterStyles = {
  container: { display: 'flex', flexDirection: 'column' },

  filterIcon: {
    width: '20px',
    height: '15px',
    flexShrink: 0,
    alignSelf: 'end',
    marginBottom: '15px',
    cursor: 'pointer',
  },
  input: { width: '32%' },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '2%',
    rowGap: '15px',
    marginBottom: '15px',
  },
  buttonBox: { display: 'flex', alignItems: 'flex-end', columnGap: '20px' },
  searchButton: {
    borderRadius: '5px',
    backgroundColor: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    color: '#fff',
  },
  cleanFilters: {
    backgroundColor: '#fff',
    color: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    borderRadius: '5px',
  },

  formMobile: { display: 'flex', flexDirection: 'column', rowGap: '20px', marginBottom: '15px' },
  inputMobile: { width: '100%', fontSize: '14px' },
  error: { color: colors.red },
}
