import { colors } from '../../../utils/colors'

export const jobsModalStyles = {
  container: { width: '100%', maxWidth: '1400px', margin: '0 auto', padding: '2% 4.3% 4.3% 4%' },
  headingBox: { display: 'flex', justifyContent: 'space-between' },
  heading: {
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '35.68px',
    color: colors.principal,
    marginBottom: '10px',
  },
  comment: { fontSize: '14px', color: '#000' },
  item: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 63px 25px 36px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  itemTitle: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '35.68px',
    marginBottom: '10px',
  },
  optionalItemTitle: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '35.68px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputContainer: {
    paddingBottom: '18px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px 3.3%',
  },
  input: { width: '31%' },
  input2: { width: '44%' },
  input3: { width: '24%' },
  checkbox: {
    margin: '0 0 0 8px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  checkboxDisable: { display: 'flex' },
  textarea: { fontSize: '14px' },
  badge: {
    textTransform: 'capitalize',
    padding: '4px 8px',
    fontSize: '10px',
    borderRadius: '5px',
  },
  badgeBox: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  badgeText: { color: colors.principal, fontSize: '20px' },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '50px',
  },
  saveButtonHover: {
    border: `1px solid ${colors.secundary}`,
    backgroundColor: colors.secundary,
    color: colors.white,
  },
  saveButton: {
    backgroundColor: colors.principal,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 16px',
    color: colors.white,
    border: `1px solid ${colors.principal}`,
  },

  modalStyles: {
    container: { width: '22%' },
    title: { textAlign: 'center', marginTop: '7%' },
    buttonBox: { justifyContent: 'center' },
  },
  modalStylesMobile: {
    container: { width: '82%' },
    title: { textAlign: 'center', marginTop: '7%' },
    buttonBox: { justifyContent: 'center' },
  },
  containerMobile: {
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
    padding: '6% 0 8% 0',
  },
  headingBoxMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  itemMobile: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 24px 25px 24px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  itemTitleMobile: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '25px',
    marginBottom: '20px',
  },
  optionalItemTitleMobile: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '25px',
    marginBottom: '20px',
    display: 'flex',
  },

  commentMobile: { marginTop: '10px', fontSize: '10px', lineHeight: '15px', color: '#000' },
  inputContainerMobile: {
    paddingBottom: '18px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
  },
  inputMobile: { width: '100%', fontSize: '14px' },
  input2Mobile: { width: '100%', fontSize: '14px' },
  input3Mobile: { width: '100%', fontSize: '14px' },
  checkboxDisableMobile: { display: 'flex', flexDirection: 'column', rowGap: '10px' },
  checkboxMobile: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  checkboxFieldMobile: { width: '15px', height: '15px' },
  buttonBoxMobile: {
    display: 'flex',
    columnGap: '50px',
  },
  saveButtonMobile: {
    height: 'auto',
    width: '100%',
    backgroundColor: colors.principal,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '8px 16px',
    color: colors.white,
    border: `1px solid ${colors.principal}`,
  },
  chevronIcon: { cursor: 'pointer', boxSize: '30px' },
}
