import * as Yup from 'yup'

import { Box, Button, FormControl, FormLabel, Heading, Input, Select, Text } from '@chakra-ui/react'
import { Departamento, Localidad, Provincia, RoleInformation, UserModalProps } from './_types'
import { Field, Form, Formik, FormikErrors, FormikTouched, useFormikContext } from 'formik'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  UserDataAction,
  UserDataAdd,
  UserDataEdit,
  UserDataField,
} from '../../Users/UsersTable/_types'
import { calcularAge, usersFields } from '../../../../utils/abm'
import {
  departmentsClean,
  departmentsRequest,
  localitiesClean,
  localitiesRequest,
  nationalitiesClean,
  nationalitiesRequest,
  provincesClean,
  provincesRequest,
} from '../../../../state/modules/user/actions'
import {
  editUserRequest,
  newUserRequest,
  rolesClean,
  rolesRequest,
} from '../../../../state/modules/abm/actions'
import { useDispatch, useSelector } from 'react-redux'

import { transformDateFromSQL } from '../../../../utils/profile'
import { userModalStyles } from './styles'

const UserModal: React.FC<UserModalProps> = ({ action, currentUser }) => {
  const [fields, setFields] = useState<any[]>([])
  const [prepaidHealth, setPrepaidHealth] = useState<boolean>(
    action === 'edit' &&
      currentUser?.Profile?.prepaidHealth !== null &&
      currentUser?.Profile?.prepaidHealth !== 'Sin Cobertura' &&
      currentUser?.Profile?.prepaidHealth !== ''
      ? true
      : false,
  )
  const dispatch = useDispatch()
  const abm = useSelector((state: any) => state.abm)

  const nationalidades = useSelector((state: any) => state?.user?.nationalitiesList)

  const provincias: Provincia[] = useSelector((state: any) =>
    state?.user?.provincesList?.sort((a: any, b: any) =>
      a.description?.localeCompare(b.description),
    ),
  )
  const [selectedProvince, setSelectedProvince] = useState<string>(
    currentUser?.Profile?.Address?.Province?.id ?? '',
  )
  const departamentos: Departamento[] = useSelector((state: any) =>
    state?.user?.departmentsList?.sort((a: any, b: any) =>
      a.description?.localeCompare(b.description),
    ),
  )
  const [selectedDepartment, setSelectedDepartment] = useState<string>(
    currentUser?.Profile?.Address?.Department?.id ?? '',
  )
  const localidades: Localidad[] = useSelector((state: any) =>
    state?.user?.localitiesList?.sort((a: any, b: any) =>
      a.description?.localeCompare(b.description),
    ),
  )
  const [selectedLocality, setSelectedLocality] = useState<string>(
    currentUser?.Profile?.Address?.Locality?.id ?? '',
  )

  const date =
    currentUser?.Profile?.birthdate === null || action === 'add'
      ? ''
      : transformDateFromSQL(currentUser?.Profile?.birthdate).formatDate

  useEffect(() => {
    if (action === 'add') {
      const addFields = usersFields
        .filter((e: UserDataField) => e.isAdded)
        .sort((e1, e2) => e1.order - e2.order)
      setFields(addFields)
    }
    if (action === 'edit') {
      const editFields = usersFields
        .filter((e: UserDataField) => e.isEdited)
        .sort((e1, e2) => e1.order - e2.order)
      setFields(editFields)
    }
    if (action === 'view') {
      const editFields = usersFields
        .filter((e: UserDataField) => e.isEdited)
        .sort((e1, e2) => e1.order - e2.order)
      setFields(editFields)
    }
  }, [action])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(provincesRequest({ token }))
    dispatch(nationalitiesRequest({ token }))

    return () => {
      dispatch(provincesClean())
      dispatch(nationalitiesClean())
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (selectedProvince !== '' && selectedProvince !== undefined && selectedProvince !== null)
      dispatch(departmentsRequest({ token, provinceId: selectedProvince }))

    return () => {
      dispatch(departmentsClean())
    }
  }, [selectedProvince])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (
      selectedDepartment !== '' &&
      selectedDepartment !== undefined &&
      selectedDepartment !== null
    )
      dispatch(
        localitiesRequest({
          token,
          departmentId: selectedDepartment,
        }),
      )
    return () => {
      dispatch(localitiesClean())
    }
  }, [selectedDepartment])

  useEffect(() => {
    dispatch(rolesRequest())

    return () => {
      dispatch(rolesClean())
    }
  }, [])

  const handleProvinceChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedProvinceId = e.target.value
    setSelectedProvince(selectedProvinceId)
    setFieldValue('provinceId', selectedProvinceId)
    setSelectedDepartment('')
    setTimeout(() => setFieldValue('departmentId', ''), 10)
    setSelectedLocality('')
    setTimeout(() => setFieldValue('localityId', ''), 20)
  }

  const handleDepartmentChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedDepartmentId = e.target.value
    setSelectedDepartment(selectedDepartmentId)
    setFieldValue('departmentId', selectedDepartmentId)
    setSelectedLocality('')
    setTimeout(() => setFieldValue('localityId', ''), 10)
  }

  const handleLocalityChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedLocalityId = e.target.value
    setSelectedLocality(selectedLocalityId)
    setFieldValue('localityId', selectedLocalityId)
  }

  const initialValues: UserDataAdd = {
    lastname: '',
    name: '',
    dni: '',
    email: '',
    role: '',
  }

  const editedValues: UserDataEdit = {
    lastname: currentUser?.Profile?.lastName ?? '',
    name: currentUser?.Profile?.name ?? '',
    dni: currentUser?.Profile?.dni ?? '',
    email: currentUser?.Profile?.email ?? '',
    cuil: currentUser?.Profile?.cuil ?? '',
    phoneNumber: currentUser?.Profile?.phoneNumber ?? '',
    cellPhone: currentUser?.Profile?.cellPhone ?? '',
    street: currentUser?.Profile?.Address?.street ?? '',
    number: currentUser?.Profile?.Address?.number
      ? currentUser?.Profile?.Address?.number === null
        ? ''
        : currentUser?.Profile?.Address?.number.toString()
      : '',
    floor: currentUser?.Profile?.Address?.floor ?? '',
    department: currentUser?.Profile?.Address?.department ?? '',
    postalCode: currentUser?.Profile?.Address?.postalCode ?? '',
    localityId: selectedLocality ?? '',
    departmentId: selectedDepartment ?? '',
    provinceId: selectedProvince ?? '',
    gender: currentUser?.Profile?.gender ?? '',
    civilStatus: currentUser?.Profile?.civilStatus ?? '',
    birthdate: date,
    age: currentUser?.Profile?.age?.toString() ?? '0',
    nationality: currentUser?.Profile?.nationalityId ?? '',
    prepaidHealthTrue:
      currentUser?.Profile?.prepaidHealth === 'Sin Cobertura'
        ? 'No'
        : currentUser?.Profile?.prepaidHealth === null || currentUser?.Profile?.prepaidHealth === ''
        ? ''
        : 'Si',
    prepaidHealth: currentUser?.Profile?.prepaidHealth ?? '',
    role: currentUser?.roleId ?? '',
  }

  const renderInput = (
    id: string,
    setFieldValue?: any,
    handleChange?: any,
    setFieldTouched?: any,
  ) => {
    switch (id) {
      case 'role':
        return (
          <Field
            as={Select}
            name={id}
            isDisabled={action === 'view' ? true : currentUser?.verified === false ? true : false}
            sx={(action === 'view' || currentUser?.verified === false) && userModalStyles.disabled}
          >
            <option value=''>Seleccione el rol</option>
            {abm?.rolesInformation
              ?.filter((role: RoleInformation) => role.type == 'global')
              .map((e: RoleInformation, i: any) => (
                <option key={i} value={e.id}>
                  {e.description}
                </option>
              ))}
          </Field>
        )
      case 'civilStatus':
        return (
          <Field
            as={Select}
            name={id}
            isDisabled={action === 'view' ? true : currentUser?.verified === false ? true : false}
            sx={(action === 'view' || currentUser?.verified === false) && userModalStyles.disabled}
          >
            <option value=''>Elija una opción</option>
            <option value='Soltero/a'>Soltero/a</option>
            <option value='Casado/a'>Casado/a</option>
            <option value='Viudo/a'>Viudo/a</option>
            <option value='Divorciado/a'>Divorciado/a</option>
            <option value='Pareja de hecho'>Pareja de hecho</option>
            <option value='Unión libre'>Unión libre</option>
          </Field>
        )
      case 'gender':
        return (
          <Field
            as={Select}
            name={id}
            isDisabled={action === 'view' ? true : currentUser?.verified === false ? true : false}
            sx={(action === 'view' || currentUser?.verified === false) && userModalStyles.disabled}
          >
            <option value=''>Elija una opción</option>
            <option value='Femenino'>Femenino</option>
            <option value='Masculino'>Masculino</option>
            <option value='Prefiero no responder'>Prefiero no responder</option>
          </Field>
        )
      case 'birthdate':
        return (
          <Field
            as={Input}
            isDisabled={action === 'view' ? true : currentUser?.verified === false ? true : false}
            sx={(action === 'view' || currentUser?.verified === false) && userModalStyles.disabled}
            type='date'
            name={id}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              handleBirthdateBlur(e, setFieldValue)
            }
          />
        )
      case 'prepaidHealthTrue':
        return (
          <Field
            as={Select}
            isDisabled={action === 'view' ? true : currentUser?.verified === false ? true : false}
            sx={(action === 'view' || currentUser?.verified === false) && userModalStyles.disabled}
            name={id}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              handleChange(e)
              handlePrepaidHealthChange(e, setFieldValue)
            }}
          >
            <option value=''>Elija una opción</option>
            <option value='Si'>Si</option>
            <option value='No'>No</option>
          </Field>
        )
      case 'localityId':
        return (
          <Field
            as={Select}
            isDisabled={action === 'view' ? true : currentUser?.verified === false ? true : false}
            sx={
              action === 'view' || currentUser?.verified === false
                ? userModalStyles.disabled
                : { textTransform: 'capitalize' }
            }
            name={id}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLocalityChange(e, setFieldValue)}
            value={selectedLocality}
          >
            <option>Seleccioná tu ciudad</option>
            {localidades?.map((locality) => (
              <option style={{ textTransform: 'capitalize' }} key={locality.id} value={locality.id}>
                {locality.description.toLowerCase()}
              </option>
            ))}
          </Field>
        )
      case 'departmentId':
        return (
          <Field
            as={Select}
            isDisabled={action === 'view' ? true : currentUser?.verified === false ? true : false}
            sx={
              action === 'view' || currentUser?.verified === false
                ? userModalStyles.disabled
                : { textTransform: 'capitalize' }
            }
            name={id}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              handleDepartmentChange(e, setFieldValue)
            }
            onFocus={() => setFieldTouched('localityId')}
            value={selectedDepartment}
          >
            <option>Seleccioná tu ciudad</option>
            {departamentos?.map((departamento) => (
              <option
                style={{ textTransform: 'capitalize' }}
                key={departamento.id}
                value={departamento.id}
              >
                {departamento.description.toLowerCase()}
              </option>
            ))}
          </Field>
        )
      case 'provinceId':
        return (
          <Field
            as={Select}
            isDisabled={action === 'view' ? true : currentUser?.verified === false ? true : false}
            sx={
              action === 'view' || currentUser?.verified === false
                ? userModalStyles.disabled
                : { textTransform: 'capitalize' }
            }
            name={id}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              handleProvinceChange(e, setFieldValue)
            }}
            onFocus={() => setFieldTouched('departmentId', true)}
            value={selectedProvince}
          >
            <option value=''>Seleccioná tu provincia</option>
            {provincias?.map((province) => (
              <option style={{ textTransform: 'capitalize' }} key={province.id} value={province.id}>
                {province.description.toLowerCase()}
              </option>
            ))}
          </Field>
        )
      case 'nationality':
        return (
          <Field
            as={Select}
            isDisabled={action === 'view' ? true : currentUser?.verified === false ? true : false}
            sx={
              action === 'view' || currentUser?.verified === false
                ? userModalStyles.disabled
                : { textTransform: 'capitalize' }
            }
            name={id}
          >
            <option>Seleccioná tu nacionalidad</option>
            {nationalidades?.map((nac: any) => (
              <option key={nac.id} value={nac.id}>
                {nac.description}
              </option>
            ))}
          </Field>
        )
      case 'age':
        return <Field as={Input} type='text' name={id} isReadOnly={true} />
      default:
        return (
          <Field
            as={Input}
            type='text'
            name={id}
            maxLength={
              id === 'dni'
                ? '8'
                : id === 'cuil' || id === 'phoneNumber' || id === 'cellPhone'
                ? '10'
                : ''
            }
            onFocus={() => {
              setFieldTouched('provinceId')
            }}
            onChange={(e: any) => {
              id === 'dni' ||
              id === 'cuil' ||
              id === 'phoneNumber' ||
              id === 'cellPhone' ||
              id === 'number'
                ? setFieldValue(id, e.target.value.trim())
                : setFieldValue(id, e.target.value)
            }}
          />
        )
    }
  }

  const validateSchema = Yup.object({
    lastname: action === 'view' ? Yup.string() : Yup.string().required('Campo obligatorio'),
    name: action === 'view' ? Yup.string() : Yup.string().required('Campo obligatorio'),
    email:
      action === 'view'
        ? Yup.string()
        : Yup.string().email('Email no válido').required('Campo obligatorio'),
    role: Yup.string() ? Yup.string().required('Campo obligatorio') : Yup.string(),
    dni:
      action === 'view'
        ? Yup.string()
        : Yup.string()
            .test({
              name: 'is-number',
              test(value, ctx) {
                if (value && value.length > 0 && Number.isNaN(Number(value))) {
                  return ctx.createError({ message: 'El campo no acepta letras' })
                }
                return true
              },
            })
            .min(7, 'El campo DNI debe contener un mínimo de 7 caracteres')
            .max(8, 'El campo DNI debe contener un máximo de 8 caracteres')

            .required('Campo obligatorio'),

    cuil: Yup.string().test({
      name: 'is-number',
      test(value, ctx) {
        if (value && value.length > 0 && Number.isNaN(Number(value))) {
          return ctx.createError({ message: 'El campo no acepta letras' })
        }
        return true
      },
    }),
    phoneNumber: Yup.string().test({
      name: 'is-number',
      test(value, ctx) {
        if (value && value.length > 0 && Number.isNaN(Number(value))) {
          return ctx.createError({ message: 'El campo no acepta letras' })
        }
        return true
      },
    }),
    cellPhone: Yup.string().test({
      name: 'is-number',
      test(value, ctx) {
        if (value && value.length > 0 && Number.isNaN(Number(value))) {
          return ctx.createError({ message: 'El campo no acepta letras' })
        }
        return true
      },
    }),
    street: Yup.string().test({
      name: 'is-address-complete',
      test(value, ctx) {
        const values = Object.keys(this.parent).filter(
          (e) =>
            e === 'street' ||
            e === 'number' ||
            e === 'floor' ||
            e === 'department' ||
            e === 'localityId' ||
            e === 'departmentId' ||
            e === 'provinceId' ||
            e === 'postalCode',
        )

        if (value !== undefined && ctx.parent.provinceId === undefined) {
          return ctx.createError({ path: 'provinceId', message: 'Campo obligatorio' })
        } else if (values.length === 0) {
          return true
        } else return true
      },
    }),

    number: Yup.string().test({
      name: 'is-address-complete',
      test(value, ctx) {
        const values = Object.keys(this.parent).filter(
          (e) =>
            e === 'street' ||
            e === 'number' ||
            e === 'floor' ||
            e === 'department' ||
            e === 'localityId' ||
            e === 'departmentId' ||
            e === 'provinceId' ||
            e === 'postalCode',
        )

        if (value !== undefined && ctx.parent.provinceId === undefined) {
          return ctx.createError({ path: 'provinceId', message: 'Campo obligatorio' })
        } else if (values.length === 0) {
          return true
        } else return true
      },
    }),
    floor: Yup.string().test({
      name: 'is-address-complete',
      test(value, ctx) {
        const values = Object.keys(this.parent).filter(
          (e) =>
            e === 'street' ||
            e === 'number' ||
            e === 'floor' ||
            e === 'department' ||
            e === 'localityId' ||
            e === 'departmentId' ||
            e === 'provinceId' ||
            e === 'postalCode',
        )

        if (value !== undefined && ctx.parent.provinceId === undefined) {
          return ctx.createError({ path: 'provinceId', message: 'Campo obligatorio' })
        } else if (values.length === 0) {
          return true
        } else return true
      },
    }),
    department: Yup.string().test({
      name: 'is-address-complete',
      test(value, ctx) {
        const values = Object.keys(this.parent).filter(
          (e) =>
            e === 'street' ||
            e === 'number' ||
            e === 'floor' ||
            e === 'department' ||
            e === 'localityId' ||
            e === 'departmentId' ||
            e === 'provinceId' ||
            e === 'postalCode',
        )

        if (value !== undefined && ctx.parent.provinceId === undefined) {
          return ctx.createError({ path: 'provinceId', message: 'Campo obligatorio' })
        } else if (values.length === 0) {
          return true
        } else return true
      },
    }),
    postalCode: Yup.string().test({
      name: 'is-address-complete',
      test(value, ctx) {
        const values = Object.keys(this.parent).filter(
          (e) =>
            e === 'street' ||
            e === 'number' ||
            e === 'floor' ||
            e === 'department' ||
            e === 'localityId' ||
            e === 'departmentId' ||
            e === 'provinceId' ||
            e === 'postalCode',
        )

        if ((value !== undefined || value === '') && ctx.parent.provinceId === undefined) {
          return ctx.createError({ path: 'provinceId', message: 'Campo obligatorio' })
        } else if (values.length === 0) {
          return true
        } else return true
      },
    }),
    localityId:
      selectedDepartment !== '' ? Yup.string().required('Campo obligatorio') : Yup.string(),
    departmentId:
      selectedProvince !== '' ? Yup.string().required('Campo obligatorio') : Yup.string(),
    provinceId: Yup.string(),
    gender: Yup.string(),
    civilStatus: Yup.string(),
    birthdate: Yup.string(),
    age: Yup.string(),
    nationality: Yup.string(),
    prepaidHealthTrue: Yup.string(),
    prepaidHealth: prepaidHealth ? Yup.string().required('Campo obligatorio') : Yup.string(),
  }).defined()

  const handleBirthdateBlur = (e: React.FocusEvent<HTMLInputElement>, setFieldValue: any) => {
    let age = 0

    if (e.target.value) {
      const { selectorDate } = transformDateFromSQL(e.target.value)
      const ageNumber = calcularAge(selectorDate)
      ageNumber > 0 && (age = ageNumber)
    }

    setFieldValue('age', age, false)
  }
  const handlePrepaidHealthChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: any,
  ) => {
    if (e.target.value === 'No') {
      setFieldValue('prepaidHealth', 'Sin Cobertura')
      setPrepaidHealth(false)
    } else if (e.target.value === 'Si') {
      setFieldValue('prepaidHealth', '')
      setPrepaidHealth(true)
    } else {
      setFieldValue('prepaidHealth', '')
      setPrepaidHealth(false)
    }
  }

  const handleSubmit = (values: UserDataAction) => {
    const token = localStorage.getItem('access_token') ?? ''
    const { role, localityId, departmentId, provinceId, number, birthdate, ...rest } = values
    const userRole = role

    const updateEditedValues = {
      ...rest,
      localityId: localityId ? (localityId === '' ? null : localityId) : null,
      departmentId: departmentId ? (departmentId === '' ? null : departmentId) : null,
      provinceId: provinceId ? (provinceId === '' ? null : provinceId) : null,
      number: number ? (number === '' ? null : Number(number)) : null,
      addressId: currentUser?.Profile?.addressId ?? null,
      birthdate: birthdate ? (birthdate === '' ? null : birthdate) : null,
      userId: currentUser?.id,
      profileId: currentUser?.Profile?.id,
      token,
      userRole,
    }

    action === 'add'
      ? dispatch(newUserRequest({ userRole, ...rest, token }))
      : dispatch(editUserRequest(updateEditedValues))
  }

  return (
    <Box sx={userModalStyles.box}>
      <Heading sx={userModalStyles.heading}>
        {action === 'add'
          ? 'Agregar usuario'
          : action === 'view'
          ? 'Información del usuario'
          : 'Editar información del usuario'}
      </Heading>
      <Formik
        initialValues={action === 'add' ? initialValues : editedValues}
        onSubmit={handleSubmit}
        validationSchema={validateSchema}
      >
        {({ errors, touched, setFieldValue, handleChange, setFieldTouched }) => {
          const touchedFields = touched as FormikTouched<any>
          const errorFields = errors as FormikErrors<any>
          const errorsArray = Object.values(errors)
          return (
            <Form>
              <Box sx={userModalStyles.form}>
                {fields.map((e: any) => {
                  return e.id === 'prepaidHealth' ? (
                    prepaidHealth && (
                      <FormControl
                        isReadOnly={
                          action === 'view'
                            ? true
                            : currentUser?.verified === false && e.id !== 'email' && e.id !== 'dni'
                            ? true
                            : false
                        }
                        sx={userModalStyles.input}
                        key={e.order}
                      >
                        <FormLabel>{e.name}*</FormLabel>
                        {renderInput(e.id, setFieldValue, handleChange, setFieldTouched)}
                        {errorFields[`${e.id}`] && touchedFields[`${e.id}`] && (
                          <Text color='red' fontSize={'sm'}>
                            {`${errorFields[e.id]}`}
                          </Text>
                        )}
                      </FormControl>
                    )
                  ) : (
                    <FormControl
                      isReadOnly={
                        action === 'view'
                          ? true
                          : currentUser?.verified === false && e.id !== 'email' && e.id !== 'dni'
                          ? true
                          : false
                      }
                      sx={userModalStyles.input}
                      key={e.order}
                    >
                      <FormLabel>
                        {e.name}
                        {(e.id === 'name' ||
                          e.id === 'lastname' ||
                          e.id === 'role' ||
                          e.id === 'dni' ||
                          e.id === 'email') &&
                          '*'}
                      </FormLabel>
                      {renderInput(e.id, setFieldValue, handleChange, setFieldTouched)}
                      {errorFields[`${e.id}`] && touchedFields[`${e.id}`] && (
                        <Text color='red' fontSize={'sm'}>
                          {`${errorFields[e.id]}`}
                        </Text>
                      )}
                    </FormControl>
                  )
                })}
              </Box>
              {action !== 'view' && (
                <Box sx={userModalStyles.submitButtonBox}>
                  <Button
                    isDisabled={errorsArray.length > 0}
                    sx={userModalStyles.submitButton}
                    type='submit'
                  >
                    Aceptar
                  </Button>
                </Box>
              )}
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default UserModal
