import { FC, useEffect, useState, useRef, ChangeEvent } from 'react'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  Center,
  RadioGroup,
  Radio,
  Stack,
  Link,
  useToast,
  Checkbox,
  useMediaQuery,
  UseToastOptions,
  Button,
  Flex,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from '@chakra-ui/react'
import { InfoIcon, AttachmentIcon } from '@chakra-ui/icons'
import BlueButton from '../../buttons/button-blue'
import { colors } from '../../../utils/colors'
import { useNavigate } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { RegisterInstitucion } from '.././_types'
import { registerInstitutionRequest } from '../../../state/modules/login/actions'
import { PostRegisterInstitution } from '../../../state/modules/login/services/types'

const initialValues: RegisterInstitucion = {
  cue: '',
  entramite: '',
  provincialNumber: '',
  documentation: null,
}

const toastMessages: UseToastOptions[] = [
  {
    description:
      'Muchas gracias, estará llegando un email a su casilla de correo para la activación',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'El DNI o email ingresado ya se encuentra registrado',
    status: 'error',
    duration: 2000,
  },
]

const textoDocumentacion = `Deberá adjuntar la documentación que dé cuenta de:\n
-	Número provincial/CUE\n
-	Razón social que vincule a número provincial/CUE\n
-	Alta en AFIP con número de CUIT\n
-	Si está "En Trámite" presentación de carpeta de apertura para identificar nivel educativo y razón social
`
const RegisterInstitutionCompo: FC = () => {
  const toast = useToast()
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const state = useSelector((state: any) => state)
  const { registerInstitutionSuccess } = useSelector((state: any) => state.login)
  const [isChecked, setIsChecked] = useState(false)
  const [isRegistering, setIsRegistering] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [filesUp, setFilesUp] = useState<File[]>([])
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([])

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      const selectedFiles = Array.from(files)
      setFilesUp(selectedFiles)
      const fileNames = selectedFiles.map((file) => file.name)
      setSelectedFileNames(fileNames)
    }
  }

  useEffect(() => {
    if (registerInstitutionSuccess !== null && registerInstitutionSuccess !== undefined)
      toast(toastMessages[registerInstitutionSuccess ? 0 : 1])
    setIsRegistering(false)
  }, [registerInstitutionSuccess])

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }

  const handleBackRegister = () => {
    navigate('/register')
  }

  const onSubmit = (values: RegisterInstitucion) => {
    const updatedInitialValuesForm: PostRegisterInstitution = {
      name: state.login.statusRegisterPostulante.name,
      lastName: state.login.statusRegisterPostulante.lastName,
      dni: state.login.statusRegisterPostulante.dni,
      email: state.login.statusRegisterPostulante.email,
      password: state.login.statusRegisterPostulante.password,
      cue: values.cue ? values.cue : '000000',
      entramite: values.entramite,
      provincialNumber: values.provincialNumber ? values.provincialNumber : '000000',
      filesUp: filesUp,
      navigate: navigate,
    }
    setIsRegistering(true)

    dispatch(registerInstitutionRequest(updatedInitialValuesForm))
  }

  return (
    <Box p={isMobile ? 8 : 0}>
      <Button
        onClick={handleBackRegister}
        color='#56769A'
        backgroundColor={'white'}
        marginLeft={'-3.5'}
      >
        {' '}
        <Text fontSize={'10px'} fontWeight={'normal'}>
          {' '}
          Volver{' '}
        </Text>
      </Button>

      <Heading as='h4' size='md' mb={4}>
        Creá tu cuenta institucional
      </Heading>

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <Stack spacing={4}>
              <FormControl isInvalid={Boolean(errors.cue && touched.cue)}>
                <Flex direction={'row'}>
                  <Box flex={1}>
                    <FormLabel htmlFor='cue' fontSize={'sm'}>
                      Cue
                    </FormLabel>
                    <Input
                      id='cue'
                      name='cue'
                      type='number'
                      fontSize={'sm'}
                      value={values.cue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.cue && touched.cue ? (
                      <Text color='red' fontSize={'sm'}>
                        {errors.cue}
                      </Text>
                    ) : null}
                  </Box>
                  <Box flex={1}>
                    <FormLabel htmlFor='entramite' fontSize={'sm'}>
                      {' '}
                      En tramite
                    </FormLabel>
                    <Center height='50%'>
                      <RadioGroup
                        value={values.entramite}
                        onChange={(value) => setFieldValue('entramite', value)}
                      >
                        <Stack direction='row' spacing='8'>
                          <Radio value='Si'>Si</Radio>
                          <Radio value='No'>No</Radio>
                        </Stack>
                      </RadioGroup>
                      {errors.cue && touched.cue ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.cue}
                        </Text>
                      ) : null}
                    </Center>
                  </Box>
                </Flex>
                <FormLabel htmlFor='provincialNumber' fontSize={'sm'}>
                  Número Provincial
                </FormLabel>
                <Input
                  id='provincialNumber'
                  name='provincialNumber'
                  type='number'
                  fontSize={'sm'}
                  value={values.provincialNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.provincialNumber && touched.provincialNumber ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.provincialNumber}
                  </Text>
                ) : null}
              </FormControl>
              <Box>
                <Checkbox isChecked={isChecked} onChange={handleCheckboxChange}>
                  <Text
                    fontSize={'10px'}
                    fontWeight={'normal'}
                    align={isMobile ? 'center' : 'inherit'}
                  >
                    Acepto los{' '}
                    <Link
                      as={RouterLink}
                      color={colors.brand.blue.default}
                      variant='link'
                      to='/register'
                    >
                      terminos y condiciones
                    </Link>{' '}
                    de Edu privada
                  </Text>
                </Checkbox>
                {selectedFileNames.length > 0 ? (
                  <Box mt={1} display={'flex'}>
                    <AttachmentIcon boxSize={4} mr={2} />
                    <Text
                      fontSize={'10px'}
                      fontWeight={'normal'}
                      align={isMobile ? 'center' : 'inherit'}
                      color={'green'}
                    >
                      Archivo/s adjunto/s
                    </Text>
                  </Box>
                ) : null}
              </Box>
              <Box>
                <Flex>
                  <Button
                    onClick={handleButtonClick}
                    isDisabled={!isChecked || !values.entramite}
                    width={'90%'}
                    borderColor='black'
                    color='black'
                    variant={'outline'}
                  >
                    <Text size={'sm'} fontWeight={'normal'}>
                      Adjuntar documentación
                    </Text>
                  </Button>
                  <input
                    ref={fileInputRef}
                    type='file'
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                  />
                  <Box ml={2} mt={1}>
                    {isMobile ? (
                      <Popover>
                        <PopoverTrigger>
                          <Button backgroundColor='transparent'>
                            <InfoIcon boxSize={7} color={colors.principal} />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverBody
                            sx={{
                              fontSize: '16px',
                              textTransform: 'initial',
                              fontWeight: 'initial',
                            }}
                          >
                            {textoDocumentacion}
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    ) : (
                      <Tooltip label={textoDocumentacion}>
                        <span>
                          <InfoIcon boxSize={7} color='blue.500' />
                        </span>
                      </Tooltip>
                    )}
                  </Box>
                </Flex>
              </Box>
              <Box>
                <BlueButton
                  type='submit'
                  isLoading={isRegistering}
                  isDisabled={
                    !isChecked ||
                    // Object.keys(errors).length > 0 ||
                    // !values.cue ||
                    !values.entramite
                    // !values.provincialNumber
                  }
                  width={'full'}
                >
                  <Text size={'sm'} fontWeight={'normal'}>
                    Confirmar
                  </Text>
                </BlueButton>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default RegisterInstitutionCompo
