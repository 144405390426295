import { colors } from '../../utils/colors'

export const adsSearchStyles = {
  container: { width: '100%', padding: '1.1% 4.3% 4.3% 4%' },
  heading: {
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '35.68px',
    color: colors.principal,
    marginBottom: '10px',
  },
  totalAds: {
    width: '100%',

    color: colors.principal,
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
    padding: '0px 30px',
  },
  adsSearch: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 63px 25px 36px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  adsList: { display: 'flex', flexDirection: 'column', rowGap: '10px' },
  loading: {
    width: '100%',
    height: '30vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsContainer: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    textAlign: 'center',
    padding: '20px 0px',
    fontWeight: 'bold',
  },
  containerMobile: { width: '100%', padding: '6% 4.3% 8% 4%' },

  adsSearchMobile: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 36px 25px 36px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  adsListMobile: { display: 'flex', flexDirection: 'column', rowGap: '20px' },
}
