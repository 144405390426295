import { login } from './_duck'
import * as types from './types'

export const loginRequest = login.createAction(types.LOGIN_POST_REQUEST)
export const loginSuccess = login.createAction(types.LOGIN_POST_SUCCESS)
export const loginFailure = login.createAction(types.LOGIN_POST_FAILURE)
export const loginClean = login.createAction(types.LOGIN_CLEAN)

export const generateRequest = login.createAction(types.GENERATE_CODE_POST_REQUEST)
export const generateSuccess = login.createAction(types.GENERATE_CODE_POST_SUCCESS)
export const generateFailure = login.createAction(types.GENERATE_CODE_POST_FAILURE)
export const generateClean = login.createAction(types.GENERATE_CODE_POST_CLEAN)

export const verifyRequest = login.createAction(types.VERIFY_CODE_POST_REQUEST)
export const verifySuccess = login.createAction(types.VERIFY_CODE_POST_SUCCESS)
export const verifyFailure = login.createAction(types.VERIFY_CODE_POST_FAILURE)
export const verifyClean = login.createAction(types.VERIFY_CODE_POST_CLEAN)

export const changePassRequest = login.createAction(types.CHANGE_PASS_POST_REQUEST)
export const changePassSuccess = login.createAction(types.CHANGE_PASS_POST_SUCCESS)
export const changePassFailure = login.createAction(types.CHANGE_PASS_POST_FAILURE)
export const changePassClean = login.createAction(types.CHANGE_PASS_POST_CLEAN)

export const changePassProfileRequest = login.createAction(types.CHANGE_PASS_PROFILE_POST_REQUEST)
export const changePassProfileSuccess = login.createAction(types.CHANGE_PASS_PROFILE_POST_SUCCESS)
export const changePassProfileFailure = login.createAction(types.CHANGE_PASS_PROFILE_POST_FAILURE)
export const changePassProfileClean = login.createAction(types.CHANGE_PASS_PROFILE_POST_CLEAN)

export const registerPostulanteRequest = login.createAction(types.REGISTER_POSTULANTE_POST_REQUEST)
export const registerPostulanteSuccess = login.createAction(types.REGISTER_POSTULANTE_POST_SUCCESS)
export const registerPostulanteFailure = login.createAction(types.REGISTER_POSTULANTE_POST_FAILURE)
export const registerPostulanteClean = login.createAction(types.REGISTER_POSTULANTE_CLEAN)

export const registerPostulanteStateRequest = login.createAction(
  types.REGISTER_POSTULANTE_PARAMETER_STATE_REQUEST,
)
export const registerPostulanteStateSuccess = login.createAction(
  types.REGISTER_POSTULANTE_PARAMETER_STATE_SUCCESS,
)
export const registerPostulanteStateFailure = login.createAction(
  types.REGISTER_POSTULANTE_PARAMETER_STATE_FAILURE,
)

export const registerInstitutionRequest = login.createAction(
  types.REGISTER_INSTITUTION_POST_REQUEST,
)
export const registerInstitutionSuccess = login.createAction(
  types.REGISTER_INSTITUTION_POST_SUCCESS,
)
export const registerInstitutionFailure = login.createAction(
  types.REGISTER_INSTITUTION_POST_FAILURE,
)
export const registerInstitutionClean = login.createAction(types.REGISTER_INSTITUTION_CLEAN)

