import { VStack } from '@chakra-ui/react'
import LandingLayout from '../../../layouts/landing'
import UserComponet from '../../../components/profile/user'

const User = () => {
  return (
    <LandingLayout>
      <VStack>
        <UserComponet />
      </VStack>
    </LandingLayout>
  )
}

export default User
