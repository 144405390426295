import {
  Box,
  Card,
  CardBody,
  HStack,
  Heading,
  Image,
  Link,
  Stack,
  StackDivider,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import IconFacebook from '../../../../../icons/icon-facebook-blue.svg'
import IconInstagram from '../../../../../icons/icon-instagram-blue.svg'
import IconLinkedin from '../../../../../icons/icon-linkedin-blue.svg'
import IconTwitter from '../../../../../icons/icon-twitter-blue.svg'
import { colors } from '../../../../../../utils/colors'
import { useSelector } from 'react-redux'

const JobsExperienceResume = () => {
  const userInformation = useSelector((state: any) => state.user.userInformation ?? '')

  const { id } = useParams()

  const institutoFilter = userInformation?.Institutions.filter((item: any) => item.id === id)

  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [datos, setDatos] = useState({
    email: '-',
    telefono: '-',
    paginaweb: '-',
    instagram: '-',
    linkedin: '-',
    facebook: '-',
    twitter: '-',
  })

  useEffect(() => {
    userInformation === undefined || userInformation === null || userInformation === ''
      ? null
      : setearDatos()
  }, [userInformation, id])

  const setearDatos = () => {
    setDatos({
      ...datos,
      email: institutoFilter?.length > 0 && (institutoFilter[0]?.email ?? '-'),
      telefono: institutoFilter?.length > 0 && (institutoFilter[0]?.phoneNumber ?? '-'),
      paginaweb: institutoFilter?.length > 0 && (institutoFilter[0]?.webURL ?? '-'),
      instagram: institutoFilter?.length > 0 && (institutoFilter[0]?.instagramURL ?? '-'),
      linkedin: institutoFilter?.length > 0 && (institutoFilter[0]?.linkedinURL ?? '-'),
      facebook: institutoFilter?.length > 0 && (institutoFilter[0]?.facebookURL ?? '-'),
      twitter: institutoFilter?.length > 0 && (institutoFilter[0]?.twitterURL ?? '-'),
    })
  }

  return (
    <Card>
      <CardBody>
        <Stack divider={<StackDivider />} spacing='4'>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Email de institución
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.email || '-'}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Teléfono
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.telefono || '-'}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Página web
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.paginaweb || '-'}
            </Text>
          </Box>
          <Box display={isMobile ? 'block' : 'flex'} flexDirection={'column'} rowGap={'10px'}>
            <Heading
              fontSize='12px'
              color={'#004876'}
              fontWeight='bold'
              textTransform='uppercase'
              mb={3}
            >
              Redes sociales
            </Heading>
            <Text fontSize='12px' mt={isMobile ? 2 : 0} display='flex'>
              <Image src={IconInstagram} alt={'Instagram'} width={25} height={25} mr={3} />
              {datos?.instagram !== '' || datos.instagram === null ? (
                <RouterLink
                  target='_blank'
                  style={{ marginLeft: 2, color: colors.principal }}
                  to={`https://www.instagram.com/${datos.instagram}`}
                >
                  https://www.instagram.com/{datos.instagram}
                </RouterLink>
              ) : (
                '-'
              )}
            </Text>
            <Text fontSize='12px' mt={isMobile ? 2 : 0} display='flex'>
              <Image src={IconFacebook} alt={'Facebook'} width={25} height={25} mr={3} />
              {datos?.facebook !== '' ? (
                <RouterLink
                  target='_blank'
                  style={{ marginLeft: 2, color: colors.principal }}
                  to={`https://www.facebook.com/${datos.facebook}`}
                >
                  https://www.facebook.com/{datos.facebook}
                </RouterLink>
              ) : (
                '-'
              )}{' '}
            </Text>
            <Text fontSize='12px' mt={isMobile ? 2 : 0} display='flex'>
              <Image src={IconLinkedin} alt={'Linkedin'} width={25} height={25} mr={3} />
              {datos?.linkedin !== '' ? (
                <RouterLink
                  target='_blank'
                  style={{ marginLeft: 2, color: colors.principal }}
                  to={`https://www.linkedin.com/${datos.linkedin}`}
                >
                  https://www.linkedin.com/{datos.linkedin}
                </RouterLink>
              ) : (
                '-'
              )}
            </Text>
            <Text fontSize='12px' mt={isMobile ? 2 : 0} display='flex'>
              <Image src={IconTwitter} alt={'IconTwitter'} width={25} height={25} mr={3} />
              {datos?.twitter !== '' ? (
                <RouterLink
                  target='_blank'
                  style={{ marginLeft: 2, color: colors.principal }}
                  to={`https://www.twitter.com/${datos.twitter}`}
                >
                  https://www.twitter.com/{datos.twitter}
                </RouterLink>
              ) : (
                '-'
              )}
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  )
}

export default JobsExperienceResume
