import React from 'react'
import { Spinner } from '@chakra-ui/react'

interface LoaderProps {
  props: any
  size?: string
}

const Loader: React.FC<LoaderProps> = ({ props, size = 'xl' }) => {
  return (
    <div style={props}>
      <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size={size} />
    </div>
  )
}

export default Loader
