import { colors } from '../../../../utils/colors'

export const userModalStyles = {
  box: { height: 'auto', margin: 10 },
  heading: {
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '35.68px',
    color: colors.principal,
    marginBottom: '18px',
  },
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: '20px',
    marginBottom: 10,
  },
  input: { width: '340px' },
  submitButtonBox: { width: '100%', display: 'flex', justifyContent: 'center' },
  submitButton: {
    width: '340px',
    margin: 'auto auto',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: colors.principal,
    color: '#fff',
    borderRadius: '5px',
    padding: '12px 36px',
  },
  disabled: { opacity: '1 !important', textTransform: 'capitalize' },
}
