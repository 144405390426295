import * as Yup from 'yup'

import { AttachmentIcon, InfoIcon, QuestionIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import {
  registerInstitutionProfileClean,
  registerInstitutionProfileRequest,
  termsAndConditionsClean,
  termsAndConditionsRequest,
} from '../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import BlueButton from '../../buttons/button-blue'
import { PostRegisterInstitutionProfile } from '../../../state/modules/user/services/types'
import { RegisterInstitucionProfile } from './_types'
import { colors } from '../../../utils/colors'
import { useNavigate } from 'react-router'

const initialValues: RegisterInstitucionProfile = {
  cue: '',
  entramite: '',
  provincialNumber: '',
  institution: '',
  documentation: null,
}

const textoDocumentacion = `Deberá adjuntar la documentación que dé cuenta de:\n
-	Número provincial/CUE\n
-	Razón social que vincule a número provincial/CUE\n
-	Alta en AFIP con número de CUIT\n
-	Si está "En Trámite" presentación de carpeta de apertura para identificar nivel educativo y razón social
`
const RegisterInstitutionCompoProfile: FC = () => {
  const toast = useToast()
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const state = useSelector((state: any) => state)
  const {
    registerInstitutionSuccess,
    statusRegisterInstitution,
    termsAndConditionsSuccess,
    termsAndConditionsStatus,
  } = useSelector((state: any) => state?.user)
  const [isChecked, setIsChecked] = useState(false)
  const [isRegistering, setIsRegistering] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [filesUp, setFilesUp] = useState<File[]>([])
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([])
  const [fileNotAllowed, setFileNotAllowed] = useState<boolean>(false)
  const message =
    'Al seleccionar la opción “En trámite” se le asignará un CUE y un Número Provincial provisorio generado por EDUprivada.'
  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    setFileNotAllowed(false)

    if (files && files.length > 0) {
      const selectedFiles = Array.from(files)
      selectedFiles.forEach((file) => {
        if (
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png' ||
          file.type === 'application/pdf'
        ) {
          setFilesUp((prev) => [...prev, file])
          setSelectedFileNames((prev) => [...prev, file.name])
        } else {
          setFileNotAllowed(true)
        }
      })
    }
  }

  function generateRandomNumber(): number {
    const min = 100000 // Valor mínimo de 6 dígitos
    const max = 999999 // Valor máximo de 6 dígitos

    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  useEffect(() => {
    if (registerInstitutionSuccess) {
      toast({
        description:
          'Su solicitud de Registro de Institución ha sido enviada a AIEPBA. A la brevedad lo estaremos contactando. Muchas gracias.',
        status: 'success',
        duration: 2000,
      })
      setTimeout(() => {
        navigate('/home')
        setIsRegistering(false)
      }, 2000)
      dispatch(registerInstitutionProfileClean())
    }

    if (registerInstitutionSuccess === false) {
      toast({
        description: statusRegisterInstitution.response?.data?.msg,
        status: 'error',
        duration: 2000,
      })

      dispatch(registerInstitutionProfileClean())
      setIsRegistering(false)
    }
  }, [registerInstitutionSuccess])

  useEffect(() => {
    if (
      termsAndConditionsSuccess &&
      termsAndConditionsSuccess !== null &&
      termsAndConditionsStatus
    ) {
      const link = document.createElement('a')
      const blobURL = URL.createObjectURL(termsAndConditionsStatus)
      link.href = blobURL
      link.target = '_blank'
      link.click()

      dispatch(termsAndConditionsClean())
    }

    if (termsAndConditionsSuccess === false) {
      toast({
        description: termsAndConditionsStatus.response?.data?.msg,
        status: 'error',
        duration: 2000,
      })

      dispatch(termsAndConditionsClean())
    }
  }, [termsAndConditionsSuccess])

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }

  const handleBackRegister = () => {
    navigate('/home')
  }

  const validateSchema = Yup.object({
    cue: Yup.number()
      .typeError('Solo se permiten números')
      .max(999999999999999, 'Maximo 15 caracteres')
      .required('Campo obligatorio'),
    provincialNumber: Yup.number()
      .typeError('Solo se permiten números')
      .max(9999999999, 'Maximo 10 caracteres')
      .required('Campo obligatorio'),
    institution: Yup.string().required('Campo obligatorio'),
  }).defined()

  const onSubmit = (values: RegisterInstitucionProfile) => {
    const updatedInitialValuesForm: PostRegisterInstitutionProfile = {
      userId: state.user.userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      cue: values.cue ? values.cue : '000000',
      entramite: values.entramite,
      provincialNumber: values.provincialNumber ? values.provincialNumber : '000000',
      institution: values.institution,
      filesUp: filesUp,
      navigate: navigate,
    }
    setIsRegistering(true)

    dispatch(registerInstitutionProfileRequest(updatedInitialValuesForm))
  }

  const handleChangeCue = (handleChange: any, e: any) => {
    const number = Number(e.nativeEvent.data)
    if (number || e.nativeEvent.data == null || e.nativeEvent.data === '0') handleChange(e)
  }

  const handleChangeProvincialNumber = (handleChange: any, e: any) => {
    const value = e.target.value

    const number = Number(e.nativeEvent.data)
    if (number || e.nativeEvent.data == null || e.nativeEvent.data === '0') handleChange(e)
  }

  const handleClickTermsAndConditions = () => {
    dispatch(termsAndConditionsRequest())
  }

  const handleChangeEnTramite = (setFieldValue: any, value: any, values: any) => {
    setFieldValue('entramite', value)
    setTimeout(() => {
      setFieldValue(
        'provincialNumber',
        value === 'Si' ? generateRandomNumber() : values.provincialNumber,
      )
    }, 100)
    setTimeout(() => {
      setFieldValue('cue', value === 'Si' ? generateRandomNumber() : values.cue)
    }, 110)
  }

  return (
    <Box p={isMobile ? 8 : 0}>
      <Button
        onClick={handleBackRegister}
        color='#56769A'
        backgroundColor={'white'}
        marginLeft={'-3.5'}
      >
        <Text fontSize={'10px'} fontWeight={'normal'}>
          Volver
        </Text>
      </Button>

      <Heading as='h4' size='md' mb={4}>
        Registrar Institución Educativa
      </Heading>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validateSchema}>
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => {
          const errorsFields = Object.values(errors)

          return (
            <Form>
              <Stack spacing={4}>
                <FormControl>
                  <Flex direction={'row'}>
                    <Box flex={1}>
                      <FormLabel htmlFor='cue' fontSize={'sm'}>
                        CUE
                      </FormLabel>
                      <Input
                        id='cue'
                        name='cue'
                        type='string'
                        max={'999999999999999'}
                        fontSize={'sm'}
                        value={values.cue}
                        onChange={(e) => handleChangeCue(handleChange, e)}
                        onBlur={handleBlur}
                      />
                      {errors.cue && touched.cue && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.cue}
                        </Text>
                      )}
                    </Box>
                    <Box flex={1}>
                      <FormLabel htmlFor='entramite' fontSize={'sm'}>
                        En trámite{' '}
                        {isMobile ? (
                          <Popover>
                            <PopoverTrigger>
                              <Button backgroundColor='transparent'>
                                <QuestionIcon color={colors.principal} />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverBody
                                sx={{
                                  fontSize: '16px',
                                  textTransform: 'initial',
                                  fontWeight: 'initial',
                                }}
                              >
                                {message}
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        ) : (
                          <Tooltip label={message}>
                            <QuestionIcon />
                          </Tooltip>
                        )}
                      </FormLabel>
                      <Center height='50%'>
                        <RadioGroup
                          defaultValue='No'
                          onChange={(value) => {
                            handleChangeEnTramite(setFieldValue, value, values)
                          }}
                        >
                          <Stack direction='row' spacing='8'>
                            <Radio value='Si'>Si</Radio>
                            <Radio value='No'>No</Radio>
                          </Stack>
                        </RadioGroup>
                      </Center>
                    </Box>
                  </Flex>
                  <FormLabel htmlFor='provincialNumber' fontSize={'sm'}>
                    Número Provincial
                  </FormLabel>
                  <Input
                    id='provincialNumber'
                    name='provincialNumber'
                    type='string'
                    fontSize={'sm'}
                    value={values.provincialNumber}
                    onChange={(e) => handleChangeProvincialNumber(handleChange, e)}
                    onBlur={handleBlur}
                  />
                  {errors.provincialNumber && touched.provincialNumber ? (
                    <Text color='red' fontSize={'sm'}>
                      {errors.provincialNumber}
                    </Text>
                  ) : null}
                  <FormLabel htmlFor='institution' fontSize={'sm'}>
                    Nombre de la Institución
                  </FormLabel>
                  <Input
                    id='institution'
                    name='institution'
                    type='text'
                    fontSize={'sm'}
                    value={values.institution}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.institution && touched.institution ? (
                    <Text color='red' fontSize={'sm'}>
                      {errors.institution}
                    </Text>
                  ) : null}
                </FormControl>
                <Box>
                  <Checkbox isChecked={isChecked} onChange={handleCheckboxChange}>
                    <Text
                      fontSize={'10px'}
                      fontWeight={'normal'}
                      align={isMobile ? 'center' : 'inherit'}
                    >
                      Acepto los{' '}
                      <Button
                        fontSize={'10px'}
                        color={colors.principal}
                        variant='link'
                        onClick={handleClickTermsAndConditions}
                      >
                        términos y condiciones
                      </Button>{' '}
                      de EDUprivada
                    </Text>
                  </Checkbox>
                  {fileNotAllowed ? (
                    <Box mt={1} display={'flex'} alignItems={'center'}>
                      <WarningIcon boxSize={4} mr={2} color={'red'} />
                      <Text
                        fontSize={'10px'}
                        fontWeight={'normal'}
                        align={isMobile ? 'center' : 'inherit'}
                        color={'red'}
                      >
                        Archivo/s no permitido/s. Solo se permiten: JPG, JPEG, PNG, PDF. - cantidad:{' '}
                        {selectedFileNames.length}
                      </Text>
                    </Box>
                  ) : (
                    selectedFileNames.length > 0 && (
                      <Box mt={1} display={'flex'}>
                        <AttachmentIcon boxSize={4} mr={2} />
                        <Text
                          fontSize={'10px'}
                          fontWeight={'normal'}
                          align={isMobile ? 'center' : 'inherit'}
                          color={'green'}
                        >
                          Archivo/s adjunto/s - cantidad: {selectedFileNames.length}
                        </Text>
                      </Box>
                    )
                  )}
                </Box>
                <Box>
                  <Flex>
                    <Button
                      onClick={handleButtonClick}
                      isDisabled={!isChecked || errorsFields.length > 0}
                      width={'90%'}
                      borderColor='black'
                      color='black'
                      variant={'outline'}
                    >
                      <Text size={'sm'} fontWeight={'normal'}>
                        Adjuntar documentación
                      </Text>
                    </Button>
                    <input
                      ref={fileInputRef}
                      type='file'
                      multiple
                      style={{ display: 'none' }}
                      onChange={handleFileSelect}
                    />
                    <Box ml={2} mt={1}>
                      {isMobile ? (
                        <Popover>
                          <PopoverTrigger>
                            <Button backgroundColor='transparent'>
                              <QuestionIcon color={colors.principal} />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody
                              sx={{
                                fontSize: '16px',
                                textTransform: 'initial',
                                fontWeight: 'initial',
                              }}
                            >
                              {textoDocumentacion}
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      ) : (
                        <Tooltip label={textoDocumentacion}>
                          <span>
                            <InfoIcon boxSize={7} color='blue.500' />
                          </span>
                        </Tooltip>
                      )}
                    </Box>
                  </Flex>
                </Box>
                <Box>
                  <BlueButton
                    type='submit'
                    isDisabled={!isChecked || errorsFields.length > 0}
                    isLoading={isRegistering}
                    width={'full'}
                  >
                    <Text size={'sm'} fontWeight={'normal'}>
                      Confirmar
                    </Text>
                  </BlueButton>
                </Box>
              </Stack>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default RegisterInstitutionCompoProfile
