import { colors } from '../../../../../../utils/colors'

export const showDataTableStyles = {
  tHead: { textTransform: 'initial', fontSize: '11px', color: colors.principal, padding: '8px' },
  tData: { fontSize: '11px', padding: '8px' },
  socialSkillsData: {
    fontSize: '13px',
    border: '2px',
    borderColor: colors.principal,
    borderRadius: '8px',
    padding: '4px',
  },
  heading: {
    color: '#004876',
    padding: '16px',
    fontWeight: 'bold',
    fontSize: '14px',
    mb: 5,
  },
  skillsHeading: { color: '#004876', padding: '16px', fontWeight: 'bold', fontSize: '14px' },
  table: { width: '90%', margin: '0 auto' },
  noResults: {
    width: '80%',
    height: '15vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  skillsDataContainerMobile: { display: 'flex', flexWrap: 'wrap' },
}
