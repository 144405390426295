import { Image } from '@chakra-ui/image'
import { Flex, VStack, Heading, Stack, Text } from '@chakra-ui/layout'
import EDUprivada from '../../../assets/images/logo-eduprivada.svg'
import { colors } from '../../../../utils/colors'
import { Box } from '@chakra-ui/react'
import { landingStyles } from '../../../../pages/landing/styles'
import { LandingMarginProps } from '../../../../pages/landing/regular-landing'
import PropTypes from 'prop-types'
const Statistics: React.FC<LandingMarginProps> = ({ contPadding }) => {

  return (
    <Box
      display='flex'
      width={['100vw', '100vw']}
      height={['290px']}
      justifyContent={['space-between', 'space-between']}
      css={{
        '@media screen and (min-width: 1536px)': {
          justifyContent: 'space-around',
        }
      }}
      alignItems={'center'}
      p={['16px 32px', '16px 32px']}
      color='#fff'
      fontSize={'5rem'}
      sx={landingStyles.statistics.mainBackground}
      px={{ base: '10px', md: '42.5px', lg: contPadding, '2xl': contPadding }}
    >
      <Box
        width={['44%', '30%']}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyItems='center'
      >
        <Box
          width={{ base: '100%', md: '100%', lg: '90%', xl: '60%', '2xl': '40%' }}
          height={['100%']}
          display='flex'
          alignItems={'center'}
          justifyItems='center'
        >
          <Box
            width={['20%']}
            height={['50%']}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            alignSelf={'flex-start'}
          >
            <Text
              fontSize={['35px', '65px']}
            >
              +
            </Text>
          </Box>
          <Box
            width={['80%']}
            display='flex'
            flexDirection='column'
          >
            <Box
              width={['100%']}
              height={['50%']}
              display='flex'
              alignItems='flex-start'
            >
              <Box
                m='0'
              >
                <Text
                  fontSize={['30px', '65px']}
                  h='15%'
                  mb={['-10px', '-20px']}
                >
                  2500
                </Text>
              </Box>
            </Box>
            <Box
              width={['100%']}
              height={['50%']}
            >
              <Text
                fontSize={{ base: '10px', md: '18px', lg: '20px' }}
              >
                Instituciones nos <br /> acompañan
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      
      <Box
        width={['44%', '30%']}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyItems='center'
      >
        <Box
          width={{ base: '100%', md: '100%', lg: '90%', xl: '60%', '2xl': '40%' }}
          height={['100%']}
          display='flex'
          alignItems={'center'}
          justifyItems='center'
        >
          <Box
            width={['20%']}
            height={['50%']}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            alignSelf={'flex-start'}
          >
            <Text
              fontSize={['35px', '65px']}
            >
              +
            </Text>
          </Box>
          <Box
            width={['80%']}
            display='flex'
            flexDirection='column'
          >
            <Box
              width={['100%']}
              height={['50%']}
              display='flex'
              alignItems='flex-start'
            >
              <Box
                m='0'
              >
                <Text
                  fontSize={['30px', '65px']}
                  h='15%'
                  mb={['-10px', '-20px']}
                >
                  200
                </Text>
              </Box>
            </Box>
            <Box
              width={['100%']}
              height={['50%']}
            >
              <Text
                fontSize={{ base: '10px', md: '18px', lg: '20px' }}
              >
                Postulaciones <br /> a empleos
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width={['44%', '30%']}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyItems='center'
      >
        <Box
          width={{ base: '100%', md: '100%', lg: '90%', xl: '60%', '2xl': '40%' }}
          height={['100%']}
          display='flex'
          alignItems={'center'}
          justifyItems='center'
        >
          <Box
            width={['20%']}
            height={['50%']}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            alignSelf={'flex-start'}
          >
            <Text
              fontSize={['35px', '65px']}
            >
              +
            </Text>
          </Box>
          <Box
            width={['80%']}
            display='flex'
            flexDirection='column'
          >
            <Box
              width={['100%']}
              height={['50%']}
              display='flex'
              alignItems='flex-start'
            >
              <Box
                m='0'
              >
                <Text
                  fontSize={['30px', '65px']}
                  h='15%'
                  mb={['-10px', '-20px']}
                >
                  300
                </Text>
              </Box>
            </Box>
            <Box
              width={['100%']}
              height={['50%']}
            >
              <Text
                fontSize={{ base: '10px', md: '18px', lg: '20px' }}
              >
                Usuarios <br /> activos
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
Statistics.propTypes = {
  contPadding: PropTypes.string.isRequired,
}
export default Statistics
