import { colors } from '../../../../utils/colors'

export const associatedUsersModalStyles = {
  box: {
    height: 'auto',
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: { alignSelf: 'flex-start', color: colors.principal, marginBottom: 10, fontSize: '32px' },
  tableSection: { width: '100%' },
  tableHead: { fontWeight: 'bold', fontSize: '10px', color: colors.principal },
  tableData: { fontSize: '10px' },
  actionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '15%',
  },
  loading: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    textTransform: 'capitalize',
    padding: '4px 8px',
    fontSize: '10px',
    borderRadius: '5px',
  },
}
