import React from 'react'
import LandingLayout from '../../layouts/landing'
import { VStack } from '@chakra-ui/react'
import Jobs from '../../components/Jobs'

export const JobAds: React.FC = () => {
  return (
    <LandingLayout>
      <VStack>
        <Jobs />
      </VStack>
    </LandingLayout>
  )
}

export default JobAds
