import { colors } from '../../../utils/colors'

const icon = { color: 'red', cursor: 'pointer' }

export const favoritesFoldersModalStyles = {
  heading: {
    fontSize: '28px',
    fontFamily:'Montserrat-Light', 
    color:colors.principal
  },
  subHeading: {
    fontSize: '20px',
    fontFamily:'Montserrat', 
    color:colors.secundary
  },
  folderContainer: {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    maxHeight: '250px',
    overflowY: 'auto',
  },
  folder: {
    display: 'flex',
    alignItems: 'center',
  },
  folderCheckbox: {
     marginRight: '8px',

     },
  folderText: {
     color: '#000',
      display: 'flex', 
      alignItems: 'center',
      fontFamily:'Montserrat',
      fontSize:'16px'
     },
  checkIcon: { ...icon, boxSize: 3 },
  closeIcon: { ...icon, boxSize: 2.5 },
  addFolderInput: { fontSize: '12px', lineHeight: '10px' },
  addFolderContainer: { display: 'flex', gap: '10px', alignItems: 'center', width: '55%' },
  addLink: {
    fontFamily: 'Montserrat-Semibold',
     fontSize: '17px', 
     color: colors.institutionalRed ,
    },
  actionButton: {
    fontFamily: 'Montserrat-Light',
    borderRadius: '6px',
    backgroundColor: `${colors.institutionalRed}`,
    padding: '20px 35px',
    color: '#fff',
    fontSize: '14px',
  },
}
