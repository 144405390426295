import {
  Box,
  Divider,
  Grid,
  GridItem,
  HStack,
  Hide,
  Image,
  Text,
  VStack,
  Link,
  useMediaQuery,
  Flex,
  Stack,
  Show,
} from '@chakra-ui/react'
import { colors } from '../../utils/colors'
// import LogoAIEPBA from '../../assets/images/landing/logo.png'
import AIEPBAImage from '../../assets/images/logo-eduprivada.svg'

import IconInstagram from '../../assets/images/landing/isntagram.png'
import { footerStyles } from './styles'

const Footer = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width={['100vw']}
      color={colors.principal}
      minH='300px'
      px={{ base: '10px', md: '0', lg: '0' }}
    // bgColor='green'
    >
      <Box
        display='flex'
        flexDirection={['column', 'row']}
        alignItems={['center', 'center']}
        mb='2'
        // bgColor='yellow'
        w={['100%', 'unset']}
      >
        <Box
          h='127px'
          w={{ base: '35%', md: '200px', lg: '206px' }}
          display='flex'
          justifyContent='center'
          alignItems='center'
          mr={{ base: '10px', md: '20px', lg: '90px' }}
        >
          <Image
            src={AIEPBAImage}
            alt={'AIEPBA'}
            width={{ base: '80%', md: '90%', lg: '90%' }}
          />
        </Box>
        <VStack
          ml={{ base: '10px', md: '20px', lg: '100px' }}
          alignItems={['center', 'flex-start']}
          width={{ base: '100%', md: '100%', lg: '100%' }}
        >
          <Box
            width={{ base: '100%', md: '100%', lg: '100%' }}
          >
            <Text
              fontSize={['1rem', '28px']}
              textAlign={['center', 'left']}
              width={'100%'}
              color='#004876'
            >
              Contactate con nosotros
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: '14px', md: '18px', lg: '20px' }}
              textAlign={['center', 'left']}
              width={'100%'}
              color='#004876'
            >
              Soporte: soporte-eduprivada@aiepba.org.ar
            </Text>
          </Box>
          <Link href='https://www.instagram.com/edu.privada/' isExternal>
            <HStack>
              <Image src={IconInstagram} alt={'Instagram'} width='20px' height='20px' />
              <Text
                fontSize={['1rem', '18px']}
                textAlign='left'
                width={'100%'}
                color='#004876'
              >
                @edu.privada
              </Text>
            </HStack>
          </Link>
        </VStack>
      </Box>

      <VStack
        mt='2'
      >
        <Box>
          <Text
            fontSize={{ base: '12px', md: '14px', lg: '15px' }}
            textAlign='center'
          >
            © 2024 - todos los derechos reservados - EDUPrivada, una plataforma desarrollada por
            <Link href='https://www.aiepba.org/' isExternal sx={footerStyles.Link.baseStyle}>
              <Text id='linkAIEPBA' as='span' fontWeight='extrabold' color={colors.institutionalRed}> AIEPBA</Text>
            </Link>
          </Text>
        </Box>
        <Box>
          <Text fontSize={['15px']} mt='30px'>
            Imágenes de www.freepik.es
          </Text>
        </Box>
      </VStack>
    </Flex>
  )
}

export default Footer
