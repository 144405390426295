import { FC, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { loginClean, loginRequest } from '../../state/modules/login/actions'
import { PostLogin } from '../../state/modules/login/services/types'
import LoginSchema from './_schema'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  Center,
  Stack,
  InputGroup,
  InputRightElement,
  Link,
  useToast,
  useBoolean,
  useMediaQuery,
  UseToastOptions,
} from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import BlueButton from '../buttons/button-blue'
import LoginComenius from './comenius'
import { colors } from '../../utils/colors'
import { State } from '../../state/types'
import { NavigateFunction, useNavigate } from 'react-router'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'

const newNavigate = {} as NavigateFunction

const initialValues: PostLogin = {
  email: '',
  password: '',
  navigate: newNavigate,
}

const toastMessages: UseToastOptions[] = [
  {
    description: 'Has iniciado sesión correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Usuario y/o contraseña incorrectos',
    status: 'error',
    duration: 2000,
  },
  {
    description: 'Error de conexión. Por favor intente más tarde',
    status: 'error',
    duration: 2000,
  },
]

const LoginCompo: FC = () => {
  const toast = useToast()
  const [showPassword, setShowPassword] = useBoolean()
  const [searchParams] = useSearchParams()
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loginSuccess, statusLogin } = useSelector((state: State) => state.login)
  const { userInformation } = useSelector((state: State) => state.user)
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    if (loginSuccess === false) {
      if (!statusLogin.response) {
        toast({ description: statusLogin.message, status: 'error', duration: 2000 })
      } else toast(toastMessages[1])
      dispatch(loginClean())
      setIsLogin(false)
    }

    if (loginSuccess && loginSuccess !== null && loginSuccess !== undefined) {
      toast(toastMessages[0])
      dispatch(loginClean())
      setIsLogin(false)
    }
  }, [loginSuccess])

  useEffect(() => {
    const oAuthCode = searchParams.get('access_token')
    if (oAuthCode !== null && oAuthCode !== undefined) {
      const payload = {
        email: searchParams.get('email'),
        password: searchParams.get('access_token'),
        navigate: navigate,
      }
      dispatch(loginRequest(payload))
    }
  }, [])

  const onSubmit = (values: PostLogin) => {
    values['navigate'] = navigate
    dispatch(loginRequest(values))
    setIsLogin(true)
  }

  const handleResetPassword = () => {
    navigate('/password/reset')
  }

  return (
    <Box p={isMobile ? 8 : 0}>
       <Center>
        <Heading as='h4' size='md' mb={4}>
          Inicio de sesión
        </Heading>
      </Center>
      <Box>
        <LoginComenius />
      </Box>
      <Center my='12px'>o</Center>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={LoginSchema}>
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Stack spacing={4}>
              <FormControl isInvalid={Boolean(errors.email && touched.email)}>
                <FormLabel htmlFor='email' fontSize={'sm'}>
                  Email
                </FormLabel>
                <Input
                  id='email'
                  name='email'
                  type='text'
                  fontSize={'sm'}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.email}
                  </Text>
                ) : null}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.password && touched.password)}>
                <FormLabel htmlFor='password' fontSize={'sm'}>
                  Contraseña
                </FormLabel>
                <InputGroup>
                  <Input
                    id='password'
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    fontSize={'sm'}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <ViewOffIcon cursor={'pointer'} onClick={setShowPassword.toggle} />
                    ) : (
                      <ViewIcon cursor={'pointer'} onClick={setShowPassword.toggle} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {errors.password && touched.password ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.password}
                  </Text>
                ) : null}
              </FormControl>
              <Box>
                <BlueButton
                  type='submit'
                  isDisabled={Object.keys(errors).length > 0}
                  width={'full'}
                  isLoading={isLogin}
                >
                  <Text size={'sm'} fontWeight={'normal'}>
                    Ingresar
                  </Text>
                </BlueButton>
              </Box>
              <Box>
                <BlueButton
                  colorScheme='blue'
                  variant='link'
                  width={'full'}
                  onClick={handleResetPassword}
                >
                  <Text size={'sm'} fontWeight={'normal'}>
                    Olvidé mi contraseña
                  </Text>
                </BlueButton>
              </Box>
              <Box>
                <Text fontSize={'sm'} fontWeight={'normal'} align={isMobile ? 'center' : 'inherit'}>
                  ¿No tenes cuenta en EDUPrivada?{' '}
                  <Link
                    as={RouterLink}
                    color={colors.brand.blue.default}
                    variant='link'
                    to='/register'
                  >
                    Creá una
                  </Link>
                </Text>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default LoginCompo
