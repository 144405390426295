import * as types from './types'

import { profiles } from './_ducks'

export const viewRequisitionsRequest = profiles.createAction(types.VIEW_REQUISITIONS_GET_REQUEST)
export const viewRequisitionsSuccess = profiles.createAction(types.VIEW_REQUISITIONS_GET_SUCCESS)
export const viewRequisitionsFailure = profiles.createAction(types.VIEW_REQUISITIONS_GET_FAILURE)
export const viewRequisitionsClean = profiles.createAction(types.VIEW_REQUISITIONS_GET_CLEAN)

export const profilesRequest = profiles.createAction(types.PROFILES_POST_REQUEST)
export const profilesSuccess = profiles.createAction(types.PROFILES_POST_SUCCESS)
export const profilesFailure = profiles.createAction(types.PROFILES_POST_FAILURE)
export const profilesClean = profiles.createAction(types.PROFILES_POST_CLEAN)

export const handleViewRequisitionRequest = profiles.createAction(
  types.HANDLE_REQUISITION_PUT_REQUEST,
)
export const handleViewRequisitionSuccess = profiles.createAction(
  types.HANDLE_REQUISITION_PUT_SUCCESS,
)
export const handleViewRequisitionFailure = profiles.createAction(
  types.HANDLE_REQUISITION_PUT_FAILURE,
)
export const handleViewRequisitionClean = profiles.createAction(types.HANDLE_REQUISITION_PUT_CLEAN)

export const sendViewRequisitionRequest = profiles.createAction(types.SEND_REQUISITION_POST_REQUEST)
export const sendViewRequisitionSuccess = profiles.createAction(types.SEND_REQUISITION_POST_SUCCESS)
export const sendViewRequisitionFailure = profiles.createAction(types.SEND_REQUISITION_POST_FAILURE)
export const sendViewRequisitionClean = profiles.createAction(types.SEND_REQUISITION_POST_CLEAN)

export const getProfileRequest = profiles.createAction(types.PROFILE_GET_REQUEST)
export const getProfileSuccess = profiles.createAction(types.PROFILE_GET_SUCCESS)
export const getProfileFailure = profiles.createAction(types.PROFILE_GET_FAILURE)
export const getProfileClean = profiles.createAction(types.PROFILE_GET_CLEAN)

export const downloadSpreadsheetRequest = profiles.createAction(
  types.DOWNLOAD_SPREADSHEET_POST_REQUEST,
)
export const downloadSpreadsheetSuccess = profiles.createAction(
  types.DOWNLOAD_SPREADSHEET_POST_SUCCESS,
)
export const downloadSpreadsheetFailure = profiles.createAction(
  types.DOWNLOAD_SPREADSHEET_POST_FAILURE,
)
export const downloadSpreadsheetClean = profiles.createAction(types.DOWNLOAD_SPREADSHEET_POST_CLEAN)

export const getInstitutionRequest = profiles.createAction(types.INSTITUTION_GET_REQUEST)
export const getInstitutionSuccess = profiles.createAction(types.INSTITUTION_GET_SUCCESS)
export const getInstitutionFailure = profiles.createAction(types.INSTITUTION_GET_FAILURE)
export const getInstitutionClean = profiles.createAction(types.INSTITUTION_GET_CLEAN)

export const getUserInvitesRequest = profiles.createAction(types.USER_INVITES_GET_REQUEST)
export const getUserInvitesSuccess = profiles.createAction(types.USER_INVITES_GET_SUCCESS)
export const getUserInvitesFailure = profiles.createAction(types.USER_INVITES_GET_FAILURE)
export const getUserInvitesClean = profiles.createAction(types.USER_INVITES_GET_CLEAN)

export const getInstitutionInviteHistoryRequest = profiles.createAction(
  types.INSTITUTION_INVITES_HISTORY_GET_REQUEST,
)
export const getInstitutionInviteHistorySuccess = profiles.createAction(
  types.INSTITUTION_INVITES_HISTORY_GET_SUCCESS,
)
export const getInstitutionInviteHistoryFailure = profiles.createAction(
  types.INSTITUTION_INVITES_HISTORY_GET_FAILURE,
)
export const getInstitutionInviteHistoryClean = profiles.createAction(
  types.INSTITUTION_INVITES_HISTORY_GET_CLEAN,
)
export const newInviteRequest = profiles.createAction(types.NEW_INVITE_POST_REQUEST)
export const newInviteSuccess = profiles.createAction(types.NEW_INVITE_POST_SUCCESS)
export const newInviteFailure = profiles.createAction(types.NEW_INVITE_POST_FAILURE)
export const newInviteClean = profiles.createAction(types.NEW_INVITE_POST_CLEAN)

export const handleInviteRequest = profiles.createAction(types.HANDLE_INVITE_PUT_REQUEST)
export const handleInviteSuccess = profiles.createAction(types.HANDLE_INVITE_PUT_SUCCESS)
export const handleInviteFailure = profiles.createAction(types.HANDLE_INVITE_PUT_FAILURE)
export const handleInviteClean = profiles.createAction(types.HANDLE_INVITE_PUT_CLEAN)

export const resendInviteRequest = profiles.createAction(types.RESEND_INVITE_POST_REQUEST)
export const resendInviteSuccess = profiles.createAction(types.RESEND_INVITE_POST_SUCCESS)
export const resendInviteFailure = profiles.createAction(types.RESEND_INVITE_POST_FAILURE)
export const resendInviteClean = profiles.createAction(types.RESEND_INVITE_POST_CLEAN)
