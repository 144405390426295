import { Box, Icon, Input, Text } from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import React, { RefObject, useRef } from 'react'

import { colors } from '../../../utils/colors'

const icon = { cursor: 'pointer', color: colors.principal }

const editInputStyles = {
  addFolderInput: { fontSize: '12px', lineHeight: '10px' },
  addFolderContainer: { display: 'flex', gap: '10px', alignItems: 'center' },
  emptyText: { color: colors.red, fontSize: '12px' },
  checkIcon: { ...icon, boxSize: 3 },
  closeIcon: { ...icon, boxSize: 2.5 },
}

const EditInput = ({
  onConfirm,
  onClose,
  isEmpty,
  setIsEmpty,
  editedText,
}: {
  onConfirm: any
  onClose: any
  isEmpty: boolean
  setIsEmpty: any
  editedText?: string
}) => {
  const name: RefObject<HTMLInputElement> = useRef(null)

  return (
    <>
      <Box sx={editInputStyles.addFolderContainer}>
        <Input
          ref={name}
          size={'xs'}
          sx={editInputStyles.addFolderInput}
          type='text'
          defaultValue={editedText}
          maxLength={20}
          onChange={() => setIsEmpty(false)}
        />
        <Icon
          sx={editInputStyles.checkIcon}
          as={CheckIcon}
          onClick={() => onConfirm(name.current?.value)}
        />
        <Icon sx={editInputStyles.closeIcon} as={CloseIcon} onClick={onClose} />
      </Box>
      {isEmpty && <Text sx={editInputStyles.emptyText}>Campo obligatorio</Text>}
    </>
  )
}

export default EditInput
