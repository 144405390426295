import { FC } from 'react'
import Favorites from '../../components/Favorites'
import LandingLayout from '../../layouts/landing'
import { VStack } from '@chakra-ui/react'

const FavoritesHandler: FC = () => {
  return (
    <LandingLayout>
      <VStack>
        <Favorites />
      </VStack>
    </LandingLayout>
  )
}

export default FavoritesHandler
