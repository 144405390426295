import { colors } from '../../utils/colors'

export const orderByInputStyles = {
  container: {
    width: '322px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    margin: '0 0 25px auto',
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: colors.principal,
  },
  select: {
    width: '100%',
    color: colors.principal,
  },
}
