import { AxiosPromise } from 'axios'
import httpModule from '../../../../utils/http'
import {
  PostGenerate,
  PostLogin,
  PostRegisterInstitution,
  PostRegisterPostulante,
  PostVerify,
  PutPassword,
  PutPasswordProfile,
  oAuthCode,
} from './types'

const paths = {
  HEALTH: 'liveness/health',
  LOGIN_AUTH: 'login/auth',
  ROOT_USER: '/users',
  GENERATE_CODE: '/users/password-recovery/generate-code',
  VERIFY_CODE: '/users/password-recovery/verify-code',
  CHANGE_PASSWORD: '/password-recovery/change-password',
  CHANGE_PASSWORD_PROFILE: '/password-recovery/change-password',
  REGISTER: '/users/register',
  OAUTH_URL: 'login/oauth',
}

export const getHealth = (): AxiosPromise<any> => httpModule.get(paths.HEALTH)

export const login = (values: PostLogin): AxiosPromise<any> =>
  httpModule.post(paths.LOGIN_AUTH, values)

export const generateCode = (values: PostGenerate): AxiosPromise<any> =>
  httpModule.post(paths.GENERATE_CODE, values)

export const verifyCode = (values: PostVerify): AxiosPromise<any> =>
  httpModule.post(paths.VERIFY_CODE, values)

export const changePassword = (values: PutPassword): AxiosPromise<any> =>
  httpModule.put(paths.ROOT_USER + paths.CHANGE_PASSWORD, {
    email: values.email,
    password: values.password,
    code: values.code,
  })
export const changePasswordProfile = (values: PutPasswordProfile): AxiosPromise<any> =>
  httpModule.post(
    paths.ROOT_USER + `/${values.userId}` + paths.CHANGE_PASSWORD_PROFILE,
    {
      password: values.password,
      newPassword: values.newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const registerPostulante = (values: PostRegisterPostulante): AxiosPromise<any> => {
  const formData = new FormData()

  // Agregar los campos de texto plano al FormData
  const payload = {
    lastName: values.lastName,
    name: values.name,
    dni: values.dni,
    email: values.email,
    password: values.password,
    cue: '',
    entramite: '',
    provincialNumber: '',
  }

  formData.append('data', JSON.stringify(payload))

  // Realizar la solicitud HTTP con axios
  return httpModule.post(paths.REGISTER, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const registerInstitution = (values: PostRegisterInstitution): AxiosPromise<any> => {
  const formData = new FormData()

  // Agregar los campos de texto plano al FormData
  const payload = {
    lastName: values.lastName,
    name: values.name,
    dni: values.dni,
    email: values.email,
    password: values.password,
    cue: values.cue,
    entramite: values.entramite,
    provincialNumber: values.provincialNumber,
  }

  formData.append('data', JSON.stringify(payload))

  // Agregar los archivos al FormData
  values.filesUp.forEach((file) => {
    formData.append('filesUp', file, file.name)
  })

  // Realizar la solicitud HTTP con axios
  return httpModule.post(paths.REGISTER, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

