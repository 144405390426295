import { colors } from '../../../utils/colors'

export const UsersStyles = {
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  addUserSection: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
  usersQuantity: { color: colors.principal, fontWeight: 'bold' },
  addUser: {
    alignSelf: 'flex-start',
    fontWeight: 'bold',
    cursor: 'pointer',
    border: `1px solid ${colors.principal}`,
    padding: '8px 16px',
    borderRadius: '5px ',
    color: colors.principal,
  },
  icon: { marginLeft: '10px', boxSize: '15px' },
}
