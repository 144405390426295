import HeaderLanding from '../../../components/landing/regular/header-landing'
import FeaturedAdvices from '../../../components/landing/regular/featured-advices'
import Statistics from '../../../components/landing/regular/statistics'
import FewStepsJob from '../../../components/landing/regular/few-steps-job'
import AreYouInstitution from '../../../components/landing/regular/are-you-Institution'
import OurUsersSays from '../../../components/landing/shared/our-users-says'
import Brands from '../../../components/landing/regular/brands'
import { Box, useBreakpoint } from '@chakra-ui/react'
import { IsInstit } from '..'
import PropTypes from 'prop-types'
export interface LandingMarginProps {
    contPadding: string
}

const RegularLanding: React.FC<IsInstit> = ({ isInstit }) => {
    const deviceWidth = useBreakpoint()
    const contPadding = deviceWidth === 'base' ? '42.5px' : '85px'

    return (
        <Box position='relative' overflow='hidden' mt='.4rem'>

            <Box
                w='740.09px'
                h='740.09px'
                position='absolute'
                top={{ base: '648px', md: '1187px', lg: '1168.9px', '2xl': '1628px' }}
                left={{ base: '-698px', md: '-692px', lg: '-651.96px', '2xl': '-651.96px' }}
                bgColor='rgba(148, 169, 190, 30%)'
                borderRadius='50%'
                zIndex='1'
            ></Box>
            <Box
                w='740.09px'
                h='740.09px'
                position='absolute'
                top={{ base: '610px', md: '1055px', lg: '1018.91px', '2xl': '1048px' }}
                right={{ base: '-701px', md: '-680px', lg: '-680px', '2xl': '-660.96px' }}
                bgColor='rgba(148, 169, 190, 30%)'
                borderRadius='50%'
                zIndex='1'
            ></Box>

            <Box mb={['80px', '160px']}>
                <HeaderLanding contPadding={contPadding} />
            </Box>
            <Box mb={['80px', '160px']}>
                <FeaturedAdvices contPadding={contPadding} />
            </Box>
            <Box mb={['80px', '160px']}>
                <Statistics contPadding={contPadding} />
            </Box>
            <Box mb={['80px', '160px']}>
                <FewStepsJob contPadding={contPadding} />
            </Box>
            <Box mb={['80px', '160px']}>
                <AreYouInstitution contPadding={contPadding} />
            </Box>
            <Box mb={['80px', '160px']}>
                <OurUsersSays contPadding={contPadding} isInstit={isInstit} />
            </Box>
            <Box mb={['80px', '160px']}>
                <Brands contPadding={contPadding} />
            </Box>
        </Box>
    )
}
RegularLanding.propTypes = {
    isInstit: PropTypes.bool.isRequired,
}
export default RegularLanding
