import * as types from './types'

import { user } from './_duck'

export const userInformationRequest = user.createAction(types.USER_INFORMATION_GET_REQUEST)
export const userInformationSuccess = user.createAction(types.USER_INFORMATION_GET_SUCCESS)
export const userInformationFailure = user.createAction(types.USER_INFORMATION_GET_FAILURE)

export const userInformationCleanRequest = user.createAction(types.USER_INFORMATION_CLEAN_REQUEST)

export const generalInformationRequest = user.createAction(types.GENERAL_INFORMATION_REQUEST)
export const generalInformationSuccess = user.createAction(types.GENERAL_INFORMATION_SUCCESS)
export const generalInformationFailure = user.createAction(types.GENERAL_INFORMATION_FAILURE)
export const generalInformationClean = user.createAction(types.GENERAL_INFORMATION_CLEAN)

export const personalDataRequest = user.createAction(types.PERSONAL_DATA_REQUEST)
export const personalDataSuccess = user.createAction(types.PERSONAL_DATA_SUCCESS)
export const personalDataFailure = user.createAction(types.PERSONAL_DATA_FAILURE)
export const personalDataClean = user.createAction(types.PERSONAL_DATA_CLEAN)

export const educationRequest = user.createAction(types.EDUCATION_REQUEST)
export const educationSuccess = user.createAction(types.EDUCATION_SUCCESS)
export const educationFailure = user.createAction(types.EDUCATION_FAILURE)
export const educationClean = user.createAction(types.EDUCATION_CLEAN)

export const jobsExperienceRequest = user.createAction(types.JOBS_EXPERIENCE_REQUEST)
export const jobsExperienceSuccess = user.createAction(types.JOBS_EXPERIENCE_SUCCESS)
export const jobsExperienceFailure = user.createAction(types.JOBS_EXPERIENCE_FAILURE)
export const jobsExperienceClean = user.createAction(types.JOBS_EXPERIENCE_CLEAN)

export const preferencesRequest = user.createAction(types.PREFERENCE_REQUEST)
export const preferencesSuccess = user.createAction(types.PREFERENCE_SUCCESS)
export const preferencesFailure = user.createAction(types.PREFERENCE_FAILURE)
export const preferencesClean = user.createAction(types.PREFERENCE_CLEAN)

export const registerInstitutionProfileRequest = user.createAction(
  types.REGISTER_INSTITUTION_PROFILE_POST_REQUEST,
)
export const registerInstitutionProfileSuccess = user.createAction(
  types.REGISTER_INSTITUTION_PROFILE_POST_SUCCESS,
)
export const registerInstitutionProfileFailure = user.createAction(
  types.REGISTER_INSTITUTION_PROFILE_POST_FAILURE,
)
export const registerInstitutionProfileClean = user.createAction(
  types.REGISTER_INSTITUTION_PROFILE_CLEAN,
)

export const validationMailRequest = user.createAction(types.VALIDATION_MAIL_REQUEST)
export const validationMailSuccess = user.createAction(types.VALIDATION_MAIL_SUCCESS)
export const validationMailFailure = user.createAction(types.VALIDATION_MAIL_FAILURE)

export const educationEditRequest = user.createAction(types.EDUCATION_EDIT_REQUEST)
export const educationEditSuccess = user.createAction(types.EDUCATION_EDIT_SUCCESS)
export const educationEditFailure = user.createAction(types.EDUCATION_EDIT_FAILURE)
export const educationEditClean = user.createAction(types.EDUCATION_EDIT_CLEAN)

export const educationDeleteRequest = user.createAction(types.EDUCATION_DELETE_REQUEST)
export const educationDeleteSuccess = user.createAction(types.EDUCATION_DELETE_SUCCESS)
export const educationDeleteFailure = user.createAction(types.EDUCATION_DELETE_FAILURE)
export const educationDeleteClean = user.createAction(types.EDUCATION_DELETE_CLEAN)

export const languagesRequest = user.createAction(types.LANGUAGES_LIST_GET_REQUEST)
export const languagesSuccess = user.createAction(types.LANGUAGES_LIST_GET_SUCCESS)
export const languagesFailure = user.createAction(types.LANGUAGES_LIST_GET_FAILURE)
export const languagesClean = user.createAction(types.LANGUAGES_LIST_GET_CLEAN)

export const languageEditRequest = user.createAction(types.LANGUAGE_EDIT_REQUEST)
export const languageEditSuccess = user.createAction(types.LANGUAGE_EDIT_SUCCESS)
export const languageEditFailure = user.createAction(types.LANGUAGE_EDIT_FAILURE)
export const languageEditClean = user.createAction(types.LANGUAGE_EDIT_CLEAN)

export const languageDeleteRequest = user.createAction(types.LANGUAGE_DELETE_REQUEST)
export const languageDeleteSuccess = user.createAction(types.LANGUAGE_DELETE_SUCCESS)
export const languageDeleteFailure = user.createAction(types.LANGUAGE_DELETE_FAILURE)
export const languageDeleteClean = user.createAction(types.LANGUAGE_DELETE_CLEAN)

export const jobsExperienceEditRequest = user.createAction(types.JOBS_EXPERIENCE_EDIT_REQUEST)
export const jobsExperienceEditSuccess = user.createAction(types.JOBS_EXPERIENCE_EDIT_SUCCESS)
export const jobsExperienceEditFailure = user.createAction(types.JOBS_EXPERIENCE_EDIT_FAILURE)
export const jobsExperienceEditClean = user.createAction(types.JOBS_EXPERIENCE_EDIT_CLEAN)

export const jobsExperienceDeleteRequest = user.createAction(types.JOBS_EXPERIENCE_DELETE_REQUEST)
export const jobsExperienceDeleteSuccess = user.createAction(types.JOBS_EXPERIENCE_DELETE_SUCCESS)
export const jobsExperienceDeleteFailure = user.createAction(types.JOBS_EXPERIENCE_DELETE_FAILURE)
export const jobsExperienceDeleteClean = user.createAction(types.JOBS_EXPERIENCE_DELETE_CLEAN)

export const socialSkillDeleteRequest = user.createAction(types.SOCIAL_SKILL_DELETE_REQUEST)
export const socialSkillDeleteSuccess = user.createAction(types.SOCIAL_SKILL_DELETE_SUCCESS)
export const socialSkillDeleteFailure = user.createAction(types.SOCIAL_SKILL_DELETE_FAILURE)
export const socialSkillDeleteClean = user.createAction(types.SOCIAL_SKILL_DELETE_CLEAN)

export const preferenceEditRequest = user.createAction(types.PREFERENCE_EDIT_REQUEST)
export const preferenceEditSuccess = user.createAction(types.PREFERENCE_EDIT_SUCCESS)
export const preferenceEditFailure = user.createAction(types.PREFERENCE_EDIT_FAILURE)
export const preferenceEditClean = user.createAction(types.PREFERENCE_EDIT_CLEAN)

export const preferenceDeleteRequest = user.createAction(types.PREFERENCE_DELETE_REQUEST)
export const preferenceDeleteSuccess = user.createAction(types.PREFERENCE_DELETE_SUCCESS)
export const preferenceDeleteFailure = user.createAction(types.PREFERENCE_DELETE_FAILURE)
export const preferenceDeleteClean = user.createAction(types.PREFERENCE_DELETE_CLEAN)

export const institutionalDataRequest = user.createAction(types.INSTITUTIONAL_DATA_REQUEST)
export const institutionalDataSuccess = user.createAction(types.INSTITUTIONAL_DATA_SUCCESS)
export const institutionalDataFailure = user.createAction(types.INSTITUTIONAL_DATA_FAILURE)
export const institutionalDataClean = user.createAction(types.INSTITUTIONAL_DATA_CLEAN)

export const ownDataRequest = user.createAction(types.OWN_DATA_REQUEST)
export const ownDataSuccess = user.createAction(types.OWN_DATA_SUCCESS)
export const ownDataFailure = user.createAction(types.OWN_DATA_FAILURE)
export const ownDataClean = user.createAction(types.OWN_DATA_CLEAN)

export const contactDataRequest = user.createAction(types.CONTACT_DATA_REQUEST)
export const contactDataSuccess = user.createAction(types.CONTACT_DATA_SUCCESS)
export const contactDataFailure = user.createAction(types.CONTACT_DATA_FAILURE)
export const contactDataClean = user.createAction(types.CONTACT_DATA_CLEAN)

export const resumeDownloadRequest = user.createAction(types.RESUME_DOWNLOAD_REQUEST)
export const resumeDownloadSuccess = user.createAction(types.RESUME_DOWNLOAD_SUCCESS)
export const resumeDownloadFailure = user.createAction(types.RESUME_DOWNLOAD_FAILURE)
export const resumeDownloadClean = user.createAction(types.RESUME_DOWNLOAD_CLEAN)

export const provincesRequest = user.createAction(types.PROVINCES_GET_REQUEST)
export const provincesSuccess = user.createAction(types.PROVINCES_GET_SUCCESS)
export const provincesFailure = user.createAction(types.PROVINCES_GET_FAILURE)
export const provincesClean = user.createAction(types.PROVINCES_GET_CLEAN)

export const departmentsRequest = user.createAction(types.DEPARTMENTS_GET_REQUEST)
export const departmentsSuccess = user.createAction(types.DEPARTMENTS_GET_SUCCESS)
export const departmentsFailure = user.createAction(types.DEPARTMENTS_GET_FAILURE)
export const departmentsClean = user.createAction(types.DEPARTMENTS_GET_CLEAN)

export const localitiesRequest = user.createAction(types.LOCALITIES_GET_REQUEST)
export const localitiesSuccess = user.createAction(types.LOCALITIES_GET_SUCCESS)
export const localitiesFailure = user.createAction(types.LOCALITIES_GET_FAILURE)
export const localitiesClean = user.createAction(types.LOCALITIES_GET_CLEAN)

export const regionsRequest = user.createAction(types.REGIONS_GET_REQUEST)
export const regionsSuccess = user.createAction(types.REGIONS_GET_SUCCESS)
export const regionsFailure = user.createAction(types.REGIONS_GET_FAILURE)
export const regionsClean = user.createAction(types.REGIONS_GET_CLEAN)

export const nationalitiesRequest = user.createAction(types.NATIONALITIES_GET_REQUEST)
export const nationalitiesSuccess = user.createAction(types.NATIONALITIES_GET_SUCCESS)
export const nationalitiesFailure = user.createAction(types.NATIONALITIES_GET_FAILURE)
export const nationalitiesClean = user.createAction(types.NATIONALITIES_GET_CLEAN)

export const privateModeRequest = user.createAction(types.PRIVATE_MODE_GET_REQUEST)
export const privateModeSuccess = user.createAction(types.PRIVATE_MODE_GET_SUCCESS)
export const privateModeFailure = user.createAction(types.PRIVATE_MODE_GET_FAILURE)
export const privateModeClean = user.createAction(types.PRIVATE_MODE_GET_CLEAN)

export const institutionPhotoRequest = user.createAction(types.INSTITUTION_PHOTO_POST_REQUEST)
export const institutionPhotoSuccess = user.createAction(types.INSTITUTION_PHOTO_POST_SUCCESS)
export const institutionPhotoFailure = user.createAction(types.INSTITUTION_PHOTO_POST_FAILURE)
export const institutionPhotoClean = user.createAction(types.INSTITUTION_PHOTO_POST_CLEAN)

export const termsAndConditionsRequest = user.createAction(types.TERMS_AND_CONDITIONS_GET_REQUEST)
export const termsAndConditionsSuccess = user.createAction(types.TERMS_AND_CONDITIONS_GET_SUCCESS)
export const termsAndConditionsFailure = user.createAction(types.TERMS_AND_CONDITIONS_GET_FAILURE)
export const termsAndConditionsClean = user.createAction(types.TERMS_AND_CONDITIONS_GET_CLEAN)

export const fileDeleteRequest = user.createAction(types.FILE_DELETE_REQUEST)
export const fileDeleteSuccess = user.createAction(types.FILE_DELETE_SUCCESS)
export const fileDeleteFailure = user.createAction(types.FILE_DELETE_FAILURE)
export const fileDeleteClean = user.createAction(types.FILE_DELETE_CLEAN)

export const employeesQuantityRequest = user.createAction(types.EMPLOYEES_QUANTITY_LIST_GET_REQUEST)
export const employeesQuantitySuccess = user.createAction(types.EMPLOYEES_QUANTITY_LIST_GET_SUCCESS)
export const employeesQuantityFailure = user.createAction(types.EMPLOYEES_QUANTITY_LIST_GET_FAILURE)
export const employeesQuantityClean = user.createAction(types.EMPLOYEES_QUANTITY_LIST_GET_CLEAN)

export const studentsQuantityRequest = user.createAction(types.STUDENTS_QUANTITY_LIST_GET_REQUEST)
export const studentsQuantitySuccess = user.createAction(types.STUDENTS_QUANTITY_LIST_GET_SUCCESS)
export const studentsQuantityFailure = user.createAction(types.STUDENTS_QUANTITY_LIST_GET_FAILURE)
export const studentsQuantityClean = user.createAction(types.STUDENTS_QUANTITY_LIST_GET_CLEAN)

export const levelsRequest = user.createAction(types.LEVELS_LIST_GET_REQUEST)
export const levelsSuccess = user.createAction(types.LEVELS_LIST_GET_SUCCESS)
export const levelsFailure = user.createAction(types.LEVELS_LIST_GET_FAILURE)
export const levelsClean = user.createAction(types.LEVELS_LIST_GET_CLEAN)

export const levelmodalitiesRequest = user.createAction(types.LEVEL_MODALITIES_LIST_GET_REQUEST)
export const levelmodalitiesSuccess = user.createAction(types.LEVEL_MODALITIES_LIST_GET_SUCCESS)
export const levelmodalitiesFailure = user.createAction(types.LEVEL_MODALITIES_LIST_GET_FAILURE)
export const levelmodalitiesClean = user.createAction(types.LEVEL_MODALITIES_LIST_GET_CLEAN)

export const institutionTypesRequest = user.createAction(types.INSTITUTION_TYPES_LIST_GET_REQUEST)
export const institutionTypesSuccess = user.createAction(types.INSTITUTION_TYPES_LIST_GET_SUCCESS)
export const institutionTypesFailure = user.createAction(types.INSTITUTION_TYPES_LIST_GET_FAILURE)
export const institutionTypesClean = user.createAction(types.INSTITUTION_TYPES_LIST_GET_CLEAN)

export const socialSkillsRequest = user.createAction(types.SOCIAL_SKILLS_LIST_GET_REQUEST)
export const socialSkillsSuccess = user.createAction(types.SOCIAL_SKILLS_LIST_GET_SUCCESS)
export const socialSkillsFailure = user.createAction(types.SOCIAL_SKILLS_LIST_GET_FAILURE)
export const socialSkillsClean = user.createAction(types.SOCIAL_SKILLS_LIST_GET_CLEAN)

export const suplenciesRequest = user.createAction(types.SUPLENCIES_LIST_GET_REQUEST)
export const suplenciesSuccess = user.createAction(types.SUPLENCIES_LIST_GET_SUCCESS)
export const suplenciesFailure = user.createAction(types.SUPLENCIES_LIST_GET_FAILURE)
export const suplenciesClean = user.createAction(types.SUPLENCIES_LIST_GET_CLEAN)
