import { login } from './_duck'
import * as types from './types'

// Types
import {
  ModuleType,
  ModuleTypeChangePass,
  ModuleTypeChangePassProfile,
  ModuleTypeGenerate,
  ModuleTypeVerify,
  ModuleTypeRegisterPostulante,
  ModuleTypeRegisterInstitution,
} from './_definitions'
import { PayloadAction } from '../../types'

const initialModuleState: ModuleType = {
  hasErrMessage: false,
  loginSuccess: null,
  statusLogin: null,
}
const initialModuleStateGenerate: ModuleTypeGenerate = {
  hasErrMessage: false,
  generateSuccess: null,
  statusGenerate: null,
}
const initialModuleStateValidate: ModuleTypeVerify = {
  hasErrMessage: false,
  validateSuccess: null,
  statusValidate: null,
}
const initialModuleStateChangePass: ModuleTypeChangePass = {
  hasErrMessage: false,
  changePassSuccess: null,
  statusChangePass: null,
}
const initialModuleStateChangePassProfile: ModuleTypeChangePassProfile = {
  hasErrMessage: false,
  changePassProfileSuccess: null,
  statusChangeProfilePass: null,
}
const initialModuleStateRegisterPostulante: ModuleTypeRegisterPostulante = {
  hasErrMessage: false,
  registerPostulanteSuccess: null,
  statusRegisterPostulante: null,
}
const initialModuleStateRegisterInstitution: ModuleTypeRegisterInstitution = {
  hasErrMessage: false,
  registerInstitutionSuccess: null,
  statusRegisterInstitution: null,
}

const reducer = login.createReducer(
  {
    [types.LOGIN_POST_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.LOGIN_POST_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      hasErrMessage: false,
      loginSuccess: true,
      statusLogin: payload,
    }),
    [types.LOGIN_POST_FAILURE]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      hasErrMessage: true,
      loginSuccess: false,
      statusLogin: payload,
    }),
    [types.LOGIN_CLEAN]: (state: ModuleType): ModuleType => ({
      ...state,
      hasErrMessage: false,
      loginSuccess: null,
      statusLogin: null,
    }),
    [types.GENERATE_CODE_POST_REQUEST]: (state: ModuleTypeGenerate): ModuleTypeGenerate => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.GENERATE_CODE_POST_SUCCESS]: (
      state: ModuleTypeGenerate,
      { payload }: PayloadAction,
    ): ModuleTypeGenerate => ({
      ...state,
      generateSuccess: true,
      statusGenerate: payload,
    }),
    [types.GENERATE_CODE_POST_FAILURE]: (
      state: ModuleTypeGenerate,
      { payload }: PayloadAction,
    ): ModuleTypeGenerate => ({
      ...state,
      hasErrMessage: true,
      generateSuccess: false,
      statusGenerate: payload,
    }),
    [types.GENERATE_CODE_POST_CLEAN]: (state: ModuleTypeGenerate): ModuleTypeGenerate => ({
      ...state,
      generateSuccess: null,
      hasErrMessage: false,
    }),
    [types.VERIFY_CODE_POST_REQUEST]: (state: ModuleTypeVerify): ModuleTypeVerify => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.VERIFY_CODE_POST_SUCCESS]: (
      state: ModuleTypeVerify,
      { payload }: PayloadAction,
    ): ModuleTypeVerify => ({
      ...state,
      validateSuccess: true,
      statusValidate: payload,
    }),
    [types.VERIFY_CODE_POST_FAILURE]: (state: ModuleTypeVerify): ModuleTypeVerify => ({
      ...state,
      hasErrMessage: true,
      validateSuccess: false,
    }),
    [types.VERIFY_CODE_POST_CLEAN]: (state: ModuleTypeVerify): ModuleTypeVerify => ({
      ...state,
      validateSuccess: null,
    }),
    [types.CHANGE_PASS_POST_REQUEST]: (state: ModuleTypeChangePass): ModuleTypeChangePass => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.CHANGE_PASS_POST_SUCCESS]: (
      state: ModuleTypeChangePass,
      { payload }: PayloadAction,
    ): ModuleTypeChangePass => ({
      ...state,
      changePassSuccess: true,
      statusChangePass: payload,
    }),
    [types.CHANGE_PASS_POST_FAILURE]: (state: ModuleTypeChangePass): ModuleTypeChangePass => ({
      ...state,
      hasErrMessage: true,
      changePassSuccess: false,
    }),
    [types.CHANGE_PASS_POST_CLEAN]: (state: ModuleTypeChangePass): ModuleTypeChangePass => ({
      ...state,
      changePassSuccess: null,
      statusChangePass: null,
    }),
    [types.CHANGE_PASS_PROFILE_POST_REQUEST]: (
      state: ModuleTypeChangePassProfile,
    ): ModuleTypeChangePassProfile => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.CHANGE_PASS_PROFILE_POST_SUCCESS]: (
      state: ModuleTypeChangePassProfile,
      { payload }: PayloadAction,
    ): ModuleTypeChangePassProfile => ({
      ...state,
      changePassProfileSuccess: true,
      statusChangeProfilePass: payload,
    }),
    [types.CHANGE_PASS_PROFILE_POST_FAILURE]: (
      state: ModuleTypeChangePassProfile,
      { payload }: PayloadAction,
    ): ModuleTypeChangePassProfile => ({
      ...state,
      hasErrMessage: true,
      statusChangeProfilePass: payload,
      changePassProfileSuccess: false,
    }),
    [types.CHANGE_PASS_PROFILE_POST_CLEAN]: (
      state: ModuleTypeChangePassProfile,
    ): ModuleTypeChangePassProfile => ({
      ...state,
      hasErrMessage: false,

      changePassProfileSuccess: null,
      statusChangeProfilePass: null,
    }),
    [types.REGISTER_POSTULANTE_POST_REQUEST]: (
      state: ModuleTypeRegisterPostulante,
    ): ModuleTypeRegisterPostulante => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.REGISTER_POSTULANTE_POST_SUCCESS]: (
      state: ModuleTypeRegisterPostulante,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterPostulante => ({
      ...state,
      registerPostulanteSuccess: true,
      statusRegisterPostulante: payload,
    }),
    [types.REGISTER_POSTULANTE_POST_FAILURE]: (
      state: ModuleTypeRegisterPostulante,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterPostulante => ({
      ...state,
      hasErrMessage: true,
      registerPostulanteSuccess: false,
      statusRegisterPostulante: payload,
    }),
    [types.REGISTER_POSTULANTE_CLEAN]: (
      state: ModuleTypeRegisterPostulante,
    ): ModuleTypeRegisterPostulante => ({
      ...state,
      registerPostulanteSuccess: null,
      statusRegisterPostulante: null,
    }),
    [types.REGISTER_POSTULANTE_PARAMETER_STATE_REQUEST]: (
      state: ModuleTypeRegisterPostulante,
    ): ModuleTypeRegisterPostulante => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.REGISTER_POSTULANTE_PARAMETER_STATE_SUCCESS]: (
      state: ModuleTypeRegisterPostulante,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterPostulante => ({
      ...state,
      statusRegisterPostulante: payload,
    }),
    [types.REGISTER_POSTULANTE_PARAMETER_STATE_FAILURE]: (
      state: ModuleTypeRegisterPostulante,
    ): ModuleTypeRegisterPostulante => ({
      ...state,
    }),
    [types.REGISTER_INSTITUTION_POST_REQUEST]: (
      state: ModuleTypeRegisterInstitution,
    ): ModuleTypeRegisterInstitution => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.REGISTER_INSTITUTION_POST_SUCCESS]: (
      state: ModuleTypeRegisterInstitution,
      { payload }: PayloadAction,
    ): ModuleTypeRegisterInstitution => ({
      ...state,
      registerInstitutionSuccess: true,
      statusRegisterInstitution: payload,
    }),
    [types.REGISTER_INSTITUTION_POST_FAILURE]: (
      state: ModuleTypeRegisterInstitution,
    ): ModuleTypeRegisterInstitution => ({
      ...state,
      hasErrMessage: true,
      registerInstitutionSuccess: false,
    }),
    [types.REGISTER_INSTITUTION_CLEAN]: (
      state: ModuleTypeRegisterInstitution,
    ): ModuleTypeRegisterInstitution => ({
      ...state,
      registerInstitutionSuccess: null,
    }),
  },
  {
    initialModuleState,
    initialModuleStateGenerate,
    initialModuleStateValidate,
    initialModuleStateChangePass,
    initialModuleStateChangePassProfile,
    initialModuleStateRegisterPostulante,
    initialModuleStateRegisterInstitution,
  },
)

export default reducer
