import {
  Box,
  Button,
  Link as ChakraLink,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Progress,
  Text,
  UseToastOptions,
  useToast,
} from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  institutionPhotoClean,
  ownDataRequest,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import { EditIcon } from '@chakra-ui/icons'
import { FormDescription } from './_types'
import InviteModal from '../../../InviteModal'
import ModalResumeInstitution from '../informationResume/ModalResumeInstitution'
import { PostInstitutionForm } from '../../forms-institution/_typesinstitution'
import ProfileEmpty from '../../../../assets/images/profile/InsitutionEmpty.png'
import { colors } from '../../../../utils/colors'
import { getRole } from '../../../../utils/profile'

const InformationInstitution: React.FC = () => {
  const [imagen, setImagen] = useState(null)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false)
  const { id } = useParams()
  const [isLogged, setIsLogged] = useState(false)
  const state = useSelector((state: any) => state)
  const userInformation = useSelector((state: any) => state?.user?.userInformation ?? '')
  const user = useSelector((state: any) => state?.user)
  const [percentage, setPercentage] = useState(0)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<number>(1)
  const toast = useToast()

  const handleOpenModal = () => {
    setIsOpen(true)
  }
  const handleCloseModal = () => {
    setIsOpen(false)
  }
  const [isEditing, setIsEditing] = useState(false)
  const institutoFilter = userInformation?.Institutions?.filter((item: any) => item.id === id)

  useEffect(() => {
    userInformation === undefined || userInformation === null || userInformation === ''
      ? null
      : cargarPercentage()
  }, [userInformation])

  const cargarPercentage = () => {
    let suma = 20
    if (institutoFilter && institutoFilter.length > 0) {
      if (institutoFilter[0]?.address !== null) {
        suma = suma + 20
      }
      if (institutoFilter[0]?.phoneNumber !== null) {
        suma = suma + 20
      }
      if (institutoFilter[0]?.instagramURL !== null) {
        suma = suma + 20
      }
      if (institutoFilter[0]?.businessName !== null) {
        suma = suma + 20
      }

      setPercentage(suma)
    }
  }

  useEffect(() => {
    setImagen(null)
    if (userInformation && userInformation !== null) {
      const institution = userInformation.Institutions?.find((insti: any) => insti?.id === id)
      setImagen(institution.UserFiles[0]?.filePath)
    }
  }, [userInformation, id])

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])

  useEffect(() => {
    if (user?.institutionPhotoSuccess && user?.institutionPhotoSuccess !== null) {
      toast({ description: user?.institutionPhoto.message, status: 'success', duration: 2000 })
      setIsOpen(false)
      dispatch(institutionPhotoClean())
      dispatch(userInformationRequest({ userId: state.user.userInformation.id }))
    }

    if (user?.institutionPhotoSuccess === false) {
      toast({
        description: user?.institutionPhoto?.response?.data?.message,
        status: 'error',
        duration: 2000,
      })
      dispatch(institutionPhotoClean())
    }
  }, [user?.institutionPhotoSuccess])

  const initialValues: FormDescription = {
    description:
      institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.description : '',
  }

  const handleSubmit = (values: any) => {
    const updatedInitialValuesForm: PostInstitutionForm = {
      id: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.id : '',
      userId: state.user.userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      email: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.email : '',
      phoneNumber:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.numberPhone : '',
      webURL: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.webURL : '',
      linkedinURL:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.linkedinURL : '',
      instagramURL:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.instagramURL : '',
      facebookURL:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.facebookURL : '',
      twitterURL:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.twitterURL : '',
      cue: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.cue : '',
      cuit: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.cuit : '',
      region: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.region : '',
      provincialNumber:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.provincialNumber : '',
      name: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.name : '',
      businessName:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.businessName : '',
      employeesQuantity:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.employeesQuantity : '',
      studentsQuantity:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.studentsQuantity : '',
      inProcess: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.inProcess : '',
      Address: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.Address : '',
      levelId: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.levelId : '',
      modality: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.modality : '',
      typeInstitution:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.typeInstitution : '',
      description: values.description ?? '',
    }

    dispatch(ownDataRequest(updatedInitialValuesForm))
    dispatch(userInformationRequest({ userId: state.user.userInformation.id }))
    setIsEditing(false)
  }

  return (
    <Box width='100%' display={'flex'} flexDirection={'column'} justifyContent={'center'} p={7}>
      <ModalResumeInstitution
        isOpen={isOpen}
        onClose={handleCloseModal}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />

      <Box
        width='125px'
        height='125px'
        borderRadius='50%'
        borderWidth='2px'
        borderColor='black'
        overflow='hidden'
        alignSelf={'center'}
        mb={3}
      >
        {imagen && imagen !== 'null' ? (
          <Image src={imagen} alt='imagen' objectFit='contain' width='100%' height='100%' />
        ) : (
          <Image
            src={ProfileEmpty}
            alt='ProfileEmpty'
            objectFit='contain'
            width='100%'
            height='100%'
          />
        )}
      </Box>
      <Text
        fontSize='xl'
        alignSelf={'center'}
        color={'#004876'}
        fontWeight='bold'
        textTransform='uppercase'
      >
        {institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.name : 'Mi nombre'}
      </Text>

      <Button colorScheme='blue' marginBottom='20px' width={'full'} onClick={handleOpenModal}>
        <Icon as={EditIcon} boxSize={6} color={'white'} mr={2} />
      </Button>

      <Text color={'#004876'} marginBottom='10px'>
        Perfil {percentage}% completado
      </Text>
      <Progress value={percentage} marginBottom='20px' />

      <Box>
        <Box>
          <Text
            fontSize='sl'
            alignSelf={'center'}
            color={'#004876'}
            fontWeight='bold'
            textTransform='uppercase'
            mb={3}
          >
            {institutoFilter && institutoFilter.length > 0
              ? institutoFilter[0]?.description
              : 'Sobre mi'}
          </Text>
          {isEditing ? (
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {() => (
                <Form
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <FormControl>
                    <FormLabel htmlFor='description'>Sobre mi</FormLabel>
                    <Field
                      as={Input}
                      mb={4}
                      type='text'
                      name='description'
                      placeholder='Escriba una breve descripción'
                      style={{
                        height: 'auto',
                        minHeight: '50px',
                        maxHeight: '150px',
                        overflow: 'auto',
                      }}
                    />
                  </FormControl>
                  <Box display='flex' justifyContent='center'>
                    <Button
                      mt={4}
                      marginBottom='20px'
                      colorScheme='blue'
                      width={'100%'}
                      type='submit'
                    >
                      Guardar cambios
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          ) : (
            <Button
              colorScheme='blue'
              marginBottom='20px'
              width={'full'}
              onClick={() => setIsEditing(true)}
            >
              <Icon as={EditIcon} boxSize={6} color={'white'} mr={2} />
              Sobre mi
            </Button>
          )}
          {institutoFilter[0]?.Role?.id == '1' && (
            <>
              <Button
                onClick={() => setIsInviteModalOpen(true)}
                background={colors.institutionalRed}
                color={'white'}
                _hover={{ background: colors.institutionalRed, opacity: 0.6 }}
                marginBottom='20px'
                width={'full'}
              >
                Invitar
              </Button>
              <InviteModal
                institutionId={institutoFilter[0]?.id}
                isOpen={isInviteModalOpen}
                onClose={() => setIsInviteModalOpen(false)}
              />
              <Box display={'flex'} justifyContent={'center'}>
                <ChakraLink
                  as={Link}
                  target='_blank'
                  to={`/invites-history/${institutoFilter[0]?.id}`}
                >
                  Historial de invitaciones
                </ChakraLink>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default InformationInstitution
