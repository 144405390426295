import { colors } from '../../../../utils/colors'

export const institutionsTableStyles = {
  box: { marginTop: '20px' },
  table: {},
  tableHead: { fontWeight: 'bold', fontSize: '12px', color: colors.principal },
  tableData: { fontSize: '12px' },
  actionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '15%',
  },
  noResultsContainer: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    textAlign: 'center',
    padding: '20px 0px',
    fontWeight: 'bold',
  },
  loading: {
    width: '100%',
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
