import { colors } from '../../../utils/colors'

export const jobApplicationsFilterStyles = {
  container: { display: 'flex', flexDirection: 'column', marginBottom: '16px' },
  filterIcon: {
    width: '20px',
    height: '15px',
    flexShrink: 0,
    alignSelf: 'end',
    marginBottom: '15px',
    cursor: 'pointer',
  },
  form: { display: 'flex', flexWrap: 'wrap', gap: '10px 20px', marginBottom: '15px' },
  buttonBox: {
    width: '300px',
    marginLeft: 'auto',
    marginTop: '25px',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    columnGap: '20px',
  },
  searchButton: {
    borderRadius: '5px',
    backgroundColor: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    color: '#fff',
  },
  cleanFilters: {
    backgroundColor: '#fff',
    color: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    borderRadius: '5px',
  },
  error: { color: colors.red },

  input: { width: '48%' },
  zoneInput: { width: '31%%' },
  dateContainer: {
    width: '97.5%',
    display: 'flex',
    flexDirection: 'column',
  },
  dateInputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    columnGap: '2%',
    marginTop: '10px',
  },
  dateInput: { width: '49%' },
  dateLabel: {
    fontSize: '14px',
    color: colors.principal,
  },
  formMobile: { display: 'flex', flexDirection: 'column', rowGap: '15px' },
  inputMobile: {
    width: '100%',
  },
  buttonBoxMobile: {
    marginTop: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  containerMobile: { display: 'flex', flexDirection: 'column' },
  dateInputContainerMobile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    marginTop: '10px',
  },
  dateInputMobile: { width: '100%' },
}
