import * as Yup from 'yup'

import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Text,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { Domicilio, PostRegisterPersonalData, YearsMonthsDays } from '../../../../state/modules/user/services/types'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { ChangeEvent, SetStateAction, useEffect, useRef, useState } from 'react'
import ReactSelect, { ActionMeta, MultiValue } from 'react-select'
import {
  departmentsClean,
  departmentsRequest,
  localitiesClean,
  localitiesRequest,
  nationalitiesClean,
  nationalitiesRequest,
  personalDataClean,
  personalDataRequest,
  provincesClean,
  provincesRequest,
  suplenciesClean,
  suplenciesRequest,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import { datosPersonalesStyles } from './styles'
import { FormPersonalData } from './_types'
import { dateTimeUntilNow, transformDateFromSQL } from '../../../../utils/profile'

interface Provincia {
  nombre: string
  id: string
  description?: any
}

interface Department {
  id: string
  description: string
}

interface Locality {
  id: string
  description: string
}

const calcularAge = (dateOfBirth: string): number => {
  const today = new Date()
  const birthDate = new Date(dateOfBirth)
  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDifference = today.getMonth() - birthDate.getMonth()
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() <= birthDate.getDate())) {
    age--
  }
  return age
}

export const InitialLinks = ['Inicio', 'Mi Perfil']

const toastMessages: UseToastOptions[] = [
  {
    description: 'Cambios guardados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const PersonalData = () => {
  const [isMobile, isTablet] = useMediaQuery(['(max-width: 600px)', '(max-width: 1000px)'])
  const state = useSelector((state: any) => state)
  const toast = useToast()
  const userInformation = useSelector((state: any) => state.user.userInformation ?? '')
  const nacionalidades = useSelector((state: any) => state?.user?.nationalitiesList)

  const provincias: Provincia[] = useSelector((state: any) =>
    state?.user?.provincesList?.sort((a: any, b: any) =>
      a.description?.localeCompare(b.description),
    ),
  )
  const [selectedProvince, setSelectedProvince] = useState<string>(
    state.user.userInformation?.Profile?.Address?.Province?.id ?? '',
  )

  const departamentos: Department[] = useSelector((state: any) =>
    state?.user?.departmentsList?.sort(
      (a: any, b: any) => a.description?.split(' ')[1] - b.description?.split(' ')[1],
    ),
  )

  const [selectedDepartment, setSelectedDepartment] = useState<string>(
    state.user.userInformation?.Profile?.Address?.Department?.id ?? '',
  )
  const localidades: Locality[] = useSelector((state: any) =>
    state?.user?.localitiesList?.sort((a: any, b: any) =>
      a.description?.localeCompare(b.description),
    ),
  )
  const [selectedLocality, setSelectedLocality] = useState<string>(
    state.user.userInformation?.Profile?.Address?.Locality?.id ?? ''
  )
  const [antiguedadEnabled, setAntiguedadEnabled] = useState(
    state.user.userInformation?.Profile?.antiquityDate !== null ? false : true
  )

  const [yearsMonthsDays, setYearsMonthsDays] = useState<YearsMonthsDays>({
    years: 0,
    months: 0,
    days: 0
  })

  const thereAreYearsMonthsDays = (): boolean => {
    if (yearsMonthsDays.years === 0 &&
      yearsMonthsDays.months === 0 &&
      yearsMonthsDays.days === 0) {
      return false
    } else {
      return true
    }
  }

  const { personalDataSuccess } = useSelector((state: any) => state?.user)
  const dispatch = useDispatch()
  const meses = [1, 2, 3, 4, 5, 6, 7, 7, 8, 9, 10, 11, 12]
  const anios = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
    26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  ]
  const prepaidHealth =
    state.user.userInformation?.Profile?.prepaidHealth === 'Sin cobertura'
      ? 'No'
      : state.user.userInformation?.Profile?.prepaidHealth
        ? 'Si'
        : ''

  const parsedDate = state.user.userInformation?.Profile?.birthdate ?? ''
  const parsedAntiquityDate = state.user.userInformation?.Profile?.antiquityDate ?? ''

  const { formatDate } = transformDateFromSQL(parsedDate)
  const { formatDateAntiquity } = transformDateFromSQL(parsedAntiquityDate)

  useEffect(() => {
    if (state.user.userInformation?.Profile?.antiquityDate) {
      setYearsMonthsDays(dateTimeUntilNow(formatDateAntiquity))
    }
  }, [])

  useEffect(() => {
    if (personalDataSuccess !== null && personalDataSuccess !== undefined) {
      toast(toastMessages[personalDataSuccess ? 0 : 1])
      personalDataSuccess && window.location.reload()
    }
    return () => {
      dispatch(personalDataClean())
    }
  }, [personalDataSuccess])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(provincesRequest({ token }))
    dispatch(nationalitiesRequest({ token }))
    dispatch(suplenciesRequest({ token }))

    return () => {
      dispatch(provincesClean())
      dispatch(nationalitiesClean())
      dispatch(suplenciesClean())
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (selectedProvince !== '' && selectedProvince !== undefined && selectedProvince !== null)
      dispatch(departmentsRequest({ token, provinceId: selectedProvince }))

    return () => {
      dispatch(departmentsClean())
    }
  }, [selectedProvince])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (
      selectedDepartment !== '' &&
      selectedDepartment !== undefined &&
      selectedDepartment !== null
    )
      dispatch(
        localitiesRequest({
          token,
          departmentId: selectedDepartment,
        }),
      )
    return () => {
      dispatch(localitiesClean())
    }
  }, [selectedDepartment])

  const handleProvinceChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedProvinceId = e.target.value
    setSelectedProvince(selectedProvinceId)
    setFieldValue('province', selectedProvinceId)
    setSelectedDepartment('')
    setTimeout(() => {
      setFieldValue('department', '')
    }, 100)
    setSelectedLocality('')
    setTimeout(() => {
      setFieldValue('locality', '')
    }, 150)
  }

  const handleDepartmentChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedDepartmentId = e.target.value
    setSelectedDepartment(selectedDepartmentId)
    setFieldValue('department', selectedDepartmentId)
    setSelectedLocality('')
    setTimeout(() => {
      setFieldValue('locality', '')
    }, 100)
  }

  const [suplenciasenable, setSuplenciasenable] = useState(
    userInformation?.Profile?.suplency ?? false,
  )
  const [suplencies, setSuplencies] = useState<any[]>(
    userInformation?.Suplencies?.map((e: any) => {
      return { value: e.id, label: e.description }
    }) ?? [],
  )

  const handleLocalityChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedLocalityId = e.target.value
    setSelectedLocality(selectedLocalityId)
    setFieldValue('locality', selectedLocalityId)
  }

  const suplenciesTable = state?.user?.suplenciesList

  const handleSuplenciasChange = (value: any) => {
    setSuplencies(value)
  }

  const handlePrepaidHealth = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    if (e.target.value === 'No') {
      setFieldValue('obrasocialdos', 'Sin Cobertura')
    } else {
      setFieldValue('obrasocialdos', '')
    }
  }

  const initialValues: FormPersonalData = {
    dni: state.user.userInformation?.Profile?.dni,
    email: state.user.userInformation?.email,
    cuil: state.user.userInformation?.Profile?.cuil,
    telefono: state.user.userInformation?.Profile?.phoneNumber ?? '',
    telefonoCelular: state.user.userInformation?.Profile?.cellPhone,
    calle: state.user.userInformation?.Profile?.Address?.street ?? '',
    numero: state.user.userInformation?.Profile?.Address?.number ?? '',
    piso: state.user.userInformation?.Profile?.Address?.floor ?? '',
    departamento: state.user.userInformation?.Profile?.Address?.department ?? '',
    codigopostal: state.user.userInformation?.Profile?.Address?.postalCode ?? '',
    locality: selectedLocality ?? '',
    department: selectedDepartment ?? '',
    province: selectedProvince ?? '',
    genero: state.user.userInformation?.Profile?.gender,
    estadocivil: state.user.userInformation?.Profile?.civilStatus,
    date: formatDate ?? '',
    antiquityDate: formatDateAntiquity ?? '',
    edad: state.user.userInformation?.Profile?.age,
    nacionalidad: state.user.userInformation?.Profile?.Nationality?.id,
    obrasocial: prepaidHealth,
    obrasocialdos: state.user.userInformation?.Profile?.prepaidHealth,
    antiguedadanios: state.user.userInformation?.Profile?.antiquityYears ?? 0,
    antiguedadmeses: state.user.userInformation?.Profile?.antiquityMonth ?? 0,
    movilidadpropia:
      state.user.userInformation?.Profile?.mobility === null
        ? ''
        : state.user.userInformation?.Profile?.mobility
          ? 'Si'
          : 'No',
    jubilado:
      state.user.userInformation?.Profile?.retired === null
        ? ''
        : state.user.userInformation?.Profile?.retired
          ? 'Si'
          : 'No',
    wathsapp:
      state.user.userInformation?.Profile?.whatsappContact === null
        ? ''
        : state.user.userInformation?.Profile?.whatsappContact
          ? 'Si'
          : 'No',
    suplencias: suplencies,
    linkedinURL: state.user.userInformation?.Profile?.linkedinURL,
    twitterURL: state.user.userInformation?.Profile?.twitterURL,
    weburl: state.user.userInformation?.Profile?.webURL,
    facebookURL: state.user.userInformation?.Profile?.facebookURL,
    instagram: state.user.userInformation?.Profile?.instagramURL,
  }
  const validateSchema = Yup.object({
    dni: Yup.string().max(8, 'El DNI debe tener máximo 8 dígitos').required('Campo requerido'),
    cuil: Yup.string().max(11, 'El Cuil debe tener máximo 11 dígitos').required('Campo requerido'),
    telefono: Yup.string()

      .test({
        name: 'is-number',
        test(value, ctx) {
          if (value && value.length > 0 && Number.isNaN(Number(value))) {
            return ctx.createError({ message: 'El campo no acepta letras' })
          }
          return true
        },
      })
      .min(8, 'El número debe tener un mínimo de 8 dígitos'),
    telefonoCelular: Yup.string()
      .test({
        name: 'is-number',
        test(value, ctx) {
          if (value && value.length > 0 && Number.isNaN(Number(value))) {
            return ctx.createError({ message: 'El campo no acepta letras' })
          }
          return true
        },
      })
      .min(8, 'El número debe tener un mínimo de 8 dígitos')
      .required('Campo requerido'),
    calle: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido'),
    numero: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido'),
    piso: Yup.string().max(2, 'Máximo 2 dígitos'),
    departamento: Yup.string().max(2, 'Máximo 2 dígitos'),
    codigopostal: Yup.string().max(5, 'Máximo de 5 dígitos').required('Campo requerido'),
    locality: Yup.string().min(1, 'campo obligatorio').required('Campo requerido localidad'),
    department: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido departamento'),
    province: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido provincia'),
    genero: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido'),
    estadocivil: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido'),
    edad: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido'),
    nacionalidad: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido'),
    antiguedadanios: Yup.string(),
    antiguedadmeses: Yup.string(),
    antiquityDate: Yup.string(),
    email: Yup.string().email('Formato de correo electrónico inválido').required('Campo requerido'),
    jubilado: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido'),
    obrasocial: Yup.string().min(1, 'Campo obligatorio').required('Campo requerido obra social'),
    obrasocialdos: Yup.string()
      .min(1, 'Campo obligatorio')
      .required('Campo requerido prestador obra social'),
    suplencias: suplenciasenable ? Yup.array().min(1, 'Campo obligatorio') : Yup.array(),
  }).defined()

  const handleDateBlur = (e: React.FocusEvent<HTMLInputElement>, setFieldValue: any, field: string) => {
    const today = new Date()
    const date = new Date(e.target.value)

    const { selectorDate } = transformDateFromSQL(e.target.value)

    const age = calcularAge(selectorDate)
    const fullAntiquity = dateTimeUntilNow(selectorDate)

    const isoDate = date.toISOString().split('T')[0] || ''
    const isoToday = today.toISOString().split('T')[0] || ''

    if (isoDate !== isoToday) {
      if (field === 'dateOfBirth') {
        setFieldValue('edad', age, false)
      } else if (field === 'antiquityDate') {
        console.log(fullAntiquity)
        setYearsMonthsDays(fullAntiquity)
      }
    } else {
      if (field === 'dateOfBirth') {
        setFieldValue('edad', '', false)
      } else if (field === 'antiquityDate') {
        setFieldValue('antiquityDate', '', false)
        setYearsMonthsDays({ years: 0, months: 0, days: 0 })
      }
    }
  }
  const handleSubmit = (values: any) => {
    const Domicilio: Domicilio = {
      id: state.user.userInformation?.Profile?.Address?.id || null,
      provinceId: selectedProvince,
      departmentId: selectedDepartment,
      localityId: selectedLocality,
      cityId: values.cityId,
      fullAddress: values.fullAddress,
      street: values.calle,
      number: values.numero ?? 0,
      department: values.departamento ?? 0,
      floor: values.piso,
      postalCode: values.codigopostal,
    }

    const { updateDate } = transformDateFromSQL(values.date)
    const { updateAntiquityDate } = transformDateFromSQL(values.antiquityDate)

    let mappedUpdateAntiquityDate: Date | null

    const suplencyArray = suplencies.map((e) => {
      return { ['suplencyId']: e.value }
    })

    if (!antiguedadEnabled) {
      mappedUpdateAntiquityDate = updateAntiquityDate
    } else {
      mappedUpdateAntiquityDate = null
    }
    const updatedInitialValuesForm: PostRegisterPersonalData = {
      id: state.user.userInformation.Profile.id || null,
      userId: state.user.userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      address: Domicilio,
      dni: values.dni,
      cuil: values.cuil,
      email: values.email,
      phoneNumber: values.telefono,
      cellPhone: values.telefonoCelular,
      gender: values.genero,
      civilStatus: values.estadocivil,
      birthdate: updateDate,
      // antiquityDate: updateAntiquityDate,
      antiquityDate: mappedUpdateAntiquityDate,
      age: values.edad,
      nationality: values.nacionalidad,
      prepaidHealth:
        values.obrasocial === 'No'
          ? 'Sin cobertura'
          : values.obrasocial === 'Si'
            ? values.obrasocialdos
            : '',
      antiquity: antiguedadEnabled,
      antiquityYears: 0,
      antiquityMonth: 0,
      // antiquityYears: !antiguedadEnabled ? values.antiguedadanios : null,
      // antiquityMonth: !antiguedadEnabled ? values.antiguedadmeses : null,
      mobility:
        values.movilidadpropia === '' ? null : values.movilidadpropia === 'Si' ? true : false,
      retired: values.jubilado === 'Si' ? true : false,
      suplency: suplenciasenable,
      suplencyText: '',
      whatsappContact: values.wathsapp === '' ? null : values.wathsapp === 'Si' ? true : false,
      webURL: values.weburl,
      linkedinURL: values.linkedinURL,
      instagramURL: values.instagram,
      twitterURL: values.twitterURL,
      facebookURL: values.facebookURL,
      suplencies: suplencyArray,
    }

    dispatch(personalDataRequest(updatedInitialValuesForm))
    dispatch(userInformationRequest({ userId: state.user.userInformation.id }))
  }

  return (
    <Box maxWidth='100%' margin='0 auto'>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validateSchema}
      >
        {({ errors, touched, setTouched, setErrors, setFieldValue, handleChange, values }) => {
          const fieldErrors = Object.values(errors)

          return (
            <Form>
              <Box>
                <Box display='flex' justifyContent='center'>
                  <Text mb={4} width={isMobile ? '100%' : '30%'} fontWeight='bold'>
                    DATOS PERSONALES
                  </Text>
                </Box>
                <Box display={isMobile ? 'block' : 'flex'}>
                  <Box width={isMobile ? '100%' : '50%'} paddingRight={isMobile ? '0' : '2rem'}>
                    <FormControl>
                      <FormLabel htmlFor='dni'>DNI*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='number'
                        min='0'
                        name='dni'
                        placeholder='Ingrese su DNI sin espacios ni puntos'
                        onChange={handleChange}
                      />
                      {errors.dni && touched.dni ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.dni}
                        </Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='email'>Email*</FormLabel>
                      <Field
                        style={{ color: 'grey' }}
                        as={Input}
                        mb={4}
                        type='email'
                        name='email'
                        placeholder='Ejemplo@ejemplo.com'
                        isReadOnly
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.email}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='cuil'>CUIL*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='number'
                        name='cuil'
                        placeholder='ingrese su Cuil sin espacios ni puntos'
                        onChange={handleChange}
                      />
                      {errors.cuil && touched.cuil ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.cuil}
                        </Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='telefono'>Teléfono</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        maxLength='10'
                        onChange={(e: any) => setFieldValue('telefono', e.target.value.trim())}
                        type='string'
                        name='telefono'
                        placeholder='sin el 15 y sin el 0'
                      />
                      {errors.telefono && touched.telefono ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.telefono}
                        </Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='telefonoCelular'>Teléfono Celular*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='string'
                        name='telefonoCelular'
                        maxLength='10'
                        onChange={(e: any) =>
                          setFieldValue('telefonoCelular', e.target.value.trim())
                        }
                        placeholder='sin el 15 y sin el 0'
                      />
                      {errors.telefonoCelular && touched.telefonoCelular && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.telefonoCelular}
                        </Text>
                      )}
                    </FormControl>
                    <FormLabel htmlFor='domicilio'>Domicilio</FormLabel>
                    <FormControl>
                      <FormLabel htmlFor='calle'>Calle*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='text'
                        name='calle'
                        placeholder='Ej: 24 de septiembre'
                      />
                      {errors.calle && touched.calle && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.calle}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='numero'>Número*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='number'
                        min='0'
                        name='numero'
                        placeholder='Ej : 125'
                      />
                      {errors.numero && touched.numero && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.numero}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='piso'>Piso</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='number'
                        min='0'
                        name='piso'
                        placeholder='Ej : 5 (Opcional)'
                        onChange={handleChange}
                      />
                      {errors.piso && touched.piso && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.piso}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='departamento'>Departamento</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='text'
                        name='departamento'
                        placeholder='Ej : F (Opcional)'
                        onChange={handleChange}
                      />
                      {errors.departamento && touched.departamento && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.departamento}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='codigopostal'>Código Postal*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='number'
                        name='codigopostal'
                        placeholder='Ej : 1000'
                        onChange={handleChange}
                      />
                      {errors.codigopostal && touched.codigopostal && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.codigopostal}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='province'>Provincia*</FormLabel>
                      <Field
                        as={Select}
                        mb={4}
                        name='province'
                        style={{ textTransform: 'capitalize' }}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleProvinceChange(e, setFieldValue)
                        }}
                        value={selectedProvince}
                      >
                        <option style={{ textTransform: 'unset' }} value=''>
                          Seleccioná tu provincia
                        </option>
                        {provincias?.map((provincia) => (
                          <option
                            style={{ textTransform: 'capitalize' }}
                            key={provincia.id}
                            value={provincia.id}
                          >
                            {provincia.description.toLowerCase()}
                          </option>
                        ))}
                      </Field>
                      {errors.province && touched.province && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.province}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='departament'>Departamento/Partido*</FormLabel>
                      <Field
                        as={Select}
                        mb={4}
                        style={{ textTransform: 'capitalize' }}
                        name='department'
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          handleDepartmentChange(e, setFieldValue)
                        }
                        value={selectedDepartment}
                      >
                        <option value=''>Seleccioná tu departamento</option>
                        {departamentos?.map((departamento) => (
                          <option
                            style={{ textTransform: 'capitalize' }}
                            key={departamento.id}
                            value={departamento.id}
                          >
                            {departamento.description.toLowerCase()}
                          </option>
                        ))}
                      </Field>
                      {errors.department && touched.department && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.department}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='locality'>Localidad*</FormLabel>
                      <Field
                        as={Select}
                        mb={4}
                        name='locality'
                        style={{ textTransform: 'capitalize' }}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          handleLocalityChange(e, setFieldValue)
                        }
                        value={selectedLocality}
                      >
                        <option value=''>Seleccioná tu ciudad</option>
                        {localidades?.sort().map((localidad) => (
                          <option
                            style={{ textTransform: 'capitalize' }}
                            key={localidad.id}
                            value={localidad.id}
                          >
                            {localidad.description.toLowerCase()}
                          </option>
                        ))}
                      </Field>
                      {errors.locality && touched.locality && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.locality}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='nacionalidad'>Nacionalidad*</FormLabel>
                      <Field as={Select} mb={4} type='text' name='nacionalidad'>
                        <option value=''>Seleccioná tu nacionalidad</option>
                        {nacionalidades?.map((nac: any) => (
                          <option key={nac.id} value={nac.id}>
                            {nac.description}
                          </option>
                        ))}
                      </Field>
                      {errors.nacionalidad && touched.nacionalidad && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.nacionalidad}
                        </Text>
                      )}
                    </FormControl>
                  </Box>
                  <Box width={isMobile ? '100%' : '50%'}>
                    <FormControl>
                      <FormLabel htmlFor='genero'>Género*</FormLabel>
                      <Field as={Select} mb={4} name='genero'>
                        <option>Elija una opción</option>
                        <option value='Femenino'>Femenino</option>
                        <option value='Masculino'>Masculino</option>
                        <option value='Prefiero no responder'>Prefiero no responder</option>
                      </Field>
                      {errors.genero && touched.genero ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.genero}
                        </Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='estadocivil'>Estado civil*</FormLabel>
                      <Field as={Select} mb={4} name='estadocivil'>
                        <option value=''>Elija una opción</option>
                        <option value='Soltero/a'>Soltero/a</option>
                        <option value='Casado/a'>Casado/a</option>
                        <option value='Viudo/a'>Viudo/a</option>
                        <option value='Divorciado/a'>Divorciado/a</option>
                        <option value='Pareja de hecho'>Pareja de hecho</option>
                        <option value='Unión libre'>Unión libre</option>
                      </Field>
                      {errors.estadocivil && touched.estadocivil ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.estadocivil}
                        </Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='date'>Fecha de Nacimiento*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='date'
                        name='date'
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                          handleDateBlur(e, setFieldValue, 'dateOfBirth')
                        }
                      />
                      <ErrorMessage name='date' component='div' />
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='edad'>Edad*</FormLabel>
                      <Field as={Input} mb={4} type='number' name='edad' isReadOnly />
                      {errors.edad && touched.edad && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.edad}
                        </Text>
                      )}
                    </FormControl>
                    <p></p>
                    <FormControl>
                      <FormLabel htmlFor='obrasocial'>Obra social*</FormLabel>
                      <Field
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleChange(e)
                          handlePrepaidHealth(e, setFieldValue)
                        }}
                        as={Select}
                        mb={4}
                        name='obrasocial'
                      >
                        <option value=''>Elija una opción</option>
                        <option value='Si'>Si</option>
                        <option value='No'>No</option>
                      </Field>
                      {errors.obrasocial && touched.obrasocial && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.obrasocial}
                        </Text>
                      )}
                    </FormControl>
                    {values.obrasocial === 'Si' && (
                      <FormControl>
                        <Field
                          onChange={handleChange}
                          as={Input}
                          mb={4}
                          type='text'
                          name='obrasocialdos'
                          placeholder='Ej : OSDE'
                        />
                        {errors.obrasocialdos && touched.obrasocialdos && (
                          <Text color='red' fontSize={'sm'}>
                            {errors.obrasocialdos}
                          </Text>
                        )}
                      </FormControl>
                    )}

                    <FormControl
                      display={isMobile ? 'block' : 'flex'}
                      alignItems='center'
                      mb={4}
                      columnGap={2}
                    >
                      <FormLabel m={0} htmlFor='antiguedad'>
                        Antigüedad docente
                      </FormLabel>
                      <Box display={'flex'} alignItems={'center'}>
                        <Checkbox
                          name='antiguedadEnabled'
                          isChecked={antiguedadEnabled}
                          onChange={(e) => {
                            setAntiguedadEnabled(e.target.checked)
                          }}
                          borderColor='blue.500'
                          _checked={{ borderColor: 'blue.500' }}
                        />
                        <Text ml={2} fontSize={'14px'}>
                          No corresponde
                        </Text>
                      </Box>
                    </FormControl>

                    {!antiguedadEnabled && (
                      <Box display={isMobile ? 'block' : 'flex'} flexDirection={'column'}>
                        <FormControl>
                          <FormLabel htmlFor='antiquityDate'>Antigüedad docente desde</FormLabel>
                          <Field
                            as={Input}
                            mb={2}
                            type='date'
                            name='antiquityDate'
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                              handleDateBlur(e, setFieldValue, 'antiquityDate')
                            }
                          />
                          <ErrorMessage name='antiquityDate' component='div' />
                        </FormControl>

                        {thereAreYearsMonthsDays() && (
                          <Box mb={3}>
                            <Stack direction='row'>
                              <Flex minWidth='max-content' alignItems='center' gap='2'>
                                <Badge sx={datosPersonalesStyles.badge}>
                                  Años: {yearsMonthsDays.years}
                                </Badge>
                                <Badge sx={datosPersonalesStyles.badge}>
                                  Meses: {yearsMonthsDays.months}
                                </Badge>
                                <Badge sx={datosPersonalesStyles.badge}>
                                  Días: {yearsMonthsDays.days}
                                </Badge>
                              </Flex>
                            </Stack>
                          </Box>
                        )}
                      </Box>
                    )}

                    <FormControl>
                      <FormLabel htmlFor='movilidadpropia'>Movilidad propia</FormLabel>
                      <Field as={Select} mb={4} name='movilidadpropia'>
                        <option value=''>Elija una opción</option>
                        <option value='Si'>Si</option>
                        <option value='No'>No</option>
                      </Field>
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='jubilado'>Jubilado/a *</FormLabel>
                      <Field as={Select} mb={4} name='jubilado'>
                        <option value=''>Elija una opción</option>
                        <option value='Si'>Si</option>
                        <option value='No'>No</option>
                      </Field>
                      {errors.jubilado && touched.jubilado ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.jubilado}
                        </Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='wathsapp'>¿Desea ser contactado por whatsapp?</FormLabel>
                      <Field as={Select} mb={4} name='wathsapp'>
                        <option value=''>Elija una opción</option>
                        <option value='Si'>Si</option>
                        <option value='No'>No</option>
                      </Field>
                    </FormControl>
                    <FormControl display='flex' alignItems='center' mb={4}>
                      <FormLabel htmlFor='suplenciasenable' mb={0}>
                        ¿Desea realizar suplencias?
                      </FormLabel>
                      <Checkbox
                        name='suplenciasenable'
                        isChecked={suplenciasenable}
                        onChange={(e) => {
                          setSuplenciasenable(e.target.checked)
                          suplenciasenable && setSuplencies([])
                          const { suplencias, ...rest } = errors
                          setErrors(rest)
                        }}
                        borderColor='blue.500'
                        _checked={{ borderColor: 'blue.500' }}
                      />
                      <Text ml={2} fontSize={'14px'}>
                        Si
                      </Text>
                    </FormControl>
                    {suplenciasenable && (
                      <Box>
                        <FormControl>
                          <>
                            <ReactSelect
                              styles={{
                                control: (baseStyles: any, state: any) => ({
                                  ...baseStyles,
                                  margin: '0 0 15px 0',
                                }),
                              }}
                              placeholder='Elija una opción'
                              name='suplencias'
                              onBlur={() => setTouched({ suplencias: true })}
                              options={suplenciesTable?.map((e: any, i: number) => {
                                return {
                                  label: e.description,
                                  value: e.id,
                                }
                              })}
                              isMulti
                              value={suplencies}
                              onChange={(e: any) => {
                                handleSuplenciasChange(e)
                                setFieldValue(
                                  'suplencias',
                                  e.map((item: any) => {
                                    return { suplencyId: item.value }
                                  }),
                                )
                              }}
                            />
                            {errors.suplencias && touched.suplencias && (
                              <Text color='red' fontSize={'sm'}>
                                {suplenciasenable && errors.suplencias}
                              </Text>
                            )}
                          </>
                        </FormControl>
                      </Box>
                    )}
                    <FormControl>
                      <FormLabel htmlFor='linkedinURL'>Redes sociales</FormLabel>
                      <Field as={Input} mb={4} name='linkedinURL' placeholder='Linkedin' />
                    </FormControl>
                    <FormControl>
                      <Field as={Input} mb={4} name='facebookURL' placeholder='Facebook' />
                    </FormControl>
                    <FormControl>
                      <Field as={Input} mb={4} name='instagram' placeholder='Instagram' />
                    </FormControl>
                    <FormControl>
                      <Field as={Input} mb={4} name='twitterURL' placeholder='Twitter' />
                    </FormControl>
                    <FormControl>
                      <Field as={Input} mb={4} name='weburl' placeholder='Página web' />
                    </FormControl>
                  </Box>
                </Box>
                <Box display='flex' justifyContent='center'>
                  <Button
                    isDisabled={fieldErrors.length === 0 ? false : true}
                    mt={4}
                    colorScheme='blue'
                    width={isMobile ? '100%' : 'auto'}
                    type='submit'
                  >
                    Guardar Cambios
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default PersonalData
