import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { Box, Button, Icon } from '@chakra-ui/react'
import React, { useState } from 'react'

import { usersPaginatorStyles } from './styles'

interface PaginatorProps {
  elements: any[]
  elementsPerpage: number
  elementsLength: number
  currentPage: number
  setCurrentPage: (currentPage: any) => void
  resultsDescription?: string
}
const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  setCurrentPage,
  elements,
  elementsPerpage,
  elementsLength,
  resultsDescription = '',
}) => {
  const pagesNumber = Math.ceil(elementsLength / elementsPerpage)
  const pages: any[] = []

  for (let i = 1; i <= pagesNumber; i++) {
    pages.push(i)
  }

  const handlePages = (sign: string) => {
    sign === '-' && currentPage > 1
      ? setCurrentPage((prev: number) => prev - 1)
      : sign === '+' && currentPage < pagesNumber && setCurrentPage((prev: number) => prev + 1)
  }

  return (
    <Box sx={usersPaginatorStyles.box}>
      <Box sx={usersPaginatorStyles.items}>
        <div style={usersPaginatorStyles.pagesContainer}>
          Mostrando {currentPage * elementsPerpage - 9} de{' '}
          {pagesNumber === currentPage ? elementsLength : currentPage * elementsPerpage}{' '}
          {resultsDescription}
          <Button
            sx={usersPaginatorStyles.button}
            _hover={usersPaginatorStyles.buttonHover}
            onClick={() => handlePages('-')}
            isDisabled={currentPage === 1 ? true : false}
            size={'xs'}
          >
            <Icon sx={usersPaginatorStyles.arrow} as={ArrowLeftIcon} />
          </Button>
          <Button
            sx={usersPaginatorStyles.button}
            _hover={usersPaginatorStyles.buttonHover}
            onClick={() => handlePages('+')}
            size={'xs'}
            isDisabled={currentPage === pagesNumber ? true : false}
          >
            <Icon sx={usersPaginatorStyles.arrow} as={ArrowRightIcon} />
          </Button>
        </div>
      </Box>
    </Box>
  )
}

export default Paginator
