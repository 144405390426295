import {
  AdDetailInfoPayload,
  AdStatusPayload,
  DeleteJobPayload,
  DownloadSpreadsheetPayload,
  DuplciateJobPayload,
  EditJobPayload,
  EditJobStatusPayload,
  FindJobsPayload,
  InstitutionsListPayload,
  JobApplicationPayload,
  JobTypesPayload,
  NewJobPayload,
  PositionsPayload,
  ReferenceNumbersPayload,
  SearchJobsPayload,
  SubjectsPayload,
  TimeSlotsPayload,
} from './types'

import { AxiosPromise } from 'axios'
import httpModule from '../../../../utils/http'

const paths = {
  INSTITUTIONS_LIST: 'careers/all-my-institutions/',
  TIME_SLOTS: 'careers/time-slot/',
  JOB_TYPES: 'careers/job-types/',
  POSITIONS: 'position',
  NEW_JOB: 'careers/',
  FIND_JOBS: 'careers/find-careers/',
  EDIT_JOB: '/edit/',
  EDIT_STATUS: '/status/',
  SEARCH_JOBS: 'careers/search',
  JOB_APPLICATION: 'job-application/',
  AD_DETAIL: 'careers/careerDetails/',
  DOWNLOAD_SPREADSHEET: 'careers/download-excel',
  AD_STATUS: 'ad-status',
  SUBJECTS: 'subject',
  DUPLICATE_JOB: 'careers/duplicate/',
  REFERENCES_NUMBERS: 'careers/referenceNumbers/',
  FEATURED_ADVICES: 'careers/last-ten/'
}

export const getInstitutionsListService = (payload: InstitutionsListPayload): AxiosPromise<any> =>
  httpModule.get(
    `${paths.INSTITUTIONS_LIST}${payload.userId}${payload.all === true ? '/show-all' : ''}`,
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )

export const getTimeSlotsService = (payload: TimeSlotsPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.TIME_SLOTS}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getJobTypesService = (payload: JobTypesPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.JOB_TYPES}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const postJobService = (payload: NewJobPayload): AxiosPromise<any> => {
  const ad = {
    title: payload.title,
    institutions: payload.cue,
    showCue: payload.showCue,
    showInstitutionName: payload.showInstitution,
    zone: payload.address,
    levels: payload.level,
    timeAvailablity: payload.availability,
    requiredTask: payload.tasks,
    softSkills: payload.softSkills,
    positionId: payload.position,
    subjectId: payload.area,
    vacancyAvailable: payload.vacancies,
    amountofHours: payload.hours,
    timeSlot: payload.timePeriod,
    workExperience: payload.jobExperience,
    workModeId: payload.modality,
    publicationDate: payload.publicationDate,
    expirationDate: payload.expirationDate,
    suplencies: payload.suplencies,
    institutionTypes: payload.institutionTypes,
    levelModalities: payload.levelModalities,
  }

  return httpModule.post(
    `${paths.NEW_JOB}`,
    { userId: `${payload.userId}`, ad },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const postFindJobsService = (payload: FindJobsPayload): AxiosPromise<any> => {
  const filters = {
    referenceNumber: payload?.filters?.referenceNumber,
    institutionId: payload?.filters?.cue,
    title: payload?.filters?.title,
    positionType: payload?.filters?.positionType,
    position: payload?.filters?.position,
  }
  const orderBy = { field: payload?.order?.field, criteria: payload?.order?.criteria }

  return httpModule.post(
    `${paths.FIND_JOBS}`,
    { filters, orderBy, page: payload.page, size: payload.size },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const putEditJobService = (payload: EditJobPayload): AxiosPromise<any> => {
  const ad = {
    referenceNumber: payload.referenceNumber,
    title: payload.title,
    institutions: payload.cue,
    showCue: payload.showCue,
    showInstitutionName: payload.showInstitution,
    zone: payload.address,
    levels: payload.level,
    timeAvailablity: payload.availability,
    requiredTask: payload.tasks,
    softSkills: payload.softSkills,
    positionId: payload.position,
    subjectId: payload.area,
    vacancyAvailable: payload.vacancies,
    amountofHours: payload.hours,
    timeSlot: payload.timePeriod,
    workExperience: payload.jobExperience,
    workModeId: payload.modality,
    publicationDate: payload.publicationDate,
    expirationDate: payload.expirationDate,
    suplencies: payload.suplencies,
    levelModalities: payload.levelModalities,
    institutionTypes: payload.institutionTypes,
  }
  return httpModule.put(
    `careers/${payload.adId}${paths.EDIT_JOB}`,
    { ad, userId: `${payload.userId}` },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const putEditJobStatusService = (payload: EditJobStatusPayload): AxiosPromise<any> =>
  httpModule.put(`careers/${payload.adId}${paths.EDIT_STATUS}${payload.statusId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const deleteJobService = (payload: DeleteJobPayload): AxiosPromise<any> =>
  httpModule.delete(`careers/${payload.adId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const postSearchJobsService = (payload: SearchJobsPayload): AxiosPromise<any> => {
  const body = { filters: payload.filters, page: payload.page, size: 10 }

  return httpModule.post(`${paths.SEARCH_JOBS}`, body, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const postJobApplicationService = (payload: JobApplicationPayload): AxiosPromise<any> =>
  httpModule.post(`${paths.JOB_APPLICATION}${payload.adId}/apply`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getAdDetailInfoService = (payload: AdDetailInfoPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.AD_DETAIL}${payload.adId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const postDownloadAdsExcelSpreedsheetService = (
  payload: DownloadSpreadsheetPayload,
): AxiosPromise<any> =>
  httpModule.post(paths.DOWNLOAD_SPREADSHEET, payload.filters, {
    headers: {
      Authorization: `${payload.token}`,
    },
    responseType: 'blob',
  })
export const getPositionsService = (payload: PositionsPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.POSITIONS}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getAdStatusService = (payload: AdStatusPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.AD_STATUS}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getSubjectsService = (payload: SubjectsPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.SUBJECTS}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const postDuplicateJobService = (payload: DuplciateJobPayload): AxiosPromise<any> => {
  return httpModule.post(
    `${paths.DUPLICATE_JOB}`,
    { creatorId: payload.creatorId, adId: payload.adId },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const getReferenceNumbersService = (payload: ReferenceNumbersPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.REFERENCES_NUMBERS}${payload.institutionId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

  export const getLastTenAdsService = (): AxiosPromise<any> =>
    httpModule.get(`${paths.FEATURED_ADVICES}`, {
    })
