import {
  DeleteFilePayload,
  EmployeesQuantityPayload,
  GetDepartments,
  GetLocalities,
  GetNationalities,
  GetPrivateModePayload,
  GetProvinces,
  GetRegions,
  GetResumeDownload,
  InstitutionTypesPayload,
  LanguagesPayload,
  LevelModalitiesPayload,
  LevelsPayload,
  PostAcademicRulesActions,
  PostContactData,
  PostInsitucionalData,
  PostInstitutionProfilePhotoPayload,
  PostJobsExperienceRulesActions,
  PostLanguageRulesActions,
  PostOwnData,
  PostPreferenceRulesActions,
  PostRegisterEducation,
  PostRegisterGeneralInformation,
  PostRegisterInstitutionProfile,
  PostRegisterJobsExpriences,
  PostRegisterPersonalData,
  PostRegisterPreferences,
  PostSocialSkillRulesActions,
  PostUpdateOneInstitution,
  SocialSkillsPayload,
  StudentsQuantityPayload,
  SuplenciesPayload,
  getUserInformation,
} from './types'

import { AxiosPromise } from 'axios'
import httpModule from '../../../../utils/http'

const paths = {
  USER_INFORMATION: 'users/information/',
  GENERAL_INFORMATION: 'general-information',
  PERSONAL_DATA: 'profile',
  EDUCATION: 'education',
  LANGUAGE: 'language',
  JOBS_EXPERIENCE: 'jobs-experience',
  NEW_PREFERENCE: '/newPreference',
  ROOT_USER: 'users',
  USER: 'user',
  REGISTER: 'register',
  INSTITUTION: 'institution',
  CONTACT_DATA: 'contact-data',
  INSTUTIONAL_DATA: 'institutional-data',
  OWN_DATA: 'institution',
  SUBJECTS: 'subject',
  SOCIAL_SKILL: 'social-skills',
  RESUME: 'download-profile/photo',
  PROVINCES: 'zone/provinces/',
  DEPARTMENTS: 'zone/departments/',
  NATIONALITIES: 'zone/nationalities/',
  PRIVATE: 'profile/private/',
  INSTITUTION_GENERAL_DATA: 'institution/update-logo/',
  TERM_AND_CONDITIONS: 'institution/terms-and-conditions',
  DELETE_FILE: 'deleteFiles/',
  EMPLOYEES_QUANTITY: 'employees-quantity',
  STUDENTS_QUANTITY: 'students-quantity',
  LEVELS: 'levels',
  LEVEL_MODALITIES: 'level-modality',
  INSTITUTION_TYPES: 'institution-type',
  SUPLENCIES: 'suplency',
}

export const getUserInformationService = (payload: getUserInformation): AxiosPromise<any> =>
  httpModule.get(paths.USER_INFORMATION + payload.userId)

export const getProvincesService = (payload: GetProvinces): AxiosPromise<any> =>
  httpModule.get(paths.PROVINCES, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getDepartmentsService = (payload: GetDepartments): AxiosPromise<any> =>
  httpModule.get(`${paths.PROVINCES}${payload.provinceId}/departments`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getLocalitiesService = (payload: GetLocalities): AxiosPromise<any> =>
  httpModule.get(`${paths.DEPARTMENTS}${payload.departmentId}/localities`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getRegionsService = (payload: GetRegions): AxiosPromise<any> =>
  httpModule.get(`${paths.PROVINCES}${payload.provinceId}/regions`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getNationalitiesService = (payload: GetNationalities): AxiosPromise<any> =>
  httpModule.get(paths.NATIONALITIES, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const postregisterGeneralInformation = (
  values: PostRegisterGeneralInformation,
): AxiosPromise<any> => {
  const formData = new FormData()

  // Agregar los campos de texto plano al FormData
  const payload = {
    lastName: values.lastName,
    name: values.name,
  }

  formData.append('data', JSON.stringify(payload))

  // Agregar los archivos al FormData
  values.filesUp.forEach((file) => {
    formData.append('filesUp', file, file.name)
  })

  values.photoUp.forEach((file) => {
    formData.append('photoUp', file, file.name)
  })
  // Realizar la solicitud HTTP con axios
  return httpModule.post(
    paths.ROOT_USER + `/${values.userId}/` + paths.GENERAL_INFORMATION,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${values.token}`,
      },
    },
  )
}

export const postPersonalData = (values: PostRegisterPersonalData): AxiosPromise<any> =>
  httpModule.post(
    paths.ROOT_USER + `/${values.userId}/` + paths.PERSONAL_DATA,
    {
      address: values.address,
      profile: {
        id: values.id,
        dni: values.dni,
        cuil: values.cuil,
        email: values.email,
        phoneNumber: values.phoneNumber,
        cellPhone: values.cellPhone,
        gender: values.gender,
        civilStatus: values.civilStatus,
        birthdate: values.birthdate,
        antiquityDate: values.antiquityDate,
        age: values.age,
        nationalityId: values.nationality,
        prepaidHealth: values.prepaidHealth,
        antiquityMonth: values.antiquityMonth,
        antiquityYears: values.antiquityYears,
        mobility: values.mobility,
        retired: values.retired,
        suplency: values.suplency,
        suplencyText: values.suplencyText,
        whatsappContact: values.whatsappContact,
        webURL: values.webURL,
        linkedinURL: values.linkedinURL,
        instagramURL: values.instagramURL,
        twitterURL: values.twitterURL,
        facebookURL: values.facebookURL,
      },
      suplencies: values.suplencies,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const postEducation = (values: PostRegisterEducation): AxiosPromise<any> =>
  httpModule.post(
    paths.EDUCATION + '/' + paths.PERSONAL_DATA + `/${values.userId}/` + paths.USER,
    {
      title: {
        ...values.title,
      },
      courses: values.courses,
      languages: values.languages,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const postJobsExperiences = (values: PostRegisterJobsExpriences): AxiosPromise<any> =>
  httpModule.post(
    paths.ROOT_USER + `/${values.userId}/` + paths.JOBS_EXPERIENCE,
    {
      jobsExperiences: values.jobsExperiences,
      socialSkills: values.socialSkills,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const postPreferences = (values: PostRegisterPreferences): AxiosPromise<any> =>
  httpModule.post(
    `preference${paths.NEW_PREFERENCE}`,
    {
      intendedSalary: values.intendedSalary,
      levels: values.levels,
      positions: values.positions,
      subjects: values.subjects,
      levelModalities: values.levelModalities,
      institutionTypes: values.institutionTypes,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const postRegisterInstitutionProfile = (
  values: PostRegisterInstitutionProfile,
): AxiosPromise<any> => {
  const formData = new FormData()

  // Agregar los campos de texto plano al FormData
  const payload = {
    cue: values.cue,
    entramite: values.entramite,
    provincialNumber: values.provincialNumber,
    institution: values.institution,
  }

  formData.append('data', JSON.stringify(payload))

  // Agregar los archivos al FormData
  values.filesUp.forEach((file) => {
    formData.append('filesUp', file, file.name)
  })

  // Realizar la solicitud HTTP con axios
  return httpModule.post(paths.ROOT_USER + `/${values.userId}/` + paths.INSTITUTION, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${values.token}`,
    },
  })
}

export const postValidationMail = (values: any): AxiosPromise<any> =>
  httpModule.get(paths.ROOT_USER + `/${values.code}/${values.id}/verification`)

export const putEducation = (values: PostAcademicRulesActions): AxiosPromise<any> =>
  httpModule.put(
    paths.EDUCATION + '/' + paths.PERSONAL_DATA + `/${values.id}/` + paths.USER,
    {
      id: values.id,
      type: values.type,
      fromDate: values.fromDate,
      toDate: values.toDate,
      institution: values.institution,
      teacherTitle: values.teacherTitle,
      percentage: values.percentage,
      title: values.title,
      schoolToDate: values.schoolToDate,
      schoolFromDate: values.schoolFromDate,
      teacherGraduated: values.teacherGraduated,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const deleteEducation = (values: PostAcademicRulesActions): AxiosPromise<any> =>
  httpModule.delete(paths.EDUCATION + '/' + paths.PERSONAL_DATA + `/${values.id}/` + paths.USER, {
    params: {
      id: values.id,
      type: values.type,
    },
    headers: {
      Authorization: `Bearer ${values.token}`,
    },
  })

export const putLanguage = (values: PostLanguageRulesActions): AxiosPromise<any> =>
  httpModule.put(
    paths.LANGUAGE + '/' + paths.PERSONAL_DATA + `/${values.id}/` + paths.USER,
    {
      id: values.id,
      level: values.level,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const deleteLanguage = (values: PostLanguageRulesActions): AxiosPromise<any> =>
  httpModule.delete(paths.LANGUAGE + '/' + `${values.id}/` + paths.USER + `/${values.userId}`, {
    params: {
      // id: values.userd,
    },
    headers: {
      Authorization: `Bearer ${values.token}`,
    },
  })

// preference y jobs experience edit y delelte

export const putJobsExperience = (values: PostJobsExperienceRulesActions): AxiosPromise<any> =>
  httpModule.put(
    paths.ROOT_USER + `/${values.id}/` + paths.JOBS_EXPERIENCE,
    {
      jobsExperiences: values.jobsExperiences,
      socialSkills: values.socialSkills,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const deleteJobsExperience = (values: PostJobsExperienceRulesActions): AxiosPromise<any> =>
  httpModule.delete(paths.ROOT_USER + `/${values.id}/` + paths.JOBS_EXPERIENCE, {
    params: {
      id: values.id,
    },
    headers: {
      Authorization: `Bearer ${values.token}`,
    },
  })

export const deleteSocialSkill = (values: PostSocialSkillRulesActions): AxiosPromise<any> =>
  httpModule.delete(paths.SOCIAL_SKILL + `/${values.id}/` + paths.USER + `/${values.userId}`, {
    headers: {
      Authorization: `Bearer ${values.token}`,
    },
  })

export const putPreference = (values: PostPreferenceRulesActions): AxiosPromise<any> =>
  httpModule.put(
    'preference' + '/' + paths.PERSONAL_DATA + `/${values.userId}/` + paths.USER + '/',
    {
      id: values.id,
      level: values.level,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const deletePreference = (values: PostPreferenceRulesActions): AxiosPromise<any> =>
  httpModule.delete(`preference/${values.id}`, {
    headers: {
      Authorization: `Bearer ${values.token}`,
    },
  })

// post insti

export const postInstitutionalData = (values: PostUpdateOneInstitution): AxiosPromise<any> => {
  const payload = values?.name
    ? {
      cue: values.cue,
      provincialNumber: values.provincialNumber,
      employeesQuantity: values.employeesQuantity,
      studentsQuantity: values.studentsQuantity,
      Address: values.Address,
      region: values.region,
      levelId: values.levelId,
      modality: values.modality,
      institutionTypeId: values.typeInstitution,
      name: values.name,
    }
    : {
      cue: values.cue,
      provincialNumber: values.provincialNumber,
      employeesQuantity: values.employeesQuantity,
      studentsQuantity: values.studentsQuantity,
      Address: values.Address,
      region: values.region,
      levelId: values.levelId,
      modality: values.modality,
      institutionTypeId: values.typeInstitution,
    }

  return httpModule.put(
    `${paths.OWN_DATA}/${values.id}`,

    payload,

    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )
}

export const postOwnData = (values: PostUpdateOneInstitution): AxiosPromise<any> =>
  httpModule.put(
    `${paths.OWN_DATA}/${values.id}`,
    {
      cuit: values.cuit,
      name: values.name,
      businessName: values.businessName,
      description: values.description,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const postContactData = (values: PostUpdateOneInstitution): AxiosPromise<any> =>
  httpModule.put(
    `${paths.OWN_DATA}/${values.id}`,
    {
      email: values.email,
      emailSubscribed: values.emailSubscribed,
      phoneNumber: values.phoneNumber,
      webURL: values.webURL,
      facebookURL: values.facebookURL,
      instagramURL: values.instagramURL,
      linkedinURL: values.linkedinURL,
      twitterURL: values.twitterURL,
    },
    {
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    },
  )

export const postInstitutionProfilePhotoService = (
  payload: PostInstitutionProfilePhotoPayload,
): AxiosPromise<any> => {
  const formData = new FormData()

  payload.photoUp.forEach((photo) => {
    formData.append('photoUp', photo, photo.name)
  })

  return httpModule.post(`${paths.INSTITUTION_GENERAL_DATA}${payload.institutionId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${payload.token}`,
    },
  })
}

export const getResumeDownload = (values: GetResumeDownload): AxiosPromise<any> =>
  httpModule.get(
    `users/${values.userId}/${paths.RESUME}/${values.photo}${values.institutionId ? `?institutionId=${values.institutionId}` : ''
    }`,
    { responseType: 'blob' },
  )

export const getPrivateModeService = (payload: GetPrivateModePayload): AxiosPromise<any> =>
  httpModule.get(`users/${payload.userId}/${paths.PRIVATE}${payload.private}`)

export const getTermsAndConditions = (): AxiosPromise<any> =>
  httpModule.get(paths.TERM_AND_CONDITIONS, { responseType: 'blob' })

export const deleteFileService = (payload: DeleteFilePayload): AxiosPromise<any> =>
  httpModule.delete(`${paths.DELETE_FILE}${payload.fileId}`, {
    headers: {
      Authorization: `Bearer ${payload.token}`,
    },
  })

export const getLanguagesService = (payload: LanguagesPayload): AxiosPromise<any> =>
  httpModule.get(paths.LANGUAGE, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getEmployeesQuantityService = (payload: EmployeesQuantityPayload): AxiosPromise<any> =>
  httpModule.get(paths.EMPLOYEES_QUANTITY, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getStudentsQuantityService = (payload: StudentsQuantityPayload): AxiosPromise<any> =>
  httpModule.get(paths.STUDENTS_QUANTITY, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getLevelsService = (payload: LevelsPayload): AxiosPromise<any> =>
  httpModule.get(paths.LEVELS, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getLevelModalitiesService = (payload: LevelModalitiesPayload): AxiosPromise<any> =>
  httpModule.get(paths.LEVEL_MODALITIES, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getInstitutionTypesService = (payload: InstitutionTypesPayload): AxiosPromise<any> =>
  httpModule.get(paths.INSTITUTION_TYPES, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getSocialSkillsService = (payload: SocialSkillsPayload): AxiosPromise<any> =>
  httpModule.get(paths.SOCIAL_SKILL, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getSuplenciesService = (payload: SuplenciesPayload): AxiosPromise<any> =>
  httpModule.get(paths.SUPLENCIES, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
