import { useMediaQuery } from '@chakra-ui/react'
import LandingLayout from '../../layouts/landing'
import RegisterCompo from '../../components/register'
import RegisterCard from '../../components/register/card'

const Register = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  return (
    <LandingLayout>
      {isMobile ? (
        <RegisterCompo />
      ) : (
        <RegisterCard>
          <RegisterCompo />
        </RegisterCard>
      )}
    </LandingLayout>
  )
}

export default Register
