import React from 'react'
import {
  Modal,
  ModalOverlay,
  useMediaQuery,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import GeneralInformation from '../../forms/informacion-general'
import PersonalData from '../../forms/datos-personales'
import Education from '../../forms/educacion'
import JobsExperience from '../../forms/experiencia-laboral'
import Preferencias from '../../forms/preferencias'
import { colors } from '../../../../utils/colors'

interface ModalResumeProps {
  isOpen: boolean
  onClose: () => void
  selectedOption: number
  setSelectedOption: (value: number) => void // Cambiar el tipo a (value: number) => void
}

const ModalResumeUser: React.FC<ModalResumeProps> = ({
  isOpen,
  onClose,
  selectedOption,
  setSelectedOption,
}) => {
  const renderComponent = () => {
    switch (selectedOption) {
      case 1:
        return <GeneralInformation onClose={onClose} />
      case 2:
        return <PersonalData />
      case 3:
        return <Education />
      case 4:
        return <JobsExperience />
      case 5:
        return <Preferencias />
      default:
        return null
    }
  }

  const [isMobile] = useMediaQuery('(max-width: 600px)')

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'} scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent width={isMobile ? '100%' : '80%'}>
        <ModalCloseButton />
        <ModalBody padding='30px'>{renderComponent()}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalResumeUser
