import { VStack } from '@chakra-ui/react'
import LandingLayout from '../../../layouts/landing'
import InstitutionComponent from '../../../components/profile/institution'

const Institution = () => {
  return (
    <LandingLayout>
      <VStack>
        <InstitutionComponent />
      </VStack>
    </LandingLayout>
  )
}

export default Institution
