import { useEffect, useState } from 'react'
import { Box, SimpleGrid, UseToastOptions, useToast } from '@chakra-ui/react'
import Advertsing from '../advertsing'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InformationInstitution from './informationInstitution'
import InformationResumeInstitution from './informationResume'
import { ownDataClean, userInformationCleanRequest } from '../../../state/modules/user/actions'
import { loginClean } from '../../../state/modules/login/actions'
import Loader from '../../loader'

const UserComponet = () => {
  const [isLogged, setIsLogged] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userInformation } = useSelector((state: any) => state.user)
  const { ownDataSuccess } = useSelector((state: any) => state?.user)
  const toast = useToast()

  const toastMessages: UseToastOptions[] = [
    {
      description: 'Datos guardados correctamente',
      status: 'success',
      duration: 2000,
    },
    {
      description: 'Ocurrio un error',
      status: 'error',
      duration: 2000,
    },
  ]

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])

  useEffect(() => {
    if (isLogged) {
      if (userInformation === undefined || userInformation === null) {
        handleLogout()
      }
    }
  }, [userInformation]) // aqui para desloggearme

  useEffect(() => {
    if (ownDataSuccess && ownDataSuccess !== null) {
      toast(toastMessages[0])
      dispatch(ownDataClean())
      window.location.reload()
    }

    if (ownDataSuccess === false) {
      toast(toastMessages[1])
      dispatch(ownDataClean())
    }
  }, [ownDataSuccess])

  const handleLogout = () => {
    dispatch(userInformationCleanRequest())
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    setIsLogged(false)
    dispatch(loginClean())
  }

  const loadingStyles = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <>
      {userInformation ? (
        <SimpleGrid
          columns={[1, null, 3]} // Mostrar una columna en dispositivos móviles y 3 columnas en escritorio
          width='100%'
          css={{
            '@media screen and (min-width: 768px)': {
              display: 'grid',
              gridTemplateColumns: '25% 55% 20%',
            },
          }}
          minWidth='fit-content'
        >
          <Box width='100' id='container'>
            <InformationInstitution />
          </Box>
          <Box width='100'>
            <InformationResumeInstitution />
          </Box>
          <Box width='100'>
            <Advertsing />
          </Box>
        </SimpleGrid>
      ) : (
        <Loader props={loadingStyles} />
      )}
    </>
  )
}

export default UserComponet
