import React from 'react'
import LandingLayout from '../../layouts/landing'
import { VStack } from '@chakra-ui/react'
import CandidateProfile from '../../components/CandidateProfile'

export const CandidateInfo: React.FC = () => {
  return (
    <LandingLayout>
      <VStack>
        <CandidateProfile />
      </VStack>
    </LandingLayout>
  )
}

export default CandidateInfo
