import './style.css'

import {
  AcademicRulesAmbos,
  AcademicRulesDocente,
  AcademicRulesNoDocente,
  LanguagesRules,
} from './_types'
import {
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { showDataTableStyles } from './styles'
import { useSelector } from 'react-redux'

const EducationResume = () => {
  const userInformation = useSelector((state: any) => state.user.userInformation ?? '')
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [docente, setDocente] = useState<AcademicRulesDocente[]>([])
  const [nodocente, setNodocente] = useState<AcademicRulesNoDocente[]>([])
  const [cursos, setCursos] = useState<AcademicRulesAmbos[]>([])
  const [language, setLanguage] = useState<LanguagesRules[]>([])

  useEffect(() => {
    userInformation === undefined || userInformation === null || userInformation === ''
      ? null
      : setearDatos()
  }, [userInformation])

  const setearDatos = () => {
    setLanguage(userInformation.Languages)

    const docenteTitles = userInformation.UserTitles.filter(
      (title: any) => title.type === 'docente' || title.type === 'cursando',
    )
    const nodocenteTitles = userInformation.UserTitles.filter(
      (title: any) => title.type === 'nodocente',
    )
    const cursos = userInformation.UserTitles.filter((title: any) => title.type === 'curso')

    setDocente(docenteTitles)
    setNodocente(nodocenteTitles)
    setCursos(cursos)
  }

  return (
    <>
      {docente.length || nodocente.length || cursos.length || language.length ? (
        <>
          <Box
            display={isMobile ? 'block' : 'block'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            {docente.length === 0 ? null : (
              <Box>
                <Heading sx={showDataTableStyles.tableTitle}>Docentes</Heading>
                <TableContainer>
                  <Table colorScheme='facebook' sx={showDataTableStyles.table}>
                    <Thead>
                      <Tr>
                        <Th sx={{ width: 0.2, ...showDataTableStyles.tHead }}>Año de inicio</Th>
                        <Th sx={{ width: 0.2, ...showDataTableStyles.tHead }}>
                          Año de finalización
                        </Th>
                        <Th sx={{ width: 0.3, ...showDataTableStyles.tHead }}>Título</Th>
                        <Th sx={{ width: 0.3, ...showDataTableStyles.tHead }}>Instituto</Th>
                        <Th sx={{ width: 0.1, ...showDataTableStyles.tHead }}>Estado</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {docente.map((docente, index) => (
                        <Tr key={index}>
                          <Td sx={showDataTableStyles.tData}>{docente.fromDate}</Td>
                          <Td sx={showDataTableStyles.tData}>{docente.toDate || 'En curso'}</Td>
                          <Td sx={showDataTableStyles.tData}>{docente.teacherTitle}</Td>
                          <Td sx={showDataTableStyles.tData}>{docente.institution}</Td>
                          <Td sx={showDataTableStyles.tData}>
                            {docente.teacherGraduated
                              ? docente.inProcess
                                ? 'En trámite'
                                : 'Completado'
                              : `${docente.percentage}%`}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            <br />
            {nodocente.length === 0 ? null : (
              <Box>
                <Heading sx={showDataTableStyles.tableTitle}>No docentes</Heading>

                <TableContainer>
                  <Table colorScheme='facebook' sx={showDataTableStyles.table}>
                    <Thead>
                      <Tr>
                        <Th sx={{ width: 0.2, ...showDataTableStyles.tHead }}>Año de inicio</Th>
                        <Th sx={{ width: 0.2, ...showDataTableStyles.tHead }}>
                          Año de finalización
                        </Th>
                        <Th sx={{ width: 0.25, ...showDataTableStyles.tHead }}>Título</Th>
                        <Th sx={{ width: 0.25, ...showDataTableStyles.tHead }}>Instituto</Th>
                        <Th sx={{ width: 0.1, ...showDataTableStyles.tHead }}>Estado</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {nodocente.map((nodocente, index) => (
                        <Tr key={index}>
                          <Td sx={showDataTableStyles.tData}>{nodocente.schoolFromDate}</Td>
                          <Td sx={showDataTableStyles.tData}>
                            {nodocente.schoolToDate || 'En curso'}
                          </Td>
                          <Td sx={showDataTableStyles.tData}>{nodocente.title}</Td>
                          <Td sx={showDataTableStyles.tData}>{nodocente.institution}</Td>
                          <Td sx={showDataTableStyles.tData}>
                            {nodocente.teacherGraduated
                              ? nodocente.inProcess
                                ? 'En trámite'
                                : 'Completado'
                              : `${nodocente.percentage}%`}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            <br />
            {cursos.length === 0 ? null : (
              <Box>
                <Heading sx={showDataTableStyles.tableTitle}>Cursos</Heading>

                <TableContainer>
                  <Table colorScheme='facebook' sx={showDataTableStyles.table}>
                    <Thead>
                      <Tr>
                        <Th sx={{ width: 0.25, ...showDataTableStyles.tHead }}>Año de inicio</Th>
                        <Th sx={{ width: 0.25, ...showDataTableStyles.tHead }}>
                          Año de finalización
                        </Th>
                        <Th sx={{ width: 0.25, ...showDataTableStyles.tHead }}>Título</Th>
                        <Th sx={{ width: 0.25, ...showDataTableStyles.tHead }}>Instituto</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cursos.map((curso, index) => (
                        <Tr key={index}>
                          <Td sx={showDataTableStyles.tData}>{curso.fromDate}</Td>
                          <Td sx={showDataTableStyles.tData}>
                            {curso.toDate === '' || curso.toDate === null
                              ? 'En curso'
                              : curso.toDate}
                          </Td>
                          <Td sx={showDataTableStyles.tData}>{curso.title}</Td>
                          <Td sx={showDataTableStyles.tData}>{curso.institution}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            <br />
          </Box>
          <Box
            display={isMobile ? 'block' : 'block'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            {language.length !== 0 &&
              (isMobile ? (
                <Box display={'flex'} flexDirection={'column'}>
                  <Heading sx={showDataTableStyles.tableTitle}>Idiomas</Heading>
                  <Box sx={showDataTableStyles.languageDataContainerMobile}>
                    {language.map((language, index) => (
                      <Text key={index} m={2} sx={showDataTableStyles.languageData}>
                        {language.description}
                      </Text>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box display={isMobile ? 'block' : 'flex'} flexWrap={'wrap'}>
                  <Heading sx={showDataTableStyles.tableTitle}>Idiomas</Heading>
                  {language.map((language, index) => (
                    <Text key={index} m={2} sx={showDataTableStyles.languageData}>
                      {language.description}
                    </Text>
                  ))}
                </Box>
              ))}
            <br />
          </Box>
        </>
      ) : (
        <Box sx={showDataTableStyles.noResults}>
          <Text>*No existen registros asociados</Text>
        </Box>
      )}
    </>
  )
}

export default EducationResume
