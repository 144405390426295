import { colors } from '../../utils/colors'
import { grid } from '@chakra-ui/react'

export const favoritesStyles = {
  container: {
    width: '100%',
    padding: '1.1% 4.3% 4.3% 4%',

    height: '76vh',
  },
  heading: {
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '35.68px',
    color: colors.principal,
    marginBottom: '10px',
  },
  containerMobile: { width: '100%', padding: '6% 4.3% 8% 4%' },
  favoritesContainer: {
    width: '100%',
    display: 'flex',
    height: '95%',
    gap: '2%',
  },
  favoritesContainerMobile: { display: 'flex', flexDirection: 'column', rowGap: '20px' },

  favoritesList: {
    border: `1px solid ${colors.principal}`,
    borderRadius: '10px',
    padding: '25px 36px 25px 36px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4%',
    height: '100%',
    width: '69%',
  },

  favoritesListMobile: { height: '100%' },
  favoritesCardContainer: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    height: '100%',
  },
  favoritesCardContainerMobile: { display: 'flex', flexDirection: 'column', rowGap: '20px' },
  noFavoritesContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.principal,
  },
  noFavoritesContainerMobile: {
    height: '30vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.principal,
  },
  loading: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingMobile: {
    width: '100%',
    height: '30vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
