export const filterSvg = {
  d: 'M10.9259 17.6432H17.5926C18.0174 17.6437 18.4259 17.8064 18.7348 18.098C19.0437 18.3896 19.2296 18.7882 19.2545 19.2122C19.2794 19.6363 19.1414 20.0539 18.8688 20.3796C18.5961 20.7054 18.2094 20.9147 17.7876 20.9649L17.5926 20.9766H10.9259C10.5011 20.9761 10.0925 20.8134 9.78361 20.5218C9.47471 20.2302 9.28883 19.8316 9.26393 19.4076C9.23904 18.9835 9.37701 18.5659 9.64966 18.2402C9.92231 17.9144 10.3091 17.7051 10.7309 17.6549L10.9259 17.6432ZM7.59255 9.3099H20.9259C21.3507 9.31037 21.7593 9.47303 22.0682 9.76464C22.3771 10.0563 22.5629 10.4548 22.5878 10.8789C22.6127 11.303 22.4748 11.7205 22.2021 12.0463C21.9295 12.372 21.5427 12.5814 21.1209 12.6316L20.9259 12.6432H7.59255C7.16776 12.6428 6.75917 12.4801 6.45027 12.1885C6.14138 11.8969 5.9555 11.4983 5.9306 11.0742C5.90571 10.6502 6.04368 10.2326 6.31633 9.90684C6.58898 9.58109 6.97573 9.37174 7.39755 9.32156L7.59255 9.3099ZM2.59255 0.976562H25.9259C26.3507 0.977034 26.7593 1.13969 27.0682 1.43131C27.3771 1.72292 27.5629 2.12148 27.5878 2.54555C27.6127 2.96962 27.4748 3.38719 27.2021 3.71295C26.9295 4.03871 26.5427 4.24806 26.1209 4.29823L25.9259 4.3099H2.59255C2.16775 4.30942 1.75917 4.14676 1.45027 3.85515C1.14138 3.56353 0.955496 3.16497 0.930601 2.7409C0.905706 2.31683 1.04368 1.89926 1.31633 1.57351C1.58898 1.24775 1.97573 1.0384 2.39755 0.988229L2.59255 0.976562Z',
  fill: '#004876',
}

export const positionTypeOptions = [
  { value: 'DOCENTE', label: 'Docente' },
  { value: 'NO DOCENTE', label: 'No Docente' },
]

export const mobilityOptions = [
  { value: 'si', label: 'Si' },
  { value: 'no', label: 'No' },
]
export const retiredOptions = [
  { value: 'si', label: 'Si' },
  { value: 'no', label: 'No' },
]

export const suplenciesOptions = [
  { value: 'si', label: 'Si' },
  { value: 'no', label: 'No' },
]
export const orderOptions = [
  { id: '1', value: 'ASC_publication', label: 'Fecha de publicación más antigüa' },
  { id: '2', value: 'DESC_publication', label: 'Fecha de publicación más reciente' },
  { id: '3', value: 'ASC_application', label: 'Fecha de postulación más antigüa' },
  { id: '4', value: 'DESC_application', label: 'Fecha de postulación más reciente' },
]

export const teacherGraduatedOptions = [
  { value: 'NONE', label: 'No' },
  { value: 'COMPLETE', label: 'Si' },
  { value: 'IN_PROGRESS', label: 'Cursando' },
]

export const jobApplicationsFields = [
  {
    name: 'Nro de referencia',
    id: 'referenceNumber',
    type: 'number',
    isShown: true,
    isFiltered: true,
    input: 'number',
    required: false,
    length: null,
    order: 3,
    width: 10,
  },

  {
    name: 'Provincia',
    id: 'applicationProvince',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'multipleSelect',
    required: false,
    length: null,
    order: 5,
    width: 8,
  },
  {
    name: 'Departamento / Partido',
    id: 'applicationDepartment',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'multipleSelect',
    required: false,
    length: null,
    order: 6,
    width: 8,
  },
  {
    name: 'Localidad',
    id: 'applicationLocality',
    type: 'multipleSelect',
    isShown: true,
    isFiltered: true,
    input: 'text',
    required: false,
    length: null,
    order: 7,
    width: 8,
  },
  {
    name: 'Tipo de cargo',
    id: 'positionType',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'select',
    required: true,
    length: null,
    order: 1,
    width: 5,
  },
  {
    name: 'Puesto de trabajo',
    id: 'position',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'multipleSelect',
    required: true,
    length: null,
    order: 2,
    width: 12,
  },
  {
    name: 'Fecha de publicación',
    id: 'publicationDate',
    type: 'text',
    isShown: true,
    isFiltered: false,
    input: 'date',
    required: false,
    length: null,
    order: 4,
    width: null,
  },
  {
    name: 'Desde',
    id: 'applicationDateFrom',
    type: 'text',
    isShown: false,
    isFiltered: true,
    input: 'date',
    required: false,
    length: null,
    order: 8,
    width: null,
  },
  {
    name: 'Hasta',
    id: 'applicationDateTo',
    type: 'text',
    isShown: false,
    isFiltered: true,
    input: 'date',
    required: false,
    length: null,
    order: 9,
    width: null,
  },
]

export const applicationsHandlerFields = [
  {
    name: 'CUE',
    id: 'cue',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'multipleSelect',
    required: true,
    length: null,
    order: 1,
    width: 10,
  },

  {
    name: 'Nro de referencia',
    id: 'referenceNumber',
    type: 'number',
    isShown: true,
    isFiltered: true,
    input: 'number',
    required: false,
    length: null,
    order: 2,
    width: 10,
  },

  {
    name: 'Tipo de cargo',
    id: 'positionType',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'select',
    required: false,
    length: null,
    order: 4,
    width: 5,
  },
  {
    name: 'Puesto de trabajo',
    id: 'position',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'multipleSelect',
    required: false,
    length: null,
    order: 5,
    width: 12,
  },
  {
    name: 'Nivel',
    id: 'level',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'multipleSelect',
    required: false,
    length: null,
    order: 10,
    width: 12,
  },
  {
    name: 'Modalidad',
    id: 'institutionTypes',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'multipleSelect',
    required: false,
    length: null,
    order: 11,
    width: 12,
  },
  {
    name: 'Título docente',
    id: 'teacherGraduated',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'multipleSelect',
    required: false,
    length: null,
    order: 6,
    width: 12,
  },
  {
    name: 'Estado del aviso',
    id: 'adStatus',
    type: 'text',
    isShown: true,
    isFiltered: true,
    input: 'multipleSelect',
    required: false,
    length: null,
    order: 3,
    width: 12,
  },
  {
    name: 'Nombre',
    id: 'applicationName',
    type: 'text',
    isShown: true,
    isFiltered: false,
    input: null,
    required: false,
    length: null,
    order: 7,
    width: 12,
  },
  {
    name: 'Apellido',
    id: 'applicationLastName',
    type: 'text',
    isShown: true,
    isFiltered: false,
    input: null,
    required: false,
    length: null,
    order: 11,
    width: 12,
  },
  {
    name: 'Departamento/Partido',
    id: 'applicationDepartment',
    type: 'text',
    isShown: true,
    isFiltered: false,
    input: null,
    required: false,
    length: null,
    order: 12,
    width: 13,
  },
  {
    name: 'Suplencias',
    id: 'suplencies',
    type: 'select',
    isShown: false,
    isFiltered: true,
    input: null,
    required: false,
    length: null,
    order: 13,
    width: 13,
  },
  {
    name: 'Movilidad propia',
    id: 'mobility',
    type: 'select',
    isShown: false,
    isFiltered: true,
    input: null,
    required: false,
    length: null,
    order: 14,
    width: 13,
  },
  {
    name: 'Jubilado',
    id: 'retired',
    type: 'select',
    isShown: false,
    isFiltered: true,
    input: null,
    required: false,
    length: null,
    order: 15,
    width: 13,
  },
  {
    name: 'Idiomas',
    id: 'languages',
    type: 'select',
    isShown: false,
    isFiltered: true,
    input: null,
    required: false,
    length: null,
    order: 16,
    width: 13,
  },
]
