import { Box, Card, CardBody, CardHeader, Heading, Icon, Image, Img, Stack, Text, Tooltip, useMediaQuery } from '@chakra-ui/react'
import { CheckIcon, DownloadIcon } from '@chakra-ui/icons'
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'
import React, { FC, useEffect, useState } from 'react'

import { CandidatePersonalDataProps } from '../_types'
import { candidateProfileStyles } from '../styles'
import noPhoto from '../../../assets/images/profile/ProfileEmpty.png'
import { setData } from '../../../utils/candidates'
import { dateTimeUntilNow } from '../../../utils/profile'
import { TbClockCog } from 'react-icons/tb'

const CandidatePersonalData: FC<CandidatePersonalDataProps> = ({
  data,
  suplencies,
  img,
  onClick,
  onModalOpen,
  userFavorites,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [atomizedEmail, setAtomizedEmail] = useState<any>([])

  const _suplencies = suplencies
  const birthDate =
    data?.birthdate === '***'
      ? data?.birthdate
      : data?.birthdate && data?.birthdate !== null && data?.birthdate !== ''
        ? new Date(data?.birthdate).toISOString().split('T')[0].split('-').reverse().join('-')
        : '-'

  const favoriteFolders = `Favorito en ${userFavorites
    ?.map((user: any) =>
      user?.UserFavoriteFolder === null ? 'general' : user?.UserFavoriteFolder?.name,
    )
    .join(', ')}`

  const fullAntiquity = dateTimeUntilNow(data?.antiquityDate || '', true)

  const mappedFullAntiquity = {
    years: fullAntiquity.years,
    months: fullAntiquity.months,
    days: (fullAntiquity.days >= 0) && fullAntiquity.days + 1
  }

  useEffect(() => {
    if (data?.email) {
      const AtomizedEmail = data && ['Email:\xA0', ...data.email.split('')]
      setAtomizedEmail(AtomizedEmail)
    }
  }, [data?.email])

  return (
    <Card
      boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
      borderRadius='12px'
      p={['20px', '40px 68px', '40px', '30px', '40px 68px']}
    >
      <CardHeader p='0'>
        <Heading sx={candidateProfileStyles.bodyTitle}>Datos del individuo</Heading>
      </CardHeader>

      <CardBody p='0' mt='30px'>
        {/* BODY TITLE */}
        <Box
          display='flex'
          flexDirection={['column', 'row']}
          flexWrap='wrap'
          w='100%'
        >

          {/* DNI */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0%', '0%', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>DNI:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.dni ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* CUIL */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>CUIL:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.cuil ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* EDAD */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Edad:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.age ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* EMAIL */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
              flexWrap='wrap'
            >
              <Box display='flex' flexWrap='wrap'>
                {atomizedEmail.map((item: any, i: number) => (
                  <Text key={i} sx={(i === 0) ? candidateProfileStyles.adDataItemReferenceText : candidateProfileStyles.adDataItemText} whiteSpace='break-spaces' flexWrap='wrap'>{item}</Text>
                ))}
              </Box>
            </Box>
          </Box>

          {/* GENERO */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Género:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.gender ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* ESTADO CIVIL */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Estado civil:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.civilStatus ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* TELÉFONO */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Teléfono:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.phoneNumber ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* FECHA DE NACIMIENTO */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Fecha de nacimiento:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {birthDate ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* NACIONALIDAD */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Nacionalidad:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.Nationality?.description ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* CALLE */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Calle:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.Address?.street ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* NUMERO */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Número:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.Address?.number ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* PISO */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Piso:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.Address?.floor ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* DEPARTAMENTO */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Departamento:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.Address?.department ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* CÓDIGO POSTAL */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Código postal:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.Address?.postalCode ?? '-'}
              </Text>
            </Box>
          </Box>

          {/* MOVILIDAD PROPIA */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Movilidad propia:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.mobility === '***'
                  ? data?.mobility
                  : data?.mobility === 'true'
                    ? 'Si'
                    : data?.mobility === null
                      ? '-'
                      : 'No'}
              </Text>
            </Box>
          </Box>

          {/* SUPLENCIAS */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Suplencias:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                {data?.suplency === '***'
                  ? data?.suplency
                  : data?.suplency === true
                    ? 'Si'
                    : data?.suplency === null
                      ? '-'
                      : 'No'}
              </Text>
            </Box>
          </Box>

          {/* TURNOS */}
          <Box
            w={['100%', '33%', '50%', '33%', '33%']}
            p='0'
            display='flex'
            mt={['15px', '30px', '24px', '10px']}
          >
            <Box w={['100%', '100%', '100%', '100%', '100%', '75%']}
              display='flex'
              ml={['0', '0', '0', '0', '0', '0']}
            // flexDirection={(suplencies?.length === 1) ? 'row' : suplencies.length > 1 ? 'column' : 'row'}
            >
              <Text sx={candidateProfileStyles
                .adDataItemReferenceText}>Turnos:&nbsp;</Text>
              <Text sx={{
                textTransform: 'capitalize', ...candidateProfileStyles.adDataItemText
              }}>
                <Box display='flex' flexWrap='wrap'>

                  {data?.suplency === '***'
                    ? '***'
                    :
                    suplencies.map((suplency, i) => (
                      <>
                        <Text key={suplency.id} display='inline-block'>{suplency.description}</Text>
                        <Box display='inline-block'>{(i !== (suplencies.length - 1)) && <Text>&nbsp;{'-'}&nbsp;</Text>}</Box>
                      </>
                    )
                    ) ?? '-'
                  }
                </Box>
              </Text>
            </Box>
          </Box>
        </Box>
      </CardBody>
    </Card>
  )
}

export default CandidatePersonalData
