import { AxiosPromise } from 'axios'
import httpModule from '../../../../utils/http'
import { NewContactPayload, NewSuscriptionPayload } from './types'

const paths = {
  NEW_CONTACT: 'landing/newContact',
  NEW_SUSCRIPTION: 'landing/newSuscribeNewsletter',
}

export const postNewContactService = (payload: NewContactPayload): AxiosPromise<any> => {
  const contact = {
    name: payload.name,
    email: payload.email,
    phone: payload.phone,
    role: payload.role,
    comment: payload.comment,
  }

  return httpModule.post(`${paths.NEW_CONTACT}`, contact)
}

export const postNewSuscriptionService = (payload: NewSuscriptionPayload): AxiosPromise<any> => {
  const suscriber = {
    email: payload.email,
  }

  return httpModule.post(`${paths.NEW_SUSCRIPTION}`, suscriber)
}
