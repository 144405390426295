import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react'

import AssociatedUsersModal from './AsocciatedUsersModal'
import InstitutionModal from './InstitutionModal'
import React from 'react'
import { RequestModal } from './RequestModal'
import UserModal from './UserModal'

interface AbmModalProps {
  isOpen: boolean
  onClose: () => void
  selectedOption: string
  currentUser?: any
  currentInstitution?: any
  isLoading?: boolean
  setIsLoading?: any
}
const AbmModal: React.FC<AbmModalProps> = ({
  isOpen,
  onClose,
  selectedOption,
  currentUser,
  currentInstitution,
  isLoading,
  setIsLoading,
}) => {
  const renderComponent = () => {
    switch (selectedOption) {
      case '1':
        return <UserModal action='add' />
      case '2':
        return <UserModal action='edit' currentUser={currentUser} />
      case '3':
        return <UserModal action='view' currentUser={currentUser} />
      case '4':
        return <RequestModal action='handle' currentUser={currentUser} />
      case '5':
        return <InstitutionModal action='add' isLoading={isLoading} setIsLoading={setIsLoading} />
      case '6':
        return (
          <InstitutionModal
            action='edit'
            currentInstitution={currentInstitution}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )
      case '7':
        return <AssociatedUsersModal institution={currentInstitution} />

      default:
        break
    }
  }
  return (
    <Modal
      scrollBehavior='inside'
      size={selectedOption === '7' ? '6xl' : '4xl'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        containerProps={{
          zIndex: '1600',
        }}
      >
        <ModalCloseButton />
        <ModalBody>{renderComponent()}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AbmModal
