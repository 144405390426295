import { position, textDecoration } from '@chakra-ui/react';
import { colors } from '../../../../utils/colors'
import { MdPadding } from 'react-icons/md';

const fontFamily = `
@font-face {
 font-family: 'Roboto';
  src: local('Roboto'),
    url(../../../../assets/fonts/Roboto/Roboto-Regular.ttf) format('opentype');
}`;

export const featuredAdvicesStyles = {
  actionButton: {
    border: `solid 1px ${colors.institutionalRed}`,
    color: colors.institutionalRed,
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: colors.institutionalRed
    },
    fontSize: '14px',
    fontWeight: '200'
  },
  advice: {
    
      position: 'relative',
      backgroundColor: '#fff',
      // pointerEvents: 'none',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: '#F5F6FF',
      },
      pill: {
        position: 'absolute',
        top: '14px',
        right: '14px',
        width: '60px',
        height: '28px',
        borderRadius: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(130, 197, 145, 0.2)',
        // padding:'3px 6px',
        // zIndex: '1',
        // pointerEvents: 'none',
        // pointerEvents: 'auto',
        '&:hover': {
          backgroundColor: 'rgba(130, 197, 145, 0.38)',
        },
        title: {
          color: '#00B050',
          fontSize:'14px',
          fontWeight:'500'
        }
      },
      adTitle: {
        fontWeight: '400',
        color: colors.principal
      },
      levelTitle: {
        fontSize: '14px',
        fontWeight: '200',
        color: '#56769A'
      },
      zoneTitle: {
        fontSize: '14px',
        fontWeight: '400',
        color: '#525252'
      },
    actionBtn: {
      color: colors.institutionalRed,
      fontWeight: 200,
      '&:hover': {
        textDecoration: 'none',
        fontWeight: 'bold',
      },
    }


  }
}

