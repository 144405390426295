import {
  AddFolderPayload,
  FavoriteNotePayload,
  FavoriteUpdatePayload,
  FavoritesByFolderPayload,
  FoldersListPayload,
  deleteFolderPayload,
  renameFolderPayload,
} from './types'

import { AxiosPromise } from 'axios'
import httpModule from '../../../../utils/http'

const paths = {
  FOLDERS_LIST: 'favorites/folders',
  ADD_FOLDER: 'favorites/addFolder',
  DELETE_FOLDER: 'favorites/folders/delete/',
  RENAME_FOLDER: 'favorites/folders/rename',
  FAVORITES_BY_FOLDER: 'favorites/folders/',
  FAVORITE_NOTE: 'favorites/updateNotes',
  FAVORITE_UPDATE: 'favorites/addOrDelete',
}

export const postAddFolderService = (payload: AddFolderPayload): AxiosPromise<any> => {
  const { name } = payload
  return httpModule.post(
    paths.ADD_FOLDER,
    { name },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const deleteFolderService = (payload: deleteFolderPayload): AxiosPromise<any> => {
  const { folderId, token } = payload
  return httpModule.delete(`${paths.DELETE_FOLDER}${folderId}`, {
    headers: {
      Authorization: `${token}`,
    },
  })
}

export const renameFolderService = (payload: renameFolderPayload): AxiosPromise<any> => {
  const { folderId, name, token } = payload
  return httpModule.put(
    paths.RENAME_FOLDER,
    { folderId, name },
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  )
}

export const getFoldersListService = (payload: FoldersListPayload): AxiosPromise<any> =>
  httpModule.get(paths.FOLDERS_LIST, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getFavoritesByFolderService = (payload: FavoritesByFolderPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.FAVORITES_BY_FOLDER}${payload.folderId}?page=${payload.page}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const putFavoriteNoteService = (payload: FavoriteNotePayload): AxiosPromise<any> => {
  const { favoriteId, notes, token } = payload

  return httpModule.put(
    paths.FAVORITE_NOTE,
    { favoriteId, notes },
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  )
}

export const postFavoriteUpdateService = (payload: FavoriteUpdatePayload): AxiosPromise<any> => {
  const { userId, folders, token } = payload

  const foldersArray = folders.sort((a: any, b: any) => {
    if (a.folderId === null) return -1
    if (b.folderId === null) return 1
    return 0
  })

  return httpModule.post(
    paths.FAVORITE_UPDATE,
    { userId, folders: foldersArray },
    {
      headers: {
        Authorization: `${token}`,
      },
    },
  )
}
