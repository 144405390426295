export const colors = {
  brand: {
    pink: {
      default: '#E23B5A',
      100: '#EBA79F',
    },
    blue: {
      default: '#153e75',
      100: '#56769A',
    },
  },
  accesibility: {
    pink: {
      default: '#D93856',
      100: '#BA5659',
    },
    blue: {
      default: '#03466F',
      100: '#56769A',
    },
  },
  white: '#FFFFFF',
  black: '#000000',
  secundary: '#56769A',
  principal: '#004876',
  green: 'green',
  red: '#CB4335',
  institutionalRed: '#E03F59',
  unchecked: 'rgba(164, 180, 201, 0.3)',
  states: { disabled: 'red', active: 'green', draft: 'purple', expired: 'gray', cover: 'yellow' },
}
