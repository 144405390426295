import { FC } from 'react'
import { useMediaQuery } from '@chakra-ui/react'
import LandingLayout from '../../../layouts/landing'
import LoginCard from '../../../components/login/card'
import ChangeForm from '../../../components/forms/change-form'

const Change: FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  return (
    <LandingLayout>
      {isMobile ? (
        <ChangeForm />
      ) : (
        <LoginCard>
          <ChangeForm />
        </LoginCard>
      )}
    </LandingLayout>
  )
}

export default Change
