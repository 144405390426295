import * as Yup from 'yup'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import {
  contactDataClean,
  contactDataRequest,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import { FormContact } from './_types'
import { PostInstitutionForm } from '../_typesinstitution'
import { useParams } from 'react-router-dom'

const toastMessages: UseToastOptions[] = [
  {
    description: 'Datos guardados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const Contact = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const state = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const userInformation = useSelector((state: any) => state.user?.userInformation ?? '')
  const { id } = useParams()
  const institutoFilter = userInformation?.Institutions.filter((item: any) => item.id === id)
  const toast = useToast()

  const { contactDataSuccess } = useSelector((state: any) => state?.user)

  useEffect(() => {
    if (contactDataSuccess && contactDataSuccess !== null) {
      toast(toastMessages[0])
      dispatch(contactDataClean())
      window.location.reload()
    }

    if (contactDataSuccess === false) {
      toast(toastMessages[1])
      dispatch(contactDataClean())
    }
  }, [contactDataSuccess])

  const initialValues: FormContact = {
    email: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.email : '-',
    emailSubscribed:
      institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.emailSubscribed : true,
    telefono: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.phoneNumber : '-',
    linkedinURL:
      institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.linkedinURL : '',
    twitterURL: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.twitterURL : '',
    weburl: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.webURL : '',
    facebookURL:
      institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.facebookURL : '',
    instagram:
      institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.instagramURL : '',
  }

  const handleSubmit = (values: any) => {
    const updatedInitialValuesForm: PostInstitutionForm = {
      id: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.id : '',
      userId: state.user.userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      email: values.email ?? '',
      phoneNumber: values.telefono ?? '',
      emailSubscribed: values.emailSubscribed ?? true,
      webURL: values.weburl ?? '',
      linkedinURL: values.linkedinURL ?? '',
      instagramURL: values.instagram ?? '',
      facebookURL: values.facebookURL ?? '',
      twitterURL: values.twitterURL ?? '',
      cue: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.cue : '',
      region: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.region : '',
      cuit: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.cuit : '',
      provincialNumber:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.provincialNumber : '',
      name: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.name : '',
      businessName:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.businessName : '',
      employeesQuantity:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.employeesQuantity : '',
      studentsQuantity:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.studentsQuantity : '',
      inProcess: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.inProcess : '',
      Address: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.Address : '',
      levelId: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.levelId : '',
      modality: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.modality : '',
      typeInstitution:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.typeInstitution : '',
      description:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.description : '',
    }

    dispatch(contactDataRequest(updatedInitialValuesForm))
    dispatch(userInformationRequest({ userId: state.user.userInformation.id }))
  }
  const validateSchema = Yup.object({
    email: Yup.string().email('Email no válido').required('Ingrese un email'),
    telefono: Yup.string()
      .test({
        name: 'is-number',
        test(value, ctx) {
          if (value && value.length > 0 && Number.isNaN(Number(value))) {
            return ctx.createError({ message: 'El campo no acepta letras' })
          }
          return true
        },
      })
      .min(8, 'El número debe tener un mínimo de 8 dígitos')
      .required('Campo requerido'),
  }).defined()

  return (
    <Box maxWidth='100%' margin='0 auto' display='flex' flexDirection='column' alignItems='center'>
      <Box display='flex' justifyContent='center'>
        <Text mb={4} width={isMobile ? '100%' : '100%'} fontWeight='bold'>
          DATOS DE CONTACTO
        </Text>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validateSchema}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form
            style={{
              display: 'flex',
              width: `${isMobile ? '100%' : '60%'}`,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <FormControl>
              <FormLabel htmlFor='email'>Email*</FormLabel>
              <Field
                as={Input}
                mb={4}
                type='text'
                name='email'
                placeholder='Ej: correo@correo.com'
              />
              {errors.email && touched.email ? (
                <Text color='red' fontSize={'sm'}>
                  {errors.email}
                </Text>
              ) : null}
            </FormControl>
            <FormControl display={'flex'} alignItems={'center'} columnGap={4} mb={4}>
              <FormLabel htmlFor='emailSubscribed' m={0}>
                Quiero recibir perfiles interesantes
              </FormLabel>
              <Field type='checkbox' name='emailSubscribed' />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='telefono'>Teléfono*</FormLabel>
              <Field
                as={Input}
                mb={4}
                type='text'
                name='telefono'
                maxLength='10'
                onChange={(e: any) => setFieldValue('telefono', e.target.value.trim())}
                placeholder='Ej: 1152223334'
              />
              {errors.telefono && touched.telefono ? (
                <Text color='red' fontSize={'sm'}>
                  {errors.telefono}
                </Text>
              ) : null}
            </FormControl>
            <Heading
              mb={4}
              sx={{ fontSize: '16px', fontWeight: 'inherit', textAlign: 'left', width: '100%' }}
            >
              Redes sociales
            </Heading>

            <FormControl
              sx={{ display: 'grid', gridTemplateColumns: '3fr 7fr', alignItems: 'center' }}
            >
              <FormLabel margin={'0 8px 16px 0'} htmlFor='linkedinURL'>
                www.linkedin.com/
              </FormLabel>
              <Field as={Input} mb={4} name='linkedinURL' placeholder='Linkedin' />
            </FormControl>
            <FormControl
              sx={{ display: 'grid', gridTemplateColumns: '3fr 7fr', alignItems: 'center' }}
            >
              <FormLabel margin={'0 8px 16px 0'} htmlFor='facebookURL'>
                www.facebook.com/
              </FormLabel>
              <Field as={Input} mb={4} name='facebookURL' placeholder='Facebook' />
            </FormControl>
            <FormControl
              sx={{ display: 'grid', gridTemplateColumns: '3fr 7fr', alignItems: 'center' }}
            >
              <FormLabel margin={'0 8px 16px 0'} htmlFor='instagram'>
                www.instagram.com/
              </FormLabel>
              <Field as={Input} mb={4} name='instagram' placeholder='Instagram' />
            </FormControl>
            <FormControl
              sx={{ display: 'grid', gridTemplateColumns: '3fr 7fr', alignItems: 'center' }}
            >
              <FormLabel margin={'0 8px 16px 0'} htmlFor='twitterURL'>
                www.twitter.com/
              </FormLabel>
              <Field as={Input} mb={4} name='twitterURL' placeholder='Twitter' />
            </FormControl>
            <FormControl>
              <Field as={Input} mb={4} name='weburl' placeholder='Página web' />
            </FormControl>
            <Box display='flex' justifyContent='center' width={isMobile ? '100%' : 'auto'}>
              <Button mt={4} colorScheme='blue' width={isMobile ? '100%' : '100%'} type='submit'>
                Guardar cambios
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default Contact
