import { colors } from '../../utils/colors'

export const invitesStyles = {
  thead: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '14px',
  },
  tdata: { fontSize: '14px' },
  loader: {
    width: '100%',
    height: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsContainer: {
    width: '100%',
    height: '20vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    textAlign: 'center',
    padding: '20px 0px',
    fontWeight: 'bold',
  },
}
