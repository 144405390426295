import { applications } from './_ducks'

export const APPLICATIONS_LIST_POST_REQUEST: string = applications.defineType(
  'APPLICATIONS_LIST_POST_REQUEST',
)
export const APPLICATIONS_LIST_POST_SUCCESS: string = applications.defineType(
  'APPLICATIONS_LIST_POST_SUCCESS',
)
export const APPLICATIONS_LIST_POST_FAILURE: string = applications.defineType(
  'APPLICATIONS_LIST_POST_FAILURE',
)
export const APPLICATIONS_LIST_POST_CLEAN: string = applications.defineType(
  'APPLICATIONS_LIST_POST_CLEAN',
)

export const HANDLE_APPLICATIONS_LIST_POST_REQUEST: string = applications.defineType(
  'HANDLE_APPLICATIONS_LIST_POST_REQUEST',
)
export const HANDLE_APPLICATIONS_LIST_POST_SUCCESS: string = applications.defineType(
  'HANDLE_APPLICATIONS_LIST_POST_SUCCESS',
)
export const HANDLE_APPLICATIONS_LIST_POST_FAILURE: string = applications.defineType(
  'HANDLE_APPLICATIONS_LIST_POST_FAILURE',
)
export const HANDLE_APPLICATIONS_LIST_POST_CLEAN: string = applications.defineType(
  'HANDLE_APPLICATIONS_LIST_POST_CLEAN',
)

export const DOWNLOAD_APPLICATIONS_SHEET_POST_REQUEST: string = applications.defineType(
  'DOWNLOAD_APPLICATIONS_SHEET_POST_REQUEST',
)
export const DOWNLOAD_APPLICATIONS_SHEET_POST_SUCCESS: string = applications.defineType(
  'DOWNLOAD_APPLICATIONS_SHEET_POST_SUCCESS',
)
export const DOWNLOAD_APPLICATIONS_SHEET_POST_FAILURE: string = applications.defineType(
  'DOWNLOAD_APPLICATIONS_SHEET_POST_FAILURE',
)
export const DOWNLOAD_APPLICATIONS_SHEET_POST_CLEAN: string = applications.defineType(
  'DOWNLOAD_APPLICATIONS_SHEET_POST_CLEAN',
)

export const APPLICATION_DELETE_REQUEST: string = applications.defineType(
  'APPLICATION_DELETE_REQUEST',
)
export const APPLICATION_DELETE_SUCCESS: string = applications.defineType(
  'APPLICATION_DELETE_SUCCESS',
)
export const APPLICATION_DELETE_FAILURE: string = applications.defineType(
  'APPLICATION_DELETE_FAILURE',
)
export const APPLICATION_DELETE_CLEAN: string = applications.defineType('APPLICATION_DELETE_CLEAN')
