import { Box, Flex, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react'
import { colors } from '../../../../utils/colors'
import FormSubscribe from './form-subscribe'
import { IsInstitutional } from '../../shared/our-users-says'
import contactUsImage from '../../../../assets/images/landing/footer-web.png'
import { FC } from 'react'

const ContactUs: FC<IsInstitutional> = ({ isInstit = false }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [criticBreakpoint] = useMediaQuery('(max-width: 1346px)')
  const [isXlarge] = useMediaQuery('(max-width: 1536px)')
  
  return (
    <Box
      display='flex'
      height={{ base: '243px', md: '453px', lg: '453px', xl: '500px', '2xl': '800px' }}
      width={['100vw', '100vw']}
      alignItems={['center', 'center']}
      bgImage={(criticBreakpoint || isXlarge) ? undefined : contactUsImage}
      bgPosition={{ base: 'right bottom', md: 'right bottom', lg: '90% bottom', '2xl': 'right bottom' }}
      bgSize={['cover', 'cover']}
      bgRepeat='no-repeat'
      pl='4.5vw'
      bgColor='#f8f9fa'
    >
      <Box
        width={{ base: '75%', md: '65%', lg: '525px', '2xl': '1000px' }}
        mx={(criticBreakpoint || isXlarge) ? 'auto' : '0'}
      >
        <FormSubscribe isInstit={isInstit} />
      </Box>
    </Box>
  )
}

export default ContactUs
