import * as Yup from 'yup'

import {
  Badge,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useMediaQuery,
} from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { InstitutionData, JobsModalProps } from './_types'
import React, { FC, useEffect, useState } from 'react'
import { availabilityOptions, jobAdsFields, positionTypeOptions } from '../../../utils/jobAds'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmModal from '../../confirmModal'
import { JobDataField } from '../JobsTable/_types'
import Select from 'react-select'
import { colors } from '../../../utils/colors'
import { editJobRequest } from '../../../state/modules/ads/actions'
import { jobsModalStyles } from './styles'

const JobsModal: FC<JobsModalProps> = ({ isOpen, onClose, currentAd, action }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const institutions = useSelector((state: any) => state?.ads?.institutionsList)
  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const user = useSelector((state: any) => state?.user)
  const ads = useSelector((state: any) => state?.ads)
  const timeSlots = useSelector((state: any) => state?.ads?.timeSlotsList)
  const jobTypes = useSelector((state: any) => state?.ads?.jobTypesList)
  const dispatch = useDispatch()
  const [editedAdValues, setEditedAdValues] = useState<any>({})
  const [draft, setDraft] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [cueOptions, setCueOptions] = useState<any[]>([])
  const [cues, setCues] = useState<any[]>([])
  const [institutionOptions, setInstitutionOptions] = useState<any[]>([])
  const [levels, setLevels] = useState<any[]>([])
  const [levelOptions, setLevelOptions] = useState<any[]>([])
  const [addressOptions, setAddressOptions] = useState<any[]>([])
  const [addresses, setAddresses] = useState<any[]>([])
  const [positionType, setPositionType] = useState<any>(null)
  const [isPositionShow, setIsPositionShow] = useState<boolean>(false)
  const [position, setPosition] = useState<any>(null)
  const [ocupationOptions, setOcupationOptions] = useState<any[]>([])
  const [positionOptions, setPositionOptions] = useState<any[]>([])
  const [availability, setAvailability] = useState<any[]>([])
  const [timeSlotOptions, setTimeSlotOptions] = useState<any[]>([])
  const [timePeriod, setTimePeriod] = useState<any[]>([])
  const [jobTypeOptions, setJobTypeOptions] = useState<any[]>([])
  const [modality, setModality] = useState<any>(null)
  const [suplencyOptions, setSuplencyOptions] = useState<any[]>([])
  const [suplencies, setSuplencies] = useState<any[]>([])
  const [areaTypeOptions, setAreaTypeOptions] = useState<any>([])
  const [areaType, setAreaType] = useState<any>([])
  const [areasOptions, setAreasOptions] = useState<any[]>([])
  const [areas, setAreas] = useState<any>([])
  const [institutionsLevelModalities, setInstitutionsLevelModalities] = useState<any>([])
  const [levelModalitiesOptions, setLevelModalitiesOptions] = useState<any>([])
  const [levelModalities, setLevelModalities] = useState<any>([])
  const [institutionTypesOptions, setInstitutionTypesOptions] = useState<any>([])
  const [institutionTypes, setInstitutionTypes] = useState<any>([])

  const [isExpirationComplete, setIsExpirationComplete] = useState<boolean>(false)
  const [isPublicationComplete, setIsPublicationComplete] = useState<boolean>(false)
  const [showOptionalFields, setShowOptionalFields] = useState<boolean>(false)

  const today = new Date().toISOString().split('T')[0]

  const institutionFields = jobAdsFields
    .filter((field) => field.section === 'institution')
    .sort((a, b) => a.order - b.order)

  const adFields = jobAdsFields.filter((field) => field.section === 'ad' && field.required === true)

  const dateFields = jobAdsFields.filter(
    (field) =>
      field.id === 'publicationDate' ||
      field.id === 'expirationDate' ||
      field.id === 'creationDate',
  )
  const optionalFields = jobAdsFields.filter(
    (field) =>
      field.section === 'ad' &&
      field.required === false &&
      field.id !== 'publicationDate' &&
      field.id !== 'expirationDate' &&
      field.id !== 'creationDate',
  )

  const stateColor =
    currentAd?.Status?.id === '5'
      ? colors.states.draft
      : currentAd?.Status?.id === '1'
      ? colors.states.active
      : currentAd?.Status?.id === '3'
      ? colors.states.cover
      : currentAd?.Status?.id === '4'
      ? colors.states.expired
      : colors.states.disabled

  useEffect(() => {
    if (isOpen && institutions) {
      setCueOptions([])
      institutions.map((institution: InstitutionData) => {
        const cue = institution?.cue.toString()
        const lastDigits = cue?.slice(-2)
        const firstLength = cue?.length - lastDigits?.length
        const transformedCue = `${cue?.slice(0, firstLength)}-${lastDigits}`
        setCueOptions((prev: any) => [
          ...prev,
          { value: institution?.cue, label: transformedCue, id: institution?.id },
        ])
      })
    }
  }, [institutions, isOpen])

  useEffect(() => {
    if (isOpen && timeSlots) {
      setTimeSlotOptions([])
      timeSlots.map((timeSlot: any) => {
        setTimeSlotOptions((prev: any) => [
          ...prev,
          { value: timeSlot?.id, label: timeSlot?.description },
        ])
      })
    }
  }, [isOpen, timeSlots])

  useEffect(() => {
    if (isOpen && jobTypes) {
      setJobTypeOptions([])
      jobTypes.map((jobType: any) => {
        setJobTypeOptions((prev: any) => [
          ...prev,
          { value: jobType?.id, label: jobType?.description },
        ])
      })
    }
  }, [isOpen, jobTypes])

  useEffect(() => {
    if (isOpen && ads) {
      const ocupations = ads?.positionsList
        ?.filter((pos: any) => pos.type == 'NO DOCENTE')
        .map((ocupation: any) => {
          return { value: ocupation.id, label: ocupation.description }
        })
      setOcupationOptions(ocupations)

      const positions = ads?.positionsList
        ?.filter((pos: any) => pos.type == 'DOCENTE')
        .map((position: any) => {
          return { value: position.id, label: position.description }
        })
      setPositionOptions(positions)

      const areaType = ads?.subjectsList
        ?.map((area: any) => area?.type)
        .filter((val: any, ind: number, arr: any) => arr.indexOf(val) === ind)
        ?.sort()
        .map((area: any, i: number) => {
          return {
            value: i + 1,
            label:
              area == 'CURRICULAR' ? 'Áreas curriculares' : 'Otras áreas de desarrollo docente',
            type: area,
          }
        })

      setAreaTypeOptions(areaType)
    }
  }, [ads, isOpen])

  useEffect(() => {
    if (isOpen && user) {
      const suplencies = user?.suplenciesList?.map((suplency: any) => {
        return { value: suplency.id, label: suplency.description }
      })
      setSuplencyOptions(suplencies)
    }
  }, [user, isOpen])

  useEffect(() => {
    if (isOpen && currentAd && currentAd !== null) {
      setCues(
        currentAd?.Institutions?.map((institution: any) => {
          return { value: institution?.cue, label: institution?.cue, id: institution?.id }
        }),
      )
      setInstitutionOptions(
        currentAd?.Institutions?.map((institution: any) => {
          return { value: institution?.id, label: institution?.name }
        }),
      )
      const lev: any[] = []
      const addresses: any[] = []
      const institutionTypes: any[] = []

      currentAd?.Institutions?.forEach((insti: any) => {
        const repeatLevel = lev.find((lev: any) => lev.value === insti?.Level?.id)
        if (!repeatLevel) {
          lev.push({ value: insti?.Level?.id, label: insti?.Level?.description })
        }
        const repeatAddress = addresses.find(
          (add: any) => add.value === insti?.Address?.Locality?.id,
        )

        if (!repeatAddress) {
          addresses.push({
            value: insti?.Address?.Locality?.id,
            label: `${insti?.Address?.Province?.description} - ${insti?.Address?.Department?.description} - ${insti?.Address?.Locality?.description}`,
            zone: {
              localityId: insti?.Address?.Locality?.id,
              provinceId: insti?.Address?.Province?.id,
              departmentId: insti?.Address?.Department?.id,
            },
          })
        }

        const repeatInstitutionType = institutionTypes.find(
          (lev: any) => lev.value === insti?.InstitutionType?.id,
        )
        if (!repeatInstitutionType) {
          insti?.InstitutionType !== null &&
            institutionTypes.push({
              value: insti?.InstitutionType?.id,
              label: insti?.InstitutionType?.description,
            })
        }
      })

      setLevelOptions(lev)
      setLevels(
        currentAd?.Levels?.map((level: any) => {
          return { value: level?.id, label: level?.description }
        }),
      )

      setAddressOptions(addresses)
      setAddresses(
        currentAd?.Zones?.map((zone: any) => {
          return {
            value: zone?.Locality?.id,
            label: `${zone?.Province?.description.toLowerCase()} - ${zone?.Department?.description.toLowerCase()} - ${zone?.Locality?.description.toLowerCase()}`,
            zone: {
              localityId: zone?.Locality?.id,
              provinceId: zone?.Province?.id,
              departmentId: zone?.Department?.id,
            },
          }
        }),
      )
      setInstitutionTypesOptions(institutionTypes)
      setInstitutionTypes(
        currentAd?.InstitutionTypes?.map((institutionType: any) => {
          return { value: institutionType?.id, label: institutionType?.description }
        }),
      )

      currentAd?.Institutions?.forEach((insti: any) => {
        const levelModalities: any[] = []

        insti?.LevelModalities?.forEach((element: any) => {
          const repeatLevelModalities = levelModalities.find(
            (levMod: any) => levMod.value === element?.Modality?.id,
          )
          if (!repeatLevelModalities) {
            levelModalities.push({
              value: element?.Modality?.id,
              label: element?.Modality?.description,
              levelId: element?.levelId,
            })
          }
        })
      })

      let options: any[] = []

      const levelModalities: any[] = []

      currentAd?.Institutions?.forEach((insti: any) => {
        insti?.LevelModalities?.forEach((element: any) => {
          const repeatLevelModalities = levelModalities.find(
            (levMod: any) => levMod.value === element?.Modality?.id,
          )
          if (!repeatLevelModalities) {
            levelModalities.push({
              value: element?.Modality?.id,
              label: element?.Modality?.description,
              levelId: element?.levelId,
            })
          }
        })
      })

      currentAd.Levels.forEach((element: any) => {
        const levels = levelModalities.filter((level: any) => level.levelId === element.id)

        options = [...options, ...levels]
        setLevelModalitiesOptions(options)
      })

      setLevelModalities(
        currentAd?.LevelModalities?.map((element: any) => {
          return {
            value: element?.Modality?.id,
            label: element?.Modality?.description,
          }
        }),
      )

      setAvailability(
        currentAd?.AdTimeAvailabilities?.map((time: any) => {
          return { value: time?.day, label: time?.day }
        }),
      )

      setTimePeriod(
        currentAd?.TimeSlots?.map((period: any) => {
          return { value: period?.id, label: period?.description }
        }),
      )

      currentAd?.JobType !== null
        ? setModality({ value: currentAd?.JobType?.id, label: currentAd?.JobType?.description })
        : setModality(null)

      currentAd?.Position !== null
        ? setPositionType({
            value: currentAd?.Position?.type,
            label: currentAd?.Position?.type?.toLowerCase(),
          })
        : setPositionType(null)

      currentAd?.Position !== null
        ? setPosition({
            value: currentAd?.Position?.id,
            label: currentAd?.Position?.description,
          })
        : setPosition(null)

      currentAd?.Subject !== null
        ? setAreaType({
            value: currentAd?.Subject?.type,
            label: currentAd?.Subject?.type?.split('-').join(' ').toLowerCase(),
          })
        : setAreaType(null)

      currentAd?.Position !== null
        ? setAreas({
            value: currentAd?.Subject?.id,
            label: currentAd?.Subject?.description,
          })
        : setAreas(null)

      setSuplencies(
        currentAd?.Suplencies?.map((suplency: any) => {
          return { value: suplency?.id, label: suplency?.description }
        }),
      )
      setIsPositionShow(currentAd?.Position?.type === 'DOCENTE' ? true : false)
    }
  }, [currentAd, isOpen])

  const renderInput = (field: JobDataField, setFieldValue?: any) => {
    switch (true) {
      case 'cue' === field.id:
        return (
          <>
            <FormLabel
              sx={
                isMobile ? jobsModalStyles.checkboxDisableMobile : jobsModalStyles.checkboxDisable
              }
            >
              {field.name} {field.required && '*'}
            </FormLabel>

            <Select
              placeholder='Selecciona tu CUE'
              name={field.id}
              styles={{
                menu: (baseStyles: any) => ({
                  ...baseStyles,
                }),
              }}
              isMulti
              options={cueOptions}
              onChange={(value) => {
                handleSearchCue(value, setFieldValue)
                setFieldValue(
                  'cue',
                  value.map((item: any) => {
                    return { id: item.id }
                  }),
                )
              }}
              value={cues}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )

      case 'institution' === field.id:
        return (
          <>
            <FormLabel
              sx={
                isMobile ? jobsModalStyles.checkboxDisableMobile : jobsModalStyles.checkboxDisable
              }
            >
              {field.name} {field.required && '*'}
              <FormLabel sx={isMobile ? jobsModalStyles.checkboxMobile : jobsModalStyles.checkbox}>
                <Field
                  style={isMobile ? jobsModalStyles.checkboxFieldMobile : {}}
                  as='input'
                  type='checkbox'
                  onChange={(e: any) => {
                    setFieldValue(`hide${field.id}`, e.target.checked)
                  }}
                  name={`hide${field.id}`}
                  disabled={action === 'view'}
                />
                No mostrar este dato en el aviso
              </FormLabel>
            </FormLabel>
            <Select
              placeholder='Institución seleccionada'
              name={field.id}
              styles={{
                menu: (baseStyles: any) => ({
                  ...baseStyles,
                }),
              }}
              isMulti
              value={institutionOptions}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'level' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona tu nivel'
              name={field.id}
              styles={{
                menu: (baseStyles) => ({
                  ...baseStyles,
                }),
              }}
              isMulti
              options={levelOptions}
              onChange={(value) => {
                handleLevel(value, setFieldValue)
                setFieldValue(
                  `${field.id}`,
                  value.map((item: any) => {
                    return { id: item.value }
                  }),
                )
              }}
              value={levels}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'address' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona tu localidad'
              name={field.id}
              styles={{
                menu: (baseStyles) => ({
                  ...baseStyles,
                }),
                option: (baseStyles: any) => ({
                  ...baseStyles,
                  textTransform: 'capitalize',
                }),
                valueContainer(base: any) {
                  return {
                    ...base,
                    textTransform: 'capitalize',
                  }
                },
                placeholder: (base: any) => ({
                  ...base,
                  textTransform: 'initial',
                }),
              }}
              isMulti
              options={addressOptions}
              onChange={(value) => {
                handleAddress(value)
                setFieldValue(
                  `${field.id}`,
                  value.map((item: any) => item.zone),
                )
              }}
              value={addresses}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'levelModalities' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona la característica'
              isMulti
              value={levelModalities}
              onChange={(value) => handleLevelModalitiesChange(value, setFieldValue, field.id)}
              name={field.name}
              options={levelModalitiesOptions}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'institutionTypes' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona la modalidad'
              isMulti
              value={institutionTypes}
              onChange={(value) => handleInstitutionTypesChange(value, setFieldValue, field.id)}
              name={field.name}
              options={institutionTypesOptions}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )

      case 'date' === field.input:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Field
              as={Input}
              type='date'
              name={field.id}
              min={today}
              onChange={(e: any) => {
                setFieldValue(field.id, e.currentTarget.value)
                handleDateChange(e, field.id)
              }}
              readOnly={
                'creationDate' === field.id
                  ? true
                  : action === 'view'
                  ? true
                  : currentAd?.Status?.id === '1' && 'publicationDate' === field.id
                  ? true
                  : false
              }
            />
          </>
        )

      case 'vacancies' === field.id || 'hours' === field.id || 'referenceNumber' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Field
              as={Input}
              min='1'
              type='number'
              name={field.id}
              readOnly={
                action === 'view' || currentAd?.Status?.id === '1'
                  ? true
                  : 'referenceNumber' === field.id
                  ? true
                  : false
              }
            />
          </>
        )
      case 'positionType' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona tu tipo de cargo'
              name={field.id}
              styles={{
                valueContainer: (baseStyles: any) => ({
                  ...baseStyles,
                  textTransform: 'capitalize',
                }),
              }}
              options={positionTypeOptions}
              value={positionType}
              onChange={(value: any) => {
                handleChangePositionType(value, setFieldValue)
                setFieldValue('positionType', value?.value)
              }}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'position' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona tu puesto'
              name={field.id}
              options={
                positionType?.value === 'DOCENTE'
                  ? positionOptions
                  : positionType?.value === 'NO DOCENTE'
                  ? ocupationOptions
                  : []
              }
              value={position}
              onChange={(value) => {
                handleChangePosition(value)
                setFieldValue('position', value?.value)
              }}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'availability' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required}
            </FormLabel>
            <Select
              placeholder='Selecciona tu disponibilidad'
              name={field.id}
              styles={{
                valueContainer: (baseStyles: any) => ({
                  ...baseStyles,
                  textTransform: 'capitalize',
                }),
              }}
              isMulti
              options={availabilityOptions}
              onChange={(value) => {
                handleChangeAvailability(value)
                setFieldValue(
                  'availability',
                  value.map((item: any) => {
                    return { day: item.value }
                  }),
                )
              }}
              value={availability}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'timePeriod' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona franja horaria'
              name={field.id}
              options={timeSlotOptions}
              onChange={(value) => {
                handleChangeTimePeriod(value)
                setFieldValue(
                  'timePeriod',
                  value.map((item: any) => {
                    return { id: item.value }
                  }),
                )
              }}
              value={timePeriod}
              isMulti
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'modality' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona modalidad'
              name={field.id}
              options={jobTypeOptions}
              onChange={(value) => {
                handleChangeModality(value)
                setFieldValue('modality', value?.value)
              }}
              value={modality}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'suplencies' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona suplencias'
              name={field.id}
              options={suplencyOptions}
              onChange={(value) => {
                handleChangeSuplencies(value)
                setFieldValue(
                  'suplencies',
                  value.map((item: any) => {
                    return { id: item.value }
                  }),
                )
              }}
              value={suplencies}
              isMulti
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'area' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              name={field.id}
              options={areasOptions}
              onChange={(value) => {
                handleChangeAreas(value)
                setFieldValue('area', value?.value)
              }}
              value={areas}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      case 'areaType' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              name={field.id}
              options={areaTypeOptions}
              onChange={(value) => handleChangeAreaType(value, setFieldValue)}
              value={areaType}
              styles={{ valueContainer: (base: any) => ({ ...base, textTransform: 'capitalize' }) }}
              isDisabled={action === 'view' || currentAd?.Status?.id === '1'}
            />
          </>
        )
      default:
        return (
          <>
            <FormLabel>
              {field.name} (máximo {field.length} caracteres) {field.required && '*'}
            </FormLabel>
            <Field
              as={Textarea}
              sx={jobsModalStyles.textarea}
              maxLength={field.length !== null ? field.length : 50}
              name={field.id}
              size={isMobile ? 'sm' : 'lg'}
              readOnly={action === 'view' || currentAd?.Status?.id === '1' ? true : false}
            />
          </>
        )
    }
  }

  const initialValues = {
    cue: currentAd?.Institutions?.map((institution: any) => {
      return { id: institution?.id }
    }),
    institution: [],
    hideinstitution: !currentAd?.showInstitutionName ?? true,
    level: currentAd?.Levels?.map((level: any) => {
      return { id: level?.id }
    }),
    address: currentAd?.Zones?.map((zone: any) => {
      return {
        localityId: zone?.Locality?.id,
        provinceId: zone?.Province?.id,
        departmentId: zone?.Department?.id,
      }
    }),
    referenceNumber: currentAd?.referenceNumber ?? '',
    title: currentAd?.title ?? '',
    creationDate:
      currentAd && currentAd !== null && currentAd?.createdAt !== null
        ? new Date(currentAd?.createdAt).toISOString().split('T')[0]
        : '',
    expirationDate:
      currentAd && currentAd !== null && currentAd?.expirationDate !== null
        ? new Date(currentAd?.expirationDate).toISOString().split('T')[0]
        : '',
    publicationDate:
      currentAd && currentAd !== null && currentAd?.publicationDate !== null
        ? new Date(currentAd?.publicationDate).toISOString().split('T')[0]
        : '',
    vacancies: currentAd?.vacancyAvailable ?? '',
    hours: currentAd?.amountofHours ?? '',
    positionType: currentAd?.Position?.type,
    position: currentAd?.Position?.id,
    areaType: currentAd?.Subject?.type,
    area: currentAd?.Subject?.id,
    availability: currentAd?.AdTimeAvailabilities?.map((time: any) => {
      return { day: time?.day }
    }),
    timePeriod: currentAd?.TimeSlots?.map((period: any) => {
      return { id: period?.id }
    }),
    jobExperience: currentAd?.workExperience ?? '',
    modality: currentAd?.JobType?.id,
    suplencies: currentAd?.Suplencies?.map((suplency: any) => {
      return { id: suplency?.id }
    }),
    tasks: currentAd?.requiredTask ?? '',
    softSkills: currentAd?.softSkills ?? '',
    levelModalities: currentAd?.LevelModalities?.map((level: any) => {
      return { id: level?.id }
    }),
    institutionTypes: currentAd?.InstitutionTypes?.map((type: any) => {
      return { id: type?.id }
    }),
  }

  const dateValidationSchema = Yup.object({
    cue: Yup.array().min(1, 'Debe seleccionar al menos una opción').required('Campo requerido'),
    hideinstitution: Yup.boolean(),
    level: Yup.array().min(1, 'Debe seleccionar al menos una opción').required('Campo requerido'),
    address: Yup.array().min(1, 'Debe seleccionar al menos una opción').required('Campo requerido'),
    levelModalities: Yup.array(),

    institutionTypes: Yup.array()
      .min(1, 'Debe seleccionar al menos una opción')
      .required('Campo requerido'),
    title: Yup.string().required('Campo requerido'),
    publicationDate: Yup.string(),
    expirationDate: Yup.string(),
    vacancies: Yup.number().min(1, 'Debe ser mayor a 0'),
    hours: Yup.number().min(1, 'Debe ser mayor a 0'),
    positionType: Yup.string().required('Campo requerido'),
    position: Yup.string().required('Campo requerido'),
    area: Yup.string(),
    availability: Yup.array(),
    timePeriod: Yup.array(),
    jobExperience: Yup.string(),
    modality: Yup.string(),
    suplencies: Yup.array(),
    tasks: Yup.string(),
    softSkills: Yup.string(),
  })

  const activeValidationSchema = Yup.object({
    expirationDate: Yup.string().required('Campo requerido'),
  })

  const draftValidationSchema = Yup.object({})

  const handleSearchCue = (value: any, setFieldValue: any) => {
    const insti: any[] = []
    const lev: any[] = []
    const addresses: any[] = []
    const levelModalities: any[] = []
    const institutionTypes: any[] = []

    setInstitutionOptions([])
    setLevels([])
    setTimeout(() => {
      setFieldValue('level', [])
    }, 100)
    setAddresses([])
    setTimeout(() => {
      setFieldValue('address', [])
    }, 150)
    setLevelModalities([])

    setTimeout(() => {
      setFieldValue('levelModalities', [])
    }, 125)

    setInstitutionTypes([])
    setTimeout(() => {
      setFieldValue('institutionTypes', [])
    }, 175)

    value.forEach((element: any) => {
      const match = institutions?.find(
        (institution: InstitutionData) => institution?.cue === element.value,
      )

      insti.push({ value: match?.id, label: match?.name })
      const repeatLevel = lev.find((lev: any) => lev.value === match?.Level?.id)
      if (!repeatLevel) {
        lev.push({ value: match?.Level?.id, label: match?.Level?.description })
      }
      const repeatAddress = addresses.find((add: any) => add.value === match?.Address?.Locality?.id)

      if (!repeatAddress) {
        addresses.push({
          value: match?.Address?.Locality?.id,
          label: `${match?.Address?.Province?.description} - ${match?.Address?.Department?.description} - ${match?.Address?.Locality?.description}`,
          zone: {
            localityId: match?.Address?.Locality?.id,
            provinceId: match?.Address?.Province?.id,
            departmentId: match?.Address?.Department?.id,
          },
        })
      }

      match?.LevelModalities.forEach((element: any) => {
        const repeatLevelModalities = levelModalities.find(
          (levMod: any) => levMod.value === element?.Modality?.id,
        )
        if (!repeatLevelModalities) {
          levelModalities.push({
            value: element?.Modality?.id,
            label: element?.Modality?.description,
            levelId: element?.levelId,
          })
        }
      })

      const repeatInstitutionType = institutionTypes.find(
        (lev: any) => lev.value === match?.InstitutionType?.id,
      )
      if (!repeatInstitutionType) {
        match?.InstitutionType !== null &&
          institutionTypes.push({
            value: match?.InstitutionType?.id,
            label: match?.InstitutionType?.description,
          })
      }
    })
    setCues(value)
    setInstitutionOptions(insti)
    setLevelOptions(lev)
    setAddressOptions(addresses)
    setInstitutionsLevelModalities(levelModalities)
    setInstitutionTypesOptions(institutionTypes)
  }
  const handleLevel = (value: any, setFieldValue: any) => {
    setLevelModalitiesOptions([])
    setLevelModalities([])
    setTimeout(() => {
      setFieldValue('levelModalities', [])
    }, 100)
    setLevels(value)
    let options: any[] = []

    value.forEach((element: any) => {
      const levels = institutionsLevelModalities.filter(
        (level: any) => level.levelId === element.value,
      )

      options = [...options, ...levels]
      setLevelModalitiesOptions(options)
    })
  }

  const handleAddress = (value: any) => {
    setAddresses(value)
  }

  const handleLevelModalitiesChange = (value: any, setFieldValue: any, name: string) => {
    setLevelModalities(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleInstitutionTypesChange = (value: any, setFieldValue: any, name: string) => {
    setInstitutionTypes(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleChangePositionType = (value: any, setFieldValue: any) => {
    setPositionType(value)
    value.value === 'DOCENTE' ? setIsPositionShow(true) : setIsPositionShow(false)
    setPosition([])
    setTimeout(() => {
      setFieldValue('position', '')
    }, 100)
  }

  const handleChangePosition = (value: any) => {
    setPosition(value)
  }

  const handleChangeAvailability = (value: any) => {
    setAvailability(value)
  }

  const handleChangeTimePeriod = (value: any) => {
    setTimePeriod(value)
  }

  const handleChangeModality = (value: any) => {
    setModality(value)
  }

  const handleChangeSuplencies = (value: any) => {
    setSuplencies(value)
  }
  const handleChangeAreaType = (value: any, setFieldValue: any) => {
    setAreaType(value)
    setAreas([])
    setTimeout(() => {
      setFieldValue('area', '')
    }, 100)
    setAreasOptions([])
    const areasList = ads?.subjectsList
      ?.filter((area: any) => {
        return area?.type === value.type
      })
      .map((area: any) => {
        return { value: area?.id, label: area?.description, type: area?.type }
      })

    setAreasOptions(areasList)
  }

  const handleChangeAreas = (value: any) => {
    setAreas(value)
  }
  const handleDateChange = (date: any, field: string) => {
    if (field === 'expirationDate') {
      if (date.target.value !== '' || currentAd?.expirationDate !== null)
        setIsExpirationComplete(true)
      else setIsExpirationComplete(false)
    } else if (field === 'publicationDate') {
      if (date.target.value !== '' || currentAd?.publicationDate !== null)
        setIsPublicationComplete(true)
      else setIsPublicationComplete(false)
    } else {
      setIsPublicationComplete(false)
      setIsExpirationComplete(false)
    }
  }
  const handleSubmit = (values: any) => {
    currentAd?.status === '5' ? setDraft(true) : setDraft(false)
    setEditedAdValues(values)
    setIsConfirmModalOpen(true)
  }

  const handleEditAd = () => {
    const token = localStorage.getItem('access_token') ?? ''
    const userId = userInformation?.id
    setDraft(true)

    const {
      expirationDate,
      publicationDate,
      hideinstitution,
      position,
      area,
      vacancies,
      modality,
      Address,
      availability,
      cue,
      level,
      timePeriod,
      suplencies,
      levelModalities,
      institutionTypes,
      hours,
      ...rest
    } = editedAdValues

    const upDatedValues = {
      token,
      userId,
      adId: currentAd?.id,
      cue: cue === '' ? null : cue,
      level: level === '' ? null : level,
      availability: availability === '' ? null : availability,
      address: Address === '' ? null : Address,
      position: position === '' ? null : position,
      area: area === '' ? null : area,
      vacancies: vacancies === '' ? null : vacancies,
      hours: hours === '' ? null : hours,
      modality: modality === '' ? null : modality,
      showInstitution: !hideinstitution,
      expirationDate: expirationDate === '' || draft ? null : expirationDate,
      publicationDate: publicationDate === '' || draft ? null : publicationDate,
      timePeriod: timePeriod === '' ? null : timePeriod,
      suplencies: suplencies === '' ? null : suplencies,
      levelModalities: levelModalities,
      institutionTypes: institutionTypes,
      ...rest,
    }

    dispatch(editJobRequest(upDatedValues))
  }

  return (
    <Modal scrollBehavior='inside' size='full' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
            title={'¿Está seguro?'}
            buttonOne='Si'
            buttonTwo='No'
            onConfirm={handleEditAd}
            styles={isMobile ? jobsModalStyles.modalStylesMobile : jobsModalStyles.modalStyles}
          />
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={
              currentAd?.status === '1'
                ? activeValidationSchema
                : isPublicationComplete ||
                  isExpirationComplete ||
                  currentAd?.expirationDate !== null ||
                  currentAd?.publicationDate !== null
                ? dateValidationSchema
                : draftValidationSchema
            }
          >
            {({ errors, touched, setFieldValue }) => {
              const touchedFields = touched as FormikTouched<any>
              const errorFields = errors as FormikErrors<any>

              return (
                <Form>
                  <Box sx={isMobile ? jobsModalStyles.containerMobile : jobsModalStyles.container}>
                    <Box
                      sx={isMobile ? jobsModalStyles.headingBoxMobile : jobsModalStyles.headingBox}
                    >
                      <Heading as='h1' sx={jobsModalStyles.heading}>
                        {action === 'edit' ? 'Edición' : 'Resumen'} del aviso
                      </Heading>
                      <Box sx={jobsModalStyles.badgeBox}>
                        <Text sx={jobsModalStyles.badgeText}>Estado del aviso:</Text>
                        <Badge sx={jobsModalStyles.badge} colorScheme={stateColor}>
                          {currentAd?.Status?.description}
                        </Badge>
                      </Box>
                    </Box>
                    <Box sx={isMobile ? jobsModalStyles.itemMobile : jobsModalStyles.item}>
                      <Heading
                        as='h2'
                        sx={isMobile ? jobsModalStyles.itemTitleMobile : jobsModalStyles.itemTitle}
                      >
                        Datos de la institución
                        <Text
                          sx={isMobile ? jobsModalStyles.commentMobile : jobsModalStyles.comment}
                        >
                          *(Solo se mostrarán las instituciones que tienen todos los datos cargados)
                        </Text>
                      </Heading>
                      <Box
                        sx={
                          isMobile
                            ? jobsModalStyles.inputContainerMobile
                            : jobsModalStyles.inputContainer
                        }
                      >
                        {institutionFields
                          .sort((a, b) => a.order - b.order)
                          .map((field) => (
                            <FormControl
                              sx={
                                isMobile
                                  ? field.id === 'address'
                                    ? jobsModalStyles.input2Mobile
                                    : field.id === 'levelModalities' ||
                                      field.id === 'institutionTypes'
                                    ? jobsModalStyles.input3Mobile
                                    : jobsModalStyles.inputMobile
                                  : field.id === 'address'
                                  ? jobsModalStyles.input2
                                  : field.id === 'levelModalities' ||
                                    field.id === 'institutionTypes'
                                  ? jobsModalStyles.input3
                                  : jobsModalStyles.input
                              }
                              display={field.id === 'levelModalities' ? 'none' : 'initial'}
                              key={field.order}
                            >
                              {renderInput(field, setFieldValue)}
                              {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                                <Text color='red' fontSize={'sm'}>{`${
                                  errorFields[field.id]
                                }`}</Text>
                              )}
                            </FormControl>
                          ))}
                      </Box>
                    </Box>
                    <Box sx={isMobile ? jobsModalStyles.itemMobile : jobsModalStyles.item}>
                      <Heading
                        as='h2'
                        sx={isMobile ? jobsModalStyles.itemTitleMobile : jobsModalStyles.itemTitle}
                      >
                        Datos del aviso
                        {currentAd?.Status?.id === '1' && (
                          <Text
                            sx={isMobile ? jobsModalStyles.commentMobile : jobsModalStyles.comment}
                          >
                            *(Solo es posible editar la fecha de vencimiento)
                          </Text>
                        )}
                      </Heading>
                      <Box
                        sx={
                          isMobile
                            ? jobsModalStyles.inputContainerMobile
                            : jobsModalStyles.inputContainer
                        }
                      >
                        {adFields
                          .sort((a, b) => a.order - b.order)
                          .map((field) => {
                            return field.id === 'areaType' || field.id === 'area' ? (
                              isPositionShow && (
                                <FormControl
                                  sx={
                                    isMobile ? jobsModalStyles.input2Mobile : jobsModalStyles.input2
                                  }
                                  key={field.order}
                                >
                                  {renderInput(field, setFieldValue)}
                                  {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                                    <Text color='red' fontSize={'sm'}>{`${
                                      errorFields[field.id]
                                    }`}</Text>
                                  )}
                                </FormControl>
                              )
                            ) : (
                              <FormControl
                                sx={
                                  isMobile ? jobsModalStyles.input2Mobile : jobsModalStyles.input2
                                }
                                key={field.order}
                              >
                                {renderInput(field, setFieldValue)}
                                {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                                  <Text color='red' fontSize={'sm'}>{`${
                                    errorFields[field.id]
                                  }`}</Text>
                                )}
                              </FormControl>
                            )
                          })}
                        {dateFields
                          .sort((a, b) => a.order - b.order)
                          .map((field) => (
                            <FormControl
                              sx={isMobile ? jobsModalStyles.input2Mobile : jobsModalStyles.input2}
                              key={field.order}
                            >
                              {renderInput(field, setFieldValue)}
                              {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                                <Text color='red' fontSize={'sm'}>{`${
                                  errorFields[field.id]
                                }`}</Text>
                              )}
                            </FormControl>
                          ))}
                      </Box>
                      <Box
                        sx={
                          isMobile
                            ? jobsModalStyles.inputContainerMobile
                            : jobsModalStyles.inputContainer
                        }
                      ></Box>
                    </Box>
                    <Box sx={isMobile ? jobsModalStyles.itemMobile : jobsModalStyles.item}>
                      <Heading
                        as='h2'
                        sx={
                          isMobile
                            ? jobsModalStyles.optionalItemTitleMobile
                            : jobsModalStyles.optionalItemTitle
                        }
                      >
                        Ver más datos del aviso{' '}
                        {showOptionalFields ? (
                          <ChevronUpIcon
                            sx={jobsModalStyles.chevronIcon}
                            onClick={() => setShowOptionalFields(!showOptionalFields)}
                          />
                        ) : (
                          <ChevronDownIcon
                            sx={jobsModalStyles.chevronIcon}
                            onClick={() => setShowOptionalFields(!showOptionalFields)}
                          />
                        )}
                      </Heading>
                      {showOptionalFields && (
                        <Box
                          sx={
                            isMobile
                              ? jobsModalStyles.inputContainerMobile
                              : jobsModalStyles.inputContainer
                          }
                        >
                          {optionalFields
                            .sort((a, b) => a.order - b.order)
                            .map((field) => {
                              return field.id === 'areaType' || field.id === 'area' ? (
                                isPositionShow && (
                                  <FormControl
                                    sx={
                                      isMobile
                                        ? jobsModalStyles.input2Mobile
                                        : jobsModalStyles.input2
                                    }
                                    key={field.order}
                                  >
                                    {renderInput(field, setFieldValue)}
                                    {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                                      <Text color='red' fontSize={'sm'}>{`${
                                        errorFields[field.id]
                                      }`}</Text>
                                    )}
                                  </FormControl>
                                )
                              ) : (
                                <FormControl
                                  sx={
                                    isMobile ? jobsModalStyles.input2Mobile : jobsModalStyles.input2
                                  }
                                  key={field.order}
                                >
                                  {renderInput(field, setFieldValue)}
                                  {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                                    <Text color='red' fontSize={'sm'}>{`${
                                      errorFields[field.id]
                                    }`}</Text>
                                  )}
                                </FormControl>
                              )
                            })}
                        </Box>
                      )}
                    </Box>
                    {action === 'edit' && (
                      <Box
                        sx={isMobile ? jobsModalStyles.buttonBoxMobile : jobsModalStyles.buttonBox}
                      >
                        <Button
                          sx={
                            isMobile ? jobsModalStyles.saveButtonMobile : jobsModalStyles.saveButton
                          }
                          _hover={isMobile ? {} : jobsModalStyles.saveButtonHover}
                          type='submit'
                        >
                          Guardar
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default JobsModal
