import { Link, Text } from '@chakra-ui/react'

const NoMatch = () => {
  return (
    <div>
      <Text>NoMatch</Text>
      <Link href='/'>
        <Text>Ir a página de inicio</Text>
      </Link>
    </div>
  )
}

export default NoMatch
