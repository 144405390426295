import { FC } from 'react'
import LandingLayout from '../../layouts/landing'
import { useDispatch } from 'react-redux'
import { validationMailRequest } from '../../state/modules/user/actions'
import { useSearchParams } from 'react-router-dom'
import { Box, Img, Text } from '@chakra-ui/react'
import { colors } from '../../utils/colors'

const EmailVerification: FC = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const id = searchParams.get('id')
  dispatch(validationMailRequest({ code: code, id: id }))
  return (
    <LandingLayout>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          padding: '120px 10%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <svg
          width='212'
          height='211'
          viewBox='0 0 212 211'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M183.087 87.5673C194.07 87.5673 202.974 78.6823 202.974 67.7221C202.974 56.7619 194.07 47.877 183.087 47.877C172.104 47.877 163.2 56.7619 163.2 67.7221C163.2 78.6823 172.104 87.5673 183.087 87.5673Z'
            fill='#E03F59'
          />
          <path
            d='M194.082 129.704C203.978 129.704 212 121.699 212 111.824C212 101.949 203.978 93.9434 194.082 93.9434C184.187 93.9434 176.165 101.949 176.165 111.824C176.165 121.699 184.187 129.704 194.082 129.704Z'
            fill='#E23B5A'
          />
          <path
            d='M185.594 171.925C194.339 171.925 201.429 164.85 201.429 156.123C201.429 147.396 194.339 140.321 185.594 140.321C176.848 140.321 169.759 147.396 169.759 156.123C169.759 164.85 176.848 171.925 185.594 171.925Z'
            fill='#B52652'
          />
          <path
            d='M157.389 200.242C165.225 200.242 171.577 193.903 171.577 186.084C171.577 178.264 165.225 171.925 157.389 171.925C149.553 171.925 143.201 178.264 143.201 186.084C143.201 193.903 149.553 200.242 157.389 200.242Z'
            fill='#862E56'
          />
          <path
            d='M121.125 211C128.536 211 134.543 205.006 134.543 197.611C134.543 190.216 128.536 184.221 121.125 184.221C113.715 184.221 107.707 190.216 107.707 197.611C107.707 205.006 113.715 211 121.125 211Z'
            fill='#4C3264'
          />
          <path
            d='M85.6323 209.083C91.9819 209.083 97.1292 203.947 97.1292 197.611C97.1292 191.274 91.9819 186.138 85.6323 186.138C79.2828 186.138 74.1355 191.274 74.1355 197.611C74.1355 203.947 79.2828 209.083 85.6323 209.083Z'
            fill='#352564'
          />
          <path
            d='M146.347 52.6594C156.891 52.6594 165.438 44.1298 165.438 33.608C165.438 23.0862 156.891 14.5566 146.347 14.5566C135.803 14.5566 127.256 23.0862 127.256 33.608C127.256 44.1298 135.803 52.6594 146.347 52.6594Z'
            fill='#E2696D'
          />
          <path
            d='M102.794 36.8525C112.992 36.8525 121.259 28.6028 121.259 18.4263C121.259 8.24971 112.992 0 102.794 0C92.5963 0 84.3293 8.24971 84.3293 18.4263C84.3293 28.6028 92.5963 36.8525 102.794 36.8525Z'
            fill='#E68883'
          />
          <path
            d='M61.2711 50.7544C70.7605 50.7544 78.4532 43.0778 78.4532 33.6082C78.4532 24.1385 70.7605 16.4619 61.2711 16.4619C51.7817 16.4619 44.089 24.1385 44.089 33.6082C44.089 43.0778 51.7817 50.7544 61.2711 50.7544Z'
            fill='#EAA7A0'
          />
          <path
            d='M29.4521 78.7956C38.4144 78.7956 45.6797 71.5454 45.6797 62.6019C45.6797 53.6583 38.4144 46.4082 29.4521 46.4082C20.4899 46.4082 13.2245 53.6583 13.2245 62.6019C13.2245 71.5454 20.4899 78.7956 29.4521 78.7956Z'
            fill='#7D93B1'
          />
          <path
            d='M15.2733 118.367C23.7083 118.367 30.5463 111.543 30.5463 103.126C30.5463 94.7085 23.7083 87.8848 15.2733 87.8848C6.8382 87.8848 0.000244141 94.7085 0.000244141 103.126C0.000244141 111.543 6.8382 118.367 15.2733 118.367Z'
            fill='#577599'
          />
          <path
            d='M23.0889 160.894C30.9968 160.894 37.4074 154.497 37.4074 146.606C37.4074 138.715 30.9968 132.317 23.0889 132.317C15.1811 132.317 8.77048 138.715 8.77048 146.606C8.77048 154.497 15.1811 160.894 23.0889 160.894Z'
            fill='#2E5D84'
          />
          <path
            d='M51.4469 193.074C58.8276 193.074 64.8108 187.104 64.8108 179.738C64.8108 172.373 58.8276 166.402 51.4469 166.402C44.0663 166.402 38.083 172.373 38.083 179.738C38.083 187.104 44.0663 193.074 51.4469 193.074Z'
            fill='#074670'
          />
          <path
            d='M146.074 85.4629L106 110.509L65.9258 85.4629V75.4443L106 100.491L146.074 75.4443M146.074 65.4258H65.9258C60.3655 65.4258 55.9072 69.884 55.9072 75.4443V135.556C55.9072 138.213 56.9627 140.761 58.8416 142.64C60.7204 144.519 63.2687 145.574 65.9258 145.574H146.074C148.731 145.574 151.28 144.519 153.158 142.64C155.037 140.761 156.093 138.213 156.093 135.556V75.4443C156.093 72.7872 155.037 70.239 153.158 68.3601C151.28 66.4813 148.731 65.4258 146.074 65.4258Z'
            fill='#004876'
          />
        </svg>
        <Text
          sx={{
            textAlign: ['center', 'left'],
            width: ['100%', '550px'],
            fontWeight: 'bold',
            fontSize: '30px',
            fontStyle: 'normal',
            lineHeight: '120%',
            mt: '43px',
            mb: '20px',
            color: colors.principal,
          }}
        >
          ¡Muchas gracias por registrarte!{' '}
        </Text>
        <Text sx={{ width: ['100%', '550px'], textAlign: ['center', 'left'], fontSize: '16px' }}>
          Tu cuenta ha sido activada correctamente
        </Text>
      </Box>
    </LandingLayout>
  )
}

export default EmailVerification
