import { colors } from '../../../../utils/colors'

export const usersFilterStyles = {
  box: {
    marginBottom: '40px',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    gap: '15px 20px',
    marginBottom: '40px',
  },
  label: { fontWeight: 'bold' },
  field: { width: '' },
  buttonsBox: { display: 'flex', justifyContent: 'flex-end', columnGap: '50px' },
  searchButton: {
    borderRadius: '5px',
    backgroundColor: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    color: '#fff',
  },
  cleanFilters: {
    backgroundColor: '#fff',
    color: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    borderRadius: '5px',
  },
  export: {
    backgroundColor: '#fff',
    color: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    borderRadius: '5px',
  },
}
