import { colors } from '../../../../../utils/colors'

export const formSubscribeStyles = {
  actionButton: {
    backgroundColor: colors.institutionalRed,
    color: '#fff',
    '&:hover': {
      backgroundColor: colors.institutionalRed
    },
    // fontSize:'1rem',
    fontWeight:'200'
  }
}
