import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Text, VStack, useToast } from '@chakra-ui/react'
import { SubscribeSchema } from '../_schema'
import { colors } from '../../../../../utils/colors'
import { PostSubscribe } from '../_types'
import { Form, Formik, FormikHelpers } from 'formik'
import BlueButton from '../../../../buttons/button-blue'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  newSuscriptionDataClean,
  newSuscriptionDataRequest,
} from '../../../../../state/modules/contact/actions'
// import ColorButton from '../../../../buttons/button-blue'
import { formSubscribeStyles } from './styles'
import { IsInstit } from '../../../../../pages/landing'
import PropTypes from 'prop-types'

const FormSubscribe: React.FC<IsInstit> = ({ isInstit }) => {

  const contact = useSelector((state: any) => state?.contact)
  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000

  useEffect(() => {
    if (contact.newSuscriptionSuccess && contact.newSuscriptionSuccess !== null) {
      toast({
        description: contact?.newSuscriptionStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(newSuscriptionDataClean())
    }
    if (contact.newSuscriptionSuccess === false) {
      toast({
        description: contact?.newSuscriptionStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(newSuscriptionDataClean())
    }
  }, [contact.newSuscriptionSuccess])

  const initialValuesSubscribe: PostSubscribe = {
    email: '',
  }

  const onSubmitSubscribe = (values: PostSubscribe, formikHelpers: FormikHelpers<PostSubscribe>) => {
    const suscription = { email: values.email }
    formikHelpers.resetForm()
    dispatch(newSuscriptionDataRequest(suscription))
  }

  return (
    <Formik
      initialValues={initialValuesSubscribe}
      onSubmit={onSubmitSubscribe}
      validationSchema={SubscribeSchema}
    >
      {({ values, errors, touched, handleChange, handleBlur, resetForm }) => (
        <Form>
          <VStack
            spacing={4}
            width={'100%'}
          >
            <Box
              width={{ base: '100%', md: '100%', lg: '100%', '2xl': '100%' }}
              color='#004876'
              fontSize={{ base: '14px', md: '22px', lg: '26px', '2xl': '45px' }}
              mb={{ base: '8px', md: '8px', lg: '30px', '2xl': '30px' }}
            >
              {isInstit ? 'Conocé todas nuestras últimas novedades' : 'Suscribite a nuestras novedades'}
            </Box>
            <FormControl isInvalid={Boolean(errors.email && touched.email)}>
              <HStack
                height={{ base: '38px', md: '42px', lg: '45px', '2xl': '85px' }}
              >
                <Box
                  width={{ base: '70%', md: '400px', lg: '75%', '2xl': '75%' }}
                  height='100%'
                >
                  <Input
                    id='email'
                    name='email'
                    type='text'
                    fontSize={{ base: '12px', md: '16px', lg: '18px', '2xl': '30px' }}
                    placeholder='Email'
                    backgroundColor={colors.white}
                    color={colors.black}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    height='100%'
                    border={`solid 1px ${colors.principal}`}
                    borderRadius='6px'
                    width={['100%']}
                    p={['5px 10px', '5px 10px']}
                  />
                </Box>
                <Box
                  width={{ base: '30%', md: '25%', lg: '25%', '2xl': '25%' }}
                  height='100%'
                >
                  <Button
                    type='submit'
                    sx={formSubscribeStyles.actionButton}
                    height='100%'
                    width='100%'
                    fontSize={{ base: '0.8rem', md: '1rem', lg: '1rem', '2xl': '1.7rem' }}
                  >
                    Suscribite
                  </Button>
                </Box>
              </HStack>
              {errors.email && touched.email ? (
                <Box margin='5px 0 0 25%'>
                  <Text color='red'
                    fontSize={{ base: '16px', md: '20px', lg: '20px', '2xl': '30px' }}
                  >
                    {errors.email}
                  </Text>
                </Box>
              ) : null}
            </FormControl>
          </VStack>
        </Form>
      )}
    </Formik >
  )
}
FormSubscribe.propTypes = {
  isInstit: PropTypes.bool.isRequired,
}
export default FormSubscribe
