import { favorites } from './_ducks'

export const FOLDERS_LIST_GET_REQUEST: string = favorites.defineType('FOLDERS_LIST_GET_REQUEST')
export const FOLDERS_LIST_GET_SUCCESS: string = favorites.defineType('FOLDERS_LIST_GET_SUCCESS')
export const FOLDERS_LIST_GET_FAILURE: string = favorites.defineType('FOLDERS_LIST_GET_FAILURE')
export const FOLDERS_LIST_GET_CLEAN: string = favorites.defineType('FOLDERS_LIST_GET_CLEAN')

export const ADD_FOLDER_POST_REQUEST: string = favorites.defineType('ADD_FOLDER_POST_REQUEST')
export const ADD_FOLDER_POST_SUCCESS: string = favorites.defineType('ADD_FOLDER_POST_SUCCESS')
export const ADD_FOLDER_POST_FAILURE: string = favorites.defineType('ADD_FOLDER_POST_FAILURE')
export const ADD_FOLDER_POST_CLEAN: string = favorites.defineType('ADD_FOLDER_POST_CLEAN')

export const RENAME_FOLDER_PUT_REQUEST: string = favorites.defineType('RENAME_FOLDER_PUT_REQUEST')
export const RENAME_FOLDER_PUT_SUCCESS: string = favorites.defineType('RENAME_FOLDER_PUT_SUCCESS')
export const RENAME_FOLDER_PUT_FAILURE: string = favorites.defineType('RENAME_FOLDER_PUT_FAILURE')
export const RENAME_FOLDER_PUT_CLEAN: string = favorites.defineType('RENAME_FOLDER_PUT_CLEAN')

export const FOLDER_DELETE_REQUEST: string = favorites.defineType('FOLDER_DELETE_REQUEST')
export const FOLDER_DELETE_SUCCESS: string = favorites.defineType('FOLDER_DELETE_SUCCESS')
export const FOLDER_DELETE_FAILURE: string = favorites.defineType('FOLDER_DELETE_FAILURE')
export const FOLDER_DELETE_CLEAN: string = favorites.defineType('FOLDER_DELETE_CLEAN')

export const FAVORITES_BY_FOLDER_GET_REQUEST: string = favorites.defineType(
  'FAVORITES_BY_FOLDER_GET_REQUEST',
)
export const FAVORITES_BY_FOLDER_GET_SUCCESS: string = favorites.defineType(
  'FAVORITES_BY_FOLDER_GET_SUCCESS',
)
export const FAVORITES_BY_FOLDER_GET_FAILURE: string = favorites.defineType(
  'FAVORITES_BY_FOLDER_GET_FAILURE',
)
export const FAVORITES_BY_FOLDER_GET_CLEAN: string = favorites.defineType(
  'FAVORITES_BY_FOLDER_GET_CLEAN',
)

export const FAVORITE_NOTE_PUT_REQUEST: string = favorites.defineType('FAVORITE_NOTE_PUT_REQUEST')
export const FAVORITE_NOTE_PUT_SUCCESS: string = favorites.defineType('FAVORITE_NOTE_PUT_SUCCESS')
export const FAVORITE_NOTE_PUT_FAILURE: string = favorites.defineType('FAVORITE_NOTE_PUT_FAILURE')
export const FAVORITE_NOTE_PUT_CLEAN: string = favorites.defineType('FAVORITE_NOTE_PUT_CLEAN')

export const FAVORITE_UPDATE_POST_REQUEST: string = favorites.defineType(
  'FAVORITE_UPDATE_POST_REQUEST',
)
export const FAVORITE_UPDATE_POST_SUCCESS: string = favorites.defineType(
  'FAVORITE_UPDATE_POST_SUCCESS',
)
export const FAVORITE_UPDATE_POST_FAILURE: string = favorites.defineType(
  'FAVORITE_UPDATE_POST_FAILURE',
)
export const FAVORITE_UPDATE_POST_CLEAN: string = favorites.defineType('FAVORITE_UPDATE_POST_CLEAN')
