import { FC, PropsWithChildren } from 'react'
import { Center, Card } from '@chakra-ui/react'

const LoginCard: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Center mt={6}>
      <Card maxW='sm' marginBottom={50} p={8} boxShadow={'lg'} borderRadius='lg'>
        {children}
      </Card>
    </Center>
  )
}

export default LoginCard
