import { useState } from 'react'
import { Box, VStack, Button, Text } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon, EditIcon } from '@chakra-ui/icons'
import PersonalDataResume from './recumecomponents/personaldata'
import EducationResume from './recumecomponents/education'
import JobsExperienceResume from './recumecomponents/jobsexperience'
import PreferenceResume from './recumecomponents/preferences'
import ModalResumeUser from './ModalResumeUser'

const InformationResume = () => {
  const [showPersonalData, setShowPersonalData] = useState(false)
  const [showEducation, setShowEducation] = useState(false)
  const [showJobsExperience, setShowJobsExperience] = useState(false)
  const [showPreferences, setShowPreferences] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<number>(2) // Inicialízalo en 0

  const handleOpenModal = (option: number) => {
    setSelectedOption(option)
    setIsOpen(true)
  }
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <Box width='100%' display={'flex'} flexDirection={'column'}>
      <ModalResumeUser
        isOpen={isOpen}
        onClose={handleCloseModal}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <VStack>
        <Box width={'100%'} p={7}>
          <Box display={'flex'} flexDirection={'row'}>
            <Button
              mt={4}
              colorScheme='white'
              width='90%'
              display='flex'
              justifyContent='space-between'
              onClick={() => {
                setShowPersonalData(!showPersonalData)
              }}
            >
              <Text color={'#004876'} fontWeight='bold'>
                Datos personales
              </Text>
              {showPersonalData ? (
                <ChevronUpIcon boxSize={8} color={'#004876'} fontWeight='bold' />
              ) : (
                <ChevronDownIcon boxSize={8} color={'#004876'} fontWeight='bold' />
              )}
            </Button>
            <Button
              mt={4}
              colorScheme='white'
              width='10%'
              display='flex'
              justifyContent='flex-end'
              onClick={() => handleOpenModal(2)}
            >
              <EditIcon boxSize={6} color={'#004876'} fontWeight='bold' />
            </Button>
          </Box>
          {showPersonalData && <PersonalDataResume />}
          <Box display={'flex'} flexDirection={'row'}>
            <Button
              mt={4}
              colorScheme='white'
              width='90%'
              display='flex'
              justifyContent='space-between'
              onClick={() => {
                setShowEducation(!showEducation)
              }}
            >
              <Text color={'#004876'} fontWeight='bold'>
                Educación
              </Text>
              {showEducation ? (
                <ChevronUpIcon boxSize={8} color={'#004876'} fontWeight='bold' />
              ) : (
                <ChevronDownIcon boxSize={8} color={'#004876'} fontWeight='bold' />
              )}
            </Button>
            <Button
              mt={4}
              colorScheme='white'
              width='10%'
              display='flex'
              justifyContent='flex-end'
              onClick={() => handleOpenModal(3)}
            >
              <EditIcon boxSize={6} color={'#004876'} fontWeight='bold' />
            </Button>
          </Box>
          {showEducation && <EducationResume />}
          <Box display={'flex'} flexDirection={'row'}>
            <Button
              mt={4}
              colorScheme='white'
              width='90%'
              display='flex'
              justifyContent='space-between'
              onClick={() => {
                setShowJobsExperience(!showJobsExperience)
              }}
            >
              <Text color={'#004876'} fontWeight='bold'>
                Experiencia laboral
              </Text>
              {showJobsExperience ? (
                <ChevronUpIcon boxSize={8} color={'#004876'} fontWeight='bold' />
              ) : (
                <ChevronDownIcon boxSize={8} color={'#004876'} fontWeight='bold' />
              )}
            </Button>
            <Button
              mt={4}
              colorScheme='white'
              width='10%'
              display='flex'
              justifyContent='flex-end'
              onClick={() => handleOpenModal(4)}
            >
              <EditIcon boxSize={6} color={'#004876'} fontWeight='bold' />
            </Button>
          </Box>
          {showJobsExperience && <JobsExperienceResume />}
          <Box display={'flex'} flexDirection={'row'}>
            <Button
              mt={4}
              colorScheme='white'
              width='90%'
              display='flex'
              justifyContent='space-between'
              onClick={() => {
                setShowPreferences(!showPreferences)
              }}
            >
              <Text color={'#004876'} fontWeight='bold'>
                Preferencias laborales
              </Text>
              {showPreferences ? (
                <ChevronUpIcon boxSize={8} color={'#004876'} fontWeight='bold' />
              ) : (
                <ChevronDownIcon boxSize={8} color={'#004876'} fontWeight='bold' />
              )}
            </Button>
            <Button
              mt={4}
              colorScheme='white'
              width='10%'
              display='flex'
              justifyContent='flex-end'
              onClick={() => handleOpenModal(5)}
            >
              <EditIcon boxSize={6} color={'#004876'} fontWeight='bold' />
            </Button>
          </Box>
          {showPreferences && <PreferenceResume />}
        </Box>
      </VStack>
    </Box>
  )
}

export default InformationResume
