import React from 'react'
import LandingLayout from '../../layouts/landing'
import { VStack } from '@chakra-ui/react'
import AdDetail from '../../components/AdDetail'

export const AdInfo: React.FC = () => {
  return (
    <LandingLayout>
      <VStack>
        <AdDetail />
      </VStack>
    </LandingLayout>
  )
}

export default AdInfo
