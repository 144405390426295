import { applications } from './_ducks'
import * as types from './types'

export const applicationsListRequest = applications.createAction(
  types.APPLICATIONS_LIST_POST_REQUEST,
)
export const applicationsListSuccess = applications.createAction(
  types.APPLICATIONS_LIST_POST_SUCCESS,
)
export const applicationsListFailure = applications.createAction(
  types.APPLICATIONS_LIST_POST_FAILURE,
)
export const applicationsListClean = applications.createAction(types.APPLICATIONS_LIST_POST_CLEAN)

export const handleApplicationsListRequest = applications.createAction(
  types.HANDLE_APPLICATIONS_LIST_POST_REQUEST,
)
export const handleApplicationsListSuccess = applications.createAction(
  types.HANDLE_APPLICATIONS_LIST_POST_SUCCESS,
)
export const handleApplicationsListFailure = applications.createAction(
  types.HANDLE_APPLICATIONS_LIST_POST_FAILURE,
)
export const handleApplicationsListClean = applications.createAction(
  types.HANDLE_APPLICATIONS_LIST_POST_CLEAN,
)

export const downloadApplicationsSheetRequest = applications.createAction(
  types.DOWNLOAD_APPLICATIONS_SHEET_POST_REQUEST,
)
export const downloadApplicationsSheetSuccess = applications.createAction(
  types.DOWNLOAD_APPLICATIONS_SHEET_POST_SUCCESS,
)
export const downloadApplicationsSheetFailure = applications.createAction(
  types.DOWNLOAD_APPLICATIONS_SHEET_POST_FAILURE,
)
export const downloadApplicationsSheetClean = applications.createAction(
  types.DOWNLOAD_APPLICATIONS_SHEET_POST_CLEAN,
)

export const deleteApplicationRequest = applications.createAction(types.APPLICATION_DELETE_REQUEST)
export const deleteApplicationSuccess = applications.createAction(types.APPLICATION_DELETE_SUCCESS)
export const deleteApplicationFailure = applications.createAction(types.APPLICATION_DELETE_FAILURE)
export const deleteApplicationClean = applications.createAction(types.APPLICATION_DELETE_CLEAN)
