import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Link as ChakraLink,
  Icon,
  Img,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { CheckIcon, EmailIcon, ViewIcon } from '@chakra-ui/icons'
import { Link, NavLink } from 'react-router-dom'
import React, { FC, useEffect, useState } from 'react'
import { caseSvg, locationSvg } from '../../../utils/general'
import { useDispatch, useSelector } from 'react-redux'

import { adCardProps } from './_types'
import { adCardStyles } from './styles'
import { colors } from '../../../utils/colors'
import institutionEmpty from '../../../assets/images/profile/InsitutionEmpty.png'
import { jobApplicationRequest } from '../../../state/modules/ads/actions'

const AdCard: FC<adCardProps> = ({ job }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const [application, setApplication] = useState<boolean>(false)
  const userId = useSelector((state: any) => state?.user?.userInformation?.id)

  const dispatch = useDispatch()
  const publicationDate = new Date(job?.publicationDate)
    .toISOString()
    .split('T')[0]
    .split('-')
    .reverse()
    .join('-')
  const handleApplication = () => {
    dispatch(jobApplicationRequest({ adId: job.id, token: localStorage.getItem('access_token') }))
  }

  useEffect(() => {
    if (userId && job?.JobApplications.length > 0) {
      const match = job?.JobApplications.find((e: any) => e.userId == userId)
      setApplication(match ? true : false)
    }
  }, [userId, job?.JobApplications])

  return (
    <Box sx={isMobile ? adCardStyles.containerMobile : adCardStyles.container}>
      <Box sx={isMobile ? adCardStyles.imageContainerMobile : adCardStyles.imageContainer}>
        <Img
          sx={isMobile ? adCardStyles.avatarMobile : adCardStyles.avatarImage}
          alt={job?.Institutions[0]?.name}
          src={
            job.showInstitutionName !== false
              ? job?.Institutions[0]?.UserFiles[0]?.filePath
                ? `${job?.Institutions[0]?.UserFiles[0]?.filePath}`
                : institutionEmpty
              : institutionEmpty
          }
        />
      </Box>
      <Box sx={isMobile ? adCardStyles.infoContainerMobile : adCardStyles.infoContainer}>
        <Text sx={adCardStyles.title}>{job.title}</Text>
        <Box sx={isMobile ? adCardStyles.mainDataContainerMobile : adCardStyles.mainDataContainer}>
          <Box
            sx={
              isMobile
                ? adCardStyles.dataItemMobile1
                : { ...adCardStyles.dataItem, display: 'flex', flexWrap: 'wrap', columnGap: '5px' }
            }
          >
            {job?.Institutions?.map((e: any, i: number) =>
              job.showInstitutionName !== false ? (
                <NavLink
                  style={{
                    textTransform: 'capitalize',
                    marginLeft: '5px',
                  }}
                  to={`/institution/detail/${e.id}`}
                  target='_blank'
                  key={e.id}
                >
                  <ChakraLink>
                    {job?.Institutions?.length > 1 && '*'}
                    {e.name}
                  </ChakraLink>
                </NavLink>
              ) : (
                <Text key={e.id} sx={adCardStyles.adData}>
                  {job?.Institutions?.length > 1 && '*'}
                  Institución educativa
                </Text>
              ),
            )}
          </Box>

          <Box
            sx={
              isMobile
                ? adCardStyles.dataItemMobile2
                : { ...adCardStyles.dataItem, display: 'flex', columnGap: '5px' }
            }
          >
            <Text sx={adCardStyles.adDataTitle}>Fecha de publicación:</Text>
            <Text sx={adCardStyles.adData}>{publicationDate}</Text>
          </Box>
          <Box sx={adCardStyles.locationContainer}>
            {job?.Zones?.map((e: any) => (
              <Box sx={isMobile ? adCardStyles.dataItemMobile2 : adCardStyles.dataItem} key={e.id}>
                <Text sx={adCardStyles.adData}>
                  <Icon
                    fill={colors.principal}
                    height='15px'
                    width='15px'
                    viewBox={locationSvg.viewBox}
                  >
                    <path d={locationSvg.d}></path>
                  </Icon>{' '}
                  {e?.Province?.description.toLowerCase()} -{' '}
                  {e?.Department?.description.toLowerCase()} -{' '}
                  {e?.Locality?.description.toLowerCase()}
                </Text>
              </Box>
            ))}
          </Box>
          <Box
            sx={
              isMobile
                ? adCardStyles.dataItemMobile2
                : { ...adCardStyles.dataItem, display: 'flex', columnGap: '5px' }
            }
          >
            <Icon width='20px' height='20px' viewBox={caseSvg.viewBox} fill='none'>
              <path
                d={caseSvg.d}
                stroke={colors.principal}
                strokeWidth={caseSvg.strokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </Icon>
            <Text sx={adCardStyles.adData}>{job?.Position?.description}</Text>
          </Box>
        </Box>
      </Box>
      <Box sx={isMobile ? adCardStyles.actionContainerMobile : adCardStyles.actionContainer}>
        <Link to={`/careers/career/${job.id}`} target='_blank'>
          <Button sx={{ width: '100%', ...adCardStyles.actionButton }}>Ver</Button>
        </Link>
        {!application ? (
          <Button onClick={handleApplication} sx={{ ...adCardStyles.actionButton }}>
            Postular
          </Button>
        ) : (
          <Text sx={isMobile ? adCardStyles.appliedMobile : adCardStyles.applied}>Postulado</Text>
        )}
      </Box>
    </Box>
  )
}

export default AdCard
