import { colors } from './../../../../../../utils/colors'
export const showDataTableStyles = {
  tHead: {
    textTransform: 'initial',
    fontSize: '11px',
    color: colors.principal,
    padding: '8px',
  },
  tData: { fontSize: '11px', padding: '8px' },
  languageData: {
    fontSize: '13px',
    border: '2px',
    borderColor: colors.principal,
    borderRadius: '8px',
    padding: '4px',
  },
  heading: { color: '#004876', fontWeight: 'bold', textTransform: 'uppercase', mb: 5 },
  table: { width: '90%', margin: '0 auto' },
  tableTitle: { fontSize: '14px', color: colors.principal, padding: '16px', fontWeight: 'bold' },
  noResults: {
    width: '80%',
    height: '15vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  languageDataContainerMobile: { display: 'flex', flexWrap: 'wrap' },
}
