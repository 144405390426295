import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'

import { colors } from '../../../../utils/colors'

interface FavoritesNotesProps {
  children: any
  id?: string
  savedNote?: string | null
  onDelete: () => void
  onSave: (note: string) => void
}

const FavoritesNote: FC<FavoritesNotesProps> = ({
  children,
  id = '1',
  savedNote,
  onDelete,
  onSave,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [note, setNote] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value)
  }

  useEffect(() => {
    isOpen && setNote('')
  }, [isOpen])
  return (
    <Popover placement='bottom' isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button p={0} size='xs' colorScheme='none' _hover={{ backgroundColor: 'none' }}>
          {children}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent width={'220px'}>
          <PopoverArrow />
          <PopoverCloseButton colorScheme='none' _hover={{ backgroundColor: 'none' }} />
          <PopoverHeader>Notas</PopoverHeader>
          <PopoverBody>
            <Textarea
              maxLength={50}
              disabled={savedNote ? true : false}
              onChange={handleChange}
              value={savedNote !== null ? savedNote : note}
              resize={'none'}
            />
            {savedNote === null && <Box>{note.length}/50</Box>}
          </PopoverBody>
          <PopoverFooter>
            {savedNote ? (
              <Button
                colorScheme='none'
                size={'xs'}
                backgroundColor={colors.principal}
                onClick={() => {
                  setNote('')
                  onDelete()
                  onClose()
                }}
              >
                Eliminar
              </Button>
            ) : (
              <Button
                colorScheme='none'
                size={'xs'}
                backgroundColor={colors.principal}
                onClick={() => {
                  onSave(note)
                  onClose()
                }}
              >
                Guardar
              </Button>
            )}
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default FavoritesNote
