import * as Yup from 'yup'

export default Yup.object().shape({
  password: Yup.string()
  .matches(/^(?=.*[A-Za-z]{2,})(?=.*\d{2,})[A-Za-z\d]{14,}$/, {
    message:
      'Debe incluir al menos 2 letras, 2 números y longitud minima de 14 caracteres',
  })
  .required('Contraseña requerida'),
  rePassword: Yup.string().required('Repetir contraseña requerida'),
})
