import './style.css'

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  ChevronDownIcon,
  CloseIcon,
  DeleteIcon,
  HamburgerIcon,
  SettingsIcon,
} from '@chakra-ui/icons'
import { FC, useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  userInformationCleanRequest,
  userInformationRequest,
} from '../../state/modules/user/actions'

import AIEPBAImage from '../../assets/images/landing/logo.png'
import ActionPopover from '../actionPopover'
import InvitesHandler from '../InvitesHandler'
import { JwtData } from '../../state/modules/login/services/types'
import NotificationsModal from '../NotificationsModal'
import { State } from '../../state/types'
import ViewRequestsModal from '../ViewRequestsModal'
import { adLinks } from '../../utils/jobAds'
import { candidatesLinks } from '../../utils/candidates'
import { colors } from '../../utils/colors'
import { getRole } from '../../utils/profile'
import { headerStyles } from './styles'
import jwtDecode from 'jwt-decode'
import { loginClean } from '../../state/modules/login/actions'

export const Links = [
  { path: '/', label: 'home', viewLabel: 'INICIO' },
  { path: '/institutional-landing', label: 'institutions', viewLabel: 'INSTITUCIONES' },
  { path: '/login', label: 'log_in', viewLabel: 'INICIAR SESIÓN' },
]
export const LinksLogged = [
  { path: '/home', viewLabel: 'Avisos' },
  { path: '/profile/user', viewLabel: 'Mi Perfil' }
]

export const Buttons = {
  LOG_IN: 'Iniciar sesión',
  CREATE_ACCOUNT: 'Crear cuenta',
  SIGN_OUT: 'Cerrar sesión',
  LANDING_ACTION_BUTTON: 'Encontrá tu trabajo ideal',
  KNOW_MORE: 'Conocé más...',
  PUBLISH_YOUR_AD: 'Publicá tu aviso',
  ACCESS_AND_PUBLISH_AD: 'Accedé y publicá tu aviso',
}

export const InitialLinks = [
  { path: '/home', viewLabel: 'Avisos' },
  { path: '/user/profile', viewLabel: 'Mi Perfil' }
]

const Header: FC = () => {
  const [isLogged, setIsLogged] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [AuthLinks, setAuthLinks] = useState(InitialLinks)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userInformation } = useSelector((state: State) => state.user)
  const [showRequests, setShowRequests] = useState(false)
  const [showNotificationsHandler, setShowNotificationsHandler] = useState(false)
  const [showInvitesHandler, setShowInvitesHandler] = useState(false)
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const location = useLocation()

  let institutionOptions = []
  if (userInformation && userInformation?.Institutions?.length > 0) {
    institutionOptions = userInformation?.Institutions?.map((institution: any) => ({
      value: institution.id,
      label: institution.name,
    }))
  }

  const { isAIEPBA, isAdmin, isViewer } = getRole(
    userInformation?.Institutions,
    userInformation?.Role?.id,
  )

  useEffect(() => {
    if (userInformation !== undefined && userInformation !== null) {
      setAuthLinks([
        { path: '/home', viewLabel: 'Avisos' },
        { path: '/profile/user', viewLabel: 'Mi Perfil' }
      ])

    } else {
      const session = localStorage.getItem('access_token')
      const sessionComenius = localStorage.getItem('access_token_comenius')
      if (session !== null && sessionComenius === null) {
        const jwtTokenData: JwtData = jwtDecode(session)
        dispatch(userInformationRequest({ userId: jwtTokenData.id.toString() }))
      }
    }
  }, [userInformation])

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])

  useEffect(() => {
    if (isLogged) {
      if (userInformation === undefined || userInformation === null) {
        handleLogout()
      }
    }
  }, [userInformation]) // aqui para desloggearme

  const handleChangePassword = () => {
    navigate('/passwordprofile')
  }

  const handleInitSession = () => {
    navigate('/login')
    window.location.reload()
  }

  const handleABM = () => {
    navigate('/profile/admin/abm')
  }

  const handleLogout = () => {
    dispatch(userInformationCleanRequest())
    setAuthLinks(InitialLinks)
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    setIsLogged(false)
    dispatch(loginClean())
  }

  const handleClosePopover = (id: string) => {
    const button = document.getElementById(`PopoverCloseButton${id}`)
    button?.click()
  }

  const mostrarIdyLabel = (id: string, label: string) => {
    navigate(`/profile/institution/${id}`)
  }

  return (
    <Box bg={colors.white} px={4} py={3} boxShadow={'md'}>
      <ViewRequestsModal isOpen={showRequests} onClose={() => setShowRequests(false)} />
      <NotificationsModal
        isOpen={showNotificationsHandler}
        onClose={() => setShowNotificationsHandler(false)}
      />
      <InvitesHandler isOpen={showInvitesHandler} onClose={() => setShowInvitesHandler(false)} />
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <IconButton
          size={'md'}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={''} alignItems={'center'}>
          <Box p={['0 0 0 10px', '0 0 0 10px', '0 0 0 5px', '0 0 0 10px']}>
            <Link as={RouterLink} to='/'>
              <Box w={['90px', '180px', '85px', '115px']}>
                <Image src={AIEPBAImage} alt='AIEPBA' w='100%' />
              </Box>
            </Link>
          </Box>
          <HStack as={'nav'}
            ml={['5px', '10px', '8px', '24px']}
            display={'flex'}
            width={'100%'}>
            {!isMobile &&
              (isLogged
                ? AuthLinks.map((link) => (
                  <Link
                    key={link.viewLabel}
                    as={RouterLink}
                    to={link.viewLabel == 'Mi Perfil' ? '/profile/user' : link.viewLabel == 'Avisos' ? '/home' : '/'}
                  >
                    <Text
                      sx={headerStyles.customLink}
                      color={(location.pathname === link.path) ? colors.institutionalRed : '#004876'}
                      fontSize={['14px', '11px', '12px', '14px']}
                      textAlign={['unset', 'unset', 'center', 'unset']}
                      mr={['0', '0', '0', '20px']}
                    >
                      {link.viewLabel}
                    </Text>
                  </Link>
                ))
                : (isLogged ? Links : Links.filter(link => link.label !== 'log_in')).map((link) => {

                  return (
                    <RouterLink key={link.path} to={link.path}>
                      <Text
                        id='navLink'
                        fontSize={'sm'}
                        color={(location.pathname === link.path) ? colors.institutionalRed : '#004876'}
                      >
                        {link.viewLabel}
                      </Text>
                    </RouterLink>
                  )
                }))}
            {!isMobile && isLogged && isViewer && (
              <Menu>
                <MenuButton
                  sx={headerStyles.customLink}
                  fontSize={['14px', '11px', '12px', '14px']}
                  color={colors.principal}
                >
                  <Text
                    mr={['0', '0', '0', '20px']}
                    color={institutionOptions.some((option: any) => `/profile/institution/${option.value}` === location.pathname) ? colors.institutionalRed : '#004876'}
                  >
                    Mis instituciones <Icon boxSize={'20px'} as={ChevronDownIcon} />
                  </Text>

                </MenuButton>
                <MenuList sx={headerStyles.customSubLink}>
                  {institutionOptions.map((option: any) => (
                    <MenuItem
                      sx={headerStyles.customSubLink}
                      key={option.value}
                      as={RouterLink}
                      color={`/profile/institution/${option.value}` === location.pathname ? colors.institutionalRed : '#004876'}
                      to={`/profile/institution/${option.value}`}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
            {!isMobile && isLogged && (isViewer || isAIEPBA) && (
              <Menu>
                <MenuButton
                  sx={headerStyles.customLink}
                  fontSize={['14px', '11px', '12px', '14px']}
                  color={colors.principal}
                >
                  <Text
                    mr={['0', '0', '0', '20px']}
                    color={adLinks.some(link => link.route === location.pathname) ? colors.institutionalRed : '#004876'}
                  >
                    Mis avisos <Icon boxSize={'20px'} as={ChevronDownIcon} />
                  </Text>
                </MenuButton>
                <MenuList sx={headerStyles.customSubLink}>
                  {adLinks.map((link, i, arrLinks) => {
                    const isShowed = isAIEPBA
                      ? true
                      : (link.id === '1' && isAdmin) ||
                        (link.id === '2' && isViewer) ||
                        (link.id === '3' && isViewer)
                        ? true
                        : false

                    return (
                      <MenuItem
                        display={isShowed ? 'block' : 'none'}
                        key={link.label}
                        sx={headerStyles.customSubLink}
                        as={RouterLink}
                        to={link.route}
                        color={link.route === location.pathname ? colors.institutionalRed : '#004876'}
                      >
                        {link.label}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            )}
            {!isMobile && isLogged && (isViewer || isAIEPBA) && (
              <Menu>
                <MenuButton
                  sx={headerStyles.customLink}
                  fontSize={['14px', '11px', '12px', '14px']}
                  color={candidatesLinks.some(link => link.route === location.pathname) ? colors.institutionalRed : '#004876'}
                >
                  <Text
                    mr={['0', '0', '0', '20px']}
                  >
                    Mis búsquedas <Icon boxSize={'20px'} as={ChevronDownIcon} />
                  </Text>
                </MenuButton>
                <MenuList sx={headerStyles.customSubLink}>
                  {candidatesLinks.map((link) => {
                    const isShowed = isAIEPBA
                      ? true
                      : (link.id === '1' && isViewer) || (link.id === '2' && isViewer)
                        ? true
                        : false

                    return (
                      <MenuItem
                        display={isShowed ? 'block' : 'none'}
                        key={link.id}
                        sx={headerStyles.customSubLink}
                        as={RouterLink}
                        to={link.route}
                        color={link.route === location.pathname ? colors.institutionalRed : '#004876'}
                      >
                        {link.label}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            )}
            {!isMobile && isLogged && (
              <RouterLink to='/careers/job-applications'>
                <Text
                  sx={headerStyles.customLink}
                  fontSize={['14px', '11px', '12px', '14px']}
                  color={(location.pathname === '/careers/job-applications') ? colors.institutionalRed : '#004876'}
                  textAlign={['unset', 'unset', 'center', 'unset']}
                >
                  Mis postulaciones
                </Text>
              </RouterLink>
            )}
          </HStack>
        </HStack>
        {isLogged ? (
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            ml={['5px', '10px', '10px', '20px']}
          >
            <Center>
              <ActionPopover
                title='¿Está seguro que quiere salir?'
                id='logout'
                handleAction={handleLogout}
                handleClose={handleClosePopover}
                buttonSize='12px'
              >
                <Button
                  onClick={() => console.log('first')}
                  sx={headerStyles.actionButton}
                  p={['14px', '14px', '8px', '14px']}
                  _hover={{ bg: '#fff' }}
                  mr={['18px', '18px', '5px', '18px']}
                >
                  {Buttons.SIGN_OUT}
                </Button>
              </ActionPopover>
            </Center>
            <Menu>
              <MenuButton
                as={IconButton}
                sx={headerStyles.gearButton}
                aria-label='Settings'
                icon={<SettingsIcon />}
                _hover={{ bg: colors.institutionalRed }}
              />
              <MenuList>
                {isAIEPBA && <MenuItem onClick={handleABM}>ABM</MenuItem>}
                <MenuItem onClick={() => setShowRequests(true)}>Gestionar solicitudes</MenuItem>
                <MenuItem onClick={() => setShowNotificationsHandler(true)}>
                  Gestionar notificaciones
                </MenuItem>
                <MenuItem onClick={() => setShowInvitesHandler(true)}>Invitaciones</MenuItem>
                <MenuItem onClick={handleChangePassword}>Cambiar contraseña</MenuItem>
                <MenuItem onClick={handleLogout}>Salir</MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        ) : (
          <Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={6}>
            <Center>
              <Button
                display={{ base: 'none', md: 'inline-flex' }}
                sx={headerStyles.navbarButton.login}
                onClick={handleInitSession}
                style={{ borderWidth: '1px' }}
              >
                <Text
                  fontSize={'sm'}
                  fontWeight={400}
                >
                  {Buttons.LOG_IN}
                </Text>
              </Button>
            </Center>
            <Link as={RouterLink} to='/register'>
              <Button
                display={{ base: 'none', md: 'inline-flex' }}
                sx={headerStyles.navbarButton.createAccount}
              >
                <Text fontSize={'sm'} fontWeight={400}>
                  {Buttons.CREATE_ACCOUNT}
                </Text>
              </Button>
            </Link>
          </Stack>
        )}
      </Flex>
      {isMobile && isOpen && isLogged ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {LinksLogged.map((link) => (
              <Link
                key={link.path}
                as={RouterLink}
                to={link.path}
                color={link.path === location.pathname ? colors.institutionalRed : '#004876'}
              >
                <Text sx={headerStyles.customLink}>
                  {link.viewLabel}
                </Text>
              </Link>
            ))}
            {isViewer && (
              <Menu>
                <MenuButton
                  textAlign={'left'}
                  sx={headerStyles.customLink}
                  color={institutionOptions.some((option: any) => `/profile/institution/${option.value}` === location.pathname) ? colors.institutionalRed : '#004876'}
                >
                  Mis instituciones <Icon boxSize={'20px'} as={ChevronDownIcon} />
                </MenuButton>
                <MenuList sx={headerStyles.menu}>
                  {institutionOptions.map((option: any) => (
                    <MenuItem
                      sx={headerStyles.menuItem}
                      key={option.value}
                      as={RouterLink}
                      color={`/profile/institution/${option.value}` === location.pathname ? colors.institutionalRed : '#004876'}
                      to={`/profile/institution/${option.value}`}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
            {(isViewer || isAIEPBA) && (
              <Menu>
                <MenuButton
                  textAlign={'left'}
                  sx={headerStyles.customLink}
                  color={adLinks.some(link => link.route === location.pathname) ? colors.institutionalRed : '#004876'}
                >
                  Mis avisos <Icon boxSize={'20px'} as={ChevronDownIcon} />
                </MenuButton>
                <MenuList sx={headerStyles.menu}>
                  {adLinks.map((link) => {
                    const isShowed = isAIEPBA
                      ? true
                      : (link.id === '1' && isAdmin) ||
                        (link.id === '2' && isViewer) ||
                        (link.id === '3' && isViewer)
                        ? true
                        : false

                    return (
                      <MenuItem
                        display={isShowed ? 'block' : 'none'}
                        key={link.id}
                        sx={headerStyles.menuItem}
                        as={RouterLink}
                        to={link.route}
                        color={link.route === location.pathname ? colors.institutionalRed : '#004876'}
                      >
                        {link.label}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            )}
            {(isViewer || isAIEPBA) && (
              <Menu>
                <MenuButton
                  textAlign={'left'}
                  sx={headerStyles.customLink}
                  color={candidatesLinks.some(link => link.route === location.pathname) ? colors.institutionalRed : '#004876'}
                >
                  Mis búsquedas <Icon boxSize={'20px'} as={ChevronDownIcon} />
                </MenuButton>
                <MenuList sx={headerStyles.customSubLink}>
                  {candidatesLinks.map((link) => {
                    const isShowed = isAIEPBA
                      ? true
                      : (link.id === '1' && isAdmin) || (link.id === '2' && isViewer)
                        ? true
                        : false

                    return (
                      <MenuItem
                        display={isShowed ? 'block' : 'none'}
                        key={link.id}
                        sx={headerStyles.customSubLink}
                        as={RouterLink}
                        to={link.route}
                        color={link.route === location.pathname ? colors.institutionalRed : '#004876'}
                      >
                        {link.label}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            )}
            {isLogged && (
              <RouterLink to='/careers/job-applications'>
                <Text
                  sx={headerStyles.customLink}
                  color={(location.pathname === '/careers/job-applications') ? colors.institutionalRed : '#004876'}
                >
                  Mis postulaciones
                </Text>
              </RouterLink>
            )}
          </Stack>
        </Box>
      ) : (
        isOpen &&
        !isLogged && (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={5}>
              {Links.map((link, i) => {
                const navigation =
                  link.label === 'home'
                    ? '/'
                    : link.path === 'AIEPBA'
                      ? 'http://www.aiepba.org/'
                      : link.path === 'Comenius'
                        ? 'http://www.aiepba.org/comenius'
                        : '/'
                const target = link.path === 'AIEPBA' || link.path === 'Comenius' ? '_blank' : '_self'

                return link.label === 'Contacto' ? (
                  <a href='/#contactUs'>
                    <Text fontSize={'sm'} color={colors.brand.blue.default}>
                      {link.viewLabel}
                    </Text>
                  </a>
                ) : (<RouterLink key={link.path} to={link.path} target={target}>
                  <Text fontSize={'sm'}
                    color={link.path === location.pathname ? colors.institutionalRed : colors.brand.blue.default}
                  >
                    {link.viewLabel}
                  </Text>
                </RouterLink>
                )
              })}
              <Link as={RouterLink} to='/register'>
                <Text
                  sx={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    backgroundColor: colors.principal,
                    color: '#fff',
                    textAlign: 'center',
                    padding: '10px',
                    borderRadius: '6px',
                    border: 'none',
                    width: '100%',
                  }}
                >
                  {Buttons.CREATE_ACCOUNT}
                </Text>
              </Link>
            </Stack>
          </Box>
        )
      )}
    </Box>
  )
}

export default Header
