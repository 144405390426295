import { abm } from './_ducks'

export const ROLES_GET_REQUEST: string = abm.defineType('ROLES_GET_REQUEST')
export const ROLES_GET_SUCCESS: string = abm.defineType('ROLES_GET_SUCCESS')
export const ROLES_GET_FAILURE: string = abm.defineType('ROLES_GET_FAILURE')
export const ROLES_GET_CLEAN: string = abm.defineType('ROLES_GET_CLEAN')

export const USERS_POST_REQUEST: string = abm.defineType('USERS_POST_REQUEST')
export const USERS_POST_SUCCESS: string = abm.defineType('USERS_POST_SUCCESS')
export const USERS_POST_FAILURE: string = abm.defineType('USERS_POST_FAILURE')
export const USERS_POST_CLEAN: string = abm.defineType('USERS_POST_CLEAN')

export const NEW_USER_POST_REQUEST: string = abm.defineType('NEW_USER_POST_REQUEST')
export const NEW_USER_POST_SUCCESS: string = abm.defineType('NEW_USER_POST_SUCCESS')
export const NEW_USER_POST_FAILURE: string = abm.defineType('NEW_USER_POST_FAILURE')
export const NEW_USER_POST_CLEAN: string = abm.defineType('NEW_USER_POST_CLEAN')

export const USER_DELETE_REQUEST: string = abm.defineType('USER_DELETE_REQUEST')
export const USER_DELETE_SUCCESS: string = abm.defineType('USER_DELETE_SUCCESS')
export const USER_DELETE_FAILURE: string = abm.defineType('USER_DELETE_FAILURE')
export const USER_DELETE_CLEAN: string = abm.defineType('USER_DELETE_CLEAN')

export const USER_EDIT_REQUEST: string = abm.defineType('USER_EDIT_REQUEST')
export const USER_EDIT_SUCCESS: string = abm.defineType('USER_EDIT_SUCCESS')
export const USER_EDIT_FAILURE: string = abm.defineType('USER_EDIT_FAILURE')
export const USER_EDIT_CLEAN: string = abm.defineType('USER_EDIT_CLEAN')

export const USER_ACTIVATE_REQUEST: string = abm.defineType('USER_ACTIVATE_REQUEST')
export const USER_ACTIVATE_SUCCESS: string = abm.defineType('USER_ACTIVATE_SUCCESS')
export const USER_ACTIVATE_FAILURE: string = abm.defineType('USER_ACTIVATE_FAILURE')
export const USER_ACTIVATE_CLEAN: string = abm.defineType('USER_ACTIVATE_CLEAN')

export const REQUISITIONS_GET_REQUEST: string = abm.defineType('REQUISITIONS_GET_REQUEST')
export const REQUISITIONS_GET_SUCCESS: string = abm.defineType('REQUISITIONS_GET_SUCCESS')
export const REQUISITIONS_GET_FAILURE: string = abm.defineType('REQUISITIONS_GET_FAILURE')
export const REQUISITIONS_GET_CLEAN: string = abm.defineType('REQUISITIONS_GET_CLEAN')

export const REQUISITION_HANDLE_PUT_REQUEST: string = abm.defineType(
  'REQUISITION_HANDLE_PUT_REQUEST',
)
export const REQUISITION_HANDLE_PUT_SUCCESS: string = abm.defineType(
  'REQUISITION_HANDLE_PUT_SUCCESS',
)
export const REQUISITION_HANDLE_PUT_FAILURE: string = abm.defineType(
  'REQUISITION_HANDLE_PUT_FAILURE',
)
export const REQUISITION_HANDLE_PUT_CLEAN: string = abm.defineType('REQUISITION_HANDLE_PUT_CLEAN')

export const INSTITUTIONS_POST_REQUEST: string = abm.defineType('INSTITUTIONS_POST_REQUEST')
export const INSTITUTIONS_POST_SUCCESS: string = abm.defineType('INSTITUTIONS_POST_SUCCESS')
export const INSTITUTIONS_POST_FAILURE: string = abm.defineType('INSTITUTIONS_POST_FAILURE')
export const INSTITUTIONS_POST_CLEAN: string = abm.defineType('INSTITUTIONS_POST_CLEAN')

export const NEW_INSTITUTION_POST_REQUEST: string = abm.defineType('NEW_INSTITUTION_POST_REQUEST')
export const NEW_INSTITUTION_POST_SUCCESS: string = abm.defineType('NEW_INSTITUTION_POST_SUCCESS')
export const NEW_INSTITUTION_POST_FAILURE: string = abm.defineType('NEW_INSTITUTION_POST_FAILURE')
export const NEW_INSTITUTION_POST_CLEAN: string = abm.defineType('NEW_INSTITUTION_POST_CLEAN')

export const FIND_USERS_GET_REQUEST: string = abm.defineType('FIND_USERS_GET_REQUEST')
export const FIND_USERS_GET_SUCCESS: string = abm.defineType('FIND_USERS_GET_SUCCESS')
export const FIND_USERS_GET_FAILURE: string = abm.defineType('FIND_USERS_GET_FAILURE')
export const FIND_USERS_GET_CLEAN: string = abm.defineType('FIND_USERS_GET_CLEAN')

export const INSTITUTION_ASSOCIATIONS_GET_REQUEST: string = abm.defineType(
  'INSTITUTION_ASSOCIATIONS_GET_REQUEST',
)
export const INSTITUTION_ASSOCIATIONS_GET_SUCCESS: string = abm.defineType(
  'INSTITUTION_ASSOCIATIONS_GET_SUCCESS',
)
export const INSTITUTION_ASSOCIATIONS_GET_FAILURE: string = abm.defineType(
  'INSTITUTION_ASSOCIATIONS_GET_FAILURE',
)
export const INSTITUTION_ASSOCIATIONS_GET_CLEAN: string = abm.defineType(
  'INSTITUTION_ASSOCIATIONS_GET_CLEAN',
)

export const NEW_USER_ASSOCIATION_POST_REQUEST: string = abm.defineType(
  'NEW_USER_ASSOCIATION_POST_REQUEST',
)
export const NEW_USER_ASSOCIATION_POST_SUCCESS: string = abm.defineType(
  'NEW_USER_ASSOCIATION_POST_SUCCESS',
)
export const NEW_USER_ASSOCIATION_POST_FAILURE: string = abm.defineType(
  'NEW_USER_ASSOCIATION_POST_FAILURE',
)
export const NEW_USER_ASSOCIATION_POST_CLEAN: string = abm.defineType(
  'NEW_USER_ASSOCIATION_POST_CLEAN',
)

export const USER_ASSOCIATION_DELETE_REQUEST: string = abm.defineType(
  'USER_ASSOCIATION_DELETE_REQUEST',
)
export const USER_ASSOCIATION_DELETE_SUCCESS: string = abm.defineType(
  'USER_ASSOCIATION_DELETE_SUCCESS',
)
export const USER_ASSOCIATION_DELETE_FAILURE: string = abm.defineType(
  'USER_ASSOCIATION_DELETE_FAILURE',
)
export const USER_ASSOCIATION_DELETE_CLEAN: string = abm.defineType('USER_ASSOCIATION_DELETE_CLEAN')

export const EDIT_USER_ASSOCIATION_POST_REQUEST: string = abm.defineType(
  'EDIT_USER_ASSOCIATION_POST_REQUEST',
)
export const EDIT_USER_ASSOCIATION_POST_SUCCESS: string = abm.defineType(
  'EDIT_USER_ASSOCIATION_POST_SUCCESS',
)
export const EDIT_USER_ASSOCIATION_POST_FAILURE: string = abm.defineType(
  'EDIT_USER_ASSOCIATION_POST_FAILURE',
)
export const EDIT_USER_ASSOCIATION_POST_CLEAN: string = abm.defineType(
  'EDIT_USER_ASSOCIATION_POST_CLEAN',
)

export const USER_DELETE_FROM_DB_REQUEST: string = abm.defineType('USER_DELETE_FROM_DB_REQUEST')
export const USER_DELETE_FROM_DB_SUCCESS: string = abm.defineType('USER_DELETE_FROM_DB_SUCCESS')
export const USER_DELETE_FROM_DB_FAILURE: string = abm.defineType('USER_DELETE_FROM_DB_FAILURE')
export const USER_DELETE_FROM_DB_CLEAN: string = abm.defineType('USER_DELETE_FROM_DB_CLEAN')

export const VERIFICATION_EMAIL_REQUEST: string = abm.defineType('VERIFICATION_EMAIL_REQUEST')
export const VERIFICATION_EMAIL_SUCCESS: string = abm.defineType('VERIFICATION_EMAIL_SUCCESS')
export const VERIFICATION_EMAIL_FAILURE: string = abm.defineType('VERIFICATION_EMAIL_FAILURE')
export const VERIFICATION_EMAIL_CLEAN: string = abm.defineType('VERIFICATION_EMAIL_CLEAN')

export const UPDATE_INSTITUTION_DATA_REQUEST: string = abm.defineType(
  'UPDATE_INSTITUTION_DATA_REQUEST',
)
export const UPDATE_INSTITUTION_DATA_SUCCESS: string = abm.defineType(
  'UPDATE_INSTITUTION_DATA_SUCCESS',
)
export const UPDATE_INSTITUTION_DATA_FAILURE: string = abm.defineType(
  'UPDATE_INSTITUTION_DATA_FAILURE',
)
export const UPDATE_INSTITUTION_DATA_CLEAN: string = abm.defineType('UPDATE_INSTITUTION_DATA_CLEAN')

export const DOWNLOAD_USER_SHEET_POST_REQUEST: string = abm.defineType(
  'DOWNLOAD_USER_SHEET_POST_REQUEST',
)
export const DOWNLOAD_USER_SHEET_POST_SUCCESS: string = abm.defineType(
  'DOWNLOAD_USER_SHEET_POST_SUCCESS',
)
export const DOWNLOAD_USER_SHEET_POST_FAILURE: string = abm.defineType(
  'DOWNLOAD_USER_SHEET_POST_FAILURE',
)
export const DOWNLOAD_USER_SHEET_POST_CLEAN: string = abm.defineType(
  'DOWNLOAD_USER_SHEET_POST_CLEAN',
)
