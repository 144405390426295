import './style.css'

import {
  AcademicRulesActions,
  AcademicRulesAmbos,
  AcademicRulesDocente,
  AcademicRulesNoDocente,
  LanguageRulesActions,
} from './_types'
import {
  Box,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Stack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  educationDeleteClean,
  educationDeleteRequest,
  educationEditClean,
  educationEditRequest,
  languageDeleteClean,
  languageDeleteRequest,
  userInformationCleanRequest,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import { JwtData } from '../../../../state/modules/login/services/types'
import Loader from '../../../loader'
import { colors } from '../../../../utils/colors'
import { editEducationFormStyles } from './styles'
import { getYears } from '../../../../utils/profile'
import jwtDecode from 'jwt-decode'

const toastMessages: UseToastOptions[] = [
  {
    description: 'Datos eliminados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const toastMessagesUpdate: UseToastOptions[] = [
  {
    description: 'Datos actulizados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const EducationEdit = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const userInformation = useSelector((state: any) => state.user?.userInformation)
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [isCurrentEdited, setIsCurrentEdited] = useState<any>(null)
  const [fromDateYears, setFromDateYears] = useState<any[]>([])

  const { educationSuccess, deleteEducationSuccess, editEducationSuccess, deleteLanguageSuccess } =
    useSelector((state: any) => state?.user)

  const [docente, setDocente] = useState<AcademicRulesDocente[]>([])
  const [nodocente, setNodocente] = useState<AcademicRulesNoDocente[]>([])
  const [courses, setCourses] = useState<any[]>([])
  const [languages, setLanguages] = useState<any[]>([])
  const [isEditing, setIsEditing] = useState(false)
  const [editingItem, setEditingItem] = useState<AcademicRulesActions | null>(null)

  const currentYear = new Date().getFullYear()
  const years = getYears()
  const limit = years.findIndex((year: number) => year === currentYear) + 1
  const startYears = years.slice(0, limit)

  const [editedValues, setEditedValues] = useState<AcademicRulesActions>({
    token: '',
    id: '',
    userId: '',
    type: '',
    fromDate: '',
    toDate: '',
    schoolToDate: '',
    schoolFromDate: '',
    teacherTitle: '',
    title: '',
    institution: '',
    percentage: '',
  })

  const loadingStyles = {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  useEffect(() => {
    if (deleteEducationSuccess !== null && deleteEducationSuccess !== undefined) {
      toast(toastMessages[deleteEducationSuccess ? 0 : 1])
      dispatch(educationDeleteClean())
    }
  }, [deleteEducationSuccess])

  useEffect(() => {
    if (deleteLanguageSuccess !== null && deleteLanguageSuccess !== undefined) {
      toast(toastMessages[deleteLanguageSuccess ? 0 : 1])
      dispatch(languageDeleteClean())
    }
  }, [deleteLanguageSuccess])

  useEffect(() => {
    if (editEducationSuccess !== null && editEducationSuccess !== undefined) {
      toast(toastMessagesUpdate[editEducationSuccess ? 0 : 1])
      dispatch(educationEditClean())
    }
  }, [editEducationSuccess])

  useEffect(() => {
    if (
      editEducationSuccess ||
      deleteEducationSuccess ||
      deleteLanguageSuccess ||
      educationSuccess
    ) {
      const session = localStorage.getItem('access_token')
      const sessionComenius = localStorage.getItem('access_token_comenius')
      if (session !== null && sessionComenius === null) {
        const jwtTokenData: JwtData = jwtDecode(session)
        dispatch(userInformationRequest({ userId: jwtTokenData.id.toString() }))
      }
    }
  }, [editEducationSuccess, deleteEducationSuccess, deleteLanguageSuccess, educationSuccess])

  useEffect(() => {
    if (userInformation !== undefined && userInformation !== null && userInformation !== '') {
      setearDatos()
    }
  }, [userInformation])

  const setearDatos = () => {
    const docenteTitles = userInformation?.Titles.filter((title: any) => title.type === 'docente')
    const nodocenteTitles = userInformation?.Titles.filter(
      (title: any) => title.type === 'nodocente',
    )

    const userCourses = userInformation?.Titles.filter((title: any) => title.type === 'curso')

    setDocente(docenteTitles)
    setNodocente(nodocenteTitles)
    setCourses(userCourses)
    setLanguages(userInformation?.Languages)
  }

  const editEducation = (values: any) => {
    setIsEditing(true)
    const token = localStorage.getItem('access_token') ?? ''
    const updatedInitialValuesForm: AcademicRulesActions = {
      token: token,
      id: values.id,
      userId: values.userId,
      type: values.type,
      fromDate: values.fromDate,
      toDate: values.toDate,
      schoolToDate: values.schoolToDate,
      schoolFromDate: values.schoolFromDate,
      teacherTitle: values.teacherTitle,
      institution: values.institution,
      title: values.title,
      percentage: values.percentage,
      teacherGraduated: values.teacherGraduated,
    }
    const fromYear = values.fromDate || values.schoolFromDate

    const fromDateLimit = startYears.findIndex((year: number) => year == fromYear)
    const yearsArray = startYears.slice(fromDateLimit)
    setFromDateYears(yearsArray)
    setEditingItem(updatedInitialValuesForm)
    setEditedValues(updatedInitialValuesForm)
  }

  const saveEducation = () => {
    setIsEditing(false)
    dispatch(educationEditRequest(editedValues))
    setEditingItem(null)
  }
  const deleteEducation = (docente: any) => {
    const token = localStorage.getItem('access_token') ?? ''
    const updatedInitialValuesForm: AcademicRulesActions = {
      token: token,
      id: docente.id,
      userId: docente.userId,
      type: docente.type,
      fromDate: docente.fromDate,
      toDate: docente.toDate,
      schoolToDate: docente.schoolToDate,
      schoolFromDate: docente.schoolToDate,
      teacherTitle: docente.teacherTitle,
      institution: docente.institution,
      title: docente.title,
      percentage: docente.percentage,
    }
    dispatch(educationDeleteRequest(updatedInitialValuesForm))
  }
  const deleteLanguage = (id: string) => {
    const token = localStorage.getItem('access_token') ?? ''

    const updatedInitialValuesForm = {
      token: token,
      userId: userInformation.Profile.userId,
      id,
    }

    dispatch(languageDeleteRequest(updatedInitialValuesForm))
  }

  return (
    <>
      {!userInformation ? (
        <Loader props={loadingStyles} />
      ) : (docente.length | nodocente.length | courses.length | languages.length) !== 0 ? (
        <Box sx={editEducationFormStyles.tablesContainer}>
          {docente.length !== 0 && (
            <Heading size='xs' color={'#004876'} fontWeight='bold' textTransform='uppercase' mt={5}>
              Docente
            </Heading>
          )}
          {docente.length !== 0 && (
            <TableContainer>
              <Table colorScheme='facebook'>
                <Thead>
                  <Tr>
                    <Th sx={{ width: 0.15, ...editEducationFormStyles.tHead }}>Año de inicio</Th>
                    <Th sx={{ width: 0.15, ...editEducationFormStyles.tHead }}>
                      Año de finalización
                    </Th>
                    <Th sx={{ width: 0.25, ...editEducationFormStyles.tHead }}>Título</Th>
                    <Th sx={{ width: 0.25, ...editEducationFormStyles.tHead }}>Instituto</Th>
                    <Th sx={{ width: 0.1, ...editEducationFormStyles.tHead }}>Estado</Th>
                    <Th sx={{ width: 0.1, ...editEducationFormStyles.tHead }}>Acción</Th>
                  </Tr>
                </Thead>
                <Tbody fontSize={'12px'}>
                  {docente.map((docente, index) => (
                    <Tr key={index}>
                      <Td sx={editEducationFormStyles.tData}>
                        {isEditing && editingItem && editingItem.id === docente.id ? (
                          <select
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.fromDate}
                            onChange={(e: any) => {
                              const fromYear = e.target.value
                              const fromDateLimit = startYears.findIndex(
                                (year: number) => year == fromYear,
                              )
                              const yearsArray = startYears.slice(fromDateLimit)
                              setFromDateYears(yearsArray)

                              Number(e.target.value) > Number(editedValues.toDate)
                                ? setEditedValues((prevValues) => ({
                                    ...prevValues,
                                    fromDate: e.target.value,
                                    toDate: e.target.value,
                                    id: docente.id,
                                    type: docente.type,
                                    userId: docente.userId,
                                  }))
                                : setEditedValues((prevValues) => ({
                                    ...prevValues,
                                    fromDate: e.target.value,
                                    id: docente.id,
                                    type: docente.type,
                                    userId: docente.userId,
                                  }))
                            }}
                          >
                            {startYears.map((year: number, i: number) => (
                              <option key={i} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        ) : (
                          docente.fromDate || '-'
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && editingItem.id === docente.id && docente.toDate !== '' ? (
                          <select
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.toDate}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                toDate: e.target.value,
                                id: docente.id,
                                type: docente.type,
                                userId: docente.userId,
                              }))
                            }
                          >
                            {fromDateYears.map((year: number, i: number) => (
                              <option key={i} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        ) : editingItem &&
                          editingItem.id === docente.id &&
                          docente.toDate === '' ? (
                          <Box>
                            {isCurrentEdited?.id === docente.id ? (
                              <select
                                className={isEditing ? 'editing-input' : ''}
                                value={editedValues.toDate}
                                onChange={(e) =>
                                  setEditedValues((prevValues) => ({
                                    ...prevValues,
                                    toDate: e.target.value,
                                    id: docente.id,
                                    type: docente.type,
                                    userId: docente.userId,
                                  }))
                                }
                              >
                                {fromDateYears.map((year: number, i: number) => (
                                  <option key={i} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <FormLabel sx={editEducationFormStyles.checkLabel}>
                                En curso{' '}
                                <Checkbox
                                  defaultChecked
                                  onChange={() => {
                                    setEditedValues((prevValues) => ({
                                      ...prevValues,
                                      percentage: '',
                                      teacherGraduated: true,
                                      toDate: `${currentYear}`,
                                    }))
                                    setIsCurrentEdited({
                                      id: docente?.id,
                                    })
                                  }}
                                />
                              </FormLabel>
                            )}
                          </Box>
                        ) : docente.toDate === '' ? (
                          'En curso'
                        ) : (
                          docente.toDate
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && editingItem.id === docente.id ? (
                          <input
                            type='text'
                            placeholder='Ingeniero en sistemas'
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.teacherTitle}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                teacherTitle: e.target.value,
                                id: docente.id,
                                type: docente.type,
                                userId: docente.userId,
                              }))
                            }
                          />
                        ) : (
                          docente.teacherTitle || '-'
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && editingItem.id === docente.id ? (
                          <input
                            type='text'
                            placeholder='UTN-FRBA'
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues?.institution}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                institution: e.target.value,
                                id: docente.id,
                                type: docente.type,
                                userId: docente.userId,
                              }))
                            }
                          />
                        ) : (
                          docente.institution || '-'
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem &&
                        docente.percentage &&
                        editingItem.id === docente.id &&
                        isCurrentEdited?.id !== docente.id ? (
                          <input
                            type='text'
                            placeholder='%'
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues?.percentage}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                percentage: e.target.value,
                                id: docente.id,
                                type: docente.type,
                                userId: docente.userId,
                              }))
                            }
                          />
                        ) : docente.teacherGraduated || isCurrentEdited?.id === docente.id ? (
                          docente.inProcess ? (
                            'En trámite'
                          ) : (
                            'Completado'
                          )
                        ) : (
                          `${docente.percentage}%`
                        )}
                      </Td>
                      <Td>
                        <Flex alignItems='center' justifyContent='space-between'>
                          {editingItem && editingItem.id === docente.id ? (
                            <Flex
                              alignItems='center'
                              justifyContent='space-between'
                              flexDirection={'row'}
                              columnGap={1}
                            >
                              <Tooltip label='Aceptar' background={colors.principal}>
                                <Icon
                                  as={CheckIcon}
                                  boxSize={3}
                                  color={'#1D8348'}
                                  onClick={() => {
                                    saveEducation()
                                  }}
                                />
                              </Tooltip>
                              <Tooltip label='Cancelar' background={colors.principal}>
                                <Icon
                                  as={CloseIcon}
                                  boxSize={2}
                                  color={'#283747'}
                                  onClick={() => {
                                    setIsEditing(false)
                                    setEditingItem(null)
                                    setIsCurrentEdited(null)
                                  }}
                                />
                              </Tooltip>
                            </Flex>
                          ) : (
                            <Flex alignItems='center' justifyContent='space-between'>
                              <Tooltip label='Editar' background={colors.principal}>
                                <Icon
                                  as={EditIcon}
                                  boxSize={3}
                                  color={'#2980B9'}
                                  mr={2}
                                  onClick={() => editEducation(docente)}
                                />
                              </Tooltip>
                              <Tooltip label='Eliminar' background={colors.principal}>
                                <Icon
                                  as={DeleteIcon}
                                  boxSize={3}
                                  color={'#CB4335'}
                                  onClick={() => deleteEducation(docente)}
                                />
                              </Tooltip>
                            </Flex>
                          )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <br />
          {/* hasta aqui docente  */}
          {nodocente.length !== 0 && (
            <Heading size='xs' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              No docente
            </Heading>
          )}
          {nodocente.length !== 0 && (
            <TableContainer>
              <Table colorScheme='facebook'>
                <Thead>
                  <Tr>
                    <Th sx={{ width: 0.15, ...editEducationFormStyles.tHead }}>Año de inicio</Th>
                    <Th sx={{ width: 0.15, ...editEducationFormStyles.tHead }}>
                      Año de finalización
                    </Th>
                    <Th sx={{ width: 0.25, ...editEducationFormStyles.tHead }}>Título</Th>
                    <Th sx={{ width: 0.25, ...editEducationFormStyles.tHead }}>Instituto</Th>
                    <Th sx={{ width: 0.1, ...editEducationFormStyles.tHead }}>Estado</Th>

                    <Th sx={{ width: 0.1, ...editEducationFormStyles.tHead }}>Acción</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {nodocente.map((nodocente, index) => (
                    <Tr key={index}>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && editingItem.id === nodocente.id ? (
                          <select
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.schoolFromDate}
                            onChange={(e: any) => {
                              const fromYear = e.target.value
                              const fromDateLimit = startYears.findIndex(
                                (year: number) => year == fromYear,
                              )
                              const yearsArray = startYears.slice(fromDateLimit)
                              setFromDateYears(yearsArray)
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                schoolFromDate: e.target.value,
                                id: nodocente.id,
                                type: nodocente.type,
                                userId: nodocente.userId,
                              }))
                            }}
                          >
                            {startYears.map((year: number, i: number) => (
                              <option key={i} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        ) : (
                          nodocente.schoolFromDate
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem &&
                        editingItem.id === nodocente.id &&
                        nodocente.schoolToDate !== '' ? (
                          <select
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.schoolToDate}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                schoolToDate: e.target.value,
                                id: nodocente.id,
                                type: nodocente.type,
                                userId: nodocente.userId,
                              }))
                            }
                          >
                            {fromDateYears.map((year: number, i: number) => (
                              <option key={i} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        ) : editingItem &&
                          editingItem.id === nodocente.id &&
                          nodocente.schoolToDate === '' ? (
                          <Box>
                            {isCurrentEdited?.id === nodocente.id ? (
                              <select
                                className={isEditing ? 'editing-input' : ''}
                                value={editedValues.schoolToDate}
                                onChange={(e) =>
                                  setEditedValues((prevValues) => ({
                                    ...prevValues,
                                    schoolToDate: e.target.value,
                                    id: nodocente.id,
                                    type: nodocente.type,
                                    userId: nodocente.userId,
                                  }))
                                }
                              >
                                {fromDateYears.map((year: number, i: number) => (
                                  <option key={i} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <FormLabel sx={editEducationFormStyles.checkLabel}>
                                En curso{' '}
                                <Checkbox
                                  defaultChecked
                                  onChange={() => {
                                    setEditedValues((prevValues) => ({
                                      ...prevValues,
                                      percentage: '',
                                      teacherGraduated: true,
                                      schoolToDate: `${currentYear}`,
                                    }))
                                    setIsCurrentEdited({
                                      id: nodocente?.id,
                                    })
                                  }}
                                />
                              </FormLabel>
                            )}
                          </Box>
                        ) : nodocente.schoolToDate === '' ? (
                          'En curso'
                        ) : (
                          nodocente.schoolToDate
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && editingItem.id === nodocente.id ? (
                          <input
                            type='text'
                            placeholder='Ingeniro en sistemas'
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.title}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                title: e.target.value,
                                id: nodocente.id,
                                type: nodocente.type,
                                userId: nodocente.userId,
                              }))
                            }
                          />
                        ) : (
                          nodocente.title
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && editingItem.id === nodocente.id ? (
                          <input
                            type='text'
                            placeholder='UTN-FRBA'
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.institution}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                institution: e.target.value,
                                id: nodocente.id,
                                type: nodocente.type,
                                userId: nodocente.userId,
                              }))
                            }
                          />
                        ) : (
                          nodocente.institution
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem &&
                        nodocente.percentage &&
                        editingItem.id === nodocente.id &&
                        isCurrentEdited?.id !== nodocente.id ? (
                          <input
                            type='text'
                            placeholder='%'
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues?.percentage}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                percentage: e.target.value,
                                id: nodocente.id,
                                type: nodocente.type,
                                userId: nodocente.userId,
                              }))
                            }
                          />
                        ) : nodocente.teacherGraduated || isCurrentEdited?.id === nodocente.id ? (
                          nodocente.inProcess ? (
                            'En trámite'
                          ) : (
                            'Completado'
                          )
                        ) : (
                          `${nodocente.percentage}%`
                        )}
                      </Td>
                      <Td>
                        <Flex alignItems='center' justifyContent='space-between'>
                          {editingItem && editingItem.id === nodocente.id ? (
                            <Flex
                              flexDirection='row'
                              alignItems='center'
                              justifyContent='center'
                              columnGap={1}
                            >
                              <Tooltip label='Aceptar' background={colors.principal}>
                                <Icon
                                  as={CheckIcon}
                                  boxSize={3}
                                  color={'#1D8348'}
                                  onClick={() => {
                                    saveEducation()
                                  }}
                                />
                              </Tooltip>
                              <Tooltip label='Cancelar' background={colors.principal}>
                                <Icon
                                  as={CloseIcon}
                                  boxSize={2}
                                  color={'#283747'}
                                  onClick={() => {
                                    setIsEditing(false)
                                    setEditingItem(null)
                                  }}
                                />
                              </Tooltip>
                            </Flex>
                          ) : (
                            <Flex
                              flexDirection='row'
                              alignItems='center'
                              justifyContent='center'
                              columnGap={1}
                            >
                              <Tooltip label='Editar' background={colors.principal}>
                                <Icon
                                  as={EditIcon}
                                  boxSize={3}
                                  color={'#2980B9'}
                                  mr={1}
                                  onClick={() => editEducation(nodocente)}
                                />
                              </Tooltip>
                              <Tooltip label='Eliminar' background={colors.principal}>
                                <Icon
                                  as={DeleteIcon}
                                  boxSize={3}
                                  color={'#CB4335'}
                                  onClick={() => deleteEducation(nodocente)}
                                />
                              </Tooltip>
                            </Flex>
                          )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <br />
          {courses.length !== 0 && (
            <Heading size='xs' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Cursos
            </Heading>
          )}
          {courses.length !== 0 && (
            <TableContainer>
              <Table colorScheme='facebook'>
                <Thead>
                  <Tr>
                    <Th sx={{ width: 0.15, ...editEducationFormStyles.tHead }}>Año de inicio</Th>
                    <Th sx={{ width: 0.15, ...editEducationFormStyles.tHead }}>
                      Año de finalización
                    </Th>
                    <Th sx={{ width: 0.45, ...editEducationFormStyles.tHead }}>Título</Th>
                    <Th sx={{ width: 0.3, ...editEducationFormStyles.tHead }}>Instituto</Th>
                    <Th sx={{ width: 0.1, ...editEducationFormStyles.tHead }}>Acción</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {courses.map((course, index) => (
                    <Tr key={index}>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && course.fromDate && editingItem.id === course.id ? (
                          <select
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.fromDate}
                            onChange={(e: any) => {
                              const fromYear = e.target.value
                              const fromDateLimit = startYears.findIndex(
                                (year: number) => year == fromYear,
                              )
                              const yearsArray = startYears.slice(fromDateLimit)
                              setFromDateYears(yearsArray)
                              Number(e.target.value) > Number(editedValues.toDate)
                                ? setEditedValues((prevValues) => ({
                                    ...prevValues,
                                    fromDate: e.target.value,
                                    toDate: e.target.value,
                                    id: course.id,
                                    type: course.type,
                                    userId: course.userId,
                                  }))
                                : setEditedValues((prevValues) => ({
                                    ...prevValues,
                                    fromDate: e.target.value,
                                    id: course.id,
                                    type: course.type,
                                    userId: course.userId,
                                  }))
                            }}
                          >
                            {startYears.map((year: number, i: number) => (
                              <option key={i} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        ) : (
                          course.fromDate
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && editingItem.id === course.id && course.toDate !== '' ? (
                          <select
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.toDate}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                toDate: e.target.value,
                                id: course.id,
                                type: course.type,
                                userId: course.userId,
                              }))
                            }
                          >
                            {fromDateYears.map((year: number, i: number) => (
                              <option key={i} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        ) : editingItem && editingItem.id === course.id && course.toDate === '' ? (
                          <Box>
                            {isCurrentEdited?.id === course.id ? (
                              <select
                                className={isEditing ? 'editing-input' : ''}
                                value={editedValues.toDate}
                                onChange={(e) =>
                                  setEditedValues((prevValues: any) => ({
                                    ...prevValues,
                                    toDate: e.target.value,
                                    id: course.id,
                                    type: course.type,
                                    userId: course.userId,
                                  }))
                                }
                              >
                                {fromDateYears.map((year: number, i: number) => (
                                  <option key={i} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <FormLabel sx={editEducationFormStyles.checkLabel}>
                                En curso
                                <Checkbox
                                  defaultChecked
                                  onChange={() => {
                                    setEditedValues((prevValues) => ({
                                      ...prevValues,
                                      toDate: `${currentYear}`,
                                    }))
                                    setIsCurrentEdited({
                                      id: course?.id,
                                    })
                                  }}
                                />
                              </FormLabel>
                            )}
                          </Box>
                        ) : course.toDate === '' ? (
                          'En curso'
                        ) : (
                          course.toDate
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && editingItem.id === course.id ? (
                          <input
                            type='text'
                            placeholder='Ingeniro en sistemas'
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.title}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                title: e.target.value,
                                id: course.id,
                                type: course.type,
                                userId: course.userId,
                              }))
                            }
                          />
                        ) : (
                          course.title
                        )}
                      </Td>
                      <Td sx={editEducationFormStyles.tData}>
                        {editingItem && editingItem.id === course.id ? (
                          <input
                            type='text'
                            placeholder='UTN-FRBA'
                            className={isEditing ? 'editing-input' : ''}
                            value={editedValues.institution}
                            onChange={(e) =>
                              setEditedValues((prevValues) => ({
                                ...prevValues,
                                institution: e.target.value,
                                id: course.id,
                                type: course.type,
                                userId: course.userId,
                              }))
                            }
                          />
                        ) : (
                          course.institution
                        )}
                      </Td>
                      <Td>
                        {editingItem && editingItem.id === course.id ? (
                          <Flex
                            flexDirection='row'
                            alignItems='center'
                            justifyContent='center'
                            columnGap={2}
                          >
                            <Tooltip label='Aceptar' background={colors.principal}>
                              <Icon
                                as={CheckIcon}
                                boxSize={3}
                                color={'#1D8348'}
                                onClick={() => {
                                  saveEducation()
                                }}
                              />
                            </Tooltip>
                            <Tooltip label='Cancelar' background={colors.principal}>
                              <Icon
                                as={CloseIcon}
                                boxSize={2}
                                color={'#283747'}
                                onClick={() => {
                                  setIsEditing(false)
                                  setEditingItem(null)
                                }}
                              />
                            </Tooltip>
                          </Flex>
                        ) : (
                          <Flex
                            flexDirection='row'
                            alignItems='center'
                            justifyContent='center'
                            columnGap={2}
                          >
                            <Tooltip label='Editar' background={colors.principal}>
                              <Icon
                                as={EditIcon}
                                boxSize={3}
                                color={'#2980B9'}
                                onClick={() => editEducation(course)}
                              />
                            </Tooltip>
                            <Tooltip label='Eliminar' background={colors.principal}>
                              <Icon
                                as={DeleteIcon}
                                boxSize={3}
                                color={'#CB4335'}
                                onClick={() => deleteEducation(course)}
                              />
                            </Tooltip>
                          </Flex>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <br />
          {languages.length !== 0 && (
            <Heading size='xs' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Idiomas
            </Heading>
          )}
          {languages.length !== 0 && (
            <TableContainer>
              <Table colorScheme='facebook'>
                <Thead>
                  <Tr>
                    <Th sx={{ width: 0.5, ...editEducationFormStyles.tHead }}>Descripción</Th>
                    <Th sx={{ width: 0.4, ...editEducationFormStyles.tHead }}>Nivel</Th>
                    <Th sx={{ width: 0.1, ...editEducationFormStyles.tHead }}>Acción</Th>
                  </Tr>
                </Thead>
                <Tbody fontSize={'12px'}>
                  {languages.map((lang, index) => (
                    <Tr key={index}>
                      <Td sx={editEducationFormStyles.tData}>{lang.description}</Td>
                      <Td sx={editEducationFormStyles.tData}>{lang['user-languages'].level}</Td>
                      <Td>
                        <Tooltip label='Eliminar' background={colors.principal}>
                          <Icon
                            as={DeleteIcon}
                            boxSize={3}
                            color={'#CB4335'}
                            onClick={() => deleteLanguage(lang.id)}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>
      ) : (
        <Box sx={editEducationFormStyles.noResults}>
          <Text>*No existen registros asociados</Text>
        </Box>
      )}
    </>
  )
}

export default EducationEdit
