import * as types from '../types'

import {
  adDetailFailure,
  adDetailSuccess,
  adFeaturedFailure,
  adFeaturedSuccess,
  adStatusFailure,
  adStatusSuccess,
  downloadAdsSpreadsheetFailure,
  downloadAdsSpreadsheetSuccess,
  duplicateJobFailure,
  duplicateJobSuccess,
  editJobFailure,
  editJobStatusFailure,
  editJobStatusSuccess,
  editJobSuccess,
  findJobsFailure,
  findJobsSuccess,
  institutionsCompleteFailure,
  institutionsCompleteSuccess,
  jobApplicationFailure,
  jobApplicationSuccess,
  jobDeleteFailure,
  jobDeleteSuccess,
  newJobFailure,
  positionsFailure,
  positionsSuccess,
  referenceNumbersFailure,
  referenceNumbersSuccess,
  searchJobsFailure,
  searchJobsSuccess,
  subjectsFailure,
  subjectsSuccess,
  adLastTenFailure,
  adLastTenSuccess
} from './../actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteJobService,
  getAdDetailInfoService,
  getAdStatusService,
  getInstitutionsListService,
  getJobTypesService,
  getPositionsService,
  getReferenceNumbersService,
  getSubjectsService,
  getTimeSlotsService,
  postDownloadAdsExcelSpreedsheetService,
  postDuplicateJobService,
  postFindJobsService,
  postJobApplicationService,
  postJobService,
  postSearchJobsService,
  putEditJobService,
  putEditJobStatusService,
  getLastTenAdsService
} from '../service'
import {
  institutionsFailure,
  institutionsSuccess,
  jobTypesFailure,
  jobTypesSuccess,
  newJobSuccess,
  timeSlotsFailure,
  timeSlotsSuccess,
} from '../actions'

import { SagaIterator } from 'redux-saga'

function* getInstitutionsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getInstitutionsListService, payload)
    yield put(institutionsSuccess(data))
  } catch (error) {
    yield put(institutionsFailure(error))
  }
}
export function* getInstitutionsWatcher(): SagaIterator {
  yield takeLatest(types.INSTITUTIONS_GET_REQUEST, getInstitutionsWorker)
}

function* getInstitutionsCompleteWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getInstitutionsListService, payload)
    yield put(institutionsCompleteSuccess(data))
  } catch (error) {
    yield put(institutionsCompleteFailure(error))
  }
}
export function* getInstitutionsCompleteWatcher(): SagaIterator {
  yield takeLatest(types.INSTITUTIONS_COMPLETE_GET_REQUEST, getInstitutionsCompleteWorker)
}

function* getTimeSlotsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getTimeSlotsService, payload)
    yield put(timeSlotsSuccess(data))
  } catch (error) {
    yield put(timeSlotsFailure(error))
  }
}
export function* getTimeSlotsWatcher(): SagaIterator {
  yield takeLatest(types.TIMESLOTS_GET_REQUEST, getTimeSlotsWorker)
}

function* getJobTypesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getJobTypesService, payload)
    yield put(jobTypesSuccess(data))
  } catch (error) {
    yield put(jobTypesFailure(error))
  }
}
export function* getJobTypesWatcher(): SagaIterator {
  yield takeLatest(types.JOBTYPES_GET_REQUEST, getJobTypesWorker)
}
function* postJobWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postJobService, payload)
    yield put(newJobSuccess(data))
  } catch (error) {
    yield put(newJobFailure(error))
  }
}
export function* postJobWatcher(): SagaIterator {
  yield takeLatest(types.NEWJOB_POST_REQUEST, postJobWorker)
}

function* postFindJobsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postFindJobsService, payload)
    yield put(findJobsSuccess(data))
  } catch (error) {
    yield put(findJobsFailure(error))
  }
}
export function* postFindJobsWatcher(): SagaIterator {
  yield takeLatest(types.FIND_JOBS_POST_REQUEST, postFindJobsWorker)
}

function* putEditJobWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(putEditJobService, payload)
    yield put(editJobSuccess(data))
  } catch (error) {
    yield put(editJobFailure(error))
  }
}
export function* putEditJobWatcher(): SagaIterator {
  yield takeLatest(types.EDIT_JOB_PUT_REQUEST, putEditJobWorker)
}

function* putEditJobStateWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(putEditJobStatusService, payload)
    yield put(editJobStatusSuccess(data))
  } catch (error) {
    yield put(editJobStatusFailure(error))
  }
}
export function* putEditJobStateWatcher(): SagaIterator {
  yield takeLatest(types.EDIT_JOB_STATUS_PUT_REQUEST, putEditJobStateWorker)
}

function* deleteJobWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(deleteJobService, payload)
    yield put(jobDeleteSuccess(data))
  } catch (error) {
    yield put(jobDeleteFailure(error))
  }
}
export function* deleteJobWatcher(): SagaIterator {
  yield takeLatest(types.JOB_DELETE_REQUEST, deleteJobWorker)
}

function* postSearchJobsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postSearchJobsService, payload)

    yield put(searchJobsSuccess(data))
  } catch (error) {
    yield put(searchJobsFailure(error))
  }
}
export function* postSearchJobsWatcher(): SagaIterator {
  yield takeLatest(types.SEARCH_JOBS_POST_REQUEST, postSearchJobsWorker)
}

function* applyJobWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postJobApplicationService, payload)
    yield put(jobApplicationSuccess(data))
  } catch (error) {
    yield put(jobApplicationFailure(error))
  }
}
export function* applyJobWatcher(): SagaIterator {
  yield takeLatest(types.JOB_APPLICATION_GET_REQUEST, applyJobWorker)
}

function* getAdDetailInformationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getAdDetailInfoService, payload)

    yield put(adDetailSuccess(data))
  } catch (error) {
    yield put(adDetailFailure(error))
  }
}
export function* getAdDetailInformationWatcher(): SagaIterator {
  yield takeLatest(types.AD_DETAIL_GET_REQUEST, getAdDetailInformationWorker)
}

function* postDownloadAdsSpreadsheetWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postDownloadAdsExcelSpreedsheetService, payload)

    yield put(downloadAdsSpreadsheetSuccess(data))
  } catch (error) {
    yield put(downloadAdsSpreadsheetFailure(error))
  }
}
export function* postDownloadSpreadsheetWatcher(): SagaIterator {
  yield takeLatest(types.DOWNLOAD_ADS_SPREADSHEET_POST_REQUEST, postDownloadAdsSpreadsheetWorker)
}

function* getPositionsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getPositionsService, payload)
    yield put(positionsSuccess(data))
  } catch (error) {
    yield put(positionsFailure(error))
  }
}
export function* getPositionsWatcher(): SagaIterator {
  yield takeLatest(types.POSITIONS_LIST_GET_REQUEST, getPositionsWorker)
}

function* getAdStatusWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getAdStatusService, payload)
    yield put(adStatusSuccess(data))
  } catch (error) {
    yield put(adStatusFailure(error))
  }
}
export function* getAdStatusWatcher(): SagaIterator {
  yield takeLatest(types.AD_STATUS_LIST_GET_REQUEST, getAdStatusWorker)
}

function* getSubjectsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getSubjectsService, payload)
    yield put(subjectsSuccess(data))
  } catch (error) {
    yield put(subjectsFailure(error))
  }
}
export function* getSubjectsWatcher(): SagaIterator {
  yield takeLatest(types.SUBJECTS_LIST_GET_REQUEST, getSubjectsWorker)
}

function* postDuplicateJobWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postDuplicateJobService, payload)
    yield put(duplicateJobSuccess(data))
  } catch (error) {
    yield put(duplicateJobFailure(error))
  }
}
export function* postDuplicateJobWatcher(): SagaIterator {
  yield takeLatest(types.DUPLICATE_JOB_POST_REQUEST, postDuplicateJobWorker)
}

function* getReferenceNumbersWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getReferenceNumbersService, payload)
    yield put(referenceNumbersSuccess(data))
  } catch (error) {
    yield put(referenceNumbersFailure(error))
  }
}
export function* getReferenceNumbersWatcher(): SagaIterator {
  yield takeLatest(types.REFERENCE_NUMBERS_GET_REQUEST, getReferenceNumbersWorker)
}

function* getLastTenAds(): SagaIterator {
  try {

    const { data } = yield call(getLastTenAdsService)

    yield put(adLastTenSuccess(data))
  } catch (error) {
    yield put(adLastTenFailure(error))
  }
}
export function* getLastTenAdsWatcher(): SagaIterator {
  yield takeLatest(types.AD_LAST_TEN_GET_REQUEST, getLastTenAds)
}
export const sagas = [
  getInstitutionsWatcher,
  getInstitutionsCompleteWatcher,
  getTimeSlotsWatcher,
  getJobTypesWatcher,
  postJobWatcher,
  postFindJobsWatcher,
  putEditJobWatcher,
  putEditJobStateWatcher,
  deleteJobWatcher,
  postSearchJobsWatcher,
  getAdDetailInformationWatcher,
  applyJobWatcher,
  postDownloadSpreadsheetWatcher,
  getPositionsWatcher,
  getAdStatusWatcher,
  getSubjectsWatcher,
  postDuplicateJobWatcher,
  getReferenceNumbersWatcher,
  getLastTenAdsWatcher
]
