import { position } from '@chakra-ui/react'
import { colors } from '../../utils/colors'

export const landingStyles = {
  headingText: {
    fontSize: '100px',
    '&.red': {
      color: 'red'
    },
    '&.blue': {
      color: 'blue'
    }
  },
  statistics: {
    mainBackground: {
      backgroundColor: '#1E5C84'
    },
    // subHeading: {
    //   fontSize: '20px',
    //   textAlign: 'left',
    //   marginTop:'-26px'
    // }
  }
}
