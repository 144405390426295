import { FC, PropsWithChildren } from 'react'
import { Box } from '@chakra-ui/react'
import Header from '../../components/header'
import Footer from '../../components/footer'

const LandingLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box height='100vh'  display='flex' flexDirection='column'>
      <Box flex='1'>
        <Box as='header'>
          <Header />
        </Box>
        <Box mx='auto'>
          {children}
        </Box>
      </Box>
      <Box as='footer'>
        <Footer />
      </Box>
    </Box>
  )
}

export default LandingLayout
