import React from 'react'
import LandingLayout from '../../layouts/landing'
import { VStack } from '@chakra-ui/react'
import Candidates from '../../components/Candidates'

export const CandidateSearch: React.FC = () => {
  return (
    <LandingLayout>
      <VStack>
        <Candidates />
      </VStack>
    </LandingLayout>
  )
}

export default CandidateSearch
