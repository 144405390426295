import { Box, FormLabel, Select, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { OrderByInputProps } from './_types'
import { orderByInputStyles } from './styles'

const OrderByInput: FC<OrderByInputProps> = ({ options, setOrder, styles }) => {
  const handleChange = (e: any) => {
    setOrder(e.target.value)
  }
  return (
    <Box
      sx={
        styles?.container
          ? { ...orderByInputStyles.container, ...styles.container }
          : orderByInputStyles.container
      }
    >
      <FormLabel
        sx={
          styles?.container
            ? { ...orderByInputStyles.label, ...styles.label }
            : orderByInputStyles.label
        }
      >
        Ordenar por:
      </FormLabel>
      <Select
        sx={
          styles?.container
            ? { ...orderByInputStyles.select, ...styles.select }
            : orderByInputStyles.select
        }
        onChange={handleChange}
      >
        {options.map((option: any) => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Box>
  )
}

export default OrderByInput
