import Invites from '../../components/Invites'
import LandingLayout from '../../layouts/landing'
import React from 'react'
import { VStack } from '@chakra-ui/react'

export const InvitesHistory: React.FC = () => {
  return (
    <LandingLayout>
      <VStack>
        <Invites />
      </VStack>
    </LandingLayout>
  )
}

export default InvitesHistory
