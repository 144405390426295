import {
  Box,
  Card,
  CardBody,
  Heading,
  Stack,
  StackDivider,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PersonalDataResume = () => {
  const userInformation = useSelector((state: any) => state.user.userInformation ?? '')
  const { id } = useParams()
  const institutoFilter = userInformation?.Institutions.filter((item: any) => item.id === id)
  const province = institutoFilter[0]?.Address?.Province?.description ?? ''
  const department = institutoFilter[0]?.Address?.Department?.description ?? ''
  const region = institutoFilter[0]?.Region?.description ?? ''
  const city = institutoFilter[0]?.Address?.Locality?.description ?? ''

  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [datos, setDatos] = useState({
    cue: '-',
    nroProvincial: '-',
    address: '-',
    region: '-',
    quantityOfStudents: '-',
    quantityOfEmployees: '-',
    levelOfTeaching: '-',
  })

  useEffect(() => {
    userInformation !== undefined &&
      userInformation !== null &&
      userInformation !== '' &&
      setearDatos()
  }, [userInformation, id])

  const setearDatos = () => {
    const cue = institutoFilter[0]?.cue
    const lastDigits = cue?.slice(-2)
    const firstLength = cue?.length - lastDigits?.length
    const transformedCue = `${cue?.slice(0, firstLength)}-${lastDigits}`

    setDatos({
      ...datos,
      cue: institutoFilter?.length > 0 ? transformedCue : '-',
      nroProvincial: institutoFilter?.length > 0 ? institutoFilter[0]?.provincialNumber : '-',
      address:
        institutoFilter?.length > 0 && institutoFilter[0]
          ? `Calle: ${institutoFilter[0]?.Address?.street || '-'} Altura: ${
              institutoFilter[0]?.Address?.number || '-'
            } | Localidad: ${city.toLowerCase() || '-'} | Departamento/Partido: ${
              department.toLowerCase() || '-'
            } | Provincia: ${province.toLowerCase() || '-'}
          `
          : '-',
      region: institutoFilter[0]?.region ?? '-',
      quantityOfStudents: institutoFilter[0]?.StudentsQuantity?.description ?? '-',
      quantityOfEmployees: institutoFilter[0]?.EmployeesQuantity?.description ?? '-',
      levelOfTeaching: institutoFilter[0]?.Level?.description ?? '-',
    })
  }

  return (
    <Card>
      <CardBody>
        <Stack divider={<StackDivider />} spacing='4'>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              CUE
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.cue}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Número provincial
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.nroProvincial}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Domicilio
            </Heading>
            <Text
              textTransform='capitalize'
              fontSize='12px'
              ml={isMobile ? 0 : 2}
              mt={isMobile ? 2 : 0}
            >
              {datos.address}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Región
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.region}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Cantidad de estudiantes
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.quantityOfStudents}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Cantidad de empleados
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.quantityOfEmployees}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Nivel de enseñanza
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.levelOfTeaching}
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  )
}

export default PersonalDataResume
