import {
  newSuscriptionDataFailure,
  newSuscriptionDataSuccess,
  newContactDataFailure,
  newContactDataSuccess,
} from './../actions'
import { put, takeLatest, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as types from '../types'
import { postNewContactService, postNewSuscriptionService } from '../service'

function* postNewContactWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postNewContactService, payload)
    yield put(newContactDataSuccess(data))
  } catch (error) {
    yield put(newContactDataFailure(error))
  }
}
export function* postNewContactWatcher(): SagaIterator {
  yield takeLatest(types.CONTACT_DATA_POST_REQUEST, postNewContactWorker)
}

function* postNewSuscriptionWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postNewSuscriptionService, payload)
    yield put(newSuscriptionDataSuccess(data))
  } catch (error) {
    yield put(newSuscriptionDataFailure(error))
  }
}
export function* postNewSuscriptionWatcher(): SagaIterator {
  yield takeLatest(types.SUSCRIPTION_DATA_POST_REQUEST, postNewSuscriptionWorker)
}

export const sagas = [postNewContactWatcher, postNewSuscriptionWatcher]
