import * as Yup from 'yup'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { NewUserFormProps, NewUserInvitation } from '../_types'
import React, { FC } from 'react'

import Select from 'react-select'
import { inviteModalStyles } from '../styles'
import { newInviteRequest } from '../../../state/modules/profiles/actions'
import { useDispatch } from 'react-redux'

const NewUserForm: FC<NewUserFormProps> = ({ roles, institutionId }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const dispatch = useDispatch()

  const initialValues = {
    userEmail: '',
    userRole: '',
    userName: '',
    userLastname: '',
    userDNI: '',
  }

  const schemaValidation = Yup.object({
    userEmail: Yup.string().email('Email inválido').required('Este campo es requerido'),
    userRole: Yup.string().required('Este campo es requerido'),
    userName: Yup.string().required('Este campo es requerido'),
    userLastname: Yup.string().required('Este campo es requerido'),
    userDNI: Yup.string().required('Este campo es requerido'),
  }).defined()

  const handleSubmit = (values: any) => {
    const token = localStorage.getItem('access_token') ?? ''
    onClose()
    const data = {
      roleId: values.userRole,
      name: values.userName,
      lastName: values.userLastname,
      dni: values.userDNI,
      email: values.userEmail,
    }
    dispatch(newInviteRequest({ token, institutionId, inviteData: data }))

  }
  return (
    <Box style={inviteModalStyles.form}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schemaValidation}
      >
        {({ setFieldValue, setFieldTouched, setValues, touched, errors }) => {
          const touchedFields = touched as FormikTouched<NewUserInvitation>
          const errorFields = errors as FormikErrors<NewUserInvitation>
          return (
            <Form>
              <FormControl mb={2}>
                <FormLabel htmlFor='userEmail' fontWeight='bold'>
                  Email
                </FormLabel>
                <Field name='userEmail' as={Input} placeholder='Ingrese un email' />
                {errorFields.userEmail && touchedFields.userEmail && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields?.userEmail}
                  </Text>
                )}
              </FormControl>
              <FormControl mb={4}>
                <FormLabel htmlFor='userRole' fontWeight='bold'>
                  Rol
                </FormLabel>
                <Select
                  name={'userRole'}
                  placeholder='Elija un rol'
                  options={roles?.map((e: any) => ({ value: e.id, label: e.description }))}
                  onChange={(e) => setFieldValue('userRole', e?.value)}
                  onBlur={() => setFieldTouched('userRole', true)}
                />
                {errorFields.userRole && touchedFields.userRole && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields?.userRole}
                  </Text>
                )}
              </FormControl>
              <FormControl mb={2}>
                <FormLabel htmlFor='userName' fontWeight='bold'>
                  Nombre
                </FormLabel>
                <Field name='userName' as={Input} placeholder='Ingrese un nombre' />
                {errorFields.userName && touchedFields.userName && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields?.userName}
                  </Text>
                )}
              </FormControl>
              <FormControl mb={2}>
                <FormLabel htmlFor='userLastname' fontWeight='bold'>
                  Apellido
                </FormLabel>
                <Field name='userLastname' as={Input} placeholder='Ingrese un apellido' />
                {errorFields.userLastname && touchedFields.userLastname && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields?.userLastname}
                  </Text>
                )}
              </FormControl>
              <FormControl mb={2}>
                <FormLabel htmlFor='userDNI' fontWeight='bold'>
                  DNI
                </FormLabel>
                <Field name='userDNI' as={Input} placeholder='Ingrese un DNI' />
                {errorFields.userDNI && touchedFields.userDNI && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields?.userDNI}
                  </Text>
                )}
              </FormControl>

              <Box display='flex' justifyContent='center' mt={8}>
                <Popover isOpen={isOpen} placement='right'>
                  <PopoverTrigger>
                    <Button
                      isDisabled={isOpen}
                      sx={inviteModalStyles.saveButton}
                      onClick={onToggle}
                    >
                      Enviar
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={onToggle} />
                    <PopoverHeader>¿Está seguro que desea enviar los datos?</PopoverHeader>
                    <PopoverBody sx={{ display: 'flex', justifyContent: 'center', columnGap: 8 }}>
                      <Button sx={inviteModalStyles.saveButton} type='submit'>
                        Si
                      </Button>
                      <Button sx={inviteModalStyles.saveButton} onClick={onToggle}>
                        No
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default NewUserForm
