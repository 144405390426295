import { contact } from './_duck'
import * as types from './types'

export const newContactDataRequest = contact.createAction(types.CONTACT_DATA_POST_REQUEST)
export const newContactDataSuccess = contact.createAction(types.CONTACT_DATA_POST_SUCCESS)
export const newContactDataFailure = contact.createAction(types.CONTACT_DATA_POST_FAILURE)
export const newContactDataClean = contact.createAction(types.CONTACT_DATA_POST_CLEAN)

export const newSuscriptionDataRequest = contact.createAction(types.SUSCRIPTION_DATA_POST_REQUEST)
export const newSuscriptionDataSuccess = contact.createAction(types.SUSCRIPTION_DATA_POST_SUCCESS)
export const newSuscriptionDataFailure = contact.createAction(types.SUSCRIPTION_DATA_POST_FAILURE)
export const newSuscriptionDataClean = contact.createAction(types.SUSCRIPTION_DATA_POST_CLEAN)
