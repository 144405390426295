import { colors } from '../../../../utils/colors'

export const preferencesTableStyles = {
  tHead: { fontSize: '11px', padding: '8px', color: colors.principal, textWrap: 'wrap' },
  tData: {
    fontSize: '11px',
    padding: '8px',
  },

  deleteButton: { cursor: 'pointer', boxSize: 3, color: colors.red },
}
