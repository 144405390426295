import { Box, useBreakpoint } from '@chakra-ui/react'
import FewStepsPublishAds from '../../../components/landing/institutional/few-steps-publish-ads'
import HeaderInstLanding from '../../../components/landing/institutional/header-inst-landing'
import Tendencies from '../../../components/landing/institutional/tendencies'
import OurUsersSays from '../../../components/landing/shared/our-users-says'
import { IsInstit } from '..'
import { useEffect } from 'react'
import PropTypes from 'prop-types'
const InstitutionalLanding: React.FC<IsInstit> = ({ isInstit }) => {
    const deviceWidth = useBreakpoint()
    const contPadding = deviceWidth === 'base' ? '42.5px' : '85px'

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Box position='relative' overflow='hidden' mt='.4rem'>
            <Box
                w='740.09px'
                h='740.09px'
                position='absolute'
                top={{ base: '651px', md: '1038px', lg: '1163px', '2xl': '1723px' }}
                left={{ base: '-700px', md: '-655px', lg: '-664px', '2xl': '-634px' }}
                bgColor='rgba(224, 63, 89,  10%)'
                borderRadius='50%'
                zIndex='1'
            ></Box>
            <Box
                w='740.09px'
                h='740.09px'
                position='absolute'
                top={{ base: '639px', md: '639px', lg: '660px', '2xl': '997px' }}
                right={{ base: '-703px', md: '-703px', lg: '-660.96px', '2xl': '-590px' }}
                bgColor='rgba(224, 63, 89,  10%)'
                borderRadius='50%'
                zIndex='1'
            ></Box>

            <Box mb={['80px', '160px']}>
                <HeaderInstLanding contPadding={contPadding} />
            </Box>
            <Box mb={['80px', '160px']}>
                <Tendencies />
            </Box>
            <Box mb={['80px', '160px']}>
                <FewStepsPublishAds contPadding={contPadding} />
            </Box>
            <Box mb={['80px', '160px']}>
                <OurUsersSays contPadding={contPadding} isInstit={isInstit} />
            </Box>
        </Box>
    )
}
InstitutionalLanding.propTypes = {
    isInstit: PropTypes.bool.isRequired,
}
export default InstitutionalLanding