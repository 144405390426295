import { Box, Center, Heading, Stack, } from '@chakra-ui/layout'
import { Button, Card, CardBody, Flex, Grid, GridItem, HStack, Text, useMediaQuery, Image } from '@chakra-ui/react'
import { landingStyles } from '../../../../pages/landing/styles'
import ColorButton from '../../../buttons/button-blue'
import { Buttons } from '../../../header'
import { colors } from '../../../../utils/colors'
import { headerLandingStyles } from '../../regular/header-landing/styles'

import Icon1 from '../../../../assets/images/landing/institutional/user_icon.png'
import Icon2 from '../../../../assets/images/landing/institutional/profile_icon.png'
import Icon3 from '../../../../assets/images/landing/institutional/folder_icon.png'
import Icon4 from '../../../../assets/images/landing/institutional/handbag_icon.png'
import arrow from '../../../../assets/images/landing/arrow.svg'
import { LandingMarginProps } from '../../../../pages/landing/regular-landing'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

const steps = [
  { title: 'Creá tu cuenta', icon: Icon1 },
  { title: 'Creá tu perfil y cargá los datos de tu institución', icon: Icon2 },
  { title: 'Creá un aviso', icon: Icon3 },
  { title: 'Conocé a tus candidatos', icon: Icon4 }
]
const FewStepsPublishAds: FC<LandingMarginProps> = ({ contPadding }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const navigate = useNavigate()
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])

  const handleActionBtn = () => {
    if (isLogged) {
      console.log('acción botón...')
    } else {
      navigate('/login')
    }
    // window.location.reload()
  }
  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width={['100%']}
      px='3vw'
    >
      <Box mb={['45px', '90px']}>
        <Text
          fontSize={['1.4rem', '2.1rem']}
          textAlign={'center'}
          width={'100%'}
          color='#004876'
        >
          Estás a pocos pasos de publicar un aviso
        </Text>
      </Box>
      <Flex
        justifyContent={{ base: 'space-around', md: 'space-around', lg: 'space-between' }}
        alignContent="center"
        flexDirection="row"
        width='100%'
        height={['200px', '196px']}
        flexWrap={'wrap'}
        gap={{ base: '10px', md: '15px', lg: '20px' }}
      >
        {steps.map((step, i, arr) => {
          return (
            <>
              <Box
                width={{ base: '15%', md: '15%', lg: '180px' }}
                height={['100%', '100%']}
                display='flex'
                justifyContent="center"
                alignItems="center"
                flexDirection='column'
              >
                <Box
                  display='flex'
                  justifyContent="center"
                  alignItems="center"
                  h={{ base: '40%', md: '60%', lg: '70%' }}
                  w='100%'
                >
                  <Box
                    h={['60px','96px']}
                    w={['60px','96px']}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
                    borderRadius='50%'
                  >
                    <Image src={step.icon} h={['40%','50%']} />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  justifyContent="center"
                  alignItems="flex-start"
                  h={{ base: '60%', md: '40%', lg: '30%' }}
                  w='100%'
                >
                  <Text
                    color={colors.principal}
                    fontSize={{ base: '12px', md: '12px', lg: '16px' }}
                    textAlign='center'
                    lineHeight={{ base: '15px', md: '13px', lg: '19.5px' }}
                  >
                    {step.title}
                  </Text>
                </Box>
              </Box>

              {(i !== arr.length - 1) &&
                <Box
                  display='flex'
                  alignItems='flex-start'
                  justifyContent='center'
                  h='100%'
                  width={{ base: '5%', md: '5%', lg: '10%' }}
                >
                  <Box
                    marginTop={{ base: '35px', md: '56px', lg: '65px', '2xl':'80px' }}
                  >
                    <Image src={arrow} />
                  </Box>
                </Box>
              }
            </>
          )
        })}
      </Flex>
      <Box
        width='max-content'
        color='#004876'
        fontSize={['18px', '18px']}
        pt={{ base: '30px', md: '30px', lg: '40px' }}
      >
        <Button
          sx={headerLandingStyles.actionButton}
          size={{ base: 'sm', md: 'md', lg: 'lg' }}
          onClick={handleActionBtn}
        > {Buttons.ACCESS_AND_PUBLISH_AD}
        </Button>
      </Box>
    </Flex>
  )
}

export default FewStepsPublishAds
