import * as Yup from 'yup'

import { Box, Button, FormControl, FormLabel, Heading, Input, Text } from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { FormAssociation, NewAssociationFormProps } from './_types'
import React, { FC, useState } from 'react'
import Select, { components } from 'react-select'
import { useDebounce, useDebouncedCallback } from 'use-debounce'

import AsyncSelect from 'react-select/async'
import { colors } from '../../../../../utils/colors'
import { getUsersService } from '../../../../../state/modules/abm/services'
import { newAssociationFormStyles } from './styles'
import { newUserAssociationRequest } from '../../../../../state/modules/abm/actions'
import { useDispatch } from 'react-redux'

const NewAssociationForm: FC<NewAssociationFormProps> = ({
  setShowUserForm,
  roles,
  institutionId,
}) => {
  const [userId, setUserId] = useState<string | null>(null)
  const dispatch = useDispatch()

  const initialValues = {
    userEmail: '',
    userName: '',
    userLastname: '',
    userDNI: '',
    userCellphone: '',
    userRole: '',
  }

  const schemaValidation = Yup.object({
    userEmail: Yup.string().required('Este campo es requerido'),
    userName: Yup.string(),
    userLastname: Yup.string(),
    userDNI: Yup.string(),
    userCellphone: Yup.string(),
    userRole: Yup.string().required('Este campo es requerido'),
  }).defined()

  const getUsers = useDebouncedCallback(
    ({ query }: { query: string }) => {
      const token = localStorage.getItem('access_token') ?? ''
      return getUsersService({ token, query })
        .then((res) => {
          return res.data.users.map((e: any) => ({
            value: e.id,
            label: e.email,
            profile: { ...e.Profile },
          }))
        })
        .catch((error) => console.log(error))
    },
    300,
    { leading: true },
  )

  const handleInputEmailChange = (InputValue: string) => {
    if (InputValue?.length > 2) {
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          resolve(getUsers({ query: InputValue }))
        }, 1000)
      })
    } else
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          resolve([])
        }, 1000)
      })
  }

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>
          {props?.selectProps?.inputValue?.length > 2
            ? 'No existen resultados'
            : 'Se debe ingresar más de 2 caracteres'}
        </span>
      </components.NoOptionsMessage>
    )
  }

  const handleEmailChange = (e: any, setValues: any) => {
    if (e !== null) {
      setUserId(e.value)
      setValues((values: any) => {
        return {
          ...values,
          userEmail: e.profile.email,
          userName: e.profile.name,
          userLastname: e.profile.lastName,
          userDNI: e.profile.dni,
          userCellphone: e.profile.cellPhone,
        }
      })
    } else {
      setUserId(null)
      setValues((values: any) => {
        return {
          ...values,
          userEmail: '',
          userName: '',
          userLastname: '',
          userDNI: '',
          userCellphone: '',
        }
      })
    }
  }

  const handleSubmit = (values: any) => {
    const token = localStorage.getItem('access_token') ?? ''
    const payload = {
      token,
      institutionId: institutionId,
      userId,
      roleId: values.userRole,
    }
    dispatch(newUserAssociationRequest(payload))
  }

  return (
    <Box width={'70%'}>
      <Formik
        initialValues={initialValues}
        validationSchema={schemaValidation}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          resetForm,
          setValues,
          setFieldTouched,
          setFieldValue,
        }) => {
          const touchedFields = touched as FormikTouched<FormAssociation>
          const errorFields = errors as FormikErrors<FormAssociation>

          return (
            <Form>
              <Box sx={newAssociationFormStyles.formContainer}>
                <Box sx={newAssociationFormStyles.closeButtonContainer}>
                  <Heading sx={newAssociationFormStyles.heading}>Agregar usuario</Heading>
                  <Button
                    alignSelf={'flex-end'}
                    onClick={() => {
                      setShowUserForm((prev: any) => !prev)
                    }}
                    colorScheme='facebook'
                  >
                    X
                  </Button>
                </Box>
                <FormControl mb={2}>
                  <FormLabel htmlFor='userEmail' fontWeight='bold'>
                    Email
                  </FormLabel>
                  <AsyncSelect
                    components={{ NoOptionsMessage }}
                    isClearable
                    loadOptions={handleInputEmailChange}
                    onChange={(e) => handleEmailChange(e, setValues)}
                    onFocus={() => setFieldTouched('userEmail', true)}
                    placeholder='Elija el usuario asociado'
                    loadingMessage={() => 'Cargando...'}
                    name='userEmail'
                  />
                  {errorFields.userEmail && touchedFields.userEmail && (
                    <Text color='red' fontSize={'sm'}>
                      {errorFields?.userEmail}
                    </Text>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight='bold' htmlFor='userName'>
                    Nombre
                  </FormLabel>
                  <Field
                    as={Input}
                    disabled
                    _disabled={{ color: colors.black }}
                    type='text'
                    name='userName'
                    placeholder='Nombre'
                    mb={2}
                  />
                  {errorFields.userName && touchedFields.userName && (
                    <Text color='red' fontSize={'sm'}>
                      {errorFields.userName}
                    </Text>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight='bold' htmlFor='userLastname'>
                    Apellido
                  </FormLabel>
                  <Field
                    as={Input}
                    disabled
                    _disabled={{ color: colors.black }}
                    type='text'
                    name='userLastname'
                    placeholder='Apellido'
                    mb={2}
                  />
                  {errorFields.userLastname && touchedFields.userLastname && (
                    <Text color='red' fontSize={'sm'}>
                      {errorFields.userLastname}
                    </Text>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight='bold' htmlFor='userDNI'>
                    DNI
                  </FormLabel>
                  <Field
                    as={Input}
                    disabled
                    _disabled={{ color: colors.black }}
                    type='text'
                    name='userDNI'
                    placeholder='DNI'
                    mb={2}
                  />
                  {errorFields.userDNI && touchedFields.userDNI && (
                    <Text color='red' fontSize={'sm'}>
                      {errorFields.userDNI}
                    </Text>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight='bold' htmlFor='userCellphone'>
                    Teléfono celular
                  </FormLabel>
                  <Field
                    as={Input}
                    disabled
                    _disabled={{ color: colors.black }}
                    type='text'
                    name='userCellphone'
                    placeholder='Teléfono celular'
                    mb={2}
                  />
                  {errorFields.userCellphone && touchedFields.userCellphone && (
                    <Text color='red' fontSize={'sm'}>
                      {errorFields.userCellphone}
                    </Text>
                  )}
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel htmlFor='userRole' fontWeight='bold'>
                    Rol
                  </FormLabel>
                  <Select
                    name={'userRole'}
                    placeholder='Elija un rol'
                    options={roles?.map((e: any) => ({ value: e.id, label: e.description }))}
                    onChange={(e) => setFieldValue('userRole', e?.value)}
                    onFocus={() => setFieldTouched('userRole', true)}
                  />
                  {errorFields.userRole && touchedFields.userRole && (
                    <Text color='red' fontSize={'sm'}>
                      {errorFields?.userRole}
                    </Text>
                  )}
                </FormControl>

                <Box display='flex' justifyContent='center'>
                  <Button sx={newAssociationFormStyles.saveButton} type='submit'>
                    Guardar cambios
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default NewAssociationForm
