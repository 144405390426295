import { contact } from './_duck'
import * as types from './types'
import { PayloadAction } from '../../types'
import { ModuleTypeNewContact, ModuleTypeNewSuscription } from './_definitions'

const initialModuleStateNewContact: ModuleTypeNewContact = {
  hasErrMessage: false,
  newContactSuccess: null,
  newContactStatus: null,
}

const initialModuleStateNewSuscription: ModuleTypeNewSuscription = {
  hasErrMessage: false,
  newSuscriptionSuccess: null,
  newSuscriptionStatus: null,
}

const reducer = contact.createReducer(
  {
    [types.CONTACT_DATA_POST_REQUEST]: (state: ModuleTypeNewContact): ModuleTypeNewContact => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.CONTACT_DATA_POST_SUCCESS]: (
      state: ModuleTypeNewContact,
      { payload }: PayloadAction,
    ): ModuleTypeNewContact => ({
      ...state,
      newContactSuccess: true,
      newContactStatus: payload,
    }),
    [types.CONTACT_DATA_POST_FAILURE]: (
      state: ModuleTypeNewContact,
      { payload }: PayloadAction,
    ): ModuleTypeNewContact => ({
      ...state,
      hasErrMessage: true,
      newContactSuccess: false,
      newContactStatus: payload,
    }),
    [types.CONTACT_DATA_POST_CLEAN]: (state: ModuleTypeNewContact): ModuleTypeNewContact => ({
      ...state,
      hasErrMessage: false,
      newContactSuccess: null,
      newContactStatus: null,
    }),
    [types.SUSCRIPTION_DATA_POST_REQUEST]: (
      state: ModuleTypeNewSuscription,
    ): ModuleTypeNewSuscription => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.SUSCRIPTION_DATA_POST_SUCCESS]: (
      state: ModuleTypeNewSuscription,
      { payload }: PayloadAction,
    ): ModuleTypeNewSuscription => ({
      ...state,
      newSuscriptionSuccess: true,
      newSuscriptionStatus: payload,
    }),
    [types.SUSCRIPTION_DATA_POST_FAILURE]: (
      state: ModuleTypeNewSuscription,
      { payload }: PayloadAction,
    ): ModuleTypeNewSuscription => ({
      ...state,
      hasErrMessage: true,
      newSuscriptionSuccess: false,
      newSuscriptionStatus: payload,
    }),
    [types.SUSCRIPTION_DATA_POST_CLEAN]: (
      state: ModuleTypeNewSuscription,
    ): ModuleTypeNewSuscription => ({
      ...state,
      hasErrMessage: false,
      newSuscriptionSuccess: null,
      newSuscriptionStatus: null,
    }),
  },
  { initialModuleStateNewContact, initialModuleStateNewSuscription },
)

export default reducer
