import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'

import React from 'react'

interface ActionPopoverProps {
  handleAction: (id: string) => void
  children: any
  id: string
  title: string
  handleClose: (param: string) => void
  buttonSize: string
  closeId?: string
  width?: string
}
const ActionPopover: React.FC<ActionPopoverProps> = ({
  children,
  title,
  handleAction,
  id,
  handleClose,
  buttonSize,
  closeId,
  width,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const name = e.currentTarget.name
    if (name === 'confirm') {
      handleAction(id)
    }
    if (name === 'cancel') {
      handleClose(closeId || id)
    }
  }

  return (
    <Popover id='hello'>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent width={width || '220px'}>
        <PopoverArrow />
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverCloseButton id={`PopoverCloseButton${closeId || id}`} />
        <PopoverBody display={'flex'} justifyContent={'space-around'}>
          <Button
            name='confirm'
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e)}
            colorScheme='blue'
            fontSize={buttonSize}
          >
            Si
          </Button>
          <Button
            name='cancel'
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e)}
            colorScheme='blue'
            fontSize={buttonSize}
          >
            No
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default ActionPopover
