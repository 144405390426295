import React, { FC, useState, useEffect } from 'react'
import { RequestsDataField, RequestsTableProps } from './_types'
import { Box, Heading, Icon, Table, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react'
import { requestsTableStyles } from './styles'
import { useSelector } from 'react-redux'
import { requestsFields } from '../../../../utils/abm'
import { ViewIcon } from '@chakra-ui/icons'
import Loader from '../../../loader'

export const RequestsTable: FC<RequestsTableProps> = ({
  showModal,
  currentPage,
  setCurrentPage,
  setCurrentUser,
}) => {
  const [requests, setRequests] = useState<any>(null)

  const abm = useSelector((state: any) => state?.abm)
  const showedFields = requestsFields.filter((e) => e.isShown).sort((e1, e2) => e1.order - e2.order)

  useEffect(() => {
    abm?.requisitionsInformation && setRequests(abm.requisitionsInformation?.rows)
  }, [abm?.requisitionsInformation])

  return (
    <Box>
      {requests === null ? (
        <Loader props={requestsTableStyles.loading} />
      ) : requests.length === 0 ? (
        <Box sx={requestsTableStyles.noResultsContainer}>
          <Text sx={requestsTableStyles.noResults}>*No existen registros</Text>
        </Box>
      ) : (
        <Table colorScheme='facebook' sx={requestsTableStyles.table}>
          <Thead>
            <Tr sx={requestsTableStyles.tableHead}>
              {showedFields.map((e: RequestsDataField) => {
                return (
                  <Td maxWidth={`${e.width}%` || 'auto'} key={e.order}>
                    <Text sx={requestsTableStyles.tableData}>{e.name}</Text>
                  </Td>
                )
              })}
              <Td>Acción</Td>
            </Tr>
          </Thead>
          <Tbody sx={requestsTableStyles.tableBody}>
            {requests.map((e: any) => {
              const cue = e?.Institution?.cue
              const lastDigits = cue?.slice(-2)
              const firstLength = cue?.length - lastDigits?.length
              const transformedCue = `${cue?.slice(0, firstLength)}-${lastDigits}`
              return (
                <Tr key={e.id}>
                  <Td width={`${showedFields[0]?.width}%`}>{e.Institution.name}</Td>
                  <Td width={`${showedFields[1]?.width}%`}>{transformedCue}</Td>
                  <Td width={`${showedFields[2]?.width}%`}>{e.User?.Profile?.name}</Td>
                  <Td width={`${showedFields[3]?.width}%`}>{e.User?.Profile?.lastName}</Td>
                  <Td width={`${showedFields[4]?.width}%`}>{e.User?.Profile?.dni}</Td>
                  <Td>
                    <Box sx={requestsTableStyles.actionField}>
                      <Icon
                        as={ViewIcon}
                        cursor='pointer'
                        boxSize={4}
                        color={'#2980B9'}
                        onClick={() => {
                          showModal('4')
                          setCurrentUser(e)
                        }}
                      />
                    </Box>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      )}
    </Box>
  )
}
