export const candidatesTableStyles = {
  container: { width: '100%', marginTop: '16px' },
  loading: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsContainer: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    textAlign: 'center',
    padding: '20px 0px',
    fontWeight: 'bold',
  },
  tableHead: {
    fontWeight: 'bold',
    fontSize: '11px',
    textWrap: 'wrap',
    padding: '8px 8px',
  },
  tableData: {
    fontSize: '11px',
    textWrap: 'wrap',
    padding: '8px 8px',
  },
  tableDataAction: {
    padding: '8px 8px',
  },
  tableDataIcon: {
    cursor: 'pointer',
    boxSize: '12px',
    margin: '0px 3px',
  },
  columnData: {
    textTransform: 'capitalize',
  },
}
