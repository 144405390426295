import { FC } from 'react'
import { useMediaQuery } from '@chakra-ui/react'
import LandingLayout from '../../../layouts/landing'
import LoginCard from '../../../components/login/card'
import ResetForm from '../../../components/forms/reset-form'

const Reset: FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  return (
    <LandingLayout>
      {isMobile ? (
        <ResetForm />
      ) : (
        <LoginCard>
          <ResetForm />
        </LoginCard>
      )}
    </LandingLayout>
  )
}

export default Reset
