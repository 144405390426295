import { profiles } from './_ducks'

export const PROFILES_POST_REQUEST: string = profiles.defineType('PROFILES_POST_REQUEST')
export const PROFILES_POST_SUCCESS: string = profiles.defineType('PROFILES_POST_SUCCESS')
export const PROFILES_POST_FAILURE: string = profiles.defineType('PROFILES_POST_FAILURE')
export const PROFILES_POST_CLEAN: string = profiles.defineType('PROFILES_POST_CLEAN')

export const VIEW_REQUISITIONS_GET_REQUEST: string = profiles.defineType(
  'VIEW_REQUISITIONS_GET_REQUEST',
)
export const VIEW_REQUISITIONS_GET_SUCCESS: string = profiles.defineType(
  'VIEW_REQUISITIONS_GET_SUCCESS',
)
export const VIEW_REQUISITIONS_GET_FAILURE: string = profiles.defineType(
  'VIEW_REQUISITIONS_GET_FAILURE',
)
export const VIEW_REQUISITIONS_GET_CLEAN: string = profiles.defineType(
  'VIEW_REQUISITIONS_GET_CLEAN',
)

export const HANDLE_REQUISITION_PUT_REQUEST: string = profiles.defineType(
  'HANDLE_REQUISITION_PUT_REQUEST',
)
export const HANDLE_REQUISITION_PUT_SUCCESS: string = profiles.defineType(
  'HANDLE_REQUISITION_PUT_SUCCESS',
)
export const HANDLE_REQUISITION_PUT_FAILURE: string = profiles.defineType(
  'HANDLE_REQUISITION_PUT_FAILURE',
)
export const HANDLE_REQUISITION_PUT_CLEAN: string = profiles.defineType(
  'HANDLE_REQUISITION_PUT_CLEAN',
)

export const SEND_REQUISITION_POST_REQUEST: string = profiles.defineType(
  'SEND_REQUISITION_POST_REQUEST',
)
export const SEND_REQUISITION_POST_SUCCESS: string = profiles.defineType(
  'SEND_REQUISITION_POST_SUCCESS',
)
export const SEND_REQUISITION_POST_FAILURE: string = profiles.defineType(
  'SEND_REQUISITION_POST_FAILURE',
)
export const SEND_REQUISITION_POST_CLEAN: string = profiles.defineType(
  'SEND_REQUISITION_POST_CLEAN',
)

export const PROFILE_GET_REQUEST: string = profiles.defineType('PROFILE_GET_REQUEST')
export const PROFILE_GET_SUCCESS: string = profiles.defineType('PROFILE_GET_SUCCESS')
export const PROFILE_GET_FAILURE: string = profiles.defineType('PROFILE_GET_FAILURE')
export const PROFILE_GET_CLEAN: string = profiles.defineType('PROFILE_GET_CLEAN')

export const DOWNLOAD_SPREADSHEET_POST_REQUEST: string = profiles.defineType(
  'DOWNLOAD_SPREADSHEET_POST_REQUEST',
)
export const DOWNLOAD_SPREADSHEET_POST_SUCCESS: string = profiles.defineType(
  'DOWNLOAD_SPREADSHEET_POST_SUCCESS',
)
export const DOWNLOAD_SPREADSHEET_POST_FAILURE: string = profiles.defineType(
  'DOWNLOAD_SPREADSHEET_POST_FAILURE',
)
export const DOWNLOAD_SPREADSHEET_POST_CLEAN: string = profiles.defineType(
  'DOWNLOAD_SPREADSHEET_POST_CLEAN',
)

export const INSTITUTION_GET_REQUEST: string = profiles.defineType('INSTITUTION_GET_REQUEST')
export const INSTITUTION_GET_SUCCESS: string = profiles.defineType('INSTITUTION_GET_SUCCESS')
export const INSTITUTION_GET_FAILURE: string = profiles.defineType('INSTITUTION_GET_FAILURE')
export const INSTITUTION_GET_CLEAN: string = profiles.defineType('INSTITUTION_GET_CLEAN')

export const USER_INVITES_GET_REQUEST: string = profiles.defineType('USER_INVITES_GET_REQUEST')
export const USER_INVITES_GET_SUCCESS: string = profiles.defineType('USER_INVITES_GET_SUCCESS')
export const USER_INVITES_GET_FAILURE: string = profiles.defineType('USER_INVITES_GET_FAILURE')
export const USER_INVITES_GET_CLEAN: string = profiles.defineType('USER_INVITES_GET_CLEAN')

export const INSTITUTION_INVITES_HISTORY_GET_REQUEST: string = profiles.defineType(
  'INSTITUTION_INVITES_HISTORY_GET_REQUEST',
)
export const INSTITUTION_INVITES_HISTORY_GET_SUCCESS: string = profiles.defineType(
  'INSTITUTION_INVITES_HISTORY_GET_SUCCESS',
)
export const INSTITUTION_INVITES_HISTORY_GET_FAILURE: string = profiles.defineType(
  'INSTITUTION_INVITES_HISTORY_GET_FAILURE',
)
export const INSTITUTION_INVITES_HISTORY_GET_CLEAN: string = profiles.defineType(
  'INSTITUTION_INVITES_HISTORY_GET_CLEAN',
)

export const NEW_INVITE_POST_REQUEST: string = profiles.defineType('NEW_INVITE_POST_REQUEST')
export const NEW_INVITE_POST_SUCCESS: string = profiles.defineType('NEW_INVITE_POST_SUCCESS')
export const NEW_INVITE_POST_FAILURE: string = profiles.defineType('NEW_INVITE_POST_FAILURE')
export const NEW_INVITE_POST_CLEAN: string = profiles.defineType('NEW_INVITE_POST_CLEAN')

export const HANDLE_INVITE_PUT_REQUEST: string = profiles.defineType('HANDLE_INVITE_PUT_REQUEST')
export const HANDLE_INVITE_PUT_SUCCESS: string = profiles.defineType('HANDLE_INVITE_PUT_SUCCESS')
export const HANDLE_INVITE_PUT_FAILURE: string = profiles.defineType('HANDLE_INVITE_PUT_FAILURE')
export const HANDLE_INVITE_PUT_CLEAN: string = profiles.defineType('HANDLE_INVITE_PUT_CLEAN')

export const RESEND_INVITE_POST_REQUEST: string = profiles.defineType('RESEND_INVITE_POST_REQUEST')
export const RESEND_INVITE_POST_SUCCESS: string = profiles.defineType('RESEND_INVITE_POST_SUCCESS')
export const RESEND_INVITE_POST_FAILURE: string = profiles.defineType('RESEND_INVITE_POST_FAILURE')
export const RESEND_INVITE_POST_CLEAN: string = profiles.defineType('RESEND_INVITE_POST_CLEAN')
