import * as Yup from 'yup'

import { Box, Button, FormControl, FormLabel, Input, Select, Text } from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { FormEducation, courses, languages } from '../_types'
import React, { useEffect, useState } from 'react'
import {
  educationRequest,
  languagesClean,
  languagesRequest,
  userInformationRequest,
} from '../../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import { PostRegisterEducation } from '../../../../../state/modules/user/services/types'
import { formsStyles } from '../../styles'

const IdiomaComp: React.FC<any> = ({ setShowLanguage, setIsConfirmModalOpen }: any) => {
  const [filteredLanguage, setFilteredLanguage] = useState<any[]>([])
  const userInformation: any = useSelector((state: any) => state.user.userInformation)
  const dispatch = useDispatch()
  const languages = useSelector((state: any) => state?.user?.languagesList)
  const userLanguages = userInformation?.Languages
  const token = localStorage.getItem('access_token')

  useEffect(() => {
    dispatch(languagesRequest({ token }))

    return () => {
      dispatch(languagesClean())
    }
  }, [])

  useEffect(() => {
    if (languages) {
      for (let i = 0; i < languages?.length; i++) {
        const repeat = userLanguages?.find((e: any) => e.id === languages[i].id)
        if (!repeat) {
          setFilteredLanguage((prev: any) => [...prev, languages[i]])
        }
      }
    }
  }, [userLanguages, languages])

  const initialValues: any = {
    languageId: '',
    level: '',
  }

  const validateSchema = Yup.object({
    languageId: Yup.string().required('Campo obligatorio'),
    level: Yup.string().required('Campo obligatorio'),
  })
  const handleSubmit = (values: any) => {
    const updatedInitialValuesForm: PostRegisterEducation = {
      userId: userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      title: null,
      courses: [],
      languages: [
        {
          languageId: values.languageId,
          level: values.level,
        },
      ],
    }
    dispatch(educationRequest(updatedInitialValuesForm))
    setShowLanguage(false)
    setIsConfirmModalOpen(true)
  }
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validateSchema}>
      {({ handleChange, touched, errors }) => {
        const touchedFields = touched as FormikTouched<languages>
        const errorFields = errors as FormikErrors<languages>
        return (
          <Form style={{ margin: '16px 0' }}>
            <Box sx={{ width: '370px', ...formsStyles.formContainer }}>
              <Button colorScheme='blue' alignSelf='end' onClick={() => setShowLanguage(false)}>
                X
              </Button>
              <Text fontSize='sm' fontWeight='bold' mt={3} mb={3}>
                Idiomas
              </Text>
              <FormControl>
                <FormLabel htmlFor='languageId' fontWeight='bold'>
                  Elegir idioma/s
                </FormLabel>
                <Field as={Select} name='languageId' onChange={handleChange}>
                  {languages?.length !== 0 && (
                    <>
                      <option value=''>Seleccionar idioma</option>
                      {filteredLanguage.map((language: any) => (
                        <option key={language.id} value={language.id}>
                          {language.description}
                        </option>
                      ))}
                    </>
                  )}
                </Field>
                {errorFields.languageId && touchedFields.languageId && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields.languageId}
                  </Text>
                )}
              </FormControl>

              <FormControl>
                <FormLabel htmlFor='level' fontWeight='bold'>
                  Nivel
                </FormLabel>
                <Field as={Select} name='level' onChange={handleChange}>
                  <option value=''>Seleccionar idioma</option>
                  <option value='Básico'>Básico</option>
                  <option value='Intermedio'>Intermedio</option>
                  <option value='Avanzado'>Avanzado</option>
                </Field>
                {errorFields.level && touchedFields.level && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields.level}
                  </Text>
                )}
              </FormControl>
              <Box display='flex' justifyContent='center' mt={4}>
                <Button sx={formsStyles.saveButton} type='submit'>
                  Guardar cambios
                </Button>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default IdiomaComp
