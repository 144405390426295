import { useState } from 'react'
import { Box, VStack, Button, Text } from '@chakra-ui/react'
import { ChevronDownIcon, EditIcon } from '@chakra-ui/icons'
import PersonalDataResume from './recumecomponents/personaldata'
import EducationResume from './recumecomponents/education'
import JobsExperienceResume from './recumecomponents/jobsexperience'
import ModalResumeInstitution from './ModalResumeInstitution'

const InformationResumeInstitution = () => {
  const [showPersonalData, setShowPersonalData] = useState(false)
  const [showEducation, setShowEducation] = useState(false)
  const [showJobsExperience, setShowJobsExperience] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<number>(2) // Inicialízalo en 0

  const handleOpenModal = (option: number) => {
    setSelectedOption(option)
    setIsOpen(true)
  }
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <Box width='100%' display={'flex'} flexDirection={'column'}>
      <ModalResumeInstitution
        isOpen={isOpen}
        onClose={handleCloseModal}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <VStack>
        <Box width={'100%'} p={7}>
          <Box display={'flex'} flexDirection={'row'}>
            <Button
              mt={4}
              colorScheme='white'
              width='90%'
              display='flex'
              justifyContent='space-between'
              onClick={() => {
                setShowPersonalData(!showPersonalData)
              }}
            >
              <Text color={'#004876'} fontWeight='bold'>
                Datos de la institución
              </Text>
              <ChevronDownIcon boxSize={8} color={'#004876'} fontWeight='bold' />
            </Button>
            <Button
              mt={4}
              colorScheme='white'
              width='10%'
              display='flex'
              justifyContent='flex-end'
              onClick={() => handleOpenModal(2)}
            >
              <EditIcon boxSize={6} color={'#004876'} fontWeight='bold' />
            </Button>
          </Box>
          {showPersonalData && <PersonalDataResume />}

          {/* ahora es datos institucionales  */}

          <Box display={'flex'} flexDirection={'row'}>
            <Button
              mt={4}
              colorScheme='white'
              width='90%'
              display='flex'
              justifyContent='space-between'
              onClick={() => {
                setShowEducation(!showEducation)
              }}
            >
              {/* aqui datos del propietario */}

              <Text color={'#004876'} fontWeight='bold'>
                Datos del propietario
              </Text>
              <ChevronDownIcon boxSize={8} color={'#004876'} fontWeight='bold' />
            </Button>
            <Button
              mt={4}
              colorScheme='white'
              width='10%'
              display='flex'
              justifyContent='flex-end'
              onClick={() => handleOpenModal(3)}
            >
              <EditIcon boxSize={6} color={'#004876'} fontWeight='bold' />
            </Button>
          </Box>
          {showEducation && <EducationResume />}

          {/* Aqui datos de contacto */}

          <Box display={'flex'} flexDirection={'row'}>
            <Button
              mt={4}
              colorScheme='white'
              width='90%'
              display='flex'
              justifyContent='space-between'
              onClick={() => {
                setShowJobsExperience(!showJobsExperience)
              }}
            >
              <Text color={'#004876'} fontWeight='bold'>
                Contacto
              </Text>
              <ChevronDownIcon boxSize={8} color={'#004876'} fontWeight='bold' />
            </Button>
            <Button
              mt={4}
              colorScheme='white'
              width='10%'
              display='flex'
              justifyContent='flex-end'
              onClick={() => handleOpenModal(4)}
            >
              <EditIcon boxSize={6} color={'#004876'} fontWeight='bold' />
            </Button>
          </Box>
          {showJobsExperience ? <JobsExperienceResume /> : null}
        </Box>
      </VStack>
    </Box>
  )
}

export default InformationResumeInstitution
