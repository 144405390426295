import { notifications } from './_ducks'
import * as types from './types'

export const userEmailNotificationRequest = notifications.createAction(
  types.USER_EMAIL_NOTIFICATION_GET_REQUEST,
)
export const userEmailNotificationSuccess = notifications.createAction(
  types.USER_EMAIL_NOTIFICATION_GET_SUCCESS,
)
export const userEmailNotificationFailure = notifications.createAction(
  types.USER_EMAIL_NOTIFICATION_GET_FAILURE,
)
export const userEmailNotificationClean = notifications.createAction(
  types.USER_EMAIL_NOTIFICATION_GET_CLEAN,
)

export const editUserEmailNotificationRequest = notifications.createAction(
  types.EDIT_USER_EMAIL_NOTIFICATION_POST_REQUEST,
)
export const editUserEmailNotificationSuccess = notifications.createAction(
  types.EDIT_USER_EMAIL_NOTIFICATION_POST_SUCCESS,
)
export const editUserEmailNotificationFailure = notifications.createAction(
  types.EDIT_USER_EMAIL_NOTIFICATION_POST_FAILURE,
)
export const editUserEmailNotificationClean = notifications.createAction(
  types.EDIT_USER_EMAIL_NOTIFICATION_POST_CLEAN,
)

export const editInstitutionEmailNotificationRequest = notifications.createAction(
  types.EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_REQUEST,
)
export const editInstitutionEmailNotificationSuccess = notifications.createAction(
  types.EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_SUCCESS,
)
export const editInstitutionEmailNotificationFailure = notifications.createAction(
  types.EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_FAILURE,
)
export const editInstitutionEmailNotificationClean = notifications.createAction(
  types.EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_CLEAN,
)
