import { Button, forwardRef } from '@chakra-ui/react'

const BlueButton = forwardRef(({ children, bgColor, ...props }, ref) => {

  return <Button colorScheme={bgColor ? bgColor : null} ref={ref} fontSize={'sm'} {...props}>
    {children}
  </Button>
})

export default BlueButton
