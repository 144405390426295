import {
  Badge,
  Box,
  Button,
  Link as ChakraLink,
  Icon,
  Img,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link, NavLink } from 'react-router-dom'
import React, { FC } from 'react'
import { caseSvg, locationSvg } from '../../../utils/general'

import { JobApplicationCardProps } from './_types'
import { colors } from '../../../utils/colors'
import { deleteApplicationRequest } from '../../../state/modules/jobApplications/actions'
import institutionEmpty from '../../../assets/images/profile/InsitutionEmpty.png'
import { jobApplicationCardStyles } from './styles'
import { useDispatch } from 'react-redux'

const JobApplicationCard: FC<JobApplicationCardProps> = ({ application }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const dispatch = useDispatch()

  const publicationDate =
    application?.Ad?.publicationDate &&
      application?.Ad?.publicationDate !== null &&
      application?.Ad?.publicationDate !== ''
      ? new Date(application?.Ad?.publicationDate)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-')
      : '-'

  const postulationDate =
    application?.createdAt && application?.createdAt !== null && application?.createdAt !== ''
      ? new Date(application?.createdAt).toISOString().split('T')[0].split('-').reverse().join('-')
      : '-'

  const state =
    application?.Ad?.Status?.description && application?.Ad?.Status?.description !== ''
      ? application?.Ad?.Status?.description
      : 'Sin datos'

  const stateColor =
    application?.Ad?.Status?.id == 5
      ? colors.states.draft
      : application?.Ad?.Status?.id == 1
        ? colors.states.active
        : application?.Ad?.Status?.id == 2
          ? colors.states.cover
          : application?.Ad?.Status?.id == 3
            ? colors.states.expired
            : colors.states.disabled

  const handleDeleteApplication = (applicationId: number | string) => {
    dispatch(
      deleteApplicationRequest({
        applicationId: applicationId,
        token: localStorage.getItem('access_token'),
      }),
    )
  }

  return (
    <Box
      sx={isMobile ? jobApplicationCardStyles.containerMobile : jobApplicationCardStyles.container}
    >
      <Box
        sx={
          isMobile
            ? jobApplicationCardStyles.imageContainerMobile
            : jobApplicationCardStyles.imageContainer
        }
      >
        <Img
          sx={
            isMobile ? jobApplicationCardStyles.avatarMobile : jobApplicationCardStyles.avatarImage
          }
          alt={application?.Ad?.Institutions[0]?.name}
          src={
            application?.Ad?.showInstitutionName !== false
              ? application?.Ad?.Institutions[0]?.UserFiles[0]?.filePath
                ? `${application?.Ad?.Institutions[0]?.UserFiles[0]?.filePath}`
                : institutionEmpty
              : institutionEmpty
          }
        />
      </Box>
      <Box
        sx={
          isMobile
            ? jobApplicationCardStyles.infoContainerMobile
            : jobApplicationCardStyles.infoContainer
        }
      >
        <Text sx={jobApplicationCardStyles.title}>{application.Ad.title}</Text>
        <Box
          sx={
            isMobile
              ? jobApplicationCardStyles.mainDataContainerMobile
              : jobApplicationCardStyles.mainDataContainer
          }
        >
          <Box
            sx={
              isMobile
                ? jobApplicationCardStyles.dataItemMobile1
                : jobApplicationCardStyles.dataItem
            }
          >
            {application?.Ad?.Institutions?.map((e: any, i: number) =>
              application?.Ad?.showInstitutionName !== false ? (
                <NavLink
                  style={{
                    textTransform: 'capitalize',
                  }}
                  to={`/institution/detail/${e.id}`}
                  target='_blank'
                  key={e.id}
                >
                  <ChakraLink>
                    {application?.Ad?.Institutions?.length > 1 && '*'}
                    {e.name}
                  </ChakraLink>
                </NavLink>
              ) : (
                <Text key={e.id} sx={{ ...jobApplicationCardStyles.adData, marginLeft: 0 }}>
                  {application?.Ad?.Institutions?.length > 1 && '*'}
                  Institución educativa
                </Text>
              ),
            )}
          </Box>
          <Box
            sx={
              isMobile
                ? jobApplicationCardStyles.dataItemMobile1
                : jobApplicationCardStyles.dataItem
            }
          >
            <Text sx={jobApplicationCardStyles.adDataTitle}>Nro de referencia del aviso:</Text>
            <Text sx={jobApplicationCardStyles.adData}>{application?.Ad?.referenceNumber}</Text>
          </Box>
          <Box
            sx={
              isMobile
                ? jobApplicationCardStyles.dataItemMobile1
                : jobApplicationCardStyles.dataItem
            }
          >
            <Text sx={jobApplicationCardStyles.adDataTitle}>Fecha de publicación:</Text>
            <Text sx={jobApplicationCardStyles.adData}>{publicationDate}</Text>
          </Box>
          <Box
            sx={
              isMobile
                ? jobApplicationCardStyles.dataItemMobile1
                : jobApplicationCardStyles.dataItem
            }
          >
            <Text sx={jobApplicationCardStyles.adDataTitle}>Fecha de postulación:</Text>
            <Text sx={jobApplicationCardStyles.adData}>{postulationDate}</Text>
          </Box>
          <Box
            sx={
              isMobile
                ? jobApplicationCardStyles.dataItemMobile2
                : jobApplicationCardStyles.dataItem
            }
          >
            <Icon width='20px' height='20px' viewBox={caseSvg.viewBox} fill='none'>
              <path
                d={caseSvg.d}
                stroke={colors.principal}
                strokeWidth={caseSvg.strokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </Icon>
            <Text sx={jobApplicationCardStyles.adData}>
              {application?.Ad?.Position?.description}
            </Text>
          </Box>
          <Box sx={jobApplicationCardStyles.locationContainer}>
            {application?.Ad?.Zones?.map((e: any) => (
              <Box
                sx={
                  isMobile
                    ? jobApplicationCardStyles.dataItemMobile2
                    : jobApplicationCardStyles.dataItem
                }
                key={e.id}
              >
                <Text sx={{ ...jobApplicationCardStyles.adData, marginLeft: '0' }}>
                  <Icon
                    fill={colors.principal}
                    height='15px'
                    width='15px'
                    viewBox={locationSvg.viewBox}
                  >
                    <path d={locationSvg.d}></path>
                  </Icon>{' '}
                  {e?.Province?.description.toLowerCase()} -{' '}
                  {e?.Department?.description.toLowerCase()} -{' '}
                  {e?.Locality?.description.toLowerCase()}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={
          isMobile
            ? jobApplicationCardStyles.actionContainerMobile
            : jobApplicationCardStyles.actionContainer
        }
      >
        <Box sx={jobApplicationCardStyles.stateContainer}>
          <Text sx={jobApplicationCardStyles.stateTitle}>Estado</Text>
          <Badge sx={jobApplicationCardStyles.badge} colorScheme={stateColor}>
            {state}
          </Badge>
        </Box>
        <Link to={`/careers/career/${application?.Ad?.id}`} target='_blank'>
          <Button sx={{ width: '100%', ...jobApplicationCardStyles.actionButton }}>Ver</Button>
        </Link>
        <Button
          sx={{ width: '100%', ...jobApplicationCardStyles.actionButton }}
          onClick={() => handleDeleteApplication(application.id)}
        >
          Eliminar
        </Button>
      </Box>
    </Box>
  )
}

export default JobApplicationCard
