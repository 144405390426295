import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik, FormikErrors, FormikTouched } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { rolesClean, rolesRequest } from '../../state/modules/abm/actions'
import { useDispatch, useSelector } from 'react-redux'

import ExistingUserForm from './ExistingUserForm'
import { InviteModalProps } from './_types'
import NewUserForm from './NewUserForm'
import { inviteModalStyles } from './styles'
import { newInviteClean } from '../../state/modules/profiles/actions'

const InviteModal: FC<InviteModalProps> = ({ institutionId, isOpen, onClose }) => {
  const [isNewUser, setIsNewUser] = useState<boolean>(false)
  const profile: any = useSelector((state: any) => state?.profiles)
  const roles = useSelector((state: any) =>
    state?.abm?.rolesInformation?.filter((e: any) => e.type === 'institution'),
  )
  const userId = useSelector((state: any) => state?.user?.userInformation?.id)
  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    isOpen && dispatch(rolesRequest({ token }))

    return () => {
      isOpen && dispatch(rolesClean())
    }
  }, [isOpen])

  useEffect(() => {
    if (profile?.newInviteSuccess === true) {
      toast({
        description: profile.newInviteStatus.message,
        status: 'success',
        duration: toastDuration,
      })
      onClose()
      dispatch(newInviteClean())
    }

    if (profile?.newInviteSuccess === false) {
      toast({
        description: profile.newInviteStatus.response.data.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(newInviteClean())
    }
  }, [profile?.newInviteSuccess])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
      <ModalOverlay />
      <ModalContent padding={'24px 48px'}>
        <ModalHeader>Invitar usuario</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Button
            width={'full'}
            colorScheme='blue'
            mb={5}
            isDisabled={isNewUser === false}
            onClick={() => setIsNewUser(false)}
          >
            Usuario existente
          </Button>
          <Button
            width={'full'}
            colorScheme='blue'
            mb={5}
            isDisabled={isNewUser}
            onClick={() => setIsNewUser(true)}
          >
            Nuevo usuario
          </Button>

          {isNewUser ? (
            <NewUserForm roles={roles} institutionId={institutionId} />
          ) : (
            <ExistingUserForm roles={roles} institutionId={institutionId} userId={userId} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InviteModal
