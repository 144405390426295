import { put, takeLatest, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as types from '../types'
import {
  deleteApplicationService,
  getJobApplicationsListService,
  postDownloadHandleApplicationsListExcelService,
  postHandleApplicationsListService,
} from '../service'
import {
  applicationsListFailure,
  applicationsListSuccess,
  deleteApplicationFailure,
  deleteApplicationSuccess,
  downloadApplicationsSheetFailure,
  downloadApplicationsSheetRequest,
  downloadApplicationsSheetSuccess,
  handleApplicationsListFailure,
  handleApplicationsListSuccess,
} from '../actions'

function* getApplicationsListWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getJobApplicationsListService, payload)

    yield put(applicationsListSuccess(data))
  } catch (error) {
    yield put(applicationsListFailure(error))
  }
}
export function* getApplicationsListWatcher(): SagaIterator {
  yield takeLatest(types.APPLICATIONS_LIST_POST_REQUEST, getApplicationsListWorker)
}

function* postApplicationsListWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postHandleApplicationsListService, payload)

    yield put(handleApplicationsListSuccess(data))
  } catch (error) {
    yield put(handleApplicationsListFailure(error))
  }
}
export function* postApplicationsListWatcher(): SagaIterator {
  yield takeLatest(types.HANDLE_APPLICATIONS_LIST_POST_REQUEST, postApplicationsListWorker)
}

function* postDownloadApplicationsSheetWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postDownloadHandleApplicationsListExcelService, payload)

    yield put(downloadApplicationsSheetSuccess(data))
  } catch (error) {
    yield put(downloadApplicationsSheetFailure(error))
  }
}
export function* postDownloadApplicationsSheetWatcher(): SagaIterator {
  yield takeLatest(
    types.DOWNLOAD_APPLICATIONS_SHEET_POST_REQUEST,
    postDownloadApplicationsSheetWorker,
  )
}

function* deleteApplicationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(deleteApplicationService, payload)

    yield put(deleteApplicationSuccess(data))
  } catch (error) {
    yield put(deleteApplicationFailure(error))
  }
}
export function* deleteApplicationWatcher(): SagaIterator {
  yield takeLatest(types.APPLICATION_DELETE_REQUEST, deleteApplicationWorker)
}

export const sagas = [
  getApplicationsListWatcher,
  postApplicationsListWatcher,
  postDownloadApplicationsSheetWatcher,
  deleteApplicationWatcher,
]
