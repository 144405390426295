import { colors } from '../../../../utils/colors'

export const experienceModalStyles = {
  heading: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '16px',
    marginBottom: '16px',
  },
}
