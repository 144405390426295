import { colors } from '../../../utils/colors'

const icon = { cursor: 'pointer', color: colors.principal }

export const favoritesCardStyles = {
  container: {
    border: `1px solid ${colors.principal}`,
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'row',
  },
  containerMobile: {
    border: `1px solid ${colors.principal}`,
    padding: '20px 30px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  imageContainer: { width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  imageContainerMobile: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  avatarMobile: { width: '100px', height: '100px', borderRadius: '100px', objectFit: 'contain' },
  avatarImage: { width: '50px', height: '50px', borderRadius: '50px', objectFit: 'contain' },
  infoContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    rowGap: '5px',
    width: '100%',
  },
  infoContainer: { width: '60%' },
  name: { color: colors.principal, fontSize: '14px', fontWeight: 'bold' },
  nameMobile: {
    color: colors.principal,
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '75%',
  },
  zone: { color: colors.principal, fontSize: '12px', textTransform: 'capitalize' },
  zoneMobile: { color: colors.principal, fontSize: '16px', textTransform: 'capitalize' },
  date: { color: colors.principal, fontSize: '12px' },
  dateMobile: { color: colors.principal, fontSize: '16px' },
  note: {
    color: colors.principal,
    fontSize: '12px',
  },
  noteMobile: {
    color: colors.principal,
    fontSize: '16px',
    textAlign: 'center',
    width: '100%',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
  },
  actionContainerMobile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '20px',
  },
  viewIcon: { ...icon, boxSize: 4 },
  noteIcon: { ...icon, boxSize: 4 },
  favoriteIcon: { ...icon, boxSize: 4 },
  favoriteIconMobile: { ...icon, boxSize: 6 },
  viewIconMobile: { ...icon, boxSize: 6 },
  noteIconMobile: { ...icon, boxSize: 6 },
}
