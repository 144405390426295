import { colors } from '../../utils/colors'

export const newJobStyles = {
  container: { width: '100%', padding: '1.1% 4.3% 4.3% 4%' },
  heading: {
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '35.68px',
    color: colors.principal,
    marginBottom: '10px',
  },
  comment: { fontSize: '14px', color: '#000' },
  item: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 63px 25px 36px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  itemButton: {},
  itemIcon: { fontSize: '40px', color: colors.principal },
  itemTitle: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '35.68px',
    marginBottom: '10px',
  },
  inputContainer: {
    paddingBottom: '18px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px 3.3%',
  },
  input: { width: '31%' },
  input2: { width: '44%' },
  input3: { width: '24%' },
  multiple: { width: '31%' },
  checkboxDisable: { display: 'flex' },
  checkbox: {
    margin: '0 0 0 8px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '50px',
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 16px',
    color: colors.secundary,
  },
  draftButton: {
    backgroundColor: colors.white,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 16px',
    color: colors.secundary,
    border: `1px solid ${colors.secundary}`,
  },
  saveButton: {
    backgroundColor: colors.principal,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 16px',
    color: colors.white,
    border: `1px solid ${colors.principal}`,
  },
  hover: {
    border: `1px solid ${colors.secundary}`,
    backgroundColor: colors.secundary,
    color: colors.white,
  },
  modalStyles: {
    container: { width: '22%' },
    containerMobile: { width: '60%' },
    title: { textAlign: 'center', marginTop: '7%' },
    buttonBox: { justifyContent: 'center' },
  },
  textarea: { fontSize: '14px' },
  loading: {
    width: '100%',
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerMobile: { width: '100%', padding: '6% 4.3% 8% 4%' },
  itemMobile: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 24px 25px 24px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  itemTitleMobile: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '25px',
    marginBottom: '20px',
  },
  commentMobile: { marginTop: '10px', fontSize: '10px', lineHeight: '15px', color: '#000' },
  inputContainerMobile: {
    paddingBottom: '18px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
  },
  inputMobile: { width: '100%', fontSize: '14px' },
  input2Mobile: { width: '100%', fontSize: '14px' },
  input3Mobile: { width: '100%', fontSize: '14px' },
  checkboxDisableMobile: { display: 'flex', flexDirection: 'column', rowGap: '10px' },
  checkboxMobile: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  checkboxFieldMobile: { width: '15px', height: '15px' },
  textFieldMobile: { fontSize: '14px' },
  dateFieldMobile: { fontSize: '14px' },
  buttonBoxMobile: {
    display: 'flex',
    flexDirection: 'column-reverse',
    rowGap: '20px',
  },
  cancelButtonMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '8px 16px',
    color: colors.institutionalRed,
    border: `1px solid ${colors.institutionalRed}`,
  },
  draftButtonMobile: {
    backgroundColor: colors.white,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 16px',
    color: colors.secundary,
    border: `1px solid ${colors.secundary}`,
  },
  saveButtonMobile: {
    backgroundColor: colors.principal,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 16px',
    color: colors.white,
    border: `1px solid ${colors.principal}`,
  },
  optionalItemTitle: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '35.68px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionalItemTitleMobile: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '25px',
    marginBottom: '20px',
    display: 'flex',
  },
  chevronIcon: { cursor: 'pointer', boxSize: '30px' },
}
