import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import React from 'react'

interface ResumeProps {
  isOpen: boolean
  onClose: () => void
  text: string
  title: string
  onConfirm: any
  buttonOne: any
  buttonTwo: any
}

const ResumeModal: React.FC<ResumeProps> = ({
  isOpen,
  onClose,
  text,
  title,
  buttonOne,
  buttonTwo,
  onConfirm,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const name = e.currentTarget.name
    if (name === buttonOne.toLowerCase()) {
      onConfirm(buttonOne.toLowerCase())
      onClose()
    } else if (name === buttonTwo.toLowerCase()) {
      onConfirm(buttonTwo.toLowerCase())
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{text}</ModalBody>

        <ModalFooter>
          <Button name={buttonOne.toLowerCase()} colorScheme='blue' mr={3} onClick={handleClick}>
            {buttonOne}
          </Button>
          <Button name={buttonTwo.toLowerCase()} variant='ghost' onClick={handleClick}>
            {buttonTwo}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ResumeModal
