import * as Yup from 'yup'

export default Yup.object().shape({
  name: Yup.string().required('Ingrese un nombre'),
  lastName: Yup.string().required('Ingrese un apellido'),
  dni: Yup.string()
    .matches(/^\d{8}$/, 'DNI inválido')
    .required('Ingrese un DNI de 8 dígitos'),
  email: Yup.string().email('Email no válido').required('Ingrese un email'),
  password: Yup.string()
  .matches(/^(?=.*[A-Za-z]{2,})(?=.*\d{2,})[A-Za-z\d]{14,}$/, {
      message:
        'Debe incluir al menos 2 letras, 2 números y longitud minima de 14 caracteres',
    })
    .required('Contraseña requerida'),
  rePassword: Yup.string().required('Repetir contraseña requerida'),
})
