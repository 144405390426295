import { useEffect, useState } from 'react'
import {
  Card,
  Box,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  useMediaQuery,
  Text,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './style.css'
// este sera datos del propietario
const EducationResume = () => {
  const userInformation = useSelector((state: any) => state.user.userInformation ?? '')

  const { id } = useParams()

  const institutoFilter = userInformation?.Institutions.filter((item: any) => item.id === id)
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const [datos, setDatos] = useState({
    razonsocial: '-',
    cuil: '-',
    name: '-',
  })

  useEffect(() => {
    userInformation === undefined || userInformation === null || userInformation === ''
      ? null
      : setearDatos()
  }, [userInformation, id])

  const setearDatos = () => {
    setDatos({
      ...datos,
      razonsocial:
        institutoFilter &&
        institutoFilter.length > 0 &&
        institutoFilter[0]?.businessName !== '' &&
        institutoFilter[0]?.businessName !== null
          ? institutoFilter[0]?.businessName
          : '-',
      cuil:
        institutoFilter &&
        institutoFilter.length > 0 &&
        institutoFilter[0]?.cuit !== '' &&
        institutoFilter[0]?.cuit !== null
          ? institutoFilter[0]?.cuit
          : '-',
      name:
        institutoFilter &&
        institutoFilter.length > 0 &&
        institutoFilter[0]?.name !== '' &&
        institutoFilter[0]?.cuit !== null
          ? institutoFilter[0]?.name
          : '-',
    })
  }

  return (
    <Card>
      <CardBody>
        <Stack divider={<StackDivider />} spacing='4'>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Razón social :
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.razonsocial}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              CUIT del propietario :
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.cuil}
            </Text>
          </Box>
          <Box
            display={isMobile ? 'block' : 'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Heading fontSize='12px' color={'#004876'} fontWeight='bold' textTransform='uppercase'>
              Nombre de la institución :
            </Heading>
            <Text fontSize='12px' ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0}>
              {datos.name}
            </Text>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  )
}

export default EducationResume
