import { FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface ProtectedProps {
  canActivate: boolean
  redirectPath?: string
}

const ProtectedLayout: FC<ProtectedProps> = ({ canActivate, redirectPath = '/' }) => {
  if (!canActivate) {
    return <Navigate to={redirectPath} replace />
  }
  return <Outlet />
}
export default ProtectedLayout
