import { user } from './../user/_duck'
import { notifications } from './_ducks'
import * as types from './types'
import {
  ModuleTypeEditInstitutionEmailNotifications,
  ModuleTypeEditUserEmailNotifications,
  ModuleTypeEmailNotifications,
} from './_definitions'
import { PayloadAction } from '../../types'

const initialModuleStateEmailNotifications: ModuleTypeEmailNotifications = {
  hasErrMessage: false,
  userEmailNotification: null,
}

const initialModuleStateEditUserEmailNotifications: ModuleTypeEditUserEmailNotifications = {
  hasErrMessage: false,
  userEmailNotificationSuccess: null,
  userEmailNotificationStatus: null,
}

const initialModuleStateEditInstitutionEmailNotifications: ModuleTypeEditInstitutionEmailNotifications =
  {
    hasErrMessage: false,
    institutionEmailNotificationSuccess: null,
    institutionEmailNotificationStatus: null,
  }

const reducer = notifications.createReducer(
  {
    [types.USER_EMAIL_NOTIFICATION_GET_REQUEST]: (
      state: ModuleTypeEmailNotifications,
    ): ModuleTypeEmailNotifications => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.USER_EMAIL_NOTIFICATION_GET_SUCCESS]: (
      state: ModuleTypeEmailNotifications,
      { payload }: PayloadAction,
    ): ModuleTypeEmailNotifications => ({
      ...state,
      userEmailNotification: payload,
    }),
    [types.USER_EMAIL_NOTIFICATION_GET_FAILURE]: (
      state: ModuleTypeEmailNotifications,
    ): ModuleTypeEmailNotifications => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.USER_EMAIL_NOTIFICATION_GET_CLEAN]: (
      state: ModuleTypeEmailNotifications,
    ): ModuleTypeEmailNotifications => ({
      ...state,
      hasErrMessage: false,
      userEmailNotification: null,
    }),
    [types.EDIT_USER_EMAIL_NOTIFICATION_POST_REQUEST]: (
      state: ModuleTypeEditUserEmailNotifications,
    ): ModuleTypeEditUserEmailNotifications => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.EDIT_USER_EMAIL_NOTIFICATION_POST_SUCCESS]: (
      state: ModuleTypeEditUserEmailNotifications,
      { payload }: PayloadAction,
    ): ModuleTypeEditUserEmailNotifications => ({
      ...state,
      userEmailNotificationStatus: payload,
      userEmailNotificationSuccess: true,
    }),
    [types.EDIT_USER_EMAIL_NOTIFICATION_POST_FAILURE]: (
      state: ModuleTypeEditUserEmailNotifications,
      { payload }: PayloadAction,
    ): ModuleTypeEditUserEmailNotifications => ({
      ...state,
      hasErrMessage: true,
      userEmailNotificationStatus: payload,
      userEmailNotificationSuccess: false,
    }),
    [types.EDIT_USER_EMAIL_NOTIFICATION_POST_CLEAN]: (
      state: ModuleTypeEditUserEmailNotifications,
    ): ModuleTypeEditUserEmailNotifications => ({
      ...state,
      hasErrMessage: false,
      userEmailNotificationStatus: null,
      userEmailNotificationSuccess: null,
    }),
    [types.EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_REQUEST]: (
      state: ModuleTypeEditInstitutionEmailNotifications,
    ): ModuleTypeEditInstitutionEmailNotifications => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_SUCCESS]: (
      state: ModuleTypeEditInstitutionEmailNotifications,
      { payload }: PayloadAction,
    ): ModuleTypeEditInstitutionEmailNotifications => ({
      ...state,
      institutionEmailNotificationStatus: payload,
      institutionEmailNotificationSuccess: true,
    }),
    [types.EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_FAILURE]: (
      state: ModuleTypeEditInstitutionEmailNotifications,
      { payload }: PayloadAction,
    ): ModuleTypeEditInstitutionEmailNotifications => ({
      ...state,
      hasErrMessage: true,
      institutionEmailNotificationStatus: payload,
      institutionEmailNotificationSuccess: false,
    }),
    [types.EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_CLEAN]: (
      state: ModuleTypeEditInstitutionEmailNotifications,
    ): ModuleTypeEditInstitutionEmailNotifications => ({
      ...state,
      hasErrMessage: false,
      institutionEmailNotificationStatus: null,
      institutionEmailNotificationSuccess: null,
    }),
  },
  {
    initialModuleStateEmailNotifications,
    initialModuleStateEditUserEmailNotifications,
    initialModuleStateEditInstitutionEmailNotifications,
  },
)

export default reducer
