import { Box, Heading, UseToastOptions, useMediaQuery, useToast } from '@chakra-ui/react'
import { applicationsHandlerStyles } from './styles'
import ApplicationsHandlerFilter from './ApplicationsHandlerFilter'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  institutionsCompleteClean,
  institutionsCompleteRequest,
} from '../../state/modules/ads/actions'
import {
  downloadApplicationsSheetClean,
  handleApplicationsListClean,
  handleApplicationsListRequest,
} from '../../state/modules/jobApplications/actions'
import ApplicationsHandlerTable from './ApplicationsHandlerTable'
import { resumeDownloadClean } from '../../state/modules/user/actions'

const ApplicationsHandler = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const userId = useSelector((state: any) => state?.user?.userInformation?.id)
  const jobApplications = useSelector((state: any) => state?.jobApplications)
  const user = useSelector((state: any) => state?.user)
  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000
  const [filters, setFilters] = useState(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [resumeName, setResumeName] = useState<string>('')
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const toastMessages: UseToastOptions[] = [
    {
      description: 'Perfil descargado correctamente',
      status: 'success',
      duration: toastDuration,
    },
    {
      description: 'Ocurrio un error',
      status: 'error',
      duration: toastDuration,
    },
  ]

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (userId !== null && userId !== undefined) {
      dispatch(institutionsCompleteRequest({ userId, token, all: true }))

      return () => {
        dispatch(institutionsCompleteClean())
      }
    }
  }, [userId])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (filters && filters !== null) {
      dispatch(handleApplicationsListRequest({ token, page: currentPage, size: 10, filters }))
    }
    return () => {
      if (filters && filters !== null) dispatch(handleApplicationsListClean())
    }
  }, [filters, currentPage])

  useEffect(() => {
    if (
      jobApplications?.downloadApplicationsSheetSuccess &&
      jobApplications?.downloadApplicationsSheetSuccess !== null
    ) {
      const link = document.createElement('a')
      const myBlob = jobApplications?.downloadApplicationsSheetStatus
      link.href = URL.createObjectURL(myBlob)
      link.download = `Export_postulaciones_${new Date().toLocaleDateString('en-US')}.xlsx`
      link.click()
      toast({
        description: 'Planilla generada correctamente',
        status: 'success',
        duration: toastDuration,
      })

      dispatch(downloadApplicationsSheetClean())
      setIsDownloading(false)
    }

    if (jobApplications?.downloadApplicationsSheetSuccess === false) {
      toast({
        description: jobApplications?.downloadApplicationsSheetStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(downloadApplicationsSheetClean())
      setIsDownloading(false)
    }
  }, [jobApplications?.downloadApplicationsSheetSuccess])

  useEffect(() => {
    if (user?.downloadResumeSuccess && user?.userResume) {
      const link = document.createElement('a')
      link.href = user.userResume
      link.download = `CV_${resumeName}.pdf`
      link.click()
      toast(toastMessages[0])

      dispatch(resumeDownloadClean())
      setResumeName('')
    }

    if (user?.downloadResumeSuccess === false) {
      toast(toastMessages[1])
      dispatch(resumeDownloadClean())
      setResumeName('')
    }
  }, [user])
  return (
    <Box
      sx={
        isMobile ? applicationsHandlerStyles.containerMobile : applicationsHandlerStyles.container
      }
    >
      <Heading sx={applicationsHandlerStyles.heading}>Ver postulantes</Heading>
      <Box
        sx={
          isMobile
            ? applicationsHandlerStyles.applicationsMobile
            : applicationsHandlerStyles.applications
        }
      >
        <ApplicationsHandlerFilter
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
          isDownloading={isDownloading}
          setIsDownloading={setIsDownloading}
        />
        <ApplicationsHandlerTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setResumeName={setResumeName}
          filters={filters}
        />
      </Box>
    </Box>
  )
}

export default ApplicationsHandler
