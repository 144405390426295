import * as types from '../types'

import { call, put, takeLatest } from 'redux-saga/effects'
import {
  contactDataClean,
  contactDataFailure,
  contactDataSuccess,
  departmentsFailure,
  departmentsSuccess,
  educationClean,
  educationDeleteClean,
  educationDeleteFailure,
  educationDeleteSuccess,
  educationEditClean,
  educationEditFailure,
  educationEditSuccess,
  educationFailure,
  educationSuccess,
  employeesQuantityFailure,
  employeesQuantitySuccess,
  fileDeleteFailure,
  fileDeleteSuccess,
  generalInformationClean,
  generalInformationFailure,
  generalInformationSuccess,
  institutionPhotoFailure,
  institutionPhotoSuccess,
  institutionTypesFailure,
  institutionTypesSuccess,
  institutionalDataClean,
  institutionalDataFailure,
  institutionalDataSuccess,
  jobsExperienceClean,
  jobsExperienceDeleteFailure,
  jobsExperienceDeleteSuccess,
  jobsExperienceEditFailure,
  jobsExperienceEditSuccess,
  jobsExperienceFailure,
  jobsExperienceSuccess,
  languageDeleteFailure,
  languageDeleteSuccess,
  languageEditClean,
  languageEditFailure,
  languageEditSuccess,
  languagesFailure,
  languagesSuccess,
  levelmodalitiesFailure,
  levelmodalitiesSuccess,
  levelsFailure,
  levelsSuccess,
  localitiesFailure,
  localitiesSuccess,
  nationalitiesFailure,
  nationalitiesSuccess,
  ownDataFailure,
  ownDataSuccess,
  personalDataClean,
  personalDataFailure,
  personalDataSuccess,
  preferenceDeleteFailure,
  preferenceDeleteSuccess,
  preferenceEditClean,
  preferenceEditFailure,
  preferenceEditSuccess,
  preferencesFailure,
  preferencesSuccess,
  privateModeFailure,
  privateModeSuccess,
  provincesFailure,
  provincesSuccess,
  regionsFailure,
  regionsSuccess,
  registerInstitutionProfileFailure,
  registerInstitutionProfileSuccess,
  resumeDownloadFailure,
  resumeDownloadSuccess,
  socialSkillDeleteFailure,
  socialSkillDeleteSuccess,
  socialSkillsFailure,
  socialSkillsSuccess,
  studentsQuantityFailure,
  studentsQuantitySuccess,
  suplenciesFailure,
  suplenciesSuccess,
  termsAndConditionsFailure,
  termsAndConditionsSuccess,
  userInformationFailure,
  userInformationSuccess,
  validationMailFailure,
  validationMailSuccess,
} from '../actions'
import {
  deleteEducation,
  deleteFileService,
  deleteJobsExperience,
  deleteLanguage,
  deletePreference,
  deleteSocialSkill,
  getDepartmentsService,
  getEmployeesQuantityService,
  getInstitutionTypesService,
  getLanguagesService,
  getLevelModalitiesService,
  getLevelsService,
  getLocalitiesService,
  getNationalitiesService,
  getPrivateModeService,
  getProvincesService,
  getRegionsService,
  getResumeDownload,
  getSocialSkillsService,
  getStudentsQuantityService,
  getSuplenciesService,
  getTermsAndConditions,
  getUserInformationService,
  postContactData,
  postEducation,
  postInstitutionProfilePhotoService,
  postInstitutionalData,
  postJobsExperiences,
  postOwnData,
  postPersonalData,
  postPreferences,
  postRegisterInstitutionProfile,
  postValidationMail,
  postregisterGeneralInformation,
  putEducation,
  putJobsExperience,
  putLanguage,
  putPreference,
} from '../services'

import { SagaIterator } from 'redux-saga'

function* getUserInformationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getUserInformationService, payload)
    yield put(userInformationSuccess(data))
  } catch (error) {
    yield put(userInformationFailure(error))
  }
}

export function* getUserInformationWatcher(): SagaIterator {
  yield takeLatest(types.USER_INFORMATION_GET_REQUEST, getUserInformationWorker)
}

function* getResumeDownloadWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getResumeDownload, payload)
    const pdfUrl = URL.createObjectURL(data)
    yield put(resumeDownloadSuccess(pdfUrl))
  } catch (error) {
    yield put(resumeDownloadFailure(error))
  }
}

export function* getResumeDownloadWatcher(): SagaIterator {
  yield takeLatest(types.RESUME_DOWNLOAD_REQUEST, getResumeDownloadWorker)
}

function* postGeneralInformationWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(postregisterGeneralInformation, values.payload)
    yield put(generalInformationSuccess(data))
  } catch (error) {
    yield put(generalInformationFailure(error))
  }
}
export function* postGeneralInformationWatcher(): SagaIterator {
  yield takeLatest(types.GENERAL_INFORMATION_REQUEST, postGeneralInformationWorker)
}

function* postPersonalDataWorker(values: any): SagaIterator {
  try {
    yield put(personalDataClean())
    const { data } = yield call(postPersonalData, values.payload)
    yield put(personalDataSuccess(data))
  } catch (error) {
    yield put(personalDataFailure(error))
  }
}
export function* postPersonalDataWatcher(): SagaIterator {
  yield takeLatest(types.PERSONAL_DATA_REQUEST, postPersonalDataWorker)
}

function* postEducationWorker(values: any): SagaIterator {
  try {
    yield put(educationClean())
    const { data } = yield call(postEducation, values.payload)
    yield put(educationSuccess(data))
  } catch (error) {
    yield put(educationFailure(error))
  }
}
export function* postEducationWatcher(): SagaIterator {
  yield takeLatest(types.EDUCATION_REQUEST, postEducationWorker)
}

function* postJobsExperiencesWorker(values: any): SagaIterator {
  try {
    yield put(jobsExperienceClean())
    const { data } = yield call(postJobsExperiences, values.payload)
    yield put(jobsExperienceSuccess(data))
  } catch (error) {
    yield put(jobsExperienceFailure(error))
  }
}
export function* postJobsExperiencesWatcher(): SagaIterator {
  yield takeLatest(types.JOBS_EXPERIENCE_REQUEST, postJobsExperiencesWorker)
}

function* postPreferencesWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(postPreferences, values.payload)
    yield put(preferencesSuccess(data))
  } catch (error) {
    yield put(preferencesFailure(error))
  }
}
export function* postPreferencesWatcher(): SagaIterator {
  yield takeLatest(types.PREFERENCE_REQUEST, postPreferencesWorker)
}

function* registerInstitutionProfileWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(postRegisterInstitutionProfile, values.payload)
    yield put(registerInstitutionProfileSuccess(data))
  } catch (error) {
    yield put(registerInstitutionProfileFailure(error))
  }
}
export function* registerInstitutionProfileWatcher(): SagaIterator {
  yield takeLatest(
    types.REGISTER_INSTITUTION_PROFILE_POST_REQUEST,
    registerInstitutionProfileWorker,
  )
}

function* postValidateEmailWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(postValidationMail, values.payload)
    yield put(validationMailSuccess(data))
  } catch (error) {
    yield put(validationMailFailure(error))
  }
}
export function* postValidateEmailWatcher(): SagaIterator {
  yield takeLatest(types.VALIDATION_MAIL_REQUEST, postValidateEmailWorker)
}

function* postEditEducationWorker(values: any): SagaIterator {
  try {
    yield put(educationEditClean())
    const { data } = yield call(putEducation, values.payload)
    yield put(educationEditSuccess(data))
  } catch (error) {
    yield put(educationEditFailure(error))
  }
}
export function* postEditEducationWatcher(): SagaIterator {
  yield takeLatest(types.EDUCATION_EDIT_REQUEST, postEditEducationWorker)
}
function* postDeleteEducationWorker(values: any): SagaIterator {
  try {
    yield put(educationDeleteClean())
    const { data } = yield call(deleteEducation, values.payload)
    yield put(educationDeleteSuccess(data))
  } catch (error) {
    yield put(educationDeleteFailure(error))
  }
}

export function* postDeleteEducationWatcher(): SagaIterator {
  yield takeLatest(types.EDUCATION_DELETE_REQUEST, postDeleteEducationWorker)
}
//
function* postEditLanguageWorker(values: any): SagaIterator {
  try {
    yield put(languageEditClean())
    const { data } = yield call(putLanguage, values.payload)
    yield put(languageEditSuccess(data))
  } catch (error) {
    yield put(languageEditFailure(error))
  }
}
export function* postEditLanguageWatcher(): SagaIterator {
  yield takeLatest(types.LANGUAGE_EDIT_REQUEST, postEditLanguageWorker)
}
function* postDeleteLanguageWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(deleteLanguage, values.payload)
    yield put(languageDeleteSuccess(data))
  } catch (error) {
    yield put(languageDeleteFailure(error))
  }
}

export function* postDeleteLanguageWatcher(): SagaIterator {
  yield takeLatest(types.LANGUAGE_DELETE_REQUEST, postDeleteLanguageWorker)
}

// jobs and prefe

function* postEditJobsExperienceWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(putJobsExperience, values.payload)
    yield put(jobsExperienceEditSuccess(data))
  } catch (error) {
    yield put(jobsExperienceEditFailure(error))
  }
}
export function* postEditJobsExperienceWatcher(): SagaIterator {
  yield takeLatest(types.JOBS_EXPERIENCE_EDIT_REQUEST, postEditJobsExperienceWorker)
}
function* postDeleteJobsExperienceWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(deleteJobsExperience, values.payload)
    yield put(jobsExperienceDeleteSuccess(data))
  } catch (error) {
    yield put(jobsExperienceDeleteFailure(error))
  }
}

export function* postDeleteJobsExperienceWatcher(): SagaIterator {
  yield takeLatest(types.JOBS_EXPERIENCE_DELETE_REQUEST, postDeleteJobsExperienceWorker)
}
// para delete social skill
function* postDeleteSocialSkillWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(deleteSocialSkill, values.payload)
    yield put(socialSkillDeleteSuccess(data))
  } catch (error) {
    yield put(socialSkillDeleteFailure(error))
  }
}

export function* postDeleteSocialSkillWatcher(): SagaIterator {
  yield takeLatest(types.SOCIAL_SKILL_DELETE_REQUEST, postDeleteSocialSkillWorker)
}
//
function* postEditPreferenceWorker(values: any): SagaIterator {
  try {
    yield put(preferenceEditClean())
    const { data } = yield call(putPreference, values.payload)
    yield put(preferenceEditSuccess(data))
  } catch (error) {
    yield put(preferenceEditFailure(error))
  }
}
export function* postEditPreferenceWatcher(): SagaIterator {
  yield takeLatest(types.PREFERENCE_EDIT_REQUEST, postEditPreferenceWorker)
}
function* postDeletePreferenceWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(deletePreference, values.payload)
    yield put(preferenceDeleteSuccess(data))
  } catch (error) {
    yield put(preferenceDeleteFailure(error))
  }
}

export function* postDeletePreferenceWatcher(): SagaIterator {
  yield takeLatest(types.PREFERENCE_DELETE_REQUEST, postDeletePreferenceWorker)
}

// para insti

function* postInstitutionalDataWorker(values: any): SagaIterator {
  try {
    yield put(institutionalDataClean())
    const { data } = yield call(postInstitutionalData, values.payload)
    yield put(institutionalDataSuccess(data))
  } catch (error) {
    yield put(institutionalDataFailure(error))
  }
}
export function* postInstitutionalDataWatcher(): SagaIterator {
  yield takeLatest(types.INSTITUTIONAL_DATA_REQUEST, postInstitutionalDataWorker)
}

function* postOwnDataWorker(values: any): SagaIterator {
  try {
    const { data } = yield call(postOwnData, values.payload)
    yield put(ownDataSuccess(data))
  } catch (error) {
    yield put(ownDataFailure(error))
  }
}
export function* postOwndataWatcher(): SagaIterator {
  yield takeLatest(types.OWN_DATA_REQUEST, postOwnDataWorker)
}

function* postContactDataWorker(values: any): SagaIterator {
  try {
    yield put(contactDataClean())
    const { data } = yield call(postContactData, values.payload)
    yield put(contactDataSuccess(data))
  } catch (error) {
    yield put(contactDataFailure(error))
  }
}
export function* postContactDataWatcher(): SagaIterator {
  yield takeLatest(types.CONTACT_DATA_REQUEST, postContactDataWorker)
}

function* getProvincesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getProvincesService, payload)
    yield put(provincesSuccess(data))
  } catch (error) {
    yield put(provincesFailure(error))
  }
}

export function* getProvincesWatcher(): SagaIterator {
  yield takeLatest(types.PROVINCES_GET_REQUEST, getProvincesWorker)
}

function* getDepartmentsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getDepartmentsService, payload)
    yield put(departmentsSuccess(data))
  } catch (error) {
    yield put(departmentsFailure(error))
  }
}

export function* getDepartmentsWatcher(): SagaIterator {
  yield takeLatest(types.DEPARTMENTS_GET_REQUEST, getDepartmentsWorker)
}

function* getLocalitiesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getLocalitiesService, payload)
    yield put(localitiesSuccess(data))
  } catch (error) {
    yield put(localitiesFailure(error))
  }
}

export function* getLocalitiesWatcher(): SagaIterator {
  yield takeLatest(types.LOCALITIES_GET_REQUEST, getLocalitiesWorker)
}

function* getRegionsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getRegionsService, payload)
    yield put(regionsSuccess(data))
  } catch (error) {
    yield put(regionsFailure(error))
  }
}

export function* getRegionsWatcher(): SagaIterator {
  yield takeLatest(types.REGIONS_GET_REQUEST, getRegionsWorker)
}

function* getNationalitiesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getNationalitiesService, payload)
    yield put(nationalitiesSuccess(data))
  } catch (error) {
    yield put(nationalitiesFailure(error))
  }
}

export function* getNationalitiesWatcher(): SagaIterator {
  yield takeLatest(types.NATIONALITIES_GET_REQUEST, getNationalitiesWorker)
}

function* getPrivateModeWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getPrivateModeService, payload)
    yield put(privateModeSuccess(data))
  } catch (error) {
    yield put(privateModeFailure(error))
  }
}

export function* getPrivateModeWatcher(): SagaIterator {
  yield takeLatest(types.PRIVATE_MODE_GET_REQUEST, getPrivateModeWorker)
}

function* postInstitutionPhotoWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postInstitutionProfilePhotoService, payload)

    yield put(institutionPhotoSuccess(data))
  } catch (error) {
    yield put(institutionPhotoFailure(error))
  }
}

export function* postInstitutionPhotoWatcher(): SagaIterator {
  yield takeLatest(types.INSTITUTION_PHOTO_POST_REQUEST, postInstitutionPhotoWorker)
}

function* getTermsAndConditionsWorker(): SagaIterator {
  try {
    const { data } = yield call(getTermsAndConditions)
    yield put(termsAndConditionsSuccess(data))
  } catch (error) {
    yield put(termsAndConditionsFailure(error))
  }
}

export function* getTermsAndConditionsWatcher(): SagaIterator {
  yield takeLatest(types.TERMS_AND_CONDITIONS_GET_REQUEST, getTermsAndConditionsWorker)
}

function* deleteFileWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(deleteFileService, payload)
    yield put(fileDeleteSuccess(data))
  } catch (error) {
    yield put(fileDeleteFailure(error))
  }
}

export function* deleteFileWatcher(): SagaIterator {
  yield takeLatest(types.FILE_DELETE_REQUEST, deleteFileWorker)
}

function* getLanguagesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getLanguagesService, payload)
    yield put(languagesSuccess(data))
  } catch (error) {
    yield put(languagesFailure(error))
  }
}

export function* getLanguagesWatcher(): SagaIterator {
  yield takeLatest(types.LANGUAGES_LIST_GET_REQUEST, getLanguagesWorker)
}

function* getEmployeesQuantityWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getEmployeesQuantityService, payload)
    yield put(employeesQuantitySuccess(data))
  } catch (error) {
    yield put(employeesQuantityFailure(error))
  }
}

export function* getEmployeesQuantityWatcher(): SagaIterator {
  yield takeLatest(types.EMPLOYEES_QUANTITY_LIST_GET_REQUEST, getEmployeesQuantityWorker)
}

function* getStudentsQuantityWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getStudentsQuantityService, payload)
    yield put(studentsQuantitySuccess(data))
  } catch (error) {
    yield put(studentsQuantityFailure(error))
  }
}

export function* getStudentsQuantityWatcher(): SagaIterator {
  yield takeLatest(types.STUDENTS_QUANTITY_LIST_GET_REQUEST, getStudentsQuantityWorker)
}
function* getLevelsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getLevelsService, payload)
    yield put(levelsSuccess(data))
  } catch (error) {
    yield put(levelsFailure(error))
  }
}

export function* getLevelsWatcher(): SagaIterator {
  yield takeLatest(types.LEVELS_LIST_GET_REQUEST, getLevelsWorker)
}

function* getLevelModalitiesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getLevelModalitiesService, payload)
    yield put(levelmodalitiesSuccess(data))
  } catch (error) {
    yield put(levelmodalitiesFailure(error))
  }
}

export function* getLevelModalitiesWatcher(): SagaIterator {
  yield takeLatest(types.LEVEL_MODALITIES_LIST_GET_REQUEST, getLevelModalitiesWorker)
}

function* getInstitutionTypesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getInstitutionTypesService, payload)
    yield put(institutionTypesSuccess(data))
  } catch (error) {
    yield put(institutionTypesFailure(error))
  }
}

export function* getInstitutionTypesWatcher(): SagaIterator {
  yield takeLatest(types.INSTITUTION_TYPES_LIST_GET_REQUEST, getInstitutionTypesWorker)
}

function* getSocialSkillsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getSocialSkillsService, payload)
    yield put(socialSkillsSuccess(data))
  } catch (error) {
    yield put(socialSkillsFailure(error))
  }
}

export function* getSocialSkillsWatcher(): SagaIterator {
  yield takeLatest(types.SOCIAL_SKILLS_LIST_GET_REQUEST, getSocialSkillsWorker)
}

function* getSuplenciesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getSuplenciesService, payload)
    yield put(suplenciesSuccess(data))
  } catch (error) {
    yield put(suplenciesFailure(error))
  }
}

export function* getSuplenciesWatcher(): SagaIterator {
  yield takeLatest(types.SUPLENCIES_LIST_GET_REQUEST, getSuplenciesWorker)
}

export const sagas = [
  getUserInformationWatcher,
  getResumeDownloadWatcher,
  postGeneralInformationWatcher,
  postPersonalDataWatcher,
  postEducationWatcher,
  postJobsExperiencesWatcher,
  postPreferencesWatcher,
  registerInstitutionProfileWatcher,
  postValidateEmailWatcher,
  postEditEducationWatcher,
  postDeleteEducationWatcher,
  postEditLanguageWatcher,
  postDeleteLanguageWatcher,
  postEditJobsExperienceWatcher,
  postDeleteJobsExperienceWatcher,
  postDeleteSocialSkillWatcher,
  postEditPreferenceWatcher,
  postDeletePreferenceWatcher,
  postInstitutionalDataWatcher,
  postOwndataWatcher,
  postContactDataWatcher,
  getProvincesWatcher,
  getDepartmentsWatcher,
  getLocalitiesWatcher,
  getRegionsWatcher,
  getNationalitiesWatcher,
  getPrivateModeWatcher,
  postInstitutionPhotoWatcher,
  getTermsAndConditionsWatcher,
  deleteFileWatcher,
  getLanguagesWatcher,
  getEmployeesQuantityWatcher,
  getStudentsQuantityWatcher,
  getLevelsWatcher,
  getLevelModalitiesWatcher,
  getInstitutionTypesWatcher,
  getSocialSkillsWatcher,
  getSuplenciesWatcher,
]
