import React, { FC, useEffect, useState } from 'react'
import { jobApplicationsStyles } from './styles'
import { Box, Heading, Text, useMediaQuery, useToast } from '@chakra-ui/react'
import JobApplicationsFilter from './JobApplicationsFilter'
import OrderByInput from '../OrderByInput'
import { orderOptions } from '../../utils/jobApplications'
import JobApplicationCard from './JobApplicationCard'
import { useDispatch, useSelector } from 'react-redux'
import {
  applicationsListRequest,
  deleteApplicationClean,
} from '../../state/modules/jobApplications/actions'
import { JobApplicationsListPayload } from '../../state/modules/jobApplications/service/types'
import Loader from '../loader'
import Paginator from '../Paginator'
import { Filter } from './_types'

const JobApplications: FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const applicationsList = useSelector((state: any) => state?.jobApplications?.jobApplicationsList)
  const { deleteApplicationSuccess, deleteApplicationStatus } = useSelector(
    (state: any) => state?.jobApplications,
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [order, setOrder] = useState(orderOptions[0].value)
  const [filter, setFilter] = useState<Filter>({
    referenceNumber: '',
    applicationProvince: [],
    applicationDepartment: [],
    applicationLocality: [],
    positionType: '',
    position: [],
    applicationDateFrom: '',
    applicationDateTo: '',
  })
  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    const criteria = order.split('_')[0]

    const field = order.split('_')[1]

    const filterValues = {
      referenceNumber: filter.referenceNumber,
      zone: {
        provinceId: filter.applicationProvince,
        departmentId: filter.applicationDepartment,
        localityId: filter.applicationLocality,
      },
      positionType: filter.positionType,
      jobPosition: filter.position,
      applicationDateFrom: filter.applicationDateFrom,
      applicationDateTo: filter.applicationDateTo,
    }

    const payload: JobApplicationsListPayload = {
      orderBy: { criteria, field },
      token,
      filters: filterValues,
      page: currentPage,
      size: 10,
    }

    dispatch(applicationsListRequest(payload))
  }, [order, filter, currentPage, deleteApplicationSuccess === true])

  useEffect(() => {
    if (deleteApplicationSuccess !== undefined && deleteApplicationSuccess !== null) {
      toast({
        description:
          deleteApplicationSuccess === false
            ? deleteApplicationStatus?.response?.data?.message
            : deleteApplicationStatus?.message,
        status: deleteApplicationSuccess === false ? 'error' : 'success',
        duration: toastDuration,
      })
      dispatch(deleteApplicationClean())
    }
  }, [deleteApplicationSuccess])

  return (
    <Box sx={isMobile ? jobApplicationsStyles.containerMobile : jobApplicationsStyles.container}>
      <Heading sx={jobApplicationsStyles.heading}>Mis postulaciones</Heading>
      <Box
        sx={
          isMobile
            ? jobApplicationsStyles.jobApplicationsSearchMobile
            : jobApplicationsStyles.jobApplicationsSearch
        }
      >
        <JobApplicationsFilter setFilter={setFilter} />
      </Box>
      {applicationsList ? (
        <Box sx={jobApplicationsStyles.applicationsList}>
          {
            <OrderByInput
              options={orderOptions}
              setOrder={setOrder}
              styles={isMobile ? { container: { margin: '0 auto 25px 0' } } : {}}
            />
          }
          <Box sx={isMobile ? jobApplicationsStyles.resultsMobile : jobApplicationsStyles.results}>
            {applicationsList?.count > 0 ? (
              <>
                {applicationsList?.rows.map((app: any) => (
                  <JobApplicationCard key={app.id} application={app} />
                ))}
                <Paginator
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  elements={applicationsList?.rows}
                  elementsLength={applicationsList?.count}
                  elementsPerpage={10}
                />
              </>
            ) : (
              <Box sx={jobApplicationsStyles.noResultsContainer}>
                <Text sx={jobApplicationsStyles.noResults}>
                  *No existen postulaciones que coincidan con la búsqueda
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Loader props={jobApplicationsStyles.loading} />
      )}
    </Box>
  )
}
export default JobApplications
