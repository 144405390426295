import { Box, Button, Icon, UseToastOptions, useToast } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import {
  institutionsClean,
  institutionsRequest,
  newInstitutionClean,
  updateInstitutionDataClean,
} from '../../../state/modules/abm/actions'
import { useDispatch, useSelector } from 'react-redux'

import AbmModal from '../AbmModal'
import { AddIcon } from '@chakra-ui/icons'
import InstitutionsFilter from './InstitutionsFilter'
import { InstitutionsProps } from './_types'
import InstitutionsTable from './InstitutionsTable'
import { institutionalDataClean } from '../../../state/modules/user/actions'
import { institutionsStyles } from './styles'

const Institutions: FC<InstitutionsProps> = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<string>('5')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchFilter, setSearchFilter] = useState<any>(null)
  const [currentInstitution, setCurrentInstitution] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  const abm = useSelector((state: any) => state?.abm)
  const user = useSelector((state: any) => state?.user)

  const toast = useToast()
  const toastDuration = 2000

  const toastMessages: UseToastOptions[] = [
    {
      description: 'Cambios guardados correctamente',
      status: 'success',
      duration: 2000,
    },
    {
      description: 'Ocurrio un error',
      status: 'error',
      duration: 2000,
    },
  ]

  useEffect(() => {
    const filters =
      searchFilter === null
        ? { cue: '', provincialNumber: '', institutionName: '', email: '' }
        : searchFilter
    const token = localStorage.getItem('access_token') ?? ''
    const size = 10
    dispatch(institutionsRequest({ token, currentPage, size, filters }))

    return () => {
      dispatch(institutionsClean())
    }
  }, [searchFilter, currentPage, abm?.newInstitutionSuccess, abm?.updateInstitutionDataSuccess])

  useEffect(() => {
    if (abm?.newInstitutionSuccess === true) {
      toast({
        title: abm?.newInstitutionStatus?.message,
        status: 'success',
        duration: toastDuration,
        isClosable: true,
      })
      dispatch(newInstitutionClean())
      setIsLoading(false)
      setShowModal(false)
    }
    if (abm?.newInstitutionSuccess === false) {
      toast({
        title: abm?.newInstitutionStatus?.response?.data.message,
        status: 'error',
        duration: toastDuration,
        isClosable: true,
      })
      dispatch(newInstitutionClean())
      setIsLoading(false)
    }
  }, [abm?.newInstitutionSuccess])

  useEffect(() => {
    if (
      abm?.updateInstitutionDataSuccess !== null &&
      abm?.updateInstitutionDataSuccess !== undefined
    ) {
      setIsLoading(false)
      abm?.updateInstitutionDataSuccess && setShowModal(false)
      toast(toastMessages[abm?.updateInstitutionDataSuccess ? 0 : 1])

      dispatch(updateInstitutionDataClean())
    }
  }, [abm?.updateInstitutionDataSuccess])

  const handleShow = (option: string) => {
    setShowModal((prev: boolean) => !prev)
    setSelectedOption(option)
  }

  return (
    <Box>
      <AbmModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        selectedOption={selectedOption}
        currentInstitution={currentInstitution}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <InstitutionsFilter setCurrentPage={setCurrentPage} setSearchFilter={setSearchFilter} />
      <Box sx={institutionsStyles.addInstitutionSection}>
        <Button
          sx={institutionsStyles.addInstitution}
          onClick={() => handleShow('5')}
          colorScheme='neutral'
        >
          Agregar institución
          <Icon sx={institutionsStyles.icon} as={AddIcon} />
        </Button>
        <Box sx={institutionsStyles.institutionsQuantity}>
          {abm?.institutionsInformation?.totalInstitutions || '-'}{' '}
          {abm?.institutionsInformation?.totalInstitutions?.length === 1
            ? 'Institución registrada'
            : 'Instituciones registradas'}
        </Box>
      </Box>
      <InstitutionsTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setCurrentInstitution={setCurrentInstitution}
        searchFilter={searchFilter}
        showModal={handleShow}
      />
    </Box>
  )
}

export default Institutions
