import React from 'react'
import {
  Modal,
  ModalOverlay,
  useMediaQuery,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react'
import GeneralInformation from '../../forms-institution/informacion-general'
import InsitutionData from '../../forms-institution/datos-institucionales'
import OwnData from '../../forms-institution/datos-propietario'
import Contact from '../../forms-institution/datos-contacto'

interface ModalResumeProps {
  isOpen: boolean
  onClose: () => void
  selectedOption: number
  setSelectedOption: (value: number) => void // Cambiar el tipo a (value: number) => void
}

const ModalResumeInstitution: React.FC<ModalResumeProps> = ({
  isOpen,
  onClose,
  selectedOption,
}) => {
  const renderComponent = () => {
    switch (selectedOption) {
      case 1:
        return <GeneralInformation /> // perfil de la institucion por ahora solo descripcion breve (en desarrollo)
      case 2:
        return <InsitutionData /> // insitutcion data digamos
      case 3:
        return <OwnData /> // datos del propietario data own
      case 4:
        return <Contact /> // y este sera contactos
      default:
        return null
    }
  }

  const [isMobile] = useMediaQuery('(max-width: 600px)')

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='6xl' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent width={isMobile ? '100%' : '70%'}>
        <ModalCloseButton />
        <ModalBody padding='30px'>{renderComponent()}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalResumeInstitution
