import { put, takeLatest, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'

import * as types from '../types'
import {
  getUserEmailNotificationService,
  postInstitutionEmailNotificationService,
  postUserEmailNotificationService,
} from '../services'
import {
  editInstitutionEmailNotificationFailure,
  editInstitutionEmailNotificationSuccess,
  editUserEmailNotificationFailure,
  editUserEmailNotificationSuccess,
  userEmailNotificationFailure,
  userEmailNotificationSuccess,
} from '../actions'
function* getUserEmailNotificationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getUserEmailNotificationService, payload)

    yield put(userEmailNotificationSuccess(data))
  } catch (error) {
    yield put(userEmailNotificationFailure(error))
  }
}
export function* getUserEmailNotificationWatcher(): SagaIterator {
  yield takeLatest(types.USER_EMAIL_NOTIFICATION_GET_REQUEST, getUserEmailNotificationWorker)
}

function* postUserEmailNotificationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postUserEmailNotificationService, payload)
    yield put(editUserEmailNotificationSuccess(data))
  } catch (error) {
    yield put(editUserEmailNotificationFailure(error))
  }
}
export function* postUserEmailNotificationWatcher(): SagaIterator {
  yield takeLatest(types.EDIT_USER_EMAIL_NOTIFICATION_POST_REQUEST, postUserEmailNotificationWorker)
}

function* postInstitutionEmailNotificationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postInstitutionEmailNotificationService, payload)

    yield put(editInstitutionEmailNotificationSuccess(data))
  } catch (error) {
    yield put(editInstitutionEmailNotificationFailure(error))
  }
}
export function* postInstitutionEmailNotificationWatcher(): SagaIterator {
  yield takeLatest(
    types.EDIT_INSTITUTION_EMAIL_NOTIFICATION_POST_REQUEST,
    postInstitutionEmailNotificationWorker,
  )
}

export const sagas = [
  postUserEmailNotificationWatcher,
  getUserEmailNotificationWatcher,
  postInstitutionEmailNotificationWatcher,
]
