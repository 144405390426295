import { Box, Heading, Img, OrderedList, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { unsubscribeStyles } from './styles'
import logoEduPrivada from '../../assets/images/logo-eduprivada.svg'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  editInstitutionEmailNotificationRequest,
  editUserEmailNotificationRequest,
} from '../../state/modules/notifications/actions'
import Loader from '../../components/loader'

export const Unsubscribe = () => {
  const { userEmailNotificationSuccess, institutionEmailNotificationSuccess } = useSelector(
    (state: any) => state.notifications,
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(useLocation().search)
  const userType = queryParams.get('userType')

  useEffect(() => {
    if (userType === 'user') {
      const id = queryParams.get('userId')
      if (id) dispatch(editUserEmailNotificationRequest({ id, emailSubscribed: false }))
      else navigate('/')
    } else if (userType === 'institution') {
      const id = queryParams.get('institutionId')

      if (id) dispatch(editInstitutionEmailNotificationRequest({ id, emailSubscribed: false }))
      else navigate('/')
    } else {
      navigate('/')
    }
  }, [])

  return (
    <Box sx={unsubscribeStyles.container}>
      {userEmailNotificationSuccess !== undefined ||
        institutionEmailNotificationSuccess !== undefined ? (
        userEmailNotificationSuccess === false || institutionEmailNotificationSuccess === false ? (
          <>
            <Img
              src={logoEduPrivada}
              sx={unsubscribeStyles.logo}
              alt='logo eduprivada'
              width={200}
            />
            <Heading sx={unsubscribeStyles.heading}>Ocurrió un error con la solicitud</Heading>
            {userType === 'user' ? (
              <OrderedList sx={unsubscribeStyles.list}>
                <li>Iniciar sesión en EDUprivada</li>
                <li>
                  Ir a <b>Gestionar notificaciones</b>
                </li>
                <li>
                  Hacer click en <b>Confirmar</b>
                </li>
              </OrderedList>
            ) : (
              <OrderedList sx={unsubscribeStyles.list}>
                <li>Iniciar sesión en EDUprivada</li>
                <li>Ir a Mis Instituciones</li>
                <li>
                  En la solapa de Contactos, marcar el check-box de{' '}
                  <b>Quiero recibir perfiles interesantes</b>
                </li>
                <li>Guardar cambios</li>
              </OrderedList>
            )}{' '}
          </>
        ) : (
          <>
            <Img
              src={logoEduPrivada}
              sx={unsubscribeStyles.logo}
              alt='logo eduprivada'
              width={'20%'}
            />
            <Heading sx={unsubscribeStyles.heading}>Ya no recibirás más estos emails</Heading>
            <Box sx={unsubscribeStyles.textContainer}>
              <Text>Recuerda que si deseas volver a recibir estos emails debes:</Text>
              {userType === 'user' ? (
                <OrderedList sx={unsubscribeStyles.list}>
                  <li>Iniciar sesión en EDUprivada</li>
                  <li>
                    Ir a <b>Gestionar notificaciones</b>
                  </li>
                  <li>
                    Hacer click en <b>Confirmar</b>
                  </li>
                </OrderedList>
              ) : (
                <OrderedList sx={unsubscribeStyles.list}>
                  <li>Iniciar sesión en EDUprivada</li>
                  <li>Ir a Mis Instituciones</li>
                  <li>
                    En la solapa de Contactos, marcar el check-box de{' '}
                    <b>Quiero recibir perfiles interesantes</b>
                  </li>
                  <li>Guardar cambios</li>
                </OrderedList>
              )}
              <Text> Te esperamos.!</Text>
              <Text sx={unsubscribeStyles.copyright}>
                ©2024 - Todos los derechos reservados - EDUPrivada, una plataforma desarrollada por
                AIEPBA
              </Text>
            </Box>
          </>
        )
      ) : (
        <Loader props={unsubscribeStyles.loader} />
      )}
    </Box>
  )
}
