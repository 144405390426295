import React from 'react'
import LandingLayout from '../../layouts/landing'
import { VStack } from '@chakra-ui/react'
import NewJob from '../../components/NewJob'

export const NewJobAd: React.FC = () => {
  return (
    <LandingLayout>
      <VStack>
        <NewJob />
      </VStack>
    </LandingLayout>
  )
}

export default NewJobAd
