import { Box, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'

import { DeleteIcon } from '@chakra-ui/icons'
import { FC } from 'react'
import { preferenceDeleteRequest } from '../../../../state/modules/user/actions'
import { preferenceFields } from '../../../../utils/profile'
import { preferencesTableStyles } from './styles'

const PreferenceEdit: FC = () => {
  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const dispatch = useDispatch()

  const handlePreferenceDelete = (id: number) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(preferenceDeleteRequest({ id, token }))
  }

  return (
    <TableContainer>
      <Table colorScheme='facebook'>
        <Thead>
          <Tr>
            {preferenceFields.map((field) => (
              <Th key={field.order} textTransform='initial' sx={preferencesTableStyles.tHead}>
                {field.name}
              </Th>
            ))}
            <Th textTransform='initial' sx={preferencesTableStyles.tHead}>
              Acción
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {userInformation?.Preferences?.map((item: any) => {
            const curricular = item?.Subjects?.filter((item: any) => item.type === 'CURRICULAR')
            const extraCurricular = item?.Subjects?.filter(
              (item: any) => item.type === 'EXTRA-CURRICULAR',
            )

            return (
              <Tr key={item.id}>
                <Td sx={preferencesTableStyles.tData}>{item?.Positions[0]?.type}</Td>

                <Td sx={preferencesTableStyles.tData}>
                  {item?.Levels?.length === 0
                    ? '-'
                    : item?.Levels?.map((item: any, i: number) => (
                        <Box key={i}>*{item.description}</Box>
                      ))}
                </Td>
                <Td sx={preferencesTableStyles.tData}>
                  {item?.Characteristics?.length === 0
                    ? '-'
                    : item?.Characteristics?.map((item: any, i: number) => (
                        <Box key={i}>*{item.description}</Box>
                      ))}
                </Td>
                <Td sx={preferencesTableStyles.tData}>
                  {item?.Modalities?.length === 0
                    ? '-'
                    : item?.Modalities?.map((item: any, i: number) => (
                        <Box key={i}>*{item.description}</Box>
                      ))}
                </Td>

                <Td sx={preferencesTableStyles.tData}>
                  {item?.Positions?.map((item: any, i: number) => (
                    <Box key={i}>*{item.description}</Box>
                  ))}
                </Td>
                <Td sx={preferencesTableStyles.tData}>
                  {item?.Positions?.find((item: any) => item.hasObservation)?.observation ?? '-'}
                </Td>
                <Td sx={preferencesTableStyles.tData}>
                  {curricular?.length === 0
                    ? '-'
                    : curricular.map((item: any, i: number) => (
                        <Box key={i}>*{item.description}</Box>
                      ))}
                </Td>
                <Td sx={preferencesTableStyles.tData}>
                  {curricular.find((item: any) => item.hasObservation)?.observation ?? '-'}
                </Td>
                <Td sx={preferencesTableStyles.tData}>
                  {extraCurricular?.length === 0
                    ? '-'
                    : extraCurricular.map((item: any, i: number) => (
                        <Box key={i}>*{item.description}</Box>
                      ))}
                </Td>
                <Td sx={preferencesTableStyles.tData}>
                  {extraCurricular.find((item: any) => item.hasObservation)?.observation ?? '-'}
                </Td>
                <Td sx={preferencesTableStyles.tData}>
                  {!item?.intendedSalary || item?.intendedSalary === null
                    ? '-'
                    : item?.intendedSalary}
                </Td>
                <Td>
                  <Tooltip label='Eliminar'>
                    <DeleteIcon
                      sx={preferencesTableStyles.deleteButton}
                      onClick={() => handlePreferenceDelete(item.id)}
                    />
                  </Tooltip>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default PreferenceEdit
