import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import {
  institutionPhotoRequest,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import { AttachmentIcon } from '@chakra-ui/icons'
import { FormGeneralInformation } from './_types'
import LoadFile from '../../../../assets/images/profile/LoadFile.png'
import { PostInstitutionProfilePhotoPayload } from '../../../../state/modules/user/services/types'
import ProfileEmpty from '../../../../assets/images/profile/ProfileEmpty.png'
import { useParams } from 'react-router-dom'

const toastMessages: UseToastOptions[] = [
  {
    description: 'Cambios guardados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const GeneralInformation = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const state = useSelector((state: any) => state)
  const userInformation = useSelector((state: any) => state.user.userInformation ?? '')
  const dispatch = useDispatch()
  const toast = useToast()
  const { id } = useParams()

  useEffect(() => {
    if (
      state.user.generalInformationSuccess !== null &&
      state.user.generalInformationSuccess !== undefined
    ) {
      toast(toastMessages[state.user.generalInformationSuccess ? 0 : 1])
    }
  }, [state.user.generalInformationSuccess])

  const filePhotoInputRef = useRef<HTMLInputElement>(null)

  const [filePhotoUp, setFilePhotoUp] = useState<any>([])
  const [selectedFileName, setSelectedFileName] = useState<string>('')

  const [imagen, setImagen] = useState<any>()
  const [actualProfileImageName, setActualProfileImageName] = useState<any>()

  useEffect(() => {
    if (userInformation) {
      const currentInsti = userInformation?.Institutions?.find((insti: any) => insti.id === id)
      const imageObject = currentInsti?.UserFiles?.findLast(
        (file: any) => file.filePath && file.fileType === 'AVATAR',
      )

      if (imageObject) {
        const actualImageName = imageObject.filePath.split(/[/]+/).pop().split(/[-]+/)
        actualImageName.shift()
        const imageFilePath = imageObject.filePath
        setImagen(imageFilePath)
        setActualProfileImageName(actualImageName.join('-'))
      }
    }
  }, [userInformation])

  const handleFilePhotoSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files && files[0]) {
      if (
        files[0]?.type === 'image/png' ||
        files[0]?.type === 'image/jpg' ||
        files[0]?.type === 'image/jpeg'
      ) {
        const selectedFiles = Array.from(files)
        setFilePhotoUp(selectedFiles)
        setSelectedFileName(files[0]?.name)
      } else {
        setFilePhotoUp([])
        setImagen(ProfileEmpty)
        setSelectedFileName('Archivo no permitido')
      }
    }
  }

  const initialValues: FormGeneralInformation = {
    fotoLoad: null,
  }

  const handleButtonClickPhoto = () => {
    if (filePhotoInputRef.current) {
      filePhotoInputRef.current.click()
    }
  }

  const handleSubmit = (values: any) => {
    const updatedInitialValuesForm: PostInstitutionProfilePhotoPayload = {
      institutionId: id,
      token: localStorage.getItem('access_token') ?? '',
      photoUp: filePhotoUp,
    }

    dispatch(institutionPhotoRequest(updatedInitialValuesForm))
    dispatch(userInformationRequest({ userId: state.user.userInformation.id }))
  }
  return (
    <Box maxWidth='100%' margin='0 auto'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <Box>
              <Box display='flex' justifyContent='center'>
                <Text mb={4} width={isMobile ? '100%' : '30%'} fontWeight='bold'>
                  INFORMACIÓN GENERAL
                </Text>
              </Box>
              <Box display={isMobile ? 'block' : 'flex'}>
                <Box
                  width={isMobile ? '100%' : '80%'}
                  margin={isMobile ? 'auto' : '0 auto'}
                  paddingRight={isMobile ? '0' : '2rem'}
                >
                  <Box mt={5}>
                    <Button
                      onClick={handleButtonClickPhoto}
                      width={'100%'}
                      height='200px'
                      borderColor='#56769A'
                      color='#56769A'
                      backgroundColor={'white'}
                      variant={'outline'}
                      borderWidth='1px'
                      borderStyle='dashed'
                      rounded='md'
                      p={4}
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Image src={LoadFile} alt='LoadFila' objectFit='contain' />
                      <Box
                        mt={5}
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        rowGap={2}
                      >
                        <Box display={'flex'}>
                          <AttachmentIcon boxSize={4} mr={2} />
                          <Text size='10px' fontWeight='normal'>
                            Subir Foto
                          </Text>
                        </Box>
                        <Text sx={{ textWrap: 'wrap' }} fontSize='12px'>
                          *Formatos permitidos: jpg, jpeg y png
                        </Text>
                        <Text sx={{ textWrap: 'wrap' }} fontWeight='normal' fontSize='12px'>
                          *Se recomienda que la imagen a subir sea cuadrada para una mejor
                          visualización
                        </Text>
                      </Box>
                    </Button>
                    <input
                      ref={filePhotoInputRef}
                      type='file'
                      style={{ display: 'none' }}
                      onChange={handleFilePhotoSelect}
                      accept='.png, .jpeg, .jpg'
                    />
                    <Box
                      mt={5}
                      width='100px'
                      height='100px'
                      borderRadius='50%'
                      borderWidth='2px'
                      borderColor='black'
                      overflow='hidden'
                      alignSelf={'center'}
                    >
                      {filePhotoUp.length > 0 ? (
                        <Image
                          src={URL.createObjectURL(filePhotoUp[0])}
                          alt='LoadFila'
                          objectFit='contain'
                          width='100%'
                          height='100%'
                        />
                      ) : (
                        <Image
                          src={imagen || ProfileEmpty}
                          alt='ProfileEmpty'
                          objectFit='contain'
                          width='100%'
                          height='100%'
                        />
                      )}
                    </Box>
                    <Text mt={2} size='10px' fontWeight='normal'>
                      {filePhotoUp.length > 0
                        ? selectedFileName
                        : selectedFileName !== ''
                          ? selectedFileName
                          : actualProfileImageName || 'sin foto'}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box display='flex' justifyContent='center'>
                <Button
                  mt={4}
                  colorScheme='blue'
                  isDisabled={filePhotoUp.length === 0}
                  width={isMobile ? '100%' : '20%'}
                  type='submit'
                >
                  Guardar Cambios
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default GeneralInformation
