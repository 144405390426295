/* eslint-disable quotes */
import ReactDOM from 'react-dom/client'
import React from 'react'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './utils/theme'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import App from './App'
import './index.css'
import { Provider } from 'react-redux'
import makeStore from './state'
import * as Sentry from '@sentry/react'
import { ExtraErrorData, HttpClient } from '@sentry/integrations'
import { boolean } from 'yup'
import { Global } from '@emotion/react'
const DSN = window._env_.REACT_APP_SENTRY_DSN
const DSN_ENV = window._env_.REACT_APP_SENTRY_ENV

const store = makeStore()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

Sentry.init({
  dsn: `${DSN}`,
  environment: `${DSN_ENV}`,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
})

root.render(
  <>
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ChakraProvider>
  </>
)

reportWebVitals()
