import { colors } from '../../utils/colors'

export const abmPanelStyles = {
  sideMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    rowGap: '12px',
    height: '100%',
    width: '100%',
    borderBottom: `1px solid ${colors.secundary}`,
  },
  panel: {
    margin: '30px auto',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderRadius: '10px',
    border: `1px solid ${colors.secundary}`,
    overflow: 'hidden',
  },
  sideMenuOption: {
    padding: '8px 28px',
    cursor: 'pointer',
    width: '50%',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '150%',
    color: `${colors.secundary}`,
  },
  sideMenuOptionActive: {
    padding: '8px 28px',
    cursor: 'pointer',
    width: '50%',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '150%',
    backgroundColor: `${colors.principal}`,
    color: `${colors.white}`,
  },
}
