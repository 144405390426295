import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { FC } from 'react'

import { CandidateLanguagesProps } from '../_types'
import { candidateProfileStyles } from '../styles'

const CandidateLanguages: FC<CandidateLanguagesProps> = ({ languages }) => {
  return (
    <Card
      boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
      borderRadius='12px'
      p={['20px', '40px 68px']}
    >
      <CardHeader p='0'>
        <Heading sx={candidateProfileStyles.bodyTitle}>Idiomas</Heading>
      </CardHeader>

      <CardBody p='0' mt='30px'>
        <Flex
          flexDirection="row"
          width={'100%'}
          flexWrap={'wrap'}
          gap='4'
          py={{ base: '15px', md: '20px', lg: '20px' }}
        >
          {languages.map((language) => (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              key={language.id}
              style={{
                // height: '30px',
                color: '#004876',
                backgroundColor: '#f8f8f8',
                borderRadius: '50px',
                boxShadow: '0px 4px 6px -1px #0000001A',
                padding: '2.5px 16px'
              }}
            >
              {language.description}
            </Box>
          ))}
        </Flex>
      </CardBody>
    </Card>

  )
}

export default CandidateLanguages
