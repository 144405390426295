import * as Sentry from '@sentry/react'
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import { getValidationError } from './general'
const baseURL = window._env_.REACT_APP_API_URL

const httpsModule = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
  },
})

httpsModule.interceptors.request.use(
  (config: AxiosRequestConfig): Promise<any> => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
    }

    return Promise.resolve(config)
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error)
  },
)
httpsModule.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: any) => {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        Sentry.captureException(error, {
          extra: {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers,
          },
        })
      } else if (error.request) {
        Sentry.captureException(error, {
          extra: {
            request: error.request,
            code: error.code,
          },
        })
      } else {
        Sentry.captureException(error)
      }
    }

    if (error.response?.status === 401) {
      location.href = '/'
      window.localStorage.removeItem('access_token')
    } else if (
      error.code === 'ERR_NETWORK' ||
      error.code === 'ERR_FAILED' ||
      error.code === 'ERR_TIMED_OUT' ||
      error.code === 'ERR_CONNECTION_REFUSED'
    ) {
      const newError = error
      newError.message = getValidationError(newError.code)
    }

    return Promise.reject(error)
  },
)

export default httpsModule
