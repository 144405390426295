import React, { useState, FC } from 'react'
import {
  Box,
  useMediaQuery,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
} from '@chakra-ui/react'
import { JobComponentProps, jobsExperiences } from '../_types'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { colors } from '../../../../../utils/colors'
import { CloseIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { jobComponentStyles } from './styles'
import { PostRegisterJobsExpriences } from '../../../../../state/modules/user/services/types'
import { useDispatch, useSelector } from 'react-redux'
import {
  jobsExperienceRequest,
  userInformationRequest,
} from '../../../../../state/modules/user/actions'
import { formsStyles } from '../../styles'
const Jobscomponent: FC<JobComponentProps> = ({ setShowExperience, setIsConfirmModalOpen }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const [currentlyJob, setCurrentlyJob] = useState(false)
  const dispatch = useDispatch()
  const today = new Date().toISOString().split('T')[0]

  const initialValues: jobsExperiences = {
    jobPosition: '',
    dateFrom: '',
    dateTo: '',
    currently: false,
    institution: '',
    jobDescription: '',
    referenceName: '',
    referenceEmail: '',
    referencePhoneNumber: '',
  }

  const validateSchema = Yup.object({
    jobPosition: Yup.string().required('Campo requerido'),
    dateFrom: Yup.string().required('Campo requerido'),
    currently: Yup.boolean(),
    dateTo: currentlyJob ? Yup.string() : Yup.string().required('Campo requerido'),
    institution: Yup.string().required('Campo requerido'),
    jobDescription: Yup.string().max(200, 'Máximo 200 caracteres').required('Campo requerido'),
    referenceName: Yup.string().max(100, 'Máximo 100 caracteres'),
    referenceEmail: Yup.string().max(60, 'Máximo 60 caracteres'),
    referencePhoneNumber: Yup.string().max(12, 'Máximo 12 caracteres'),
  }).defined()

  const handleSubmit = (values: any) => {
    const token = localStorage.getItem('access_token')
    const { dateTo, ...rest } = values

    const updatedInitialValuesForm: PostRegisterJobsExpriences = {
      userId: userInformation.id,
      token: token,
      jobsExperiences: [{ dateTo: values.dateTo === '' ? null : values.dateTo, ...rest }],
      socialSkills: [],
    }

    dispatch(jobsExperienceRequest(updatedInitialValuesForm))
    dispatch(userInformationRequest({ userId: userInformation.id }))
    setShowExperience(false)
    setIsConfirmModalOpen(true)
  }

  const handlFromDate = (e: any, handleChange: any, setFieldValue: any) => {
    handleChange(e)
    setFieldValue('dateTo', '')
  }

  return (
    <Box
      sx={formsStyles.formContainer}
      width={isMobile ? '100%' : '70%'}
      paddingRight={isMobile ? '0rem' : '2rem'}
    >
      <Button
        sx={formsStyles.closeButton}
        alignSelf={'flex-end'}
        onClick={() => setShowExperience(false)}
      >
        <CloseIcon />
      </Button>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validateSchema}
      >
        {({ setFieldValue, values, handleChange }) => {
          return (
            <Form
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Text fontWeight='bold' fontSize='16px' mt={3} mb={3}>
                Experiencia Laboral
              </Text>
              <FormControl>
                <FormLabel htmlFor={'jobPosition'}>Nombre del puesto</FormLabel>
                <Field
                  as={Input}
                  type='text'
                  name={'jobPosition'}
                  placeholder='Ingrese el nombre del puesto'
                  mb={5}
                />
                <ErrorMessage
                  name='jobPosition'
                  render={(msg) => <Text sx={jobComponentStyles.error}>{msg}</Text>}
                />
              </FormControl>
              <FormControl mb={5}>
                <FormLabel htmlFor='dateFrom'>Fecha desde</FormLabel>
                <Field
                  as={Input}
                  max={today}
                  type='date'
                  name={'dateFrom'}
                  onChange={(e: any) => handlFromDate(e, handleChange, setFieldValue)}
                />
                <ErrorMessage
                  name='dateFrom'
                  render={(msg) => <Text sx={jobComponentStyles.error}>{msg}</Text>}
                />
              </FormControl>
              <FormControl display='flex' flexDirection='column' mb={5}>
                <FormLabel htmlFor={'dateTo'} mb={2}>
                  Fecha de Finalización
                </FormLabel>
                <Box display={'flex'} columnGap={'10px'}>
                  <Text fontSize={'14px'}>Estoy trabajando actualmente</Text>
                  <Checkbox
                    name={'currently'}
                    checked={currentlyJob}
                    onChange={(e) => {
                      setCurrentlyJob(e.target.checked)
                      setFieldValue('dateTo', '')
                    }}
                    borderColor={colors.principal}
                  />
                </Box>
              </FormControl>
              {!currentlyJob && (
                <FormControl>
                  <Field
                    as={Input}
                    min={values.dateFrom}
                    max={'2050-01-01'}
                    type='date'
                    name={'dateTo'}
                  />
                  <ErrorMessage
                    name='dateTo'
                    render={(msg) => <Text sx={jobComponentStyles.error}>{msg}</Text>}
                  />
                </FormControl>
              )}

              <FormControl mt={5}>
                <FormLabel htmlFor={'institution'}>Institución/Empresa</FormLabel>
                <Field
                  as={Input}
                  type='text'
                  name={'institution'}
                  placeholder='Nombre de la institución'
                  mb={5}
                />
                <ErrorMessage
                  name='institution'
                  render={(msg) => <Text sx={jobComponentStyles.error}>{msg}</Text>}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor={'jobDescription'}>Tareas realizadas</FormLabel>
                <Field
                  as={Input}
                  type='text'
                  name={'jobDescription'}
                  placeholder='Describa brevemente sus tareas'
                  mb={5}
                />
                <ErrorMessage
                  name='jobDescription'
                  render={(msg) => <Text sx={jobComponentStyles.error}>{msg}</Text>}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor={'referenceName'}>Referencias</FormLabel>
                <Field
                  as={Input}
                  type='text'
                  name={'referenceName'}
                  placeholder='Nombre: Juan Perez'
                  mb={5}
                />
                <ErrorMessage
                  name='referenceName'
                  render={(msg) => <Text sx={jobComponentStyles.error}>{msg}</Text>}
                />
              </FormControl>
              <FormControl>
                <Field
                  as={Input}
                  type='number'
                  name={'referencePhoneNumber'}
                  placeholder='Teléfono: 1158889991'
                  mb={5}
                />
                <ErrorMessage
                  name='referencePhoneNumber'
                  render={(msg) => <Text sx={jobComponentStyles.error}>{msg}</Text>}
                />
              </FormControl>
              <FormControl>
                <Field
                  as={Input}
                  type='email'
                  name={'referenceEmail'}
                  placeholder='Email: ejemplo@ejemplo.com'
                  mb={5}
                />
                <ErrorMessage
                  name='referenceEmail'
                  render={(msg) => <Text sx={jobComponentStyles.error}>{msg}</Text>}
                />
              </FormControl>
              <Button
                sx={formsStyles.saveButton}
                alignSelf={'center'}
                mt={4}
                width={isMobile ? '100%' : 'auto'}
                type='submit'
              >
                Guardar cambios
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default Jobscomponent
