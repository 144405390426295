import { Box, Button, Heading, Link, Text, useMediaQuery, useToast } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { FavoritesProps, favoriteData } from './_types'
import {
  addFolderClean,
  deleteFolderClean,
  favoriteNoteClean,
  favoriteUpdateClean,
  favoriteUpdateRequest,
  getFavoritesByFolderClean,
  getFavoritesByFolderRequest,
  getFoldersClean,
  getFoldersRequest,
  renameFolderClean,
} from '../../state/modules/favorites/actions'
import { useDispatch, useSelector } from 'react-redux'

import FavoritesCard from './FavoritesCard'
import FavoritesFolders from './FavoritesFolders'
import FavoritesFoldersModal from './FavoritesFoldersModal'
import Loader from '../loader'
import Paginator from '../Paginator'
import { favoritesStyles } from './styles'

const Favorites: FC<FavoritesProps> = () => {
  const favorites = useSelector((state: any) => state?.favorites)
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null)
  const [favoritesList, setFavoritesList] = useState<any[]>([])
  const [favoriteUserId, setFavoriteUserId] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 3000
  const token = localStorage.getItem('token') ?? ''

  useEffect(() => {
    dispatch(getFoldersRequest({ token }))
    return () => {
      dispatch(getFoldersClean())
    }
  }, [])

  useEffect(() => {
    const folder = selectedFolder === null ? 'general' : selectedFolder
    dispatch(getFavoritesByFolderRequest({ token, folderId: folder, page: currentPage }))
    return () => {
      dispatch(getFavoritesByFolderClean())
    }
  }, [selectedFolder, currentPage])

  useEffect(() => {
    if (favorites?.addFolderSuccess === true) {
      toast({
        description: favorites?.addFolderStatus.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(addFolderClean())
      dispatch(getFoldersRequest({ token }))
    }

    if (favorites?.addFolderSuccess === false) {
      toast({
        description: favorites?.addFolderStatus.response.data.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(addFolderClean())
    }
  }, [favorites?.addFolderSuccess])

  useEffect(() => {
    if (favorites?.deleteFolderSuccess === true) {
      toast({
        description: favorites?.deleteFolderStatus.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(deleteFolderClean())
      dispatch(getFoldersRequest({ token }))
      setSelectedFolder(null)
    }

    if (favorites?.deleteFolderSuccess === false) {
      toast({
        description: favorites?.deleteFolderStatus.response.data.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(deleteFolderClean())
    }
  }, [favorites?.deleteFolderSuccess])

  useEffect(() => {
    if (favorites?.renameFolderSuccess === true) {
      toast({
        description: favorites?.renameFolderStatus.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(renameFolderClean())
      dispatch(getFoldersRequest({ token }))
    }

    if (favorites?.renameFolderSuccess === false) {
      toast({
        description: favorites?.renameFolderStatus.response.data.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(renameFolderClean())
    }
  }, [favorites?.renameFolderSuccess])

  useEffect(() => {
    if (favorites?.favoriteNoteSuccess === true) {
      toast({
        description: favorites?.favoriteNoteStatus.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(favoriteNoteClean())
      dispatch(
        getFavoritesByFolderRequest({
          token,
          folderId: selectedFolder === null ? 'general' : selectedFolder,
          page: currentPage,
        }),
      )
    }

    if (favorites?.favoriteNoteSuccess === false) {
      toast({
        description: favorites?.favoriteNoteStatus.response.data.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(favoriteNoteClean())
    }
  }, [favorites?.favoriteNoteSuccess])

  useEffect(() => {
    if (favorites?.favoriteUpdateSuccess === true) {
      toast({
        description: favorites?.favoriteUpdateStatus.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(favoriteUpdateClean())
      dispatch(getFoldersRequest({ token }))
      dispatch(
        getFavoritesByFolderRequest({
          token,
          folderId: selectedFolder === null ? 'general' : selectedFolder,
          page: currentPage,
        }),
      )
    }

    if (favorites?.favoriteUpdateSuccess === false) {
      toast({
        description: favorites?.favoriteUpdateStatus.response.data.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(favoriteUpdateClean())
    }
  }, [favorites?.favoriteUpdateSuccess])

  const handleOpen = (fav: favoriteData) => {
    setIsModalOpen(true)

    const list =
      fav?.folderId === null
        ? [{ folderId: null }]
        : fav?.folders?.map((elem: any) => ({ folderId: elem.id }))
    setFavoritesList(list)
    setFavoriteUserId(fav.userId)
  }

  const handleConfirm = (favoritesArray: any[]) => {
    const token = localStorage.getItem('token') ?? ''
    dispatch(favoriteUpdateRequest({ token, userId: favoriteUserId, folders: favoritesArray }))
  }

  useEffect(() => {
    favorites?.favoritesByFolderStatus?.favorites?.rows?.length === 0 &&
      currentPage > 1 &&
      setCurrentPage(currentPage - 1)
  }, [favorites?.favoritesByFolderStatus?.favorites?.rows])

  return (
    <Box sx={isMobile ? favoritesStyles.containerMobile : favoritesStyles.container}>
      <FavoritesFoldersModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setFavoritesList([])
          setFavoriteUserId(null)
        }}
        foldersList={favorites?.foldersListStatus?.folders.filter((folder: any) => folder.id)}
        favoritesList={favoritesList}
        onConfirm={handleConfirm}
      />
      <Heading sx={favoritesStyles.heading}>Favoritos</Heading>
      <Box
        sx={
          isMobile ? favoritesStyles.favoritesContainerMobile : favoritesStyles.favoritesContainer
        }
      >
        <FavoritesFolders
          selectedFolder={selectedFolder}
          setSelectedFolder={setSelectedFolder}
          setCurrentPage={setCurrentPage}
        />
        <Box sx={isMobile ? favoritesStyles.favoritesListMobile : favoritesStyles.favoritesList}>
          {favorites?.favoritesByFolderStatus?.favorites === undefined ? (
            <Loader props={isMobile ? favoritesStyles.loadingMobile : favoritesStyles.loading} />
          ) : favorites?.favoritesByFolderStatus?.favorites?.rows?.length > 0 ? (
            <>
              <Box
                sx={
                  isMobile
                    ? favoritesStyles.favoritesCardContainerMobile
                    : favoritesStyles.favoritesCardContainer
                }
              >
                {favorites?.favoritesByFolderStatus?.favorites?.rows?.map((fav: favoriteData) => (
                  <FavoritesCard
                    key={fav.id}
                    onOpen={() => handleOpen(fav)}
                    data={fav.User}
                    userNote={fav.notes}
                    favoriteId={fav.id}
                    createdDate={fav.createdAt}
                    favoriteFolders={fav?.folders?.map((elem: any) => ({ name: elem.name }))}
                    institutionVisibility={fav.InstitutionsPublicProfile}
                  />
                ))}
              </Box>
              <Box>
                <Paginator
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  elements={favorites?.favoritesByFolderStatus?.favorites?.rows}
                  elementsPerpage={10}
                  elementsLength={favorites?.favoritesByFolderStatus?.favorites?.count}
                />
              </Box>
            </>
          ) : (
            <Box
              sx={
                isMobile
                  ? favoritesStyles.noFavoritesContainerMobile
                  : favoritesStyles.noFavoritesContainer
              }
            >
              <Text>Esta carpeta no contiene perfiles favoritos</Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Favorites
