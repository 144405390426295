import { login } from './_duck'

export const LOGIN_POST_REQUEST: string = login.defineType('LOGIN_POST_REQUEST')
export const LOGIN_POST_SUCCESS: string = login.defineType('LOGIN_POST_SUCCESS')
export const LOGIN_POST_FAILURE: string = login.defineType('LOGIN_POST_FAILURE')
export const LOGIN_CLEAN: string = login.defineType('LOGIN_CLEAN')

export const GENERATE_CODE_POST_REQUEST: string = login.defineType('GENERATE_CODE_POST_REQUEST')
export const GENERATE_CODE_POST_SUCCESS: string = login.defineType('GENERATE_CODE_POST_SUCCESS')
export const GENERATE_CODE_POST_FAILURE: string = login.defineType('GENERATE_CODE_POST_FAILURE')
export const GENERATE_CODE_POST_CLEAN: string = login.defineType('GENERATE_CODE_POST_CLEAN')

export const VERIFY_CODE_POST_REQUEST: string = login.defineType('VERIFY_CODE_POST_REQUEST')
export const VERIFY_CODE_POST_SUCCESS: string = login.defineType('VERIFY_CODE_POST_SUCCESS')
export const VERIFY_CODE_POST_FAILURE: string = login.defineType('VERIFY_CODE_POST_FAILURE')
export const VERIFY_CODE_POST_CLEAN: string = login.defineType('VERIFY_CODE_POST_CLEAN')

export const CHANGE_PASS_POST_REQUEST: string = login.defineType('CHANGE_PASS_POST_REQUEST')
export const CHANGE_PASS_POST_SUCCESS: string = login.defineType('CHANGE_PASS_POST_SUCCESS')
export const CHANGE_PASS_POST_FAILURE: string = login.defineType('CHANGE_PASS_POST_FAILURE')
export const CHANGE_PASS_POST_CLEAN: string = login.defineType('CHANGE_PASS_POST_CLEAN')

export const CHANGE_PASS_PROFILE_POST_REQUEST: string = login.defineType(
  'CHANGE_PASS_PROFILE_POST_REQUEST',
)
export const CHANGE_PASS_PROFILE_POST_SUCCESS: string = login.defineType(
  'CHANGE_PASS_PROFILE_POST_SUCCESS',
)
export const CHANGE_PASS_PROFILE_POST_FAILURE: string = login.defineType(
  'CHANGE_PASS_PROFILE_POST_FAILURE',
)
export const CHANGE_PASS_PROFILE_POST_CLEAN: string = login.defineType(
  'CHANGE_PASS_PROFILE_POST_CLEAN',
)

export const REGISTER_POSTULANTE_POST_REQUEST: string = login.defineType(
  'REGISTER_POSTULANTE_POST_REQUEST',
)
export const REGISTER_POSTULANTE_POST_SUCCESS: string = login.defineType(
  'REGISTER_POSTULANTE_POST_SUCCESS',
)
export const REGISTER_POSTULANTE_POST_FAILURE: string = login.defineType(
  'REGISTER_POSTULANTE_POST_FAILURE',
)
export const REGISTER_POSTULANTE_CLEAN: string = login.defineType('REGISTER_POSTULANTE_CLEAN')

export const REGISTER_POSTULANTE_PARAMETER_STATE_REQUEST: string = login.defineType(
  'REGISTER_POSTULANTE_PARAMETER_STATE_REQUEST',
)
export const REGISTER_POSTULANTE_PARAMETER_STATE_SUCCESS: string = login.defineType(
  'REGISTER_POSTULANTE_PARAMETER_STATE_SUCCESS',
)
export const REGISTER_POSTULANTE_PARAMETER_STATE_FAILURE: string = login.defineType(
  'REGISTER_POSTULANTE_PARAMETER_STATE_FAILURE',
)

export const REGISTER_INSTITUTION_POST_REQUEST: string = login.defineType(
  'REGISTER_INSTITUTION_POST_REQUEST',
)
export const REGISTER_INSTITUTION_POST_SUCCESS: string = login.defineType(
  'REGISTER_INSTITUTION_POST_SUCCESS',
)
export const REGISTER_INSTITUTION_POST_FAILURE: string = login.defineType(
  'REGISTER_INSTITUTION_POST_FAILURE',
)
export const REGISTER_INSTITUTION_CLEAN: string = login.defineType('REGISTER_INSTITUTION_CLEAN')

export const OAUTH_TOKEN_REQUEST: string = login.defineType('OAUTH_TOKEN_REQUEST')
export const OAUTH_TOKEN_SUCCESS: string = login.defineType('OAUTH_TOKEN_SUCCESS')
export const OAUTH_TOKEN_FAILURE: string = login.defineType('OAUTH_TOKEN_FAILURE')
