import { colors } from '../../utils/colors'

export const institutionProfileStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topStripe: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '180px',
    background: 'linear-gradient(180deg, #004876 122px, #004876 122px, #fff 122px)'
  },
  topStripeMobile: {
    position: 'relative',
    // display: 'flex',
    // justifyItems:'flex-end',
    // alignItems:'center',
    width: '100%',
    height: '128px',
    background: 'linear-gradient(180deg, #004876 32px, #004876 32px, #fff 32px)'
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
    height: '120px',
    borderRadius: '12px',
    backgroundColor: '#fff',
  },
  avatarContainerMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    width: '66px',
    height: '66px',
    borderRadius: '12px',
    backgroundColor: '#fff',
  },
  avatarImage: {
    width: '100px',
    height: '100px',
    objectFit: 'contain'
  },
  avatarMobile: {
    width: '100px',
    height: '100px',
    borderRadius: '100px',
    objectFit: 'contain',
    alignSelf: 'center',
  },
  bodyTitle: {
    fontFamily: 'Montserrat',
    fontSize: '30px',
    color: colors.principal
  },
  adDataItemReferenceText: {
    fontSize: '16px',
    color: colors.black,
    fontWeight: 'bold',
  },
  adDataItemText: {
    fontSize: '16px',
    color: colors.black
  },
  heading: {
    color: ' #000',
    fontFamily: 'Montserrat-Semibold',
    fontWeight: 'bolder',
    '&.extraInfo': {
      fontSize: '28px',
      fontFamily: 'Montserrat',
      fontWeight: 500,
    },
    '&.sub': {
      fontFamily: 'Montserrat',
      fontSize: '25px',
      fontWeight: 400,
    }
  },
  institutionName: {
    fontFamily: 'Montserrat',
    fontSize: '22px',
    color:'#000',
    marginBottom:'-6px'
  },
  subHeading: {
    color: ' #000',
    fontFamily: 'Montserrat',
    fontSize:'25px'
  },
  subHeadingMini: {
    color: ' #000',
    fontFamily: 'Montserrat',
    fontSize:'15px'
  },


  // ***********************************
  noResults: {
    width: '100%',
    height: '30vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResults2: {
    width: '100%',
    height: '45vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResultsText: { width: '80%', textAlign: 'center' },

  noResultsTextMobile: { width: '90%', textAlign: 'center' },

  loaderStyles: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // heading: {
  //   fontSize: '25px',
  //   fontWeight: 700,
  //   lineHeight: '35.68px',
  //   color: colors.principal,
  //   marginBottom: '10px',
  // },
  headingIcon: { boxSize: '13px', marginRight: '5px', cursor: 'pointer' },
  headingText: { fontSize: '13px' },
  profile: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 63px 25px 36px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  profileImage: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
    marginBottom: '20px',
    borderRadius: '10px',
  },
  sectionHeading: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '20px',
  },

  dataSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: '8px',
    columnGap: '8px',
    marginBottom: '20px',
  },
  dataItem: { display: 'flex', flexDirection: 'row', columnGap: '4px' },
  dataReferenceText: {
    fontSize: '16px',
    color: colors.black,
    fontWeight: 'bold',
  },

  dataText: { fontSize: '16px', color: colors.black, textTransform: 'capitalize' },
  dataList: {
    listStyleType: 'none',
    listStylePosition: 'inside',
    padding: 0,
    margin: '0 0 0 10px',
  },
  educationDataSection: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    marginBottom: '20px',
  },

  tableHeading: {
    fontSize: '16px',
    color: colors.black,
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  tableTHead: {
    fontSize: '14px',
    color: colors.principal,
    fontWeight: 'bold',
    textTransform: 'initial',
    padding: '8px 8px',
  },
  tableTData: {
    fontSize: '14px',
    color: colors.black,
    padding: '8px 8px',
  },
  dataItemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    padding: '8px',
  },

  profileText: {
    fontSize: '20px',
    color: colors.principal,
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  profileBio: { fontSize: '18px', marginBottom: '20px' },
  sectionContainer: { marginBottom: '20px', borderBottom: `1px solid ${colors.principal}` },
  containerMobile: { width: '100%', padding: '4% 4.3% 4.3% 4%' },
  profileMobile: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 25px 25px 25px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  profileImageMobile: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
    border: `1px solid ${colors.principal}`,
    margin: '0 auto 20px auto',
    borderRadius: '10px',
  },
  dataSectionMobile: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: '8px',
    marginBottom: '20px',
    marginTop: '20px',
  },
  dataItemMobile: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: '4px',
  },
  profileTextMobile: {
    fontSize: '20px',
    color: colors.principal,
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  profileBioMobile: { fontSize: '16px', marginBottom: '20px' },
  sectionHeadingMobile: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '20px',
    marginBottom: '10px',
  },
}
