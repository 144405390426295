import { colors } from '../../../../utils/colors'

export const userTableStyles = {
  box: { marginTop: '20px' },
  table: {},
  tableHead: { fontWeight: 'bold', fontSize: '12px', color: colors.principal },
  tableData: { fontSize: '12px' },
  actionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '15%',
  },
  badge: {
    textTransform: 'capitalize',
    padding: '4px 8px',
    fontSize: '10px',
    borderRadius: '5px',
  },
  popover: { width: '25%' },
  loading: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsContainer: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    textAlign: 'center',
    padding: '20px 0px',
    fontWeight: 'bold',
  },
}
