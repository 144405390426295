import {
  Box,
  Button,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { Navigate, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import {
  getInstitutionInviteHistoryClean,
  getInstitutionInviteHistoryRequest,
  resendInviteClean,
  resendInviteRequest,
} from '../../state/modules/profiles/actions'
import { useDispatch, useSelector } from 'react-redux'

import { EmailIcon } from '@chakra-ui/icons'
import Loader from '../loader'
import { invitesHistoryFields } from '../../utils/profile'
import { invitesStyles } from './styles'

const Invites = () => {
  const profile = useSelector((state: any) => state.profiles)
  const userInvites: any = useSelector(
    (state: any) => state.profiles?.institutionInvitesHistory?.invitations,
  )
  const { institutionId } = useParams()
  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    dispatch(getInstitutionInviteHistoryRequest({ institutionId }))
    return () => {
      getInstitutionInviteHistoryClean()
    }
  }, [institutionId, profile?.resendInviteSuccess === true])

  useEffect(() => {
    if (profile?.resendInviteSuccess === true) {
      toast({
        description: profile.resendInviteStatus.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(resendInviteClean())
    }
    if (profile?.resendInviteSuccess === false) {
      toast({
        description: profile.resendInviteStatus.response.data.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(resendInviteClean())
    }
  }, [profile?.resendInviteSuccess])

  const handleResend = (inviteId: string) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(resendInviteRequest({ inviteId, token }))
  }

  return profile?.institutionInvitesHistory === false ? (
    <Navigate to='/' />
  ) : userInvites?.length === 0 ? (
    <Box sx={invitesStyles.noResultsContainer}>
      <Text sx={invitesStyles.noResults}>*No existen invitaciones</Text>
    </Box>
  ) : (
    <TableContainer mt={4} py={8} width={'100%'} px={[4, 0]}>
      <Table>
        <Thead>
          <Tr>
            {invitesHistoryFields
              .sort((a, b) => a.order - b.order)
              .map((field) => (
                <Td key={field.order} sx={invitesStyles.thead}>
                  {field.name}
                </Td>
              ))}
          </Tr>
        </Thead>
        <Tbody>
          {userInvites?.map((invite: any, i: number) => (
            <Tr key={i}>
              <Td sx={invitesStyles.tdata}>{invite.User?.Profile?.name}</Td>
              <Td sx={invitesStyles.tdata}>{invite.User?.Profile?.lastName}</Td>
              <Td sx={invitesStyles.tdata}>{invite.User?.Profile?.dni}</Td>
              <Td sx={invitesStyles.tdata}>{invite.User?.Profile?.email}</Td>
              <Td sx={invitesStyles.tdata}>
                {new Date(invite.createdAt).toISOString().split('T')[0]}
              </Td>
              <Td sx={invitesStyles.tdata}>
                {invite.UserInstitutionInvitationStatus?.description}
              </Td>
              <Td sx={{ ...invitesStyles.tdata, textAlign: 'center' }}>
                <Button
                  onClick={() => handleResend(invite.id)}
                  isDisabled={
                    invite.UserInstitutionInvitationStatus.id !== '3' &&
                    invite.UserInstitutionInvitationStatus.id !== '4'
                  }
                  colorScheme='neutral'
                  color={'black'}
                >
                  <Icon as={EmailIcon} boxSize={4} />
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default Invites
