import { Box, Center, Heading, Stack, } from '@chakra-ui/layout'
import { Button, Card, CardBody, Flex, Grid, GridItem, HStack, Text, useMediaQuery, Image, Img, VStack } from '@chakra-ui/react'
import { landingStyles } from '../../../../pages/landing/styles'
import ColorButton from '../../../buttons/button-blue'
import { Buttons } from '../../../header'
import { colors } from '../../../../utils/colors'
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'

// import Icon1 from '../../../assets/images/landing/vector1.png'
import ritaImg from '../../../../assets/images/landing/rita.png'
import mariaImg from '../../../../assets/images/landing/maria.png'
import { FC } from 'react'
import { LandingMarginProps } from '../../../../pages/landing/regular-landing'
// import Icon3 from '../../../assets/images/landing/vector3.png'
// import Icon4 from '../../../assets/images/landing/vector4.png'
export interface IsInstitutional {
  isInstit?: boolean
}
const CommentCard: FC<any & IsInstitutional> = ({ comment, isInstit }) => {
  return (
    <>
      {
        (isInstit) ?
          (
            //INSTITUTIONAL LANDING
            <HStack
              width={{ base: '100%', md: '48%', lg: '47%' }}
              minHeight={{ base: '190px', md: '220px', lg: '280px' }}
              display='flex'
              justifyContent="flex-start"
              alignItems="center"
              key={comment.id}
              p='26px 0'
            >
              <Box
                h={['100%', '100%']}
                w={'20%'}
                display='flex'
                alignItems='flex-start'
                justifyContent='center'
              >
                <Box
                  h={['100px', '100px']}
                  w={['100px', '100px']}
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  borderRadius='12px'
                  boxShadow='0px 0px 52.5px 6px #00000017'
                  mt='25px'
                >
                  <Img
                    alt={'inst2.png'}
                    src={comment.avatarImg}
                    w='100%'
                  />
                </Box>
              </Box>
              <Box
                w='80%'
                h='100%'
                display='flex'
                justifyItems='flex-start'
                alignContent='flex-start'
                flexDirection='column'
                pl='10px'
              >
                <Box
                  height={{ base: '80%', md: '80%', lg: '75%' }}
                >
                  <Box
                    height='16px'
                    width='22px'
                    bgImage={require('../../../../assets/images/landing/mic.png')}
                    bgPosition={'center center'}
                    bgSize={['contain', 'contain']}
                    bgRepeat='no-repeat'
                    mb='5px'
                  ></Box>

                  <Text
                    mt='1'
                    mb='3'
                    color='#525252'
                    fontSize={{ base: '13px', md: '12px', lg: '16px' }}
                    lineHeight='22px'
                  >
                    {comment.comment}
                  </Text>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  height={{ base: '20%', md: '20%', lg: '25%' }}
                >
                  <Text
                    color={colors.institutionalRed}
                    fontSize='16px'
                    fontWeight='bold'
                    textAlign='left'
                    mb='5px'
                  >
                    {(comment.personName === '' || null) ? 'Anónimo' : comment.personName}
                  </Text>
                  <Text color='#525252'
                    fontSize={{ base: '13px', md: '12px', lg: '14px' }}
                  >
                    {comment.jobTitle}
                  </Text>
                </Box>
              </Box>
            </HStack>
          ) : (
            //NORMAL LANDING
            <VStack
              width={{ base: '100%', md: '45%', lg: '47%' }}
              minHeight={{ base: '190px', md: '220px', lg: '280px' }}
              display='flex'
              key={comment.id}
              p='26px 0'
            >
              <Box
                height={{ base: '80%', md: '80%', lg: '60%' }}
                margin='0px'
              >
                <Box
                  height='16px'
                  width='22px'
                  bgImage={require('../../../../assets/images/landing/mic.png')}
                  bgPosition={'center center'}
                  bgSize={['contain', 'cover']}
                  bgRepeat='no-repeat'
                  mb='5px'
                ></Box>

                <Text
                  mt='1'
                  color='#525252'
                  fontSize={{ base: '12px', md: '13px', lg: '16px' }}
                  lineHeight='22px'
                >
                  {comment.comment}
                </Text>
              </Box>

              <Box
                width='100%'
                height={{ base: '20%', md: '20%', lg: '40%' }}
                display='flex'
                pt={{ base: '5px', md: '5px', lg: '0' }}
              >
                {(comment.avatarImg !== null) &&
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    height='100%'
                  >
                    <Box
                      height='50px'
                      width='50px'
                      bgImage={comment.avatarImg}
                      bgPosition={'center center'}
                      bgSize={['contain', 'cover']}
                      bgRepeat='no-repeat'
                      borderRadius='50%'
                    ></Box>
                  </Box>
                }

                <Box
                  display='flex'
                  flexDirection='column'
                  alignItems='flex-start'
                  justifyContent='flex-start'
                  height='100%'
                >
                  <Box mb='8px'>
                    <Text
                      color={colors.institutionalRed}
                      fontSize='16px'
                      fontWeight='bold'
                      textAlign='left'
                      h='15px'
                    >
                      {(comment.personName === '' || null) ? 'Anónimo' : comment.personName}
                    </Text>
                  </Box>
                  <Box>
                    <Text color='#525252' fontSize='14px' h='15px'>
                      {comment.jobTitle}
                    </Text>
                  </Box>
                </Box>

              </Box>
            </VStack>
          )
      }
    </>
  )
}
export default CommentCard
