import * as Yup from 'yup'

import { Box, Button, FormControl, FormLabel, Input, Select, Text } from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { educationRequest, userInformationRequest } from '../../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import { FormEducation } from '../_types'
import { PostRegisterEducation } from '../../../../../state/modules/user/services/types'
import { formsStyles } from '../../styles'
import { getYears } from '../../../../../utils/profile'

const CursosCompo: React.FC<any> = ({ setShowCourse, setIsConfirmModalOpen }: any) => {
  const userInformation = useSelector((state: any) => state.user.userInformation)
  const currentYear = new Date().getFullYear()
  const years = getYears()
  const limit = years.findIndex((year: number) => year === currentYear) + 1
  const startYears = years.slice(0, limit)
  const [toDateYears, setToDateYears] = useState([currentYear])
  const [isComplete, setIsComplete] = useState<boolean>(true)
  const dispatch = useDispatch()

  const courseCompleteInitialValues: any = {
    type: 'curso',
    teacherGraduated: 'si',
    fromDate: currentYear.toString(),
    toDate: currentYear.toString(),
    institution: '',
    title: '',
  }

  const courseIncompleteInitialValues: any = {
    type: 'curso',
    teacherGraduated: 'no',
    fromDate: currentYear.toString(),
    institution: '',
    title: '',
  }

  const validateSchema = Yup.object({
    type: Yup.string(),
    fromDate: Yup.string().required('Campo obligatorio'),
    toDate: !isComplete ? Yup.string() : Yup.string().required('Campo obligatorio'),
    institution: Yup.string().required('Campo obligatorio'),
    title: Yup.string().required('Campo obligatorio'),
  })

  const handleCourseComplete = (target: any) => {
    const value = target.value === 'si' ? true : false
    setToDateYears([currentYear])
    setIsComplete(value)
  }

  const handleFromDateChange = (handleChange: any, e: any) => {
    handleChange(e)
    const fromYear = e.target.value
    const fromDateLimit = startYears.findIndex((year: number) => year == fromYear)
    const yearsArray = startYears.slice(fromDateLimit)
    setToDateYears(yearsArray)
  }

  const handleSubmit = (values: any) => {
    const updatedInitialValuesForm: PostRegisterEducation = {
      userId: userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      title: null,
      courses: [
        {
          fromDate: values.fromDate,
          toDate: values.toDate ?? '',
          institution: values.institution,
          title: values.title,
        },
      ],
      languages: [],
    }
    dispatch(educationRequest(updatedInitialValuesForm))
    dispatch(userInformationRequest({ userId: userInformation.id }))
    setShowCourse(false)
    setIsConfirmModalOpen(true)
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={courseCompleteInitialValues}
      validationSchema={validateSchema}
    >
      {({ handleChange, touched, errors, resetForm }) => {
        const touchedFields = touched as FormikTouched<FormEducation>
        const errorFields = errors as FormikErrors<FormEducation>

        return (
          <Form>
            <Box sx={formsStyles.formContainer}>
              <Button
                colorScheme='blue'
                alignSelf='end'
                mb={3}
                onClick={() => setShowCourse(false)}
              >
                X
              </Button>

              <FormControl>
                <FormLabel htmlFor='teacherGraduated' fontWeight='bold' mb={5}>
                  Estado del Curso/capacitación o certificado
                </FormLabel>
                <Field
                  as={Select}
                  name='teacherGraduated'
                  onChange={(e: ChangeEvent) => {
                    isComplete
                      ? resetForm({ values: courseIncompleteInitialValues })
                      : resetForm({ values: courseCompleteInitialValues })
                    handleChange(e)
                    handleCourseComplete(e.currentTarget)
                  }}
                >
                  <option value='si'>Completo</option>
                  <option value='cursando'>Cursando</option>
                </Field>
              </FormControl>
              <FormControl>
                <FormLabel fontWeight='bold' htmlFor='fromDate'>
                  Año de inicio
                </FormLabel>
                <Field
                  as={Select}
                  name='fromDate'
                  onChange={(e: any) => handleFromDateChange(handleChange, e)}
                >
                  {startYears.map((year: number, i: number) => (
                    <option key={i} value={year}>
                      {year}
                    </option>
                  ))}
                </Field>
                {errorFields.fromDate && touchedFields.fromDate && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields.fromDate}
                  </Text>
                )}
              </FormControl>
              {isComplete && (
                <FormControl>
                  <FormLabel fontWeight={'bold'} htmlFor='toDate'>
                    Año de finalización
                  </FormLabel>
                  <Field as={Select} name='toDate' maxLength={4} onChange={handleChange}>
                    {toDateYears?.map((year: number, i: number) => (
                      <option key={i} value={year}>
                        {year}
                      </option>
                    ))}
                  </Field>
                  {errorFields.toDate && touchedFields.toDate && (
                    <Text color='red' fontSize={'sm'}>
                      {errorFields.toDate}
                    </Text>
                  )}
                </FormControl>
              )}
              <FormControl>
                <FormLabel fontWeight={'bold'} htmlFor='institution'>
                  Institución
                </FormLabel>
                <Field
                  as={Input}
                  type='text'
                  name='institution'
                  placeholder='Nombre de la institución'
                  onChange={handleChange}
                />
                {errorFields.institution && touchedFields.institution && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields.institution}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel fontWeight={'bold'} htmlFor='title'>
                  Denominación de la capacitación
                </FormLabel>
                <Field
                  as={Input}
                  type='text'
                  name='title'
                  placeholder='Nombre del título/certificado obtenido'
                  onChange={handleChange}
                />
                {errorFields.title && touchedFields.title && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields.title}
                  </Text>
                )}
              </FormControl>
              <Box display='flex' justifyContent='center' mt={4}>
                <Button type='submit' sx={formsStyles.saveButton}>
                  Guardar cambios
                </Button>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CursosCompo
