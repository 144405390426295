import * as types from './types'

import {
  ModuleTypeAdFeaturedList,
  ModuleTypeAdInformation,
  ModuleTypeAdStatusList,
  ModuleTypeDeleteJob,
  ModuleTypeDownloadAdsSpreadsheet,
  ModuleTypeDuplicateJob,
  ModuleTypeEditJob,
  ModuleTypeEditJobState,
  ModuleTypeFindJobs,
  ModuleTypeInstitutionsCompleteList,
  ModuleTypeInstitutionsList,
  ModuleTypeJobApplication,
  ModuleTypeJobTypesList,
  ModuleTypeNewJob,
  ModuleTypePositionsList,
  ModuleTypeReferenceNumbersList,
  ModuleTypeSearchJobs,
  ModuleTypeSubjectsList,
  ModuleTypeTimeSlotsList,
  ModuleLastTenAdsInformation
} from './_definitions'

import { PayloadAction } from '../../types'
import { ads } from './_ducks'

const initialModuleStateinstitutionsList: ModuleTypeInstitutionsList = {
  hasErrMessage: false,
  institutionsList: null,
}

const lastTenAdsList: ModuleLastTenAdsInformation = {
  hasErrMessage: false,
  ads: null,
  trend: [],
}

const initialModuleStateinstitutionsCompleteList: ModuleTypeInstitutionsCompleteList = {
  hasErrMessage: false,
  institutionsCompleteList: null,
}

const initialModuleStateTimeSlotsList: ModuleTypeTimeSlotsList = {
  hasErrMessage: false,
  timeSlotsList: null,
}

const initialModuleStateJobTypesList: ModuleTypeJobTypesList = {
  hasErrMessage: false,
  jobTypesList: null,
}

const initialModuleStateNewJob: ModuleTypeNewJob = {
  hasErrMessage: false,
  newJobSuccess: null,
  newJobStatus: null,
}

const initialModuleStateFindAds: ModuleTypeFindJobs = {
  hasErrMessage: false,
  jobsList: null,
}

const initialModuleStateEditJob: ModuleTypeEditJob = {
  hasErrMessage: false,
  editJobSuccess: null,
  editJobStatus: null,
}

const initialModuleStateEditJobState: ModuleTypeEditJobState = {
  hasErrMessage: false,
  editJobStateSuccess: null,
  editJobStateStatus: null,
}
const initialModuleStateDeleteJob: ModuleTypeDeleteJob = {
  hasErrMessage: false,
  deleteJobSuccess: null,
  deleteJobStatus: null,
}

const initialModuleStateSearchJobs: ModuleTypeSearchJobs = {
  hasErrMessage: false,
  searchedJobs: null,
}
const initialModuleStateJobApplication: ModuleTypeJobApplication = {
  hasErrMessage: false,
  jobApplicationSuccess: null,
  jobApplicationStatus: null,
}

const initialModuleStateAdInformation: ModuleTypeAdInformation = {
  hasErrMessage: false,
  adInformation: null,
}

const initialModuleStateDownloadAdsSpreadsheet: ModuleTypeDownloadAdsSpreadsheet = {
  hasErrMessage: false,
  downloadAdsSpreadsheetStatus: null,
  downloadAdsSpreadsheetSuccess: null,
}

const initialModuleStatePositionsList: ModuleTypePositionsList = {
  hasErrMessage: false,
  positionsList: null,
}

const initialModuleStateAdStatusList: ModuleTypeAdStatusList = {
  hasErrMessage: false,
  adStatusList: null,
}

const initialModuleStateSubjectsList: ModuleTypeSubjectsList = {
  hasErrMessage: false,
  subjectsList: null,
}

const initialModuleStateDuplicateJob: ModuleTypeDuplicateJob = {
  hasErrMessage: false,
  duplicateJobSuccess: null,
  duplicateJobStatus: null,
}

const initialModuleStateReferenceNumbersList: ModuleTypeReferenceNumbersList = {
  hasErrMessage: false,
  referenceNumbersListStatus: null,
}

const reducer = ads.createReducer({
  [types.INSTITUTIONS_GET_REQUEST]: (
    state: ModuleTypeInstitutionsList,
  ): ModuleTypeInstitutionsList => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.INSTITUTIONS_GET_SUCCESS]: (
    state: ModuleTypeInstitutionsList,
    { payload }: PayloadAction,
  ): ModuleTypeInstitutionsList => ({
    ...state,
    institutionsList: payload.institutions,
  }),
  [types.INSTITUTIONS_GET_FAILURE]: (
    state: ModuleTypeInstitutionsList,
  ): ModuleTypeInstitutionsList => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.INSTITUTIONS_GET_CLEAN]: (
    state: ModuleTypeInstitutionsList,
  ): ModuleTypeInstitutionsList => ({
    ...state,
    hasErrMessage: false,
    institutionsList: null,
  }),
  [types.INSTITUTIONS_COMPLETE_GET_REQUEST]: (
    state: ModuleTypeInstitutionsCompleteList,
  ): ModuleTypeInstitutionsCompleteList => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.INSTITUTIONS_COMPLETE_GET_SUCCESS]: (
    state: ModuleTypeInstitutionsCompleteList,
    { payload }: PayloadAction,
  ): ModuleTypeInstitutionsCompleteList => ({
    ...state,
    institutionsCompleteList: payload.institutions,
  }),
  [types.INSTITUTIONS_COMPLETE_GET_FAILURE]: (
    state: ModuleTypeInstitutionsCompleteList,
  ): ModuleTypeInstitutionsCompleteList => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.INSTITUTIONS_COMPLETE_GET_CLEAN]: (
    state: ModuleTypeInstitutionsCompleteList,
  ): ModuleTypeInstitutionsCompleteList => ({
    ...state,
    hasErrMessage: false,
    institutionsCompleteList: null,
  }),
  [types.TIMESLOTS_GET_REQUEST]: (state: ModuleTypeTimeSlotsList): ModuleTypeTimeSlotsList => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.TIMESLOTS_GET_SUCCESS]: (
    state: ModuleTypeTimeSlotsList,
    { payload }: PayloadAction,
  ): ModuleTypeTimeSlotsList => ({
    ...state,
    timeSlotsList: payload.timeslots,
  }),
  [types.TIMESLOTS_GET_FAILURE]: (state: ModuleTypeTimeSlotsList): ModuleTypeTimeSlotsList => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.TIMESLOTS_GET_CLEAN]: (state: ModuleTypeTimeSlotsList): ModuleTypeTimeSlotsList => ({
    ...state,
    hasErrMessage: false,
    timeSlotsList: null,
  }),
  [types.JOBTYPES_GET_REQUEST]: (state: ModuleTypeJobTypesList): ModuleTypeJobTypesList => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.JOBTYPES_GET_SUCCESS]: (
    state: ModuleTypeJobTypesList,
    { payload }: PayloadAction,
  ): ModuleTypeJobTypesList => ({
    ...state,
    jobTypesList: payload.jobtypes,
  }),
  [types.JOBTYPES_GET_FAILURE]: (state: ModuleTypeJobTypesList): ModuleTypeJobTypesList => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.JOBTYPES_GET_CLEAN]: (state: ModuleTypeJobTypesList): ModuleTypeJobTypesList => ({
    ...state,
    hasErrMessage: false,
    jobTypesList: null,
  }),
  [types.NEWJOB_POST_REQUEST]: (state: ModuleTypeNewJob): ModuleTypeNewJob => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.NEWJOB_POST_SUCCESS]: (
    state: ModuleTypeNewJob,
    { payload }: PayloadAction,
  ): ModuleTypeNewJob => ({
    ...state,
    newJobSuccess: true,
    newJobStatus: payload,
  }),
  [types.NEWJOB_POST_FAILURE]: (
    state: ModuleTypeNewJob,
    { payload }: PayloadAction,
  ): ModuleTypeNewJob => ({
    ...state,
    hasErrMessage: true,
    newJobSuccess: false,
    newJobStatus: payload,
  }),
  [types.NEWJOB_POST_CLEAN]: (state: ModuleTypeNewJob): ModuleTypeNewJob => ({
    ...state,
    hasErrMessage: false,
    newJobSuccess: null,
    newJobStatus: null,
  }),
  [types.FIND_JOBS_POST_REQUEST]: (state: ModuleTypeFindJobs): ModuleTypeFindJobs => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.FIND_JOBS_POST_SUCCESS]: (
    state: ModuleTypeFindJobs,
    { payload }: PayloadAction,
  ): ModuleTypeFindJobs => ({
    ...state,
    jobsList: payload?.foundAds,
  }),
  [types.FIND_JOBS_POST_FAILURE]: (state: ModuleTypeFindJobs): ModuleTypeFindJobs => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.FIND_JOBS_POST_CLEAN]: (state: ModuleTypeFindJobs): ModuleTypeFindJobs => ({
    ...state,
    hasErrMessage: false,
    jobsList: null,
  }),
  [types.EDIT_JOB_PUT_REQUEST]: (state: ModuleTypeNewJob): ModuleTypeNewJob => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.EDIT_JOB_PUT_SUCCESS]: (
    state: ModuleTypeEditJob,
    { payload }: PayloadAction,
  ): ModuleTypeEditJob => ({
    ...state,
    editJobSuccess: true,
    editJobStatus: payload,
  }),
  [types.EDIT_JOB_PUT_FAILURE]: (
    state: ModuleTypeEditJob,
    { payload }: PayloadAction,
  ): ModuleTypeEditJob => ({
    ...state,
    hasErrMessage: true,
    editJobSuccess: false,
    editJobStatus: payload,
  }),
  [types.EDIT_JOB_PUT_CLEAN]: (state: ModuleTypeEditJob): ModuleTypeEditJob => ({
    ...state,
    hasErrMessage: false,
    editJobSuccess: null,
    editJobStatus: null,
  }),
  [types.EDIT_JOB_STATUS_PUT_REQUEST]: (state: ModuleTypeEditJobState): ModuleTypeEditJobState => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.EDIT_JOB_STATUS_PUT_SUCCESS]: (
    state: ModuleTypeEditJobState,
    { payload }: PayloadAction,
  ): ModuleTypeEditJobState => ({
    ...state,
    editJobStateSuccess: true,
    editJobStateStatus: payload,
  }),
  [types.EDIT_JOB_STATUS_PUT_FAILURE]: (
    state: ModuleTypeEditJobState,
    { payload }: PayloadAction,
  ): ModuleTypeEditJobState => ({
    ...state,
    hasErrMessage: true,
    editJobStateSuccess: false,
    editJobStateStatus: payload,
  }),
  [types.EDIT_JOB_STATUS_PUT_CLEAN]: (state: ModuleTypeEditJobState): ModuleTypeEditJobState => ({
    ...state,
    hasErrMessage: false,
    editJobStateSuccess: null,
    editJobStateStatus: null,
  }),
  [types.JOB_DELETE_REQUEST]: (state: ModuleTypeDeleteJob): ModuleTypeDeleteJob => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.JOB_DELETE_SUCCESS]: (
    state: ModuleTypeDeleteJob,
    { payload }: PayloadAction,
  ): ModuleTypeDeleteJob => ({
    ...state,
    deleteJobSuccess: true,
    deleteJobStatus: payload,
  }),
  [types.JOB_DELETE_FAILURE]: (
    state: ModuleTypeDeleteJob,
    { payload }: PayloadAction,
  ): ModuleTypeDeleteJob => ({
    ...state,
    hasErrMessage: true,
    deleteJobSuccess: false,
    deleteJobStatus: payload,
  }),
  [types.JOB_DELETE_CLEAN]: (state: ModuleTypeDeleteJob): ModuleTypeDeleteJob => ({
    ...state,
    hasErrMessage: false,
    deleteJobSuccess: null,
    deleteJobStatus: null,
  }),
  [types.SEARCH_JOBS_POST_REQUEST]: (state: ModuleTypeSearchJobs): ModuleTypeSearchJobs => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.SEARCH_JOBS_POST_SUCCESS]: (
    state: ModuleTypeSearchJobs,
    { payload }: PayloadAction,
  ): ModuleTypeSearchJobs => ({
    ...state,
    searchedJobs: payload?.ads,
  }),
  [types.SEARCH_JOBS_POST_FAILURE]: (state: ModuleTypeSearchJobs): ModuleTypeSearchJobs => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.SEARCH_JOBS_POST_CLEAN]: (state: ModuleTypeSearchJobs): ModuleTypeSearchJobs => ({
    ...state,
    hasErrMessage: false,
    searchedJobs: null,
  }),
  [types.AD_DETAIL_GET_REQUEST]: (state: ModuleTypeAdInformation): ModuleTypeAdInformation => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.AD_DETAIL_GET_SUCCESS]: (
    state: ModuleTypeAdInformation,
    { payload }: PayloadAction,
  ): ModuleTypeAdInformation => ({
    ...state,
    adInformation: payload,
  }),
  [types.AD_DETAIL_GET_FAILURE]: (state: ModuleTypeAdInformation): ModuleTypeAdInformation => ({
    ...state,
    hasErrMessage: true,
    adInformation: false,
  }),
  [types.AD_DETAIL_GET_CLEAN]: (state: ModuleTypeAdInformation): ModuleTypeAdInformation => ({
    ...state,
    hasErrMessage: false,
    adInformation: null,
  }),
  [types.JOB_APPLICATION_GET_REQUEST]: (
    state: ModuleTypeJobApplication,
  ): ModuleTypeJobApplication => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.JOB_APPLICATION_GET_SUCCESS]: (
    state: ModuleTypeJobApplication,
    { payload }: PayloadAction,
  ): ModuleTypeJobApplication => ({
    ...state,
    jobApplicationSuccess: true,
    jobApplicationStatus: payload,
  }),
  [types.JOB_APPLICATION_GET_FAILURE]: (
    state: ModuleTypeJobApplication,
    { payload }: PayloadAction,
  ): ModuleTypeJobApplication => ({
    ...state,
    hasErrMessage: true,
    jobApplicationSuccess: false,
    jobApplicationStatus: payload,
  }),
  [types.JOB_APPLICATION_GET_CLEAN]: (
    state: ModuleTypeJobApplication,
  ): ModuleTypeJobApplication => ({
    ...state,
    hasErrMessage: false,
    jobApplicationSuccess: null,
    jobApplicationStatus: null,
  }),
  [types.DOWNLOAD_ADS_SPREADSHEET_POST_REQUEST]: (
    state: ModuleTypeDownloadAdsSpreadsheet,
  ): ModuleTypeDownloadAdsSpreadsheet => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.DOWNLOAD_ADS_SPREADSHEET_POST_SUCCESS]: (
    state: ModuleTypeDownloadAdsSpreadsheet,
    { payload }: PayloadAction,
  ): ModuleTypeDownloadAdsSpreadsheet => ({
    ...state,
    downloadAdsSpreadsheetStatus: payload,
    downloadAdsSpreadsheetSuccess: true,
  }),
  [types.DOWNLOAD_ADS_SPREADSHEET_POST_FAILURE]: (
    state: ModuleTypeDownloadAdsSpreadsheet,
    { payload }: PayloadAction,
  ): ModuleTypeDownloadAdsSpreadsheet => ({
    ...state,
    hasErrMessage: true,
    downloadAdsSpreadsheetStatus: payload,
    downloadAdsSpreadsheetSuccess: false,
  }),
  [types.DOWNLOAD_ADS_SPREADSHEET_POST_CLEAN]: (
    state: ModuleTypeDownloadAdsSpreadsheet,
  ): ModuleTypeDownloadAdsSpreadsheet => ({
    ...state,
    hasErrMessage: false,
    downloadAdsSpreadsheetStatus: null,
    downloadAdsSpreadsheetSuccess: null,
  }),
  [types.POSITIONS_LIST_GET_REQUEST]: (
    state: ModuleTypePositionsList,
  ): ModuleTypePositionsList => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.POSITIONS_LIST_GET_SUCCESS]: (
    state: ModuleTypePositionsList,
    { payload }: PayloadAction,
  ): ModuleTypePositionsList => ({
    ...state,
    positionsList: payload.positions,
  }),
  [types.POSITIONS_LIST_GET_FAILURE]: (
    state: ModuleTypePositionsList,
  ): ModuleTypePositionsList => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.POSITIONS_LIST_GET_CLEAN]: (state: ModuleTypePositionsList): ModuleTypePositionsList => ({
    ...state,
    hasErrMessage: false,
    positionsList: null,
  }),
  [types.AD_STATUS_LIST_GET_REQUEST]: (state: ModuleTypeAdStatusList): ModuleTypeAdStatusList => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.AD_STATUS_LIST_GET_SUCCESS]: (
    state: ModuleTypeAdStatusList,
    { payload }: PayloadAction,
  ): ModuleTypeAdStatusList => ({
    ...state,
    adStatusList: payload.allStatus,
  }),
  [types.AD_STATUS_LIST_GET_FAILURE]: (state: ModuleTypeAdStatusList): ModuleTypeAdStatusList => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.AD_STATUS_LIST_GET_CLEAN]: (state: ModuleTypeAdStatusList): ModuleTypeAdStatusList => ({
    ...state,
    hasErrMessage: false,
    adStatusList: null,
  }),
  [types.AD_FEATURED_LIST_GET_REQUEST]: (state: ModuleTypeAdFeaturedList): ModuleTypeAdFeaturedList => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.AD_FEATURED_LIST_GET_SUCCESS]: (
    state: ModuleTypeAdFeaturedList,
    { payload }: PayloadAction,
  ): ModuleTypeAdFeaturedList => ({
    ...state,
    adFeaturedList: payload.adFeaturedList
  }),
  [types.AD_FEATURED_LIST_GET_FAILURE]: (state: ModuleTypeAdFeaturedList): ModuleTypeAdFeaturedList => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.AD_FEATURED_LIST_GET_CLEAN]: (state: ModuleTypeAdFeaturedList): ModuleTypeAdFeaturedList => ({
    ...state,
    hasErrMessage: false,
    adFeaturedList: null,
  }),
  [types.SUBJECTS_LIST_GET_REQUEST]: (state: ModuleTypeSubjectsList): ModuleTypeSubjectsList => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.SUBJECTS_LIST_GET_SUCCESS]: (
    state: ModuleTypeSubjectsList,
    { payload }: PayloadAction,
  ): ModuleTypeSubjectsList => ({
    ...state,
    subjectsList: payload.subjects,
  }),
  [types.SUBJECTS_LIST_GET_FAILURE]: (state: ModuleTypeSubjectsList): ModuleTypeSubjectsList => ({
    ...state,
    hasErrMessage: true,
  }),
  [types.SUBJECTS_LIST_GET_CLEAN]: (state: ModuleTypeSubjectsList): ModuleTypeSubjectsList => ({
    ...state,
    hasErrMessage: false,
    subjectsList: null,
  }),
  [types.DUPLICATE_JOB_POST_REQUEST]: (state: ModuleTypeDuplicateJob): ModuleTypeDuplicateJob => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.DUPLICATE_JOB_POST_SUCCESS]: (
    state: ModuleTypeDuplicateJob,
    { payload }: PayloadAction,
  ): ModuleTypeDuplicateJob => ({
    ...state,
    duplicateJobSuccess: true,
    duplicateJobStatus: payload,
  }),
  [types.DUPLICATE_JOB_POST_FAILURE]: (
    state: ModuleTypeDuplicateJob,
    { payload }: PayloadAction,
  ): ModuleTypeDuplicateJob => ({
    ...state,
    hasErrMessage: true,
    duplicateJobSuccess: false,
    duplicateJobStatus: payload,
  }),
  [types.DUPLICATE_JOB_POST_CLEAN]: (state: ModuleTypeDuplicateJob): ModuleTypeDuplicateJob => ({
    ...state,
    hasErrMessage: false,
    duplicateJobSuccess: null,
    duplicateJobStatus: null,
  }),
  [types.REFERENCE_NUMBERS_GET_REQUEST]: (
    state: ModuleTypeReferenceNumbersList,
  ): ModuleTypeReferenceNumbersList => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.REFERENCE_NUMBERS_GET_SUCCESS]: (
    state: ModuleTypeReferenceNumbersList,
    { payload }: PayloadAction,
  ): ModuleTypeReferenceNumbersList => ({
    ...state,
    referenceNumbersListStatus: payload,
  }),
  [types.REFERENCE_NUMBERS_GET_FAILURE]: (
    state: ModuleTypeReferenceNumbersList,
    { payload }: PayloadAction,
  ): ModuleTypeReferenceNumbersList => ({
    ...state,
    hasErrMessage: true,
    referenceNumbersListStatus: payload,
  }),
  [types.REFERENCE_NUMBERS_GET_CLEAN]: (
    state: ModuleTypeReferenceNumbersList,
  ): ModuleTypeReferenceNumbersList => ({
    ...state,
    hasErrMessage: false,
    referenceNumbersListStatus: null,
  }),
  [types.AD_LAST_TEN_GET_REQUEST]: (state: ModuleLastTenAdsInformation): ModuleLastTenAdsInformation => ({
    ...state,
    hasErrMessage: false,
  }),
  [types.AD_LAST_TEN_GET_SUCCESS]: (
    state: ModuleLastTenAdsInformation,
    { payload }: PayloadAction,
  ): ModuleLastTenAdsInformation => ({
    ...state,
    trend: payload.trend,
    ads: payload.ads,
  }),
  [types.AD_LAST_TEN_GET_FAILURE]: (state: ModuleLastTenAdsInformation): ModuleLastTenAdsInformation => ({
    ...state,
    hasErrMessage: true,
    ads: false,
  }),
  [types.AD_LAST_TEN_GET_CLEAN]: (state: ModuleLastTenAdsInformation): ModuleLastTenAdsInformation => ({
    ...state,
    hasErrMessage: false,
    ads: null,
  }),
  initialModuleStateinstitutionsList,
  lastTenAdsList,
  initialModuleStateinstitutionsCompleteList,
  initialModuleStateTimeSlotsList,
  initialModuleStateJobTypesList,
  initialModuleStateNewJob,
  initialModuleStateFindAds,
  initialModuleStateEditJob,
  initialModuleStateEditJobState,
  initialModuleStateDeleteJob,
  initialModuleStateSearchJobs,
  initialModuleStateAdInformation,
  initialModuleStateJobApplication,
  initialModuleStateDownloadAdsSpreadsheet,
  initialModuleStatePositionsList,
  initialModuleStateAdStatusList,
  initialModuleStateSubjectsList,
  initialModuleStateDuplicateJob,
  initialModuleStateReferenceNumbersList,
})

export default reducer
