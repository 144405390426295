import { colors } from '../../utils/colors'

export const invitesHandlerStyles = {
  modalContainer: { padding: '1.5% 0' },
  tHead: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: colors.black,
    padding: '8px 8px',
  },
  checkIcon: {
    color: colors.green,
    boxSize: 4,
    cursor: 'pointer',
    margin: '0px 3px',
  },
  closeIcon: {
    color: colors.red,
    boxSize: 3,
    cursor: 'pointer',
    margin: '0px 3px',
  },
  checkIconMobile: {
    color: colors.green,
    boxSize: 5,
    cursor: 'pointer',
    margin: '0px 3px 10px 3px',
  },
  closeIconMobile: {
    color: colors.red,
    boxSize: 4,
    cursor: 'pointer',
    margin: '0px 3px',
  },
  tData: { fontSize: '13px', padding: '8px 8px' },
  tDataMobile: { fontSize: '13px', padding: '12px' },
  noResultsContainer: {
    width: '100%',
    height: '20vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    textAlign: 'center',
    padding: '20px 0px',
    fontWeight: 'bold',
  },
  loading: {
    width: '100%',
    height: '20vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
