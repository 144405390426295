import * as types from './types'

import { ads } from './_ducks'

export const institutionsRequest = ads.createAction(types.INSTITUTIONS_GET_REQUEST)
export const institutionsSuccess = ads.createAction(types.INSTITUTIONS_GET_SUCCESS)
export const institutionsFailure = ads.createAction(types.INSTITUTIONS_GET_FAILURE)
export const institutionsClean = ads.createAction(types.INSTITUTIONS_GET_CLEAN)

export const institutionsCompleteRequest = ads.createAction(types.INSTITUTIONS_COMPLETE_GET_REQUEST)
export const institutionsCompleteSuccess = ads.createAction(types.INSTITUTIONS_COMPLETE_GET_SUCCESS)
export const institutionsCompleteFailure = ads.createAction(types.INSTITUTIONS_COMPLETE_GET_FAILURE)
export const institutionsCompleteClean = ads.createAction(types.INSTITUTIONS_COMPLETE_GET_CLEAN)

export const timeSlotsRequest = ads.createAction(types.TIMESLOTS_GET_REQUEST)
export const timeSlotsSuccess = ads.createAction(types.TIMESLOTS_GET_SUCCESS)
export const timeSlotsFailure = ads.createAction(types.TIMESLOTS_GET_FAILURE)
export const timeSlotsClean = ads.createAction(types.TIMESLOTS_GET_CLEAN)

export const jobTypesRequest = ads.createAction(types.JOBTYPES_GET_REQUEST)
export const jobTypesSuccess = ads.createAction(types.JOBTYPES_GET_SUCCESS)
export const jobTypesFailure = ads.createAction(types.JOBTYPES_GET_FAILURE)
export const jobTypesClean = ads.createAction(types.JOBTYPES_GET_CLEAN)

export const newJobRequest = ads.createAction(types.NEWJOB_POST_REQUEST)
export const newJobSuccess = ads.createAction(types.NEWJOB_POST_SUCCESS)
export const newJobFailure = ads.createAction(types.NEWJOB_POST_FAILURE)
export const newJobClean = ads.createAction(types.NEWJOB_POST_CLEAN)

export const findJobsRequest = ads.createAction(types.FIND_JOBS_POST_REQUEST)
export const findJobsSuccess = ads.createAction(types.FIND_JOBS_POST_SUCCESS)
export const findJobsFailure = ads.createAction(types.FIND_JOBS_POST_FAILURE)
export const findJobsClean = ads.createAction(types.FIND_JOBS_POST_CLEAN)

export const editJobRequest = ads.createAction(types.EDIT_JOB_PUT_REQUEST)
export const editJobSuccess = ads.createAction(types.EDIT_JOB_PUT_SUCCESS)
export const editJobFailure = ads.createAction(types.EDIT_JOB_PUT_FAILURE)
export const editJobClean = ads.createAction(types.EDIT_JOB_PUT_CLEAN)

export const editJobStatusRequest = ads.createAction(types.EDIT_JOB_STATUS_PUT_REQUEST)
export const editJobStatusSuccess = ads.createAction(types.EDIT_JOB_STATUS_PUT_SUCCESS)
export const editJobStatusFailure = ads.createAction(types.EDIT_JOB_STATUS_PUT_FAILURE)
export const editJobStatusClean = ads.createAction(types.EDIT_JOB_STATUS_PUT_CLEAN)

export const jobDeleteRequest = ads.createAction(types.JOB_DELETE_REQUEST)
export const jobDeleteSuccess = ads.createAction(types.JOB_DELETE_SUCCESS)
export const jobDeleteFailure = ads.createAction(types.JOB_DELETE_FAILURE)
export const jobDeleteClean = ads.createAction(types.JOB_DELETE_CLEAN)

export const searchJobsRequest = ads.createAction(types.SEARCH_JOBS_POST_REQUEST)
export const searchJobsSuccess = ads.createAction(types.SEARCH_JOBS_POST_SUCCESS)
export const searchJobsFailure = ads.createAction(types.SEARCH_JOBS_POST_FAILURE)
export const searchJobsClean = ads.createAction(types.SEARCH_JOBS_POST_CLEAN)

export const jobApplicationRequest = ads.createAction(types.JOB_APPLICATION_GET_REQUEST)
export const jobApplicationSuccess = ads.createAction(types.JOB_APPLICATION_GET_SUCCESS)
export const jobApplicationFailure = ads.createAction(types.JOB_APPLICATION_GET_FAILURE)
export const jobApplicationClean = ads.createAction(types.JOB_APPLICATION_GET_CLEAN)

export const adDetailRequest = ads.createAction(types.AD_DETAIL_GET_REQUEST)
export const adDetailSuccess = ads.createAction(types.AD_DETAIL_GET_SUCCESS)
export const adDetailFailure = ads.createAction(types.AD_DETAIL_GET_FAILURE)
export const adDetailClean = ads.createAction(types.AD_DETAIL_GET_CLEAN)

export const downloadAdsSpreadsheetRequest = ads.createAction(
  types.DOWNLOAD_ADS_SPREADSHEET_POST_REQUEST,
)
export const downloadAdsSpreadsheetSuccess = ads.createAction(
  types.DOWNLOAD_ADS_SPREADSHEET_POST_SUCCESS,
)
export const downloadAdsSpreadsheetFailure = ads.createAction(
  types.DOWNLOAD_ADS_SPREADSHEET_POST_FAILURE,
)
export const downloadAdsSpreadsheetClean = ads.createAction(
  types.DOWNLOAD_ADS_SPREADSHEET_POST_CLEAN,
)

export const positionsRequest = ads.createAction(types.POSITIONS_LIST_GET_REQUEST)
export const positionsSuccess = ads.createAction(types.POSITIONS_LIST_GET_SUCCESS)
export const positionsFailure = ads.createAction(types.POSITIONS_LIST_GET_FAILURE)
export const positionsClean = ads.createAction(types.POSITIONS_LIST_GET_CLEAN)

export const adStatusRequest = ads.createAction(types.AD_STATUS_LIST_GET_REQUEST)
export const adStatusSuccess = ads.createAction(types.AD_STATUS_LIST_GET_SUCCESS)
export const adStatusFailure = ads.createAction(types.AD_STATUS_LIST_GET_FAILURE)
export const adStatusClean = ads.createAction(types.AD_STATUS_LIST_GET_CLEAN)

export const adFeaturedRequest = ads.createAction(types.AD_FEATURED_LIST_GET_REQUEST)
export const adFeaturedSuccess = ads.createAction(types.AD_FEATURED_LIST_GET_SUCCESS)
export const adFeaturedFailure = ads.createAction(types.AD_FEATURED_LIST_GET_FAILURE)
export const adFeaturedClean = ads.createAction(types.AD_FEATURED_LIST_GET_CLEAN)

export const subjectsRequest = ads.createAction(types.SUBJECTS_LIST_GET_REQUEST)
export const subjectsSuccess = ads.createAction(types.SUBJECTS_LIST_GET_SUCCESS)
export const subjectsFailure = ads.createAction(types.SUBJECTS_LIST_GET_FAILURE)
export const subjectsClean = ads.createAction(types.SUBJECTS_LIST_GET_CLEAN)

export const duplicateJobRequest = ads.createAction(types.DUPLICATE_JOB_POST_REQUEST)
export const duplicateJobSuccess = ads.createAction(types.DUPLICATE_JOB_POST_SUCCESS)
export const duplicateJobFailure = ads.createAction(types.DUPLICATE_JOB_POST_FAILURE)
export const duplicateJobClean = ads.createAction(types.DUPLICATE_JOB_POST_CLEAN)

export const referenceNumbersRequest = ads.createAction(types.REFERENCE_NUMBERS_GET_REQUEST)
export const referenceNumbersSuccess = ads.createAction(types.REFERENCE_NUMBERS_GET_SUCCESS)
export const referenceNumbersFailure = ads.createAction(types.REFERENCE_NUMBERS_GET_FAILURE)
export const referenceNumbersClean = ads.createAction(types.REFERENCE_NUMBERS_GET_CLEAN)

export const adLastTenRequest = ads.createAction(types.AD_LAST_TEN_GET_REQUEST)
export const adLastTenSuccess = ads.createAction(types.AD_LAST_TEN_GET_SUCCESS)
export const adLastTenFailure = ads.createAction(types.AD_LAST_TEN_GET_FAILURE)
export const adLastTenClean = ads.createAction(types.AD_LAST_TEN_GET_CLEAN)