import { Box, Text, VStack } from '@chakra-ui/react'
import LandingLayout from '../../layouts/landing'
import AdsSearch from '../../components/AdsSearch'

const Home = () => {
  return (
    <LandingLayout>
      <VStack>
        <AdsSearch />
      </VStack>
    </LandingLayout>
  )
}

export default Home
