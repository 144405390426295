import { background, position } from '@chakra-ui/react'
import { colors } from '../../utils/colors'
import { isWhiteSpaceLike } from 'typescript'

const avatarImgContWidth = 120
const headingAndBodyPadding = 80

export const candidateProfileStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  containerMobile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  adMobile: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 25px 25px 25px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  topStripe: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '180px',
    background: 'linear-gradient(180deg, #004876 122px, #004876 122px, #fff 122px)',
  },
  topStripeMobile: {
    position: 'relative',
    // display: 'flex',
    // justifyItems:'flex-end',
    // alignItems:'center',
    width: '100%',
    height: '128px',
    background: 'linear-gradient(180deg, #004876 32px, #004876 32px, #fff 32px)'
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '120px',
    height: '120px',
    borderRadius: '12px',
    backgroundColor: '#fff',
  },
  avatarContainerMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    width: '66px',
    height: '66px',
    borderRadius: '12px',
    backgroundColor: '#fff',

  },
  avatarImage: {
    width: '100px',
    height: '100px',
    objectFit: 'contain'
  },
  headingAndBodyContainer: {
    display: 'flex',
    marginTop: '78px',
    width: '100%',
    padding: '0 80px',
    flexDirection: 'column',
    '&.tablet': {
      padding: '0 40px',
    },
    '&.mobile': {
      padding: '0 20px',
    }
  },
  heading: {
    color: ' #000',
    fontFamily: 'Montserrat-Semibold',
    fontWeight: 'bolder',
  },
  subHeading: {
    color: ' #000',
    fontFamily: 'Montserrat',
    whiteSpace: 'nowrap',
    fontSize:'17px'
  },
  subHeadingWrap: {
    color: ' #000',
    fontFamily: 'Montserrat',
    whiteSpace: 'wrap',
    fontSize:'17px'
  },
  appliedJob: {
    color: colors.institutionalRed,
    fontFamily: 'Montserrat-Medium',
    fontSize: '20px',
    textAlign: 'right'
  },
  appliedJobMobile: {
    color: colors.institutionalRed,
    fontFamily: 'Montserrat-Medium',
    fontSize: '16px',
    textAlign: 'right'
  },
  actionButton: {
    fontFamily: 'Montserrat-Light',
    borderRadius: '6px',
    backgroundColor: `${colors.institutionalRed}`,
    padding: '24px 16px',
    color: '#fff',
    fontSize: '16px',
  },
  bodyTitle: {
    fontFamily: 'Montserrat',
    fontSize: '30px',
    color: colors.principal
  },
  institutionName: {
    fontFamily: 'Montserrat',
    fontSize: '22px',
    color: '#000',
    marginBottom: '-6px'
  },
  profileImage: {
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    marginBottom: '20px',
    borderRadius: '10px',
  },
  profileImageMobile: {
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    margin: '0 auto 20px auto',
    borderRadius: '10px',
  },
  personalDataSectionMobile: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: '8px',
    marginBottom: '20px',
    marginTop: '40px',
  },
  subHeadingMini: {
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '15px'
  },
  experienceHeader: {
    color: colors.secundary,
    fontFamily: 'Montserrat-Semibold',
    fontSize: '25px',
    textTransform: 'capitalize'
  },
  experienceTitleItem: {
    color: '#000',
    fontFamily: 'Montserrat-Semibold',
    fontSize: '18px',
  },
  educationSubTitle: {
    color: '#000',
    fontSize: '16px',
      fontFamily: 'Montserrat-Semibold',
  },
  experienceItem: {
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '18px',
  },
  experienceItemDate: {
    color: '#525252',
    fontFamily: 'Montserrat-Light',
    fontSize: '18px',
  },
  seeMoreButton:{
    width:'100%',
    backgroundColor:'#fff',
    color:colors.institutionalRed,
    fontSize:'14px',
    fontFamily: 'Montserrat',
    padding:'20px 0',
    '&:hover':{
      backgroundColor:'#fff'
    }
  },
  subTitleItem: {
    fontFamily: 'Montserrat',
    fontSize: '25px',
    fontWeight: '600',
    color:colors.secundary
  },
  boldItemTitle:{
    fontFamily: 'Montserrat-Bold',
    fontSize: '18px',
    color:'#000'
  },
  // ***********************************
  ad: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.principal}`,
    padding: '25px 36px 25px 36px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  adTitle: { fontSize: '22px', fontWeight: 700, lineHeight: '27.5px', marginBottom: '10px' },
  adDataSection1: {
    display: 'grid',
    gridTemplateColumns: '2fr 1.5fr 1.5fr',
    rowGap: '12px',
    columnGap: '12px',
    marginBottom: '30px',
  },
  adDataSection2: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: '12px',
    columnGap: '12px',
    marginBottom: '30px',
  },
  adDataSection3: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    marginBottom: '30px',
  },
  adDataSection4: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: '12px',
    columnGap: '12px',
    marginBottom: '30px',
  },
  adDataItem: { display: 'flex', flexDirection: 'column', rowGap: '4px' },
  adDataItemNoData: { display: 'flex', flexDirection: 'row', columnGap: '4px' },
  adDataItemReferenceText: {
    fontSize: '16px',
    color: colors.black,
    fontWeight: 'bold',
  },

  adDataItemText: { fontSize: '16px', color: colors.black },
  adDataTextAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
  },
  adDataTextAreaNoDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '4px',
  },
  adDataTextAreaTitle: {
    fontSize: '16px',
    color: colors.black,
    fontWeight: 'bold',
  },
  adDataTextArea: { fontSize: '16px', color: colors.black },
  divider: {
    borderColor: colors.principal,
    marginBottom: '30px',
  },
  applied: {
    color: colors.red,
    fontWeight: 'bold',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  loaderStyles: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  adTitleMobile: { fontSize: '20px', fontWeight: 700, lineHeight: '25.5px', marginBottom: '10px' },

  adDataSectionMobile: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
    columnGap: '12px',
    marginBottom: '30px',
  },
  adDataItemMobile: { display: 'flex', flexDirection: 'column', rowGap: '8px' },
  actionButtonMobile: {
    width: '100%',
    height: '3rem',
    fontSize: '20px',
    borderRadius: '5px',
    backgroundColor: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    color: '#fff',
    marginBottom: '30px',
  },
  appliedMobile: {
    color: colors.red,
    fontWeight: 'bold',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    marginBottom: '30px',
  },
  avatarMobile: {
    width: '100px',
    height: '100px',
    borderRadius: '100px',
    objectFit: 'contain',
    alignSelf: 'center',
  }
}
