import React, { useState } from 'react'

import { Box } from '@chakra-ui/react'
import Institutions from './Institutions'
import { Requests } from './Requests'
import Users from './Users'
import { abmPanelStyles } from './styles'
import { sideMenuLinks } from '../../utils/abm'

const AbmPanel: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string>('1')

  const handleSelectView = (id: string) => {
    setSelectedOption(id)
  }

  const renderOptions = (option: string) => {
    switch (option) {
      case '1':
        return <Users />

      case '2':
        return <Requests />

      case '3':
        return <Institutions />

      default:
        break
    }
  }

  return (
    <Box sx={abmPanelStyles.panel}>
      <Box sx={abmPanelStyles.sideMenu}>
        {sideMenuLinks.map((e) => (
          <Box
            sx={
              selectedOption === e.id
                ? abmPanelStyles.sideMenuOptionActive
                : abmPanelStyles.sideMenuOption
            }
            key={e.id}
            onClick={() => handleSelectView(e.id)}
          >
            {e.menuName}
          </Box>
        ))}
      </Box>
      <Box p={7} flex={'1 0'}>
        {renderOptions(selectedOption)}
      </Box>
    </Box>
  )
}

export default AbmPanel
