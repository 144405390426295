import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { NavigateFunction, useNavigate } from 'react-router'
import { generateClean, generateRequest } from '../../../state/modules/login/actions'
import { useDispatch, useSelector } from 'react-redux'

import { PostGenerate } from '../../../state/modules/login/services/types'

const initialValues: PostGenerate = {
  email: '',
}

const toastMessages: UseToastOptions[] = [
  {
    description: 'Se envio email correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Usuario no registrado',
    status: 'error',
    duration: 2000,
  },
]

const ResetForm: FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [isSending, setIsSending] = useState(false)
  const toast = useToast()
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state.login)
  const navigate = useNavigate()

  const onSubmit = (values: PostGenerate) => {
    dispatch(generateRequest(values))
    setIsSending(true)
  }

  useEffect(() => {
    if (
      state.generateSuccess &&
      state.generateSuccess !== null &&
      state.generateSuccess !== undefined
    ) {
      toast(toastMessages[0])
      dispatch(generateClean())
      setTimeout(() => navigate('/password/code'), 1000)
    }
    if (state.generateSuccess === false) {
      toast({
        description: state.statusGenerate.response?.data?.message,
        status: 'error',
        duration: 2000,
      })
      dispatch(generateClean())
    }
    setIsSending(false)
  }, [state.generateSuccess])

  const validateEmail = (value: any) => {
    let error
    if (!value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      error = 'Ingrese un email'
    }
    return error
  }

  return (
    <Box p={isMobile ? 8 : 0}>
      <Center>
        <Heading as='h4' size='md' mb={12}>
          Recupero de contraseña
        </Heading>
      </Center>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Stack spacing={12}>
              <FormControl>
                <FormLabel htmlFor='email' fontSize={'sm'}>
                  Ingrese la dirección de email con la que se registró para poder cambiar la
                  contraseña
                </FormLabel>
                <Field
                  as={Input}
                  id='email'
                  name='email'
                  fontSize={'sm'}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.email && touched.email}
                  validate={validateEmail}
                />
                {errors.email && touched.email ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.email}
                  </Text>
                ) : null}
                <Box mt={2}>
                  <Text
                    fontSize={'xs'}
                    fontWeight={'normal'}
                    align={isMobile ? 'center' : 'inherit'}
                  >
                    A este correo le llegará un código para realizar el proceso de cambio de
                    contraseña
                  </Text>
                </Box>
              </FormControl>
              <Button
                type='submit'
                colorScheme={errors.email ? 'gray' : 'blue'}
                isLoading={isSending}
              >
                Enviar
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default ResetForm
