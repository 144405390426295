import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  useSteps,
} from '@chakra-ui/react'
import React, { createRef, FC, useEffect, useRef, useState } from 'react'
import { candidateProfileStyles } from '../../styles'
import { CandidateJobExperienceProps } from '../../_types'
import { dateFormater } from '../../../../utils/profile'
import { candidateProfileDataSet } from '../../../../utils/candidates'
import { transformDate } from '../../../../utils/jobAds'
import { JobsExperienceRules } from '../../../profile/forms-edit/form-jobs-experience-edit/_types'

const ExperiencesStepper: FC<any> = ({ experiences }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  return (
    <>
      {experiences.map((experience: any, i: number) => {
        return (
          <Box
            key={i}
            display='flex'
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='flex-start'
              pt='20px'
              position='relative'
            >
              {/* circle */}
              <Box
                w='16px'
                h='16px'
                bgColor='#56769A'
                borderRadius='50%'
                position='absolute'
                left='0'
                top={(i === 0) ? '10px' : '35px'}
                zIndex='1'
              ></Box>

              {/* line */}
              <Box
                w='1px'
                h={(i === 0) ? '95%' : '100%'}
                bgColor='#DDE4EB'
                position='absolute'
                left='7px'
                bottom={(i === 0) ? '0' : 'unset'}
                top={(i === 0) ? 'unset' : '0'}
              ></Box>
            </Box>

            <Box
              h='fit-content'
              display='flex'
              flexDirection='column'
              m={(i === 0) ? '0 0 0 25px' : '25px 0 0 25px'}
            >
              <Text sx={candidateProfileStyles.experienceHeader} mb='10px'>{experience.jobDescription}</Text>
              <Text sx={candidateProfileStyles.experienceItem} mb='10px'>
                <span style={candidateProfileStyles.experienceTitleItem}>Institución: </span>{experience.institution}
              </Text>
              <Text sx={candidateProfileStyles.experienceItem} mb='10px'>
                <span style={candidateProfileStyles.experienceTitleItem}>Tareas realizadas: </span>{experience.jobPosition}
              </Text>
              <Text sx={candidateProfileStyles.experienceItem} mb='10px'>
                <span style={candidateProfileStyles.experienceTitleItem}>Nombre de referencia: </span>{experience.referenceName}
              </Text>
              <Text sx={candidateProfileStyles.experienceItem} mb='10px'>
                <span style={candidateProfileStyles.experienceTitleItem}>Email de referencia: </span>{experience.referenceEmail}
              </Text>
              <Text sx={candidateProfileStyles.experienceItem} mb='10px'>
                <span style={candidateProfileStyles.experienceTitleItem}>Teléfono de referencia: </span>{experience.referencePhoneNumber}
              </Text>
              <Text sx={candidateProfileStyles.experienceItemDate}>
                {`${transformDate(experience.dateFrom)} - ${experience.dateTo != null
                  ? transformDate(experience.dateTo)
                  : 'Actualmente'}`}
              </Text>
            </Box>
          </Box>
        )
      }
      )}
    </>
  )
}

export default ExperiencesStepper
