import { colors } from '../../../utils/colors'

export const institutionsStyles = {
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  addInstitutionSection: { display: 'flex', justifyContent: 'space-between' },
  addInstitution: {
    fontWeight: 'bold',
    cursor: 'pointer',
    border: `1px solid ${colors.principal}`,
    padding: '8px 16px',
    borderRadius: '5px ',
    color: colors.principal,
  },
  icon: { marginLeft: '10px', boxSize: '15px' },
  institutionsQuantity: { color: colors.principal, fontWeight: 'bold' },
}
