import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { FC } from 'react'

import { CandidateEducationProps } from '../_types'
import { candidateProfileStyles } from '../styles'
import { transformDate } from '../../../utils/jobAds'

const CandidateEducation: FC<CandidateEducationProps> = ({ titles, verified }) => {
  const teacherTitles = titles?.filter((item: any) => item.type === 'docente')
  const nonTeachearTitles = titles?.filter((item: any) => item.type === 'nodocente')
  const courses = titles?.filter((item: any) => item.type === 'curso')

  return (
    <Card
      boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
      borderRadius='12px'
      p={['20px', '40px 68px']}
    >
      <CardHeader p='0' mb='20px'>
        <Heading sx={candidateProfileStyles.bodyTitle}>Educación</Heading>
      </CardHeader>

      <CardBody p='0'>
        {/* BODY TITLE */}
        <Box
          display='flex'
          flexDirection={['column', 'row']}
          flexWrap='wrap'
          w='100%'
        >
          {/* DOCENTE */}
          {teacherTitles?.length > 0 && (
            <Box
              w={['100%', '33%', '100%']}
              display='flex'
              flexDirection='column'
            >
              <Text sx={candidateProfileStyles.subTitleItem}>Docente</Text>
              {teacherTitles.map((title: any, i: number) => (
                <Box key={i} m={`20px 0 ${(i === (teacherTitles.length - 1)) ? '0' : '20px'} 0`} >
                  <Text sx={candidateProfileStyles.boldItemTitle} mb='8px'>Título: {title.teacherTitle}</Text>
                  <Text sx={candidateProfileStyles.experienceItem} mb='8px'>
                    <span style={candidateProfileStyles.experienceTitleItem}>Institución: </span>{title.institution}
                  </Text>
                  <Text sx={candidateProfileStyles.experienceItemDate}>
                    {`${title.fromDate} - ${((title.toDate === null || title.toDate === '') && title.percentage !== '') ? `En curso (${title.percentage} %)` : title.toDate} `}
                  </Text>
                </Box>
              ))}
            </Box>)}

          {/* NO DOCENTE */}
          {nonTeachearTitles?.length > 0 && <Box
            height='0.7px'
            width='100%'
            bgColor='#DDE4EB'
            pl='25px'
            my='35px'
          >
          </Box>}

          {nonTeachearTitles?.length > 0 && (
            <Box
              w={['100%', '33%', '100%']}
              display='flex'
              flexDirection='column'
            >
              <Text sx={candidateProfileStyles.subTitleItem}>No docente</Text>
              {nonTeachearTitles.map((title: any, i: number) => (
                <Box key={i} m={`20px 0 ${(i === (teacherTitles.length - 1)) ? '0' : '20px'} 0`} >
                  <Text sx={candidateProfileStyles.boldItemTitle} mb='8px'>Título: {title.title}</Text>
                  <Text sx={candidateProfileStyles.experienceItem} mb='8px'>
                    <span style={candidateProfileStyles.experienceTitleItem}>Institución: </span>{title.institution}
                  </Text>
                  <Text sx={candidateProfileStyles.experienceItemDate}>
                    {`${title.schoolFromDate} - ${((title.schoolToDate === null || title.schoolToDate === '') && title.percentage !== '') ? `En curso (${title.percentage} %)` : title.schoolToDate} `}
                  </Text>
                </Box>
              ))}
            </Box>
          )}

          {/* CURSOS */}

          {courses?.length > 0 && <Box
            height='0.7px'
            width='100%'
            bgColor='#DDE4EB'
            pl='25px'
            my='35px'
          >
          </Box>}

          {courses?.length > 0 && (
            <Box
              w={['100%', '33%', '100%']}
              display='flex'
              flexDirection='column'
            >
              <Text sx={candidateProfileStyles.subTitleItem}>Cursos</Text>
              {courses.map((course: any, i: number) => (
                <Box key={i} m={`20px 0 ${(i === (teacherTitles.length - 1)) ? '0' : '20px'} 0`} >
                  <Text sx={candidateProfileStyles.boldItemTitle} mb='8px'>Título: {course.title}</Text>
                  <Text sx={candidateProfileStyles.experienceItem} mb='8px'>
                    <span style={candidateProfileStyles.experienceTitleItem}>Institución: </span>{course.institution}
                  </Text>

                  <Text sx={candidateProfileStyles.experienceItemDate}>
                    {`${course.fromDate} - ${course.toDate}`}
                  </Text>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </CardBody>
    </Card>
  )
}

export default CandidateEducation
