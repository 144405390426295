import { AxiosPromise } from 'axios'
import httpModule from '../../../../utils/http'
import {
  EditInstitutionEmailNotificationPayload,
  EditUserEmailNotificationPayload,
  UserNotificationsPayload,
} from './types'

const paths = {
  USER_NOTIFICATIONS: '/users/settings/',
  EDIT_USER_EMAIL_NOTIFICATIONS: '/users/emailSubscription',
  EDIT_INSTITUTION_EMAIL_NOTIFICATIONS: '/users/institution/emailSubscription',
}

export const getUserEmailNotificationService = (
  payload: UserNotificationsPayload,
): AxiosPromise<any> => {
  return httpModule.get(paths.USER_NOTIFICATIONS + payload.id, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const postUserEmailNotificationService = (
  payload: EditUserEmailNotificationPayload,
): AxiosPromise<any> => {
  return httpModule.post(
    paths.EDIT_USER_EMAIL_NOTIFICATIONS,
    {
      userId: payload.id,
      emailSubscribed: payload.emailSubscribed,
    },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const postInstitutionEmailNotificationService = (
  payload: EditInstitutionEmailNotificationPayload,
): AxiosPromise<any> => {
  return httpModule.post(
    paths.EDIT_INSTITUTION_EMAIL_NOTIFICATIONS,
    {
      institutionId: payload.id,
      emailSubscribed: payload.emailSubscribed,
    },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}
