import React from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
} from '@chakra-ui/react'
import { confirmModalStyles } from './styles'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  text?: string
  title?: string
  onConfirm: any
  buttonOne: any
  buttonTwo: any
  styles?: any
}

const ConfirmModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  text,
  title,
  buttonOne,
  buttonTwo,
  onConfirm,
  styles,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const name = e.currentTarget.name
    if (name === buttonOne.toLowerCase()) {
      onConfirm()
      onClose()
    } else if (name === buttonTwo.toLowerCase()) {
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={styles ? ((isMobile) ? styles.containerMobile : styles.container) : confirmModalStyles.container}>
        <ModalHeader sx={styles ? styles.title : confirmModalStyles.title}>{title}</ModalHeader>
        <ModalCloseButton />
        {text && <ModalBody>{text}</ModalBody>}

        <ModalFooter sx={styles ? styles.buttonBox : confirmModalStyles.buttonBox}>
          <Button name={buttonOne.toLowerCase()} colorScheme='blue' mr={3} onClick={handleClick}>
            {buttonOne}
          </Button>
          <Button name={buttonTwo.toLowerCase()} variant='ghost' onClick={handleClick}>
            {buttonTwo}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmModal
