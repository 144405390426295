export const sideMenuLinks = [
  {
    menuName: 'Usuarios',
    id: '1',
    isShown: true,
  },
  // {
  //   menuName: 'Solicitudes',
  //   id: '2',
  //   isShown: true,
  // },
  {
    menuName: 'Instituciones',
    id: '3',
    isShown: true,
  },
]

export const usersFields = [
  {
    name: 'Nombre',
    id: 'name',
    type: 'text',
    isShown: true,
    isAdded: true,
    isEdited: true,
    isFiltered: true,
    width: '15',
    order: 2,
  },
  {
    name: 'Apellido',
    id: 'lastname',
    type: 'text',
    isShown: true,
    isAdded: true,
    isEdited: true,
    isFiltered: true,
    width: '15',
    order: 1,
  },
  {
    name: 'Email',
    id: 'email',
    type: 'text',
    isShown: true,
    isAdded: true,
    isEdited: true,
    isFiltered: true,
    width: '25',
    order: 4,
  },
  {
    name: 'DNI',
    id: 'dni',
    type: 'text',
    isShown: true,
    isAdded: true,
    isEdited: true,
    isFiltered: true,
    width: '15',
    order: 3,
  },
  {
    name: 'Estado',
    id: 'state',
    type: 'boolean',
    isShown: true,
    isAdded: false,
    isEdited: false,
    isFiltered: false,
    width: '15',
    order: 5,
  },
  {
    name: 'CUIL',
    id: 'cuil',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 6,
  },

  {
    name: 'Teléfono',
    id: 'phoneNumber',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 7,
  },
  {
    name: 'Teléfono Celular',
    id: 'cellPhone',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 8,
  },
  {
    name: 'Género',
    id: 'gender',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 9,
  },
  {
    name: 'Estado civil',
    id: 'civilStatus',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 10,
  },
  {
    name: 'Fecha de nacimiento',
    id: 'birthdate',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 11,
  },
  {
    name: 'Edad',
    id: 'age',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 12,
  },
  {
    name: 'Nacionalidad',
    id: 'nationality',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 13,
  },
  {
    name: 'Obra social',
    id: 'prepaidHealthTrue',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 14,
  },
  {
    name: 'Prestador obra social',
    id: 'prepaidHealth',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 15,
  },
  {
    name: 'Calle',
    id: 'street',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 16,
  },
  {
    name: 'Número',
    id: 'number',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 17,
  },
  {
    name: 'Piso',
    id: 'floor',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 18,
  },
  {
    name: 'Dpto.',
    id: 'department',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 19,
  },
  {
    name: 'Código postal',
    id: 'postalCode',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 20,
  },
  {
    name: 'Provincia',
    id: 'provinceId',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 21,
  },
  {
    name: 'Departamento/Partido',
    id: 'departmentId',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 22,
  },

  {
    name: 'Localidad',
    id: 'localityId',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 23,
  },
  {
    name: 'Rol',
    id: 'role',
    type: 'string',
    isShown: false,
    isAdded: true,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 24,
  },
]

export const requestsFields = [
  {
    name: 'Institución',
    id: 'institution',
    type: 'text',
    isShown: true,
    isApproved: true,
    width: '30',
    order: 1,
  },
  {
    name: 'CUE',
    id: 'cue',
    type: 'text',
    isShown: true,
    isApproved: true,
    width: '15',
    order: 2,
  },
  {
    name: 'Nombre',
    id: 'name',
    type: 'text',
    isShown: true,
    isApproved: true,
    width: '20',
    order: 5,
  },
  {
    name: 'Apellido',
    id: 'lastname',
    type: 'text',
    isShown: true,
    isApproved: true,
    width: '20',
    order: 4,
  },
  { name: 'DNI', id: 'dni', type: 'text', isShown: true, isApproved: true, width: '15', order: 6 },
  {
    name: 'Email',
    id: 'email',
    type: 'text',
    isShown: false,
    isApproved: true,
    width: '15',
    order: 7,
  },
  {
    name: 'Nro. provincial',
    id: 'provincialNumber',
    type: 'text',
    isShown: false,
    isApproved: true,
    width: '15',
    order: 3,
  },
  {
    name: 'Rol',
    id: 'roleId',
    type: 'text',
    isShown: false,
    isApproved: true,
    width: '15',
    order: 8,
  },
]

export const institutionFields = [
  {
    name: 'CUE',
    id: 'cue',
    type: 'string',
    isShown: true,
    isAdded: true,
    isEdited: true,
    isFiltered: true,
    width: null,
    order: 1,
    section: 'institution',
  },
  {
    name: 'Nro. Provincial',
    id: 'provincialNumber',
    type: 'string',
    isShown: true,
    isAdded: true,
    isEdited: true,
    isFiltered: true,
    width: null,
    order: 2,
    section: 'institution',
  },
  {
    name: 'Nombre de la institución',
    id: 'institutionName',
    type: 'string',
    isShown: true,
    isAdded: true,
    isEdited: true,
    isFiltered: true,
    width: null,
    order: 3,
    section: 'institution',
  },
  {
    name: 'Email',
    id: 'email',
    type: 'string',
    isShown: true,
    isAdded: true,
    isEdited: true,
    isFiltered: true,
    width: null,
    order: 4,
    section: 'institution',
  },
  {
    name: 'En trámite',
    id: 'inProcess',
    type: 'boolean',
    isShown: false,
    isAdded: true,
    isEdited: false,
    isFiltered: false,
    width: null,
    order: 5,
    section: 'institution',
  },
  {
    name: 'Logo de la institución',
    id: 'institutionLogo',
    type: 'file',
    isShown: false,
    isAdded: true,
    isEdited: false,
    isFiltered: false,
    width: null,
    order: 6,
    section: 'photo',
  },
  {
    name: 'Email del individuo',
    id: 'userEmail',
    type: 'string',
    isShown: false,
    isAdded: true,
    isEdited: false,
    isFiltered: false,
    width: null,
    order: 7,
    section: 'user',
  },

  {
    name: 'Apellido y nombre del Individuo',
    id: 'userName',
    type: 'string',
    isShown: false,
    isAdded: true,
    isEdited: false,
    isFiltered: false,
    width: null,
    order: 8,
    section: 'user',
  },

  {
    name: 'DNI del Individuo',
    id: 'userDNI',
    type: 'string',
    isShown: false,
    isAdded: true,
    isEdited: false,
    isFiltered: false,
    width: null,
    order: 10,
    section: 'user',
  },
  {
    name: 'Teléfono del Individuo',
    id: 'userCellphone',
    type: 'string',
    isShown: false,
    isAdded: true,
    isEdited: false,
    isFiltered: false,
    width: null,
    order: 11,
    section: 'user',
  },
  {
    name: 'Rol del Individuo',
    id: 'userRole',
    type: 'string',
    isShown: false,
    isAdded: true,
    isEdited: false,
    isFiltered: false,
    width: null,
    order: 12,
    section: 'user',
  },
  {
    name: 'Nivel',
    id: 'level',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 13,
    section: 'institution',
  },
  {
    name: 'Característica',
    id: 'levelModalities',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 14,
    section: 'institution',
  },
  {
    name: 'Modalidad',
    id: 'institutionTypes',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 15,
    section: 'institution',
  },
  {
    name: 'Cantidad de alumnos',
    id: 'studentsQuantity',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 16,
    section: 'institution',
  },
  {
    name: 'Cantidad de empleados',
    id: 'employeesQuantity',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 17,
    section: 'institution',
  },
  {
    name: 'Provincia',
    id: 'provinceId',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 18,
    section: 'institution',
  },

  {
    name: 'Departamento/Partido',
    id: 'departmentId',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 19,
    section: 'institution',
  },
  {
    name: 'Localidad',
    id: 'localityId',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 20,
    section: 'institution',
  },
  {
    name: 'Región',
    id: 'regionId',
    type: 'string',
    isShown: false,
    isAdded: false,
    isEdited: true,
    isFiltered: false,
    width: null,
    order: 10,
    section: 'institution',
  },
]

export const associatedUsersFields = [
  {
    name: 'Email',
    id: 'userEmail',
    type: 'string',
    isShown: true,
    isAdded: true,
    width: null,
    order: 4,
    section: 'user',
  },

  {
    name: 'Nombre',
    id: 'userName',
    type: 'string',
    isShown: true,
    isAdded: true,
    width: null,
    order: 1,
    section: 'user',
  },

  {
    name: 'Apellido',
    id: 'userLastname',
    type: 'string',
    isShown: true,
    isAdded: true,
    width: null,
    order: 2,
    section: 'user',
  },

  {
    name: 'DNI',
    id: 'userDNI',
    type: 'string',
    isShown: true,
    isAdded: true,
    width: null,
    order: 3,
    section: 'user',
  },
  {
    name: 'Teléfono celular',
    id: 'userCellphone',
    type: 'string',
    isShown: true,
    isAdded: true,
    width: null,
    order: 5,
    section: 'user',
  },
  {
    name: 'Rol',
    id: 'userRole',
    type: 'string',
    isShown: true,
    isAdded: true,
    width: null,
    order: 6,
    section: 'user',
  },
  {
    name: 'Estado',
    id: 'userStatus',
    type: 'string',
    isShown: true,
    isAdded: false,
    width: null,
    order: 7,
    section: 'user',
  },
]

export const calcularAge = (dateOfBirth: string): number => {
  const today = new Date()
  const birthDate = new Date(dateOfBirth)
  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDifference = today.getMonth() - birthDate.getMonth()
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() <= birthDate.getDate())) {
    age--
  }
  return age
}
