import { useEffect, useState } from 'react'
import {
  Box,
  useMediaQuery,
  Text,
  Button,
  useToast,
  Heading,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react'

import { useDispatch, useSelector } from 'react-redux'
import {
  preferenceDeleteClean,
  preferencesClean,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { preferencesStyles } from './styles'
import { AddIcon, QuestionIcon } from '@chakra-ui/icons'
import PreferenceForm from './PreferenceForm'
import PreferenceEdit from '../../forms-edit/form-preference-edit'
import { JwtData } from '../../../../state/modules/login/services/types'
import jwtDecode from 'jwt-decode'
import Loader from '../../../loader'
import ConfirmModal from '../../../confirmModal'
import { formsStyles } from '../styles'
import { colors } from '../../../../utils/colors'

const Preferencias = () => {
  const [showPreference, setShowPreference] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const toast = useToast()
  const message =
    'Aquí cargarás el/los puesto/s de trabajo a los que aspiras y resultará indispensable para las búsquedas'

  const {
    preferencesSuccess,
    statusPreferences,
    userInformation,
    deletePreferenceSuccess,
    statusDeletePreference,
  } = useSelector((state: any) => state?.user)
  const dispatch = useDispatch()
  const toastDuration = 2000

  const modalStyles = {
    buttonBox: { display: 'flex', flexDirection: 'row-reverse', justifyContent: 'end' },
  }

  const handleShow = () => {
    setShowPreference(true)
  }

  useEffect(() => {
    if (preferencesSuccess) {
      toast({
        description: statusPreferences?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(preferencesClean())
      setShowPreference(false)
    }
    if (preferencesSuccess === false) {
      toast({
        description: statusPreferences?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(preferencesClean())
    }
  }, [preferencesSuccess])

  useEffect(() => {
    if (deletePreferenceSuccess) {
      toast({
        description: statusDeletePreference?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(preferenceDeleteClean())
      setShowPreference(false)
    }
    if (deletePreferenceSuccess === false) {
      toast({
        description: statusDeletePreference?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(preferenceDeleteClean())
    }
  }, [deletePreferenceSuccess])

  useEffect(() => {
    if (preferencesSuccess || deletePreferenceSuccess) {
      const session = localStorage.getItem('access_token')
      const sessionComenius = localStorage.getItem('access_token_comenius')
      if (session !== null && sessionComenius === null) {
        const jwtTokenData: JwtData = jwtDecode(session)
        dispatch(userInformationRequest({ userId: jwtTokenData.id.toString() }))
      }
    }
  }, [preferencesSuccess, deletePreferenceSuccess])

  return (
    <Box maxWidth='100%' margin='0 auto' display='flex' flexDirection='column' alignItems='center'>
      <Heading sx={preferencesStyles.heading}>
        Preferencias laborales
        {isMobile ? (
          <Popover>
            <PopoverTrigger>
              <Button backgroundColor='transparent'>
                <QuestionIcon color={colors.principal} />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody sx={formsStyles.popOverMobile}>{message}</PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <Tooltip label={message}>
            <QuestionIcon />
          </Tooltip>
        )}
      </Heading>
      {userInformation !== null && userInformation !== undefined ? (
        userInformation?.Preferences?.length === 0 ? (
          <Box sx={preferencesStyles.noResults}>
            <Text>*No existen registros asociados</Text>
          </Box>
        ) : (
          <PreferenceEdit />
        )
      ) : (
        <Loader props={preferencesStyles.loading} />
      )}
      <Box
        sx={isMobile ? preferencesStyles.preferenceFormMobile : preferencesStyles.preferenceForm}
      >
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          title='¿Desea agregar otro item'
          buttonOne={'No'}
          buttonTwo={'Si'}
          onConfirm={() => window.location.reload()}
          styles={modalStyles}
        />
        {showPreference && (
          <PreferenceForm
            setShowPreference={setShowPreference}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
          />
        )}
        <Button
          isDisabled={showPreference}
          sx={formsStyles.saveButton}
          mt={4}
          name='addExperience'
          alignSelf='center'
          width={isMobile ? 'auto' : '70%'}
          onClick={handleShow}
        >
          Agregar preferencia <AddIcon boxSize={4} ml={2} />
        </Button>
      </Box>
    </Box>
  )
}

export default Preferencias
