import { Box, GenericAvatarIcon, Icon, Table, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react'
import { EditIcon, ViewIcon } from '@chakra-ui/icons'
import { InstitutionDataField, InstitutionDataShow, InstitutionsTableProps } from './_types'
import React, { FC } from 'react'

import { Link } from 'react-router-dom'
import Loader from '../../../loader'
import Paginator from '../../../Paginator'
import { institutionFields } from '../../../../utils/abm'
import { institutionsTableStyles } from './style'
import { useSelector } from 'react-redux'

const InstitutionsTable: FC<InstitutionsTableProps> = ({
  searchFilter,
  setCurrentPage,
  setCurrentInstitution,
  currentPage,
  showModal,
}) => {
  const institutionsList = useSelector((state: any) => state?.abm?.institutionsInformation)

  const showedFields = institutionFields
    .filter((e) => e.isShown)
    .sort((e1, e2) => e1.order - e2.order)

  const handleEditInstitution = (institution: any) => {
    showModal('6')
    setCurrentInstitution(institution)
  }

  const handleAssociatedUSers = (institution: any) => {
    showModal('7')
    setCurrentInstitution(institution)
  }
  return (
    <Box sx={institutionsTableStyles.box}>
      {!institutionsList ? (
        <Loader props={institutionsTableStyles.loading} />
      ) : institutionsList?.rows?.length === 0 ? (
        searchFilter !== null ? (
          <Box sx={institutionsTableStyles.noResultsContainer}>
            <Text sx={institutionsTableStyles.noResults}>
              *No existen registros acordes a los filtros solicitados
            </Text>
          </Box>
        ) : (
          <Text sx={institutionsTableStyles.noResults}>*No existen registros asociados</Text>
        )
      ) : (
        <>
          <Box>
            <Table colorScheme='facebook' sx={institutionsTableStyles.table}>
              <Thead>
                <Tr>
                  {showedFields.map((e: InstitutionDataField) => (
                    <Td key={e.order} sx={institutionsTableStyles.tableHead}>
                      {e.name}
                    </Td>
                  ))}
                  <Td sx={institutionsTableStyles.tableHead}>Acción</Td>
                </Tr>
              </Thead>
              <Tbody>
                {institutionsList?.rows?.map((e: InstitutionDataShow, i: number) => (
                  <Tr key={i}>
                    <Td sx={institutionsTableStyles.tableData} width={'15%'}>
                      {e.cue}
                    </Td>
                    <Td sx={institutionsTableStyles.tableData} width={'15%'}>
                      {e.provincialNumber}
                    </Td>
                    <Td sx={institutionsTableStyles.tableData} width={'32%'}>
                      {e.name}
                    </Td>
                    <Td sx={institutionsTableStyles.tableData} width={'28%'}>
                      {e.email || '-'}
                    </Td>
                    <Td sx={institutionsTableStyles.tableData} width={'auto'}>
                      <Box sx={institutionsTableStyles.actionBox}>
                        <Link to={`/institution/detail/${e.id}`} target='_blank'>
                          <Icon as={ViewIcon} cursor='pointer' boxSize={4} color={'#2980B9'} />
                        </Link>
                        <Icon
                          as={EditIcon}
                          cursor='pointer'
                          boxSize={4}
                          color={'#2980B9'}
                          onClick={() => handleEditInstitution(e)}
                        />
                        <Icon
                          as={GenericAvatarIcon}
                          cursor='pointer'
                          boxSize={5}
                          color={'#2980B9'}
                          onClick={() => handleAssociatedUSers(e)}
                        />
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Paginator
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            elements={institutionsList?.rows}
            elementsPerpage={10}
            elementsLength={institutionsList?.count}
          />
        </>
      )}
    </Box>
  )
}

export default InstitutionsTable
