import React from 'react'
import LandingLayout from '../../layouts/landing'
import { VStack } from '@chakra-ui/react'
import ApplicationsHandler from '../../components/ApplicationsHandler'

export const JobApplicationsHandler: React.FC = () => {
  return (
    <LandingLayout>
      <VStack>
        <ApplicationsHandler />
      </VStack>
    </LandingLayout>
  )
}

export default JobApplicationsHandler
