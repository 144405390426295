import React, { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import {
  Box,
  FormControl,
  useMediaQuery,
  FormLabel,
  Button,
  Input,
  Text,
  UseToastOptions,
} from '@chakra-ui/react'
import { FormDataOwn } from './_types'
import { useDispatch, useSelector } from 'react-redux'
import { ownDataRequest, userInformationRequest } from '../../../../state/modules/user/actions'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { PostInstitutionForm } from '../_typesinstitution'

const toastMessages: UseToastOptions[] = [
  {
    description: 'Datos guardados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const OwnData = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const state = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const { id } = useParams()
  const userInformation = useSelector((state: any) => state.user?.userInformation ?? '')
  const institutoFilter = userInformation?.Institutions.filter((item: any) => item.id === id)
  const [cuit, setCuit] = useState(
    institutoFilter && institutoFilter.length > 0 && institutoFilter[0]?.cuit
      ? `${institutoFilter[0]?.cuit?.slice(0, 2)}-${institutoFilter[0]?.cuit?.slice(
          2,
          10,
        )}-${institutoFilter[0]?.cuit?.slice(10)}`
      : '',
  )

  const initialValues: FormDataOwn = {
    razonsocial:
      institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.businessName : '',
    cuit: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.cuit : '',
    name: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.name : '',
  }

  const handleSubmit = (values: any) => {
    const updatedInitialValuesForm: PostInstitutionForm = {
      id: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.id : '',
      userId: state.user.userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      cuit: values.cuit ?? '',
      businessName: values.razonsocial ?? '',
      name: values.name ?? '',
      email: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.email : '',
      cue: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.cue : '',
      region: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.region : '',
      provincialNumber:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.provincialNumber : '',
      phoneNumber:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.phoneNumber : '',
      employeesQuantity:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.employeesQuantity : '',
      studentsQuantity:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.studentsQuantity : '',
      inProcess: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.inProcess : '',
      webURL: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.webURL : '',
      facebookURL:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.facebookURL : '',
      instagramURL:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.instagramURL : '',
      linkedinURL:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.linkedinURL : '',
      twitterURL:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.twitterURL : '',
      Address: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.Address : '',
      levelId: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.levelId : '',
      modality: institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.modality : '',
      typeInstitution:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.typeInstitution : '',
      description:
        institutoFilter && institutoFilter.length > 0 ? institutoFilter[0]?.description : '',
    }
    dispatch(ownDataRequest(updatedInitialValuesForm))
    dispatch(userInformationRequest({ userId: state.user.userInformation.id }))
  }
  const validateSchema = Yup.object({
    razonsocial: Yup.string().max(30, 'Máximo 30 caracteres').required('Campo requerido'),
    cuit: Yup.string()
      .min(11, 'El número de CUIT debe tener 11 dígitos')
      .required('Campo requerido'),
    name: Yup.string().max(50, 'Máximo 50 caracteres').required('Campo requerido'),
  }).defined()

  const handleChangeCuit = (event: any, setFieldValue: any) => {
    const value = event.target.value.split('-').join('')
    const isNumber = /^([0-9-])*$/.test(value)
    if (isNumber) {
      if (value.length > 2 && value.length < 11) {
        const arr = value.slice(0, 2) + '-' + value.slice(2)
        setCuit(arr)
        setFieldValue('cuit', arr.split('-').join(''))
      } else if (value.length > 10) {
        const arr = value.slice(0, 2) + '-' + value.slice(2, 10) + '-' + value.slice(10)
        setCuit(arr)
        setFieldValue('cuit', arr.split('-').join(''))
      } else {
        setCuit(value)
        setFieldValue('cuit', value)
      }
    }
  }

  return (
    <Box maxWidth='100%' margin='0 auto' display='flex' flexDirection='column' alignItems='center'>
      <Box
        display='flex'
        justifyContent='center'
        mt={state.user?.userInformation?.UserTitles.length === 0 ? 0 : 5}
      >
        <Text mb={4} width={isMobile ? '100%' : '100%'} fontWeight='bold'>
          DATOS DE PROPIETARIO
        </Text>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validateSchema}
      >
        {({ errors, touched, setFieldValue, handleChange, values }) => (
          <Form
            style={{
              display: 'flex',
              width: `${isMobile ? '100%' : '60%'}`,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <FormControl>
              <FormLabel htmlFor='razonsocial'>Razón Social*</FormLabel>
              <Field as={Input} mb={4} type='text' name='razonsocial' placeholder='razón social' />
              {errors.razonsocial && touched.razonsocial ? (
                <Text color='red' fontSize={'sm'}>
                  {errors.razonsocial}
                </Text>
              ) : null}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='cuit'>CUIT*</FormLabel>
              <Field
                value={cuit}
                as={Input}
                mb={4}
                type='text'
                name='cuit'
                maxLength={13}
                placeholder='Ej: 30336667778'
                onChange={(event: any) => handleChangeCuit(event, setFieldValue)}
              />
              {errors.cuit && touched.cuit ? (
                <Text color='red' fontSize={'sm'}>
                  {errors.cuit}
                </Text>
              ) : null}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='name'>Nombre de la institución *</FormLabel>
              <Field
                as={Input}
                mb={4}
                type='text'
                name='name'
                placeholder='Nombre de la institución'
              />
              {errors.name && touched.name ? (
                <Text color='red' fontSize={'sm'}>
                  {errors.name}
                </Text>
              ) : null}
            </FormControl>

            <Box display='flex' justifyContent='center' width={isMobile ? '100%' : 'auto'}>
              <Button mt={4} colorScheme='blue' width={isMobile ? '100%' : '100%'} type='submit'>
                Guardar cambios
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default OwnData
