import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  useSteps,
} from '@chakra-ui/react'
import React, { createRef, FC, useEffect, useRef, useState } from 'react'
import { candidateProfileStyles } from '../styles'
import { CandidateJobExperienceProps } from '../_types'
import { dateFormater } from '../../../utils/profile'
import { candidateProfileDataSet } from '../../../utils/candidates'
import { transformDate } from '../../../utils/jobAds'
import ExperiencesStepper from './ExperiencesStepper'

const CandidateJobExperience: FC<any> = ({
  experiences,
  skills,
  verified,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [firstsExperiences, setFirstsExperiences] = useState<any>([])

  useEffect(() => {
    if (experiences.length > 2) {
      setFirstsExperiences(experiences.slice(0, 2))
    }
  }, [experiences])

  return (
    <Card
      boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
      borderRadius='12px'
      p={['20px', '40px 68px 18px 68px']}
    >
      <CardHeader p='0' mb='20px'>
        <Heading sx={candidateProfileStyles.bodyTitle}>Experiencia laboral</Heading>
      </CardHeader>

      <CardBody p='0'>
        {!verified ? (
          <Box >
            <Text
              sx={
                isMobile
                  ? candidateProfileStyles.experienceItem
                  : candidateProfileStyles.experienceItem
              }
            >
              *Lo sentimos, pero no tiene permisos para ver esta información. <br />
              Si quieres ver esta información, puedes enviar una solicitud al Individuo, volviendo a
              la búsqueda de individuos y haciendo click en el ícono del sobre en la columna “Acción”.
            </Text>
          </Box>
        ) : (
          <Box>
            <ExperiencesStepper experiences={
              (experiences.length <= 2) ? (experiences) :
                isExpanded ? experiences : firstsExperiences}
            />
             <Box
              m='20px 0 12px 10px'
            >
              <Box
                height='0.7px'
                width='100%'
                bgColor='#DDE4EB'
              >
              </Box>
            </Box>
            {(experiences.length > 2) && <Button sx={candidateProfileStyles.seeMoreButton}
              onClick={() => setIsExpanded(isExpanded => !isExpanded)}>
              {isExpanded ? 'Ver menos' : 'Mostrar todas las experiencias laborales'}
            </Button>}
          </Box>
        )}
      </CardBody>
    </Card>

  )
}

export default CandidateJobExperience
