import * as types from './types'

import { favorites } from './_ducks'

export const getFoldersRequest = favorites.createAction(types.FOLDERS_LIST_GET_REQUEST)
export const getFoldersSuccess = favorites.createAction(types.FOLDERS_LIST_GET_SUCCESS)
export const getFoldersFailure = favorites.createAction(types.FOLDERS_LIST_GET_FAILURE)
export const getFoldersClean = favorites.createAction(types.FOLDERS_LIST_GET_CLEAN)

export const addFolderRequest = favorites.createAction(types.ADD_FOLDER_POST_REQUEST)
export const addFolderSuccess = favorites.createAction(types.ADD_FOLDER_POST_SUCCESS)
export const addFolderFailure = favorites.createAction(types.ADD_FOLDER_POST_FAILURE)
export const addFolderClean = favorites.createAction(types.ADD_FOLDER_POST_CLEAN)

export const renameFolderRequest = favorites.createAction(types.RENAME_FOLDER_PUT_REQUEST)
export const renameFolderSuccess = favorites.createAction(types.RENAME_FOLDER_PUT_SUCCESS)
export const renameFolderFailure = favorites.createAction(types.RENAME_FOLDER_PUT_FAILURE)
export const renameFolderClean = favorites.createAction(types.RENAME_FOLDER_PUT_CLEAN)

export const deleteFolderRequest = favorites.createAction(types.FOLDER_DELETE_REQUEST)
export const deleteFolderSuccess = favorites.createAction(types.FOLDER_DELETE_SUCCESS)
export const deleteFolderFailure = favorites.createAction(types.FOLDER_DELETE_FAILURE)
export const deleteFolderClean = favorites.createAction(types.FOLDER_DELETE_CLEAN)

export const getFavoritesByFolderRequest = favorites.createAction(
  types.FAVORITES_BY_FOLDER_GET_REQUEST,
)
export const getFavoritesByFolderSuccess = favorites.createAction(
  types.FAVORITES_BY_FOLDER_GET_SUCCESS,
)
export const getFavoritesByFolderFailure = favorites.createAction(
  types.FAVORITES_BY_FOLDER_GET_FAILURE,
)
export const getFavoritesByFolderClean = favorites.createAction(types.FAVORITES_BY_FOLDER_GET_CLEAN)

export const favoriteNoteRequest = favorites.createAction(types.FAVORITE_NOTE_PUT_REQUEST)
export const favoriteNoteSuccess = favorites.createAction(types.FAVORITE_NOTE_PUT_SUCCESS)
export const favoriteNoteFailure = favorites.createAction(types.FAVORITE_NOTE_PUT_FAILURE)
export const favoriteNoteClean = favorites.createAction(types.FAVORITE_NOTE_PUT_CLEAN)

export const favoriteUpdateRequest = favorites.createAction(types.FAVORITE_UPDATE_POST_REQUEST)
export const favoriteUpdateSuccess = favorites.createAction(types.FAVORITE_UPDATE_POST_SUCCESS)
export const favoriteUpdateFailure = favorites.createAction(types.FAVORITE_UPDATE_POST_FAILURE)
export const favoriteUpdateClean = favorites.createAction(types.FAVORITE_UPDATE_POST_CLEAN)
