import * as Yup from 'yup'

import { ArrowDownIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Link as ChakraLink,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { InstitutionData, NewJobField, NewJobFormValidation } from './_types'
import React, { FC, useEffect, useState } from 'react'
import { NavLink as ReactRouterLink, useNavigate } from 'react-router-dom'
import { availabilityOptions, newJobAdFields, positionTypeOptions } from '../../utils/jobAds'
import {
  institutionsClean,
  institutionsRequest,
  jobTypesClean,
  jobTypesRequest,
  newJobClean,
  newJobRequest,
  positionsClean,
  positionsRequest,
  subjectsClean,
  subjectsRequest,
  timeSlotsClean,
  timeSlotsRequest,
} from '../../state/modules/ads/actions'
import { suplenciesClean, suplenciesRequest } from '../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmModal from '../confirmModal'
import Loader from '../loader'
import Select from 'react-select'
import { newJobStyles } from './styles'

const NewJob: FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const dispatch = useDispatch()
  const toast = useToast()
  const [adValues, setAdValues] = useState<any>({
    cue: [],
    hidecue: false,
    hideinstitution: false,
    level: [],
    address: [],
    levelModalities: [],
    institutionTypes: [],
    title: '',
    publicationDate: '',
    expirationDate: '',
    vacancies: '',
    hours: '',
    positionType: '',
    position: '',
    availability: [],
    timePeriod: [],
    areaType: '',
    area: '',
    jobExperience: '',
    modality: '',
    suplencies: [],
    tasks: '',
    softSkills: '',
  })
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [draft, setDraft] = useState<boolean>(true)
  const ads = useSelector((state: any) => state?.ads)
  const user = useSelector((state: any) => state?.user)
  const institutions = useSelector((state: any) => state?.ads?.institutionsList)
  const timeSlots = useSelector((state: any) => state?.ads?.timeSlotsList)
  const jobTypes = useSelector((state: any) => state?.ads?.jobTypesList)
  const userId = useSelector((state: any) => state?.user?.userInformation?.id)
  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const [cueOptions, setCueOptions] = useState<any[]>([])
  const [institutionOptions, setInstitutionOptions] = useState<any[]>([])
  const [levelOptions, setLevelOptions] = useState<any[]>([])
  const [addressOptions, setAddressOptions] = useState<any[]>([])
  const [levels, setLevels] = useState<any[]>([])
  const [addresses, setAddresses] = useState<any[]>([])
  const [cues, setCues] = useState<any[]>([])
  const [positionType, setPositionType] = useState<string>('')
  const [ocupationOptions, setOcupationOptions] = useState<any[]>([])
  const [isPositionShow, setIsPositionShow] = useState<boolean>(false)
  const [positionOptions, setPositionOptions] = useState<any[]>([])
  const [position, setPosition] = useState<any[]>([])
  const [timeSlotOptions, setTimeSlotOptions] = useState<any[]>([])
  const [timePeriod, setTimePeriod] = useState<any[]>([])
  const [jobTypeOptions, setJobTypeOptions] = useState<any[]>([])
  const [modality, setModality] = useState<any[]>([])
  const [suplencyOptions, setSuplencyOptions] = useState<any[]>([])
  const [suplencies, setSuplencies] = useState<any[]>([])
  const [areaTypeOptions, setAreaTypeOptions] = useState<any[]>([])
  const [areaType, setAreaType] = useState<any[]>([])
  const [areasOptions, setAreasOptions] = useState<any[]>([])
  const [areas, setAreas] = useState<any[]>([])
  const [availability, setAvailability] = useState<any[]>([])
  const [institutionsLevelModalities, setInstitutionsLevelModalities] = useState<any>([])
  const [levelModalitiesOptions, setLevelModalitiesOptions] = useState<any>([])
  const [levelModalities, setLevelModalities] = useState<any>([])
  const [institutionTypesOptions, setInstitutionTypesOptions] = useState<any>([])
  const [institutionTypes, setInstitutionTypes] = useState<any>([])
  const instutionFields = newJobAdFields.filter((field) => field.section === 'institution')
  const adFields = newJobAdFields.filter(
    (field) => field.section === 'ad' && field.required === true,
  )
  const dateFields = newJobAdFields.filter(
    (field) => field.id === 'publicationDate' || field.id === 'expirationDate',
  )
  const optionalFields = newJobAdFields.filter(
    (field) =>
      field.section === 'ad' &&
      field.required === false &&
      field.id !== 'publicationDate' &&
      field.id !== 'expirationDate',
  )
  const [showOptionalFields, setShowOptionalFields] = useState<boolean>(false)
  const toastDuration = 2000
  const today = new Date().toISOString().split('T')[0]
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    if (userId !== null && userId !== undefined) {
      dispatch(institutionsRequest({ token, userId }))
      dispatch(timeSlotsRequest({ token }))
      dispatch(jobTypesRequest({ token }))
      dispatch(positionsRequest({ token }))
      dispatch(subjectsRequest({ token }))
      dispatch(suplenciesRequest({ token }))

      return () => {
        dispatch(institutionsClean())
        dispatch(timeSlotsClean())
        dispatch(jobTypesClean())
        dispatch(positionsClean())
        dispatch(subjectsClean())
        dispatch(suplenciesClean())
      }
    }
  }, [userId])

  useEffect(() => {
    if (ads) {
      const ocupations = ads?.positionsList
        ?.filter((pos: any) => pos.type == 'NO DOCENTE')
        .map((ocupation: any) => {
          return { value: ocupation.id, label: ocupation.description }
        })
      setOcupationOptions(ocupations)
      const positions = ads?.positionsList
        ?.filter((pos: any) => pos.type == 'DOCENTE')
        .map((position: any) => {
          return { value: position.id, label: position.description }
        })
      setPositionOptions(positions)

      const areaType = ads?.subjectsList
        ?.map((area: any) => area?.type)
        .filter((val: any, ind: number, arr: any) => arr.indexOf(val) === ind)
        .sort()
        .map((area: any, i: number) => {
          return {
            value: i + 1,
            label:
              area == 'CURRICULAR' ? 'Áreas curriculares' : 'Otras áreas de desarrollo docente',
            type: area,
          }
        })

      setAreaTypeOptions(areaType)
    }
  }, [ads])

  useEffect(() => {
    if (user) {
      const suplencies = user?.suplenciesList?.map((suplency: any) => {
        return { value: suplency.id, label: suplency.description }
      })
      setSuplencyOptions(suplencies)
    }
  }, [user])

  useEffect(() => {
    if (institutions) {
      setCueOptions([])
      institutions.map((institution: InstitutionData) => {
        const cue = institution?.cue.toString()
        const lastDigits = cue?.slice(-2)
        const firstLength = cue?.length - lastDigits?.length
        const transformedCue = `${cue?.slice(0, firstLength)}-${lastDigits}`

        setCueOptions((prev: any) => [
          ...prev,
          { value: institution?.cue, label: transformedCue, id: institution?.id },
        ])
      })
    }
  }, [institutions])

  useEffect(() => {
    if (timeSlots) {
      setTimeSlotOptions([])
      timeSlots.map((timeSlot: any) => {
        setTimeSlotOptions((prev: any) => [
          ...prev,
          { value: timeSlot?.id, label: timeSlot?.description },
        ])
      })
    }
  }, [timeSlots])

  useEffect(() => {
    if (jobTypes) {
      setJobTypeOptions([])
      jobTypes.map((jobType: any) => {
        setJobTypeOptions((prev: any) => [
          ...prev,
          { value: jobType?.id, label: jobType?.description },
        ])
      })
    }
  }, [jobTypes])

  useEffect(() => {
    if (ads?.newJobSuccess) {
      toast({
        title: 'Aviso creado',
        description: `Número de referencia: ${ads?.newJobStatus?.referenceNumber}`,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(newJobClean())
      setTimeout(() => {
        navigate('/careers/oportunities')
      }, 1000)
    }
    if (ads?.newJobSuccess === false) {
      toast({
        title: 'Error',
        description: ads?.newJobStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(newJobClean())
    }
  }, [ads?.newJobSuccess])

  const renderInput = (field: NewJobField, setFieldValue?: any) => {
    switch (true) {
      case 'availability' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required}
            </FormLabel>
            <Select
              placeholder='Selecciona tu disponibilidad'
              name={field.id}
              isMulti
              options={availabilityOptions}
              onChange={(value) => {
                handleChangeAvailability(value)
                setFieldValue(
                  'availability',
                  value.map((item: any) => {
                    return { day: item.value }
                  }),
                )
              }}
              value={availability}
            />
          </>
        )
      case 'area' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              name={field.id}
              options={areasOptions}
              onChange={(value) => {
                handleChangeAreas(value)
                setFieldValue('area', value?.value)
              }}
              value={areas}
            />
          </>
        )
      case 'areaType' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              name={field.id}
              options={areaTypeOptions}
              onChange={(value) => handleChangeAreaType(value, setFieldValue)}
              value={areaType}
            />
          </>
        )
      case 'suplencies' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona suplencias'
              name={field.id}
              options={suplencyOptions}
              onChange={(value) => {
                handleChangeSuplencies(value)
                setFieldValue(
                  'suplencies',
                  value.map((item: any) => {
                    return { id: item.value }
                  }),
                )
              }}
              value={suplencies}
              isMulti
            />
          </>
        )

      case 'modality' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona modalidad'
              name={field.id}
              options={jobTypeOptions}
              onChange={(value) => {
                handleChangeModality(value)
                setFieldValue('modality', value?.value)
              }}
              value={modality}
            />
          </>
        )
      case 'timePeriod' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona franja horaria'
              name={field.id}
              options={timeSlotOptions}
              onChange={(value) => {
                handleChangeTimePeriod(value)
                setFieldValue(
                  'timePeriod',
                  value.map((item: any) => {
                    return { id: item.value }
                  }),
                )
              }}
              value={timePeriod}
              isMulti
            />
          </>
        )
      case 'position' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona tu puesto'
              name={field.id}
              options={
                positionType === ''
                  ? []
                  : positionType === 'DOCENTE'
                  ? positionOptions
                  : ocupationOptions
              }
              value={position}
              onChange={(value) => {
                handleChangePosition(value)
                setFieldValue('position', value?.value)
              }}
            />
          </>
        )
      case 'positionType' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona tu tipo de cargo'
              name={field.id}
              options={positionTypeOptions}
              onChange={(value: any) => {
                handleChangePositionType(value, setFieldValue)
                setFieldValue('positionType', value?.value)
              }}
            />
          </>
        )
      case 'vacancies' === field.id || 'hours' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Field
              style={isMobile ? newJobStyles.textFieldMobile : {}}
              as={Input}
              min='1'
              type='number'
              name={field.id}
            />
          </>
        )
      case 'cue' === field.id:
        return (
          <>
            <FormLabel
              sx={isMobile ? newJobStyles.checkboxDisableMobile : newJobStyles.checkboxDisable}
            >
              {field.name} {field.required && '*'}
            </FormLabel>

            <Select
              placeholder='Selecciona tu CUE'
              name={field.id}
              styles={{
                menu: (baseStyles: any) => ({
                  ...baseStyles,
                }),
              }}
              isMulti
              options={cueOptions}
              onChange={(value) => {
                handleSearchCue(value, setFieldValue)
                setFieldValue(
                  'cue',
                  value.map((item: any) => {
                    return { id: item.id }
                  }),
                )
              }}
              value={cues}
            />
          </>
        )
      case 'institution' === field.id:
        return (
          <>
            <FormLabel
              sx={isMobile ? newJobStyles.checkboxDisableMobile : newJobStyles.checkboxDisable}
            >
              {field.name} {field.required && '*'}
              <FormLabel sx={isMobile ? newJobStyles.checkboxMobile : newJobStyles.checkbox}>
                <Field
                  as='input'
                  style={isMobile ? newJobStyles.checkboxFieldMobile : {}}
                  type='checkbox'
                  onChange={(e: any) => {
                    setFieldValue('hideinstitution', e.target.checked)
                  }}
                  name={`hide${field.id}`}
                />{' '}
                No mostrar este dato en el aviso
              </FormLabel>
            </FormLabel>
            <Select
              placeholder='Institución seleccionada'
              name={field.id}
              styles={{
                menu: (baseStyles: any) => ({
                  ...baseStyles,
                  display: 'none',
                }),
              }}
              isMulti
              value={institutionOptions}
            />
          </>
        )
      case 'level' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona tu nivel'
              name={field.id}
              styles={{
                menu: (baseStyles) => ({
                  ...baseStyles,
                }),
              }}
              isMulti
              options={levelOptions}
              onChange={(value) => {
                handleLevel(value, setFieldValue)
                setFieldValue(
                  'level',
                  value.map((item: any) => {
                    return { id: item.value }
                  }),
                )
              }}
              value={levels}
            />
          </>
        )
      case 'address' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona tu localidad'
              name={field.id}
              styles={{
                menu: (baseStyles) => ({
                  ...baseStyles,
                }),
                option: (baseStyles: any) => ({
                  ...baseStyles,
                  textTransform: 'capitalize',
                }),
                valueContainer: (base: any) => {
                  return {
                    ...base,
                    textTransform: 'capitalize',
                  }
                },
                placeholder: (base: any) => ({
                  ...base,
                  textTransform: 'initial',
                }),
              }}
              isMulti
              options={addressOptions}
              onChange={(value) => {
                handleAddress(value)
                setFieldValue(
                  'address',
                  value.map((item: any) => item.zone),
                )
              }}
              value={addresses}
            />
          </>
        )

      case 'levelModalities' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona la característica'
              isMulti
              value={levelModalities}
              onChange={(value) => handleLevelModalitiesChange(value, setFieldValue, field.id)}
              name={field.name}
              options={levelModalitiesOptions}
            />
          </>
        )
      case 'institutionTypes' === field.id:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Select
              placeholder='Selecciona la modalidad'
              isMulti
              value={institutionTypes}
              onChange={(value) => handleInstitutionTypesChange(value, setFieldValue, field.id)}
              name={field.name}
              options={institutionTypesOptions}
            />
          </>
        )

      case 'date' === field.input:
        return (
          <>
            <FormLabel>
              {field.name} {field.required && '*'}
            </FormLabel>
            <Field
              as={Input}
              style={isMobile ? newJobStyles.dateFieldMobile : {}}
              type='date'
              name={field.id}
              min={today}
            />
          </>
        )
      default:
        return (
          <>
            <FormLabel>
              {field.name} (máximo {field.length} caracteres) {field.required && '*'}
            </FormLabel>
            <Field
              as={Textarea}
              sx={newJobStyles.textarea}
              maxLength={field.length !== null ? field.length : 50}
              name={field.id}
              size={isMobile ? 'sm' : 'lg'}
            />
          </>
        )
    }
  }

  const initialValues = {
    ...adValues,
  }

  const draftValidation: NewJobFormValidation = Yup.object({})

  const saveValidation: NewJobFormValidation = Yup.object({
    cue: Yup.array().min(1, 'Campo requerido'),
    hidecue: Yup.boolean(),
    hideinstitution: Yup.boolean(),
    level: Yup.array().min(1, 'Campo requerido'),
    address: Yup.array().min(1, 'Campo requerido'),
    levelModalities: Yup.array(),
    institutionTypes: Yup.array().min(1, 'Campo requerido'),
    title: Yup.string().required('Campo requerido'),
    publicationDate: Yup.string(),
    expirationDate: Yup.string(),
    vacancies: Yup.number().min(1, 'Debe ser mayor a 0'),
    hours: Yup.number().min(1, 'Debe ser mayor a 0'),
    positionType: Yup.string().required('Campo requerido'),
    position: Yup.string().required('Campo requerido'),
    area: Yup.string(),
    availability: Yup.array(),
    timePeriod: Yup.array(),
    jobExperience: Yup.string(),
    modality: Yup.string(),
    suplencies: Yup.array(),
    tasks: Yup.string(),
    softSkills: Yup.string(),
  }).defined()

  const handleSearchCue = (value: any, setFieldValue: any) => {
    const insti: any[] = []
    const lev: any[] = []
    const addresses: any[] = []
    const levelModalities: any[] = []
    const institutionTypes: any[] = []

    setInstitutionOptions([])

    setLevels([])
    setTimeout(() => {
      setFieldValue('level', [])
    }, 100)

    setLevelModalities([])
    setTimeout(() => {
      setFieldValue('levelModalities', [])
    }, 125)

    setAddresses([])
    setTimeout(() => {
      setFieldValue('address', [])
    }, 150)

    setInstitutionTypes([])
    setTimeout(() => {
      setFieldValue('institutionTypes', [])
    }, 175)

    value.forEach((element: any) => {
      const match = institutions?.find(
        (institution: InstitutionData) => institution?.cue === element.value,
      )
      insti.push({ value: match?.id, label: match?.name })
      const repeatLevel = lev.find((lev: any) => lev.value === match?.Level?.id)
      if (!repeatLevel) {
        lev.push({ value: match?.Level?.id, label: match?.Level?.description })
      }
      const repeatAddress = addresses.find((add: any) => add.value === match?.Address?.Locality?.id)

      if (!repeatAddress) {
        addresses.push({
          value: match?.Address?.Locality?.id,
          label: `${match?.Address?.Province?.description.toLowerCase()} - ${match?.Address?.Department?.description.toLowerCase()} - ${match?.Address?.Locality?.description.toLowerCase()}`,
          zone: {
            localityId: match?.Address?.Locality?.id,
            provinceId: match?.Address?.Province?.id,
            departmentId: match?.Address?.Department?.id,
          },
        })
      }

      match?.LevelModalities.forEach((element: any) => {
        const repeatLevelModalities = levelModalities.find(
          (levMod: any) => levMod.value === element?.Modality?.id,
        )
        if (!repeatLevelModalities) {
          levelModalities.push({
            value: element?.Modality?.id,
            label: element?.Modality?.description,
            levelId: element?.levelId,
          })
        }
      })

      const repeatInstitutionType = institutionTypes.find(
        (lev: any) => lev.value === match?.InstitutionType?.id,
      )
      if (!repeatInstitutionType) {
        match?.InstitutionType !== null &&
          institutionTypes.push({
            value: match?.InstitutionType?.id,
            label: match?.InstitutionType?.description,
          })
      }
    })

    setCues(value)

    setInstitutionOptions(insti)
    setLevelOptions(lev)
    setAddressOptions(addresses)
    setInstitutionsLevelModalities(levelModalities)
    setInstitutionTypesOptions(institutionTypes)

    if (value.length === 1) {
      setLevels(lev)
      setTimeout(() => {
        setFieldValue(
          'level',
          lev.map((item: any) => {
            return { id: item.value }
          }),
        )
      }, 100)

      setLevelModalities(levelModalities)
      setTimeout(() => {
        setFieldValue(
          'levelModalities',
          levelModalities.map((item: any) => {
            return { id: item.value }
          }),
        )
      }, 125)

      setAddresses(addresses)
      setTimeout(() => {
        setFieldValue(
          'address',
          addresses.map((item: any) => item.zone),
        )
      }, 150)

      setInstitutionTypes(institutionTypes)
      setTimeout(() => {
        setFieldValue(
          'institutionTypes',
          institutionTypes.map((item: any) => {
            return { id: item.value }
          }),
        )
      }, 175)
    }
  }

  const handleLevel = (value: any, setFieldValue: any) => {
    setLevelModalitiesOptions([])
    setLevelModalities([])
    setTimeout(() => {
      setFieldValue('levelModalities', [])
    }, 100)
    setLevels(value)
    let options: any[] = []

    value.forEach((element: any) => {
      const levels = institutionsLevelModalities.filter(
        (level: any) => level.levelId === element.value,
      )

      options = [...options, ...levels]
      setLevelModalitiesOptions(options)
    })
  }

  const handleAddress = (value: any) => {
    setAddresses(value)
  }

  const handleLevelModalitiesChange = (value: any, setFieldValue: any, name: string) => {
    setLevelModalities(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleInstitutionTypesChange = (value: any, setFieldValue: any, name: string) => {
    setInstitutionTypes(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleChangePositionType = (value: any, setFieldValue: any) => {
    setPositionType(value.value)
    value.value === 'DOCENTE' ? setIsPositionShow(true) : setIsPositionShow(false)
    setPosition([])
    setTimeout(() => {
      setFieldValue('position', '')
    }, 100)
  }

  const handleChangePosition = (value: any) => {
    setPosition(value)
  }

  const handleChangeTimePeriod = (value: any) => {
    setTimePeriod(value)
  }

  const handleChangeModality = (value: any) => {
    setModality(value)
  }

  const handleChangeSuplencies = (value: any) => {
    setSuplencies(value)
  }

  const handleChangeAreaType = (value: any, setFieldValue: any) => {
    setAreaType(value)
    setAreas([])
    setTimeout(() => {
      setFieldValue('area', '')
    }, 100)
    setAreasOptions([])
    const areasList = ads?.subjectsList
      ?.filter((area: any) => {
        return area?.type === value.type
      })
      .map((area: any) => {
        return { value: area?.id, label: area?.description, type: area?.type }
      })

    setAreasOptions(areasList)
  }

  const handleChangeAreas = (value: any) => {
    setAreas(value)
  }

  const handleChangeAvailability = (value: any) => {
    setAvailability(value)
  }

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false)
  }

  const handleSubmit = (values: any) => {
    setAdValues(values)
    setIsConfirmModalOpen(true)
  }

  const handleCreateAd = () => {
    const token = localStorage.getItem('access_token') ?? ''
    const userId = userInformation?.id
    setDraft(true)

    const {
      expirationDate,
      publicationDate,
      hidecue,
      hideinstitution,
      position,
      area,
      vacancies,
      modality,
      Address,
      availability,
      cue,
      level,
      timePeriod,
      suplencies,
      levelModalities,
      institutionTypes,
      hours,
      ...rest
    } = adValues

    const upDatedValues = {
      token,
      userId,
      cue: cue?.length === 0 ? null : cue,
      level: level?.length === 0 ? null : level,
      levelModalities: levelModalities?.length === 0 ? null : levelModalities,
      institutionTypes: institutionTypes?.length === 0 ? null : institutionTypes,
      availability: availability?.length === 0 ? null : availability,
      address: Address?.length === 0 ? null : Address,
      position: position === '' ? null : position,
      area: area === '' ? null : area,
      vacancies: vacancies === '' ? null : vacancies,
      modality: modality === '' ? null : modality,
      showCue: !hidecue,
      showInstitution: !hideinstitution,
      expirationDate: expirationDate === '' || draft ? null : expirationDate,
      publicationDate: publicationDate === '' || draft ? null : publicationDate,
      timePeriod: timePeriod?.length === 0 ? null : timePeriod,
      suplencies: suplencies?.length === 0 ? null : suplencies,
      hours: hours === '' ? null : hours,
      ...rest,
    }

    dispatch(newJobRequest(upDatedValues))
  }

  return (
    <Box sx={isMobile ? newJobStyles.containerMobile : newJobStyles.container}>
      {!institutions ? (
        <Loader props={newJobStyles.loading} />
      ) : (
        <>
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            onClose={handleConfirmModalClose}
            title={'¿Está seguro?'}
            buttonOne='Si'
            buttonTwo='No'
            onConfirm={handleCreateAd}
            styles={newJobStyles.modalStyles}
          />
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={draft ? draftValidation : saveValidation}
          >
            {({ errors, touched, setFieldValue }) => {
              const touchedFields = touched as FormikTouched<any>
              const errorFields = errors as FormikErrors<any>

              return (
                <Form>
                  <Heading as='h1' sx={newJobStyles.heading}>
                    Nuevo aviso
                  </Heading>
                  <Box sx={isMobile ? newJobStyles.itemMobile : newJobStyles.item}>
                    <Heading
                      as='h2'
                      sx={isMobile ? newJobStyles.itemTitleMobile : newJobStyles.itemTitle}
                    >
                      Datos de la institución
                      <Text sx={isMobile ? newJobStyles.commentMobile : newJobStyles.comment}>
                        *(Solo se mostrarán las instituciones que tienen todos los datos cargados)
                      </Text>
                    </Heading>

                    <Box
                      sx={
                        isMobile ? newJobStyles.inputContainerMobile : newJobStyles.inputContainer
                      }
                    >
                      {instutionFields
                        .sort((a, b) => a.order - b.order)
                        .map((field) => (
                          <FormControl
                            sx={
                              isMobile
                                ? field.id === 'address'
                                  ? newJobStyles.input2Mobile
                                  : field.id === 'levelModalities' ||
                                    field.id === 'institutionTypes'
                                  ? newJobStyles.input3Mobile
                                  : newJobStyles.inputMobile
                                : field.id === 'address'
                                ? newJobStyles.input2
                                : field.id === 'levelModalities' || field.id === 'institutionTypes'
                                ? newJobStyles.input3
                                : newJobStyles.input
                            }
                            key={field.order}
                            display={field.id === 'levelModalities' ? 'none' : 'initial'}
                          >
                            {renderInput(field, setFieldValue)}
                            {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                              <Text color='red' fontSize={'sm'}>{`${errorFields[field.id]}`}</Text>
                            )}
                          </FormControl>
                        ))}
                    </Box>
                  </Box>
                  <Box sx={isMobile ? newJobStyles.itemMobile : newJobStyles.item}>
                    <Heading
                      as='h2'
                      sx={isMobile ? newJobStyles.itemTitleMobile : newJobStyles.itemTitle}
                    >
                      Datos del aviso
                    </Heading>
                    <Box
                      sx={
                        isMobile ? newJobStyles.inputContainerMobile : newJobStyles.inputContainer
                      }
                    >
                      {adFields
                        .sort((a, b) => a.order - b.order)
                        .map((field) => (
                          <FormControl
                            sx={isMobile ? newJobStyles.input2Mobile : newJobStyles.input2}
                            key={field.order}
                          >
                            {renderInput(field, setFieldValue)}
                            {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                              <Text color='red' fontSize={'sm'}>{`${errorFields[field.id]}`}</Text>
                            )}
                          </FormControl>
                        ))}
                    </Box>

                    <Box
                      sx={
                        isMobile ? newJobStyles.inputContainerMobile : newJobStyles.inputContainer
                      }
                    >
                      {dateFields
                        .sort((a, b) => a.order - b.order)
                        .map((field) => (
                          <FormControl
                            sx={isMobile ? newJobStyles.input2Mobile : newJobStyles.input2}
                            key={field.order}
                          >
                            {renderInput(field, setFieldValue)}
                            {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                              <Text color='red' fontSize={'sm'}>{`${errorFields[field.id]}`}</Text>
                            )}
                          </FormControl>
                        ))}
                    </Box>
                  </Box>

                  <Box sx={isMobile ? newJobStyles.itemMobile : newJobStyles.item}>
                    <Heading
                      as='h2'
                      sx={
                        isMobile
                          ? newJobStyles.optionalItemTitleMobile
                          : newJobStyles.optionalItemTitle
                      }
                    >
                      Quiero agregar más datos en el aviso{' '}
                      {showOptionalFields ? (
                        <ChevronUpIcon
                          sx={newJobStyles.chevronIcon}
                          onClick={() => setShowOptionalFields(!showOptionalFields)}
                        />
                      ) : (
                        <ChevronDownIcon
                          sx={newJobStyles.chevronIcon}
                          onClick={() => setShowOptionalFields(!showOptionalFields)}
                        />
                      )}
                    </Heading>
                    {showOptionalFields && (
                      <Box
                        sx={
                          isMobile ? newJobStyles.inputContainerMobile : newJobStyles.inputContainer
                        }
                      >
                        {optionalFields
                          .sort((a, b) => a.order - b.order)
                          .map((field) => {
                            return field.id === 'areaType' || field.id === 'area' ? (
                              isPositionShow && (
                                <FormControl
                                  sx={isMobile ? newJobStyles.input2Mobile : newJobStyles.input2}
                                  key={field.order}
                                >
                                  {renderInput(field, setFieldValue)}
                                  {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                                    <Text color='red' fontSize={'sm'}>{`${
                                      errorFields[field.id]
                                    }`}</Text>
                                  )}
                                </FormControl>
                              )
                            ) : (
                              <FormControl
                                sx={isMobile ? newJobStyles.input2Mobile : newJobStyles.input2}
                                key={field.order}
                              >
                                {renderInput(field, setFieldValue)}
                                {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                                  <Text color='red' fontSize={'sm'}>{`${
                                    errorFields[field.id]
                                  }`}</Text>
                                )}
                              </FormControl>
                            )
                          })}
                      </Box>
                    )}
                  </Box>

                  <Box sx={isMobile ? newJobStyles.buttonBoxMobile : newJobStyles.buttonBox}>
                    <ChakraLink
                      as={ReactRouterLink}
                      to={'/careers/oportunities'}
                      sx={isMobile ? newJobStyles.cancelButtonMobile : newJobStyles.cancelButton}
                      _hover={isMobile ? {} : newJobStyles.hover}
                    >
                      Cancelar
                    </ChakraLink>
                    <Button
                      sx={isMobile ? newJobStyles.draftButtonMobile : newJobStyles.draftButton}
                      _hover={isMobile ? {} : newJobStyles.hover}
                      onClick={() => setDraft(true)}
                      type='submit'
                    >
                      Guardar en borrador
                    </Button>
                    <Button
                      sx={isMobile ? newJobStyles.saveButtonMobile : newJobStyles.saveButton}
                      _hover={isMobile ? {} : newJobStyles.hover}
                      onClick={() => setDraft(false)}
                      type='submit'
                    >
                      Publicar
                    </Button>
                  </Box>
                </Form>
              )
            }}
          </Formik>
        </>
      )}
    </Box>
  )
}

export default NewJob
