import * as Yup from 'yup'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  Textarea,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  CandidatesDataField,
  Department,
  InstitutionData,
  Locality,
  Position,
  Province,
  Subject,
} from '../_types'
import { CloseIcon, QuestionIcon } from '@chakra-ui/icons'
import { ErrorMessage, Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import {
  candidatesFields,
  filterSvg,
  positionTypeOptions,
  singleValueOptions,
  teacherGraduatedOptions,
} from '../../../utils/candidates'
import {
  departmentsClean,
  departmentsRequest,
  institutionTypesClean,
  institutionTypesRequest,
  languagesClean,
  languagesRequest,
  levelsClean,
  levelsRequest,
  localitiesClean,
  localitiesRequest,
  provincesClean,
  provincesRequest,
} from '../../../state/modules/user/actions'
import {
  positionsClean,
  positionsRequest,
  subjectsClean,
  subjectsRequest,
} from '../../../state/modules/ads/actions'
import { useDispatch, useSelector } from 'react-redux'

import { CandidatesFilterProps } from './_types'
import Select from 'react-select'
import { candidatesFilterStyles } from './styles'
import { colors } from '../../../utils/colors'
import { downloadSpreadsheetRequest } from '../../../state/modules/profiles/actions'

export const CandidatesFilter: FC<CandidatesFilterProps> = ({
  setFilters,
  filters,
  setCurrentPage,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const institutions = useSelector((state: any) => state?.ads?.institutionsCompleteList)
  const user = useSelector((state: any) => state?.user)
  const ads = useSelector((state: any) => state?.ads)
  const token = localStorage.getItem('access_token') ?? ''

  const [showFilters, setShowFilters] = useState<boolean>(true)
  const [isClean, setIsClean] = useState<boolean>(true)
  const [cueOptions, setCueOptions] = useState<any[]>([])
  const [cues, setCues] = useState<any>(null)
  const provincias: Province[] = user?.provincesList?.map((prov: any) => {
    return { label: prov.description, value: prov.id }
  })
  const [selectedProvince, setSelectedProvince] = useState<any>([])
  const departamentos: Department[] = user?.departmentsList?.map((dpto: any) => {
    return { label: dpto.description, value: dpto.id }
  })

  const [selectedDepartment, setSelectedDepartment] = useState<any>([])
  const localidades: Locality[] = user?.localitiesList?.map((city: any) => {
    return { label: city.description, value: city.id }
  })
  const [selectedLocality, setSelectedLocality] = useState<any>([])
  const [positionType, setPositionType] = useState<any>(null)
  const [positionOptions, setPositionOptions] = useState<any[]>([])
  const [position, setPosition] = useState<any[]>([])
  const levelOptions = user?.levelsList?.map((level: any) => {
    return { label: level.description, value: level.id }
  })
  const [levels, setLevels] = React.useState<any[]>([])
  const [educationAreasOptions, setEducationAreasOptions] = useState<any[]>([])
  const [extraEducationAreasOptions, setExtraEducationAreasOptions] = useState<any[]>([])
  const [educationAreas, setEducationAreas] = useState<any[]>([])
  const [extraEducationAreas, setExtraEducationAreas] = useState<any[]>([])
  const languageOptions = user?.languagesList?.map((level: any) => {
    return { label: level.description, value: level.id }
  })
  const [languages, setLanguages] = useState<any[]>([])
  const [singleValue, setSingleValue] = useState<any>({
    mobility: null,
    retired: null,
    suplency: null,
  })
  const [teacherGraduated, setTeacherGraduated] = useState<any>(null)
  const [textField, setTextField] = useState<any>({ candidateName: '', candidateLastname: '' })
  const [isTeacher, setIsTeacher] = useState<boolean>(false)
  const institutionTypesOptions: any[] = user?.institutionTypesList?.map((type: any) => {
    return { label: type.description, value: type.id }
  })
  const [institutionTypes, setInstitutionTypes] = useState<any[]>([])
  const dispatch = useDispatch()

  const message =
    'Desde el cuál desea hacer la búsqueda. Solo se mostrarán las instituciones que tienen todos los datos cargados.'

  useEffect(() => {
    if (institutions) {
      setCueOptions([])
      institutions.map((institution: InstitutionData) => {
        const cue = institution?.cue.toString()
        const lastDigits = cue?.slice(-2)
        const firstLength = cue?.length - lastDigits?.length
        const transformedCue = `${cue?.slice(0, firstLength)}-${lastDigits}`
        const institutionName = institution?.name
        setCueOptions((prev: any) => [
          ...prev,
          {
            value: institution?.cue,
            label: `${transformedCue} / ${institutionName}`,
            id: institution?.id,
          },
        ])
      })
    }
  }, [institutions])

  useEffect(() => {
    dispatch(provincesRequest({ token }))
    dispatch(levelsRequest({ token }))
    dispatch(languagesRequest({ token }))
    dispatch(institutionTypesRequest({ token }))
    dispatch(positionsRequest({ token }))
    dispatch(subjectsRequest({ token }))

    return () => {
      dispatch(provincesClean())
      dispatch(levelsClean())
      dispatch(languagesClean())
      dispatch(institutionTypesClean())
      dispatch(positionsClean())
      dispatch(subjectsClean())
    }
  }, [])

  useEffect(() => {
    if (
      selectedProvince !== undefined &&
      selectedProvince !== null &&
      selectedProvince.length === 1
    ) {
      dispatch(departmentsRequest({ token, provinceId: selectedProvince[0].value }))
      return () => {
        dispatch(departmentsClean())
      }
    }
  }, [selectedProvince])

  useEffect(() => {
    if (
      selectedDepartment !== undefined &&
      selectedDepartment !== null &&
      selectedDepartment.length === 1
    ) {
      dispatch(
        localitiesRequest({
          token,
          departmentId: selectedDepartment[0].value,
        }),
      )
      return () => {
        dispatch(localitiesClean())
      }
    }
  }, [selectedDepartment])

  useEffect(() => {
    if (ads) {
      if (positionType?.value === 'DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: Position) => pos.type === 'DOCENTE')
            .map((pos: Position) => {
              return { value: pos.id, label: pos.description }
            }),
        )
        setEducationAreasOptions(
          ads?.subjectsList
            ?.filter((subject: Subject) => subject.type === 'CURRICULAR')
            .map((subject: Subject) => {
              return { value: subject.id, label: subject.description }
            }),
        )
        setExtraEducationAreasOptions(
          ads?.subjectsList
            ?.filter((subject: Subject) => subject.type === 'EXTRA-CURRICULAR')
            .map((subject: Subject) => {
              return { value: subject.id, label: subject.description }
            }),
        )
      } else if (positionType?.value === 'NO DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: any) => pos.type === 'NO DOCENTE')
            .map((pos: any) => {
              return { value: pos.id, label: pos.description }
            }),
        )
        setEducationAreasOptions([])
        setExtraEducationAreasOptions([])
      } else {
        setPositionOptions([])
        setEducationAreasOptions([])
        setExtraEducationAreasOptions([])
      }
    }
  }, [ads, positionType])

  const renderInput = (
    field: CandidatesDataField,
    setFieldValue?: any,
    handleChange?: any,
    setFieldTouched?: any,
  ) => {
    switch (true) {
      case 'cue' === field.id:
        return (
          <Select
            placeholder='Selecciona tu CUE'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            options={cueOptions}
            isClearable
            onBlur={() => setFieldTouched(field.id)}
            onChange={(value) => {
              handleSearchCue(value, setFieldValue)
            }}
            value={cues}
          />
        )

      case 'candidateProvince' === field.id:
        return (
          <Select
            placeholder='Selecciona tu provincia'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            options={provincias}
            value={selectedProvince}
            onChange={(value: any) => {
              handleAddress(field.id, value, setFieldValue)
            }}
            isMulti
          />
        )

      case 'candidateDepartment' === field.id:
        return (
          <Select
            placeholder='Selecciona tu departamento o partido'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            options={departamentos?.sort(
              (a: any, b: any) => a?.label?.split(' ')[1] - b?.label?.split(' ')[1],
            )}
            value={selectedDepartment}
            onChange={(value: any) => {
              handleAddress(field.id, value, setFieldValue)
            }}
            isDisabled={selectedProvince?.length > 1}
            isMulti
          />
        )
      case 'candidateLocality' === field.id:
        return (
          <Select
            placeholder='Selecciona tu localidad'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            value={selectedLocality}
            onChange={(value: any) => {
              handleAddress(field.id, value, setFieldValue)
            }}
            isDisabled={selectedProvince?.length > 1 || selectedDepartment?.length > 1}
            options={localidades}
            isMulti
          />
        )
      case 'level' === field.id:
        return (
          <Select
            placeholder='Selecciona tu nivel'
            name={field.id}
            styles={{
              menu: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            isMulti
            options={levelOptions}
            onChange={(value) => {
              handleLevel(value, setFieldValue)
            }}
            value={levels}
          />
        )

      case 'institutionTypes' === field.id:
        return (
          <Select
            placeholder='Selecciona tu modalidad'
            name={field.id}
            styles={{
              menu: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            isMulti
            options={institutionTypesOptions}
            onChange={(value) => {
              handleInstitutionTypes(value, setFieldValue)
            }}
            value={institutionTypes}
          />
        )

      case 'positionType' === field.id:
        return (
          <Select
            placeholder='Selecciona tu tipo de cargo'
            name={field.id}
            styles={{
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            onBlur={() => setFieldTouched(field.id)}
            options={positionTypeOptions}
            value={positionType}
            onChange={(value: any) => {
              handleChangePositionType(value, setFieldValue)
            }}
          />
        )
      case 'position' === field.id:
        return (
          <Select
            placeholder='Selecciona tu puesto'
            name={field.id}
            options={positionOptions}
            onBlur={() => setFieldTouched(field.id)}
            value={position}
            onChange={(value) => {
              handleChangePosition(value, setFieldValue)
            }}
            isMulti
          />
        )

      case 'educationAreas' === field.id:
        return (
          <Select
            placeholder='Selecciona tu área'
            name={field.id}
            options={educationAreasOptions}
            value={educationAreas}
            isMulti
            onChange={(value) => {
              handleChangeAreas(field.id, value, setFieldValue)
            }}
          />
        )

      case 'extraEducationAreas' === field.id:
        return (
          <Select
            placeholder='Selecciona tu área'
            name={field.id}
            options={extraEducationAreasOptions}
            value={extraEducationAreas}
            isMulti
            onChange={(value) => {
              handleChangeAreas(field.id, value, setFieldValue)
            }}
          />
        )

      case 'candidateLanguages' === field.id:
        return (
          <Select
            placeholder='Selecciona tu área'
            name={field.id}
            styles={{
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
              }),
              menu: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            options={languageOptions}
            value={languages}
            isMulti
            onChange={(value) => {
              handleChangeLanguage(field.id, value, setFieldValue)
            }}
          />
        )
      case 'mobility' === field.id || 'retired' === field.id || 'suplency' === field.id:
        return (
          <Select
            placeholder='Selecciona tu opción'
            name={field.id}
            isClearable
            options={singleValueOptions}
            value={singleValue[field.id]}
            onChange={(value) => {
              handleChangeSingleValue(field.id, value, setFieldValue)
            }}
          />
        )
      case 'teacherGraduated' === field.id:
        return (
          <Select
            placeholder='Selecciona tu opción'
            name={field.id}
            styles={{
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            options={teacherGraduatedOptions}
            value={teacherGraduated}
            onChange={(value: any) => {
              handleChangeTeacherGraduated(value, setFieldValue)
            }}
          />
        )
      default:
        return (
          <Field
            as={Input}
            name={field.id}
            placeholder={field.name}
            value={textField[field.id]}
            onChange={(e: any) => {
              handleChange(e)
              setTextField((prev: any) => ({ ...prev, [field.id]: e.target.value }))
            }}
          />
        )
    }
  }

  const handleSearchCue = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.value : ''

    setCues(value)
    setFieldValue('cue', fieldValue)
  }

  const handleAddress = (name: string, value: any, setFieldValue: any) => {
    if (name === 'candidateProvince') {
      setSelectedProvince(value)
      setFieldValue(
        name,
        value?.map((item: any) => {
          return { id: item.value }
        }),
      )
      setSelectedDepartment([])
      setSelectedLocality([])
      setTimeout(() => {
        setFieldValue('candidateDepartment', [])
      }, 100)
      setTimeout(() => {
        setFieldValue('candidateLocality', [])
      }, 110)
    }
    if (name === 'candidateDepartment') {
      setSelectedDepartment(value)
      setFieldValue(
        name,
        value?.map((item: any) => {
          return { id: item.value }
        }),
      )
      setSelectedLocality([])
      setTimeout(() => {
        setFieldValue('candidateLocality', [])
      }, 101)
    }

    if (name === 'candidateLocality') {
      setSelectedLocality(value)
      setFieldValue(
        name,
        value?.map((item: any) => {
          return { id: item.value }
        }),
      )
    }
  }

  const handleChangePositionType = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.value : ''
    setPositionType(value)
    setFieldValue('positionType', fieldValue)
    setPosition([])
    setTimeout(() => {
      setFieldValue('position', [])
    }, 100)
    setLevels([])
    setTimeout(() => {
      setFieldValue('level', [])
    }, 120)
    setTimeout(() => {
      setFieldValue('educationAreas', [])
    }, 130)
    setEducationAreas([])
    setTimeout(() => {
      setFieldValue('extraEducationAreas', [])
    }, 140)
    setExtraEducationAreas([])
  }

  const handleChangePosition = (value: any, setFieldValue: any) => {
    setPosition(value)
    setFieldValue(
      'position',
      value.map((item: any) => {
        return { id: item.value }
      }),
    )

    const isTeacher = value.find((item: any) => item.value === '20')
    if (isTeacher) setIsTeacher(true)
    else setIsTeacher(false)
  }

  const handleLevel = (value: any, setFieldValue: any) => {
    setLevels(value)
    setFieldValue(
      'level',
      value?.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleInstitutionTypes = (value: any, setFieldValue: any) => {
    setInstitutionTypes(value)
    setFieldValue(
      'institutionTypes',
      value.map((item: any) => {
        return { id: item.value }
      }),
    )
  }
  const handleChangeAreas = (name: string, value: any, setFieldValue: any) => {
    if (name === 'educationAreas') {
      setFieldValue(
        'educationAreas',
        value.map((item: any) => {
          return { id: item.value }
        }),
      )
      setEducationAreas(value)
    }
    if (name === 'extraEducationAreas') {
      setFieldValue(
        'extraEducationAreas',
        value.map((item: any) => {
          return { id: item.value }
        }),
      )
      setExtraEducationAreas(value)
    }
  }

  const handleChangeSingleValue = (name: string, value: any, setFieldValue: any) => {
    setSingleValue((prev: any) => ({
      ...prev,
      [name]: value === null ? '' : value,
    }))
    setFieldValue(name, value === null ? '' : value?.value)
  }
  const handleChangeTeacherGraduated = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.value : ''

    setTeacherGraduated(value)
    setFieldValue('teacherGraduated', fieldValue)
  }

  const handleChangeLanguage = (name: string, value: any, setFieldValue: any) => {
    setLanguages(value)
    setFieldValue(
      name,
      value?.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const initialValues = {
    cue: '',
    candidateName: '',
    candidateLastname: '',
    candidateProvince: [],
    candidateDepartment: [],
    candidateLocality: [],
    level: [],
    positionType: '',
    position: [],
    educationAreas: [],
    extraEducationAreas: [],
    suplency: '',
    retired: '',
    mobility: '',
    candidateLanguages: [],
    teacherGraduated: '',
    institutionTypes: [],
  }

  const validateSchema = Yup.object({
    cue: Yup.string().required('Este campo es obligatorio'),
    candidateName: Yup.string(),
    candidateLastname: Yup.string(),
    candidateProvince: Yup.array(),
    candidateDepartment:
      selectedProvince.length === 1 ? Yup.array().min(1, 'Este campo es obligatorio') : Yup.array(),
    candidateLocality: Yup.array(),
    positionType: Yup.string(),
    position: Yup.array(),
    level: Yup.array(),
    institutionTypes: Yup.array(),
    educationAreas: Yup.array(),
    extraEducationAreas: Yup.array(),
    suplency: Yup.string(),
    retired: Yup.string(),
    mobility: Yup.string(),
    candidateLanguages: Yup.array(),
    teacherGraduated: Yup.string(),
  }).defined()

  const handleCleanFilters = (resetForm: any) => {
    setIsClean(true)
    setCues(null)
    setTextField({ candidateName: '', candidateLastname: '' })
    setSelectedProvince([])
    setSelectedDepartment([])
    setSelectedLocality([])
    setLevels([])
    setInstitutionTypes([])
    setPositionType(null)
    setPosition([])
    setEducationAreas([])
    setExtraEducationAreas([])
    setSingleValue({
      mobility: null,
      retired: null,
      suplency: null,
    })
    setLanguages([])
    setTeacherGraduated(null)
    setFilters(null)
    setCurrentPage(1)
    resetForm({})
  }
  const handleSubmit = (values: any) => {
    const provinces = values?.candidateProvince?.map((item: any) => item.id.toString())
    const departments = values.candidateDepartment?.map((item: any) => item.id.toString())
    const localities = values?.candidateLocality?.map((item: any) => item.id.toString())

    const zone = { provinces, departments, localities }

    const educationAreaValues = values.educationAreas?.map((item: any) => {
      return { areaId: item.id }
    })

    const extraEducationAreaValues = values.extraEducationAreas?.map((item: any) => {
      return { areaId: item.id }
    })
    const positionValues = values.position?.map((item: any) => {
      return { positionId: item.id }
    })

    const levelValues = values.level?.map((item: any) => {
      return { levelId: item.id }
    })

    const languageValues = values.candidateLanguages?.map((item: any) => {
      return { languageId: item.id }
    })

    const updatedFilters = {
      firstName: values.candidateName === '' ? null : values.candidateName,
      lastName: values.candidateLastname === '' ? null : values.candidateLastname,
      institutionId: cues.id,
      zone,
      teacherTitle: values.teacherGraduated === '' ? null : values.teacherGraduated,
      retired: values.retired === '' ? null : values.retired === 'si' ? true : false,
      mobility: values.mobility === '' ? null : values.mobility === 'si' ? true : false,
      suplency: values.suplency === '' ? null : values.suplency === 'si' ? true : false,
      areas: [...educationAreaValues, ...extraEducationAreaValues],
      positions: positionValues,
      level: levelValues,
      languages: languageValues,
      institutionTypes: values.institutionTypes,
      positionType: positionType !== null ? positionType.value : null,
    }

    setFilters(updatedFilters)
    setIsClean(false)
    setCurrentPage(1)
  }

  const handleDownloadSpreadsheet = () => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(downloadSpreadsheetRequest({ token, filters }))
  }

  return (
    <Box
      sx={
        isMobile
          ? !showFilters
            ? { display: 'flex', justifyContent: 'space-between' }
            : candidatesFilterStyles.containerMobile
          : candidatesFilterStyles.container
      }
    >
      {!showFilters ? (
        isMobile ? (
          <>
            <Text color={colors.principal}>Filtros</Text>
            <Icon
              sx={candidatesFilterStyles.filterIcon}
              viewBox='0 0 28 21'
              onClick={() => setShowFilters(true)}
            >
              <path d={filterSvg.d} fill={filterSvg.fill} />
            </Icon>
          </>
        ) : (
          <Icon
            sx={candidatesFilterStyles.filterIcon}
            viewBox='0 0 28 21'
            onClick={() => setShowFilters(true)}
          >
            <path d={filterSvg.d} fill={filterSvg.fill} />
          </Icon>
        )
      ) : (
        <CloseIcon
          color={'#004876'}
          sx={candidatesFilterStyles.filterIcon}
          onClick={() => setShowFilters(false)}
        />
      )}
      {showFilters && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validateSchema}
        >
          {({
            setFieldValue,
            resetForm,
            handleChange,
            errors,
            touched,
            setFieldTouched,
            validateField,
            values,
          }) => {
            const touchedFields = touched as FormikTouched<any>
            const errorFields = errors as FormikErrors<any>

            return (
              <Form>
                <Box
                  sx={isMobile ? candidatesFilterStyles.formMobile : candidatesFilterStyles.form}
                >
                  {candidatesFields
                    .filter((e) => e.isFiltered)
                    .sort((e1, e2) => e1.order - e2.order)
                    .map((field: CandidatesDataField) => {
                      const isShown =
                        (field.id === 'educationAreas' || field.id === 'extraEducationAreas') &&
                        !isTeacher
                          ? 'none'
                          : (field.id === 'level' && positionType?.value !== 'DOCENTE') ||
                            (field.id === 'institutionTypes' && positionType?.value !== 'DOCENTE')
                          ? 'none'
                          : 'initial'
                      return (
                        <FormControl
                          display={isShown}
                          sx={
                            isMobile
                              ? candidatesFilterStyles.inputMobile
                              : candidatesFilterStyles.input
                          }
                          key={field.order}
                        >
                          <FormLabel
                            sx={field.id === 'cue' ? { display: 'flex', alignItems: 'center' } : {}}
                          >
                            {field.name}
                            {field.required && field.id === 'cue' && '*'}
                            {field.id === 'cue' &&
                              (isMobile ? (
                                <Popover>
                                  <PopoverTrigger>
                                    <Button backgroundColor='transparent'>
                                      <QuestionIcon color={colors.principal} />
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody sx={candidatesFilterStyles.popOverMobile}>
                                      {message}
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              ) : (
                                <Tooltip label={message}>
                                  <QuestionIcon ml={2} />
                                </Tooltip>
                              ))}
                          </FormLabel>
                          {renderInput(field, setFieldValue, handleChange, setFieldTouched)}
                          {errorFields[field.id] && touchedFields[field.id] && (
                            <Text sx={candidatesFilterStyles.error}>{`${
                              errorFields[field.id]
                            }`}</Text>
                          )}
                        </FormControl>
                      )
                    })}
                  <Box
                    sx={
                      isMobile
                        ? candidatesFilterStyles.buttonBoxMobile
                        : candidatesFilterStyles.buttonBox
                    }
                  >
                    <Button sx={candidatesFilterStyles.searchButton} type='submit'>
                      Buscar
                    </Button>
                    <Button
                      sx={candidatesFilterStyles.cleanFilters}
                      isDisabled={isClean}
                      onClick={() => handleDownloadSpreadsheet()}
                    >
                      Exportar
                    </Button>
                    <Button
                      sx={candidatesFilterStyles.cleanFilters}
                      isDisabled={isClean}
                      onClick={() => handleCleanFilters(resetForm)}
                    >
                      Limpiar filtros
                    </Button>
                  </Box>
                </Box>
              </Form>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}

export default CandidatesFilter
