/* eslint-disable quotes */

import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  fonts: {
    heading: "'Verdana', sans-serif",
    body: "'Verdana', sans-serif",
  },
  colors: {
    pink: {
      100: '#EBA79F',
      500: '#E23B5A',
    },
    blue: {
      100: '#56769A',
      500: '#153E75',
    },
  },
  components: {
    Avatar: {
      defaultProps: {
        // size: 'xl',
      },
    },
  },
})
