import { Box, Center, Heading, Stack, } from '@chakra-ui/layout'
import { Button, Card, CardBody, Flex, Grid, GridItem, HStack, Text, useMediaQuery, Image, Tag, useBreakpoint } from '@chakra-ui/react'
import { landingStyles } from '../../../../pages/landing/styles'
import ColorButton from '../../../buttons/button-blue'
import { Buttons } from '../../../header'
import { colors } from '../../../../utils/colors'
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'

// import Icon1 from '../../../assets/images/landing/vector1.png'
import ritaImg from '../../../../assets/images/landing/rita.png'
import mariaImg from '../../../../assets/images/landing/maria.png'
import { FC, useEffect, useState } from 'react'
import { adLastTenRequest } from '../../../../state/modules/ads/actions'
import { useDispatch, useSelector } from 'react-redux'

// import Icon3 from '../../../assets/images/landing/vector3.png'
// import Icon4 from '../../../assets/images/landing/vector4.png'
export interface IsInstitutional {
  isInstit?: boolean
}
const Tendencies: FC<IsInstitutional> = ({ isInstit }) => {
  const deviceWidth = useBreakpoint()
  const dispatch = useDispatch()
  const [localTrends, setLocalTrends] = useState<any[]>([]);
  const trend = useSelector((state: any) => state.ads.trend || []);
  const [isLogged, setIsLogged] = useState(false)

  // const navigate = useNavigate()
  useEffect(() => {
    dispatch(adLastTenRequest());

    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [dispatch]);

  useEffect(() => {

    if (trend && trend.length > 0) {
      setLocalTrends(trend)
    } else {
      console.log('No ads available or ads is empty');
    }
  }, [trend]);

  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width={['100%']}
    >
      <Box mb={['45px', '90px']}>
        <Text
          fontSize={['1.4rem', '2.1rem']}
          textAlign={'center'}
          color='#004876'
        >
          Tendencias
        </Text>
      </Box>
      <Flex
        justifyContent={'center'}
        // justifyContent={isEven ? 'center' : 'space-between'}
        alignItems="center"
        flexDirection="row"
        width={
          deviceWidth === 'base' ? '95%' :
          localTrends.length <= 5 ? '60%' : '78%'
        }
        flexWrap={'wrap'}
        gap='4'
        px={{ base: '10px', md: '32px', lg: '85px' }}
      >
        {localTrends.map((tendency) => (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            key={tendency}
            style={{
              // height: '30px',
              color: '#004876',
              backgroundColor: '#f8f8f8',
              borderRadius: '50px',
              boxShadow: '0px 4px 6px -1px #0000001A',
              padding: '2.5px 16px'
            }}
          >
            {tendency}
          </Box>
        ))}
      </Flex>
    </Flex>
  )
}

export default Tendencies
