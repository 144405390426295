import abm from './modules/abm/reducer'
import ads from './modules/ads/reducer'
import { combineReducers } from 'redux'
import contact from './modules/contact/reducer'
import favorites from './modules/favorites//reducer'
import jobApplications from './modules/jobApplications/reducer'
import list from './modules/list/reducer'
import login from './modules/login/reducer'
import notifications from './modules/notifications/reducer'
import profiles from './modules/profiles/reducers'
import user from './modules/user/reducer'

const createRootReducer = combineReducers({
  login,
  list,
  user,
  abm,
  ads,
  profiles,
  contact,
  jobApplications,
  notifications,
  favorites,
})

export default createRootReducer
