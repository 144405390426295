import * as Yup from 'yup'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  Department,
  FilterFields,
  JobApplicationsFilterProps,
  Locality,
  Position,
  Province,
  jobApplicationsDataField,
} from './_types'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import {
  departmentsClean,
  departmentsRequest,
  localitiesClean,
  localitiesRequest,
  provincesClean,
  provincesRequest,
} from '../../../state/modules/user/actions'
import { jobApplicationsFields, positionTypeOptions } from '../../../utils/jobApplications'
import { positionsClean, positionsRequest } from '../../../state/modules/ads/actions'
import { useDispatch, useSelector } from 'react-redux'

import { CloseIcon } from '@chakra-ui/icons'
import Select from 'react-select'
import { colors } from '../../../utils/colors'
import { filterSvg } from '../../../utils/jobAds'
import { jobApplicationsFilterStyles } from './styles'

const JobApplicationsFilter: FC<JobApplicationsFilterProps> = ({ setFilter }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const user = useSelector((state: any) => state?.user)
  const ads = useSelector((state: any) => state?.ads)
  const [isClean, setIsClean] = useState<boolean>(true)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [textField, setTextField] = useState<any>({
    referenceNumber: '',
    applicationDateFrom: '',
    applicationDateTo: '',
  })
  const [positionType, setPositionType] = useState<any>(null)
  const [positionOptions, setPositionOptions] = useState<any[]>([])
  const [position, setPosition] = useState<any[]>([])
  const provincias: Province[] = user?.provincesList?.map((prov: any) => {
    return { label: prov.description, value: prov.id }
  })
  const [selectedProvince, setSelectedProvince] = useState<any>([])
  const departamentos: Department[] = user?.departmentsList?.map((dpto: any) => {
    return { label: dpto.description, value: dpto.id }
  })
  const [selectedDepartment, setSelectedDepartment] = useState<any>([])
  const localidades: Locality[] = user?.localitiesList?.map((city: any) => {
    return { label: city.description, value: city.id }
  })
  const [selectedLocality, setSelectedLocality] = useState<any>([])

  const dispatch = useDispatch()

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    dispatch(provincesRequest({ token }))
    dispatch(positionsRequest({ token }))

    return () => {
      dispatch(provincesClean())
      dispatch(positionsClean())
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    if (
      selectedProvince !== undefined &&
      selectedProvince !== null &&
      selectedProvince.length === 1
    ) {
      dispatch(departmentsRequest({ token, provinceId: selectedProvince[0].value }))
      return () => {
        dispatch(departmentsClean())
      }
    }
  }, [selectedProvince])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    if (
      selectedDepartment !== undefined &&
      selectedDepartment !== null &&
      selectedDepartment.length === 1
    ) {
      dispatch(
        localitiesRequest({
          token,
          departmentId: selectedDepartment[0].value,
        }),
      )
      return () => {
        dispatch(localitiesClean())
      }
    }
  }, [selectedDepartment])

  useEffect(() => {
    if (ads) {
      if (positionType?.value === 'DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: Position) => pos.type === 'DOCENTE')
            .map((pos: Position) => {
              return { value: pos.id, label: pos.description }
            }),
        )
      } else if (positionType?.value === 'NO DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: any) => pos.type === 'NO DOCENTE')
            .map((pos: any) => {
              return { value: pos.id, label: pos.description }
            }),
        )
      } else {
        setPositionOptions([])
      }
    }
  }, [ads, positionType])

  const renderInput = (
    field: jobApplicationsDataField,
    setFieldValue?: any,
    handleChange?: any,
    setFieldTouched?: any,
  ) => {
    switch (true) {
      case 'applicationProvince' === field.id:
        return (
          <Select
            placeholder='Selecciona tu provincia'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            options={provincias}
            value={selectedProvince}
            onChange={(value: any) => {
              handleAddress(field.id, value, setFieldValue)
            }}
            isMulti
          />
        )
      case 'applicationDepartment' === field.id:
        return (
          <Select
            placeholder='Selecciona tu departamento o partido'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            options={departamentos?.sort(
              (a: any, b: any) => a?.label?.split(' ')[1] - b?.label?.split(' ')[1],
            )}
            value={selectedDepartment}
            onChange={(value: any) => {
              handleAddress(field.id, value, setFieldValue)
            }}
            isDisabled={selectedProvince?.length > 1}
            isMulti
          />
        )
      case 'applicationLocality' === field.id:
        return (
          <Select
            placeholder='Selecciona tu localidad'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            value={selectedLocality}
            onChange={(value: any) => {
              handleAddress(field.id, value, setFieldValue)
            }}
            isDisabled={selectedProvince?.length > 1 || selectedDepartment?.length > 1}
            options={localidades}
            isMulti
          />
        )
      case 'positionType' === field.id:
        return (
          <Select
            placeholder='Selecciona tu tipo de cargo'
            name={field.id}
            styles={{
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            onBlur={() => setFieldTouched(field.id)}
            options={positionTypeOptions}
            value={positionType}
            onChange={(value: any) => {
              handleChangePositionType(value, setFieldValue)
            }}
          />
        )
      case 'position' === field.id:
        return (
          <Select
            placeholder='Selecciona tu puesto'
            name={field.id}
            options={positionOptions}
            onBlur={() => setFieldTouched(field.id)}
            value={position}
            onChange={(value) => {
              handleChangePosition(value, setFieldValue)
            }}
            isMulti
          />
        )
      case 'applicationDateFrom' === field.id || 'applicationDateTo' === field.id:
        return (
          <Field
            as={Input}
            type='date'
            name={field.id}
            value={textField[field.id]}
            onChange={(e: any) => {
              handleChange(e)
              setTextField((prev: any) => ({ ...prev, [field.id]: e.target.value }))
            }}
          />
        )
      default:
        return (
          <Field
            as={Input}
            name={field.id}
            placeholder={field.name}
            type={field.type}
            value={textField[field.id]}
            onChange={(e: any) => {
              handleChange(e)
              setTextField((prev: any) => ({ ...prev, [field.id]: e.target.value }))
            }}
          />
        )
    }
  }

  const handleAddress = (name: string, value: any, setFieldValue: any) => {
    if (name === 'applicationProvince') {
      setSelectedProvince(value)
      setFieldValue(
        name,
        value?.map((item: any) => {
          return { id: item.value }
        }),
      )
      setSelectedDepartment([])
      setSelectedLocality([])
      setTimeout(() => {
        setFieldValue('applicationDepartment', [])
      }, 100)
      setTimeout(() => {
        setFieldValue('applicationLocality', [])
      }, 110)
    }
    if (name === 'applicationDepartment') {
      setSelectedDepartment(value)
      setFieldValue(
        name,
        value?.map((item: any) => {
          return { id: item.value }
        }),
      )
      setSelectedLocality([])
      setTimeout(() => {
        setFieldValue('applicationLocality', [])
      }, 101)
    }

    if (name === 'applicationLocality') {
      setSelectedLocality(value)
      setFieldValue(
        name,
        value?.map((item: any) => {
          return { id: item.value }
        }),
      )
    }
  }

  const handleChangePositionType = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.value : ''
    setPositionType(value)
    setFieldValue('positionType', fieldValue)
    setPosition([])
    setTimeout(() => {
      setFieldValue('position', [])
    }, 100)
  }

  const handleChangePosition = (value: any, setFieldValue: any) => {
    setPosition(value)
    setFieldValue(
      'position',
      value.map((item: any) => {
        return item.value
      }),
    )
  }

  const initialValues: FilterFields = {
    referenceNumber: '',
    applicationProvince: [],
    applicationDepartment: [],
    applicationLocality: [],
    positionType: '',
    position: [],
    applicationDateFrom: '',
    applicationDateTo: '',
  }
  const validationSchema = Yup.object({
    referenceNumber: Yup.string(),
    applicationProvince: Yup.array(),
    applicationDepartment: Yup.array(),
    applicationLocality: Yup.array(),
    positionType: Yup.string().required('Este campo es obligatorio'),
    position: Yup.array().min(1, 'Este campo es obligatorio'),
    applicationDateFrom:
      textField.applicationDateTo !== ''
        ? Yup.string().required('Este campo es obligatorio')
        : Yup.string(),
    applicationDateTo:
      textField.applicationDateFrom !== ''
        ? Yup.string().required('Este campo es obligatorio')
        : Yup.string(),
  }).defined()

  const handleSubmit = (values: any) => {
    setFilter(values)
    setIsClean(false)
  }

  const handleCleanFilters = (resetForm: any) => {
    resetForm(initialValues)
    setFilter(initialValues)
    setTextField({
      referenceNumber: '',
      applicationDateFrom: '',
      applicationDateTo: '',
    })
    setPositionType(null)
    setPosition([])
    setSelectedProvince
    setSelectedDepartment([])
    setSelectedLocality([])
    setIsClean(true)
  }

  return (
    <Box
      sx={
        isMobile
          ? !showFilters
            ? { display: 'flex', justifyContent: 'space-between' }
            : jobApplicationsFilterStyles.containerMobile
          : !showFilters
          ? { display: 'flex', justifyContent: 'space-between' }
          : jobApplicationsFilterStyles.container
      }
    >
      {!showFilters ? (
        isMobile ? (
          <>
            <Text color={colors.principal}>Filtros</Text>
            <Icon
              sx={jobApplicationsFilterStyles.filterIcon}
              viewBox='0 0 28 21'
              onClick={() => setShowFilters(true)}
            >
              <path d={filterSvg.d} fill={filterSvg.fill} />
            </Icon>
          </>
        ) : (
          <>
            <Text color={colors.principal}>Filtros</Text>

            <Icon
              sx={jobApplicationsFilterStyles.filterIcon}
              viewBox='2 2 28 19'
              onClick={() => setShowFilters(true)}
            >
              <path d={filterSvg.d} fill={filterSvg.fill} />
            </Icon>
          </>
        )
      ) : (
        <CloseIcon
          color={'#004876'}
          sx={jobApplicationsFilterStyles.filterIcon}
          onClick={() => setShowFilters(false)}
        />
      )}
      {showFilters && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, resetForm, handleChange, errors, touched, setFieldTouched }) => {
            const touchedFields = touched as FormikTouched<any>
            const errorFields = errors as FormikErrors<any>

            return (
              <Form>
                <Box
                  sx={
                    isMobile
                      ? jobApplicationsFilterStyles.formMobile
                      : jobApplicationsFilterStyles.form
                  }
                >
                  {jobApplicationsFields
                    .filter(
                      (e) =>
                        e.isFiltered &&
                        e.id !== 'applicationDateTo' &&
                        e.id !== 'applicationDateFrom',
                    )
                    .sort((e1, e2) => e1.order - e2.order)
                    .map((field: jobApplicationsDataField) => {
                      return (
                        <FormControl
                          sx={
                            isMobile
                              ? jobApplicationsFilterStyles.inputMobile
                              : jobApplicationsFilterStyles.input
                          }
                          key={field.order}
                        >
                          <FormLabel>
                            {field.name}
                            {field.required && '*'}
                          </FormLabel>
                          {renderInput(field, setFieldValue, handleChange, setFieldTouched)}
                          {errorFields[field.id] && touchedFields[field.id] && (
                            <Text sx={jobApplicationsFilterStyles.error}>{`${
                              errorFields[field.id]
                            }`}</Text>
                          )}
                        </FormControl>
                      )
                    })}
                  <Box sx={jobApplicationsFilterStyles.dateContainer}>
                    <Text>Fecha de postulación</Text>
                    <Box
                      sx={
                        isMobile
                          ? jobApplicationsFilterStyles.dateInputContainerMobile
                          : jobApplicationsFilterStyles.dateInputContainer
                      }
                    >
                      {jobApplicationsFields
                        .filter(
                          (e) =>
                            (e.isFiltered && e.id === 'applicationDateTo') ||
                            e.id === 'applicationDateFrom',
                        )
                        .map((field: jobApplicationsDataField) => {
                          return (
                            <FormControl
                              sx={
                                isMobile
                                  ? jobApplicationsFilterStyles.dateInputMobile
                                  : jobApplicationsFilterStyles.dateInput
                              }
                              key={field.order}
                            >
                              <FormLabel sx={jobApplicationsFilterStyles.dateLabel}>
                                {field.id === 'applicationDateFrom' ? 'Desde' : 'Hasta'}
                              </FormLabel>
                              {renderInput(field, setFieldValue, handleChange, setFieldTouched)}
                              {errorFields[field.id] && touchedFields[field.id] && (
                                <Text sx={jobApplicationsFilterStyles.error}>{`${
                                  errorFields[field.id]
                                }`}</Text>
                              )}
                            </FormControl>
                          )
                        })}
                    </Box>
                  </Box>
                  <Box
                    sx={
                      isMobile
                        ? jobApplicationsFilterStyles.buttonBoxMobile
                        : jobApplicationsFilterStyles.buttonBox
                    }
                  >
                    <Button sx={jobApplicationsFilterStyles.searchButton} type='submit'>
                      Buscar
                    </Button>

                    <Button
                      sx={jobApplicationsFilterStyles.cleanFilters}
                      isDisabled={isClean}
                      onClick={() => handleCleanFilters(resetForm)}
                    >
                      Limpiar filtros
                    </Button>
                  </Box>
                </Box>
              </Form>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}

export default JobApplicationsFilter
