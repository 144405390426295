import * as Yup from 'yup'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { JobsFilterProps, Position } from './_types'
import React, { useEffect } from 'react'
import { filterSvg, jobAdsFields, positionTypeOptions } from '../../../utils/jobAds'
import { useDispatch, useSelector } from 'react-redux/'

import { CloseIcon } from '@chakra-ui/icons'
import { InstitutionData } from '../JobsModal/_types'
import Select from 'react-select'
import { colors } from '../../../utils/colors'
import { jobsFilterStyles } from './styles'
import { referenceNumbersRequest } from '../../../state/modules/ads/actions'

const JobsFilter: React.FC<JobsFilterProps> = ({ setFilters, filters, setCurrentPage }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const ads = useSelector((state: any) => state?.ads)
  const allInstitutions = useSelector((state: any) => state?.ads?.institutionsCompleteList)
  const referenceNumbers = useSelector(
    (state: any) => state?.ads?.referenceNumbersListStatus?.referenceNumbers,
  )
  const [showFilters, setShowFilters] = React.useState<boolean>(true)
  const [cueOptions, setCueOptions] = React.useState<any[]>([])
  const [cue, setCue] = React.useState<{ id: string; value: string; label: string } | null>(null)
  const [referenceNumber, setReferenceNumber] = React.useState<object | null>(null)
  const [isClean, setIsClean] = React.useState<boolean>(true)
  const [positionType, setPositionType] = React.useState<any>(null)
  const [positionOptions, setPositionOptions] = React.useState<any[]>([])
  const [position, setPosition] = React.useState<any[]>([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (allInstitutions) {
      setCueOptions([])
      allInstitutions.map((institution: InstitutionData) => {
        const cue = institution?.cue.toString()
        const lastDigits = cue?.slice(-2)
        const firstLength = cue?.length - lastDigits?.length
        const transformedCue = `${cue?.slice(0, firstLength)}-${lastDigits}`

        setCueOptions((prev: any) => [
          ...prev,
          {
            value: institution?.cue,
            label: `${transformedCue} / ${institution?.name}`,
            id: institution?.id,
          },
        ])
      })
    }
  }, [allInstitutions])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    if (cue !== null) dispatch(referenceNumbersRequest({ token, institutionId: cue?.id }))
  }, [cue])

  useEffect(() => {
    if (ads) {
      if (positionType?.value === 'DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: Position) => pos.type === 'DOCENTE')
            .map((pos: Position) => {
              return { value: pos.id, label: pos.description }
            }),
        )
      } else if (positionType?.value === 'NO DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: any) => pos.type === 'NO DOCENTE')
            .map((pos: any) => {
              return { value: pos.id, label: pos.description }
            }),
        )
      } else {
        setPositionOptions([])
      }
    }
  }, [ads, positionType])

  const renderInput = (
    field: any,
    setFieldValue?: any,
    handleChange?: any,
    setFieldTouched?: any,
  ) => {
    switch (true) {
      case 'cue' === field.id:
        return (
          <Select
            name={field.id}
            placeholder='Selecciona tu CUE'
            options={cueOptions}
            value={cue}
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
              menu: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
            }}
            onChange={(newValue: any) => handleChangeCue(field.id, newValue, setFieldValue)}
            isClearable
          />
        )

      case 'referenceNumber' === field.id:
        return (
          <Select
            isClearable
            placeholder='Selecciona el número de referencia'
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
              menu: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
            }}
            value={referenceNumber}
            options={
              cue === null
                ? []
                : referenceNumbers?.map((elem: any) => ({
                    value: elem.referenceNumber,
                    label: `${elem.referenceNumber} / ${
                      elem.title === '' ? 'Sin título' : elem.title
                    }`,
                  }))
            }
            onChange={(newValue: any) => handleChangeReference(field.id, newValue, setFieldValue)}
            onBlur={() => setFieldTouched(field.id)}
          />
        )

      case 'positionType' === field.id:
        return (
          <Select
            placeholder='Selecciona el tipo de cargo'
            name={field.id}
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
              menu: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
            }}
            isClearable
            onBlur={() => setFieldTouched(field.id)}
            options={positionTypeOptions}
            value={positionType}
            onChange={(value: any) => {
              handleChangePositionType(value, setFieldValue)
            }}
          />
        )
      case 'position' === field.id:
        return (
          <Select
            placeholder='Selecciona el puesto'
            name={field.id}
            styles={{
              valueContainer: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
              menu: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
            }}
            options={positionOptions}
            onBlur={() => setFieldTouched(field.id)}
            value={position}
            onChange={(value) => {
              handleChangePosition(value, setFieldValue)
            }}
            isMulti
          />
        )

      case 'title' === field.id:
        return (
          <Field
            as={Input}
            name={field.id}
            style={isMobile ? { fontSize: '14px' } : { fontSize: '14px' }}
          />
        )

      default:
        break
    }
  }

  const handleChangeCue = (name: string, newValue: any, setFieldValue: any) => {
    if (newValue === null) setCue(null)
    else setCue({ id: newValue?.id, value: newValue?.value, label: newValue?.label })
    setFieldValue(name, newValue)
    setReferenceNumber(null)
    setTimeout(() => {
      setFieldValue('referenceNumber', null)
    }, 100)
  }
  const handleChangeReference = (name: string, newValue: any, setFieldValue: any) => {
    const value = newValue === null ? null : newValue.value
    setReferenceNumber(newValue)
    setFieldValue(name, value)
  }

  const handleChangePositionType = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.value : null
    setPositionType(value)
    setFieldValue('positionType', fieldValue)
    setPosition([])
    setTimeout(() => {
      setFieldValue('position', [])
    }, 100)
  }
  const handleChangePosition = (value: any, setFieldValue: any) => {
    setPosition(value)
    setFieldValue(
      'position',
      value.map((item: any) => item.value),
    )
  }

  const handleSubmit = (values: any) => {
    const filters = {
      cue: values.cue === null ? null : values.cue?.id,
      referenceNumber: values.referenceNumber === null ? null : values.referenceNumber,
      title: values.title,
      positionType: values.positionType === null ? null : values.positionType,
      position: values.position.map((item: any) => item),
    }
    setCurrentPage(1)
    setFilters(filters)
    setIsClean(false)
  }

  const validateSchema = Yup.object({}).defined()

  const handleCleanFilters = (resetForm: any) => {
    setCue(null)
    setReferenceNumber(null)
    setPositionType(null)
    setPosition([])
    setFilters({ cue: null, referenceNumber: null, title: '', positionType: null, position: [] })
    resetForm()
    setCurrentPage(1)
    setIsClean(true)
  }

  return (
    <Box
      sx={
        isMobile
          ? !showFilters
            ? { display: 'flex', justifyContent: 'space-between' }
            : jobsFilterStyles.container
          : jobsFilterStyles.container
      }
    >
      {!showFilters ? (
        isMobile ? (
          <>
            <Text color={colors.principal}>Filtros</Text>
            <Icon
              sx={jobsFilterStyles.filterIcon}
              viewBox='0 0 28 21'
              onClick={() => setShowFilters(true)}
            >
              <path d={filterSvg.d} fill={filterSvg.fill} />
            </Icon>
          </>
        ) : (
          <Icon
            sx={jobsFilterStyles.filterIcon}
            viewBox='0 0 28 21'
            onClick={() => setShowFilters(true)}
          >
            <path d={filterSvg.d} fill={filterSvg.fill} />
          </Icon>
        )
      ) : (
        <CloseIcon
          color={'#004876'}
          sx={jobsFilterStyles.filterIcon}
          onClick={() => setShowFilters(false)}
        />
      )}
      {showFilters && (
        <Formik initialValues={filters} onSubmit={handleSubmit} validationSchema={validateSchema}>
          {({ setFieldValue, handleChange, resetForm, touched, errors, setFieldTouched }) => {
            const touchedFields = touched as FormikTouched<any>
            const errorFields = errors as FormikErrors<any>
            return (
              <Form>
                <Box sx={isMobile ? jobsFilterStyles.formMobile : jobsFilterStyles.form}>
                  {jobAdsFields
                    .filter((e) => e.isFiltered)
                    .map((field: any) => {
                      return (
                        <FormControl
                          sx={isMobile ? jobsFilterStyles.inputMobile : jobsFilterStyles.input}
                          key={field.order}
                        >
                          <FormLabel>{field.id === 'cue' ? 'Institución' : field.name}</FormLabel>
                          {renderInput(field, setFieldValue, handleChange, setFieldTouched)}
                          {errorFields[field.id] && touchedFields[field.id] && (
                            <Text sx={jobsFilterStyles.error}>{`${errorFields[field.id]}`}</Text>
                          )}
                          {/* {e.id === 'cue' ? (
                            isMobile ? (
                              <Select
                                name={e.id}
                                options={cueOptions}
                                value={cue}
                                styles={{
                                  valueContainer: (baseStyles:any) => ({
                                    ...baseStyles,
                                    fontSize: '14px',
                                  }),
                                  menu: (baseStyles: any) => ({
                                    ...baseStyles,
                                    fontSize: '14px',
                                  }),
                                }}
                                onChange={(newValue: any) =>
                                  handleChangeCue(e.id, newValue, setFieldValue)
                                }
                              />
                            ) : (
                              <Select
                                name={e.id}
                                options={cueOptions}
                                value={cue}
                                styles={{
                                  valueContainer: (baseStyles:any) => ({
                                    ...baseStyles,
                                    fontSize: '16px',
                                  }),
                                  menu: (baseStyles:any) => ({
                                    ...baseStyles,
                                    fontSize: '16px',
                                  }),
                                }}
                                onChange={(newValue: any) =>
                                  handleChangeCue(e.id, newValue, setFieldValue)
                                }
                              />
                            )
                          ) : (
                            <Field
                              as={Input}
                              name={e.id}
                              type='number'
                              style={isMobile ? { fontSize: '14px' } : {}}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleChangeReference(e, setFieldValue)
                              }
                            />
                          )} */}
                        </FormControl>
                      )
                    })}
                  <Box sx={jobsFilterStyles.buttonBox}>
                    <Button sx={jobsFilterStyles.searchButton} type='submit'>
                      Buscar
                    </Button>
                    <Button
                      sx={jobsFilterStyles.cleanFilters}
                      isDisabled={isClean}
                      onClick={() => handleCleanFilters(resetForm)}
                    >
                      Limpiar filtros
                    </Button>
                  </Box>
                </Box>
              </Form>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}

export default JobsFilter
