import { colors } from '../../../../../utils/colors'

export const newAssociationFormStyles = {
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
    alignItems: 'flex-start',
    margin: '16px auto',
    border: `1px solid ${colors.principal}`,
    borderRadius: '10px',
    padding: '30px',
  },
  closeButtonContainer: { display: 'flex', flexDirection: 'column-reverse', width: '100%' },
  heading: { fontSize: '22px', marginTop: 4 },
  saveButton: { backgroundColor: colors.principal, color: 'white' },
}
