import * as Yup from 'yup'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ExistingUserFormProps, ExistingUserInvitation } from '../_types'
import { Form, Formik, FormikErrors, FormikTouched } from 'formik'
import React, { FC, useState } from 'react'
import Select, { components } from 'react-select'

import AsyncSelect from 'react-select/async'
import { getUsersService } from '../../../state/modules/abm/services'
import { inviteModalStyles } from '../styles'
import { newInviteRequest } from '../../../state/modules/profiles/actions'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch } from 'react-redux'

const ExistingUserForm: FC<ExistingUserFormProps> = ({ roles, institutionId, userId }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const dispatch = useDispatch()

  const getUsers = useDebouncedCallback(
    ({ query }: { query: string }) => {
      const token = localStorage.getItem('access_token') ?? ''
      return getUsersService({ token, query })
        .then((res) => {
          return res.data.users.map((e: any) => ({
            value: e.id,
            label: e.email,
            profile: { ...e.Profile },
          }))
        })
        .catch((error) => console.log(error))
    },
    300,
    { leading: true },
  )

  const handleInputEmailChange = (InputValue: string) => {
    if (InputValue?.length > 2) {
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          resolve(getUsers({ query: InputValue }))
        }, 1000)
      })
    } else
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          resolve([])
        }, 1000)
      })
  }

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>
          {props?.selectProps?.inputValue?.length > 2
            ? 'No existen resultados'
            : 'Se debe ingresar más de 2 caracteres'}
        </span>
      </components.NoOptionsMessage>
    )
  }

  const initialValues = {
    userEmail: '',
    userRole: '',
  }

  const handleExistingUserChange = (e: any, setFieldValue: any) => {
    const value = e?.value ?? ''
    setFieldValue('userEmail', e?.value)
  }

  const schemaValidation = Yup.object({
    userEmail: Yup.string().required('Este campo es requerido'),
    userRole: Yup.string().required('Este campo es requerido'),
  }).defined()

  const handleSubmit = (values: any) => {
    const token = localStorage.getItem('access_token') ?? ''
    onClose()
    const data = { roleId: values.userRole, userId: values.userEmail }
    dispatch(newInviteRequest({ token, institutionId, inviteData: data }))
  }

  return (
    <Box style={inviteModalStyles.form}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schemaValidation}
      >
        {({ setFieldValue, setFieldTouched, setValues, touched, errors }) => {
          const touchedFields = touched as FormikTouched<ExistingUserInvitation>
          const errorFields = errors as FormikErrors<ExistingUserInvitation>
          return (
            <Form>
              <FormControl mb={2}>
                <FormLabel htmlFor='userEmail' fontWeight='bold'>
                  Email
                </FormLabel>
                <AsyncSelect
                  components={{ NoOptionsMessage }}
                  isClearable
                  loadOptions={handleInputEmailChange}
                  onChange={(e) => handleExistingUserChange(e, setFieldValue)}
                  // onBlur={() => setFieldTouched('userEmail', true)}
                  // onFocus={() => setFieldTouched('userEmail', true)}
                  placeholder='Elija el usuario asociado'
                  loadingMessage={() => 'Cargando...'}
                  name='userEmail'
                />
                {errorFields.userEmail && touchedFields.userEmail && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields?.userEmail}
                  </Text>
                )}
              </FormControl>
              <FormControl mb={4}>
                <FormLabel htmlFor='userRole' fontWeight='bold'>
                  Rol
                </FormLabel>
                <Select
                  name={'userRole'}
                  placeholder='Elija un rol'
                  options={roles?.map((e: any) => ({ value: e.id, label: e.description }))}
                  onChange={(e) => setFieldValue('userRole', e?.value)}
                  onBlur={() => setFieldTouched('userRole', true)}
                />
                {errorFields.userRole && touchedFields.userRole && (
                  <Text color='red' fontSize={'sm'}>
                    {errorFields?.userRole}
                  </Text>
                )}
              </FormControl>
              <Box display='flex' justifyContent='center' mt={8}>
                <Popover isOpen={isOpen} placement='right'>
                  <PopoverTrigger>
                    <Button
                      isDisabled={isOpen}
                      sx={inviteModalStyles.saveButton}
                      onClick={onToggle}
                    >
                      Enviar
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={onToggle} />
                    <PopoverHeader>¿Está seguro que desea enviar los datos?</PopoverHeader>
                    <PopoverBody sx={{ display: 'flex', justifyContent: 'center', columnGap: 8 }}>
                      <Button sx={inviteModalStyles.saveButton} type='submit'>
                        Si
                      </Button>
                      <Button sx={inviteModalStyles.saveButton} onClick={onToggle}>
                        No
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default ExistingUserForm
