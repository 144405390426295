import { Table } from '@chakra-ui/react'
import { colors } from '../../../../utils/colors'
export const requestsTableStyles = {
  heading: { fontWeight: 'bold', fontSize: '25px', textAlign: 'center', marginBottom: '16px' },
  table: { width: '1100px' },
  tableHead: { fontWeight: 'bold', fontSize: '12px', color: colors.principal },
  tableBody: { fontSize: '12px' },
  actionField: { display: 'flex', justifyContent: 'center', alignItems: 'center' },

  tableData: {},
  loading: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsContainer: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    textAlign: 'center',
    padding: '20px 0px',
    fontWeight: 'bold',
  },
}
