import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Select,
  Text,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import * as Yup from 'yup'
import React, { FC, useEffect, useState } from 'react'
import {
  RequestDataAction,
  RequestHandle,
  RequestsDataField,
} from '../../Requests/RequestsTable/_types'
import { RequestsModalProps, RoleInformation } from './_types'
import { requestsFields } from '../../../../utils/abm'
import { requestModalStyles } from './styles'
import {
  handleRequisitionRequest,
  rolesClean,
  rolesRequest,
} from '../../../../state/modules/abm/actions'
import { useDispatch, useSelector } from 'react-redux'

export const RequestModal: FC<RequestsModalProps> = ({ action, currentUser }) => {
  const [fields, setFields] = useState<any[]>([])
  const abm = useSelector((state: any) => state.abm)
  const dispatch = useDispatch()

  useEffect(() => {
    if (action === 'handle') {
      const handleFields = requestsFields
        .filter((e: RequestsDataField) => e.isApproved)
        .sort((e1, e2) => e1.order - e2.order)
      setFields(handleFields)
    }
  }, [])

  useEffect(() => {
    dispatch(rolesRequest())

    return () => {
      dispatch(rolesClean())
    }
  }, [])

  const renderInput = (id: string, setFieldValue?: any, handleChange?: any) => {
    switch (id) {
      case 'roleId':
        return (
          <Field as={Select} name={id}>
            <option value=''>Seleccione el Rol</option>
            {abm?.rolesInformation
              ?.filter((e: RoleInformation) => e.type === 'institution')
              .map((e: any, i: number) => (
                <option key={i} value={e.id}>
                  {e.description}
                </option>
              ))}
          </Field>
        )

      default:
        return <Field as={Input} type='text' name={id} />
    }
  }

  const cue = currentUser?.Institution?.cue
  const lastDigits = cue?.slice(-2)
  const firstLength = cue?.length - lastDigits?.length
  const transformedCue = `${cue?.slice(0, firstLength)}-${lastDigits}`

  const initialValues: RequestHandle = {
    institution: currentUser?.Institution?.name,
    cue: transformedCue,
    name: currentUser?.User?.Profile?.name,
    lastname: currentUser?.User?.Profile?.lastName,
    dni: currentUser?.User?.Profile?.dni,
    email: currentUser?.User?.email,
    provincialNumber: currentUser?.Institution?.provincialNumber,
    roleId: '',
  }

  const validateSchema = Yup.object({ roleId: Yup.string().required('Campo requerido') }).defined()

  const handleSubmit = (values: RequestHandle) => {
    const token = localStorage.getItem('access_token') ?? ''

    const requestPayload: RequestDataAction = {
      token,
      requestId: currentUser?.id,
      approve: true,
      roleId: values.roleId,
    }

    dispatch(handleRequisitionRequest(requestPayload))
  }

  const handleReject = (e: React.MouseEvent<HTMLButtonElement>) => {
    const token = localStorage.getItem('access_token') ?? ''

    const requestPayload: RequestDataAction = {
      token,
      requestId: currentUser?.id,
      approve: false,
      roleId: null,
    }

    dispatch(handleRequisitionRequest(requestPayload))
  }

  return (
    <Box sx={requestModalStyles.box}>
      <Heading sx={requestModalStyles.heading}>Aprobación de solicitud</Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => {
          const touchedFields = touched as FormikTouched<any>
          const errorFields = errors as FormikErrors<any>
          const errorsArray = Object.values(errors)
          return (
            <Form>
              <Box sx={requestModalStyles.form}>
                <Heading sx={requestModalStyles.institutionDataHeading}>
                  Datos de la institución
                </Heading>
                <Box sx={requestModalStyles.institutionData}>
                  {fields.slice(0, 3).map((e: any) => {
                    return (
                      <FormControl isReadOnly sx={requestModalStyles.input} key={e.order}>
                        <FormLabel>{e.id === 'institution' ? 'Nombre' : e.name}</FormLabel>
                        {renderInput(e.id)}
                        {e.id === 'roleId' &&
                          errorFields[`${e.id}`] &&
                          touchedFields[`${e.id}`] && (
                            <Text color='red' fontSize={'sm'}>
                              {`${errorFields[e.id]}`}
                            </Text>
                          )}
                      </FormControl>
                    )
                  })}
                </Box>
                <Heading sx={requestModalStyles.userDataHeading}>
                  Datos del usuario solicitante
                </Heading>
                <Box sx={requestModalStyles.userData}>
                  {fields.slice(3).map((e: any) => {
                    return (
                      <FormControl isReadOnly sx={requestModalStyles.input} key={e.order}>
                        <FormLabel>{e.name}</FormLabel>
                        {renderInput(e.id)}
                        {e.id === 'roleId' &&
                          errorFields[`${e.id}`] &&
                          touchedFields[`${e.id}`] && (
                            <Text color='red' fontSize={'sm'}>
                              {`${errorFields[e.id]}`}
                            </Text>
                          )}
                      </FormControl>
                    )
                  })}
                </Box>
              </Box>
              <Box sx={requestModalStyles.linkBox}>
                <Text sx={requestModalStyles.linkTitle}>Documentación adjunta</Text>

                {currentUser?.UserFiles?.length === 0 ? (
                  <Text sx={requestModalStyles.noLinkResults}>No existe documentación adjunta</Text>
                ) : (
                  currentUser?.UserFiles?.map((e: any, i: number) => (
                    <Text key={e.id} sx={requestModalStyles.linkText}>
                      Link {`${i}`}:{' '}
                      <Link sx={requestModalStyles.link} target='_blank' href={e.filePath}>
                        {e.filePath.substring(e.filePath.lastIndexOf('/') + 1)}
                      </Link>
                    </Text>
                  ))
                )}
              </Box>
              <Box sx={requestModalStyles.buttonBox}>
                <Button alignSelf='center' colorScheme='blue' type='submit'>
                  Aprobar
                </Button>
                <Button alignSelf='center' colorScheme='blue' onClick={handleReject}>
                  Rechazar
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}
