import React, { FC, useEffect, useState } from 'react'
import { RequestsProps } from './_types'
import { Box, useToast } from '@chakra-ui/react'
import { RequestsTable } from './RequestsTable'
import { requestsStyles } from './styles'
import AbmModal from '../AbmModal'
import { useDispatch, useSelector } from 'react-redux'
import { handleRequisitionClean, requisitionsRequest } from '../../../state/modules/abm/actions'

export const Requests: FC<RequestsProps> = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<string>('4')
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const dispatch = useDispatch()
  const abm = useSelector((state: any) => state?.abm)
  const toast = useToast()
  const toastDuration = 2000

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(requisitionsRequest({ token }))
  }, [abm?.handleRequisitionSuccess])

  const handleShow = (option: string) => {
    setShowModal((prev: boolean) => !prev)
    setSelectedOption(option)
  }

  useEffect(() => {
    if (abm?.handleRequisitionSuccess) {
      toast({
        description: abm?.handleRequisitionStatus?.data?.status,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(handleRequisitionClean())
      setShowModal(false)
    }
    if (abm?.handleRequisitionSuccess === false) {
      toast({
        description: abm?.handleRequisitionStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(handleRequisitionClean())
    }
  }, [abm?.handleRequisitionSuccess])

  return (
    <Box sx={requestsStyles.box}>
      <AbmModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        selectedOption={selectedOption}
        currentUser={currentUser}
      />
      <RequestsTable
        showModal={handleShow}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setCurrentUser={setCurrentUser}
      />
    </Box>
  )
}
