import * as Yup from 'yup'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
} from '@chakra-ui/react'
import { ChangeEvent, useState } from 'react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { getYears, month } from '../../../../../utils/profile'
import { useDispatch, useSelector } from 'react-redux'

import { FormEducation } from '../_types'
import { PostRegisterEducation } from '../../../../../state/modules/user/services/types'
import { educationRequest } from '../../../../../state/modules/user/actions'
import { formsStyles } from '../../styles'

const TitleComponent: React.FC<any> = ({ setShowTitle, setIsConfirmModalOpen }: any) => {
  const [isTeacher, setIsTeacher] = useState<boolean>(true)
  const [isComplete, setIsComplete] = useState<boolean>(true)
  const dispatch = useDispatch()
  const userInformation = useSelector((state: any) => state.user.userInformation)
  const currentYear = new Date().getFullYear()
  const years = getYears()
  const limit = years.findIndex((year: number) => year === currentYear) + 1
  const startYears = years.slice(0, limit)
  const [toDateYears, setToDateYears] = useState([currentYear])

  const teacherCompleteInitialValues: any = {
    type: 'docente',
    teacherGraduated: 'si',
    fromDate: currentYear.toString(),
    toDate: currentYear.toString(),
    inProcess: false,
    institution: '',
    teacherTitle: '',
  }

  const teacherIncompleteInitialValues: any = {
    type: 'docente',
    teacherGraduated: 'no',
    fromDateMonth: '1',
    fromDate: currentYear.toString(),
    institution: '',
    teacherTitle: '',
    percentage: '',
  }

  const notTeacherValues: any = {
    type: 'nodocente',
    teacherGraduated: 'si',
    schoolToDate: currentYear.toString(),
    schoolFromDate: currentYear.toString(),
    inProcess: false,
    institution: '',
    title: '',
  }

  const notTeacherIncompleteValues: any = {
    type: 'nodocente',
    teacherGraduated: 'no',
    fromDateMonth: '1',
    schoolFromDate: currentYear.toString(),
    institution: '',
    title: '',
    percentage: '',
  }

  const validateSchema = Yup.object({
    type: Yup.string(),
    inProcess: Yup.boolean(),
    teacherGraduated: Yup.string(),
    fromDate: isTeacher ? Yup.string().required('Campo obligatorio') : Yup.string(),
    fromDateMonth: isComplete ? Yup.string() : Yup.string().required('Campo obligatorio'),
    toDate: !isComplete || !isTeacher ? Yup.string() : Yup.string().required('Campo obligatorio'),
    institution: Yup.string().required('Campo obligatorio'),
    teacherTitle: !isTeacher ? Yup.string() : Yup.string().required('Campo obligatorio'),
    percentage: isComplete ? Yup.string() : Yup.string().required('Campo obligatorio'),
    title: isTeacher ? Yup.string() : Yup.string().required('Campo obligatorio'),
    schoolToDate:
      !isTeacher && isComplete ? Yup.string().required('Campo obligatorio') : Yup.string(),
    schoolFromDate: isTeacher ? Yup.string() : Yup.string().required('Campo obligatorio'),
  })

  const handleTeacher = (target: any) => {
    const value = target.value === 'docente' ? true : false
    setIsTeacher(value)
    setIsComplete(true)
  }

  const handleTeacherComplete = (target: any) => {
    const value = target.value === 'si' ? true : false
    setToDateYears([currentYear])
    setIsComplete(value)
  }
  const handleSubmit = (values: any) => {
    const updatedInitialValuesForm: PostRegisterEducation = {
      userId: userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      title: {
        type: values.type ?? '',
        inProcess: values.inProcess,
        teacherGraduated: values.teacherGraduated === 'si' ? true : false,
        fromDateMonth: values.fromDateMonth ?? '',
        fromDate: values.fromDate ?? '',
        toDate: values.toDate ?? '',
        schoolToDate: values.schoolToDate ?? '',
        schoolFromDate: values.schoolFromDate ?? '',
        institution: values.institution ?? '',
        teacherTitle: values.teacherTitle ?? '',
        title: values.title ?? '',
        percentage: values.percentage ?? '',
      },
      courses: [],
      languages: [],
    }

    dispatch(educationRequest(updatedInitialValuesForm))
    setShowTitle(false)
    setIsConfirmModalOpen(true)
  }

  const handleFromDateChange = (handleChange: any, e: any) => {
    handleChange(e)
    const fromYear = e.target.value
    const fromDateLimit = startYears.findIndex((year: number) => year == fromYear)
    const yearsArray = startYears.slice(fromDateLimit)
    setToDateYears(yearsArray)
  }

  return (
    <Formik
      initialValues={teacherCompleteInitialValues}
      onSubmit={handleSubmit}
      validationSchema={validateSchema}
    >
      {({ errors, touched, handleChange, resetForm, values }) => {
        const touchedFields = touched as FormikTouched<FormEducation>
        const errorFields = errors as FormikErrors<FormEducation>
        console.log(errors)

        return (
          <Form>
            <Box sx={{ ...formsStyles.formContainer }}>
              <Button
                alignSelf={'flex-end'}
                onClick={() => {
                  setShowTitle(false)
                }}
                colorScheme='facebook'
              >
                X
              </Button>
              <FormControl mb={4}>
                <FormLabel htmlFor='type' fontWeight='bold'>
                  Registrarse como
                </FormLabel>
                <Field
                  as={Select}
                  name='type'
                  onChange={(e: ChangeEvent) => {
                    !isTeacher
                      ? resetForm({ values: teacherCompleteInitialValues })
                      : resetForm({ values: notTeacherValues })
                    handleChange(e)
                    handleTeacher(e.currentTarget)
                  }}
                >
                  <option value='docente'>Docente</option>
                  <option value='nodocente'>No docente</option>
                </Field>
              </FormControl>
              {isTeacher ? (
                <>
                  <FormControl>
                    <FormLabel htmlFor='teacherGraduated' fontWeight='bold' mb={5}>
                      Título docente
                    </FormLabel>
                    <Field
                      as={Select}
                      name='teacherGraduated'
                      onChange={(e: ChangeEvent) => {
                        !isComplete
                          ? resetForm({ values: teacherCompleteInitialValues })
                          : resetForm({ values: teacherIncompleteInitialValues })
                        handleChange(e)
                        handleTeacherComplete(e.currentTarget)
                      }}
                    >
                      <option value='si'>Si</option>
                      <option value='cursando'>Cursando</option>
                    </Field>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='bold' htmlFor='fromDate'>
                      Año de inicio
                    </FormLabel>
                    <Field
                      as={Select}
                      name='fromDate'
                      mb={5}
                      onChange={(e: any) => handleFromDateChange(handleChange, e)}
                    >
                      {startYears.map((year: number, i: number) => (
                        <option key={i} value={year}>
                          {year}
                        </option>
                      ))}
                    </Field>
                    {errorFields.fromDate && touchedFields.fromDate && (
                      <Text color='red' fontSize={'sm'}>
                        {errorFields.fromDate}
                      </Text>
                    )}
                  </FormControl>
                  {!isComplete && (
                    <FormControl>
                      <FormLabel fontWeight='bold' htmlFor='fromDateMonth'>
                        Mes de inicio
                      </FormLabel>
                      <Field as={Select} name='fromDateMonth' onChange={handleChange} mb={5}>
                        {month.map((month, i) => (
                          <option key={i} value={i + 1}>
                            {month}
                          </option>
                        ))}
                      </Field>
                      {errorFields.fromDateMonth && touchedFields.fromDateMonth && (
                        <Text color='red' fontSize={'sm'}>
                          {errorFields.fromDateMonth}
                        </Text>
                      )}
                    </FormControl>
                  )}
                  {isComplete && (
                    <>
                      <FormControl>
                        <FormLabel fontWeight='bold' htmlFor='toDate'>
                          Año de finalización
                        </FormLabel>
                        <Field as={Select} name='toDate' mb={5} onChange={handleChange}>
                          {toDateYears.map((year: number, i: number) => (
                            <option key={i} value={year}>
                              {year}
                            </option>
                          ))}
                        </Field>
                        {errorFields.toDate && touchedFields.toDate && (
                          <Text color='red' fontSize={'sm'}>
                            {errorFields.toDate}
                          </Text>
                        )}
                      </FormControl>
                      <FormControl display={'flex'} alignItems='center' margin={'0 8px 16px 0'}>
                        <FormLabel fontWeight='bold' margin={'0 8px 0 0'} htmlFor='inProcess'>
                          ¿En trámite?
                        </FormLabel>
                        <Field as={Checkbox} name='inProcess' />
                      </FormControl>
                    </>
                  )}
                  <FormControl>
                    <FormLabel fontWeight='bold' htmlFor='institution'>
                      Institución
                    </FormLabel>
                    <Field
                      as={Input}
                      type='text'
                      name='institution'
                      placeholder='Institución'
                      mb={5}
                      onChange={handleChange}
                    />
                    {errorFields.institution && touchedFields.institution && (
                      <Text color='red' fontSize={'sm'}>
                        {errorFields.institution}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='bold' htmlFor='teacherTitle'>
                      Denominación de la capacitación
                    </FormLabel>
                    <Field
                      as={Input}
                      type='text'
                      name='teacherTitle'
                      placeholder='Título obtenido'
                      mb={5}
                      onChange={handleChange}
                    />
                    {errorFields.teacherTitle && touchedFields.teacherTitle && (
                      <Text color='red' fontSize={'sm'}>
                        {errorFields.teacherTitle}
                      </Text>
                    )}
                  </FormControl>
                  {!isComplete && (
                    <FormControl>
                      <FormLabel fontWeight='bold' htmlFor='percentage'>
                        Porcentaje de avance %
                      </FormLabel>
                      <Field
                        as={Input}
                        type='text'
                        name='percentage'
                        placeholder='Ingrese número de porcentaje'
                        maxLength={2}
                        onChange={handleChange}
                        mb={5}
                      />
                      {errorFields.percentage && touchedFields.percentage && (
                        <Text color='red' fontSize={'sm'}>
                          {errorFields.percentage}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </>
              ) : (
                <>
                  <FormControl>
                    <FormLabel htmlFor='teacherGraduated' fontWeight='bold'>
                      Título no docente
                    </FormLabel>
                    <Field
                      as={Select}
                      mb={5}
                      name='teacherGraduated'
                      onChange={(e: ChangeEvent) => {
                        !isComplete
                          ? resetForm({ values: notTeacherValues })
                          : resetForm({ values: notTeacherIncompleteValues })
                        handleChange(e)
                        handleTeacherComplete(e.currentTarget)
                      }}
                    >
                      <option value='si'>Si</option>
                      <option value='cursando'>Cursando</option>
                    </Field>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight='bold' htmlFor='schoolFromDate'>
                      Año de inicio
                    </FormLabel>
                    <Field
                      as={Select}
                      name='schoolFromDate'
                      mb={5}
                      onChange={(e: any) => handleFromDateChange(handleChange, e)}
                    >
                      {startYears.map((year: number, i: number) => (
                        <option key={i} value={year}>
                          {year}
                        </option>
                      ))}
                    </Field>
                    {errorFields.schoolFromDate && touchedFields.schoolFromDate && (
                      <Text color='red' fontSize={'sm'}>
                        {errorFields.schoolFromDate}
                      </Text>
                    )}
                  </FormControl>
                  {!isComplete && (
                    <FormControl>
                      <FormLabel fontWeight='bold' htmlFor='fromDateMonth'>
                        Mes de inicio
                      </FormLabel>
                      <Field as={Select} name='fromDateMonth' onChange={handleChange} mb={5}>
                        {month.map((month, i) => (
                          <option key={i} value={i + 1}>
                            {month}
                          </option>
                        ))}
                      </Field>
                      {errorFields.fromDateMonth && touchedFields.fromDateMonth && (
                        <Text color='red' fontSize={'sm'}>
                          {errorFields.fromDateMonth}
                        </Text>
                      )}
                    </FormControl>
                  )}
                  {isComplete && (
                    <>
                      <FormControl>
                        <FormLabel fontWeight='bold' htmlFor='schoolToDate'>
                          Año de finalización
                        </FormLabel>
                        <Field as={Select} name='schoolToDate' mb={5} onChange={handleChange}>
                          {toDateYears.map((year: number, i: number) => (
                            <option key={i} value={year}>
                              {year}
                            </option>
                          ))}
                        </Field>
                        {errorFields.schoolToDate && touchedFields.schoolToDate && (
                          <Text color='red' fontSize={'sm'}>
                            {errorFields.schoolToDate}
                          </Text>
                        )}
                      </FormControl>

                      <FormControl display={'flex'} alignItems='center' margin={'0 8px 16px 0'}>
                        <FormLabel fontWeight='bold' margin={'0 8px 0 0'} htmlFor='inProcess'>
                          ¿En trámite?
                        </FormLabel>
                        <Field as={Checkbox} name='inProcess' />
                      </FormControl>
                    </>
                  )}
                  <FormControl>
                    <FormLabel fontWeight='bold' htmlFor='institution'>
                      Institución
                    </FormLabel>
                    <Field
                      as={Input}
                      type='text'
                      name='institution'
                      placeholder='Nombre de la institucion'
                      mb={5}
                    />
                    {errorFields.institution && touchedFields.institution && (
                      <Text color='red' fontSize={'sm'}>
                        {errorFields.institution}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel fontWeight='bold' htmlFor='title'>
                      Denominación de la capacitación
                    </FormLabel>
                    <Field
                      as={Input}
                      type='text'
                      name='title'
                      placeholder='Nombre del titulo obtenido'
                      mb={5}
                    />
                    {errorFields.title && touchedFields.title && (
                      <Text color='red' fontSize={'sm'}>
                        {errorFields.title}
                      </Text>
                    )}
                  </FormControl>
                  {!isComplete && (
                    <FormControl>
                      <FormLabel fontWeight='bold' htmlFor='percentage'>
                        Porcentaje de avance %
                      </FormLabel>
                      <Field
                        as={Input}
                        type='text'
                        name='percentage'
                        placeholder='Ingrese número de porcentaje'
                        maxLength={2}
                        onChange={handleChange}
                        mb={5}
                      />
                      {errorFields.percentage && touchedFields.percentage && (
                        <Text color='red' fontSize={'sm'}>
                          {errorFields.percentage}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </>
              )}
              <Box display='flex' justifyContent='center'>
                <Button sx={formsStyles.saveButton} type='submit'>
                  Guardar cambios
                </Button>
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default TitleComponent
