import { Box, Heading, UseToastOptions, useMediaQuery, useToast } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { candidatesStyles } from './styles'
import CandidatesFilter from './CandidatesFilter'
import { Filters } from './CandidatesFilter/_types'
import { useDispatch, useSelector } from 'react-redux'
import {
  institutionsClean,
  institutionsCompleteClean,
  institutionsCompleteRequest,
  institutionsRequest,
} from '../../state/modules/ads/actions'
import CandidatesTable from './CandidatesTable'
import {
  downloadSpreadsheetClean,
  profilesClean,
  profilesRequest,
  sendViewRequisitionClean,
} from '../../state/modules/profiles/actions'
import Paginator from '../Paginator'
import {
  ProfilesListPayload,
  SendRequestPayload,
} from '../../state/modules/profiles/services/types'
import { resumeDownloadClean } from '../../state/modules/user/actions'

const Candidates: FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const userId = useSelector((state: any) => state?.user?.userInformation?.id)
  const user = useSelector((state: any) => state?.user)

  const profiles = useSelector((state: any) => state?.profiles)
  const [filters, setFilters] = useState<Filters | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [resumeName, setResumeName] = useState<string>('')

  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000

  const toastMessages: UseToastOptions[] = [
    {
      description: 'Perfil descargado correctamente',
      status: 'success',
      duration: toastDuration,
    },
    {
      description: 'Ocurrio un error',
      status: 'error',
      duration: toastDuration,
    },
  ]

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(institutionsCompleteRequest({ token, userId, all: true }))
    return () => {
      dispatch(institutionsCompleteClean())
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (filters && filters !== null) {
      const payload: ProfilesListPayload = {
        token,
        filters,
        currentPage,
      }

      dispatch(profilesRequest(payload))
    }
    return () => {
      if (filters && filters !== null) dispatch(profilesClean())
    }
  }, [filters, currentPage])

  useEffect(() => {
    if (profiles?.sendRequestSuccess && profiles?.sendRequestSuccess !== null) {
      toast({
        description: profiles?.sendRequestStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(sendViewRequisitionClean())
    }
    if (profiles?.sendRequestSuccess === false) {
      toast({
        description: profiles?.sendRequestStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(sendViewRequisitionClean())
    }
  }, [profiles?.sendRequestSuccess])

  useEffect(() => {
    if (user?.downloadResumeSuccess && user?.userResume) {
      const link = document.createElement('a')
      link.href = user.userResume
      link.download = `CV_${resumeName}.pdf`
      link.click()
      toast(toastMessages[0])

      dispatch(resumeDownloadClean())
      setResumeName('')
    }

    if (user?.downloadResumeSuccess === false) {
      toast(toastMessages[1])
      dispatch(resumeDownloadClean())
      setResumeName('')
    }
  }, [user])

  useEffect(() => {
    if (profiles?.downloadSpreadsheetSuccess && profiles?.downloadSpreadsheetSuccess !== null) {
      const link = document.createElement('a')
      const myBlob = profiles?.downloadSpreadsheetStatus
      link.href = URL.createObjectURL(myBlob)
      link.download = `Export_${new Date().toLocaleDateString('en-US')}.xlsx`
      link.click()
      toast({
        description: 'Planilla descargada correctamente',
        status: 'success',
        duration: toastDuration,
      })

      dispatch(downloadSpreadsheetClean())
    }

    if (profiles?.downloadSpreadsheetSuccess === false) {
      toast({
        description: profiles?.downloadSpreadsheetStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
    }
  }, [profiles?.downloadSpreadsheetSuccess])

  return (
    <Box sx={isMobile ? candidatesStyles.containerMobile : candidatesStyles.container}>
      <Heading sx={candidatesStyles.heading}>Búsqueda de individuo</Heading>
      <Box sx={isMobile ? candidatesStyles.candidatesSearchMobile : candidatesStyles.candidates}>
        <CandidatesFilter
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />
        <CandidatesTable
          filters={filters}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setResumeName={setResumeName}
        />
      </Box>
    </Box>
  )
}

export default Candidates
