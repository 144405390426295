import { FC } from 'react'
import { useMediaQuery } from '@chakra-ui/react'
import LandingLayout from '../../../layouts/landing'
import LoginCard from '../../../components/login/card'
import CodeForm from '../../../components/forms/code-form'

const Code: FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  return (
    <LandingLayout>
      {isMobile ? (
        <CodeForm />
      ) : (
        <LoginCard>
          <CodeForm />
        </LoginCard>
      )}
    </LandingLayout>
  )
}

export default Code
