import { colors } from '../../utils/colors'

export const inviteModalStyles = {
  form: {
    padding: '32px',
    border: `1px solid ${colors.principal}`,
    borderRadius: 5,
    marginBottom: '20px',
  },
  saveButton: { backgroundColor: colors.principal, color: 'white' },
}
