import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  UseToastOptions,
  useBoolean,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  changePassProfileClean,
  changePassProfileRequest,
} from '../../../state/modules/login/actions'
import { useDispatch, useSelector } from 'react-redux'

import BlueButton from '../../buttons/button-blue'
import { ChangePassword } from './_types'
import ChangeSchema from './_schema'
import { PutPasswordProfile } from '../../../state/modules/login/services/types'
import { useNavigate } from 'react-router'

const initialValues: ChangePassword = {
  password: '',
  rePassword: '',
  reNewPassword: '',
}
const toastMessages: UseToastOptions[] = [
  {
    description: 'Se cambio contraseña correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const ChangeForm: FC = () => {
  const [showPassword, setShowPassword] = useBoolean()
  const [showRePassword, setShowRePassword] = useBoolean()
  const [showReNewPassword, setShowReNewPassword] = useBoolean()
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [passwordError, setPasswordError] = useState('')
  const [isChanging, setIsChanging] = useState(false)

  const dispatch = useDispatch()
  const { changePassProfileSuccess } = useSelector((state: any) => state.login)
  const state2 = useSelector((state: any) => state)
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    if (changePassProfileSuccess !== null && changePassProfileSuccess !== undefined) {
      toast(toastMessages[changePassProfileSuccess ? 0 : 1])
      dispatch(changePassProfileClean())
      setIsChanging(false)
    }
  }, [changePassProfileSuccess])

  const onSubmit = (values: ChangePassword) => {
    if (values.password === values.rePassword) {
      setPasswordError('Las contraseña nueva debe ser diferente a la actual')
      return
    }
    if (values.reNewPassword !== values.rePassword) {
      setPasswordError('Las contraseñas no son iguales')
      return
    }
    const updatedInitialValuesForm: PutPasswordProfile = {
      userId: state2.user.userInformation.id,
      password: values.password,
      newPassword: values.rePassword,
      token: localStorage.getItem('access_token') ?? '',
      navigate: navigate,
    }
    setIsChanging(true)
    dispatch(changePassProfileRequest(updatedInitialValuesForm))
  }

  return (
    <Box p={isMobile ? 8 : 0}>
      <Center>
        <Heading as='h4' size='md' mb={12}>
          Cambio de contraseña
        </Heading>
      </Center>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ChangeSchema}>
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Stack spacing={12}>
              <FormControl isInvalid={Boolean(errors.password && touched.password)}>
                <FormLabel htmlFor='password' fontSize={'sm'}>
                  Contraseña actual
                </FormLabel>
                <InputGroup>
                  <Input
                    id='password'
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    fontSize={'sm'}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minLength={14}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <ViewOffIcon cursor={'pointer'} onClick={setShowPassword.toggle} />
                    ) : (
                      <ViewIcon cursor={'pointer'} onClick={setShowPassword.toggle} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {errors.password && touched.password ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.password}
                  </Text>
                ) : null}
                <Text fontSize={'xs'} mt='1'>
                  Debe incluir al menos 2 números, 2 letras y tener al menos 14 caracteres
                </Text>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.password && touched.password)}>
                <FormLabel htmlFor='rePassword' fontSize={'sm'}>
                  Nueva contraseña
                </FormLabel>
                <InputGroup>
                  <Input
                    id='rePassword'
                    name='rePassword'
                    type={showRePassword ? 'text' : 'password'}
                    fontSize={'sm'}
                    value={values.rePassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minLength={14}
                  />
                  <InputRightElement>
                    {showRePassword ? (
                      <ViewOffIcon cursor={'pointer'} onClick={setShowRePassword.toggle} />
                    ) : (
                      <ViewIcon cursor={'pointer'} onClick={setShowRePassword.toggle} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {errors.rePassword && touched.rePassword ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.rePassword}
                  </Text>
                ) : null}
                {passwordError && (
                  <Text color='red' fontSize={'sm'}>
                    {passwordError}
                  </Text>
                )}
                <Text fontSize={'xs'} mt='1'>
                  Debe incluir al menos 2 números, 2 letras y tener al menos 14 caracteres
                </Text>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.password && touched.password)}>
                <FormLabel htmlFor='reNewPassword' fontSize={'sm'}>
                  Repetir contraseña
                </FormLabel>
                <InputGroup>
                  <Input
                    id='reNewPassword'
                    name='reNewPassword'
                    type={showReNewPassword ? 'text' : 'password'}
                    fontSize={'sm'}
                    value={values.reNewPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minLength={14}
                  />
                  <InputRightElement>
                    {showReNewPassword ? (
                      <ViewOffIcon cursor={'pointer'} onClick={setShowReNewPassword.toggle} />
                    ) : (
                      <ViewIcon cursor={'pointer'} onClick={setShowReNewPassword.toggle} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {errors.reNewPassword && touched.reNewPassword ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.reNewPassword}
                  </Text>
                ) : null}
                {passwordError && (
                  <Text color='red' fontSize={'sm'}>
                    {passwordError}
                  </Text>
                )}
                <Text fontSize={'xs'} mt='1'>
                  Debe incluir al menos 2 números, 2 letras y tener al menos 14 caracteres
                </Text>
              </FormControl>
              <Box>
                <BlueButton type='submit' width={'full'} isLoading={isChanging}>
                  <Text size={'sm'} fontWeight={'normal'}>
                    Confirmar
                  </Text>
                </BlueButton>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default ChangeForm
