import { colors } from '../../../utils/colors'

export const formsStyles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',

    alignItems: 'flex-start',
    margin: '16px auto',
    border: `1px solid ${colors.principal}`,
    borderRadius: '10px',
    padding: '30px',
  },
  saveButton: { backgroundColor: colors.principal, color: 'white' },
  closeButton: { backgroundColor: colors.principal, color: 'white' },
  popOverMobile: {
    fontSize: '16px',
    textTransform: 'initial',
    fontWeight: 'initial',
  },
}
