import { colors } from '../../../utils/colors'

export const candidatesFilterStyles = {
  container: { display: 'flex', flexDirection: 'column', marginBottom: '16px' },

  filterIcon: {
    width: '20px',
    height: '15px',
    flexShrink: 0,
    alignSelf: 'end',
    marginBottom: '15px',
    cursor: 'pointer',
  },
  input: { width: '31%' },
  form: { display: 'flex', flexWrap: 'wrap', gap: '10px 20px', marginBottom: '15px' },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    columnGap: '20px',
  },
  searchButton: {
    borderRadius: '5px',
    backgroundColor: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    color: '#fff',
  },
  cleanFilters: {
    backgroundColor: '#fff',
    color: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    borderRadius: '5px',
  },
  error: { color: colors.red },
  containerMobile: { display: 'flex', flexDirection: 'column' },
  formMobile: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
  },
  inputMobile: {
    width: '100%',
  },
  buttonBoxMobile: {
    marginTop: '15px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  popOverMobile: {
    fontSize: '16px',
    textTransform: 'initial',
    fontWeight: 'initial',
  },
}
