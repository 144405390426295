import { contact } from './_duck'

export const CONTACT_DATA_POST_REQUEST: string = contact.defineType('CONTACT_DATA_POST_REQUEST')
export const CONTACT_DATA_POST_SUCCESS: string = contact.defineType('CONTACT_DATA_POST_SUCCESS')
export const CONTACT_DATA_POST_FAILURE: string = contact.defineType('CONTACT_DATA_POST_FAILURE')
export const CONTACT_DATA_POST_CLEAN: string = contact.defineType('CONTACT_DATA_POST_CLEAN')

export const SUSCRIPTION_DATA_POST_REQUEST: string = contact.defineType(
  'SUSCRIPTION_DATA_POST_REQUEST',
)
export const SUSCRIPTION_DATA_POST_SUCCESS: string = contact.defineType(
  'SUSCRIPTION_DATA_POST_SUCCESS',
)
export const SUSCRIPTION_DATA_POST_FAILURE: string = contact.defineType(
  'SUSCRIPTION_DATA_POST_FAILURE',
)
export const SUSCRIPTION_DATA_POST_CLEAN: string = contact.defineType('SUSCRIPTION_DATA_POST_CLEAN')
