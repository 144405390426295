import { colors } from '../../../../utils/colors'

export const institutionModalStyles = {
  box: { height: 'auto', margin: 10 },
  heading: {
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '35.68px',
    color: colors.principal,
    marginBottom: '32px',
  },
  item: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 36px 25px 36px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },

  itemTitle: {
    color: colors.principal,
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '35.68px',
    marginBottom: '10px',
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: '20px',
  },
  input: { width: '47%' },
  imgPreviewContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    rowGap: '15px',
  },
  imgPreview: {
    width: '150px',
    height: '150px',
    objectFit: 'contain',
    borderRadius: '200px',
  },
  fileButton: { fontSize: '12px', padding: '15px', height: '25px' },
  imgText: {
    fontSize: '12px',
    color: colors.principal,
  },
  imgNameText: { fontSize: '15px', color: colors.black },
  notAllowText: { color: 'red', fontSize: '15px' },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '50px',
  },
  saveButton: {
    backgroundColor: colors.principal,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 16px',
    color: colors.white,
    border: `1px solid ${colors.principal}`,
  },
  saveButtonHover: {
    border: `1px solid ${colors.secundary}`,
    backgroundColor: colors.secundary,
    color: colors.white,
  },
  inProcessText: {
    fontSize: '14px',
    color: colors.principal,
    width: '75%',
  },
}
