import { list } from './_duck'
import * as types from './types'

// Types
import { PayloadAction } from '../../types'
import { ModuleType } from './_definitions'

const initialModuleState: ModuleType = {
  description: {},
  hasErrMessage: false,
}

const reducer = list.createReducer(
  {
    [types.LIST_GET_REQUEST]: (state: ModuleType): ModuleType => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.LIST_GET_SUCCESS]: (state: ModuleType, { payload }: PayloadAction): ModuleType => ({
      ...state,
      description: payload,
    }),
    [types.LIST_GET_FAILURE]: (state: ModuleType): ModuleType => ({
      ...state,
      hasErrMessage: true,
    }),
  },
  initialModuleState,
)

export default reducer
