import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Heading, Img, Stack, UseToastOptions, useMediaQuery, useToast, Text, Button, Tooltip, Icon } from '@chakra-ui/react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  addFolderClean,
  favoriteUpdateClean,
  favoriteUpdateRequest,
  getFoldersClean,
  getFoldersRequest,
} from '../../state/modules/favorites/actions'
import { getProfileClean, getProfileRequest } from '../../state/modules/profiles/actions'
import { resumeDownloadClean, resumeDownloadRequest } from '../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import CandidateEducation from './CandidateEducation'
import CandidateJobExperience from './CandidateJobExperience'
import CandidatePersonalData from './CandidatePersonalData'
import FavoritesFoldersModal from '../Favorites/FavoritesFoldersModal'
import Loader from '../loader'
import { candidateProfileStyles } from './styles'
import emptyHeart from '../../assets/images/icons/filled_heart.svg'
import filledHeart from '../../assets/images/icons/empty_heart.svg'
import noPhoto from '../../assets/images/profile/ProfileEmpty.png'
import CandidateLanguages from './CandidateLanguages'
import { colors } from '../../utils/colors'
import CandidatePreferences from './CandidatePreferences'
import { MdFavorite } from 'react-icons/md'
import SocialSkills from './SocialSkills'

const CandidateProfile = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const profile = useSelector((state: any) => state?.profiles?.profileInformation)
  const {
    addFolderSuccess,
    addFolderStatus,
    favoriteUpdateSuccess,
    favoriteUpdateStatus,
    foldersListStatus,
  } = useSelector((state: any) => state?.favorites)
  const user = useSelector((state: any) => state?.user)
  const { userId } = useParams()
  const { search } = useLocation()
  const institutionId = search.split('=')[1]
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000

  const toastMessages: UseToastOptions[] = [
    {
      description: 'Perfil descargado correctamente',
      status: 'success',
      duration: toastDuration,
    },
    {
      description: 'Ocurrio un error',
      status: 'error',
      duration: toastDuration,
    },
  ]
  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(getProfileRequest({ token, userId, institutionId }))

    return () => {
      dispatch(getProfileClean())
    }
  }, [userId, favoriteUpdateSuccess === true])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(getFoldersRequest({ token }))

    return () => {
      dispatch(getFoldersClean())
    }
  }, [addFolderSuccess === true])

  useEffect(() => {
    if (addFolderSuccess === true) {
      toast({ description: addFolderStatus.message, status: 'success', duration: toastDuration })
      dispatch(addFolderClean())
    }

    if (addFolderSuccess === false) {
      toast({
        description: addFolderStatus.response.data.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(addFolderClean())
    }
  }, [addFolderSuccess])

  useEffect(() => {
    if (favoriteUpdateSuccess === true) {
      toast({
        description: favoriteUpdateStatus.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(favoriteUpdateClean())
    }

    if (favoriteUpdateSuccess === false) {
      toast({
        description: favoriteUpdateStatus.response.data.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(favoriteUpdateClean())
    }
  }, [favoriteUpdateSuccess])

  useEffect(() => {
    if (user?.downloadResumeSuccess && user?.userResume) {
      const link = document.createElement('a')
      link.href = user.userResume
      link.download = `CV_${profile?.Profile?.name}_${profile?.Profile?.lastName}.pdf`
      link.click()
      toast(toastMessages[0])

      dispatch(resumeDownloadClean())
    }

    if (user?.downloadResumeSuccess === false) {
      toast(toastMessages[1])
      dispatch(resumeDownloadClean())
    }
  }, [user])

  const img = profile?.UserFiles?.findLast(
    (file: any) => file.filePath && file.fileType === 'AVATAR',
  )?.filePath
  const handleDownloadProfile = () => {
    dispatch(
      resumeDownloadRequest({ userId: profile?.Profile?.userId, photo: 'true', institutionId }),
    )
  }

  const handleConfirm = (favoritesArray: any[]) => {
    const token = localStorage.getItem('token') ?? ''
    dispatch(favoriteUpdateRequest({ userId: profile?.id, folders: favoritesArray, token }))
  }

  const favoritesList = profile?.UserFavorites?.map((fav: any) => ({ folderId: fav.folderId }))

  const favoriteFolders = `Favorito en ${profile?.UserFavorites
    ?.map((user: any) =>
      user?.UserFavoriteFolder === null ? 'general' : user?.UserFavoriteFolder?.name,
    )
    .join(', ')}`

  const favTooltip = <Tooltip label={favoriteFolders}>
    <Button
      onClick={() => setIsModalOpen(true)}
      style={{
        backgroundColor: 'transparent'
      }}
      _hover={{ bg: colors.institutionalRed }}
      pr='0'
    >
      <Img
        src={profile?.UserFavorites?.length > 0 ? filledHeart : emptyHeart}
      />
    </Button>
  </Tooltip>

  return (
    <>
      {profile === false ? (
        <Navigate to='/home' />
      ) : profile ? (
        < Box id='adDetail' sx={candidateProfileStyles.container}>

          {/* MAIN CONTAINER */}

          {/* BLUE AND WHITE STRIPE */}
          <Box
            sx={isMobile ? candidateProfileStyles.topStripeMobile : candidateProfileStyles.topStripe}
            // px={['40px', '40px', '40px', '80px', '80px', '80px']}
            px={['25px', '25px', '30px', '68px', '80px', '80px', '80px']}
          >
            <Box
              position='relative'
            >
              <Box
                position='absolute'
                top={['6px', '10px', '8px']}
              >
                <Breadcrumb
                  color='#fff'
                  fontSize={['13px', '14px', '19px']}
                  fontWeight={100}
                  fontFamily={'Roboto-Flex'}
                  w={['80vw', '50vw']}
                >
                  <BreadcrumbItem>
                    <BreadcrumbLink href='/home'>Avisos</BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <BreadcrumbLink href='/careers/handle-applications'>Ver postulantes</BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <BreadcrumbLink href='#'>Postulante</BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Box>

              {/* AVATAR IMG AND BREADCRUM CONTAINER */}
              <Box
                sx={isMobile ? candidateProfileStyles.avatarContainerMobile : candidateProfileStyles.avatarContainer}
                boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
                position='absolute'
                top={['42px', '62px', '62px']}
                bgColor='orange'
              >
                <Img
                  sx={isMobile ? candidateProfileStyles.avatarMobile : candidateProfileStyles.avatarImage}
                  src={img && img !== null ? img : noPhoto}
                  alt='Profile image'
                />
              </Box>
            </Box>

            {/* HEART BUTTON (ONLY VISIBLE IN DESKTOP)*/}
            {!isMobile && <Box
              m='auto 0 10px auto'
            >
              {favTooltip}
            </Box>}

            {/* ACTION BUTTON AND HEART CONTAINER (ONLY VISIBLE IN MOBILE)*/}
            {isMobile && <Box
              display='flex'
              flexDirection={['column']}
              justifyContent='flex-end'
              alignItems='flex-end'
              position='absolute'
              right={['20px', '40px']}
              top='25%'
            >
              <Box mb='0px'>
                {favTooltip}
              </Box>
              <Box>
                <Button
                  onClick={handleDownloadProfile}
                  sx={candidateProfileStyles.actionButton}
                  _hover={{ bg: colors.institutionalRed }}
                  size={'sm'}
                >
                  Descargar perfil
                </Button>
              </Box>
            </Box>}
          </Box>

          {/* TITLE, CANDIDATE NAME AND LOCATION CONTAINER */}
          <Box
            display='flex'
            flexDirection={['row']}
            justifyContent='space-between'
            alignItems={['flex-end', 'flex-start', 'flex-start']}
            px={['25px', '25px', '30px', '68px', '80px', '80px', '80px']}
            pt={['20px', '0']}
          >
            <Box
              display='flex'
              flexDirection={['column']}
            >
              <Box
                w={['100%']}
              >
                <Text
                  sx={candidateProfileStyles.heading}
                  fontSize={['25px', '30px', '40px', '40px']}
                >
                  {`${profile?.Profile.name} ${profile?.Profile.lastName}`}
                </Text>
              </Box>
              <Box
                w={['100%']}
              >
                <Box
                  display='flex'
                  flexWrap='wrap'
                  w='100%'
                  sx={candidateProfileStyles.subHeadingWrap}
                  fontSize={['12px', '30px', '15px', '15px']}
                >
                  {profile?.Profile?.Address?.Locality?.description === null && profile?.Profile?.Address?.Department?.description === null && profile?.Profile?.Address?.Province?.description === null
                    ? ''
                    : `${profile?.Profile?.Address?.Locality?.description
                      ? profile?.Profile?.Address?.Locality?.description.charAt(0).toUpperCase() + profile?.Profile?.Address?.Locality?.description.slice(1).toLowerCase()
                      : ''} 
       ${profile?.Profile?.Address?.Department?.description
                      ? ' - ' + profile?.Profile?.Address?.Department?.description.charAt(0).toUpperCase() + profile?.Profile?.Address?.Department?.description.slice(1).toLowerCase()
                      : ''} 
       ${profile?.Profile?.Address?.Province?.description
                      ? ' - ' + profile?.Profile?.Address?.Province?.description.charAt(0).toUpperCase() + profile?.Profile?.Address?.Province?.description.slice(1).toLowerCase()
                      : ''}`
                  }
                </Box>
              </Box>
            </Box>

            {/* ACTION BUTTON CONTAINER (VISIBLE IN DESKTOP)*/}
            {!isMobile && <Box
              display='flex'
              flexDirection={['column']}
              justifyContent='flex-end'
              alignItems='flex-end'
              mt='13px'
            >
              <Box>
                <Button
                  onClick={handleDownloadProfile}
                  sx={candidateProfileStyles.actionButton}
                  _hover={{ bg: colors.institutionalRed }}
                  size={'sm'}
                >
                  Descargar perfil
                </Button>
              </Box>
            </Box>}

          </Box>

          {/* SECTIONS CONTAINER */}
          <Box
            mx={['25px', '25px', '30px', '68px', '80px', '80px', '80px']}
          >
            <Box
              my='30px'
            >
              <CandidatePersonalData
                data={profile?.Profile}
                img={img}
                onClick={handleDownloadProfile}
                suplencies={profile?.Suplencies}
                onModalOpen={setIsModalOpen}
                userFavorites={profile?.UserFavorites}
              />
            </Box>

            <Box
              my='30px'
            >
              <CandidateEducation
                titles={profile?.Titles}
                verified={profile?.hasVisibilityApproved}
              />
            </Box>

            {(profile?.Languages.length > 0) &&
              <Box
                my='30px'
              >
                <CandidateLanguages
                  languages={profile?.Languages}
                />
              </Box>}

            <Box
              my='30px'
            >
              <CandidateJobExperience
                experiences={profile?.JobExperiences}
                verified={profile?.hasVisibilityApproved}
              />
            </Box>

            {(profile?.SocialSkills.length > 0) &&
              <Box
                my='30px'
              >
                <SocialSkills
                  socialSkills={profile?.SocialSkills}
                />
              </Box>}

            <Box
              my='30px'
            >
              <CandidatePreferences
                preferences={profile?.Preferences}
              />
            </Box>
          </Box>

          <FavoritesFoldersModal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false)
            }}
            onConfirm={handleConfirm}
            foldersList={foldersListStatus?.folders.filter((folder: any) => folder.id)}
            favoritesList={favoritesList}
          />
        </Box >
      ) : (
        <Loader props={candidateProfileStyles.loaderStyles} />
      )}
    </>
  )
}

export default CandidateProfile
