import { colors } from '../../utils/colors'

export const usersPaginatorStyles = {
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    columnGap: '10px',
  },
  arrow: {},
  pagesContainer: { display: 'flex', columnGap: '10px', alignItems: 'center' },
  button: { background: colors.principal, color: '#fff' },
  buttonHover: { background: 'darkGray', color: '#fff' },

  items: { display: 'flex' },
  dots: {},
  currentItem: {
    fontWeight: 'bold',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '5px',
    borderRadius: '30px',
    border: `1px solid ${colors.principal}`,
    backgroundColor: colors.principal,
    color: '#fff',
  },
  item: {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '5px',
    borderRadius: '30px',
    border: `1px solid ${colors.principal}`,
    color: colors.principal,
  },
  hidden: {
    visibility: 'hidden',
  },
}
