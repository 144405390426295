import { useEffect, useState, MouseEventHandler } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Box,
  useMediaQuery,
  Button,
  UseToastOptions,
  useToast,
  Heading,
  Text,
} from '@chakra-ui/react'
import Jobscomponent from './jobs/jobscomponent'
import { useDispatch, useSelector } from 'react-redux'
import { jobsExperienceClean } from '../../../../state/modules/user/actions'
import JobsExperienceEdit from '../../forms-edit/form-jobs-experience-edit'
import SkillComponent from './skills/skillsComponent'
import ConfirmModal from '../../../confirmModal'
import { experienceModalStyles } from './styles'
import { AddIcon } from '@chakra-ui/icons'

const toastMessages: UseToastOptions[] = [
  {
    description: 'Datos guardados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const JobsExperience = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const state = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const { jobsExperienceSuccess } = useSelector((state: any) => state.user)
  const toast = useToast()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [showExperience, setShowExperience] = useState<boolean>(false)
  const [showSkills, setShowSkills] = useState<boolean>(false)

  const modalStyles = {
    buttonBox: { display: 'flex', flexDirection: 'row-reverse', justifyContent: 'end' },
  }

  useEffect(() => {
    if (jobsExperienceSuccess !== null && jobsExperienceSuccess !== undefined) {
      toast(toastMessages[jobsExperienceSuccess ? 0 : 1])
      dispatch(jobsExperienceClean())
    }
  }, [jobsExperienceSuccess])

  const handleShow = (e: any) => {
    const name = e.target.name

    if (name === 'addExperience') {
      setShowExperience(true)
      setShowSkills(false)
    }
    if (name === 'addSkills') {
      setShowSkills(true)
      setShowExperience(false)
    }
  }

  return (
    <Box maxWidth='100%' margin='0 auto' display='flex' flexDirection='column' alignItems='center'>
      <Text sx={experienceModalStyles.heading}>Datos laborales</Text>
      <JobsExperienceEdit />

      <Box
        sx={{
          display: 'flex',
          width: `${isMobile ? '100%' : '50%'}`,
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '24px',
        }}
      >
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          title='¿Desea agregar otro item'
          buttonOne={'No'}
          buttonTwo={'Si'}
          onConfirm={() => window.location.reload()}
          styles={modalStyles}
        />
        {showExperience && (
          <Jobscomponent
            setShowExperience={setShowExperience}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
          />
        )}
        <Button
          mt={4}
          name='addExperience'
          colorScheme='facebook'
          width={isMobile ? '100%' : '70%'}
          onClick={handleShow}
          isDisabled={showExperience}
        >
          Agregar experiencia laboral
          <AddIcon boxSize={4} ml={2} />
        </Button>
        {showSkills && (
          <SkillComponent
            setShowSkills={setShowSkills}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
          />
        )}
        <Button
          mt={4}
          name='addSkills'
          colorScheme='facebook'
          width={isMobile ? '100%' : '70%'}
          onClick={handleShow}
          isDisabled={showSkills}
        >
          Agregar habilidades sociales
          <AddIcon boxSize={4} ml={2} />
        </Button>
      </Box>
    </Box>
  )
}

export default JobsExperience
