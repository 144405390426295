import { Box, Icon, Img, Text, Tooltip, useMediaQuery } from '@chakra-ui/react'
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'

import { FC } from 'react'
import { FavoritesCardProps } from './_types'
import FavoritesNote from './FavoritesNote'
import { Link } from 'react-router-dom'
import { LuPencil } from 'react-icons/lu'
import { ViewIcon } from '@chakra-ui/icons'
import { colors } from '../../../utils/colors'
import { favoriteNoteRequest } from '../../../state/modules/favorites/actions'
import { favoritesCardStyles } from './styles'
import { locationSvg } from '../../../utils/general'
import profileEmpty from '../../../assets/images/profile/ProfileEmpty.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

const FavoritesCard: FC<FavoritesCardProps> = ({
  onOpen,
  userNote,
  data,
  favoriteId,
  createdDate,
  favoriteFolders,
  institutionVisibility,
}) => {
  const dispatch = useDispatch()
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const handleSaveNote = (note: string) => {
    const token = localStorage.getItem('token') ?? ''
    dispatch(favoriteNoteRequest({ token, notes: note, favoriteId }))
  }

  const handleDeleteNote = () => {
    const token = localStorage.getItem('token') ?? ''
    dispatch(favoriteNoteRequest({ token, notes: null, favoriteId }))
  }

  const favoriteLabel = `Favorito en ${favoriteFolders.length === 0
    ? 'general'
    : favoriteFolders?.map((user: any) => user?.name).join(', ')
    }`

  return (
    <Box sx={isMobile ? favoritesCardStyles.containerMobile : favoritesCardStyles.container}>
      <Box
        sx={
          isMobile ? favoritesCardStyles.imageContainerMobile : favoritesCardStyles.imageContainer
        }
      >
        <Img
          sx={isMobile ? favoritesCardStyles.avatarMobile : favoritesCardStyles.avatarImage}
          alt={'Profile image of the favorite'}
          src={
            data?.UserFiles?.findLast((file: any) => file.filePath && file.fileType === 'AVATAR')
              ?.filePath ?? profileEmpty
          }
        />
      </Box>
      <Box
        sx={isMobile ? favoritesCardStyles.infoContainerMobile : favoritesCardStyles.infoContainer}
      >
        <Text sx={isMobile ? favoritesCardStyles.nameMobile : favoritesCardStyles.name}>
          {data?.Profile?.name} {data?.Profile?.lastName}
        </Text>
        {data?.Profile?.Address?.Department?.description ? (
          <Text sx={isMobile ? favoritesCardStyles.zoneMobile : favoritesCardStyles.zone}>
            <Icon fill={colors.principal} height='15px' width='15px' viewBox={locationSvg.viewBox}>
              <path d={locationSvg.d}></path>
            </Icon>{' '}
            {data?.Profile?.Address?.Department?.description.toLowerCase()}
          </Text>
        ) : (
          <Text sx={{ fontSize: '12px', color: colors.principal }}> No hay ubicación cargada</Text>
        )}
        <Text sx={isMobile ? favoritesCardStyles.dateMobile : favoritesCardStyles.date}>
          {new Date(createdDate).toISOString().split('T')[0].split('-').reverse().join('-')}
        </Text>
        <Text sx={isMobile ? favoritesCardStyles.noteMobile : favoritesCardStyles.note}>
          {userNote !== null ? `"${userNote}"` : 'No hay nota cargada'}
        </Text>
      </Box>
      <Box
        sx={
          isMobile ? favoritesCardStyles.actionContainerMobile : favoritesCardStyles.actionContainer
        }
      >
        <Link
          to={`/candidates/profile/${data?.Profile?.userId}${institutionVisibility.length > 0
            ? `?institutionId=${institutionVisibility[0].institutionId}`
            : ''
            }`}
          target='_blank'
        >
          <Icon
            as={ViewIcon}
            sx={isMobile ? favoritesCardStyles.viewIconMobile : favoritesCardStyles.viewIcon}
          />
        </Link>
        <FavoritesNote savedNote={userNote} onSave={handleSaveNote} onDelete={handleDeleteNote}>
          <Icon
            as={LuPencil}
            sx={isMobile ? favoritesCardStyles.noteIconMobile : favoritesCardStyles.noteIcon}
          />
        </FavoritesNote>
        <Tooltip label={favoriteLabel}>
          <span>
            <Icon
              as={MdFavorite}
              sx={
                isMobile ? favoritesCardStyles.favoriteIconMobile : favoritesCardStyles.favoriteIcon
              }
              onClick={onOpen}
            />
          </span>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default FavoritesCard
