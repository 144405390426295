import {
  DownloadSpreadsheetPayload,
  HandleInvitePayload,
  HandleRequestPayload,
  InstitutionInvitesPayload,
  InstitutionPayload,
  NewUserInvitePayload,
  ProfilePayload,
  ProfilesListPayload,
  RequestsListPayload,
  ResendInvitePayload,
  SendRequestPayload,
  UserInvitesPayload,
} from './types'

import { AxiosPromise } from 'axios'
import httpModule from '../../../../utils/http'

const paths = {
  PROFILES_LIST: 'user-institution/visualization-request/search',
  REQUESTS_LIST: 'user-institution/visualization-requests',
  HANDLE_REQUEST: '/visualization-request',
  SEND_REQUEST: 'user-institution/visualization-request',
  PROFILE_INFORMATION: '/visualization-requests',
  INSTITUTION_INFORMATION: 'institution',
  DOWNLOAD_SPREADSHEET: 'user-institution/visualization-request/download-excel',
  USER_INVITES: 'user-institution-invitation/user/',
  NEW_USER_INVITE: 'user-institution-invitation/create',
  INSTITUTION_INVITES: 'user-institution-invitation/institution/',
  HANDLE_INVITE: 'user-institution-invitation/update/',
  RESEND_INVITE: 'user-institution-invitation/resendInvitation',
}

export const postProfilesListService = (payload: ProfilesListPayload): AxiosPromise<any> => {
  const filters = {
    ...payload.filters,
    page: payload.currentPage,
    size: 10,
  }

  return httpModule.post(paths.PROFILES_LIST, filters, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const getRequestsListService = (payload: RequestsListPayload): AxiosPromise<any> =>
  httpModule.get(paths.REQUESTS_LIST, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const putHandleViewRequestService = (payload: HandleRequestPayload): AxiosPromise<any> =>
  httpModule.put(
    `user-institution/${payload.requestId}${paths.HANDLE_REQUEST}`,
    { approve: payload.approve },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )

export const postSendViewRequestService = (payload: SendRequestPayload): AxiosPromise<any> =>
  httpModule.post(
    paths.SEND_REQUEST,
    { userId: payload.userId, institutionId: payload.institutionId },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )

export const getProfileService = (payload: ProfilePayload): AxiosPromise<any> =>
  httpModule.get(
    `user-institution/${payload.userId}${paths.PROFILE_INFORMATION}${
      payload.institutionId ? `?institutionId=${payload.institutionId}` : ''
    }`,
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )

export const postDownloadExcelSpreedsheetService = (
  payload: DownloadSpreadsheetPayload,
): AxiosPromise<any> =>
  httpModule.post(paths.DOWNLOAD_SPREADSHEET, payload.filters, {
    headers: {
      Authorization: `${payload.token}`,
    },
    responseType: 'blob',
  })

export const getInstitutionService = (payload: InstitutionPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.INSTITUTION_INFORMATION}/${payload.institutionId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getUserInvitesService = (payload: UserInvitesPayload): AxiosPromise<any> =>
  httpModule.get(`${paths.USER_INVITES}${payload.userId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getInstitutionInvitesHistoryService = (
  payload: InstitutionInvitesPayload,
): AxiosPromise<any> =>
  httpModule.get(`${paths.INSTITUTION_INVITES}${payload.institutionId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const postNewUserInviteService = (payload: NewUserInvitePayload): AxiosPromise<any> => {
  const data = {
    institutionId: payload.institutionId,
    roleId: payload.inviteData.roleId,
    userId: payload.inviteData.userId ?? null,
    email: payload.inviteData.email ?? null,
    name: payload.inviteData.name ?? null,
    lastName: payload.inviteData.lastName ?? null,
    dni: payload.inviteData.dni ?? null,
  }

  return httpModule.post(`${paths.NEW_USER_INVITE}`, data, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const putHandleInviteService = (payload: HandleInvitePayload): AxiosPromise<any> => {
  return httpModule.put(
    `${paths.HANDLE_INVITE}${payload.inviteId}`,
    { statusId: payload.statusId },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const postResendInviteService = (payload: ResendInvitePayload): AxiosPromise<any> => {
  return httpModule.post(
    `${paths.RESEND_INVITE}`,
    { invitationId: payload.inviteId },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}
