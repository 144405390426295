import { useMediaQuery } from '@chakra-ui/react'
import LandingLayout from '../../layouts/landing'
import LoginCard from '../../components/login/card'
import LoginCompo from '../../components/login'
import { useEffect } from 'react'

const Login = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <LandingLayout>
      {isMobile ? (
        <LoginCompo />
      ) : (
        <LoginCard>
          <LoginCompo />
        </LoginCard>
      )}
    </LandingLayout>
  )
}

export default Login
