import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useMediaQuery,
} from '@chakra-ui/react'
import React, { FC, useState } from 'react'

interface AdvertisingPopUpProps {
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  height?: string
  width?: string
}

const AdvertisingPopUp: FC<AdvertisingPopUpProps> = ({
  children,
  isOpen,
  onClose,
  height,
  width,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  return (
    <Modal
      scrollBehavior={'outside'}
      size={'6xl'}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      motionPreset={'slideInRight'}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent
        style={
          isMobile
            ? { width: '100%' }
            : { width: width || '779px', height: height || '438px', minHeight: height || '438px' }
        }
      >
        <ModalCloseButton _hover={{ backgroundColor: 'transparent' }} />
        <ModalBody sx={{ padding: 0 }}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AdvertisingPopUp
