import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  PinInput,
  PinInputField,
  Stack,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { NavigateFunction, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { PostVerify } from '../../../state/modules/login/services/types'
import { verifyRequest } from '../../../state/modules/login/actions'

const newNavigate = {} as NavigateFunction

const initialValues: PostVerify = {
  email: '',
  code: '',
  navigate: newNavigate,
}

const toastMessages: UseToastOptions[] = [
  {
    description: 'El código es valido',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Código invalido',
    status: 'error',
    duration: 2000,
  },
]
const CodeForm: FC = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const dispatch = useDispatch()
  const toast = useToast()
  const state = useSelector((state: any) => state)
  const navigate = useNavigate()

  const [pin, setPin] = useState('')
  const [isPinComplete, setIsPinComplete] = useState(false)

  useEffect(() => {
    if (state.login.validateSuccess !== null && state.login.validateSuccess !== undefined)
      toast(toastMessages[state.login.validateSuccess ? 0 : 1])
  }, [state.login.validateSuccess])

  const handlePinChange = (index: number, value: string) => {
    const newPin = pin.split('')
    newPin[index] = value
    setPin(newPin.join(''))
    setIsPinComplete(newPin.join('').length === 5)
  }

  const onSubmit = (values: PostVerify) => {
    values.code = pin
    values.email = state.login.statusGenerate.payload.email
    values['navigate'] = navigate
    dispatch(verifyRequest(values))
  }

  return (
    <Box p={isMobile ? 8 : 0}>
      <Center>
        <Heading as='h4' size='md' mb={12}>
          Ingreso de código para cambio de contraseña
        </Heading>
      </Center>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <Form>
            <Stack spacing={12}>
              <FormControl>
                <FormLabel htmlFor='code' fontSize={'sm'}>
                  Ingresá el código que te llego en tu email para poder cambiar la contraseña
                </FormLabel>
                <HStack justifyContent={'space-between'}>
                  <PinInput size='lg'>
                    <PinInputField
                      value={pin[0]}
                      onChange={(e) => handlePinChange(0, e.target.value)}
                    />
                    <PinInputField
                      value={pin[1]}
                      onChange={(e) => handlePinChange(1, e.target.value)}
                    />
                    <PinInputField
                      value={pin[2]}
                      onChange={(e) => handlePinChange(2, e.target.value)}
                    />
                    <PinInputField
                      value={pin[3]}
                      onChange={(e) => handlePinChange(3, e.target.value)}
                    />
                    <PinInputField
                      value={pin[4]}
                      onChange={(e) => handlePinChange(4, e.target.value)}
                    />
                  </PinInput>
                </HStack>
                <Center>
                  <Button type='submit' colorScheme={isPinComplete ? 'blue' : 'gray'} mt={15}>
                    Enviar
                  </Button>
                </Center>
              </FormControl>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default CodeForm
