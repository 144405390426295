import { colors } from '../../utils/colors'

export const candidatesStyles = {
  container: { width: '100%', padding: '1.1% 4.3% 4.3% 4%' },
  heading: {
    fontSize: '25px',
    fontWeight: 700,
    lineHeight: '35.68px',
    color: colors.principal,
    marginBottom: '10px',
  },
  candidates: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 63px 25px 36px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  containerMobile: { width: '100%', padding: '6% 4.3% 8% 4%' },

  candidatesSearchMobile: {
    border: `1px solid ${colors.principal}`,
    padding: '25px 36px 25px 36px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginBottom: '20px',
  },
  candidatesListMobile: { display: 'flex', flexDirection: 'column', rowGap: '20px' },
}
