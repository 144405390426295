import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Heading,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  useSteps,
} from '@chakra-ui/react'
import React, { createRef, FC, useEffect, useRef, useState } from 'react'
import { candidateProfileStyles } from '../../styles'
import { CandidateJobExperienceProps } from '../../_types'
import { dateFormater } from '../../../../utils/profile'
import { candidateProfileDataSet } from '../../../../utils/candidates'
import { transformDate } from '../../../../utils/jobAds'
import { JobsExperienceRules } from '../../../profile/forms-edit/form-jobs-experience-edit/_types'

const PreferencesStepper: FC<any> = ({ preferences }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  return (
    <>
      {preferences.map((preference: any, i: number) => {

        const curricularAreas = preference?.Subjects?.filter(
          (item: any) => item.type === 'CURRICULAR'
        )

        const notCurricularAreas = preference?.Subjects?.filter(
          (item: any) => item.type === 'EXTRA-CURRICULAR'
        )
        return (
          <>
            <Box
              key={i}
              display='flex'
            >
              <Box
                h='fit-content'
                display='flex'
                flexDirection='column'
                pt={(i === 0) ? '0' : '22px'}
              >
                <Box display='flex' flexDirection='row' mb='8px'>
                  <Text sx={candidateProfileStyles.experienceTitleItem}>
                    Postularse a:&nbsp;
                  </Text>
                  <Text sx={candidateProfileStyles.experienceItem}>
                    {preference?.Positions?.length === 0
                      ? '-'
                      :
                      <Box key={i}><Text>{preference?.Positions[0].type}</Text></Box>
                    }
                  </Text>
                </Box>

                <Box display='flex' flexDirection='row' mb='8px'>
                  <Text sx={candidateProfileStyles.experienceTitleItem}>
                    Nivel:&nbsp;
                  </Text>
                  <Text sx={candidateProfileStyles.experienceItem}>
                    {preference?.Levels?.length === 0
                      ? ''
                      :
                      preference?.Levels?.map((item: any, i: number) => (
                        <>
                          <Box key={i} display='inline-block'><Text>{item.description}</Text></Box>
                          <Box display='inline-block'>{(i !== (preference?.Levels?.length - 1)) && <Text>&nbsp;{'-'}&nbsp;</Text>}</Box>
                        </>
                      ))
                    }
                  </Text>
                </Box>

                <Box
                  display='flex'
                  flexDirection={(preference?.Characteristics?.length === 1) ? 'row' : preference?.Characteristics?.length > 1 ? 'column' : 'row'}
                  mb='8px'
                >
                  <Text sx={candidateProfileStyles.experienceTitleItem} display={'flex'} justifyContent={'flex-start'}>
                    <Box display='flex' flexWrap='wrap' wordBreak='break-all'>
                      {preference?.Characteristics?.length === 0
                        ? <Text sx={candidateProfileStyles.experienceTitleItem}>
                          Características:&nbsp;
                          <Text as='span' sx={candidateProfileStyles.experienceItem}>-</Text>
                        </Text>
                        : [{ id: 1, description: 'Características:\xA0' }, ...preference.Characteristics]?.map((item: any, i: number) => (
                          <>
                            <Text sx={(i === 0) ? candidateProfileStyles.experienceTitleItem : candidateProfileStyles.experienceItem} wordBreak='break-word' flexWrap='wrap'>{item.description}</Text>
                            {(i !== (preference?.Characteristics?.length) && (i !== 0)) && <Text sx={candidateProfileStyles.experienceItem}>&nbsp;{'-'}&nbsp;</Text>}
                          </>
                        ))}
                    </Box>
                  </Text>
                </Box>

                <Box
                  display='flex'
                  flexDirection={(preference?.Modalities?.length === 1) ? 'row' : preference?.Modalities?.length > 1 ? 'column' : 'row'}
                  mb='8px'
                >
                  <Text sx={candidateProfileStyles.experienceTitleItem} display={'flex'} justifyContent={'flex-start'}>
                    <Box display='flex' flexWrap='wrap'>
                      {preference?.Modalities?.length === 0
                        ? <Text sx={candidateProfileStyles.experienceTitleItem}>
                          Modalidad:&nbsp;
                          <Text as='span' sx={candidateProfileStyles.experienceItem}>-</Text>
                        </Text>
                        : [{ id: 1, description: 'Modalidad:\xA0' }, ...preference.Modalities,]?.map((item: any, i: number) => (
                          <>
                            <Text sx={(i === 0) ? candidateProfileStyles.experienceTitleItem : candidateProfileStyles.experienceItem} whiteSpace='break-spaces'>{item.description}</Text>
                            {(i !== (preference?.Modalities?.length) && (i !== 0)) && <Text sx={candidateProfileStyles.experienceItem}>&nbsp;{'-'}&nbsp;</Text>}
                          </>
                        ))}
                    </Box>
                  </Text>
                </Box>

                <Box display='flex' flexDirection={['column', 'row', 'row']} mb='8px'>
                  <Text sx={candidateProfileStyles.experienceTitleItem}>
                    Puestos de trabajo:&nbsp;
                  </Text>
                  <Text sx={candidateProfileStyles.experienceItem}>
                    {preference?.Positions?.length === 0
                      ? '-'
                      : preference?.Positions?.map((item: any, i: number) => (
                        <>
                          <Box key={i} display='inline-block'><Text>{item.description}</Text></Box>
                          <Box display='inline-block'>{(i !== (preference?.Positions?.length - 1)) && <Text>&nbsp;{'-'}&nbsp;</Text>}</Box>
                        </>
                      ))}
                  </Text>
                </Box>

                <Box
                  display='flex'
                  flexDirection={(curricularAreas.length === 1) ? 'row' : curricularAreas.length > 1 ? 'column' : 'row'}
                  mb='8px'
                >
                  <Text sx={candidateProfileStyles.experienceTitleItem}>
                    Áreas curriculares:&nbsp;
                  </Text>
                  <Text sx={candidateProfileStyles.experienceItem}>
                    {curricularAreas?.length === 0
                      ? '-'
                      : curricularAreas.map((item: any, i: number) => (
                        <>
                          <Box key={i} display='inline-block'><Text>{item.description}</Text></Box>
                          <Box display='inline-block'>{(i !== (curricularAreas.length - 1)) && <Text>&nbsp;{'-'}&nbsp;</Text>}</Box>
                        </>
                      ))}
                  </Text>
                </Box>
                <Box
                  display='flex'
                  flexDirection={(notCurricularAreas.length === 1) ? 'row' : notCurricularAreas.length > 1 ? 'column' : 'row'}
                  mb='8px'
                >
                  <Text sx={candidateProfileStyles.experienceTitleItem}>
                    Otras áreas de desarrollo docente:&nbsp;
                  </Text>
                  <Text sx={candidateProfileStyles.experienceItem}>
                    {notCurricularAreas?.length === 0
                      ? '-'
                      : notCurricularAreas.map((item: any, i: number) => (
                        <>
                          <Box key={i} display='inline-block'><Text>{item.description}</Text></Box>
                          <Box display='inline-block'>{(i !== (notCurricularAreas.length - 1)) && <Text>&nbsp;{'-'}&nbsp;</Text>}</Box>
                        </>
                      ))
                    }
                  </Text>
                </Box>

                <Box display='flex' flexDirection='row' mb='8px' flexWrap='wrap'>
                  <Box>
                    <Text sx={candidateProfileStyles.experienceTitleItem} w='auto'>
                      Remuneración neta pretendida:&nbsp;
                    </Text>
                  </Box>
                  <Box>
                    <Text sx={candidateProfileStyles.experienceItem}>
                      {preference.intendedSalary ?? '-'}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              m={(i === (preferences.length - 1)) ? '25px 0' : '35px 0'}
              pl='25px'
            >
              <Box
                height='0.7px'
                width='100%'
                bgColor='#DDE4EB'
              >
              </Box>
            </Box>
          </>
        )
      }
      )}
    </>
  )
}

export default PreferencesStepper
