import { colors } from '../../utils/colors'

export const unsubscribeStyles = {
  container: {
    width: '100%',
    height: '100vh',
    // padding: '1.1% 4.3% 4.3% 4%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '20px',
  },
  logo: { marginBottom: '20px' },
  heading: {
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '35.68px',
    color: colors.principal,
    marginBottom: '10px',
  },
  textContainer: { display: 'flex', flexDirection: 'column', rowGap: '10px' },
  list: { marginBottom: '10px', listStylePosition: 'inside', padding: 0, margin: 0 },
  copyright: { marginTop: '20px' },
  loader: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
