import { Box, Heading, VStack } from '@chakra-ui/react'
import LandingLayout from '../../layouts/landing'
import AdvertisingPopUp from '../../components/advertisingPopUp'
import popUpImg from '../../assets/images/landing-legacy/popUp_img.png'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import InstitutionalLanding from './institutional-landing'
import RegularLanding from './regular-landing'
import ContactUs from '../../components/landing/shared/contact-us'

export interface IsInstit {
  isInstit: boolean
}

const Landing = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')
  const location = useLocation()
  const [modalOpen, setModalOpen] = useState(false)
  const isInstit = location.pathname === '/institutional-landing' ? true : false

/*   useEffect(() => {
    if (!token) setTimeout(() => setModalOpen(true), 2500)
  }, [token]) */

  return (
    <LandingLayout>
      <AdvertisingPopUp onClose={() => setModalOpen(false)} isOpen={modalOpen}>
        <img
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/register')}
          src={popUpImg}
        />
      </AdvertisingPopUp>
      <VStack>
        {(isInstit) ?
          <InstitutionalLanding isInstit={isInstit} /> :
          <RegularLanding isInstit={isInstit} />
        }
        <ContactUs isInstit={isInstit}/>
      </VStack>
    </LandingLayout>
  )
}

export default Landing
