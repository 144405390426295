import { position, textDecoration } from '@chakra-ui/react';
// import { colors } from '../../../utils/colors'

export const footerStyles = {
  Link: {
    baseStyle: {
      '&:hover': { textDecoration: 'none !important' },
    },
  },
  
}

