import { colors } from '../../../utils/colors'

const icon = { cursor: 'pointer', color: colors.principal }

export const favoritesFoldersStyles = {
  container: {
    border: `1px solid ${colors.principal}`,
    borderRadius: '10px',
    padding: '25px 36px 25px 36px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    flex: 1,
  },
  editIcon: { ...icon, boxSize: 3 },
  deleteIcon: { ...icon, boxSize: 3 },
  checkIcon: { ...icon, boxSize: 3 },
  closeIcon: { ...icon, boxSize: 2.5 },
  folderIcon: { ...icon, boxSize: 5, marginRight: '5px' },
  folderContainer: {
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    maxHeight: '250px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  folder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    columnGap: '5px',
  },
  actionContainer: { display: 'flex', columnGap: '10px', alignItems: 'center' },
  folderText: {
    color: colors.principal,
    fontSize: '14px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  folderTextHover: { textDecoration: 'underline' },

  addFolderInput: { fontSize: '12px', lineHeight: '10px' },
  addFolderContainer: { display: 'flex', gap: '10px', alignItems: 'center' },
  addLink: { fontSize: '14px', color: colors.principal },
  emptyText: { color: colors.red },
}
