import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import React, { FC, useEffect } from 'react'
import {
  getUserInvitesClean,
  getUserInvitesRequest,
  handleInviteClean,
  handleInviteRequest,
} from '../../state/modules/profiles/actions'
import { useDispatch, useSelector } from 'react-redux'

import { InvitesHandlerProps } from './_types'
import Loader from '../loader'
import { invitesHandlerStyles } from './styles'

const InvitesHandler: FC<InvitesHandlerProps> = ({ isOpen, onClose }) => {
  const profile = useSelector((state: any) => state.profiles)
  const userId = useSelector((state: any) => state.user?.userInformation?.id)
  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    isOpen && dispatch(getUserInvitesRequest({ token, userId }))

    return () => {
      isOpen && dispatch(getUserInvitesClean())
    }
  }, [isOpen, profile?.handleInviteSuccess === true])

  useEffect(() => {
    if (
      profile?.handleInviteSuccess !== null &&
      profile?.handleInviteSuccess !== undefined &&
      profile?.handleInviteSuccess === true
    ) {
      toast({
        description: profile?.handleInviteStatus.message,
        status: 'success',
        duration: toastDuration,
      })

      dispatch(handleInviteClean())
      onClose()
    }

    if (profile?.handleInviteSuccess === false) {
      toast({
        description: profile?.handleInviteStatus.response.data.message,
      })
      dispatch(handleInviteClean())
    }
  }, [profile?.handleInviteSuccess])

  const handleInvite = (inviteId: string, statusId: string) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(handleInviteRequest({ token, inviteId, statusId }))
  }

  return (
    <Modal scrollBehavior='inside' isOpen={isOpen} onClose={onClose} size={'2xl'}>
      <ModalOverlay />
      <ModalContent sx={invitesHandlerStyles.modalContainer}>
        <ModalHeader>Invitaciones</ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={isMobile ? '4px 12px' : '8px 24px'}>
          {!profile?.userInvitesList?.invitations ? (
            <Loader props={invitesHandlerStyles.loading} />
          ) : profile?.userInvitesList?.invitations?.length === 0 ? (
            <Box sx={invitesHandlerStyles.noResultsContainer}>
              <Text sx={invitesHandlerStyles.noResults}>*No existen invitaciones pendientes</Text>
            </Box>
          ) : (
            <Table colorScheme='facebook'>
              <Thead>
                <Tr>
                  <Td sx={invitesHandlerStyles.tHead}>Institución</Td>
                  <Td sx={invitesHandlerStyles.tHead}>CUE</Td>
                  <Td sx={invitesHandlerStyles.tHead}>Fecha</Td>
                  <Td sx={invitesHandlerStyles.tHead}>Rol</Td>
                  <Td sx={invitesHandlerStyles.tHead}>Acción</Td>
                </Tr>
              </Thead>
              <Tbody>
                {profile?.userInvitesList?.invitations?.map((field: any, i: number) => (
                  <Tr key={i}>
                    <Td
                      sx={isMobile ? invitesHandlerStyles.tDataMobile : invitesHandlerStyles.tData}
                    >
                      {field.Institution.name}
                    </Td>
                    <Td
                      sx={isMobile ? invitesHandlerStyles.tDataMobile : invitesHandlerStyles.tData}
                    >
                      {field.Institution.cue}
                    </Td>
                    <Td
                      sx={isMobile ? invitesHandlerStyles.tDataMobile : invitesHandlerStyles.tData}
                    >
                      {new Date(field?.createdAt).toISOString().split('T')[0]}
                    </Td>
                    <Td
                      sx={isMobile ? invitesHandlerStyles.tDataMobile : invitesHandlerStyles.tData}
                    >
                      {field.Role.description}
                    </Td>
                    <Td
                      sx={isMobile ? invitesHandlerStyles.tDataMobile : invitesHandlerStyles.tData}
                    >
                      <Icon
                        sx={
                          isMobile
                            ? invitesHandlerStyles.checkIconMobile
                            : { ...invitesHandlerStyles.checkIcon }
                        }
                        as={CheckIcon}
                        onClick={() => handleInvite(field.id, '1')}
                      />
                      <Icon
                        sx={
                          isMobile
                            ? invitesHandlerStyles.closeIconMobile
                            : { ...invitesHandlerStyles.closeIcon }
                        }
                        as={CloseIcon}
                        onClick={() => handleInvite(field.id, '2')}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InvitesHandler
