// import { AxiosPromise } from 'axios'
// import httpModule from '../../../../utils/http'

const mockData = {
  data: {
    id: 0,
    name: 'Pepe',
    surname: 'Argento',
    description: 'Zapatero',
  },
}

//  export const getListDetails = (name: string): AxiosPromise<any> =>
//    httpModule.get(`https://pokeapi.co/api/v2/pokemon/${name}`)
// }

export const getList = () => {
  return mockData
}
