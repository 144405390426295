// SAGAS

import * as types from '../types'

import { call, put, takeLatest } from 'redux-saga/effects'
import {
  changePassClean,
  changePassFailure,
  changePassProfileClean,
  changePassProfileFailure,
  changePassProfileSuccess,
  changePassSuccess,
  generateClean,
  generateFailure,
  generateSuccess,
  loginClean,
  loginFailure,
  loginSuccess,
  registerInstitutionClean,
  registerInstitutionFailure,
  registerInstitutionSuccess,
  registerPostulanteClean,
  registerPostulanteFailure,
  registerPostulanteStateFailure,
  registerPostulanteStateSuccess,
  registerPostulanteSuccess,
  verifyClean,
  verifyFailure,
  verifySuccess,
} from '../actions'
import {
  changePassword,
  changePasswordProfile,
  generateCode,
  login,
  registerInstitution,
  registerPostulante,
  verifyCode,
} from '../services'

import { JwtData } from '../services/types'
import { SagaIterator } from 'redux-saga'
import jwt from 'jwt-decode'
import { userInformationRequest } from '../../user/actions'

function* postLoginWorker(values: any): SagaIterator {
  try {
    // yield put(loginClean())
    const { navigate } = values.payload
    const { data } = yield call(login, values.payload)
    const jwtToken = data.token
    const jwtTokenData: JwtData = jwt(jwtToken)
    localStorage.setItem('access_token', jwtToken)
    yield put(loginSuccess())
    yield put(userInformationRequest({ userId: jwtTokenData.id }))
    // navigate('/home')
  } catch (error) {
    yield put(loginFailure(error))
  }
}
export function* loginWatcher(): SagaIterator {
  yield takeLatest(types.LOGIN_POST_REQUEST, postLoginWorker)
}

function* generateCodeWorker(values: any): SagaIterator {
  try {
    yield call(generateCode, values.payload)
    yield put(generateSuccess(values))
  } catch (error) {
    yield put(generateFailure(error))
  }
}
export function* generateCodeWatcher(): SagaIterator {
  yield takeLatest(types.GENERATE_CODE_POST_REQUEST, generateCodeWorker)
}

function* validateCodeWorker(values: any): SagaIterator {
  try {
    yield put(verifyClean())
    const { navigate } = values.payload
    yield call(verifyCode, values.payload)
    yield put(verifySuccess(values))
    navigate('/password/change')
  } catch (error) {
    yield put(verifyFailure(error))
  }
}
export function* validateCodeWatcher(): SagaIterator {
  yield takeLatest(types.VERIFY_CODE_POST_REQUEST, validateCodeWorker)
}

function* changePassWorker(values: any): SagaIterator {
  const { navigate } = values.payload
  try {
    yield put(changePassClean())
    const { data } = yield call(changePassword, values.payload)
    yield put(changePassSuccess(data))
    yield put(generateClean())
    yield put(verifyClean())
    yield put(loginClean())
    navigate('/login')
  } catch (error) {
    yield put(changePassFailure(error))
  }
}
export function* changePassWatcher(): SagaIterator {
  yield takeLatest(types.CHANGE_PASS_POST_REQUEST, changePassWorker)
}

////
function* changePassProfileWorker(values: any): SagaIterator {
  const { navigate } = values.payload
  try {
    yield put(changePassProfileClean())
    const { data } = yield call(changePasswordProfile, values.payload)
    yield put(changePassProfileSuccess(data))
    navigate('/home')
  } catch (error) {
    yield put(changePassProfileFailure(error))
  }
}
export function* changePassProfileWatcher(): SagaIterator {
  yield takeLatest(types.CHANGE_PASS_PROFILE_POST_REQUEST, changePassProfileWorker)
}

function* registerPostulanteWorker(values: any): SagaIterator {
  try {
    const { navigate } = values.payload
    const { data } = yield call(registerPostulante, values.payload)
    yield put(registerPostulanteSuccess(data))
    navigate('/login')
  } catch (error) {
    yield put(registerPostulanteFailure(error))
  }
}
export function* registerPostulanteWatcher(): SagaIterator {
  yield takeLatest(types.REGISTER_POSTULANTE_POST_REQUEST, registerPostulanteWorker)
}

function* registerPostulanteParameterWorker(values: any): SagaIterator {
  const navigate = values.payload.navigate
  try {
    yield put(registerPostulanteClean())
    yield put(registerInstitutionClean())
    yield put(registerPostulanteStateSuccess(values.payload))
    navigate('/registerinstitution')
  } catch (error) {
    yield put(registerPostulanteStateFailure(values.payload))
    navigate('/registerinstitution')
  }
}
export function* registerPostulanteStateWatcher(): SagaIterator {
  yield takeLatest(
    types.REGISTER_POSTULANTE_PARAMETER_STATE_REQUEST,
    registerPostulanteParameterWorker,
  )
}

function* registerInstitutionWorker(values: any): SagaIterator {
  try {
    yield put(registerInstitutionClean())
    const { navigate } = values.payload
    const { data } = yield call(registerInstitution, values.payload)
    yield put(registerInstitutionSuccess(data))
    navigate('/login')
  } catch (error) {
    yield put(registerInstitutionFailure(error))
  }
}
export function* registerInstitutionWatcher(): SagaIterator {
  yield takeLatest(types.REGISTER_INSTITUTION_POST_REQUEST, registerInstitutionWorker)
}

export const sagas = [
  loginWatcher,
  generateCodeWatcher,
  validateCodeWatcher,
  changePassWatcher,
  changePassProfileWatcher,
  registerPostulanteWatcher,
  registerPostulanteStateWatcher,
  registerInstitutionWatcher,
]
