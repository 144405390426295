import { AxiosPromise } from 'axios'
import httpModule from '../../../../utils/http'
import {
  DeleteApplicationsListPayload,
  DownloadHandleApplicationsListExcelPayload,
  HandleApplicationsListPayload,
  JobApplicationsListPayload,
} from './types'

const paths = {
  APPLICATIONS_LIST: 'job-application/my-applications',
  HANDLE_APPLICATIONS_LIST: 'job-application/institutions',
  DOWNLOAD_APPLICATIONS_SHEET: 'job-application/institutions/download-excel',
  DELETE_APPLICATION: 'job-application/',
}

export const getJobApplicationsListService = (
  payload: JobApplicationsListPayload,
): AxiosPromise<any> => {
  const body = {
    filters: {
      ...payload?.filters,
    },
    orderBy: { field: payload?.orderBy?.field, criteria: payload?.orderBy?.criteria },
    page: payload.page,
    size: 10,
  }

  return httpModule.post(paths.APPLICATIONS_LIST, body, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const postHandleApplicationsListService = (
  payload: HandleApplicationsListPayload,
): AxiosPromise<any> => {
  const body = { ...payload.filters, page: payload.page, size: payload.size }
  return httpModule.post(paths.HANDLE_APPLICATIONS_LIST, body, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const postDownloadHandleApplicationsListExcelService = (
  payload: DownloadHandleApplicationsListExcelPayload,
): AxiosPromise<any> =>
  httpModule.post(paths.DOWNLOAD_APPLICATIONS_SHEET, payload.filters, {
    headers: {
      Authorization: `${payload.token}`,
    },
    responseType: 'blob',
  })

export const deleteApplicationService = (
  payload: DeleteApplicationsListPayload,
): AxiosPromise<any> => {
  return httpModule.delete(`${paths.DELETE_APPLICATION}${payload.applicationId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}
