import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Link as ChakraLink,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Img,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useBreakpoint,
  useMediaQuery,
} from '@chakra-ui/react'
import { CheckIcon, EmailIcon, ViewIcon } from '@chakra-ui/icons'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import React, { FC, useEffect, useState } from 'react'
import { caseSvg, locationSvg } from '../../../../utils/general'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from '../../../../utils/colors'
import greekHouse from '../../../../assets/images/landing/greek_house.png'
import { jobApplicationRequest } from '../../../../state/modules/ads/actions'
// import { adCardProps } from '../../AdsSearch/AdCard/_types'
import { featuredAdvicesStyles } from './styles'
import { adCardStyles } from '../../../AdsSearch/AdCard/styles'

// import { Image } from '@chakra-ui/react'

interface FilePath {
  filePath: string
}

interface AdProps {
  title: string,
  createdAt: string,
  Institution: {
    id: string,
    UserFiles: {
      filePath: string;
    }[],
    Level: {
      id: string,
      description: string
    },
    Address: {
      id: string,
      Province: {
        id: string,
        description: string
      },
      Department: {
        id: string,
        description: string
      }
    }
  }
}
interface AdCardProps {
  ad: AdProps
  calcCardWidth: string
}
const Advice: FC<AdCardProps> = ({ ad, calcCardWidth }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [application, setApplication] = useState<boolean>(false)
  const userId = useSelector((state: any) => state?.user?.userInformation?.id)
  const [isLogged, setIsLogged] = useState(false)
  const deviceWidth = useBreakpoint()

  const navigate = useNavigate()

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])

  const handleActionBtn = () => {
    if (isLogged) {
      console.log('acción botón...')
    } else {
      navigate('/login')
    }
    window.location.reload()
  }

  const condImg = (ad?.Institution.UserFiles.length === 0) ? greekHouse :
    `${ad?.Institution.UserFiles[0].filePath}`

  return (
    <Card
      direction={['row', 'row']}
      overflow='hidden'
      variant='outline'
      height='190px'
      width={calcCardWidth}
      boxShadow={'0px 0px 52.5px 6px rgba(0, 0, 0, 0.09)'}
      p='20px'
      sx={featuredAdvicesStyles.advice}
      mb={{ base: '15px', md: '40px', lg: '40px', '2xl': '40px' }}
    >
      <Box
        h={['100%', '100%']}
        w={['20%']}
        display='flex'
        alignItems='center'
        justifyItems='center'
      >
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Img
            sx={isMobile ? adCardStyles.avatarMobile : adCardStyles.avatarImage}
            alt={ad?.title}
            src={condImg}
          />
        </Box>
      </Box>
      <CardBody
        id='advice'
        p={['0 0 0 25px', '0 0 0 30px']}
        justifyContent='flex-start'
        alignContent='flex-start'
        w={['80%']}
      >
        <Box
          h='75%'
          w='100%'
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
        >
          <Box sx={featuredAdvicesStyles.advice.pill}>
            <Text sx={featuredAdvicesStyles.advice.pill.title}>
              Activo
            </Text>
          </Box>

          <Box
            p='0'
            mb='4px'
            h={'60%'}
          >
            <Text
              sx={featuredAdvicesStyles.advice.adTitle}
              fontSize={{ base: '17.5px', md: '17.5px', lg: '20px' }}
              w={{ base: '60%', md: '65%', lg: '75%' }}
            >
              {ad?.title.length > (deviceWidth === 'base' ? 15 : 20) ? (<>
                {ad?.title.substring(0, deviceWidth === 'base' ? 15 : 20)}
                <Tooltip
                  label={ad?.title}
                  aria-label={ad?.title}
                  bgColor='#fff'
                  color={colors.principal}
                  ml='5px'
                >
                  ...
                </Tooltip>
              </>
              ) : ad?.title
              }
            </Text>
          </Box>

          <Text sx={featuredAdvicesStyles.advice.levelTitle} mb='4px'>
            {ad?.Institution.Level?.description || ''}
          </Text>
          <Text sx={featuredAdvicesStyles.advice.zoneTitle} textTransform='capitalize' mb='4px'>
            {ad?.Institution.Address?.Department?.description.toLowerCase() || ''}
          </Text>
        </Box>

        <Box
          h='25%'
          w='100%'
          display='flex'
          justifyItems='center'
        >
          <Button
            sx={featuredAdvicesStyles.advice.actionBtn}
            variant='link'
            onClick={handleActionBtn}
          >
            Postulate
          </Button>
        </Box>
      </CardBody>
    </Card>
  )
}

export default Advice
