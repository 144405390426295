// SAGAS
import { put, takeLatest, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as types from '../types'

import { getListFailure, getListSuccess } from '../actions'

import { getList } from '../services'

function* getListWorker(): SagaIterator {
  try {
    const { data } = yield call(getList)
    yield put(getListSuccess(data))
  } catch (error) {
    yield put(getListFailure(error))
  }
}

export function* listWatcher(): SagaIterator {
  yield takeLatest(types.LIST_GET_REQUEST, getListWorker)
}

export const sagas = [listWatcher]
