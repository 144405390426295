import * as types from '../types'

import { call, put, takeLatest } from 'redux-saga/effects'
import {
  downloadSpreadsheetFailure,
  downloadSpreadsheetSuccess,
  getInstitutionFailure,
  getInstitutionInviteHistoryFailure,
  getInstitutionInviteHistorySuccess,
  getInstitutionSuccess,
  getProfileFailure,
  getProfileSuccess,
  getUserInvitesFailure,
  getUserInvitesSuccess,
  handleInviteFailure,
  handleInviteSuccess,
  handleViewRequisitionFailure,
  handleViewRequisitionSuccess,
  newInviteFailure,
  newInviteSuccess,
  profilesFailure,
  profilesSuccess,
  resendInviteFailure,
  resendInviteSuccess,
  sendViewRequisitionFailure,
  sendViewRequisitionSuccess,
  viewRequisitionsFailure,
  viewRequisitionsSuccess,
} from '../actions'
import {
  getInstitutionInvitesHistoryService,
  getInstitutionService,
  getProfileService,
  getRequestsListService,
  getUserInvitesService,
  postDownloadExcelSpreedsheetService,
  postNewUserInviteService,
  postProfilesListService,
  postResendInviteService,
  postSendViewRequestService,
  putHandleInviteService,
  putHandleViewRequestService,
} from '../services'

import { SagaIterator } from 'redux-saga'

function* getProfilesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postProfilesListService, payload)
    yield put(profilesSuccess(data))
  } catch (error) {
    yield put(profilesFailure(error))
  }
}
export function* getProfilesWatcher(): SagaIterator {
  yield takeLatest(types.PROFILES_POST_REQUEST, getProfilesWorker)
}

function* getViewRequestsWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getRequestsListService, payload)
    yield put(viewRequisitionsSuccess(data))
  } catch (error) {
    yield put(viewRequisitionsFailure(error))
  }
}
export function* getViewRequestsWatcher(): SagaIterator {
  yield takeLatest(types.VIEW_REQUISITIONS_GET_REQUEST, getViewRequestsWorker)
}

function* putHandleViewRequestWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(putHandleViewRequestService, payload)
    yield put(handleViewRequisitionSuccess(data))
  } catch (error) {
    yield put(handleViewRequisitionFailure(error))
  }
}
export function* putHandleViewRequestWatcher(): SagaIterator {
  yield takeLatest(types.HANDLE_REQUISITION_PUT_REQUEST, putHandleViewRequestWorker)
}

function* postSendViewRequestWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postSendViewRequestService, payload)
    yield put(sendViewRequisitionSuccess(data))
  } catch (error) {
    yield put(sendViewRequisitionFailure(error))
  }
}
export function* postSendViewRequestWatcher(): SagaIterator {
  yield takeLatest(types.SEND_REQUISITION_POST_REQUEST, postSendViewRequestWorker)
}

function* getProfileInformationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getProfileService, payload)
    yield put(getProfileSuccess(data))
  } catch (error) {
    yield put(getProfileFailure(error))
  }
}
export function* getProfileInformationWatcher(): SagaIterator {
  yield takeLatest(types.PROFILE_GET_REQUEST, getProfileInformationWorker)
}

function* postDownloadSpreadsheetWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postDownloadExcelSpreedsheetService, payload)

    yield put(downloadSpreadsheetSuccess(data))
  } catch (error) {
    yield put(downloadSpreadsheetFailure(error))
  }
}
export function* postDownloadSpreadsheetWatcher(): SagaIterator {
  yield takeLatest(types.DOWNLOAD_SPREADSHEET_POST_REQUEST, postDownloadSpreadsheetWorker)
}

function* getInstitutionInformationWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getInstitutionService, payload)
    yield put(getInstitutionSuccess(data))
  } catch (error) {
    yield put(getInstitutionFailure(error))
  }
}
export function* getInstitutionInformationWatcher(): SagaIterator {
  yield takeLatest(types.INSTITUTION_GET_REQUEST, getInstitutionInformationWorker)
}

function* getUserInvitesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getUserInvitesService, payload)
    yield put(getUserInvitesSuccess(data))
  } catch (error) {
    yield put(getUserInvitesFailure(error))
  }
}
export function* getUserInvitesWatcher(): SagaIterator {
  yield takeLatest(types.USER_INVITES_GET_REQUEST, getUserInvitesWorker)
}

function* postNewUserInviteWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postNewUserInviteService, payload)
    yield put(newInviteSuccess(data))
  } catch (error) {
    yield put(newInviteFailure(error))
  }
}
export function* postNewUserInviteWatcher(): SagaIterator {
  yield takeLatest(types.NEW_INVITE_POST_REQUEST, postNewUserInviteWorker)
}

function* getInstitutionInvitesWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(getInstitutionInvitesHistoryService, payload)
    yield put(getInstitutionInviteHistorySuccess(data))
  } catch (error) {
    yield put(getInstitutionInviteHistoryFailure(error))
  }
}
export function* getInstitutionInvitesWatcher(): SagaIterator {
  yield takeLatest(types.INSTITUTION_INVITES_HISTORY_GET_REQUEST, getInstitutionInvitesWorker)
}

function* postHandleInviteWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(putHandleInviteService, payload)
    yield put(handleInviteSuccess(data))
  } catch (error) {
    yield put(handleInviteFailure(error))
  }
}
export function* postHandleInviteWatcher(): SagaIterator {
  yield takeLatest(types.HANDLE_INVITE_PUT_REQUEST, postHandleInviteWorker)
}
function* postResendInviteWorker(request: any): SagaIterator {
  try {
    const { payload } = request
    const { data } = yield call(postResendInviteService, payload)
    yield put(resendInviteSuccess(data))
  } catch (error) {
    yield put(resendInviteFailure(error))
  }
}
export function* postResendInviteWatcher(): SagaIterator {
  yield takeLatest(types.RESEND_INVITE_POST_REQUEST, postResendInviteWorker)
}

export const sagas = [
  getProfilesWatcher,
  getViewRequestsWatcher,
  putHandleViewRequestWatcher,
  postSendViewRequestWatcher,
  getProfileInformationWatcher,
  postDownloadSpreadsheetWatcher,
  getInstitutionInformationWatcher,
  getUserInvitesWatcher,
  postNewUserInviteWatcher,
  getInstitutionInvitesWatcher,
  postHandleInviteWatcher,
  postResendInviteWatcher,
]
