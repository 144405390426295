import { AttachmentIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Link,
  Text,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { FormGeneralInformation, GeneralInformationProps } from './_types'
import {
  fileDeleteClean,
  fileDeleteRequest,
  generalInformationClean,
  generalInformationRequest,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import LoadFile from '../../../../assets/images/profile/LoadFile.png'
import { PostRegisterGeneralInformation } from '../../../../state/modules/user/services/types'
import ProfileEmpty from '../../../../assets/images/profile/ProfileEmpty.png'
import { colors } from '../../../../utils/colors'

const toastMessages: UseToastOptions[] = [
  {
    description: 'Cambios guardados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const GeneralInformation: FC<GeneralInformationProps> = ({ onClose }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const state = useSelector((state: any) => state)
  const { generalInformationSuccess } = useSelector((state: any) => state.user)
  const userInformation = useSelector((state: any) => state.user?.userInformation ?? '')
  const { fileDeleteSuccess, fileDeleteStatus } = useSelector((state: any) => state.user)
  const dispatch = useDispatch()
  const toast = useToast()

  useEffect(() => {
    if (
      generalInformationSuccess !== null &&
      generalInformationSuccess !== undefined &&
      generalInformationSuccess
    ) {
      onClose()
      dispatch(userInformationRequest({ userId: state.user.userInformation.id }))
      toast(toastMessages[0])
      dispatch(generalInformationClean())
    }
    if (generalInformationSuccess === false) {
      toast(toastMessages[1])
      dispatch(generalInformationClean())
    }
  }, [generalInformationSuccess])

  useEffect(() => {
    if (fileDeleteSuccess !== null && fileDeleteSuccess !== undefined && fileDeleteSuccess) {
      dispatch(userInformationRequest({ userId: state.user.userInformation.id }))

      toast({ description: fileDeleteStatus?.message, status: 'success', duration: 2000 })
      dispatch(fileDeleteClean())
    }
    if (fileDeleteSuccess === false) {
      toast({ description: fileDeleteStatus?.response?.data?.msg, status: 'error', duration: 2000 })
      dispatch(fileDeleteClean())
    }
  }, [fileDeleteSuccess])

  const filePhotoInputRef = useRef<HTMLInputElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [filePhotoUp, setFilePhotoUp] = useState<File[]>([])
  const [imagen, setImagen] = useState<any>()
  const [actualProfileImageName, setActualProfileImageName] = useState()
  const [selectedFileName, setSelectedFileName] = useState<string>('')
  const [fileUp, setFileUp] = useState<File[]>([])
  const [filePreview, setFilePreview] = useState<any[]>([])
  const [fileError, setFileError] = useState<false | string>(false)

  useEffect(() => {
    if (userInformation && userInformation.UserFiles) {
      const imageObject = userInformation.UserFiles.findLast(
        (file: any) => file.filePath && file.fileType === 'AVATAR',
      )

      if (imageObject) {
        const actualImageName = imageObject.filePath.split(/[/]+/).pop().split(/[-]+/)
        actualImageName.shift()
        const imageFilePath = imageObject.filePath
        setImagen(imageFilePath)
        setActualProfileImageName(actualImageName.join('-'))
      }
    }
  }, [userInformation])

  useEffect(() => {
    if (userInformation && userInformation.UserFiles) {
      const filesArray = userInformation.UserFiles.filter(
        (file: any) => file.filePath && file.fileType !== 'AVATAR',
      ).map((file: any) => {
        return {
          name: file.filePath.split(/[/]+/).pop().split(/[-]+/).slice(1).join('-'),
          url: file.filePath,
          id: file.id,
        }
      })

      setFilePreview(filesArray)
    }
  }, [userInformation])

  useEffect(() => {
    if (fileError) {
      toast({ description: fileError, status: 'error', duration: 2000 })
      setFileError(false)
    }
  }, [fileError])

  const handleFilePhotoSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files && files[0]) {
      if (
        files[0]?.type === 'image/png' ||
        files[0]?.type === 'image/jpg' ||
        files[0]?.type === 'image/jpeg'
      ) {
        const selectedFiles = Array.from(files)
        setFilePhotoUp(selectedFiles)
        setSelectedFileName(files[0]?.name)
      } else {
        setFilePhotoUp([])
        setImagen(ProfileEmpty)
        setSelectedFileName('Archivo no permitido')
      }
    }
  }

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files
    setFileError(false)

    if (files) {
      if (
        files[0]?.type === 'image/png' ||
        files[0]?.type === 'image/jpg' ||
        files[0]?.type === 'image/jpeg' ||
        files[0]?.type === 'application/pdf'
      ) {
        const maxSize = 10 * 1024 * 1024

        if (files[0].size < maxSize) {
          const repeat = fileUp.find((file: any) => file.name === files[0]?.name)
          if (!repeat) {
            const selectedFiles = Array.from(files)

            setFileUp((current) => [...current, ...selectedFiles])
            setFilePreview((current: any[]) => [...current, ...selectedFiles])
          } else {
            setFileError('Archivo ya cargado')
          }
        } else {
          setFileError('El archivo excede el tamaño permitido (10 MB)')
        }
      } else {
        setFileError('Archivo no permitido')
      }
    }
  }

  const initialValues: FormGeneralInformation = {
    name: state.user.userInformation?.Profile?.name,
    lastName: state.user.userInformation?.Profile?.lastName,
    fotoLoad: null,
    filesLoad: null,
  }

  const handleButtonClickPhoto = () => {
    if (filePhotoInputRef.current) {
      filePhotoInputRef.current.click()
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  const handleClickInputFile = () => {
    if (fileInputRef.current) fileInputRef.current.value = ''
  }

  const handleFilePreview = (file: any) => {
    const link = document.createElement('a')
    const blobURL = file?.url ? file.url : URL.createObjectURL(file)
    link.href = blobURL
    link.target = '_blank'
    link.click()
  }

  const handleSubmit = (values: any) => {
    const updatedInitialValuesForm: PostRegisterGeneralInformation = {
      userId: state.user.userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      name: values.name,
      lastName: values.lastName,
      filesUp: fileUp,
      photoUp: filePhotoUp,
    }
    setFilePreview([])
    setFileUp([])
    setFilePhotoUp([])

    dispatch(generalInformationRequest(updatedInitialValuesForm))
  }

  const handleFileDelete = (file: any) => {
    const newFile = fileUp.find((f: any) => f.name === file.name)

    if (newFile) {
      setFileUp(fileUp.filter((f: any) => f.name !== file.name))
      setFilePreview(filePreview.filter((f: any) => f.name !== file.name))
    } else {
      setFilePreview(filePreview.filter((f: any) => f.id !== file.id))
      dispatch(
        fileDeleteRequest({ fileId: file.id, token: localStorage.getItem('access_token') ?? '' }),
      )
    }
  }

  return (
    <Box maxWidth='100%' margin='0 auto'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <Box padding='15px'>
              <Box display='flex' justifyContent='center'>
                <Text mb={4} width={isMobile ? '100%' : '30%'} fontWeight='bold'>
                  INFORMACIÓN GENERAL
                </Text>
              </Box>
              <Box display={isMobile ? 'block' : 'flex'}>
                <Box width={isMobile ? '100%' : '50%'} paddingRight={isMobile ? '0' : '2rem'}>
                  <FormControl>
                    <FormLabel htmlFor='name'>Nombre</FormLabel>
                    <Field as={Input} type='text' name='name' placeholder='Ingrese su Nombre' />
                  </FormControl>
                  {isMobile ? (
                    <FormControl>
                      <FormLabel htmlFor='lastName'>Apellido</FormLabel>
                      <Field
                        as={Input}
                        type='text'
                        name='lastName'
                        placeholder='Ingrese su Apellido'
                      />
                    </FormControl>
                  ) : null}

                  <Box mt={5}>
                    <Button
                      onClick={handleButtonClickPhoto}
                      width={'100%'}
                      height={['200px', '200px']}
                      borderColor='#56769A'
                      color='#56769A'
                      backgroundColor={'white'}
                      variant={'outline'}
                      borderWidth='1px'
                      borderStyle='dashed'
                      rounded='md'
                      p={4}
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Image src={LoadFile} alt='LoadFila' objectFit='contain' />
                      <Box
                        mt={5}
                        display={'flex'}
                        flexDirection={'column'}
                        rowGap={2}
                        alignItems={'center'}
                      >
                        <Box display={'flex'}>
                          <AttachmentIcon boxSize={4} mr={2} />
                          <Text size='10px' fontWeight='normal'>
                            Subir Foto
                          </Text>
                        </Box>
                        <Text sx={{ textWrap: 'wrap' }} fontSize='12px'>
                          *Formatos permitidos: jpg, jpeg y png
                        </Text>
                        <Text sx={{ textWrap: 'wrap' }} fontWeight='normal' fontSize='12px'>
                          *Se recomienda que la imagen a subir sea cuadrada para una mejor
                          visualización
                        </Text>
                      </Box>
                    </Button>
                    <input
                      ref={filePhotoInputRef}
                      type='file'
                      style={{ display: 'none' }}
                      onChange={handleFilePhotoSelect}
                      accept='.png, .jpeg, .jpg'
                    />
                    <Box
                      mt={5}
                      width='100px'
                      height='100px'
                      borderRadius='50%'
                      borderWidth='2px'
                      borderColor='black'
                      overflow='hidden'
                      alignSelf={'center'}
                    >
                      {filePhotoUp.length > 0 ? (
                        <Image
                          src={URL.createObjectURL(filePhotoUp[0])}
                          alt='LoadFila'
                          objectFit='contain'
                          width='100%'
                          height='100%'
                        />
                      ) : (
                        <Image
                          src={imagen || ProfileEmpty}
                          alt='ProfileEmpty'
                          objectFit='contain'
                          width='100%'
                          height='100%'
                        />
                      )}
                    </Box>
                    <Text mt={2} size='10px' fontWeight='normal'>
                      {filePhotoUp.length > 0
                        ? selectedFileName
                        : selectedFileName !== ''
                          ? selectedFileName
                          : actualProfileImageName || 'Sin foto'}
                    </Text>
                  </Box>
                </Box>
                <Box width={isMobile ? '100%' : '50%'}>
                  {isMobile ? null : (
                    <FormControl>
                      <FormLabel htmlFor='lastName'>Apellido</FormLabel>
                      <Field
                        as={Input}
                        type='text'
                        name='lastName'
                        placeholder='Ingrese su Apellido'
                      />
                    </FormControl>
                  )}
                  <Button
                    onClick={handleButtonClick}
                    mt={5}
                    width={'100%'}
                    height='200px'
                    borderColor='#56769A'
                    color='#56769A'
                    backgroundColor='white'
                    variant='outline'
                    borderWidth='1px'
                    borderStyle='dashed'
                    rounded='md'
                    p={4}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Image src={LoadFile} alt='LoadFila' objectFit='contain' />

                    <Box mt={5} display={'flex'}>
                      <AttachmentIcon boxSize={4} mr={2} />
                      <Text size='10px' fontWeight='normal'>
                        Subir DNI, CV, Titulo
                      </Text>
                    </Box>
                    <Box mt={1} display={'flex'}>
                      <AttachmentIcon boxSize={4} mr={2} />
                      <Text size='10px' fontWeight='normal' whiteSpace={'normal'}>
                        Constancia de CUIL y Capacitaciones
                      </Text>
                    </Box>
                  </Button>
                  <input
                    ref={fileInputRef}
                    id='file_input'
                    type='file'
                    style={{ display: 'none' }}
                    onClick={handleClickInputFile}
                    onChange={handleFileSelect}
                    accept='.png, .jpeg, .jpg, .pdf'
                  />
                  {filePreview?.length > 0 && (
                    <Box mt={2} mb={6}>
                      {filePreview?.map((file, index) => (
                        <Box
                          key={index}
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <Box mt={1} display={'flex'} width={'92%'}>
                            <AttachmentIcon boxSize={4} mr={2} />
                            <Link
                              onClick={() => handleFilePreview(file)}
                              size='10px'
                              fontWeight='normal'
                              width={'auto'}
                              textOverflow={'ellipsis'}
                              whiteSpace={'nowrap'}
                              overflow={'hidden'}
                            >
                              {file?.name}
                            </Link>
                          </Box>
                          <Icon
                            color={colors.red}
                            cursor={'pointer'}
                            onClick={() => handleFileDelete(file)}
                            as={DeleteIcon}
                            boxSize={4}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box display='flex' justifyContent='center'>
                <Button mt={4} colorScheme='blue' width={isMobile ? '100%' : 'auto'} type='submit'>
                  Guardar Cambios
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default GeneralInformation
