import { colors } from '../../../../utils/colors'

export const headerLandingStyles = {
  actionButton: {
    backgroundColor: colors.institutionalRed,
    color: '#fff',
    '&:hover': {
      backgroundColor: colors.institutionalRed
    },
    fontSize:'14px',
    fontWeight:'200'
  }
}
