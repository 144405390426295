import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  useMediaQuery
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { candidateProfileStyles } from '../styles'
import PreferencesStepper from './PreferencesStepper'

const CandidatePreferences: FC<any> = ({ preferences }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [firstsPreferences, setFirstsPreferences] = useState<any>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (preferences.length > 2) {
      setFirstsPreferences(preferences.slice(0, 2))
    }
  }, [preferences])

  return (
    <Card
      boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
      borderRadius='12px'
      p={['20px', '40px 68px']}
    >
      <CardHeader p='0' mb='20px'>
        <Heading sx={candidateProfileStyles.bodyTitle}>Preferencias laborales</Heading>
      </CardHeader>

      <CardBody p='0'>
        <Box>
          <PreferencesStepper preferences={
            (preferences.length <= 2) ? (preferences) :
              isExpanded ? preferences : firstsPreferences
          } />
          {(preferences.length > 2) && <Button sx={candidateProfileStyles.seeMoreButton}
            onClick={() => setIsExpanded(isExpanded => !isExpanded)}>
            {isExpanded ? 'Ver menos' : 'Mostrar todas las preferencias laborales'}
          </Button>}
        </Box>
      </CardBody>
    </Card>
  )
}

export default CandidatePreferences
