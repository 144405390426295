import { applications } from './_ducks'
import * as types from './types'
import { PayloadAction } from '../../types'
import {
  ModuleTypeDeleteApplication,
  ModuleTypeDownloadHandleApplicationsSpreadsheet,
  ModuleTypeHandleApplicationsList,
  ModuleTypeJobApplicationsList,
} from './_definitions'

const initialModuleStateJobApplicationsList: ModuleTypeJobApplicationsList = {
  hasErrMessage: false,
  jobApplicationsList: null,
}

const initialModuleStateHandleApplicationsList: ModuleTypeHandleApplicationsList = {
  hasErrMessage: false,
  handleApplicationsList: null,
}

const initialModuleStateDownloadApplicationsSheet: ModuleTypeDownloadHandleApplicationsSpreadsheet =
  {
    hasErrMessage: false,
    downloadApplicationsSheetSuccess: null,
    downloadApplicationsSheetStatus: null,
  }

const initialModuleStateDeleteApplication: ModuleTypeDeleteApplication = {
  hasErrMessage: false,
  deleteApplicationSuccess: null,
  deleteApplicationStatus: null,
}

const reducer = applications.createReducer(
  {
    [types.APPLICATIONS_LIST_POST_REQUEST]: (
      state: ModuleTypeJobApplicationsList,
    ): ModuleTypeJobApplicationsList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.APPLICATIONS_LIST_POST_SUCCESS]: (
      state: ModuleTypeJobApplicationsList,
      { payload }: PayloadAction,
    ): ModuleTypeJobApplicationsList => ({
      ...state,
      jobApplicationsList: payload?.applications,
    }),
    [types.APPLICATIONS_LIST_POST_FAILURE]: (
      state: ModuleTypeJobApplicationsList,
    ): ModuleTypeJobApplicationsList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.APPLICATIONS_LIST_POST_CLEAN]: (
      state: ModuleTypeJobApplicationsList,
    ): ModuleTypeJobApplicationsList => ({
      ...state,
      hasErrMessage: false,
      jobApplicationsList: null,
    }),
    [types.HANDLE_APPLICATIONS_LIST_POST_REQUEST]: (
      state: ModuleTypeHandleApplicationsList,
    ): ModuleTypeHandleApplicationsList => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.HANDLE_APPLICATIONS_LIST_POST_SUCCESS]: (
      state: ModuleTypeHandleApplicationsList,
      { payload }: PayloadAction,
    ): ModuleTypeHandleApplicationsList => ({
      ...state,
      handleApplicationsList: payload?.applications,
    }),
    [types.HANDLE_APPLICATIONS_LIST_POST_FAILURE]: (
      state: ModuleTypeHandleApplicationsList,
    ): ModuleTypeHandleApplicationsList => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.HANDLE_APPLICATIONS_LIST_POST_CLEAN]: (
      state: ModuleTypeHandleApplicationsList,
    ): ModuleTypeHandleApplicationsList => ({
      ...state,
      hasErrMessage: false,
      handleApplicationsList: null,
    }),
    [types.DOWNLOAD_APPLICATIONS_SHEET_POST_REQUEST]: (
      state: ModuleTypeDownloadHandleApplicationsSpreadsheet,
    ): ModuleTypeDownloadHandleApplicationsSpreadsheet => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.DOWNLOAD_APPLICATIONS_SHEET_POST_SUCCESS]: (
      state: ModuleTypeDownloadHandleApplicationsSpreadsheet,
      { payload }: PayloadAction,
    ): ModuleTypeDownloadHandleApplicationsSpreadsheet => ({
      ...state,
      downloadApplicationsSheetSuccess: true,
      downloadApplicationsSheetStatus: payload,
    }),
    [types.DOWNLOAD_APPLICATIONS_SHEET_POST_FAILURE]: (
      state: ModuleTypeDownloadHandleApplicationsSpreadsheet,
      { payload }: PayloadAction,
    ): ModuleTypeDownloadHandleApplicationsSpreadsheet => ({
      ...state,
      hasErrMessage: true,
      downloadApplicationsSheetSuccess: false,
      downloadApplicationsSheetStatus: payload,
    }),
    [types.DOWNLOAD_APPLICATIONS_SHEET_POST_CLEAN]: (
      state: ModuleTypeDownloadHandleApplicationsSpreadsheet,
    ): ModuleTypeDownloadHandleApplicationsSpreadsheet => ({
      ...state,
      hasErrMessage: false,
      downloadApplicationsSheetSuccess: null,
      downloadApplicationsSheetStatus: null,
    }),
    [types.APPLICATION_DELETE_REQUEST]: (
      state: ModuleTypeDeleteApplication,
    ): ModuleTypeDeleteApplication => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.APPLICATION_DELETE_SUCCESS]: (
      state: ModuleTypeDeleteApplication,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteApplication => ({
      ...state,
      deleteApplicationSuccess: true,
      deleteApplicationStatus: payload,
    }),
    [types.APPLICATION_DELETE_FAILURE]: (
      state: ModuleTypeDeleteApplication,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteApplication => ({
      ...state,
      hasErrMessage: true,
      deleteApplicationSuccess: false,
      deleteApplicationStatus: payload,
    }),
    [types.APPLICATION_DELETE_CLEAN]: (
      state: ModuleTypeDeleteApplication,
    ): ModuleTypeDeleteApplication => ({
      ...state,
      hasErrMessage: false,
      deleteApplicationSuccess: null,
      deleteApplicationStatus: null,
    }),
  },
  {
    initialModuleStateJobApplicationsList,
    initialModuleStateHandleApplicationsList,
    initialModuleStateDownloadApplicationsSheet,
    initialModuleStateDeleteApplication,
  },
)

export default reducer
