import { useEffect, useState, useRef } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Card,
  Box,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useMediaQuery,
  TableContainer,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { JobsExperienceRules, SocialSkillsceRules } from './_types'
import { showDataTableStyles } from './styles'
import { transformDate } from '../../../../../../utils/jobAds'

const JobsExperienceResume = () => {
  const componentRef = useRef<HTMLDivElement>(null)
  const [anchoMaximo, setAnchoMaximo] = useState(0)

  const userInformation = useSelector((state: any) => state?.user?.userInformation ?? '')
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const [jobs, setJobs] = useState<JobsExperienceRules[]>([])
  const [skill, setSkill] = useState<SocialSkillsceRules[]>([])

  useEffect(() => {
    if (componentRef.current) {
      const width = componentRef.current.offsetWidth
      setAnchoMaximo(width)
    }
  }, [])

  useEffect(() => {
    userInformation === undefined || userInformation === null || userInformation === ''
      ? null
      : setearDatos()
  }, [userInformation])

  const setearDatos = () => {
    const jobsarray = userInformation.JobExperiences.filter((item: JobsExperienceRules) => item)
    setJobs(jobsarray)

    const socialarray = userInformation.SocialSkills.filter((item: SocialSkillsceRules) => item)
    setSkill(socialarray)
  }

  const fechaFromater = (fechasinformato: any) => {
    const fecha = new Date(fechasinformato)
    const dia = fecha.getDate()
    const mes = fecha.getMonth() + 1
    const anio = fecha.getFullYear()
    const diaFormateado = ('0' + dia).slice(-2)
    const mesFormateado = ('0' + mes).slice(-2)
    const fechaFormateada = `${mesFormateado}/${diaFormateado}/${anio}`

    return fechaFormateada
  }

  return (
    <>
      {jobs.length !== 0 || skill.length !== 0 ? (
        <>
          <Box
            display={isMobile ? 'block' : 'block'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            {jobs?.length !== 0 && (
              <Box>
                <Heading sx={showDataTableStyles.heading}>Datos Laborales:</Heading>

                <TableContainer>
                  <Table colorScheme='facebook' sx={showDataTableStyles.table}>
                    <Thead>
                      <Tr>
                        <Th sx={{ width: 0.2, ...showDataTableStyles.tHead }}>Desde</Th>
                        <Th sx={{ width: 0.2, ...showDataTableStyles.tHead }}>Hasta</Th>
                        <Th sx={{ width: 0.3, ...showDataTableStyles.tHead }}>Título</Th>
                        <Th sx={{ width: 0.3, ...showDataTableStyles.tHead }}>Tareas</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {jobs.map((jobs, index) => {
                        const dateTo = transformDate(jobs.dateTo)
                        const dateFrom = transformDate(jobs.dateFrom)

                        return (
                          <Tr key={index}>
                            <Td sx={showDataTableStyles.tData}>{dateFrom}</Td>
                            <Td sx={showDataTableStyles.tData}>
                              {fechaFromater(jobs.dateTo) !== '12/31/1969' ? dateTo : 'Actualmente'}
                            </Td>
                            <Td sx={showDataTableStyles.tData}>{jobs.jobPosition}</Td>
                            <Td sx={showDataTableStyles.tData}>{jobs.jobDescription}</Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
          <br />
          <Box
            display={isMobile ? 'block' : 'block'}
            flexWrap={'wrap'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            {skill?.length !== 0 &&
              (isMobile ? (
                <Box width={'100%'} display={'flex'} flexDirection='column'>
                  <Heading sx={showDataTableStyles.skillsHeading}>Habilidades sociales:</Heading>

                  <Box sx={showDataTableStyles.skillsDataContainerMobile}>
                    {skill.map((job, index) => (
                      <Text key={index} m={2} sx={showDataTableStyles.socialSkillsData}>
                        {job.description}
                      </Text>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box width={'100%'} display={'flex'} flexWrap={'wrap'}>
                  <Heading sx={showDataTableStyles.skillsHeading}>Habilidades sociales:</Heading>

                  {skill.map((job, index) => (
                    <Text key={index} m={2} sx={showDataTableStyles.socialSkillsData}>
                      {job.description}
                    </Text>
                  ))}
                </Box>
              ))}
          </Box>
        </>
      ) : (
        <Box sx={showDataTableStyles.noResults}>
          <Text>*No existen registros asociados</Text>
        </Box>
      )}
    </>
  )
}

export default JobsExperienceResume
